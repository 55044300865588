import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLabelsAll, getLabels, patchLabels } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /**
   * 証紙申請一覧
   * @type {{ result?: { data?: Label[] }}|null}
   */
  fetchAll: null,
  labelDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** 証紙申請一覧 */
    fetchAll: null,
    /** 証紙申請詳細 */
    fetchLabelDetail: null,
    /** 証紙申請更新 */
    updateLabel: null,
  },
}

export const fetchAll = createAsyncThunk(
  'aniplex/labels/fetchAll',
  /**
   * 証紙詳細取得処理
   * @param {*} params
   */
  async (params, { dispatch }) => {
    try {
      const data = await getLabelsAll(params);
      return data;
    } catch (err) {
      // エラーが発生したらキャッシュ済みの結果をクリアする
      dispatch(clearFetchAll());
      handleAniplexApiError(err, dispatch, {
        '10801': ApiErrorMessage.TooManyResult,
      });
    }
  }
)

/**
 * 証紙申請詳細情報取得
 */
export const fetchLabelDetail = createAsyncThunk(
  'aniplex/labels/fetchLabelDetail',
  /**
   * 証紙申請詳細情報取得
   * @param {number} labelApplicationId 証紙申請書内部コード
   * @returns {labelDetail}
   */
  async(labelApplicationId, { dispatch }) => {
    try {
      const data = await getLabels(labelApplicationId);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '10901': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 証紙申請更新処理
 */
export const updateLabel = createAsyncThunk(
  'aniplex/labels/updateLabel',
  /**
   * @param {object} param1
   * @param {number} param1.labelApplicationId 証紙申請書内部コード
   * @param {PatchLabelParams} param1.params リクエストパラメータ
   */
  async({ labelApplicationId, params }, { dispatch }) => {
    try {
      const result = await patchLabels(labelApplicationId, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '11001': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '11002': ApiErrorMessage.UpdateStateMismatch,
        // 履歴登録失敗
        '11004': ApiErrorMessage.SystemError,
        // メッセージ登録失敗
        '11005': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * アニプレックス向け画面の証紙申請情報周りのスライス
 */
export const labelsSlice = createSlice({
  name: 'aniplex/labels',
  initialState,
  reducers: {
    /**
     * 証紙申請一覧をクリア
     * @param {typeof initialState} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null
    },
    /**
     * 証紙申請詳細をクリア
     * @param {typeof initialState} state
     */
    clearLabelDetail: (state) => {
      state.labelDetail = null
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        // 証紙情報一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        case 'fetchLabelDetail':
          state.apiStatus.fetchLabelDetail = null;
          break;
        case 'updateLabel':
          state.apiStatus.updateLabel = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // 証紙一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // 証紙申請詳細
      .addCase(fetchLabelDetail.pending, (state) => {
        state.apiStatus.fetchLabelDetail = 'loading';
      })
      .addCase(fetchLabelDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchLabelDetail = 'finish';
        state.labelDetail = action.payload;
      })
      .addCase(fetchLabelDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchLabelDetail = null;
      })
      // 証紙申請更新
      .addCase(updateLabel.pending, (state) => {
        state.apiStatus.updateLabel = 'loading';
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        state.apiStatus.updateLabel = 'finish';
      })
      .addCase(updateLabel.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateLabel = null;
      })
  }
});

export const {
  clearApiStatus,
  clearFetchAll,
  clearLabelDetail
} = labelsSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.labels.apiStatus;

/**
 * stateから証紙情報を取得する
 * @param {*} state
 * @returns {{ result?: { data?: Label[] }}|null}
 */
export const fetchAllLabels = (state) => state.aniplex.labels.fetchAll;

/**
 * 証紙詳細情報取得
 * @param {*} state
 * @returns {labelDetail|null} 証紙申請詳細情報
 */
export const selectLabelDetail = (state) => state.aniplex.labels.labelDetail;

export default labelsSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAll'|'fetchLabelDetail'|'updateLabel'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Label 証紙申請情報
 * @property {number} labelApplicationId 証紙申請書内部コード
 * @property {valueOf<Constants['Aniplex']['labelStatus']>} labelStatus 証紙申請ステータス
 * REQ: 申請中
 * TMP: 修正中
 * REJ: 差し戻し中
 * APP: 承認済み
 * @property {string} labelApplicationNo 証紙申請書No
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} preferredDate 希望日(YYYY/MM/DD)
 * @property {string} dispatchDate 発送日(YYYY/MM/DD)
 * @property {string} message 連絡事項
 */
/**
 * @typedef {object} labelDetail 証紙申請詳細情報
 * @property {number} labelApplicationId 証紙申請書内部コード
 * @property {string} labelApplicationNo 証紙申請書No
 * @property {number} revision 版数
 * @property {string} preferredDate 証紙受け取り希望日(YYYY/MM/DD)
 * @property {string} dispatchDate 発送日(YYYY/MM/DD)
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {'REQ'|'TMP'|'REJ'|'APP'} labelStatus 申請ステータス
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} message 連絡事項
 * @property {string} deliveryZipCode 送付先郵便番号
 * @property {string} deliveryAddress 送付先住所
 * @property {string} deliveryPhone 送付先電話番号
 * @property {string} deliveryCorpDivision 宛先会社名／部署名
 * @property {string} deliveryName 宛名
 * @property {labelProduct[]} labelList 証紙申請商品情報リスト
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {MessageHistory[]} messageHistoryList 連絡事項のリスト
 * @property {LabelPreviousVersion} [previousVersion] 前バージョンの証紙申請書情報
 */
/**
 * @typedef {object} labelProduct
 * @property {number} labelNo 証紙No
 * @property {string} productNo 品番
 * @property {string} productName 商品名
 * @property {number} labelFigure 証紙申請数
 * @property {'S'|'P'} labelType 証紙タイプ（S:シール P:印刷）
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'REJ'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * REJ: 差し戻し
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {'ANX'|'LCS'} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
/**
 * @typedef {object} LabelPreviousVersion 前バージョンの証紙申請詳細情報
 * @property {string} preferredDate 希望日(YYYY/MM/DD)
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} message 連絡事項
 * @property {string} deliveryZipCode 送付先郵便番号
 * @property {string} deliveryAddress 送付先住所
 * @property {string} deliveryPhone 送付先電話番号
 * @property {string} deliveryCorpDivision 宛先会社名／部署名
 * @property {string} deliveryName 宛名
 * @property {labelProduct[]} labelList 証紙申請商品リスト
 */
/**
 * @typedef {import('../../lib/api/aniplex').PatchLabelParams} PatchLabelParams
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion
