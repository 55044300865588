import { useMemo } from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearApiStatus, selectApiStatus, setAutoUpdateContractFlag } from "../../../slices/aniplex/proposalsSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay";

/**
 * 自動契約更新設定ポップアップ
 * @param {object} params
 * @param {ProposalDetail} params.proposal 企画詳細情報
 * @param {OnClose} params.onClose ポップアップを閉じたときのコールバック関数
 */
export const AutoContractUpdatePopup = ({ proposal, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).setAutoUpdateContractFlag;

  // フォームデータ
  const [autoUpdateFlg, setAutoUpdateFlg] = useState(false);

  // 現在の企画の状態を初期値として設定する
  useEffect(() => {
    setAutoUpdateFlg(proposal?.automaticContractUpdateFlag ?? false);
  }, [proposal?.automaticContractUpdateFlag]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  /** 設定ボタン押下時のハンドラ */
  const onSubmit = useCallback(() => {
    if (apiStatus === 'loading') {
      // API通信中は処理しない
      return;
    }

    // 自動契約更新制御フラグ設定処理を呼び出す
    dispatch(setAutoUpdateContractFlag({
      proposalId: proposal?.proposalId,
      updateDatetime: proposal?.updateDatetime,
      automaticContractUpdateFlag: autoUpdateFlg,
    }));
  }, [apiStatus, autoUpdateFlg, dispatch, proposal?.proposalId, proposal?.updateDatetime]);

  // 自動契約更新制御フラグ設定処理のハンドリング
  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(pushMessage('自動契約更新の設定を行いました。'));
      dispatch(clearApiStatus('setAutoUpdateContractFlag'));
      onClose('submit');
      return;
    }
  }, [apiStatus, dispatch, onClose]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup
        onClose={() => onClose('close')}
      >
        <h4 className="popup-title">自動契約更新の設定</h4>

        <div className="l-form">
          <div className="form-set" style={{ display: 'block' }}>
            <dl className="form-name required">自動契約更新</dl>
            <dd>
              <div style={{ marginLeft: '10px', marginTop: '-10px' }}>
                <ul>
                  <li>
                    <input type="radio" name="automaticContractUpdateFlag"
                      id="automaticContractUpdateFlag-1"
                      style={{ border: 'none' }}
                      value="true"
                      disabled={formLocked}
                      checked={autoUpdateFlg}
                      onChange={ev => setAutoUpdateFlg(str2bool(ev.target.value))} />
                    <label htmlFor="automaticContractUpdateFlag-1" className="form-radio">
                      1年ごとに自動契約延長を行う
                    </label>
                  </li>
                  <li>
                    <input type="radio"
                      name="automaticContractUpdateFlag"
                      id="automaticContractUpdateFlag-2"
                      style={{ border: 'none' }}
                      value="false"
                      disabled={formLocked}
                      checked={!autoUpdateFlg}
                      onChange={ev => setAutoUpdateFlg(str2bool(ev.target.value))} />
                    <label htmlFor="automaticContractUpdateFlag-2" className="form-radio">
                      自動契約延長を行わない（終了する）
                    </label>
                  </li>
                </ul>
              </div>
            </dd>
          </div>
        </div>

        <div className="mt10 mb15 wsPre" style={{ color: '#FF0000', fontWeight: 'bold', lineHeight: '1.5' }}>
          ライセンシーに覚書を送付済みか確認してください。<br />
          自動契約更新の設定を行いますか？
        </div>

        <div className="btn-wrapper">
          <div className="btn c-aniplex mt15">
            <button
              disabled={formLocked}
              onClick={() => onClose('close')}
            >キャンセル</button>
          </div>
          <div className="btn label mt15 bg-aniplex">
            <button
              disabled={formLocked}
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  )
}

function str2bool(value) {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') {
      return true;
    }
    if (value.toLowerCase() === 'false') {
      return false;
    }
  }
  return value;
}

//#region
/**
 * @typedef {import("../../../slices/aniplex/proposalsSlice").ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
//#endregion
