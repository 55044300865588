import { useState } from 'react';
import logoRoys from '../../assets/licensee_img/logo-roys.png';
import logoAniplex from '../../assets/licensee_img/logo-aniplex.png';
import { Link, matchRoutes } from 'react-router-dom';

/**
 * ライセンシー側ヘッダー
 */
export const LicenseeHeader = ({ username, onLogoutClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  /** 企画一覧配下の画面滞在中フラグ */
  const inProposalPage = !!matchRoutes([
      {path: '/licensee/proposalList'},
      {path: '/licensee/proposalDetail'},
      {path: '/licensee/proposalDetail/:proposalId'},
      {path: '/licensee/proposalStatus/:proposalId'},
      {path: '/licensee/contractDetail/:sDecisionNo'},
    ], window.location.hash.substring(1));
  /** 証紙申請一覧配下の画面滞在中フラグ */
  const inLabelRequestPage = !!matchRoutes([
    {path: '/licensee/labelRequestList'},
    {path: '/licensee/labelRequestList/:proposalNo'},
    {path: '/licensee/labelRequestDetail'},
    {path: '/licensee/labelRequestDetail/:labelApplicationId'},
  ], window.location.hash.substring(1));
  /** ロイヤリティ報告配下の画面滞在中フラグ */
  const inRoyaltyReportPage = !!matchRoutes([
    {path: '/licensee/royaltyReportList'},
    {path: '/licensee/royaltyReportList/:proposalNo'},
    {path: '/licensee/royaltyReportDetail'},
    {path: '/licensee/royaltyReportDetail/:ryReportId'}
  ], window.location.hash.substring(1));

  const onToggleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <header className="l-header">

      <div className="wrap">
        <Link to="/licensee/home">
          <h1 className="header-logo transition-img">
            <span className="header_login-logo_img">
              <img
                src={logoRoys}
                alt="ROYS ロゴ"
                title="ROYS"
                className="logo-roys"
                width="155px"
                height="30px"
              />
            </span>
            <span className="header_login-logo_img">
              <img
                src={logoAniplex}
                alt="aniplex ロゴ"
                title="aniplex"
                className="logo-aniplex"
                width="100px"
                height="30px"
              />
            </span>
          </h1>
        </Link>
        <div className="header-links">
          <p className="link-user c-black">
            <i className="icn user"></i>
            {username ? username + ' 様' : ''}
          </p>
          <dl className="btn-user">
            <dt className={"js-nav_trigger" + (isOpen ? ' is-active' : '')} onClick={onToggleClick}>
            ユーザー管理
            </dt>
            <dd className="js-nav_body" style={isOpen ? null : styleDisplayNone }>
              <ul>
                <li>
                  <Link to="/licensee/userList">ユーザー一覧</Link>
                </li>
                <li>
                  <Link to="/licensee/userUpdate">ユーザー情報編集</Link>
                </li>
              </ul>
            </dd>
          </dl>
          <p className="btn-logout btn c-black">
            <button type="button" onClick={() => onLogoutClick()}>
              <i className="icn logout"></i>
              ログアウト
            </button>
          </p>
        </div>
      </div>

      <nav className="header-nav">
        <ul className="header-nav_list">
          <li className={inProposalPage ? 'active' : ''}>
            <Link to="/licensee/proposalList">企画一覧</Link>
          </li>
          <li className={inLabelRequestPage ? 'active' : ''}>
            <Link to="/licensee/labelRequestList">証紙申請一覧</Link>
          </li>
          <li className={inRoyaltyReportPage ? 'active' : ''}>
            <Link to="/licensee/royaltyReportList">ロイヤリティ報告一覧</Link>
          </li>
        </ul>
      </nav>

    </header>
  );
};

// ユーザー管理、閉じている場合のstyle
const styleDisplayNone = { display: 'none' };
