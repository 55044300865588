import { LoadingOverlay } from "./LoadingOverlay"

/**
 * APIの通信中ステータスを読み取ってローディング表示を行うコンポーネント
 * @param {object} props
 * @param {ApiStatus[]} props.apiStatus 判定対象のAPIステータス
 */
export const ApiLoadingOverlay = ({ apiStatus = [] }) => {
  if (apiStatus.includes('loading')) {
    return <LoadingOverlay />
  }

  return null;
}

/**
 * @typedef {'loading'|'error'|'finish'|null} ApiStatus API通信状況
 */
