import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadFileRetry } from "../../../lib/api/aniplex";
import { basename } from "../../../lib/util";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { RyProofChangeStatus } from "./RyProofChangeStatus";

/**
 * ロイヤリティ報告詳細画面の確証ファイルダウンロードの部品
 * @param {object} params
 * @param {RoyaltyDetail|undefined} params.royalty ロイヤリティ報告詳細情報
 */
export const RyProofDownloader = ({ royalty }) => {
  const dispatch = useDispatch();

  // ダウンロード中フラグ
  const [downloading, setDownloading] = useState(false);
  // ダウンロード用に追加した要素への参照
  const [downloadEls, setDownloadEls] = useState([]);

  useEffect(() => {
    return () => {
      // ダウンロード用要素が変更されたら前回の要素は削除
      downloadEls.forEach(el => {
        if (document.body.contains(el)) {
          document.body.removeChild(el);
        }
      });
    }
  }, [downloadEls]);

  useEffect(() => {
    return () => {
      // 離脱時にダウンロード要素を削除させるために空をセット
      setDownloadEls([]);
    }
  }, []);

  /** 確証ダウンロードボタン押下時のハンドラ */
  const onDownloadClick = useCallback(async () => {
    try {
      setDownloading(true);
      const newEls = await downloadFiles(royalty?.ryProofList.map(r => r.ryProofNo));
      setDownloadEls(prev => ([...prev, ...newEls]));
    } catch {
      dispatch(pushMessage('ファイルのダウンロードに失敗しました。'));
    } finally {
      setDownloading(false);
    }
  }, [dispatch, royalty?.ryProofList]);

  return (
    <>
      <div className="l-form mt20">
        <dl className="form-set">
          <dt className="form-name">
            <div>
              <p>
                報告数量の証憑となる資料の添付をお願いします。
                {
                  royalty != null && (
                    <RyProofChangeStatus
                      ryProofList={royalty.ryProofList}
                      prevRyProofList={royalty.previousVersion?.ryProofList} />
                  )
                }
              </p>
              <p className="attention">※複数資料添付可能</p>
            </div>
          </dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <p className="btn bg-yellow download" style={{ width: '200px' }}>
              <button onClick={onDownloadClick}>確証ダウンロード</button>
            </p>
            <p className="mt10">
              ファイル形式：PDF, Microsoft Word, Microsoft Excel, JPEG, PNG, GIF<br />
              ファイルサイズ：5MB以内<br />
              ファイル数：5つ以内
            </p>
          </dd>
        </dl>
      </div>

      {
        downloading && <LoadingOverlay />
      }
    </>
  )
}

/**
 * 確証ファイルのダウンロード処理
 * @param {number[]} fileNoList ファイルNoのリスト
 * @throws {number[]} ダウンロードAPI呼出しに失敗したファイルNo
 */
async function downloadFiles(fileNoList) {
  /**
   * 確証ファイルを取得してDataURIを生成する
   * @param {number} fileNo ファイルNo
   * @returns {Promise<{dataUri: string, filename: string}>}
   */
  const fileDownload = async (fileNo) => {
    const res = await downloadFileRetry({
      filetype: 'ryProof',
      fileNo,
    });

    const link = res.downloadPresignedUrl;

    // ファイル名はURLから取得
    let filename = decodeURIComponent(basename(new URL(link).pathname));
    const fileRes = await axios.get(link, { responseType: 'arraybuffer' });

    // DataURIに変換
    const dataUri = await new Promise(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        res(reader.result);
      })
      reader.readAsDataURL(new Blob(
        [fileRes.data],
      ));
    });

    return { dataUri, filename };
  }

  const result = await Promise.allSettled(
    fileNoList.map(fileNo =>
      fileDownload(fileNo)
    )
  );

  const errors = result.map((val, idx) => ([val, idx]))
    .filter(([val]) => val.status === 'rejected')
    .map(([val, idx]) => fileNoList[idx]);
  if (errors.length > 0) {
    throw errors;
  }

  return result.map(res => res.value ?? null)
    .filter(data => data?.dataUri)
    .map(info => {
      // ポップアップブロック対策として
      // iframeを生成してその中にaタグを作成
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      // DataURIを設定したaタグをクリックすることでダウンロードさせる
      const a = iframe.contentDocument.createElement('a');
      a.href = info.dataUri;
      a.download = info.filename;
      iframe.contentDocument.body.appendChild(a);
      iframe.sandbox = 'allow-downloads';
      a.click();
      return iframe;
    });
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告詳細情報
 */
//#endregion typedef
