import { isDecimalNumber } from "./validator";
/**
 * 数値のカンマ区切り挿入
 * @param {*} value
 * @return value（カンマ区切り、小数0抑制）
 */
 export const insertComma = (value) => {
  // 数値以外の場合は整形せずに返却
  if (!isDecimalNumber(value)) return value;
  const numArray = value.toString().split('.');
  // 整数部の数値グループ化（カンマ区切り）
  const IntStr = Number(deleteComma(numArray[0])).toLocaleString('en-US');
  if (numArray.length > 1) {
    // 小数の0抑制
    const decimalStr = numArray[1].replace(/([0-9]*[1-9]+)(0*)|^0+$/, '$1');
    if (decimalStr) return IntStr + '.' + decimalStr;
  }
  return IntStr;
}

/**
 * 数値のカンマ除去
 * @param {*} value
 * @return value（カンマなし）
 */
export const deleteComma = (value) => {
  if (!value) return value;
  // カンマは全て除去
  return value.toString().replaceAll(',', '');
}
