//@ts-check
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { statusListLabelAniplex } from '../../common/headerList';
import { selectPropertyMst } from '../../../slices/aniplex/masterSlice';
import { SearchableListBox } from '../../common/SearchableListBox';
import { useSaveFormData } from '../../../lib/hooks/common';
import { Constants } from '../../../Constants';

/**
 * 検索パラメーターの初期値
 * @type {SearchFormData}
 */
export const defaultParams = {
  proposalNo: '',
  proposalTitle: '',
  applicationDateFrom: undefined,
  applicationDateTo: undefined,
  labelStatus: '',
  applicantUserName: '',
  propertySummaryCode: '',
  licenseeCode: '',
  licenseeNameKanji: '',
  preferredDateFrom: undefined,
  preferredDateTo: undefined,
  dispatchDateFrom: undefined,
  dispatchDateTo: undefined,
  filterCondition: 'ALL',
  labelApplicationNo: '',
};

/**
 * クリア時の検索パラメータの値
 * @type {SearchFormData}
 */
const clearedParams = {
  ...defaultParams,
  filterCondition: 'PSO'
};

/**
 * 日付型のパラメータ
 * @satisfies {(keyof SearchFormData)[]}
 */
export const dateParams = [
  'applicationDateFrom',
  'applicationDateTo',
  'preferredDateFrom',
  'preferredDateTo',
  'dispatchDateFrom',
  'dispatchDateTo',
];

/**
 * [A]証紙一覧の検索フォーム
 * @param {object} props
 * @param {SearchFormData} props.params 検索フォームデータ
 * @param {React.Dispatch<React.SetStateAction<SearchFormData>>} props.setParams 検索フォームデータ設定処理
 * @param {(param: SearchFormData) => void} props.onSearch 検索実行時のコールバック
 */
export const LabelRequestListSearchForm = ({ params, setParams, onSearch }) => {

  // 検索パラメータ保存機能
  const { saveData } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.LabelRequestList,
    dataType: params,
    dateParams,
  });

  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertyMst);

  /** 作品マスタ(申請可能) */
  const propertyMstShown = useMemo(() => {
    if (!propertyMst) {
      return propertyMst;
    }
    return propertyMst.filter(property => {
      return property.proposalFlag;
    });
  }, [propertyMst]);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, [setParams]);

  /** 作品の変更時 */
  const onPropertyChange = useCallback((name, value) => {
    setParams(params => ({ ...params, [name]: value }));
  }, [setParams]);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, [setParams]);

  /** 選択された作品 */
  const selectedProperty = useMemo(() => {
    const found = propertyMstShown.find(i => i.propertySummaryCode === params.propertySummaryCode);
    if (!found) {
      return null;
    }
    return {
      label: found.propertySummaryName,
      value: found.propertySummaryCode,
    };
  }, [propertyMstShown, params]);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    // 現在のパラメータを保存
    saveData(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefined・日付はフォーマットする)
    onSearch(params);
  }, [saveData, params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    const params = {...clearedParams};
    setParams(params);
  }, [setParams]);

  return (
    <>
      <div className="l-form mt30">
        <dl className="form-set">
          <dt className="form-name" style={{width: '145px'}}>企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="proposalNo" title="企画書Noを入力してください" aria-label="企画書No"
                value={params.proposalNo}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="proposalTitle" title="企画件名を選択してください" aria-label="企画書No"
                value={params.proposalTitle}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '145px'}}>申請日</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateFrom"
                title="申請開始日を入力してください"
                aria-label="申請開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateFrom}
                onChange={date => onDateChange('applicationDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateTo"
                title="申請終了日を入力してください"
                aria-label="申請終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateTo}
                onChange={date => onDateChange('applicationDateTo', date)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">証紙申請ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="labelStatus" title="証紙申請ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.labelStatus}>
                <option value=""></option>
                {Object.keys(statusListLabelAniplex).map(i => (
                  <option value={i} key={i}>{statusListLabelAniplex[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">申請者</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="applicantUserName" title="申請者を入力してください" aria-label="申請者"
                value={params.applicantUserName}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">証紙申請No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="labelApplicationNo" title="証紙申請Noを入力してください" aria-label="証紙申請No"
                value={params.labelApplicationNo}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '145px'}}>作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              {/* @ts-expect-error */}
              <SearchableListBox
                // @ts-expect-error
                isDisabled={false}
                value={selectedProperty}
                data={propertyMstShown}
                onChange={item => onPropertyChange('propertySummaryCode', item?.value)}
                labelKey='propertySummaryName'
                valueKey='propertySummaryCode'
                katakanaKey='propertySummaryNameKana'
                hankakuKanaKey='propertySummaryNameHankakuKana'
                hiraganaKey='propertySummaryNameHiragana' />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '145px'}}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="licenseeCode" title="取引先コードを入力してください" aria-label="取引先コード"
                value={params.licenseeCode}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="licenseeNameKanji" title="取引先名を入力してください" aria-label="取引先名"
                value={params.licenseeNameKanji}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '250px'}}>証紙受け取り希望日（期間検索）</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name="preferredDateFrom"
                title="証紙受け取り希望日（期間検索）開始日を入力してください"
                aria-label="証紙受け取り希望日（期間検索）開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.preferredDateFrom}
                onChange={date => onDateChange('preferredDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="preferredDateTo"
                title="証紙受け取り希望日（期間検索）終了日を入力してください"
                aria-label="証紙受け取り希望日（期間検索）終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.preferredDateTo}
                onChange={date => onDateChange('preferredDateTo', date)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">発送日（期間検索）</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name="dispatchDateFrom"
                title="発送日（期間検索）開始日を入力してください"
                aria-label="発送日（期間検索）開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.dispatchDateFrom}
                onChange={date => onDateChange('dispatchDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="dispatchDateTo"
                title="発送日（期間検索）終了日を入力してください"
                aria-label="発送日（期間検索）終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.dispatchDateTo}
                onChange={date => onDateChange('dispatchDateTo', date)} />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: "140px" }}>絞り込み</dt>
          <dd className="form-body">
            <ul className="list-form df">
              <li>
                <input
                  id="filterConditionALL"
                  type="radio"
                  name="filterCondition"
                  aria-label="すべて"
                  value="ALL"
                  checked={params.filterCondition === 'ALL'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionALL" className="form-radio">すべて</label>
              </li>
              <li>
                <input
                  id="filterConditionPSO"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当作品のみ表示"
                  value="PSO"
                  checked={params.filterCondition === 'PSO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPSO" className="form-radio">自分の担当作品のみ表示</label>
              </li>
              <li>
                <input
                  id="filterConditionPP0"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当企画のみ表示"
                  value="PPO"
                  checked={params.filterCondition === 'PPO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPP0" className="form-radio">自分の担当企画のみ表示</label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      <div className="l-buttons mt15">
        <p className="btn bg-yellow" style={{ width: '160px' }}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn c-aniplex" style={{ width: '160px' }}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームデータ
 * @property {string} proposalNo 企画No※部分一致検索
 * @property {string} proposalTitle 企画件名※部分一致検索
 * @property {Date|undefined|null} applicationDateFrom 申請日From
 * @property {Date|undefined|null} applicationDateTo 申請日To
 * @property {valueOf<Constants['Aniplex']['labelStatus']>|''} labelStatus 証紙申請ステータス
 * REQ: 申請中
 * TMP: 修正中
 * REJ: 差し戻し中
 * APP: 承認済み
 * @property {string} applicantUserName 申請者氏名※部分一致検索
 * @property {string} propertySummaryCode 作品コード
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）※部分一致検索
 * @property {Date|undefined|null} preferredDateFrom 希望日From
 * @property {Date|undefined|null} preferredDateTo 希望日To
 * @property {Date|undefined|null} dispatchDateFrom 発送日From
 * @property {Date|undefined|null} dispatchDateTo 発送日To
 * @property {'ALL'|'PSO'|'PPO'} filterCondition 絞り込み条件
 * ALL: すべて
 * PSO: 自分の担当作品のみ表示
 * PPO: 自分の担当企画のみ表示
 * @property {string} labelApplicationNo 証紙申請書No※部分一致検索
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
