import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AniplexHeader } from "./AniplexHeader";
import { AniplexFooter } from "./AniplexFooter";
import '../../style/aniplex/import_aniplex.scss';
import { useDispatch, useSelector } from "react-redux";
import { popMessage, selectLatestMessage, selectReturnPath, setReturnPath } from "../../slices/aniplex/utilSlice";
import { MessagePopup } from "../common/MessagePopup";
import { useCallback, useEffect } from "react";
import { fetchPropertyMst, fetchCategoryMst, fetchRoyaltyPatternMst, fetchAniplexUserMst } from "../../slices/aniplex/masterSlice";
import { fetchMyself, selectMyself } from "../../slices/aniplex/usersSlice";
import { Config } from "../../config";
import { useState } from "react";

/**
 * Aniplex向け画面ラッパコンポーネント
 * @returns
 */
export const AniplexContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myself = useSelector(selectMyself);
  const returnPath = useSelector(selectReturnPath);

  // セッション確認済みフラグ
  const [hasSession, setHasSession] = useState(false);

  // セッション取得完了時の処理
  const onGetSession = useCallback(() => {
    setHasSession(true);
    // ログインユーザー詳細取得
    dispatch(fetchMyself());
    // マスタ系を取得する
    // TODO: ログイン判定は必要か？
    dispatch(fetchPropertyMst());
    dispatch(fetchCategoryMst());
    dispatch(fetchRoyaltyPatternMst());
    dispatch(fetchAniplexUserMst());

    if (returnPath != null) {
      // 復帰先パスが保存されている場合はそのパスへ移動して保存しているパスを削除
      dispatch(setReturnPath(null));
      navigate(returnPath);
    }
  }, [dispatch, navigate, returnPath]);

  // タイトルを設定値に上書き
  useEffect(() => {
    document.title = Config.SiteTitle;
  }, []);

  // マウント時にamplifyのセッションを取得
  useEffect(() => {
    let unmount = false;
    const f = async () => {
      let session = null;
      try {
        session = await Auth.currentSession();
      } catch {
        // 有効なセッションが存在しない場合はエラーになるので
        // 握りつぶすことでセッションがない状態で後続処理へ進む
      }
      if (unmount) {
        return;
      }
      if (!session && !Config.NoCognitoRedirect) {
        // 現在のパスを復帰先のパスとして保存
        dispatch(setReturnPath(location.pathname));
        // セッションがなければcognitoのログインフォームに遷移
        Auth.federatedSignIn({ provider: Config.AniplexProvider });
        return;
      }
      // セッションがあれば後続の処理を実行
      onGetSession();
    };
    if (!hasSession) {
      f();
    }
    return () => {
      unmount = true;
    };
  }, [dispatch, hasSession, location.pathname, onGetSession]);

  return (
    <div className="root root-aniplex">
      {/* HACK:ルーティング変更時のclass付替えの為、navigateをpropsに渡してrenderを発火させる */}
      <AniplexHeader
        username={myself?.employeeName}
        unusedNavigate={navigate}
      />
      <Outlet />
      <AniplexFooter />
      <AniplexMessagePopup />
    </div>
  );
}

/**
 * ANIPLEX向け画面のメッセージポップアップ
 */
function AniplexMessagePopup() {
  const dispatch = useDispatch();
  const message = useSelector(selectLatestMessage);

  if (message == null) {
    return null;
  }

  const onClose = () => {
    dispatch(popMessage());
  }

  return (
    <MessagePopup
      message={message}
      btn={{ ok: 'OK' }}
      btnClass="bg-aniplex"
      onClose={onClose} />
  )
}
