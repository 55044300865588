import dayjs from "dayjs";
import DatePicker from 'react-datepicker';
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../../lib/validator";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { getMessage } from "../../../lib/message";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { useDispatch, useSelector } from "react-redux";
import { clearApiStatus, selectApiStatus, updateSampleDate } from "../../../slices/licensee/proposalsSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { useFocusError } from "../../../lib/hooks/common";

/**
 * サンプル送付日登録ポップアップ
 * @param {object} props
 * @param {number} props.proposalId 企画内部コード
 * @param {Product[]} props.targets 対象の商品情報
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const SampleDatePopup = ({ proposalId, targets, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateSampleDate;
  // サンプル送付日
  const [sampleDate, setSampleDate] = useState('');
  // エラーメッセージ
  const [messages, setMessages] = useState({
    sampleDate: [],
  });

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['sampleDate']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.sampleDate.length > 0) {
      if (focusError('sampleDate')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.sampleDate.length, needFocusError]);

  /** サンプル送付日のDateオブジェクト */
  const sampleDateObj = useMemo(() => {
    if (isEmpty(sampleDate)) {
      return null;
    }

    return dayjs(sampleDate, 'YYYY/MM/DD').toDate();
  }, [sampleDate]);

  /** サンプル送付日変更時のハンドラ */
  const handleChange = useCallback((date) => {
    let value;
    if (date == null) {
      value = '';
    } else {
      value = dayjs(date).format('YYYY/MM/DD');
    }

    setMessages(prev => ({
      ...prev,
      sampleDate: validateSampleDate(value)
    }));
    setSampleDate(value);
  }, []);

  /** 登録ボタン押下時のコールバック */
  const onSubmit = useCallback(() => {
    const errors = {
      sampleDate: validateSampleDate(sampleDate),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      return;
    }

    // サンプル送付日登録APIを呼び出す
    const productList = targets.map(t => ({
      productId: t.productId,
      sampleDate,
      sampleRegDatetime: t.sampleRegDatetime,
    }));
    dispatch(updateSampleDate({
      proposalId,
      productList,
    }));
  }, [dispatch, proposalId, sampleDate, targets]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(clearApiStatus('updateSampleDate'));
      onClose('submit');
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('サンプル送付日の登録中にエラーが発生しました。'));
      dispatch(clearApiStatus('updateSampleDate'));
      return;
    }
  }, [apiStatus, dispatch, onClose]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup
        onClose={() => onClose('close')}>
        <h4 className="popup-title">サンプル送付日登録</h4>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required">サンプル送付日</dt>
            <dd className="form-body">
              <div className="input-form">
                <DatePicker
                  dateFormat='yyyy/MM/dd'
                  locale='ja'
                  ref={formRefs.current.sampleDate}
                  popperProps={{
                    strategy: 'fixed'
                  }}
                  disabled={formLocked}
                  selected={sampleDateObj}
                  onChange={date => handleChange(date)} />
              </div>
              <ErrorMessageList messages={messages.sampleDate} />
            </dd>
          </dl>
        </div>

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-pink">
            <button
              disabled={formLocked}
              onClick={onSubmit}
            >登録</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  );
}

/**
 * サンプル送付日をバリデートする
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateSampleDate(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  return errors;
}

//#region typedef
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン
 */
/**
 * @typedef {import('./ProposalStatusForm').Product} Product 商品情報
 */
//#endregion typedef
