import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { clearApiStatus, clearLicenseeDetail, fetchLicenseeDetail, updateLicensee, selectApiStatus, selectLicenseeDetail } from '../../../slices/aniplex/licenseesSlice';
import { LicenseeDetailForm } from './LicenseeDetailForm';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { selectMyself } from '../../../slices/aniplex/usersSlice';
import { Constants } from '../../../Constants';
import { pushMessage } from '../../../slices/aniplex/utilSlice';

/**
 * [A]取引先詳細画面
 * @returns
 */
export const LicenseeDetailPage = () => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const apiStatus = useSelector(selectApiStatus);
  const licenseeDetail = useSelector(selectLicenseeDetail);
  const routerParams = useParams();

  /** 編集可能なロールか? */
  const editable = useMemo(() => {
    if (myself == null) {
      return false;
    }
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
  }, [myself]);

  // 取引先詳細を取得
  useEffect(() => {
    if (routerParams.licenseeCode != null) {
      dispatch(fetchLicenseeDetail(routerParams.licenseeCode, dispatch));
    }
    return () => {
      dispatch(clearApiStatus());
      dispatch(clearLicenseeDetail());
    };
  }, [dispatch, routerParams.licenseeCode]);

  // API呼び出し完了時・エラー時の処理
  useEffect(() => {
    if (apiStatus.updateLicensee === 'finish') {
      dispatch(pushMessage('取引先情報を更新しました。'));
      dispatch(clearApiStatus('updateLicensee'));
      // 再読み込みする
      dispatch(fetchLicenseeDetail(routerParams.licenseeCode));
    }
  }, [dispatch, apiStatus, routerParams.licenseeCode]);

  /** フォームロックフラグ */
  const formLocked = useMemo(() => {
    return apiStatus.licenseeDetail === 'loading' || apiStatus.updateLicensee === 'loading';
  }, [apiStatus]);

  /** 送信時の処理 */
  const onSubmit = useCallback(formData => {
    dispatch(updateLicensee({
      licenseeCode: licenseeDetail.licenseeCode,
      params: formData,
    }));
  }, [dispatch, licenseeDetail]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return (apiStatus.licenseeDetail === 'loading' || apiStatus.updateLicensee === 'loading') ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">取引先詳細</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='../licenseeList'>
            <i className="icn pageback"></i>
            取引先一覧へ戻る
          </Link>
        </p>
      </div>
      <LicenseeDetailForm
        editable={editable}
        formLocked={formLocked}
        onSubmit={onSubmit}
        licensee={licenseeDetail} />

      {loading}

    </div>
  );
};
