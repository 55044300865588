//@ts-check
import React, { useMemo } from 'react';
import { ChangeStatusIcon } from '../common/ChangeStatusIcon';

/**
 * ロイヤリティ報告確証ファイル変更状態表示
 * @param {object} params
 * @param {RyProof[]} params.ryProofList ロイヤリティ報告確証ファイルのリスト
 * @param {RyProof[]|undefined} params.prevRyProofList 前バージョンのロイヤリティ報告確証ファイルのリスト
 */
export const RyProofChangeStatus = ({
  ryProofList,
  prevRyProofList,
}) => {
  const isChanged = useIsRyProofChanged({
    ryProofList,
    prevRyProofList,
  });

  return isChanged ? (
    <ChangeStatusIcon
      className='inline'
      iconClass="changed"
      tooltipText=''
      hideTooltip={true} />
  ) : null;
}

/**
 * 添付ファイルが変更されているかを判定する
 * @param {object} params
 * @param {RyProof[]} params.ryProofList ロイヤリティ報告確証ファイルのリスト
 * @param {RyProof[]|undefined} params.prevRyProofList 前バージョンのロイヤリティ報告確証ファイルのリスト
 */
const useIsRyProofChanged = ({
  ryProofList,
  prevRyProofList,
}) => {
  return useMemo(() => {
    if (prevRyProofList == null) {
      // 前バージョンが存在しない場合は変更なし扱い
      return false;
    }

    const addedList = ryProofList.filter(r => prevRyProofList.find(p => p.ryProofNo === r.ryProofNo) == null);
    const deletedList = prevRyProofList.filter(p => ryProofList.find(r => r.ryProofNo === p.ryProofNo) == null);

    return addedList.length !== 0 || deletedList.length !== 0;
  }, [prevRyProofList, ryProofList]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RyProof} RyProof
 */
//#endregion
