import { useCallback, useState } from 'react';
import { emptyToUndefined } from '../../../lib/listPageUtil';
import { statusListLicenseeUser } from '../../common/headerList';

/** 検索パラメーターの初期値 */
const defaultParams = {
  userId: '',
  username: '',
  mailaddress: '',
  licenseeCode: '',
  licenseeNameKanji: '',
  userStatus: '',
};

/**
 * [A]取引先一覧の検索フォーム
 * @returns
 */
export const LicenseeUserListSearchForm = ({ onSearch }) => {

  /** 検索パラメーター(入力途中) */
  const [params, setParams] = useState(defaultParams);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, []);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefinedにする)
    onSearch(emptyToUndefined(params));
  }, [params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    setParams(defaultParams);
    if (typeof onSearch !== 'function') {
      return;
    }
    onSearch();
  }, [onSearch]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '110px'}}>ユーザーID</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="userId" title="ユーザーIDを入力してください" aria-label="ユーザーID"
                value={params.userId}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">氏名</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="username" title="氏名を入力してください" aria-label="氏名"
                value={params.username}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">メールアドレス</dt>
          <dd className="form-body">
            <div className="input-form wdt500">
              <input type="email" name="mailaddress" title="メールアドレスを入力してください" aria-label="メールアドレス"
                value={params.mailaddress}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '110px'}}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeCode" title="取引先コードを入力してください" aria-label="取引先コード"
                value={params.licenseeCode}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="licenseeNameKanji" title="取引先名を入力してください" aria-label="取引先名"
                value={params.licenseeNameKanji}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="userStatus" title="ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.userStatus}>
                <option value=""></option>
                {Object.keys(statusListLicenseeUser).map(i => (
                  <option value={i} key={i}>{statusListLicenseeUser[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{width: '160px'}}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{width: '160px'}}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};
