import { HandledError } from './HandledError';

/**
 * エラー処理済みのAPIエラー
 */
export class HandledApiError extends HandledError {
  /** @type {import('./ApiError').ApiError} 元になったAPIエラー */
  orgError = null;

  /**
   * @param {import('./ApiError').ApiError} orgError 元になったAPIエラー
   */
  constructor (orgError) {
    super();
    this.name = this.constructor.name;
    this.orgError = orgError;
  }
}
