import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLicenseeUsers, udpateLicenseeUsers, postInvitation, checkUserAuth, postLicenseeUsers } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {LicenseeUsersDetail[]|null} ライセンシーユーザー一覧 */
  fetchUsers: null,
  /** @type {UserAuth} 招待ユーザー確認 */
  userAuth: null,

  /** API通信ステータス */
  apiStatus : {
    /** @type {ApiStatus} ライセンシーユーザー一覧 */
    fetchUsers: null,
    /** @type {ApiStatus} ライセンシーユーザー更新 */
    updateUser: null,
    /** @type {ApiStatus} ユーザー招待 */
    invitation: null,
    /** @type {ApiStatus} 招待ユーザー確認 */
    userAuth: null,
    /** @type {ApiStatus} ライセンシーユーザー登録 */
    registUser: null,
  }
}

/**
 * ライセンシーユーザー一覧取得
 */
 export const fetchUsers = createAsyncThunk(
  'licensee/licenseeUsers/fetchUsers',
  async (_, { dispatch }) => {
    try {
      const data = await getLicenseeUsers();
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '22901': ApiErrorMessage.UserListFailed,
      });
    }
  }
)

/**
 * ライセンシーユーザー更新
 */
 export const updateUser = createAsyncThunk(
  'licensee/licenseeUsers/updateUser',
  async ({userId, updateDatetime}, { dispatch }) => {
    const param = {
      deleteFlag: true,
      updateDatetime: updateDatetime,
    };
    try {
      const data = await udpateLicenseeUsers(userId, param);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '23001': ApiErrorMessage.DeleteStateMismatch,
        // 担当企画存在
        '23002': ApiErrorMessage.UserHasProposalForLicensee,
      });
    }
  }
)

/**
 * ユーザー招待処理
 */
export const invitation = createAsyncThunk(
  'licensee/licenseeUsers/invitation',
  async(params, { dispatch }) => {
    try {
      const data = await postInvitation(params);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        // TODO: エラーメッセージが未定
        '22601': ApiErrorMessage.SystemError,
        // データ重複
        '22602': ApiErrorMessage.DuplicatedUser,
        // メール送信失敗
        '22603': ApiErrorMessage.SystemError,
      });
    }
  }
)

/**
 * 招待ユーザー確認
 */
 export const userAuth = createAsyncThunk(
  'licensee/licenseeUsers/userAuth',
  async (invitationToken, { dispatch }) => {
    try {
      const data = await checkUserAuth(invitationToken);
      return data.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '22701': ApiErrorMessage.UserRegisterFailed,
      });
    }
  }
)

/**
 * ライセンシーユーザー登録
 */
 export const registUser = createAsyncThunk(
  'licensee/licenseeUsers/registUser',
  async ({params, invitationToken}, { dispatch }) => {
    try {
      const data = await postLicenseeUsers(params, invitationToken);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '22801': ApiErrorMessage.UserRegisterFailed,
      });
    }
  }
)

/**
 * ライセンシー向け画面のユーザー招待周りのスライス
 */
export const licenseeUsersSlice = createSlice({
  name: 'licensee/licenseeUsers',
  initialState,
  reducers: {
    /**
     * 取得済みのライセンシーユーザー一覧情報をクリアする
     * @param {typeof initialState} state
     */
    clearFetchUsers: (state) => {
      state.fetchUsers = null;
    },
    /**
     * 取得済みの招待ユーザー情報をクリアする
     * @param {typeof initialState} state
     */
    clearUserAuth: (state) => {
      state.userAuth = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        case 'fetchUsers':
          state.apiStatus.fetchUsers = null;
          break;
        case 'updateUser':
          state.apiStatus.updateUser = null;
          break;
        case 'invitation':
          state.apiStatus.invitation = null;
          break;
        case 'userAuth':
          state.apiStatus.userAuth = null;
          break;
        case 'registUser':
          state.apiStatus.registUser = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ライセンシーユーザー一覧取得処理
      .addCase(fetchUsers.pending, (state) => {
        state.apiStatus.fetchUsers = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.apiStatus.fetchUsers = 'finish';
        state.fetchUsers = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchUsers = null;
      })
      // ライセンシーユーザー更新処理
      .addCase(updateUser.pending, (state) => {
        state.apiStatus.updateUser = 'loading';
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.apiStatus.updateUser = 'finish';
      })
      .addCase(updateUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateUser = null;
      })
      // ユーザー招待処理
      .addCase(invitation.pending, (state) => {
        state.apiStatus.invitation = 'loading';
      })
      .addCase(invitation.fulfilled, (state) => {
        state.apiStatus.invitation = 'finish';
      })
      .addCase(invitation.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.invitation = null;
      })
      // 招待ユーザー情報取得処理
      .addCase(userAuth.pending, (state) => {
        state.apiStatus.userAuth = 'loading';
      })
      .addCase(userAuth.fulfilled, (state, action) => {
        state.apiStatus.userAuth = 'finish';
        state.userAuth = action.payload;
      })
      .addCase(userAuth.rejected, (state) => {
        state.apiStatus.userAuth = 'error';
      })
      // ライセンシーユーザー登録処理
      .addCase(registUser.pending, (state) => {
        state.apiStatus.registUser = 'loading';
      })
      .addCase(registUser.fulfilled, (state) => {
        state.apiStatus.registUser = 'finish';
      })
      .addCase(registUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.registUser = null;
      });
  }
});

export const { clearApiStatus, clearFetchUsers, clearUserAuth } = licenseeUsersSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.licenseeUsers.apiStatus;

/**
 * ライセンシーユーザー一覧を取得する
 * @param {*} state
 * @returns {{result?: { data?: LicenseeUsersDetail[] }}|null}
 */
export const fetchUserList = (state) => state.licensee.licenseeUsers.fetchUsers;

/**
 * 招待ユーザー情報を取得する
 * @param {*} state
 * @returns {UserAuth|null}
 */
export const fetchUserAuth = (state) => state.licensee.licenseeUsers.userAuth;

export default licenseeUsersSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchUsers'|'updateUser'|'invitation'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} LicenseeUsersDetail ライセンシーユーザー一覧APIの結果詳細
 * @property {string} userId ユーザーID
 * @property {string} mailaddress メールアドレス
 * @property {string} department 部署名
 * @property {'INV' | 'REG'} userStatus ユーザ－ステータス
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} loginDatetime 最終ログイン日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} UpdateLicenseeUsersParam ライセンシーユーザー更新APIパラメータ
 * @property {boolean} deleteFlag 削除フラグ
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} UpdateLicenseeUsersResponse ライセンシーユーザー更新API結果
 * @property {string} userId ユーザーID
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PostInvitationParam ユーザー招待APIパラメータ
 * @property {string} mailaddress メールアドレス
 */
/**
 * @typedef {object} PostInvitationResponse ユーザー招待API結果
 */
/**
 * @typedef {object} UserAuth ユーザー招待API結果
 * @property {string} mailaddress メールアドレス
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} RegistUserParam ライセンシーユーザー登録APパラメータ
 * @property {string} username 氏名
 * @property {string} department 部署名
 * @property {string} password パスワード
 */
//#endregion
