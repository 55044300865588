import React, { useCallback, useMemo, useState } from "react"
import { comma3 } from "../../lib/util";
import { isNumber } from "../../lib/validator";
import { NumberInput } from "./NumberInput";

/**
 * blur時に自動で3桁カンマ編集をかける入力部品
 * @param {object} params
 * @param {*} params.value inputに設定する値
 * @param {React.MutableRefObject} params.inputRef input要素に設定するref
 * @param {number} [params.decimals] 小数部の桁数
 * @param {Function} [params.onBlur] blur時のコールバック
 * @param {Function} [params.onFocus] focus時のコールバック
 * @param {(val: string) => void} [params.onChange] 値変更時のハンドラ
 * @param {React.InputHTMLAttributes} params.props inputタグに引き渡すプロパティ
 */
export const CommaInput = ({value, inputRef, decimals, onBlur, onFocus, onChange, ...props}) => {
  // フォーカス中フラグ
  const [isFocused, setIsFocused] = useState(false);
  /** カンマ表示用内部値 */
  const innerValue = useMemo(() => {
    if (isFocused) {
      return value;
    } else {
      return comma3(value);
    }
  }, [isFocused, value]);

  /** blur時のハンドラ */
  const myOnBlur = useCallback((ev) => {
    setIsFocused(false);
    if (typeof onChange === 'function') {
      onChange(formatNumberOnBlur(value));
    }
    if (typeof onBlur === 'function') {
      onBlur(ev);
    }
  }, [onBlur, onChange, value]);

  /** focus時のハンドラ */
  const myOnFocus = useCallback((ev) => {
    setIsFocused(true);
    if (typeof onFocus === 'function') {
      onFocus(ev);
    }
  }, [onFocus]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((val) => {
    // 数値形式に整形
    val = formatNumber(val);

    if (isNumber(decimals)) {
      val = formatDecimal(val, Number(decimals));
    }
    if (typeof onChange === 'function') {
      onChange(val);
    }
  }, [decimals, onChange]);

  return (
    <NumberInput
      inputRef={inputRef}
      value={innerValue}
      extraChars={[',']}
      onBlur={myOnBlur}
      onFocus={myOnFocus}
      onChange={handleChange}
      {...props} />
  )
}

/**
 * 入力された値を数値の形式に整形する
 * @param {string} value 入力された値
 * @returns {string} 整形後の値
 */
function formatNumber(value) {
  // 数字以外が入力されている場合は除去
  value = value.replace(/[^\d.]/g, '');

  // 小数点が先頭にある場合は除去
  value = value.replace(/^\./, '');

  // 小数点を複数含む場合は一番左の小数点のみ残す
  if (value.match(/\./g)?.length > 1) {
    const [first, ...remain] = value.split('.');
    value = first + '.' + remain.join('');
  }

  return value;
}

/**
 * blur時にかける数値の整形処理
 * @param {string} value 入力された値
 * @param {string} 整形後の値
 */
function formatNumberOnBlur(value) {
  if (value != null && typeof value !== 'string') {
    value = String(value);
  }

  // 先頭にある0は除去
  value = value?.replace(/^0+(?=0|[1-9]\d*)/, '') ?? '';

  // 小数部の末尾の0は除去
  const [intPart, decPart] = value.split('.');
  if (decPart !== undefined) {
    const decOmitted = decPart.replace(/0+$/, '');
    value = intPart + (decOmitted.length ? '.' + decOmitted : '');
  }

  return value;
}

/**
 * 入力された値の小数部を整形する
 * @param {string} value 入力された値
 * @param {number} decimals 小数部の桁数
 * @returns {string} 整形後の値
 */
function formatDecimal(value, decimals) {
  // 数値形式でない場合は処理しない
  if (isNaN(Number(value))) {
    return value;
  }

  // 小数部の入力制限
  if (decimals === 0 && value.includes('.')) {
    const [valInt] = value.split('.');
    return valInt;
  }

  const [valInt, valDecimal] = value.split('.');
  if (valDecimal !== undefined && valDecimal.length > decimals) {
    return valInt + '.' + valDecimal.substring(0, decimals);
  }

  return value;
}
