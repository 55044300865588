import axios from 'axios';
import { ApiError } from './errors/ApiError';

/**
 * APIに対してリクエストを送信するための基底処理
 * @param {string} url 送信先APIのURL
 * @param {RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {*} option axiosのオプション
 * @returns {Promise<SendResult>} APIのレスポンスボディ
 */
export async function axiosSend(url, method, data, option) {
  const config = { ...option, url, method };
  if (method === 'GET' || method === 'DELETE') {
    // GETおよびDELETEではbodyがつけられないのでクエリパラメータに設定
    config.params = data;
  } else {
    config.data = data;
  }

  let result;
  try {
    result = await axios(config);
  } catch (err) {
    result = err.response;
  } finally {
    checkResponse(result);
  }

  return {
    data: result?.data,
    status: result?.status,
  };
}

/**
 * APIレスポンスが正常値か判定する
 * @param {import('axios').AxiosResponse} response APIレスポンス
 * @returns {true} 正常値の場合はtrue
 * @throws {import('./errors/ApiError.js').ApiError} APIエラー
 */
function checkResponse(response) {
  let statusCode = Number(response?.status ?? null);

  let isSuccess = true
  if (isNaN(statusCode)) {
    statusCode = 0;
    // ステータスコードが数値以外の場合はエラー
    isSuccess = false;
  }

  if (Math.floor(statusCode / 100) !== 2) {
    // 200番台以外はエラー
    isSuccess = false;
  }

  if (response?.data?.status !== 'SUCCESS') {
    // APIが成功以外を返したらエラー
    isSuccess = false;
  }

  if (!isSuccess) {
    throw new ApiError(response);
  }

  return true;
}

//#region typedef
/**
 * @typedef {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'} RequestMethod APIのリクエストメソッド
 */
/**
 * @typedef {object} SendResult APIリクエスト処理結果
 * @property {number} status レスポンスのHTTPステータスコード
 * @property {SendResultData} data レスポンスボディ
 */
/**
 * @typedef {object} SendResultData APIリクエスト処理結果のボディ部
 * @property {object} result データ部
 */
//#endregion typedef
