import { useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { Constants } from '../../../Constants';
import { useFormData } from '../../../lib/hooks/common';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { getMessage } from '../../../lib/message';
import { SafeCharTextArea } from '../../common/SafeCharTextArea';

/**
 * [A]取引先詳細画面のフォーム部分
 * @returns
 */
export const LicenseeDetailForm = ({ editable, formLocked, licensee, onSubmit }) => {

  /** フォーム入力値 */
  const [formData, handleFormDataChange, errors] = useFormData(() => ({
    kihonkeiyakuFlag: false,
    kihonkeiyakuDate: '',
    assessment: '',
    licenseeRemarks: '',
    priorityUse: false,
    detailUpdateDatetime: '',
  }), validate, false);

  // APIから取得した取引先詳細情報をフォームに反映
  useEffect(() => {
    if (!licensee) {
      return;
    }
    handleFormDataChange('kihonkeiyakuFlag', licensee.kihonkeiyakuFlag);
    const kihonkeiyakuDate = licensee.kihonkeiyakuDate ? (
      dayjs(licensee.kihonkeiyakuDate).toDate()
    ) : '';
    handleFormDataChange('kihonkeiyakuDate', kihonkeiyakuDate);
    handleFormDataChange('assessment', licensee.assessment || '');
    handleFormDataChange('licenseeRemarks', licensee.licenseeRemarks || '');
    handleFormDataChange('priorityUse', licensee.priorityUse);
    handleFormDataChange('detailUpdateDatetime', licensee.detailUpdateDatetime || '');
  }, [licensee, handleFormDataChange]);

  /** 非活性フラグ */
  const disabled = useMemo(() => {
    return !editable || formLocked;
  }, [editable, formLocked]);

  /** 更新ボタン押下時の処理 */
  const onClick = useCallback(() => {
    if (!editable) {
      return;
    }
    if (typeof onSubmit !== 'function') {
      return;
    }
    const kihonkeiyakuDate = formData.kihonkeiyakuDate ? (
      dayjs(formData.kihonkeiyakuDate).format('YYYY/MM/DD')
    ) : null;
    onSubmit({
      ...formData,
      assessment: formData.assessment || null,
      licenseeRemarks: formData.licenseeRemarks || null,
      kihonkeiyakuDate,
    });
  }, [editable, formData, onSubmit]);

  return (
    <>
      {/* 入力のみの項目ここから */}
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeCode" title="取引先コードは入力済みです" aria-label="取引先コード"
                value={licensee?.licenseeCode || ''} disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeNameKanji" title="取引先名は入力済みです" disabled aria-label="取引先名"
                value={licensee?.licenseeNameKanji || ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名カナ</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeNameKana" title="取引先名カナは入力済みです" disabled aria-label="取引先名"
                value={licensee?.licenseeNameKana || ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>郵便番号</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="zipCode" title="郵便番号は入力済みです" aria-label="郵便番号"
                onChange={ev => handleFormDataChange(ev.target.name, ev.target.value)}
                value={licensee?.zipCode || ''} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>住所</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="address" title="住所は入力済みです" aria-label="住所"
                onChange={ev => handleFormDataChange(ev.target.name, ev.target.value)}
                value={licensee?.address || ''} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>電話番号</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="tel" name="phone" title="電話番号は入力済みです" aria-label="電話番号"
                onChange={ev => handleFormDataChange(ev.target.name, ev.target.value)}
                value={licensee?.phone || ''} disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">FAX</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="tel" name="fax" title="FAXは入力済みです" aria-label="FAX"
                onChange={ev => handleFormDataChange(ev.target.name, ev.target.value)}
                value={licensee?.fax || ''} disabled />
            </div>
          </dd>
        </dl>
      </div>
      {/* 入力のみの項目ここまで */}

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>基本契約締結</dt>
          <dd className="form-body">
            <fieldset>
              <legend>基本契約締結</legend>
              <ul className="list-form ml0">
              <li className="ml0">
                <input type="checkbox" id="check-01" name="kihonkeiyakuFlag" value="true"
                  onChange={ev => handleFormDataChange(ev.target.name, ev.target.checked)}
                  checked={formData.kihonkeiyakuFlag}
                  disabled={disabled} />
                <label htmlFor="check-01" className="form-checkbox"></label>
              </li>
              </ul>
            </fieldset>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">基本契約締結日</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name="kihonkeiyakuDate"
                title="基本契約締結日を入力してください"
                aria-label="基本契約締結日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={formData.kihonkeiyakuDate}
                onChange={date => handleFormDataChange('kihonkeiyakuDate', date)}
                disabled={disabled} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>優先利用</dt>
          <dd className="form-body">
            <fieldset>
              <legend>優先利用</legend>
              <ul className="list-form ml0">
                <li className="ml0">
                  <input type="checkbox" id="check-02" name="priorityUse" value="優先利用"
                    onChange={ev => handleFormDataChange(ev.target.name, ev.target.checked)}
                    checked={formData.priorityUse}
                    disabled={disabled} />
                  <label htmlFor="check-02" className="form-checkbox"></label>
                </li>
              </ul>
            </fieldset>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>評価</dt>
          <dd className="form-body">
            <div className="form-select wdt100">
              <select name="assessment" title="評価を選択してください"
                onChange={ev => handleFormDataChange(ev.target.name, ev.target.value)}
                value={formData.assessment}
                disabled={disabled}>
                <option value=""></option>
                {Object.keys(Constants.Aniplex.licenseeAssessmentName).map(s => (
                  <option key={s} value={s}>{Constants.Aniplex.licenseeAssessmentName[s]}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>備考</dt>
          <dd className="form-body">
            <div className="form-textarea wdt1200">
              <SafeCharTextArea rows="4" name="licenseeRemarks" title="備考を入力してください" aria-label="備考"
                onChange={val => handleFormDataChange('licenseeRemarks', val)}
                maxLength="500"
                value={formData.licenseeRemarks}
                disabled={disabled} />
            </div>
          </dd>
        </dl>
      </div>
      <ErrorMessageList messages={errors?.licenseeRemarks ?? []} />

      {Boolean(editable) && (
        <div className="l-buttons mt20">
          <p className="btn bg-yellow" style={{width: '200px'}}>
            <button onClick={onClick} disabled={disabled}>取引先詳細を更新</button>
          </p>
        </div>
      )}
    </>
  );
};

/** バリデーション */
const validate = (prop, value, formData) => {
  switch (prop) {
    case 'licenseeRemarks':
      const errors = [];
      // maxLengthをかけているので実際には表示されない想定
      if (value.length > 500) {
        errors.push(getMessage('maxLength', { max: 500 }));
      }
      return errors;
    default:
      return [];
  }
};
