import React, { useCallback, useEffect, useMemo, useState } from "react"
import { getMessage } from "../../../lib/message";
import { isEmpty, isNumber, maxLength, valueRange } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { NumberInput } from "../../common/NumberInput";
import { SafeCharTextArea } from "../../common/SafeCharTextArea";

/** このフォームで対象とするプロパティ */
const targetProps = [
  'shareWeb',
  'shareWholesale',
  'shareOwnEcFlag',
  'shareOwnEc',
  'shareOtherEc',
  'shareAnimation',
  'shareKaden',
  'shareCvs',
  'shareGms',
  'shareSm',
  'shareDrug',
  'shareHobby',
  'shareOnline',
  'shareOther',
  'shareWholesaleRemarks',
  'target',
  'promotionChannel',
  'promotionUser',
  'promotionWeb',
  'promotionTwitter',
  'promotionDisplay',
];

/**
 * 企画書申請画面のアンケートフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject<{}>} props.formRefs フォーム項目へのref
 * @param {OnChange} props.onChange フォーム内容更新時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @returns
 */
export const ProposalSurveyForm = ({ data, formLocked, formRefs, onChange, onValidated, validateAllFlg = false }) => {
  const [messages, setMessages] = useState({
    /** WEB通販比率 */
    shareWeb: [],
    /** 卸売比率 */
    shareWholesale: [],
    /** 自社EC有無 */
    shareOwnEcFlag: [],
    /** Web通販比率_自社EC */
    shareOwnEc: [],
    /** Web通販比率_他社EC */
    shareOtherEc: [],
    /** 卸売比率_アニメ専門店 */
    shareAnimation: [],
    /** 卸売比率_家電量販店 */
    shareKaden: [],
    /** 卸売比率_CVS */
    shareCvs: [],
    /** 卸売比率_GMS */
    shareGms: [],
    /** 卸売比率_スーパーマーケット */
    shareSm: [],
    /** 卸売比率_ドラッグストア */
    shareDrug: [],
    /** 卸売比率_ホビー */
    shareHobby: [],
    /** 卸売比率_オンライン（くじ、プライズ、電子書籍） */
    shareOnline: [],
    /** 卸売比率_その他 */
    shareOther: [],
    /** 卸売備考（流通先名、その他内訳） */
    shareWholesaleRemarks: [],
    /** メイン・ターゲット */
    target: [],
    /** 対流通施策 */
    promotionChannel: [],
    /** 対ユーザー施策 */
    promotionUser: [],
    /** WEB対策 */
    promotionWeb: [],
    /** Twitter情報 */
    promotionTwitter: [],
    /** 展示会・カタログ情報 */
    promotionDisplay: [],
  });

  /** @type {FormData} フォームデータ */
  const formData = useMemo(() => {
    return {
      /** WEB通販比率 */
      shareWeb: '',
      /** 卸売比率 */
      shareWholesale: '',
      /** 自社EC有無 */
      shareOwnEcFlag: false,
      /** Web通販比率_自社EC */
      shareOwnEc: '',
      /** Web通販比率_他社EC */
      shareOtherEc: '',
      /** 卸売比率_アニメ専門店 */
      shareAnimation: '',
      /** 卸売比率_家電量販店 */
      shareKaden: '',
      /** 卸売比率_CVS */
      shareCvs: '',
      /** 卸売比率_GMS */
      shareGms: '',
      /** 卸売比率_スーパーマーケット */
      shareSm: '',
      /** 卸売比率_ドラッグストア */
      shareDrug: '',
      /** 卸売比率_ホビー */
      shareHobby: '',
      /** 卸売比率_オンライン（くじ、プライズ、電子書籍） */
      shareOnline: '',
      /** 卸売比率_その他 */
      shareOther: '',
      /** 卸売備考（流通先名、その他内訳） */
      shareWholesaleRemarks: '',
      /** メイン・ターゲット */
      target: '',
      /** 対流通施策 */
      promotionChannel: '',
      /** 対ユーザー施策 */
      promotionUser: '',
      /** WEB対策 */
      promotionWeb: '',
      /** Twitter情報 */
      promotionTwitter: '',
      /** 展示会・カタログ情報 */
      promotionDisplay: '',
      ...data,
    };
  }, [data]);

  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っているときは全項目をバリデートする
      const newMessages = {};
      targetProps.forEach(prop => {
        let value = formData[prop];
        const errors = validate(prop, value);
        onValidated(prop, errors);
        newMessages[prop] = errors.apply;
      });
      setMessages(prevMessages => ({
        ...prevMessages,
        ...newMessages,
      }));
    }
  }, [formData, onValidated, validateAllFlg]);

  /**
   * フォーム更新ハンドラ
   * @type {(name: keyof FormData, value: *) => void}
   */
  const handleChange = useCallback((name, value) => {
    const errors = validate(name, value);
    onValidated(name, errors);
    setMessages(prevMessages => ({
      ...prevMessages,
      [name]: errors.apply,
    }));
    onChange(name, value);
  }, [onChange, onValidated]);

  /**
   * シェア率の項目フォーカスアウト時のハンドラ
   * @type {(name: keyof FormData) => void}
   */
  const onShareBlur = useCallback(name => {
    if (isEmpty(formData[name])) {
      // 入力内容が空の場合は0を入れる
      handleChange(name, '0');
    }
  }, [formData, handleChange]);

  return (
    <>
      <section className="mt40">
        <div className="title-pink">
          <h2 className="title"><i className="icn store"></i>おおまかな流通シェアを教えてください</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name required">販売経路【流通先のシェアを教えてください】</dt>
            <dd className="ml15">
              <div className="dif a-center">
                <p>WEB通販</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="WEB通販"
                    title="WEB通販の％を入力してください"
                    aria-label="WEB通販"
                    inputRef={formRefs.current.shareWeb}
                    disabled={formLocked}
                    value={formData.shareWeb ?? ''}
                    onBlur={() => onShareBlur('shareWeb')}
                    onChange={val => handleChange('shareWeb', val)} />
                </div>
                <p>%　／　卸売</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="卸売"
                    title="卸売の％を入力してください"
                    aria-label="卸売"
                    inputRef={formRefs.current.shareWholesale}
                    disabled={formLocked}
                    value={formData.shareWholesale ?? ''}
                    onBlur={() => onShareBlur('shareWholesale')}
                    onChange={val => handleChange('shareWholesale', val)} />
                </div>
                <p>%</p>
              </div>
              <ErrorMessageList messages={messages.shareWeb} />
              <ErrorMessageList messages={messages.shareWholesale} />
            </dd>
          </dl>

          <dl className="pt10 mt20 bt-dashed-gray">
            <dt className="form-name required">WEB通販</dt>
            <dd className="ml15">
              <p>
                <input type="checkbox"
                  id="check-web"
                  name="自社EC"
                  title="自社EC"
                  disabled={formLocked}
                  checked={formData.shareOwnEcFlag ?? false}
                  onChange={ev => handleChange('shareOwnEcFlag', ev.target.checked)} />
                <label htmlFor="check-web" className="form-checkbox">自社ECを保有している場合はチェックをお願いします</label>
              </p>
              <div className="dif a-center">
                <p>市場シェアを教えてください。</p>
                <p>自社EC</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="自社EC"
                    title="自社ECの％を入力してください"
                    aria-label="自社EC"
                    inputRef={formRefs.current.shareOwnEc}
                    disabled={formLocked}
                    value={formData.shareOwnEc ?? ''}
                    onBlur={() => onShareBlur('shareOwnEc')}
                    onChange={val => handleChange('shareOwnEc', val)} />
                </div>
                <p>%　／　他社EC</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="他社EC"
                    title="他社ECの％を入力してください"
                    aria-label="他社EC"
                    inputRef={formRefs.current.shareOtherEc}
                    disabled={formLocked}
                    value={formData.shareOtherEc ?? ''}
                    onBlur={() => onShareBlur('shareOtherEc')}
                    onChange={val => handleChange('shareOtherEc', val)} />
                </div>
                <p>%</p>
              </div>
              <ErrorMessageList messages={messages.shareOwnEc} />
              <ErrorMessageList messages={messages.shareOtherEc} />
            </dd>
          </dl>

          <dl className="pt10 mt20 bt-dashed-gray">
            <dt className="form-name required">卸売【市場シェア率を教えてください】</dt>
            <dd className="ml15">
              <div className="dif a-center">
                <p>アニメ専門店</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="アニメ専門店"
                    title="アニメ専門店の％を入力してください"
                    aria-label="アニメ専門店"
                    inputRef={formRefs.current.shareAnimation}
                    disabled={formLocked}
                    value={formData.shareAnimation ?? ''}
                    onBlur={() => onShareBlur('shareAnimation')}
                    onChange={val => handleChange('shareAnimation', val)} />
                </div>
                <p>%　／　家電量販店</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="家電量販店"
                    title="家電量販店の％を入力してください"
                    aria-label="家電量販店"
                    inputRef={formRefs.current.shareKaden}
                    disabled={formLocked}
                    value={formData.shareKaden ?? ''}
                    onBlur={() => onShareBlur('shareKaden')}
                    onChange={val => handleChange('shareKaden', val)} />
                </div>
                <p>%　／　CVS</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="CVS"
                    title="CVSの％を入力してください"
                    aria-label="CVS"
                    inputRef={formRefs.current.shareCvs}
                    disabled={formLocked}
                    value={formData.shareCvs ?? ''}
                    onBlur={() => onShareBlur('shareCvs')}
                    onChange={val => handleChange('shareCvs', val)} />
                </div>
                <p>%　／　GMS</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="GMS"
                    title="GMSの％を入力してください"
                    aria-label="GMS"
                    inputRef={formRefs.current.shareGms}
                    disabled={formLocked}
                    value={formData.shareGms ?? ''}
                    onBlur={() => onShareBlur('shareGms')}
                    onChange={val => handleChange('shareGms', val)} />
                </div>
                <p>%　／　スーパーマーケット</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="スーパーマーケット"
                    title="スーパーマーケットの％を入力してください"
                    aria-label="スーパーマーケット"
                    inputRef={formRefs.current.shareSm}
                    disabled={formLocked}
                    value={formData.shareSm ?? ''}
                    onBlur={() => onShareBlur('shareSm')}
                    onChange={val => handleChange('shareSm', val)} />
                </div>
                <p>%　／　ドラッグストア</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="ドラッグストア"
                    title="ドラッグストアの％を入力してください"
                    aria-label="ドラッグストア"
                    inputRef={formRefs.current.shareDrug}
                    disabled={formLocked}
                    value={formData.shareDrug ?? ''}
                    onBlur={() => onShareBlur('shareDrug')}
                    onChange={val => handleChange('shareDrug', val)} />
                </div>
                <p>%　／　ホビー</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="ホビー"
                    title="ホビーの％を入力してください"
                    aria-label="ホビー"
                    inputRef={formRefs.current.shareHobby}
                    disabled={formLocked}
                    value={formData.shareHobby ?? ''}
                    onBlur={() => onShareBlur('shareHobby')}
                    onChange={val => handleChange('shareHobby',val)} />
                </div>
                <p>%</p>
              </div>
              <div className="dif a-center mt10">
                <p>オンライン※くじ、プライズ、電子書籍</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="オンライン※くじ、プライズ、電子書籍"
                    title="オンライン※くじ、プライズ、電子書籍の％を入力してください"
                    aria-label="オンライン※くじ、プライズ、電子書籍"
                    inputRef={formRefs.current.shareOnline}
                    disabled={formLocked}
                    value={formData.shareOnline ?? ''}
                    onBlur={() => onShareBlur('shareOnline')}
                    onChange={val => handleChange('shareOnline', val)} />
                </div>
                <p>%　／　その他</p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <NumberInput type="text"
                    pattern="\d{1,3}"
                    maxLength="3"
                    name="その他"
                    title="その他の％を入力してください"
                    aria-label="その他"
                    inputRef={formRefs.current.shareOther}
                    disabled={formLocked}
                    value={formData.shareOther ?? ''}
                    onBlur={() => onShareBlur('shareOther')}
                    onChange={val => handleChange('shareOther', val)} />
                </div>
                <p>%</p>
              </div>
              <ErrorMessageList messages={messages.shareAnimation} />
              <ErrorMessageList messages={messages.shareKaden} />
              <ErrorMessageList messages={messages.shareCvs} />
              <ErrorMessageList messages={messages.shareGms} />
              <ErrorMessageList messages={messages.shareSm} />
              <ErrorMessageList messages={messages.shareDrug} />
              <ErrorMessageList messages={messages.shareHobby} />
              <ErrorMessageList messages={messages.shareOnline} />
              <ErrorMessageList messages={messages.shareOther} />
            </dd>
          </dl>

          <dl>
            <dt className="form-name no-style required">流通限定施策の場合は具体的な流通先名を、また、その他の場合は内訳をご記入ください。</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="市場シェア率"
                  title="流通限定施策の場合は具体的な流通先名を、また、その他の場合は内訳をご記入ください。"
                  aria-label="市場シェア率"
                  ref={formRefs.current.shareWholesaleRemarks}
                  disabled={formLocked}
                  value={formData.shareWholesaleRemarks}
                  onChange={val => handleChange('shareWholesaleRemarks', val)} />
              </div>
              <ErrorMessageList messages={messages.shareWholesaleRemarks} />
              <span className="attention">※卸売が無い場合は「無し」と記載をお願いします。</span>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-pink">
          <h2 className="title"><i className="icn user_pink"></i>契約希望商品のメイン・ターゲット</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name required">商品のメイン・ターゲットは誰ですか？（年齢層、性別、購買目的、消費動向などのイメージ）</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="契約希望商品のメイン・ターゲット"
                  title="商品のメイン・ターゲットは誰ですか？（年齢層、性別、購買目的、消費動向などのイメージ）"
                  aria-label="契約希望商品のメイン・ターゲット"
                  ref={formRefs.current.target}
                  disabled={formLocked}
                  value={formData.target}
                  onChange={val => handleChange('target', val)} />
              </div>
              <ErrorMessageList messages={messages.target} />
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-pink">
          <h2 className="title"><i className="icn lightbulb"></i>契約希望商品のための販促施策</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name required">商品を店頭にならべるために、どのような働きかけをされますか？（対流通施策）</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="契約希望商品のための販促施策（対流通施策）"
                  title="商品を店頭にならべるために、どのような働きかけをされますか？（対流通施策）"
                  aria-label="契約希望商品のための販促施策（対流通施策）"
                  ref={formRefs.current.promotionChannel}
                  disabled={formLocked}
                  value={formData.promotionChannel}
                  onChange={val => handleChange('promotionChannel', val)} />
              </div>
              <ErrorMessageList messages={messages.promotionChannel} />
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name required">店頭でお客様に手にとってもらえるために、どのような働きかけをされますか？（対ユーザー施策）</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="契約希望商品のための販促施策（対ユーザー施策）"
                  title="店頭でお客様に手にとってもらえるために、どのような働きかけをされますか？（対ユーザー施策）"
                  aria-label="契約希望商品のための販促施策（対ユーザー施策）"
                  ref={formRefs.current.promotionUser}
                  disabled={formLocked}
                  value={formData.promotionUser}
                  onChange={val => handleChange('promotionUser', val)} />
              </div>
              <ErrorMessageList messages={messages.promotionUser} />
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name required">想定しうるWEBでのプロモーション施策を教えて下さい。</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="WEBでのプロモーション施策"
                  title="想定しうるWEBでのプロモーション施策を教えて下さい。"
                  aria-label="WEBでのプロモーション施策"
                  ref={formRefs.current.promotionWeb}
                  disabled={formLocked}
                  value={formData.promotionWeb}
                  onChange={val => handleChange('promotionWeb', val)} />
              </div>
              <ErrorMessageList messages={messages.promotionWeb} />
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name required">自社X（旧Twitter）の有無を教えて下さい。お持ちの場合はアカウント名の記載をお願いします。</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="自社X（旧Twitter）の有無"
                  title="自社X（旧Twitter）の有無を教えて下さい。お持ちの場合はアカウント名の記載をお願いします。"
                  aria-label="自社X（旧Twitter）の有無"
                  ref={formRefs.current.promotionTwitter}
                  disabled={formLocked}
                  value={formData.promotionTwitter}
                  onChange={val => handleChange('promotionTwitter', val)} />
              </div>
              <ErrorMessageList messages={messages.promotionTwitter} />
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name required">展示会、商談会等への出展予定があればご記入ください。</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <SafeCharTextArea rows="4"
                  name="出展予定"
                  title="展示会、商談会等への出展予定があればご記入ください。"
                  aria-label="出展予定"
                  ref={formRefs.current.promotionDisplay}
                  disabled={formLocked}
                  value={formData.promotionDisplay}
                  onChange={val => handleChange('promotionDisplay', val)} />
              </div>
              <ErrorMessageList messages={messages.promotionDisplay} />
            </dd>
          </dl>
        </div>
      </section>
    </>
  )
}

/**
 * フォーム入力内容をバリデートする
 * @param {keyof FormData} name 入力された項目名
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(name, value) {
  switch (name) {
    // WEB通販比率
    case 'shareWeb':
      return validateShareWeb(value);
    // 卸売比率
    case 'shareWholesale':
      return validateShareWholesale(value);
    // Web通販比率_自社EC
    case 'shareOwnEc':
      return validateShareOwnEc(value);
    // Web通販比率_他社EC
    case 'shareOtherEc':
      return validateShareOtherEc(value);
    // 卸売比率_アニメ専門店
    case 'shareAnimation':
      return validateShareAnimation(value);
    // 卸売比率_家電量販店
    case 'shareKaden':
      return validateShareKaden(value);
    // 卸売比率_CVS
    case 'shareCvs':
      return validateShareCvs(value);
    // 卸売比率_GMS
    case 'shareGms':
      return validateShareGms(value);
    // 卸売比率_スーパーマーケット
    case 'shareSm':
      return validateShareSm(value);
    // 卸売比率_ドラッグストア
    case 'shareDrug':
      return validateShareDrug(value);
    // 卸売比率_ホビー
    case 'shareHobby':
      return validateShareHobby(value);
    // 卸売比率_オンライン
    case 'shareOnline':
      return validateShareOnline(value);
    // 卸売比率_その他
    case 'shareOther':
      return validateShareOther(value);
    // 卸売備考（流通先名、その他内訳）
    case 'shareWholesaleRemarks':
      return validateShareWholesaleRemarks(value);
    // メイン・ターゲット
    case 'target':
      return validateTarget(value);
    // 対流通施策
    case 'promotionChannel':
      return validatePromotionChannel(value);
    // 対ユーザー施策
    case 'promotionUser':
      return validatePromotionUser(value);
    // WEB対策
    case 'promotionWeb':
      return validatePromotionWeb(value);
    // Twitter対策
    case 'promotionTwitter':
      return validatePromotionTwitter(value);
    // 展示会・カタログ情報
    case 'promotionDisplay':
      return validatePromotionDisplay(value);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: [] };
  }
}

/**
 * WEB通販比率のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareWeb(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('WEB通販のシェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'WEB通販のシェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'WEB通販のシェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareWholesale(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('卸売のシェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = '卸売のシェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = '卸売のシェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * Web通販比率_自社ECのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareOwnEc(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('自社ECの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = '自社ECの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = '自社ECの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * WEB通販比率_他社ECのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareOtherEc(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('他社ECの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = '他社ECの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = '他社ECの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_アニメ専門店のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareAnimation(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('アニメ専門店の市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'アニメ専門店の市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'アニメ専門店の市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_家電量販店のバリデート
 * @param {*} value 入力された値
 * @param {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareKaden(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('家電量販店の市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = '家電量販店の市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = '家電量販店の市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_CVSのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareCvs(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('CVSの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'CVSの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'CVSの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_GMSのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareGms(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('GMSの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'GMSの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'GMSの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_スーパーマーケットのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareSm(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('スーパーマーケットの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'スーパーマーケットの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'スーパーマーケットの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_ドラッグストアのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareDrug(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('ドラッグストアの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'ドラッグストアの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'ドラッグストアの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_ホビーのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareHobby(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('ホビーの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'ホビーの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'ホビーの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_オンラインのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareOnline(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('オンラインの市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'オンラインの市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'オンラインの市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売比率_その他のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareOther(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push('その他の市場シェアは必須項目です');
    return errors;
  }

  if (!isNumber(value)) {
    const msg = 'その他の市場シェアは数値で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
    return errors;
  }

  if (!valueRange(value, 0, 100)) {
    const msg = 'その他の市場シェアは0から100の範囲で入力してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 卸売備考（流通先名、その他内訳）のバリデート
 * @param {*} value 入力された値
 * @returns {{tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateShareWholesaleRemarks(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * メイン・ターゲットのバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateTarget(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 対流通施策のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePromotionChannel(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 対ユーザー施策のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePromotionUser(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * Web対策のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePromotionWeb(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * Twitter情報のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePromotionTwitter(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

/**
 * 展示会・カタログ情報のバリデート
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePromotionDisplay(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    const msg = getMessage('maxLength', { max: 500 });
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

//#region typedef
/**
 * @typedef {object} FormData フォームの入力・表示用データ
 * @property {number} shareWeb WEB通販比率
 * @property {number} shareWholesale 卸売比率
 * @property {boolean} shareOwnEcFlag 自社EC有無
 * @property {number} shareOwnEc Web通販比率_自社EC
 * @property {number} shareOtherEc Web通販比率_他社EC
 * @property {number} shareAnimation 卸売比率_アニメ専門店
 * @property {number} shareKaden 卸売比率_家電量販店
 * @property {number} shareCvs 卸売比率_CVS
 * @property {number} shareGms 卸売比率_GMS
 * @property {number} shareSm 卸売比率_スーパーマーケット
 * @property {number} shareDrug 卸売比率_ドラッグストア
 * @property {number} shareHobby 卸売比率_ホビー
 * @property {number} shareOnline 卸売比率_オンライン（くじ、プライズ、電子書籍）
 * @property {number} shareOther 卸売比率_その他
 * @property {string} shareWholesaleRemarks 卸売備考（流通先名、その他内訳）
 * @property {string} target メイン・ターゲット
 * @property {string} promotionChannel 対流通施策
 * @property {string} promotionUser 対ユーザー施策
 * @property {string} promotionWeb WEB対策
 * @property {string} promotionTwitter Twitter情報
 * @property {string} promotionDisplay 展示会・カタログ情報
 */
/**
 * @callback OnChange フォームの入力内容変更時のハンドラ
 * @param {keyof FormData} prop 変更されたプロパティ
 * @param {*} value 変更後の値
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {keyof FormData} prop バリデート対象のプロパティ
 * @param {string[]} errors エラーメッセージのリスト
 */
//#endregion typedef
