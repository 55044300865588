/**
 * テーブルセル内のボタン
 * @returns
 */
export const TableCellButton = ({ onClick, className, children }) => (
  // childrenは文字列だけ含むこと
  <p className={'btn small mlrauto ' + className || ''} style={{width: '60px'}}>
    <button type="button" onClick={e => {
      if (typeof onClick !== 'function') {
        return;
      }
      onClick(e);
    }}>{String(children)}</button>
  </p>
);
