import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Config } from '../../../config';
import { Constants } from '../../../Constants';
import { getMessage } from '../../../lib/message';
import { comma3 } from '../../../lib/util';
import { isEmpty, maxLength } from '../../../lib/validator';
import { ErrorMessageList } from '../../common/ErrorMessageList';

/**
 * ロイヤリティ報告詳細画面のANIPLEX管理部分
 * @param {object} props
 * @param {RoyaltyDetail} props.royalty ロイヤルティ報告情報
 * @param {FormData} props.formData フォーム内容のデータ
 * @param {React.MutableRefObject} props.formRefs フォーム項目に設定するref
 * @param {(prop: keyof FormData, value: FormData[prop]) => void} props.handleChange 項目入力時のハンドラ
 * @param {Partial<Record<keyof FormData, string[]>>} props.errors エラーメッセージ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {boolean} props.hasAuthority 更新権限を持っているかのフラグ
 * @param {number} props.ryAmountPrice ロイヤリティ金額
 * @param {boolean} props.isZeroReport 0円報告フラグ
 */
export const AniplexMngForm = ({
  royalty,
  formData,
  formRefs,
  handleChange,
  errors,
  formLocked,
  hasAuthority,
  ryAmountPrice,
  isZeroReport,
}) => {
  /** 更新可能フラグ */
  const isUpdatable = useMemo(() => {
    // 申請中の場合は更新可能
    return royalty?.reportStatus === Constants.Aniplex.reportStatus.Requesting;
  }, [royalty?.reportStatus]);

  /** フォーム非活性化フラグ */
  const formDisabled = useMemo(() => formLocked || !isUpdatable || !hasAuthority,
    [formLocked, hasAuthority, isUpdatable]);

  /** ANIPLEXの申請フラグ */
  const isAniplexApply = useMemo(() => {
    return royalty?.licenseeCode === Config.AniplexLicenseeCode;
  }, [royalty?.licenseeCode]);

  // 値の初期化
  useEffect(() => {
    if (!isUpdatable) {
      // 更新不可時はAPIレスポンス内容をそのまま設定
      handleChange('process', royalty?.process, true);
      handleChange('billingDate', royalty?.billingDate, true);
      handleChange('salesDate', royalty?.salesDate, true);
      handleChange('depositDate', royalty?.depositDate, true);
      handleChange('taxType', royalty?.taxType, true);
      handleChange('taxInOut', royalty?.taxInOut, true);
      handleChange('consumptionTax', royalty?.consumptionTax, true);
      handleChange('bill', royalty?.bill, true);
      handleChange('billRemarks', royalty?.billRemarks, true);
      return;
    }
    if (!isZeroReport) {
      const today = dayjs();
      handleChange('salesDate', today.format('YYYY/MM/DD'), true);
      handleChange('process', Constants.Aniplex.Royalty.Process.Sales, true);
      handleChange('bill', Constants.Aniplex.Royalty.Bill.Required, true);
    }
  }, [handleChange, isUpdatable, isZeroReport, royalty?.bill, royalty?.billRemarks, royalty?.billingDate, royalty?.consumptionTax, royalty?.depositDate, royalty?.process, royalty?.salesDate, royalty?.taxInOut, royalty?.taxType]);

  // 処理内容の変更時
  useEffect(() => {
    if (!isUpdatable) {
      // 更新不可時は処理しない
      return;
    }
    if (formData?.process === Constants.Aniplex.Royalty.Process.Sales) {
      // 売上入力
      const today = dayjs();
      handleChange('billingDate', today.format('YYYY/MM/DD'), true);
      const depositDate = today.add(1, 'month').set('date', 25);
      handleChange('depositDate', depositDate.format('YYYY/MM/DD'), true);
      handleChange('taxType', Constants.Aniplex.Royalty.TaxType.Tax10Percent, true);
      handleChange('taxInOut', Constants.Aniplex.Royalty.TaxInOut.Out, true);
    } else {
      // 分配対象額入力
      handleChange('billingDate', '', true);
      handleChange('depositDate', '', true);
      handleChange('taxType', '', true);
      handleChange('taxInOut', '', true);
    }
  }, [formData?.process, handleChange, isUpdatable]);

  // ANIPLEXからの申請時に「分配対象額入力」を選択する
  useEffect(() => {
    if (isAniplexApply) {
      handleChange('process', Constants.Aniplex.Royalty.Process.Distribution);
    }
  }, [handleChange, isAniplexApply]);

  /** 分配対象額入力フラグ */
  const isDistribution = useMemo(() => {
    return formData?.process === Constants.Aniplex.Royalty.Process.Distribution;
  }, [formData?.process]);

  /** 請求予定日 */
  const billingDate = useMemo(() => {
    return !isEmpty(formData?.billingDate) ?
      dayjs(formData.billingDate, 'YYYY/MM/DD').toDate() :
      null;
  }, [formData?.billingDate])

  /** 売上日 */
  const salesDate = useMemo(() => {
    return !isEmpty(formData?.salesDate) ?
      dayjs(formData.salesDate, 'YYYY/MM/DD').toDate() :
      null;
  }, [formData?.salesDate]);

  /** 売上日の選択可能最小日 */
  const salesMinDate = useMemo(() => {
    // 過去日選択不可
    return dayjs().startOf('day').toDate();
  }, []);

  /** 入金予定日 */
  const depositDate = useMemo(() => {
    return !isEmpty(formData?.depositDate) ?
      dayjs(formData.depositDate, 'YYYY/MM/DD').toDate() :
      null;
  }, [formData?.depositDate])

  /** 消費税 */
  const consumptionTax = useMemo(() => {
    if (!isUpdatable) {
      // 更新不可時はデータをそのまま返す
      return formData.consumptionTax ?? '';
    }
    if (isZeroReport) {
      // 0円報告時は空欄
      return '';
    }
    return isDistribution ? '' :
      calcConsumptionTax(ryAmountPrice, formData?.taxInOut, formData?.taxType)
  }, [formData.consumptionTax, formData?.taxInOut, formData?.taxType, isDistribution, isUpdatable, isZeroReport, ryAmountPrice]);

  // 消費税の変更時にフォームデータに反映する
  useEffect(() => {
    if (!isUpdatable) {
      return;
    }
    handleChange('consumptionTax', consumptionTax, true);
  }, [consumptionTax, handleChange, isUpdatable]);

  /**
   * Date項目変更時のハンドラ
   * @type {(prop: keyof FormData, date: Date) => void}
   */
  const handleDateChange = useCallback((prop, date) => {
    if (isNaN(date?.getTime() ?? NaN)) {
      // 不正な日付の場合(空欄含む)は空を設定
      handleChange(prop, '');
      return;
    }
    const value = dayjs(date).format('YYYY/MM/DD');
    handleChange(prop, value);
  }, [handleChange]);

  return (
    <>
      <div className="title-aniplex">
        <h2 className="title">ANIPLEX管理</h2>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '120px' }}>処理内容</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="処理内容" title="処理内容を選択してください"
                ref={formRefs.current.process}
                disabled={formDisabled || isZeroReport}
                value={formData?.process ?? ''}
                onChange={ev => handleChange('process', ev.target.value)}
              >
                { !isZeroReport && (
                  <>
                    {
                      !isAniplexApply && (
                        <option value={Constants.Aniplex.Royalty.Process.Sales}>売上入力</option>
                      )
                    }
                    <option value={Constants.Aniplex.Royalty.Process.Distribution}>分配対象額入力</option>
                  </>
                )}
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '120px' }}>請求予定日</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form input-calendar">
              <DatePicker
                dateFormat='yyyy/MM/dd'
                locale='ja'
                name='請求予定日'
                title="請求予定日を入力してください"
                aria-label="請求予定日"
                ref={formRefs.current.billingDate}
                selected={billingDate}
                disabled={isDistribution || formDisabled || isZeroReport}
                onChange={date => handleDateChange('billingDate', date)} />
            </div>
            <ErrorMessageList messages={errors?.billingDate ?? []} />
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">売上日</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form input-calendar">
              <DatePicker
                dateFormat='yyyy/MM/dd'
                locale='ja'
                name="売上日"
                title="売上日を入力してください"
                aria-label="売上日"
                ref={formRefs.current.salesDate}
                selected={salesDate}
                minDate={salesMinDate}
                disabled={formDisabled || isZeroReport}
                onChange={date => handleDateChange('salesDate', date)} />
            </div>
            <ErrorMessageList messages={errors?.salesDate ?? []} />
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">入金予定日</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form input-calendar">
              <DatePicker
                dateFormat='yyyy/MM/dd'
                locale='ja'
                name="入金予定日"
                title="入金予定日を入力してください"
                aria-label="入金予定日"
                ref={formRefs.current.depositDate}
                selected={depositDate}
                disabled={isDistribution || formDisabled || isZeroReport}
                onChange={date => handleDateChange('depositDate', date)} />
            </div>
            <ErrorMessageList messages={errors?.depositDate ?? []} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '120px' }}>税区分</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="税区分" title="税区分を選択してください"
                ref={formRefs.current.taxType}
                disabled={isDistribution || formDisabled || isZeroReport}
                value={formData?.taxType ?? ''}
                onChange={ev => handleChange('taxType', ev.target.value)}
              >
              {
                !isZeroReport && !isDistribution && (
                  <>
                    <option value={Constants.Aniplex.Royalty.TaxType.Tax10Percent}>(10%)課税</option>
                    <option value={Constants.Aniplex.Royalty.TaxType.Tax8Percent}>(8%)課税売上</option>
                    <option value={Constants.Aniplex.Royalty.TaxType.Tax5Percent}>(5%)課税売上</option>
                    <option value={Constants.Aniplex.Royalty.TaxType.DutyFree}>(0%)免税売上</option>
                    <option value={Constants.Aniplex.Royalty.TaxType.NotTaxable}>対象外または非課税仕入</option>
                  </>
                )
              }
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">消費税内外区分</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="消費税内外区分" title="消費税内外区分を選択してください"
                ref={formRefs.current.taxInOut}
                disabled={isDistribution || formDisabled || isZeroReport}
                value={formData?.taxInOut ?? ''}
                onChange={ev => handleChange('taxInOut', ev.target.value)}
              >
                {
                  !isZeroReport && !isDistribution && (
                    <>
                      <option value={Constants.Aniplex.Royalty.TaxInOut.Out}>外税</option>
                      <option value={Constants.Aniplex.Royalty.TaxInOut.In}>内税</option>
                      <option value={Constants.Aniplex.Royalty.TaxInOut.NotTaxable}>非課税</option>
                    </>
                  )
                }
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">消費税</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt140 cost">
              <input type="text" name="消費税"
                title="消費税を入力してください"
                aria-label="消費税"
                disabled={formDisabled || isZeroReport || isDistribution}
                readOnly
                ref={formRefs.current.consumptionTax}
                value={comma3(consumptionTax)} />
            </div>
            <span className="attention">※上記は参考金額です。</span>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '120px' }}>請求書発行有無</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="請求書発行有無" title="請求書発行有無を選択してください"
                ref={formRefs.current.bill}
                disabled={formDisabled || isZeroReport}
                value={formData?.bill ?? ''}
                onChange={ev => handleChange('bill', ev.target.value)}
              >
              {
                !isZeroReport && (
                  <>
                    <option value={Constants.Aniplex.Royalty.Bill.NotRequired}>不要</option>
                    <option value={Constants.Aniplex.Royalty.Bill.Required}>必要</option>
                  </>
                )
              }
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">請求項目</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt600">
              <input type="text" name="請求項目"
                title="請求項目を入力してください"
                aria-label="請求項目"
                ref={formRefs.current.billRemarks}
                disabled={formDisabled || isZeroReport}
                value={formData?.billRemarks ?? ''}
                onChange={ev => handleChange('billRemarks', ev.target.value)} />
            </div>
            <ErrorMessageList messages={errors?.billRemarks ?? []} />
          </dd>
        </dl>
      </div>
    </>
  )
}

/**
 * バリデート処理
 * @param {boolean} isZeroReport 0円報告フラグ
 * @param {keyof FormData} prop 対象プロパティ名
 * @param {FormData[prop]} value 入力された値
 * @param {FormData} formData フォーム全体
 * @returns {string[]} エラーメッセージ
 */
export function validate (isZeroReport, prop, value, formData) {
  if (isZeroReport) {
    // 0円報告時は全項目入力不可なのでエラーなし
    return [];
  }

  switch (prop) {
    // 請求予定日
    case 'billingDate':
      return validateBillingDate(formData?.process, value);
    // 売上日
    case 'salesDate':
      return validateSalesDate(value);
    // 入金予定日
    case 'depositDate':
      return validateDepositDate(formData?.process, value);
    // 請求項目
    case 'billRemarks':
      return validateBillRemarks(value);
    default:
      return [];
  }
}

/**
 * 請求予定日のバリデート
 * @param {string} process 処理内容
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateBillingDate(process, value) {
  const errors = [];

  if (process !== Constants.Aniplex.Royalty.Process.Sales) {
    // 売上入力以外は対象外
    return errors;
  }

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 売上日のバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateSalesDate(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 入金予定日のバリデート
 * @param {string} process 処理内容
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateDepositDate(process, value) {
  const errors = [];

  if (process !== Constants.Aniplex.Royalty.Process.Sales) {
    // 売上入力以外は対象外
    return errors;
  }

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 請求項目のバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateBillRemarks(value) {
  const errors = [];

  if (!maxLength(value, 40)) {
    errors.push(getMessage('maxLength', { max: 40 }));
  }

  return errors;
}

/**
 * 消費税を計算する
 * @param {number} price 対象金額
 * @param {string} taxInOut 消費税内外区分
 * @param {string} taxType 税区分
 */
function calcConsumptionTax(price, taxInOut, taxType) {
  if (taxInOut === Constants.Aniplex.Royalty.TaxInOut.NotTaxable
    || taxType === Constants.Aniplex.Royalty.TaxType.DutyFree
    || taxType === Constants.Aniplex.Royalty.TaxType.NotTaxable
  ) {
      // 免税・非課税の場合は0円
      return 0
  }

  // 税率
  const taxRate = (() => {
    switch (taxType) {
      case Constants.Aniplex.Royalty.TaxType.Tax10Percent:
        return 10;
      case Constants.Aniplex.Royalty.TaxType.Tax8Percent:
        return 8;
      case Constants.Aniplex.Royalty.TaxType.Tax5Percent:
        return 5;
      default:
        return 0;
    }
  })();
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  if (taxInOut === Constants.Aniplex.Royalty.TaxInOut.Out) {
    // 外税
    return BN(price).times(BN(taxRate)).dividedBy(BN(100)).dp(0).toNumber();
  }
  if (taxInOut === Constants.Aniplex.Royalty.TaxInOut.In) {
    // 内税
    // price / (100 + taxRate) * taxRate
    return BN(price).dividedBy((BN(100).plus(BN(taxRate)))).times(BN(taxRate))
      .dp(0).toNumber();
  }

  return 0;
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告情報
 */
/**
 * @typedef {import('../../../lib/api/aniplex').ExportRoyaltyParams} FormData 入力フォームデータ
 */
//#endregion typedef
