import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { clearApiStatus, clearProposalDetail, fetchProposalDetail, selectApiStatus, selectProposalDetail } from "../../../slices/licensee/proposalsSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { ProposalStatusForm } from "./ProposalStatusForm";

/**
 * ライセンシー向け企画情報参照画面
 * @returns
 */
export const ProposalStatusPage = () => {
  const authRedirect = useAuthRedirect();
  const dispatch = useDispatch();
  /** 企画情報 */
  const proposalDetail = useSelector(selectProposalDetail);
  /** API通信状況 */
  const apiStatus = useSelector(selectApiStatus).fetchProposalDetail;
  /** react-routerによるパラメータ */
  const routerParams = useParams();

  useEffect(() => {
    // 画面突入時に対象の企画情報を取得する
    dispatch(fetchProposalDetail(routerParams.proposalId));
  }, [dispatch, routerParams.proposalId]);

  useEffect(() => {
    // 企画情報の取得失敗
    if (apiStatus === 'error') {
      // メッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('企画の情報を取得中にエラーが発生しました。'));
      dispatch(clearApiStatus('fetchProposalDetail'));
    }
  });

  useEffect(() => {
    return () => {
      // 画面離脱時にAPIステータスをクリアする
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(clearProposalDetail());
    };
  }, [dispatch]);

  /** データ再読込要求のハンドラ */
  const requestReload = useCallback(() => {
    // いったん現在の値を削除
    dispatch(clearApiStatus('fetchProposalDetail'));
    dispatch(clearProposalDetail());
    // 企画情報の取得をリクエスト
    dispatch(fetchProposalDetail(routerParams.proposalId));
  }, [dispatch, routerParams.proposalId]);

  /** ローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    )
  }

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">企画状況</h1>
      </div>

      <ProposalStatusForm
        proposal={proposalDetail}
        requestReload={requestReload} />

      {loading}
    </div>
  );
}
