import { useCallback } from "react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { clearApiStatus, login, selectApiStatus, setSessionExpired } from "../../../slices/licensee/authSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { LoginForm } from "./LoginForm";

/**
 * ライセンシー向けログイン画面
 * @returns
 */
export const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus).login;
  const authRedirect = useAuthRedirect()

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('login'));
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  const canSubmit = useMemo(() => {
    return apiStatus !== 'loading' && apiStatus !== 'finish';
  }, [apiStatus]);

  // ログインボタン押下時のコールバック
  const onSubmit = useCallback((credential) => {
    dispatch(login(credential));
  }, [dispatch]);

  // 未ログイン以外は対応する画面へリダイレクト
  useEffect(() => {
    if (authRedirect == null) {
      return navigate('/licensee/home');
    }
    if (authRedirect !== '/licensee/login') {
      navigate(authRedirect);
    }
  }, [authRedirect, navigate]);

  // API成功時
  useEffect(() => {
    if (apiStatus === 'finish') {
      // セッション期限切れフラグをクリアして認証コード画面へ遷移
      dispatch(setSessionExpired(false));
      navigate('/licensee/authCode');
    }
  }, [apiStatus, dispatch, navigate]);

  return (
    <>
      <h1 className="main_login-head">ログイン</h1>
      <div className="main_login-body wrap">
        <LoginForm
          canSubmit={canSubmit}
          onSubmit={onSubmit} />
        <p className="tac link-question">
          <i className="icn question"></i>
          <Link to={'/licensee/passwordReset'}>パスワードを忘れた方はこちら</Link>
        </p>
      </div>
      {loading}
    </>
  );
}
