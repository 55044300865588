//@ts-check
import React, { useMemo } from "react";
import { FlexiblePopup } from "./FlexiblePopup";

/**
 * メッセージ表示用ポップアップ
 * @param {object} prop
 * @param {string} prop.message モーダル内に表示するメッセージ
 * @param {object} prop.btn ボタンの文言設定
 * @param {string} [prop.btn.ok] OKボタンの表示文言
 * @param {string} [prop.btn.cancel] キャンセルボタンの表示文言
 * @param {onCloseCallback} prop.onClose 閉じるボタン押下時のコールバック関数
 * @param {string} [prop.btnClass] OKボタンに付与するCSSクラス
 * @param {boolean} [prop.swapBtn=false] OKボタンとキャンセルボタンの位置を入れ替える
 * @returns
 */
export const MessagePopup = ({
  message,
  btn = {ok: 'OK'},
  onClose,
  btnClass,
  swapBtn,
}) => {
  const okBtnCls = btnClass ?? ''
  const okButton = useMemo(() =>
    btn.ok ? (
      <div
        key='ok'
        className={`btn label mt15 ${okBtnCls}`}
        onClick={() => onClose('ok')}
      >
        <button>{btn.ok}</button>
      </div>
    ) : null
  , [btn.ok, okBtnCls, onClose]);


  const cancelBtn = useMemo(() =>
    btn.cancel ? (
      <div
        key='cancel'
        className="btn label mt15"
        onClick={() => onClose('cancel')}
      >
        <button>{btn.cancel}</button>
      </div>
    ) : null
  , [btn.cancel, onClose]);

  const buttons = useMemo(() => {
    if (!swapBtn) {
      return [cancelBtn, okButton];
    } else {
      return [okButton, cancelBtn];
    }
  }, [cancelBtn, okButton, swapBtn]);

  return (
    <FlexiblePopup
      className="txt-center no-btn-close"
      onClose={() => onClose('close')}>
      <div className="wrap">
        <div className="msg-wrap">
          <p className="msg wsPre">{message}</p>
        </div>
      </div>
      <div className="btn-wrapper">
        {
          buttons.map(b => b)
        }
      </div>
    </FlexiblePopup>
  )
}

//#region typedef
/**
 * @callback onCloseCallback ポップアップが閉じたときのコールバック
 * @param {'ok'|'cancel'|'close'} ポップアップが閉じるときに押されたボタン
 * @returns
 */
//#endregion typedef
