import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from '../../../Constants';
import { planPriceCost, rypPriceCostType } from '../../../lib/royalty';
import { comma3 } from '../../../lib/util';
import { pushMessage } from '../../../slices/aniplex/utilSlice';
import { SpannableTableView } from '../../common/table/SpannableTableView';
import { ProductDetail } from '../proposalDetail/productDetail';
import { RecordChangeStatus } from '../common/RecordChangeStatus';
import { useNotAcceptedAlertConfirm } from './AlertConfirmContext';
import { useNotAcceptedReportExists } from './hooks';

/**
 * 上代を使用するロイヤリティ報告パターン
 * @type {string[]}
 * @satisfies {(valueOf<Constants['RoyaltyPattern']>)[]}
 */
const usePricePattern = [
  Constants.RoyaltyPattern.PriceRateProductNum,
  Constants.RoyaltyPattern.PriceRateSalesNum,
];

/**
 * ロイヤリティ報告詳細画面の商品リスト部分
 * @param {object} props
 * @param {RoyaltyDetail} props.royalty ロイヤリティ報告情報
 * @param {ProposalDetail} props.proposal 企画詳細情報
 * @returns
 */
export const RoyaltyProductsForm = ({ royalty, proposal }) => {

  // 商品ポップアップ
  const [productPopup, setProductPopup] = useState({
    showFlg: false,
    target: null,
    onClose: null,
  });

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((productNo) => {
    const target = proposal?.productList.find(p => p.productNo === productNo);
    if (!target) {
      return;
    }

    setProductPopup({
      showFlg: true,
      target,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, [proposal?.productList]);

  /** テーブルのヘッダ定義 */
  const headers = useMemo(() => getTableHeaders(), []);

  /** テーブルのレコード定義 */
  const records = useMemo(() => getRecords(royalty, proposal, onProductNameClick),
    [onProductNameClick, proposal, royalty]);

  // 未報告証紙の警告
  useUnreportedLabelAlert({ proposal, royalty });
  // 上代変更の警告
  usePriceChangedAlert({ proposal, royalty });

  return (
    <>
      <SpannableTableView
        className='mt30 border0 has-total-row scroll'
        headers={headers}
        records={records}
        scrollable />

      {
        productPopup.showFlg && (
          <ProductDetail
            product={productPopup.target}
            periodList={proposal.periodList}
            onClose={productPopup.onClose} />
        )
      }
    </>
  );
}

/**
 * 上代変更アラート
 * @param {object} params
 * @param {ProposalDetail|undefined} params.proposal 企画詳細
 * @param {RoyaltyDetail|undefined} params.royalty ロイヤリティ報告詳細
 */
const usePriceChangedAlert = ({
  proposal,
  royalty,
}) => {
  const dispatch = useDispatch();

  // 未受領報告関連
  const { notAcceptedAlertConfirmed } = useNotAcceptedAlertConfirm();
  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  // アラート表示済みRY報告ID
  const [alertedReportId, setAlertedReportId] = useState(/** @type {number|null} */(null));

  /** 上代変更商品存在フラグ */
  const priceChangedExists = useMemo(() => {
    return royalty?.ryAmountList.some(r => {
      const product = proposal?.productList.find(p => p.productNo === r.productNo)

      if (product == null || !usePricePattern.includes(product?.rypId)) {
        // 上代を使用しないロイヤリティ報告パターンは対象外
        return false;
      }

      return r.reportPrice !== product.planPrice;
    })
  }, [proposal?.productList, royalty?.ryAmountList]);

  useEffect(() => {
    if (royalty?.reportStatus !== Constants.Aniplex.reportStatus.Requesting) {
      // 申請中以外は対象外
      return;
    }
    if (notAcceptedExists && !notAcceptedAlertConfirmed) {
      // 未受領報告が存在する場合は確認アラートが閉じられるまで警告は表示しない
      return;
    }
    if (!priceChangedExists) {
      return;
    }
    if (alertedReportId === royalty.ryReportId) {
      return;
    }
    dispatch(pushMessage('上代を変更した商品が申請されています。'));
    setAlertedReportId(royalty.ryReportId);
  }, [alertedReportId, dispatch, notAcceptedAlertConfirmed, notAcceptedExists, priceChangedExists, royalty?.reportStatus, royalty?.ryReportId]);
}

/**
 * 未報告証紙アラート
 * @param {object} params
 * @param {ProposalDetail|undefined} params.proposal 企画詳細
 * @param {RoyaltyDetail|undefined} params.royalty ロイヤリティ報告詳細
 */
const useUnreportedLabelAlert = ({
  proposal,
  royalty,
}) => {
  const dispatch = useDispatch();

  // 未受領報告関連
  const { notAcceptedAlertConfirmed } = useNotAcceptedAlertConfirm();
  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  // アラート表示済みRY報告ID
  const [alertedReportId, setAlertedReportId] = useState(/** @type {number|null} */(null));

  /** 未報告証紙存在フラグ */
  const unreportedExist = useMemo(() => {
    return proposal?.productList.some(p => {
      const productPeriod = p.periodList.find(pr => pr.period === royalty?.period);
      const ryAmount = royalty?.ryAmountList.find(r => r.productNo === p.productNo);

      const unreported = (productPeriod?.resultLabel ?? 0) - (ryAmount?.reportProduction ?? 0);

      return unreported > 0;
    });
  }, [proposal?.productList, royalty?.period, royalty?.ryAmountList]);

  useEffect(() => {
    if (royalty?.reportStatus !== Constants.Aniplex.reportStatus.Requesting) {
      // 申請中以外は対象外
      return;
    }
    if (notAcceptedExists && !notAcceptedAlertConfirmed) {
      // 未受領報告が存在する場合は確認アラートが閉じられるまで警告は表示しない
      return;
    }
    if (!unreportedExist) {
      return;
    }
    if (alertedReportId === royalty.ryReportId) {
      return;
    }
    dispatch(pushMessage('生産報告がされていない未報告証紙が存在します。申請内容を確認してください。'));
    setAlertedReportId(royalty.ryReportId);
  }, [alertedReportId, dispatch, notAcceptedAlertConfirmed, notAcceptedExists, royalty?.reportStatus, royalty?.ryReportId, unreportedExist]);
}

/**
 * テーブルのヘッダ定義を取得する
 * @returns {TableHeader[]} テーブルヘッダ定義
 */
function getTableHeaders() {
  /** @type {TableHeader[]} */
  const headers = [
    { id: 'updateStatus', label: '', style: { minWidth: '50px' } },
    { id: 'productName', label: '商品名等', style: { minWidth: '180px' } },
    { id: 'character', label: 'キャラクター', style: { minWidth: '100px' } },
    { id: 'totalResultLabel', label: '証紙発行数\n（合計）', style: { minWidth: '100px' }},
    { id: 'totalResultProduction', label: '実績生産数\n（合計）', style: { minWidth: '100px' }},
    { id: 'resultLabel', label: '当期\n発行数', style: { minWidth: '100px' }},
    { id: 'unreportedLabel', label: '当期\n未報告数', style: { minWidth: '100px' }},
    { id: 'production', label: '生産数', style: { minWidth: '100px' } },
    { id: 'sales', label: '販売数', style: { minWidth: '100px' } },
    { id: 'priceCost', label: '上代（税抜き）・製造原価\nもしくは納品価格', className: 'bg-aniplex', children: [
      { id: 'planPriceCost', label: '予定', style: { minWidth: '94px'}, className: 'border-left' },
      { id: 'resultPriceCost', label: '確定', style: { minWidth: '110px' } },
    ]},
    { id: 'royalty', label: 'ロイヤリティ', className: 'bg-aniplex', children: [
      { id: 'ryTarget', label: 'ロイヤリティ\n対象金額', style: { minWidth: '105px' } },
      { id: 'ryRate', label: 'ロイヤリティ\n料率', style: { minWidth: '100px' } },
      { id: 'ryPrice', label: 'ロイヤリティ\n単価', style: { minWidth: '100px' } },
      { id: 'ryAmount', label: 'ロイヤリティ額', style: { minWidth: '110px' } },
    ]},
    { id: 'salesPrice', label: '販売金額', style: { minWidth: '110px' } },
  ];

  return headers;
}

/**
 * テーブルのレコード定義を取得する
 * @param {?RoyaltyDetail} royalty ロイヤルティ報告情報
 * @param {ProposalDetail} proposal 企画情報
 * @param {(productNo: string) => void} onProductNameClick 商品名クリック時のコールバック
 * @returns {TableRecord[]} レコード定義
 */
function getRecords(royalty, proposal, onProductNameClick) {
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  let ryAmountTotal = BN(0);

  /** 前バージョンが存在するか */
  const prevExists = royalty?.previousVersion != null;

  /**
   * 更新状態表示用にデータを変形する
   * @param {UpdatableRyAmount|undefined} ryAmount ロイヤリティ金額情報
   * @returns {Record<keyof UpdatableRyAmount, string>|null}
   */
  const ryAmountConvert = (ryAmount) => {
    if (ryAmount == null) {
      return null;
    }

    return {
      reportProduction: comma3(ryAmount.reportProduction),
      reportSales: comma3(ryAmount.reportSales),
      reportPrice: comma3(ryAmount.reportPrice),
      reportCost: comma3(ryAmount.reportCost),
      reportProceeds: comma3(ryAmount.reportProceeds),
      reportRyAmount: comma3(ryAmount.reportRyAmount),
    };
  }

  /**
   * 更新状態表示用の項目名
   * @type {Record<keyof UpdatableRyAmount, string>}
   */
  const fieldName = {
    reportProduction: '生産数',
    reportSales: '販売数',
    reportPrice: '上代（税抜き）・製造原価もしくは納品価格',
    reportCost: '上代（税抜き）・製造原価もしくは納品価格',
    reportProceeds: 'ロイヤリティ対象金額',
    reportRyAmount: 'ロイヤリティ額'
  };

  /**
   * 更新状態表示用の項目表示順
   * @type {(keyof UpdatableRyAmount)[]}
   */
  const fieldOrder = [
    'reportProduction',
    'reportSales',
    'reportPrice',
    'reportCost',
    'reportProceeds',
    'reportRyAmount',
  ];

  const result = royalty?.ryAmountList
    .map(r => {
      // 前バージョンの金額情報
      const prevRyAmount = royalty.previousVersion?.ryAmountList.find(p => p.productNo === r.productNo);
      // 対応する商品を取得
      const product = proposal?.productList.find(p => p.productNo === r.productNo);
      // 商品の対象期の情報
      const productPeriod = product?.periodList.find(p => p.period === royalty.period);

      // 申請完了のステータス
      const approvedReportStatus = [
        Constants.Aniplex.reportStatus.ReportedZero,
        Constants.Aniplex.reportStatus.Exported,
      ];

      // 証紙発行数(合計)の計算
      const totalResultLabel = (() => {
        if (approvedReportStatus.includes(royalty.reportStatus)) {
          // 申請完了の場合はロイヤリティ報告情報から取得
          return r.preTotalResultLabel;
        }

        // 申請未完了の場合は企画情報から計算
        return product?.periodList
          .reduce((prev, p) => prev + p.resultLabel, 0);
      })();

      // 実績生産数(合計)の計算
      const totalResultProduction = (() => {
        if (approvedReportStatus.includes(royalty.reportStatus)) {
          // 申請完了の場合はロイヤリティ報告情報から取得
          return r.preTotalResultProduction;
        }

        // 申請未完了の場合は企画情報から計算
        return product?.periodList
          .filter(p => p.period <= royalty.period)
          .reduce((prev, p) => prev + p.resultProduction, 0);
      })();

      // 当期発行数
      const resultLabel = productPeriod?.resultLabel ?? '';

      // 当期未報告数
      const unreportedLabel = Math.max((resultLabel || 0) - r.reportProduction, 0);

      // 上代・製造原価(確定)の取得元プロパティ名
      const resultPriceCostProp = (() => {
        switch (rypPriceCostType(product?.rypId)) {
          case 'price':
            return 'reportPrice';
          case 'cost':
            return 'reportCost';
          default:
            return null;
        }
      })();

      // 上代変更フラグ
      const priceChanged = (() => {
        if (!usePricePattern.includes(product?.rypId)) {
          // 上代を使用しないパターンの場合はfalse
          return false;
        }

        return product?.planPrice !== r.reportPrice;
      })();

      /** @type {TableRecord} */
      const record = {
        // 更新状態
        updateStatus: {
          el: prevExists ? (
            <RecordChangeStatus
              record={ryAmountConvert(r)}
              prevRecord={ryAmountConvert(prevRyAmount)}
              fieldName={fieldName}
              fieldOrder={fieldOrder} />
          ) : '',
        },
        // 商品名等
        productName: {
          el: (
            <button className="link"
              onClick={() => onProductNameClick(r.productNo)}
            >{r.productName}</button>
          )
        },
        // キャラクター
        character: {
          el: product?.character ?? '',
        },
        // 証紙発行数(合計)
        totalResultLabel: {
          className: 'cost',
          el: comma3(totalResultLabel ?? ''),
        },
        // 実績生産数(合計)
        totalResultProduction: {
          className: 'cost',
          el: comma3(totalResultProduction ?? ''),
        },
        // 当期発行数
        resultLabel: {
          className: 'cost',
          el: comma3(resultLabel),
        },
        // 当期未報告数
        unreportedLabel: {
          className: 'cost',
          el: comma3(unreportedLabel),
        },
        // 生産数
        production: {
          className: 'cost',
          el: comma3(r.reportProduction ?? ''),
        },
        // 販売数
        sales: {
          className: 'cost',
          el: comma3(r.reportSales ?? ''),
        },
        // 上代・製造原価(予定)
        planPriceCost: {
          className: 'cost',
          el: comma3(planPriceCost(product ?? {}) ?? ''),
        },
        // 上代・製造原価(確定)
        resultPriceCost: {
          className: 'cost',
          el: (
            <span style={{ color: priceChanged ? '#ff0000' : undefined }}>
              {comma3(r[resultPriceCostProp] ?? '')}
            </span>
          ),
        },
        // ロイヤリティ対象金額
        ryTarget: {
          className: 'cost',
          el: comma3(r.calReportRyTarget ?? ''),
        },
        // ロイヤリティ料率
        ryRate: {
          className: 'cost',
          el: comma3(product?.ryRate ?? ''),
        },
        // ロイヤリティ単価
        ryPrice: {
          className: 'cost',
          el: comma3(product?.ryPrice ?? ''),
        },
        // ロイヤリティ額
        ryAmount: {
          className: 'cost',
          el: comma3(r.calReportRyAmount ?? 0),
        },
        // 販売金額
        salesPrice: {
          className: 'cost',
          el: comma3(r.calReportProceeds ?? 0),
        },
      };
      ryAmountTotal = ryAmountTotal.plus(BN(r.calReportRyAmount ?? 0));

      return record;
    }) ?? [];

  // 合計行を追加
  result.push({
    _tr: { className: 'total' },
    productName: { el: '', colSpan: 12 },
    ryRate: {
      className: 'total-head',
      colSpan: 2,
      el: '合計',
    },
    ryAmount: {
      className: 'cost fwb bb-solid-tblcolor',
      el: comma3(ryAmountTotal.dp(0).toNumber()),
    },
    salesPrice: { el: '', colSpan: 2 },
  });

  return result;
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告詳細情報
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RyAmount} RyAmount
 */
/**
 * @typedef {Pick<RyAmount, 'reportProduction'|'reportSales'|'reportPrice'|'reportCost'|'reportProceeds'|'reportRyAmount'>} UpdatableRyAmount LCS側で更新される可能性があるプロパティのみに絞ったロイヤリティ金額情報
 */
/**
 * @typedef {import('./RoyaltyReportDetailForm').ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader テーブルのヘッダ定義
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブルのデータ行
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
