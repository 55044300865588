/**
 * ライセンシー側フッター(未ログイン)
 */
export const LicenseeFooterNoLogin = () => {
  return (
    <footer className="l-footer_login">
      <div className="wrap">
        <p className="l-copyright"><small>&copy; Aniplex Inc. All rights reserved.</small></p>
      </div>
    </footer>
  );
};
