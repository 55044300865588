import logoRoys from '../../assets/licensee_img/logo-roys.png';
import logoAniplex from '../../assets/licensee_img/logo-aniplex.png';

/**
 * ライセンシー側ヘッダー(未ログイン)
 */
export const LicenseeHeaderNoLogin = () => {
  return (
    <header className="l-header_login">
      <div className="wrap">
        <h1 className="header_login-logo">
          <span className="header_login-logo_img">
            <img
              src={logoAniplex}
              alt="aniplex ロゴ"
              title="aniplex"
              className="logo-aniplex"
              width="100px"
              height="30px"
            />
          </span>
          <span className="header_login-logo_img">
            <img
              src={logoRoys}
              alt="ROYS ロゴ"
              title="ROYS"
              className="logo-roys"
              width="176px"
              height="34px"
            />
          </span>
        </h1>
      </div>
    </header>
  );
};
