// Cognito認証完了時のリダイレクト先(当アプリ自身のURL)
const redirectUrl = window.location.origin + window.location.pathname;

export const AwsConfig = Object.freeze({
  Auth: {
    // Amazon Cognito Region
    region: "ap-northeast-1",
    // Amazon Cognito User Pool ID
    userPoolId: window.ServerConfig.AwsConfig.userPoolId,
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: window.ServerConfig.AwsConfig.userPoolWebClientId,

    oauth: Object.freeze({
      domain: window.ServerConfig.AwsConfig.oauth.domain,
      scope: Object.freeze(['aws.cognito.signin.user.admin', 'openid']),
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    }),
  },
});
