
/**
 * エラーメッセージ表示コンポーネント
 * @param {object} props
 * @param {string|string[]} props.messages エラーメッセージ
 * @returns
 */
export const ErrorMessageList = ({ messages }) => {
  if (typeof messages === 'string') {
    messages = [messages];
  }

  if (!Array.isArray(messages) || messages.length === 0) {
    return null;
  }

  return (
    <ul className="error_list">
      {
        messages.map((m, i) => (
          <li key={i} className="error_list-item">{m}</li>
        ))
      }
    </ul>
  )
}
