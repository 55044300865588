import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAnnouncementAll } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {Announcement[]|null} 取得したお知らせ一覧 */
  announcementList: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** お知らせ一覧 */
    fetchAnnouncementAll: null,
  },
}

/**
 * お知らせ一覧取得処理
 */
export const fetchAnnouncementAll = createAsyncThunk(
  'licensee/announcement/fetchAnnouncementAll',
  /**
   * お知らせ一覧取得処理
   */
  async (_, { dispatch }) => {
    dispatch(clearAnnouncementList);
    try {
      const data = await getAnnouncementAll();
      return data.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '23101': ApiErrorMessage.GetAnnouncementFailed,
      });
    }
  }
)

/**
 * ライセンシー向け画面のお知らせ情報周りのスライス
 */
export const announcementSlice = createSlice({
  name: 'licensee/announcement',
  initialState,
  reducers: {
    /**
     * 取得済みのお知らせ一覧をクリアする
     * @param {typeof initialState} state
     */
     clearAnnouncementList: (state) => {
      state.announcementList = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        case 'fetchAnnouncementAll':
          state.apiStatus.fetchAnnouncementAll = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // お知らせ一覧
      .addCase(fetchAnnouncementAll.pending, (state) => {
        state.apiStatus.fetchAnnouncementAll = 'loading';
      })
      .addCase(fetchAnnouncementAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAnnouncementAll = 'finish';
        state.announcementList = action.payload;
      })
      .addCase(fetchAnnouncementAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAnnouncementAll = null;
      });
  }
});

export const {
  clearAnnouncementList,
  clearApiStatus,
} = announcementSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.announcement.apiStatus;

/**
 * stateからお知らせ一覧を取得する
 * @param {*} state
 * @returns {Announcement[]} お知らせ一覧
 */
export const selectAnnouncementList = (state) => state.licensee.announcement.announcementList;

export default announcementSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAnnouncementAll'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Announcement お知らせ情報
 * @property {number} announcementId お知らせ内部コード
 * @property {string} announcementTitle お知らせ件名
 * @property {string} announcementContent お知らせ内容
 * @property {string} announcementStartDatetime 公開開始日時
 */
//#endregion
