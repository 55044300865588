import BigNumber from 'bignumber.js';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../../../Constants';
import { comma3, emptyToHyphen, formatPeriodYM } from '../../../lib/util';
import { selectRoyaltyPatternMst } from '../../../slices/aniplex/masterSlice';
import { getColNumBeforeId, SpannableTableView } from '../../common/table/SpannableTableView';
import { ProductDetail } from './productDetail';
import { ProductUpdateStatus } from './ProductUpdateStatus';

/**
 * 企画詳細画面の許諾商品一覧フォーム
 * @param {object} props
 * @param {Proposal} props.proposal 企画情報
 */
export const ProposalProductsForm = ({ proposal }) => {
  // 商品ポップアップ関連
  const [productPopup, setProductPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {Product|null} 対象商品情報 */
    target: null,
    /** @type {Product|null} 対象商品の以前のバージョン */
    prevProduct: null,
    /** @type {Function|null} 閉じたときのコールバック */
    onClose: null,
  });

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((product, prevProduct) => {
    setProductPopup({
      showFlg: true,
      target: product,
      prevProduct,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, []);

  /** ロイヤリティ報告パターンマスタ */
  const rypPtnMst = useSelector(selectRoyaltyPatternMst);
  /** テーブルヘッダ定義 */
  const headers = useMemo(() => getTableHeaders(proposal), [proposal]);
  /** テーブルレコード定義 */
  const records = useMemo(() => {
    return getRecords({ proposal, rypPtnMst, headers, onProductNameClick });
  }, [proposal, rypPtnMst, headers, onProductNameClick]);

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title"><i className="icn box"></i>許諾申請内容</h2>
      </div>

      <SpannableTableView
        className='border0 mt15 has-total-row scroll header-2line'
        headers={headers}
        records={records}
        scrollable={true}
        fixedCols={4}
        ignoreFixRows={-1} />

      {
        productPopup.showFlg ?
          <ProductDetail
            product={productPopup.target}
            prevProduct={productPopup.prevProduct}
            periodList={proposal.periodList}
            onClose={productPopup.onClose}
          /> :
          null
      }

    </section>
  );
}

/**
 * テーブルのヘッダ定義を取得する
 * @param {Proposal} proposal 企画情報
 * @returns {TableHeader[]} テーブルヘッダ定義
 */
function getTableHeaders(proposal) {
  /** @type {TableHeader[]} 固定部の前半 */
  const preHeaders = [
    { id: 'updateStatus', label: '', style: { minWidth: '50px' } },
    { id: 'productNo', label: '品番', style: { minWidth: '64px' } },
    { id: 'productName', label: '商品名', style: { minWidth: '110px' } },
    { id: 'character', label: 'キャラクター', style: { minWidth: '100px' } },
    { id: 'rypId', label: 'ロイヤリティ\n報告パターン', style: { minWidth: '120px' } },
    { id: 'priceCost', label: '上代（税抜き）\n・製造原価', style: { minWidth: '100px' } },
  ];

  /** @type {TableHeader[]} 固定部の後半 */
  const postHeaders = [
    {
      id: 'periodTotal', label: '期間合計', className: 'bg-aniplex', children: [
        { id: 'planNumTotal', label: '数量', style: { minWidth: '87px' } },
        { id: 'planPriceTotal', label: '金額', style: { minWidth: '87px' } },
      ]
    },
    {
      id: 'royalty', label: 'ロイヤリティ', className: 'bg-aniplex', children: [
        { id: 'ryRate', label: 'ロイヤリティ\n料率', style: { minWidth: '100px' } },
        { id: 'ryPrice', label: 'ロイヤリティ\n単価', style: { minWidth: '100px' } },
        { id: 'planRyAmount', label: 'ロイヤリティ\n額', style: { minWidth: '105px' } },
      ],
    },
  ];

  /** @type {TableHeader[]} 期間部分のヘッダ */
  const periodHeaders = (proposal?.periodList ?? [])
    .map((p, idx) => {
      const ym = formatPeriodYM(p.ryStartDate, p.ryEndDate);
      return {
        id: `period${p.period}`,
        label: `第${p.period}期\n${ym}`,
        className: 'bg-aniplex',
        children: [
          {
            id: `planNum${p.period}`,
            label: '数量',
            style: { minWidth: '87px' },
            className: idx === 0 ? 'border-left' : null,
          },
          {
            id: `planPrice${p.period}`,
            label: '金額',
            style: { minWidth: '87px'},
          }
        ]
      }
    });

  return [...preHeaders, ...periodHeaders, ...postHeaders];
}

/**
 * テーブルに表示するレコード定義を取得する
 * @param {object} args
 * @param {Proposal} args.proposal 企画情報
 * @param {*} args.rypPtnMst ロイヤルティ報告パターンマスタ
 * @param {TableHeader[]} args.headers テーブルヘッダ定義
 * @param {(p: Product, prev: Product) => void} args.onProductNameClick 商品名クリック時のコールバック
 * @returns {TableRecord[]} テーブルレコード定義
 */
function getRecords({ proposal, rypPtnMst, headers, onProductNameClick }) {
  const periodList = proposal?.periodList ?? [];
  const curProductList = proposal?.productList ?? [];
  const prevOnlyProductList = proposal?.previousVersion?.productList
    .filter(p => curProductList.find(cur => cur.productId === p.productId) == null) ?? [];
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });

  /** 前バージョンが存在するか */
  const prevExists = proposal?.previousVersion != null;

  /** @type {ProductWithMeta[]} */
  const productList = [
    ...curProductList,
    ...(prevOnlyProductList?.map(p => ({ ...p, deleted: true }))),
  ];

  const result = productList.map(p => {
    /** 前のバージョンの商品 */
    const prevProduct = proposal?.previousVersion?.productList.find(prev => prev.productId === p.productId) ?? null;
    /** 削除済みフラグ */
    const deleted = p.deleted;

    /** @type {TableRecord} */
    const record = {
      _tr: {
        className: deleted ? 'deleted' : undefined,
      },
      updateStatus: {
        style: { textAlign: 'center' },
        el: prevExists ? (
            <ProductUpdateStatus
              product={p}
              prevProduct={prevProduct}
              deleted={deleted} />
          ) : '',
      },
      productNo: p.productNo,
      productName: {
        style: {
          maxWidth: '20vw'
        },
        className: 'text-wrap',
        el: (
          <button className='link' onClick={() => onProductNameClick(p, prevProduct)}>
            {p.productName}
          </button>
        ),
      },
      character: {
        style: {
          maxWidth: '20vw',
        },
        className: 'text-wrap',
        el: p.character ?? '',
      },
      rypId: rypPtnMst.find(r => r.rypId === p.rypId)?.rypName ?? '',
      priceCost: {
        el: comma3(getPriceCost(p)),
        className: 'cost',
      },
      ryRate: {
        el: comma3(emptyToHyphen(p.ryRate)),
        className: 'cost',
      },
      ryPrice: {
        el: comma3(emptyToHyphen(p.ryPrice)),
        className: 'cost',
      },
      planRyAmount: {
        el: comma3(emptyToHyphen(p.planRyAmount)),
        className: 'cost',
      },
    };

    let planNumTotal = 0;
    let planPriceTotal = BN(0);
    periodList.forEach(pr => {
      const planNum = getPeriodPlanNum(p, pr.period);
      const planPrice = getPeriodPlanPrice(p, pr.period);

      record[`planNum${pr.period}`] = {
        el: comma3(planNum),
        className: 'cost',
      };
      record[`planPrice${pr.period}`] = {
        el: comma3(planPrice),
        className: 'cost',
      };

      planNumTotal += planNum === '-' ? 0 : planNum;
      planPriceTotal = planPriceTotal.plus(BN(planPrice === '-' ? 0 : planPrice));
    });

    record.planNumTotal = {
      el: comma3(emptyToHyphen(planNumTotal)),
      className: 'cost',
    };
    record.planPriceTotal = {
      el: comma3(emptyToHyphen(planPriceTotal.toNumber())),
      className: 'cost',
    };

    return record;
  });

  // 合計行を追加
  const planRyAmountTotal = productList
    .filter(p => !p.deleted)
    .reduce((prev, cur) => prev.plus(BN(cur.planRyAmount || 0)), BN(0));
  const colSpan = getColNumBeforeId(headers, 'ryRate')
  result.push({
    _tr: {
      className: 'total'
    },
    productNo: {
      el: '',
      colSpan: colSpan,
    },
    ryRate: {
      el: (
        <dl>
          <dt>合計</dt>
          <dd>{comma3(planRyAmountTotal.toNumber())}</dd>
        </dl>
      ),
      colSpan: 3,
      className: 'total-body',
    }
  });

  return result;
}

/**
 * 商品情報から上代・製造原価の表示内容を取得する
 * @param {Product} product 商品情報
 * @returns {string} 上代・製造単価の表示内容
 */
function getPriceCost(product) {
  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代
      return product.planPrice ?? '-';
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代
      return product.planPrice ?? '-';
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価
      return product.planCost ?? '-';
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から第N期の数量の表示内容を取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の期数
 * @returns {string} 表示内容
 */
function getPeriodPlanNum(product, period) {
  const targetPeriod = product.periodList?.find(p => p.period === period);

  if (!targetPeriod) {
    // 対象の期が見つからなかった場合は非表示
    return '-';
  }

  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 販売数
      return targetPeriod.planSales ?? '-';
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 生産数×ロイヤリティ単価
    case Constants.RoyaltyPattern.ProductNumUniPrice:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 販売数×ロイヤリティ単価
    case Constants.RoyaltyPattern.SalesNumUnitPrice:
      // 販売数
      return targetPeriod.planSales ?? '-';
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から第N期の金額の表示内容を取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の期数
 * @returns {string} 表示内容
 */
function getPeriodPlanPrice(product, period) {
  const targetPeriod = product.periodList?.find(p => p.period === period);

  if (!targetPeriod) {
    // 対象の期が見つからなかった場合は非表示
    return '-';
  }

  const HUpBN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });

  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      if (targetPeriod.planProduction == null) {
        return '-';
      }
      // 上代×生産数
      return HUpBN(product.planPrice).times(HUpBN(targetPeriod.planProduction)).dp(2).toNumber();
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      if (targetPeriod.planSales == null) {
        return '-';
      }
      // 上代×販売数
      return HUpBN(product.planPrice).times(HUpBN(targetPeriod.planSales)).dp(2).toNumber();
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      if (targetPeriod.planProduction == null) {
        return '-';
      }
      // 製造原価×生産数
      return HUpBN(product.planCost).times(HUpBN(targetPeriod.planProduction)).dp(2).toNumber();
    default:
      // 上記以外は非表示
      return '-';
  }
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
/**
 * @typedef {import('./ProposalDetailForm').Product} Product 商品情報
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader テーブルのヘッダ定義
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブルのレコード定義
 */
/**
 * @typedef {object} ProductMeta 商品メタ情報
 * @property {boolean} [deleted] 削除済みフラグ
 */
/**
 * @typedef {Product & ProductMeta} ProductWithMeta メタ情報付き商品情報
 */
//#endregion typedef
