//@ts-check
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertySummaryMst } from '../../../slices/licensee/masterSlice.js';

/**
 * 作品名称
 * @param {?Proposal} proposal 企画情報
 */
export const useProposalToSummaryName = (proposal) => {
  const propertyMst = useSelector(selectPropertySummaryMst);

  /** 作品名称を取得する処理 */
  const getSummaryName = useCallback(
    /** @param {string|undefined} propertySummaryCode 作品コード */
    (propertySummaryCode) => {
      const found = propertyMst.find(p => p.propertySummaryCode === propertySummaryCode);
      if (!found) {
        return '';
      }
      return found.propertySummaryName;
    }, [propertyMst]);

  /** 作品名称 */
  const propertySummaryName = useMemo(
    () => getSummaryName(proposal?.propertySummaryCode),
    [getSummaryName, proposal?.propertySummaryCode]
  );

  return {
    propertySummaryName,
  };
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice.js').ProposalDetail} Proposal 企画情報
 */
//#endregion typedef
