import { useCallback, useEffect, useMemo, useState } from "react";
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { SearchableListBox } from "../../common/SearchableListBox";

/**
 * 企画担当者設定ポップアップ
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const AssignUserPopup = ({ data, licenseeUsers, onClose }) => {
  // ユーザーID
  const [userId, setUserId] = useState('');
  // エラーメッセージ
  const [messages, setMessages] = useState({
    /** ユーザーID */
    loginId: [],
  });

  useEffect(() => {
    setUserId(data.kikakuUserId);
  }, [data.kikakuUserId]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['loginId']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.loginId.length > 0) {
      if (focusError('loginId')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.loginId.length, needFocusError]);

  /** 氏名が設定されているユーザ */
  const namedUserList = useMemo(() => {
    return licenseeUsers.filter(u => u.username);
  }, [licenseeUsers]);

  /**選択された社員 */
  const selectedUser = useMemo(() => {
    const targetUserId = userId ?? '';
    const found = licenseeUsers.find(i => i.userId === targetUserId);
    if (!found) {
      return null;
    }
    return {
      label: found.username,
      value: found.userId,
    };
  }, [licenseeUsers, userId]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      userId: validateUserId(value),
    }));
    setUserId(value);
  }, []);

  /** 登録ボタン押下時のコールバック */
  const onSubmit = useCallback(() => {
    const errors = {
      userId: validateUserId(userId),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      return;
    }

    onClose('submit', selectedUser);
  }, [userId, onClose, selectedUser]);

  return (
    <>
      <FlexiblePopup onClose={() => onClose('close')}>
        <h4 className="popup-title">企画担当者設定</h4>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required">担当者</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="input-form wdt300">
                <SearchableListBox
                  selectRef={formRefs.current.employeeNo}
                  value={selectedUser}
                  data={namedUserList}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  onChange={item => handleChange(item?.value)}
                  labelKey='username'
                  valueKey='userId' />
              </div>
              <ErrorMessageList messages={messages.userId} />
            </dd>
          </dl>
        </div>

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-blue">
            <button
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>
    </>
  );
}

/**
 * ユーザーIDのバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateUserId(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  return errors;
}

//#region typedef
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
//#endregion typedef
