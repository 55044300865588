import React, { useMemo, useState, useCallback } from 'react';
import { comma3 } from '../../../lib/util';
import { ProductDetail } from '../proposalDetail/productDetail'
import { RecordChangeStatus } from '../common/RecordChangeStatus';

/**
 * 証紙申請の商品リスト
 * @param {object} params
 * @param {Product[]} params.productList 商品リスト（証紙情報を含む）
 * @param {LabelProduct[]|undefined} params.prevLabelList 前バージョンの証紙情報のリスト
 * @param {ProposalDetail} params.proposal 企画詳細情報
 */
export const LabelProductForm = ({
  productList,
  prevLabelList,
  proposal
}) => {
  // 商品ポップアップ関連
  const [productPopup, setProductPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {Product|null} 対象商品情報 */
    target: null,
    /** @type {Function|null} 閉じたときのコールバック */
    onClose: null,
  });

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((product) => {
    setProductPopup({
      showFlg: true,
      target: product,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, []);

  /** 証紙が申請されている商品のリスト */
  const labelRequestedProductList = useLabelRequestedProductList({
    productList,
    prevLabelList,
  });

  /** 証紙表示データ作成 */
  const records = useMemo(() => {
    const result = [];
    if (labelRequestedProductList.length < 1) return result;

    /** 前バージョンが存在するか */
    const prevExists = prevLabelList != null;

    /**
     * 更新状態用にデータを変形する
     * @param {UpdatableLabelProduct|undefined} label 証紙情報
     * @returns {Record<keyof UpdatableLabelProduct, string>|null}
     */
    const labelConvert = (label) => {
      if (label == null) {
        return null;
      }

      return {
        labelFigure: comma3(label?.labelFigure ?? ''),
        labelType: (() => {
          switch (label?.labelType) {
            case 'S':
              return 'シール';
            case 'P':
              return '印刷';
            default:
              return '';
          }
        })(),
      };
    };

    /**
     * 更新状態表示用の項目名
     * @type {Record<keyof UpdatableLabelProduct, string>}
     */
    const fieldName = {
      labelFigure: '証紙申請数',
      labelType: '証紙',
    };

    /**
     * 更新状態表示用の項目表示順
     * @type {(keyof UpdatableLabelProduct)[]}
     */
    const fieldOrder = [
      'labelFigure',
      'labelType',
    ];

    // 商品リストによるデータ作成
    labelRequestedProductList.forEach((data, idx) => {
      const prevLabel = prevLabelList?.find(l => l.labelNo === data.label.labelNo);

      const label = data.label;
      result.push(
        <tr key={`label${idx}`}>
          <td>
            {
              prevExists && (
                <RecordChangeStatus
                  record={labelConvert(data.label)}
                  prevRecord={labelConvert(prevLabel)}
                  fieldName={fieldName}
                  fieldOrder={fieldOrder} />
              )
            }
          </td>
          <td>
            <button className='link'
              onClick={() => onProductNameClick(data)}
              style={{ textAlign: 'left' }}
            >{data.productName}</button>
          </td>
          <td>{data.character ?? ''}</td>
          <td className="cost">{comma3(data.planPrice ?? '')}</td>
          <td>
            <div className="input-form cost mlauto wdt140">
              <input type="text" name={`labelFigure${idx}`} aria-label="証紙申請数"
                value={label.labelFigure ? comma3(label.labelFigure) : 0 }
                disabled={true} />
            </div>
          </td>
          <td>
            <fieldset>
              <legend>証紙{idx}</legend>
              <ul className="list-form df">
                <li>
                  <input type="radio" id={`radioS-${idx}`} name={`labelType${idx}`} aria-label={`証紙${idx}`} value="S"
                  checked={(label.labelType === 'S') ?? true}
                  style={{ display: 'none'}} disabled={true} />
                  <label htmlFor={`radioS-${idx}`} className="form-radio">シール</label>
                </li>
                <li className="ml15">
                  <input type="radio" id={`radioT-${idx}`} name={`labelType${idx}`} aria-label={`証紙${idx}`} value="P"
                  checked={label.labelType === 'P'}
                  style={{ display: 'none'}} disabled={true} />
                  <label htmlFor={`radioT-${idx}`} className="form-radio">印刷</label>
                </li>
              </ul>
            </fieldset>
          </td>
        </tr>
      )
    });
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  return(
    <div className="l-table border0 mt30" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <table>
        <thead style={{ position: 'sticky', top: 0, zIndex: '1' }}>
          <tr>
            <th scope="col" style={{ minWidth: 50 }}></th>
            <th scope="col" style={{ minWidth: 750 }}>商品名等</th>
            <th scope="col" style={{ minWidth: 150 }}>キャラクター</th>
            <th scope="col" style={{ minWidth: 110 }}>上代（税抜き）</th>
            <th scope="col" className="tar" style={{ minWidth: 197, width: 310 }}>証紙申請数</th>
            <th scope="col" style={{ minWidth: 160 }}>証紙</th>
          </tr>
        </thead>
        <tbody>
          {
            records.length > 0 ? records :
            <tr><td colSpan={5}>該当する商品が存在しません。</td></tr>
          }
        </tbody>
      </table>
      {
        productPopup.showFlg ?
          <ProductDetail
          product={productPopup.target}
          periodList={proposal.periodList}
          onClose={productPopup.onClose}
          /> : null
      }
    </div>
  );
}

/**
 * 証紙が要求された商品のみのリスト
 * @param {object} params
 * @param {Product[]} params.productList 商品リスト（証紙情報を含む）
 * @param {LabelProduct[]|undefined} params.prevLabelList 前バージョンの証紙情報のリスト
 */
const useLabelRequestedProductList = ({
  productList,
  prevLabelList,
}) => {
  return useMemo(() => {
    return productList.filter(p => {
      if (p.label.labelFigure > 0) {
        return true;
      }

      const prev = prevLabelList?.find(pr => pr.productNo === p.productNo);
      if ((prev?.labelFigure ?? 0) > 0) {
        return true;
      }

      return false;
    })
  }, [prevLabelList, productList]);
}

//#region
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProduct} ProposalProduct
 */
/**
 * @typedef {import('../../../slices/aniplex/labelsSlice').labelProduct} LabelProduct
 */
/**
 * @typedef {ProposalProduct & { label: LabelProduct }} Product
 */
/**
 * @typedef {Pick<LabelProduct, 'labelFigure'|'labelType'>} UpdatableLabelProduct
 */
//#endregion
