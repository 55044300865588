//@ts-check
import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { statusListRoyaltySearch } from '../../common/headerList';
import { useSaveFormData } from '../../../lib/hooks/common';
import { Constants } from '../../../Constants';

/**
 * 検索パラメーターの初期値
 * @type {SearchFormData}
 */
export const defaultParams = {
  proposalNo: '',
  proposalTitle: '',
  reportStatus: '',
  reportUserName: '',
  reportDateFrom: undefined,
  reportDateTo: undefined,
  ryReportNo: '',
};

/**
 * 日付型のパラメータ
 * @satisfies {(keyof SearchFormData)[]}
 */
export const dateParams = [
  'reportDateFrom',
  'reportDateTo',
];

/**
 * [L]ロイヤリティ報告一覧検索フォーム
 * @param {object} props
 * @param {SearchFormData} props.params 検索フォームデータ
 * @param {React.Dispatch<React.SetStateAction<SearchFormData>>} props.setParams 検索フォームデータ設定処理
 * @param {(params: SearchFormData) => void} props.onSearch 検索処理
 */
export const RoyaltyReportListSearchForm = ({ params, setParams, onSearch }) => {

  // 検索パラメータ保存関連
  const { saveData } = useSaveFormData({
    saveKey: Constants.Licensee.SearchFormSaveKey.RoyaltyReportList,
    dataType: params,
    dateParams,
  });

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value }));
  }, [setParams]);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, [setParams]);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    // 現在のパラメータを保存
    saveData(params);

    if (typeof onSearch !== 'function') {
      return;
    }

    onSearch(params);
  }, [saveData, params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    const params = {...defaultParams};
    // 現在のパラメータを保存
    saveData(params);

    setParams(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    onSearch(params);
  }, [onSearch, saveData, setParams]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="proposalNo" title="企画書Noを入力してください" aria-label="企画書No"
                value={params.proposalNo}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="proposalTitle" title="企画件名を入力してください" aria-label="企画件名"
                value={params.proposalTitle}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>ロイヤリティ報告ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="reportStatus" title="ロイヤリティ報告ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.reportStatus}>
                <option value=""></option>
                {Object.keys(statusListRoyaltySearch).map(i => (
                  <option value={i} key={i}>{statusListRoyaltySearch[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告者</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="reportUserName" title="報告者を必ず入力してください" aria-label="報告者"
                value={params.reportUserName}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告日</dt>
          <dd className="form-body df a-center" style={{display: 'flex'}}>
            <div className="input-form input-calendar mr10">
              <DatePicker
                name="reportDateFrom"
                title="報告開始日を入力してください"
                aria-label="報告開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.reportDateFrom}
                onChange={date => onDateChange('reportDateFrom', date)} />
            </div>
            <p>〜</p>
            <div className="input-form input-calendar ml10">
              <DatePicker
                name="reportDateTo"
                title="報告終了日を入力してください"
                aria-label="報告終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.reportDateTo}
                onChange={date => onDateChange('reportDateTo', date)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>ロイヤリティ報告No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="ryReportNo" title="ロイヤリティ報告Noを入力してください" aria-label="ロイヤリティ報告No"
                value={params.ryReportNo}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn bg-blue" style={{ width: '160px' }}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn c-pink" style={{ width: '160px' }}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームデータ
 * @property {string} proposalNo 企画No※部分一致検索
 * @property {string} proposalTitle 企画件名※部分一致検索
 * @property {'TMP'|'REQ'|'REJ'|'APP_EXP'|''} reportStatus ロイヤリティ報告ステータス
 * TMP: 一時保存
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP_EXP: 申請完了
 * @property {string} reportUserName 報告者氏名 ※部分一致検索
 * @property {Date|undefined|null} reportDateFrom 報告日From
 * @property {Date|undefined|null} reportDateTo 報告日To
 * @property {string} ryReportNo ロイヤリティ報告書No※部分一致検索
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
