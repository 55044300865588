import { scrollToPageTop } from '../../lib/util.js';

/**
 * アニプレックス側フッター
 */
export const AniplexFooter = () => {
  return (
    <footer className="l-footer mt30">
      <div className="wrap">
        <p className="l-copyright"><small>&copy; Aniplex Inc. All rights reserved.</small></p>
        <p className="js-pagetop l-pagetop" tabIndex="0" onClick={scrollToPageTop}><i className="icn pagetop"></i>ページの先頭へ</p>
      </div>
    </footer>
  );
};
