import { useCallback, useState } from "react"

/** 検索フォームの初期値 */
const defaultParams = {
  /** @type {string} 作品コード */
  propertySummaryCode: '',
  /** @type {string} 作品名称 */
  propertySummaryName: '',
  /** @type {string} 作品名称フリガナ※部分一致検索 */
  propertySummaryNameFurigana: '',
  /** @type {boolean|''} 企画申請可否 */
  proposalFlag: '',
}

/**
 * [A]作品一覧画面の検索フォーム
 * @param {object} props
 * @param {(params: Record<string, string>) => void} props.onSearch 検索実行時のコールバック
 */
export const PropertyListSearchForm = ({ onSearch }) => {
  // フォーム入力内容
  const [params, setParams] = useState(defaultParams);

  /**
   * フォーム内容変更時のハンドラ
   * @type {(name: keyof params, value: *) => void}
   */
  const handleChange = useCallback((name, value) => {
    setParams(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    if (typeof onSearch !== 'function') {
      return;
    }

    onSearch(formatParams(params))
  }, [onSearch, params]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    setParams(defaultParams);
    if (typeof onSearch !== 'function') {
      return;
    }

    onSearch({});
  }, [onSearch]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>作品コード</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="作品コード"
                title="作品コードを入力してください"
                aria-label="集約用作品コード"
                value={params.propertySummaryCode}
                onChange={ev => handleChange('propertySummaryCode', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt500">
              <input type="text" name="作品名称"
                title="作品名称を入力してください"
                aria-label="集約作品名称"
                value={params.propertySummaryName}
                onChange={ev => handleChange('propertySummaryName', ev.target.value)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">作品名称カナ</dt>
          <dd className="form-body">
            <div className="input-form wdt500">
              <input type="text" name="作品名称カナ"
                title="作作品名称カナを入力してください"
                aria-label="集約作品名称カナ"
                value={params.propertySummaryNameFurigana}
                onChange={ev => handleChange('propertySummaryNameFurigana', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>企画申請可否</dt>
          <dd className="form-body">
            <div className="form-select wdt100">
              <select name="企画申請可否" title="企画申請可否を選択してください"
                value={params.proposalFlag}
                onChange={ev => handleChange('proposalFlag', ev.target.value)}
              >
                <option value=""></option>
                <option value={true}>可能</option>
                <option value={false}>不可</option>
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{ width: '160px' }}>
          <button
            onClick={onSearchClick}
          ><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{ width: '160px' }}>
          <button
            onClick={onClearClick}
          ><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  )
}

/**
 * 入力されたパラメータをGETリクエスト用のパラメータに変換する
 * @param {typeof defaultParams} params フォームに入力されたパラメータ
 * @returns {Record<string, string>} フォーマット後のパラメータ
 */
function formatParams(params) {
  const result = {};

  Object.entries(params).forEach(([prop, value]) => {
    if (value == null || !String(value)) {
      // nullish、空文字相当の値は設定しない
      return;
    }

    result[prop] = String(value);
  });

  return result;
}
