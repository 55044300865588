//@ts-check
import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty, maxLength } from '../../../lib/validator';
import { getMessage } from '../../../lib/message';
import { FlexiblePopup } from '../../common/FlexiblePopup';
import { SafeCharTextArea } from '../../common/SafeCharTextArea';
import { ErrorMessageList } from '../../common/ErrorMessageList';

/**
 * 企画の更新開始用ポップアップ
 * @param {object} params
 * @param {OnClose} params.onClose ポップアップが閉じるときのコールバック
 */
export const PermitUpdatePopup = ({ onClose }) => {
  // 更新理由
  const [messageContent, setMessageContent] = useState('');
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState({
    messageContent: /** @type {string[]} */ ([]),
  });

  /** エラーフラグ */
  const hasError = useMemo(() => Object.values(errorMessage).flat().length > 0, [errorMessage]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback(value => {
    setErrorMessage(prev => ({
      ...prev,
      messageContent: validateMessageContent(value),
    }));
    setMessageContent(value);
  }, []);

  /** 更新開始ボタン押下時のハンドラ */
  const onPermitUpdate = useCallback(() => {
    const errors = {
      messageContent: validateMessageContent(messageContent),
    };

    setErrorMessage(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      return;
    }

    onClose('ok', messageContent);
  }, [messageContent, onClose]);

  return (
    // @ts-expect-error
    <FlexiblePopup className='no-btn-close'>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required">更新理由</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-textarea wdt300">
              {/* @ts-expect-error */}
              <SafeCharTextArea
                rows={4}
                value={messageContent}
                onChange={val => handleChange(val)} />
            </div>
            {/* @ts-expect-error */}
            <ErrorMessageList messages={errorMessage.messageContent} />
          </dd>
        </dl>
      </div>

      <div className="btn-wrapper">
        <div className="btn label mt15">
          <button
            onClick={() => onClose('cancel')}
          >キャンセル</button>
        </div>
        <div className="btn mt15 bg-pink">
          <button
            disabled={hasError}
            onClick={onPermitUpdate}
          >更新開始</button>
        </div>
      </div>
    </FlexiblePopup>
  )
}

/**
 * 更新理由のバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateMessageContent(value) {
  /** @type {string[]} */
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  if (!maxLength(value, 500)) {
    errors.push(getMessage('maxLength', { max: 500 }));
  }

  return errors;
}

//#region typedef
/**
 * @callback OnClose ポップアップが閉じるときのコールバック
 * @param {'ok'|'cancel'} btn 閉じるときに押されたボタン
 * @param {string} [messageContent] 更新理由 (btn='ok'のときのみ設定される)
 */
//#endregion
