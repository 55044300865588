/**
 * APIのエラー
 */
export class ApiError extends Error {
  /** @type {import("axios").AxiosResponse} */
  response = null;

  /**
   *
   * @param {import("axios").AxiosResponse} response APIレスポンス
   */
  constructor (response = null) {
    super('API Error');
    this.name = this.constructor.name;
    this.response = response;
  }

  /**
   * APIレスポンスのステータスコード
   */
  get statusCode() {
    return this.response?.status ?? null;
  }

  /**
   * APIのエラーコード
   */
  get errorCode() {
    return this.response?.data?.result?.errorCode ?? null;
  }
}
