import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersMyself } from "../../lib/api/aniplex";
import { handleAniplexApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {UserMyself|null} ログイン中ユーザ情報 */
  myself: null,
  /** API通信ステータス */
  apiStatus : {
    /** @type {'loading'|'finish'|'error'|null} ログインユーザー情報取得 */
    fetch: null
  }
}

/**
 * ログイン中ユーザ情報取得処理
 */
export const fetchMyself = createAsyncThunk(
  'aniplex/users/fetchMyself',
  async (_, { dispatch }) => {
    try {
      const data = await getUsersMyself();
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * ANIPLEX画面のユーザー情報周りのスライス
 */
export const usersSlice = createSlice({
  name: 'aniplex/users',
  initialState,
  reducers: {
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     */
    clearApiStatus: (state, action) => {
      // // ログインユーザー詳細処理
      switch(action.payload) {
        case 'fetch':
          state.apiStatus.fetch = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ログインユーザ情報取得処理
      .addCase(fetchMyself.pending, (state) => {
        state.apiStatus.fetch = 'loading';
      })
      .addCase(fetchMyself.fulfilled, (state, action) => {
        state.apiStatus.fetch = 'finish';
        state.myself = action.payload;
      })
      .addCase(fetchMyself.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetch = null;
      });
  }
});

export const { clearApiStatus } = usersSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.users.apiStatus;

/**
 * stateからログイン中ユーザ情報を取得する
 * @param {*} state
 * @returns {UserMyself|null} ログイン中ユーザ情報
 */
export const selectMyself = (state) => state.aniplex.users.myself;

export default usersSlice.reducer;

//#region typedef
/**
 * @typedef {object} UserMyself ログインユーザー詳細
 * @property {string} employeeNo 社員番号
 * @property {string} employeeName ユーザー氏名
 * @property {string} mailaddress メールアドレス
 * @property {'M'|'T'|'A'|'S'} role ロール
 * @property {string} updateDatetime 更新日時
 */
//#endregion
