//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Link, useParams } from 'react-router-dom';
import { useAuthRedirect } from '../../../lib/hooks/licensee';
import { royaltyReport, statusListRoyalty } from '../../common/headerList';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { TableVisible } from '../../common/table/TableVisible';
import { PagingTable } from '../../common/table/PagingTable';
import { RoyaltyReportListSearchForm, dateParams, defaultParams } from './RoyaltyReportListSearchForm';
import { fetchAll, fetchAllRoyalties, selectApiStatus, clearApiStatus, clearFetchAll } from '../../../slices/licensee/royaltiesSlice';
import { clearApiStatus as clearUsersApiStatus, fetchMyself, selectMyself } from '../../../slices/licensee/usersSlice';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil';
import { useSaveFormData } from '../../../lib/hooks/common';

const reportStatusEmphasis = Constants.Licensee.reportStatusEmphasis;

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
royaltyReport.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [L]ロイヤリティ一覧画面
 * @returns
 */
export const RoyaltyReportListPage = () => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const hasMyself = Boolean(myself);
  const username = myself?.username || undefined;
  const authRedirect = useAuthRedirect();
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const royaltyList = useSelector(fetchAllRoyalties);
  /** react-routerによるパラメータ */
  const routerParams = useParams();

  const proposalNo = routerParams.proposalNo || undefined

  /**
   * 検索フォーム初期値
   * @type {SearchFormData}
   */
  const defaultSearchParam = useMemo(() => ({
    ...defaultParams,
    proposalNo: proposalNo ?? '',
  }), [proposalNo]);

  // 検索パラメータ
  const [searchParams, setSearchParams] = useState(defaultSearchParam);

  // 検索パラメータ保存関連
  const {
    isRestored,
    savedData,
    saveData,
  } = useSaveFormData({
    saveKey: Constants.Licensee.SearchFormSaveKey.RoyaltyReportList,
    dataType: searchParams,
    dateParams,
  });

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [headers, setHeaders] = useState(royaltyReport);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'ryReportNo', order: 'desc' });

  /** 検索処理 */
  const onSearch = useCallback(params => {
    dispatch(fetchAll(emptyToUndefined({
      ...params,
      reportDateFrom: formatDate(params.reportDateFrom),
      reportDateTo:   formatDate(params.reportDateTo),
    })));
  }, [dispatch]);

  // マウント時にログインユーザ情報を取得
  useEffect(() => {
    dispatch(fetchMyself());
  }, [dispatch]);

  // 画面離脱時にAPI通信状況をクリアする
  useEffect(() => {
    return () => {
      dispatch(clearUsersApiStatus('fetch'));
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

    // 画面突入時に保存済み検索パラメータを復元
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (proposalNo != null) {
      // 企画書Noのパラメータが存在する場合は復元しない
      setParamRestored(true);
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }
    setSearchParams(savedData);
    setParamRestored(true);
  }, [isRestored, paramRestored, proposalNo, savedData]);

  // 保存済みパラメータ復元後に検索
  useEffect(() => {
    if (initialFetched) {
      return;
    }
    if (!paramRestored) {
      return;
    }
    if (!hasMyself) {
      return;
    }
    const params = {...searchParams};
    if (savedData == null && proposalNo == null) {
      // 保存済みパラメータが存在せず、企画一覧からの遷移でもない場合
      // 申請者の項目にログインユーザを設定
      params.reportUserName = username ?? '';
    }
    setSearchParams(params);
    onSearch(params);
    saveData(params);
    setInitialFetched(true);
  }, [hasMyself, initialFetched, onSearch, paramRestored, proposalNo, saveData, savedData, searchParams, username]);

  // パスパラメータ変更時に検索パラメータをリセットする
  useEffect(() => {
    setSearchParams(defaultSearchParam);
    setInitialFetched(false);
  }, [defaultSearchParam]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [royaltyList, sortInfo]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const royalties = royaltyList?.result?.data || [];
    return royalties.map(royalty => {
      return {
        ryReportId: royalty.ryReportId,
        reportStatus: royalty.reportStatus,
        ryReportNo: royalty.ryReportNo,
        propertySummaryName: royalty.propertySummaryName,
        proposalId: royalty.proposalId,
        proposalNo: royalty.proposalNo,
        proposalTitle: royalty.proposalTitle,
        reportUserName: royalty.reportUserName,
        reportDate: royalty.reportDatetime,
      };
    });
  }, [royaltyList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'reportStatus') {
      return (a, b) => statusListRoyalty?.[a[sortKey]]?.index - statusListRoyalty?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {

      // ステータスが強調表示の対象か?
      const statusEmphasis = reportStatusEmphasis.includes(record.reportStatus);
      const statisText = statusListRoyalty[record.reportStatus]?.text;

      return {
        ...record,
        id: record.ryReportId,
        //@ts-expect-error
        ryReportNo:(<Link to={'/licensee/royaltyReportDetail/' + record.ryReportId}>{record.ryReportNo}</Link>),
        //@ts-expect-error
        proposalNo:(<Link to={'/licensee/proposalDetail/' + record.proposalId}>{record.proposalNo}</Link>),
        reportStatus: statusEmphasis ? (
          <span className="attention">{statisText}</span>
        ) : statisText,
        reportDate: String(record.reportDate || '').substring(0, 10),
      };
    });
  }, [sortedRecords]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(royaltyReport.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <div className="wrap">
      <div className="mt30">
        <p className="btn bg-green" style={{width: '226px'}}>
          {/* @ts-expect-error */}
          <Link to="/licensee/royaltyReportDetail">
            <i className="icn plus"></i>
            新規ロイヤリティ報告
          </Link>
        </p>
      </div>
      <hr />
      <RoyaltyReportListSearchForm
        params={searchParams}
        setParams={setSearchParams}
        onSearch={onSearch} />
      <TableVisible
        className="mt30"
        isOpen={isOpen}
        headers={royaltyReport}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(royaltyList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          pageSize={pageSize}
          current={current}
          sortParam={sortInfo}
          emptyMessage="検索条件と一致するロイヤリティ報告情報がありません。"
          scrollable
          resizable
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}

    </div>
  );
};

//#region typedef
/**
 * @typedef {import('./RoyaltyReportListSearchForm').SearchFormData} SearchFormData
 */
//#endregion typedef
