import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProperties, getProperty, patchProperty } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/** 初期値 */
const initialState = {
  /** @type {Property[]} 取得した作品一覧 */
  propertyList: [],
  /** @type {PropertyDetail|null} 取得した作品詳細情報 */
  propertyDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** 作品一覧取得処理 */
    fetchPropertyList: null,
    /** 作品詳細取得処理 */
    fetchPropertyDetail: null,
    /** 作品更新処理 */
    updateProperty: null,
  }
}

/**
 * 作品一覧取得処理
 */
export const fetchPropertyList = createAsyncThunk(
  'aniplex/properties/fetchPropertyList',
  /**
   * 作品一覧取得処理
   * @param {import('../../lib/api/aniplex').GetPropertiesParam} param
   */
  async (param, { dispatch }) => {
    try {
      const result = await getProperties(param);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * 作品詳細取得処理
 */
export const fetchPropertyDetail = createAsyncThunk(
  'aniplex/properties/fetchPropertyDetail',
  /**
   * 作品詳細取得処理
   * @param {string} propertySummaryCode 作品コード
   */
  async (propertySummaryCode, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearPropertyDetail());
    try {
      const result = await getProperty(propertySummaryCode);
      return result;
    } catch (err) {
      handleAniplexApiError(err, thunkApi.dispatch, {
        // データ不正
        '11801': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 作品更新処理
 */
export const updateProperty = createAsyncThunk(
  'aniplex/properties/updateProperty',
  /**
   * @property {string} propertySummaryCode 作品コード
   * @property {UpdatePropertyParam} param 作品更新パラメータ
   * @returns {updatePropertyResult}
   */
  async({propertySummaryCode, params}, {dispatch}) => {
    try {
      const result = await patchProperty(propertySummaryCode, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '11901': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '11902': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * ANIPLEX向け画面の作品関連のスライス
 */
export const propertiesSlice = createSlice({
  name: 'aniplex/properties',
  initialState,
  reducers: {
    /**
     * 取得した作品一覧をクリアする
     * @param {typeof initialState} state
     */
    clearPropertyList: (state) => {
      state.propertyList = [];
    },
    /**
     * 取得した作品詳細情報をクリアする
     * @param {typeof initialState} state
     */
    clearPropertyDetail: (state) => {
      state.propertyDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // 作品一覧取得処理
        case 'fetchPropertyList':
          state.apiStatus.fetchPropertyList = null;
          break;
        // 作品詳細取得処理
        case 'fetchPropertyDetail':
          state.apiStatus.fetchPropertyDetail = null;
          break;
        // 作品更新処理
        case 'updateProperty':
          state.apiStatus.updateProperty = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // 作品一覧取得処理
      .addCase(fetchPropertyList.pending, (state) => {
        state.apiStatus.fetchPropertyList = 'loading';
      })
      .addCase(fetchPropertyList.fulfilled, (state, action) => {
        state.apiStatus.fetchPropertyList = 'finish';
        state.propertyList = action.payload;
      })
      .addCase(fetchPropertyList.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchPropertyList = null;
      })
      // 作品詳細取得処理
      .addCase(fetchPropertyDetail.pending, (state) => {
        state.apiStatus.fetchPropertyDetail = 'loading';
      })
      .addCase(fetchPropertyDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchPropertyDetail = 'finish';
        state.propertyDetail = action.payload;
      })
      .addCase(fetchPropertyDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchPropertyDetail = null;
      })
      // 作品更新処理
      .addCase(updateProperty.pending, (state) => {
        state.apiStatus.updateProperty = 'loading';
      })
      .addCase(updateProperty.fulfilled, (state) => {
        state.apiStatus.updateProperty = 'finish';
      })
      .addCase(updateProperty.rejected, (state) => {
        // TODO::エラー処理入れる
        state.apiStatus.updateProperty = 'error';
      });
  }
});

export const {
  clearPropertyList,
  clearPropertyDetail,
  clearApiStatus,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.properties.apiStatus;

/**
 * stateから作品一覧を取得する
 * @param {*} state
 * @returns {Property[]} 作品一覧
 */
export const selectPropertyList = (state) => state.aniplex.properties.propertyList;

/**
 * stateから作品詳細情報を取得する
 * @param {*} state
 * @returns {PropertyDetail} 作品詳細情報
 */
export const selectPropertyDetail = (state) => state.aniplex.properties.propertyDetail;

//#region typedef
/**
 * @typedef {'fetchPropertyList'|'fetchPropertyDetail'|'updateProperty'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Property 作品情報
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {string} propertySummaryNameKana 作品名称カナ
 * @property {string} propertySummaryNameHankakuKana 作品名称半角カナ
 * @property {string} propertySummaryNameHiragana 作品名称ひらがな
 * @property {boolean} proposalFlag 企画申請可否
 * @property {string} iconFilename アイコンファイル名
 */
/**
 * @typedef {object} PropertyDetail 作品詳細情報
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {string} propertySummaryNameKana 作品名称カナ
 * @property {string} propertySummaryNameHankakuKana 作品名称半角カナ
 * @property {string} propertySummaryNameHiragana 作品名称ひらがな
 * @property {boolean} proposalFlag 企画申請可否
 * @property {string} iconFilename アイコンファイル名
 * @property {} memberList 作品担当グループ
 * @property {string} detailUpdateDatetime 詳細情報更新日時
 */
/**
 * @typedef {object} PropertyMember 作品担当グループ情報
 * @property {string} employeeNo 社員番号
 * @property {string} employeeName ユーザー氏名
 */
/**
 * @typedef {object} UpdatePropertyParam 作品更新パラメータ
 * @property {boolean} proposalFlag 企画申請可否
 * @property {string} iconFilename アイコンファイル名
 * @property {} memberList 作品担当グループ
 * @property {string} memberList.employeeNo 社員番号
 * @property {stirng} detailUpdateDatetime 詳細情報更新日時 YYYY/MM/DD HH:mm:ss
 */
/**
 * @typedef {object} updatePropertyResult 作品更新結果
 * @property {string} propertySummaryCode 作品コード
 * @property {string} detailUpdateDatetime 詳細情報更新日時 YYYY/MM/DD HH:mm:ss
 */
//#endregion typedef
