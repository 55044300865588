import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty, isValidEmail, isValidPassword, lengthRange, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";

/** フォーム項目の順序 */
const formPropOrder = [
  'mailaddress',
  'password',
];

/**
 * ログイン画面の入力フォーム部分
 * @param {object} prop
 * @param {boolean} prop.canSubmit フォーム送信可能フラグ
 * @param {(formData: { mailaddress: string, password: string }) => void} prop.onSubmit 送信ボタン押下時のコールバック
 * @returns
 */
export const LoginForm = ({ canSubmit, onSubmit }) => {
  // 入力データ
  const [ formData, setFormData ] = useState({
    mailaddress: '',
    password: '',
  });
  // バリデーションメッセージ
  const [ messages, setMessages ] = useState({
    mailaddress: [],
    password: [],
  });
  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);

  /** エラーがないかのチェック処理 */
  const checkHasError = useCallback((messages) => {
    return Object.values(messages)
      .flat().length > 0;
  }, []);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  /**
    * フォーム更新ハンドラ
    * @param {'mailaddress'|'password'} name 更新された項目名
    * @param {string} value 更新後の値
    */
  const handleChange = (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
      setMessages({
        ...messages,
        [name]: validate(name, value),
      });
  };

  /** ログインボタン押下時の処理 */
  const onLoginClick = () => {
    // 全項目をバリデート
    const newMessages = {
      ...messages,
      mailaddress: validate('mailaddress', formData.mailaddress),
      password: validate('password', formData.password),
    };
    if (checkHasError(newMessages)) {
      // バリデートエラーがある場合は表示に反映して処理終了
      setMessages(newMessages);
      setNeedFocusError(true);
      return;
    }

    onSubmit({
      mailaddress: formData.mailaddress,
      password: formData.password,
    });
  }

  /** 送信系ボタン無効化フラグ */
  const submitDisabled = !canSubmit;

  return (
    <div className="main_login-content">
      <dl className="main_login-form form-set">
        <dt className="form-name">メールアドレス</dt>
        <dd className="form-body wdt500">
          <div className="input-form">
            <input type="email"
              title="メールアドレス"
              name="mailaddress"
              placeholder="例：sample@example.com"
              ref={formRefs.current.mailaddress}
              maxLength={256}
              required
              value={formData.mailaddress}
              onChange={event => handleChange('mailaddress', event.target.value)} />
          </div>
          <ErrorMessageList messages={messages.mailaddress} />
        </dd>
      </dl>

      <dl className="main_login-form form-set">
        <dt className="form-name">パスワード</dt>
        <dd className="form-body wdt500">
          <div className="input-form">
            <input type="password"
              title="パスワード"
              name="password"
              aria-label="パスワード"
              ref={formRefs.current.password}
              maxLength={32}
              required
              value={formData.password}
              onChange={event => handleChange('password', event.target.value)} />
          </div>
          <ErrorMessageList messages={messages.password} />
        </dd>
      </dl>

      <p className="btn bg-green">
        <button onClick={() => onLoginClick()}
          disabled={submitDisabled}
        >ログイン</button>
      </p>
    </div>
  );
}

/**
 * フォーム入力内容をバリデートする
 * @param {'mailaddress'|'password'} name 入力された項目名
 * @param {string} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validate(name, value) {
  switch (name) {
    // メールアドレス
    case 'mailaddress':
      return validateMailaddress(value);
    // パスワード
    case 'password':
      return validatePassword(value);
    default:
      // 上記以外の項目名の場合は何もしない
      return '';
  }
}

/**
 * メールアドレスのバリデートを行う
 * @param {string} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateMailaddress(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!isValidEmail(value)) {
    errors.push(getMessage('isValidEmail'));
  }
  if (!maxLength(value, 256)) {
    errors.push(getMessage('maxLength', {max: 256}));
  }

  return errors;
}

/**
 * パスワードのバリデートを行う
 * @param {string} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validatePassword(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!isValidPassword(value)) {
    errors.push(getMessage('isValidPassword'));
  }
  if (!lengthRange(value, 8, 32)) {
    errors.push(getMessage('lengthRange', { min: 8, max: 32 }));
  }

  return errors;
}
