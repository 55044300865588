import { useCallback } from 'react';
import { UploadArea } from '../common/UploadArea';
import { useFileUpload } from '../../lib/hooks/common';
import { getImageRetry, uploadFile } from '../../lib/api/licensee';
import { UploadedImageList } from './UploadedImageList';
import { UploadList } from '../common/UploadList';

/**
 * ライセンシー側・画像アップロード
 * @returns
 */
export const UploadImage = ({
  uploadedFileNoList = [],
  onChangeFileNoList,
  onChangeHasError,
  onChangeUploaded,
  onChangeHasProgress,
  onChangeHasExceeded,
  maxFileSize,
  maxFileSizeErrMsg,
}) => {

  // ファイルアップロードのhook
  const [fileEntries, setFileEntries, startUploading] = useFileUpload({
    isImageMode: true,
    apiGetImage: getImageRetry,
    apiUploadFile: bindUploadFile({
      filetype: 'renderingImage',
    }),
    maxFileSize,
    maxFileSizeErrMsg,
  });

  // アップロード済み画像(一時保存からの復帰時)が削除された時の処理
  const onDeleteFile = useCallback(entries => {
    if (typeof onChangeUploaded !== 'function') {
      return;
    }
    onChangeUploaded(entries.map(entry => entry.fileNo));
  }, [onChangeUploaded]);

  const props = {
    fileEntries,
    setFileEntries,
    startUploading,
    onChangeFileNoList,
    onChangeHasError,
    onChangeHasProgress,
    onChangeHasExceeded
  };
  return (
    <UploadArea { ...props }
      ListComponent={UploadList}
    >
      {uploadedFileNoList.length > 0 && (
        <UploadedImageList fileNoList={uploadedFileNoList} onDeleteFile={onDeleteFile} />
      )}
    </UploadArea>
  );
};

/**
 * ファイルアップロードAPIのパラメーターを固定する
 */
const bindUploadFile = bindOptions => async options => {
  return uploadFile({
    ...bindOptions,
    ...options,
  });
};
