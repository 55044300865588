import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMasters } from '../../lib/api/licensee';
import { handleLicenseeApiError } from '../../lib/util';

/**
 * 初期値
 */
const initialState = {
  /** @type {import('../../lib/api/licensee').PropertySummaryMst[]} 作品マスタ */
  propertySummaryMst: [],
  /** @type {import('../../lib/api/licensee').CategoryMst[]} 商品カテゴリマスタ */
  categoryMst: [],
  /** @type {import('../../lib/api/licensee').RoyaltyPatternMst[]} ロイヤリティ報告パターンマスタ */
  royaltyPatternMst: [],
  /** API通信ステータス */
  apiStatus: {
    /** @type {'loading'|'finish'|'error'|null} マスタ情報取得 */
    fetchMaster: null
  }
}

/**
 * マスタ情報取得処理
 */
export const fetchMaster = createAsyncThunk(
  'licensee/master/fetchMaster',
  async (_, { dispatch }) => {
    try {
      const data = await getMasters();
      return data.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch);
    }
  }
)

export const masterSlice = createSlice({
  name: 'licensee/master',
  initialState,
  reducers: {
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     * @param {object} action
     * @param {'fetchMaster'} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        case 'fetchMaster':
          state.apiStatus.fetchMaster = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // マスタ情報取得処理
      .addCase(fetchMaster.pending, (state) => {
        state.apiStatus.fetchMaster = 'loading';
      })
      .addCase(fetchMaster.fulfilled, (state, action) => {
        state.propertySummaryMst = action.payload.propertySummaryMst;
        state.categoryMst = action.payload.categoryMst;
        state.royaltyPatternMst = action.payload.royaltyPatternMst;
      })
      .addCase(fetchMaster.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchMaster = null;
      });
  }
});

export const {
  clearApiStatus,
} = masterSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.master.apiStatus;

/**
 * stateから作品マスタ情報を取得する
 * @param {*} state
 * @returns {import('../../lib/api/licensee').PropertySummaryMst[]} 取得した作品マスタ情報
 */
export const selectPropertySummaryMst = (state) => state.licensee.master.propertySummaryMst;

/**
 * stateから商品カテゴリマスタ情報を取得する
 * @param {*} state
 * @returns {import('../../lib/api/licensee').CategoryMst[]} 取得した商品カテゴリマスタ情報
 */
export const selectCategoryMst = (state) => state.licensee.master.categoryMst;

/**
 * stateからロイヤリティ報告パターンマスタ情報を取得する
 * @param {*} state
 * @returns {RoyaltyPatternMst[]} 取得したロイヤリティ報告パターンマスタ情報
 */
export const selectRoyaltyPatternMst = (state) => state.licensee.master.royaltyPatternMst;

/**
 * rypIdから必須の項目を取得する（企画申請）
 * @param {*} rypId ロイヤリティ報告パターンID
 * @returns {Array} 入力必須項目のパラメータ名リスト
 */
export const selectRypInputRequire = (state) => (rypId) => {
  const rypMst = state.licensee.master.royaltyPatternMst;
  const inputReq = [];
  const targetRypMst = rypMst.find(m => m.rypId === rypId);
  if (targetRypMst == null) {
    return [];
  }
  Object.entries(targetRypMst).forEach(([key, value]) => {
    if (value && /^(inputPlan.*|inputRy.*|inputSalesMethod|inputProductMethod)$/.test(key)) {
      // ロイヤリティ料率（マスタ情報の値からパラメータ名に変換）
      const str = 'input';
      const name = key.replace(str, '')[0].toLowerCase() + key.replace(str, '').substring(1);
      inputReq.push(name);
    }
  });
  return inputReq;
}

/**
 * rypIdから必須の項目を取得する（RY報告申請）
 * @param {*} rypId ロイヤリティ報告パターンID
 * @returns {Array} 必須項目のパラメータ名リスト
 */
 export const selectRypReportRequire = (state) => (rypId) => {
  const RypMst = state.licensee.master.royaltyPatternMst;
  const reportReq = [];
  Object.entries(RypMst[rypId - 1]).forEach(([key, value]) => {
    if (value && /^inputReport.*$/.test(key)) {
      const str = key.replace('report', '');
      const name = str[0].toLowerCase() + str.substring(1);
      reportReq.push(name);
    }
  });
  return reportReq;
}
export default masterSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchMaster'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} RoyaltyPatternMst ロイヤリティ報告パターンマスタ情報
 * @property {'1'|'2'|'3'|'4'|'5'|'6'|'7'} rypId ロイヤリティ報告パターンID
 * @property {string} rypName ロイヤリティ報告パターン名
 * @property {boolean} inputPlanProduction 予定生産数入力
 * @property {boolean} inputPlanSales 予定販売数入力
 * @property {boolean} inputPlanProce 予定上代入力
 * @property {boolean} inputPlanCost 予定製造原価入力
 * @property {boolean} inputPlanProceeds 予定売上金額入力
 * @property {boolean} inputPlanRyAmount ロイヤリティ金額入力
 * @property {boolean} inputRyRate ロイヤリティ率入力
 * @property {boolean} inputRyPrice ロイヤリティ単価入力
 * @property {boolean} inputReportProduction 生産数報告入力
 * @property {boolean} inputReportSales 販売数報告入力
 * @property {boolean} inputReportPrice 確定上代報告入力
 * @property {boolean} inputReportCost 確定製造原価報告入力
 * @property {boolean} inputReportProceeds 確定売上金額報告入力
 * @property {boolean} inputReportRyAmount ロイヤリティ金額報告入力
 * @property {string} calProceeds 売上金額算出項目
 * @property {string} calRyTarget ロイヤリティ対象金額算出項目
 * @property {string} calRyAmount ロイヤリティ金額算出項目
 * @property {boolean} inputSalesMethod 販売方式入力
 * @property {boolean} inputProductMethod 生産方式入力
 */
//#endregion typedef
