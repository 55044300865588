//@ts-check
import React, { useCallback, useMemo, useState } from "react"
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { MessagePopup } from "../../common/MessagePopup";
import { Constants } from "../../../Constants";

const periodAlertTxt =
`商品登録後に契約開始日、契約終了日、ロイヤリティ報告締め月を変更すると、
商品の予定生産数、予定販売数情報がクリアされます。`

/**
 * ロイヤリティ報告締め月選択ポップアップ
 * @param {object} props
 * @param {Partial<FormData>} props.data フォーム内容のデータ
 * @param {import('./ProposalDetailForm').Product[]} props.productList 許諾商品リスト
 * @param {OnClose} props.onClose ポップアップを閉じたときのハンドラ
 * @returns
 */
export const ProposalClosingMonthPopup = ({ data, productList, onClose }) => {
  // 期間変更アラート関連
  const [periodAlert, setPeriodAlert] = useState(/** @type {PeriodAlert} */ ({
    /** アラート表示フラグ */
    showFlg: false,
    /** close時コールバック */
    onClose: null,
  }));
  // フォームデータ
  const [formData, setFormData] = useState(({
    /** ロイヤリティ報告締め月_1月 */
    ryReq1: false,
    /** ロイヤリティ報告締め月_2月 */
    ryReq2: false,
    /** ロイヤリティ報告締め月_3月 */
    ryReq3: false,
    /** ロイヤリティ報告締め月_4月 */
    ryReq4: false,
    /** ロイヤリティ報告締め月_5月 */
    ryReq5: false,
    /** ロイヤリティ報告締め月_6月 */
    ryReq6: false,
    /** ロイヤリティ報告締め月_7月 */
    ryReq7: false,
    /** ロイヤリティ報告締め月_8月 */
    ryReq8: false,
    /** ロイヤリティ報告締め月_9月 */
    ryReq9: false,
    /** ロイヤリティ報告締め月_10月 */
    ryReq10: false,
    /** ロイヤリティ報告締め月_11月 */
    ryReq11: false,
    /** ロイヤリティ報告締め月_12月 */
    ryReq12: false,
    /** ロイヤリティ報告区分 */
    ryReportCategory: Constants.Licensee.RyReportCategory.Monthly,
    ...data,
  }));
  // 商品有無フラグ
  const productExists = useMemo(() => {
    return (productList ?? []).length > 0;
  }, [productList]);

  /**
   * フォーム更新ハンドラ
   * @type {(name: keyof FormData, value: boolean|string) => void}
   */
  const handleChange = useCallback((name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  }, [formData]);

  /** 指定月編集可能フラグ */
  const monthEditable = useMemo(() =>
    formData.ryReportCategory === Constants.Licensee.RyReportCategory.Specified
  , [formData.ryReportCategory]);

  /** 設定ボタン押下時のハンドラ */
  const onSetting = useCallback(() => {
    let showAlert = false;
    // 許諾商品が存在し変更有りの場合は期間変更のアラートを表示する
    if (productExists && checkDirty(formData, data)) {
      showAlert = true;
    }

    if (!showAlert) {
      onClose('setting', formData);
    } else {
      setPeriodAlert({
        showFlg: true,
        onClose: (btn) => {
          setPeriodAlert({
            showFlg: false,
            onClose: null,
          });
          if (btn === 'ok') {
            onClose('setting', formData);
          }
        }
      });
    }
  }, [productExists, formData, data, onClose]);

  return (
    <>
      <FlexiblePopup
        onClose={() => onClose('close')} >
        <h4 className="popup-title">ロイヤリティ報告締め月</h4>
        {
          productExists ?
            (
              <div className="c-pink mb15 wsPre">
                {periodAlertTxt}
              </div>
            )
            : null
        }
        <div>
          <div>
            <input
              type="radio"
              name="ryReportCategory"
              id="ryReportCategory-MON"
              aria-label="毎月"
              value={Constants.Licensee.RyReportCategory.Monthly}
              style={{ display: 'none' }}
              checked={formData.ryReportCategory === Constants.Licensee.RyReportCategory.Monthly}
              onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
            <label
              htmlFor="ryReportCategory-MON"
              className="form-radio"
            >毎月</label>
          </div>
          <div>
            <input
              type="radio"
              name="ryReportCategory"
              id="ryReportCategory-SPE"
              aria-label="指定月"
              value={Constants.Licensee.RyReportCategory.Specified}
              style={{ display: 'none'}}
              checked={formData.ryReportCategory === Constants.Licensee.RyReportCategory.Specified}
              onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
            <label
              htmlFor="ryReportCategory-SPE"
              className="form-radio"
            >指定月</label>
          </div>
          <div style={{ paddingLeft: '3em' }}>
            <div style={{ display: 'inline-block' }}>
              <ul className="list-form ml0 taLeft">
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-01" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq1}
                    onChange={ev => handleChange('ryReq1', ev.target.checked)} />
                  <label htmlFor="closing-month-01" className="form-checkbox">1月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-02" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq2}
                    onChange={ev => handleChange('ryReq2', ev.target.checked)} />
                  <label htmlFor="closing-month-02" className="form-checkbox">2月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-03" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq3}
                    onChange={ev => handleChange('ryReq3', ev.target.checked)} />
                  <label htmlFor="closing-month-03" className="form-checkbox">3月</label>
                </li>
              </ul>
              <ul className="list-form ml0 taLeft">
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-04" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq4}
                    onChange={ev => handleChange('ryReq4', ev.target.checked)} />
                  <label htmlFor="closing-month-04" className="form-checkbox">4月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-05" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq5}
                    onChange={ev => handleChange('ryReq5', ev.target.checked)} />
                  <label htmlFor="closing-month-05" className="form-checkbox">5月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-06" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq6}
                    onChange={ev => handleChange('ryReq6', ev.target.checked)} />
                  <label htmlFor="closing-month-06" className="form-checkbox">6月</label>
                </li>
              </ul>
              <ul className="list-form ml0 taLeft">
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-07" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq7}
                    onChange={ev => handleChange('ryReq7', ev.target.checked)} />
                  <label htmlFor="closing-month-07" className="form-checkbox">7月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-08" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq8}
                    onChange={ev => handleChange('ryReq8', ev.target.checked)} />
                  <label htmlFor="closing-month-08" className="form-checkbox">8月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-09" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq9}
                    onChange={ev => handleChange('ryReq9', ev.target.checked)} />
                  <label htmlFor="closing-month-09" className="form-checkbox">9月</label>
                </li>
              </ul>
              <ul className="list-form ml0 taLeft">
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-10" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq10}
                    onChange={ev => handleChange('ryReq10', ev.target.checked)} />
                  <label htmlFor="closing-month-10" className="form-checkbox">10月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-11" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq11}
                    onChange={ev => handleChange('ryReq11', ev.target.checked)} />
                  <label htmlFor="closing-month-11" className="form-checkbox">11月</label>
                </li>
                <li className="ml0 wdt80">
                  <input type="checkbox" id="closing-month-12" title="報告締め月" name="報告締め月"
                    disabled={!monthEditable}
                    checked={formData.ryReq12}
                    onChange={ev => handleChange('ryReq12', ev.target.checked)} />
                  <label htmlFor="closing-month-12" className="form-checkbox">12月</label>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginTop: '5px' }}>
            <input
              type="radio"
              name="ryReportCategory"
              id="ryReportCategory-ANY"
              aria-label="任意"
              value={Constants.Licensee.RyReportCategory.Any}
              style={{ display: 'none' }}
              checked={formData.ryReportCategory === Constants.Licensee.RyReportCategory.Any}
              onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
            <label
              htmlFor="ryReportCategory-ANY"
              className="form-radio"
            >任意　※ライセンス部担当と事前に確認の上、選択をお願いします。</label>
          </div>
        </div>
        <div className="btn-wrapper">
          <div className="btn label mt15">
            <button onClick={() => onClose('close')}>キャンセル</button>
          </div>
          <div className="btn label mt15 bg-pink">
            <button onClick={onSetting}>変更</button>
          </div>
        </div>
      </FlexiblePopup>
      {
        periodAlert.showFlg ?
          <MessagePopup
            message={periodAlertTxt}
            btn={{ ok: '変更する', cancel: 'キャンセル' }}
            btnClass="bg-pink"
            onClose={btn => periodAlert.onClose ? periodAlert.onClose(btn) : null} />
          : null
      }
    </>
  )
}

/**
 * フォーム内容が変更されているか判定する
 * @param {Partial<FormData>} data 判定対象のデータ
 * @param {Partial<FormData>} orgData 元のデータ
 * @return {boolean} 変更されている場合はtrue
 */
function checkDirty(data, orgData) {
  /** @type {(keyof FormData)[]} */
  const targetProps = [
    'ryReq1', 'ryReq2', 'ryReq3',
    'ryReq4', 'ryReq5', 'ryReq6',
    'ryReq7', 'ryReq8', 'ryReq9',
    'ryReq10', 'ryReq11', 'ryReq12',
    'ryReportCategory',
  ];
  return targetProps.filter(prop => data[prop] !== orgData[prop]).length > 0;
}

//#region typedef
/**
 * @typedef {import('../../../lib/api/licensee').PostProposalParam} PostProposalParam
 */
/**
 * @typedef {Pick<PostProposalParam, 'ryReportCategory'|'ryReq1'|'ryReq2'|'ryReq3'|'ryReq4'|'ryReq5'|'ryReq6'|'ryReq7'|'ryReq8'|'ryReq9'|'ryReq10'|'ryReq11'|'ryReq12'>} FormData フォームの入力用データ
 */
/**
 * @typedef {object} FormData1 フォームの入力用データ
 * @property {boolean} ryReq1 ロイヤリティ報告締め月_1月
 * @property {boolean} ryReq2 ロイヤリティ報告締め月_2月
 * @property {boolean} ryReq3 ロイヤリティ報告締め月_3月
 * @property {boolean} ryReq4 ロイヤリティ報告締め月_4月
 * @property {boolean} ryReq5 ロイヤリティ報告締め月_5月
 * @property {boolean} ryReq6 ロイヤリティ報告締め月_6月
 * @property {boolean} ryReq7 ロイヤリティ報告締め月_7月
 * @property {boolean} ryReq8 ロイヤリティ報告締め月_8月
 * @property {boolean} ryReq9 ロイヤリティ報告締め月_9月
 * @property {boolean} ryReq10 ロイヤリティ報告締め月_10月
 * @property {boolean} ryReq11 ロイヤリティ報告締め月_11月
 * @property {boolean} ryReq12 ロイヤリティ報告締め月_12月
 */
/**
 * @typedef {object} PeriodAlert 期間変更アラート
 * @property {boolean} showFlg アラート表示フラグ
 * @property {?(btn: 'ok'|'cancel'|'close') => void} onClose アラートを閉じたときのコールバック
 */
/**
 * @callback OnClose フォームが閉じたときのハンドラ
 * @param {'close'|'setting'} type フォームの閉じられ方.close: 閉じる
 * @param {FormData} [data] 設定されたデータ
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
