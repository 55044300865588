import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { selectAniplexUserMst } from "../../../slices/aniplex/masterSlice";
import { clearApiStatus, clearPropertyDetail, fetchPropertyDetail, selectApiStatus, selectPropertyDetail, updateProperty } from "../../../slices/aniplex/propertiesSlice";
import { fetchPropertyMst, selectApiStatus as selectApiStatusMst } from "../../../slices/aniplex/masterSlice";
import { PropertyIconForm } from "./PropertyIconForm";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { PropertyGroupForm } from "./PropertyGroupForm";
import { selectMyself } from "../../../slices/aniplex/usersSlice";
import { Constants } from "../../../Constants";
import { useFocusError } from "../../../lib/hooks/common";

/**
 * 作品詳細
 * @returns
 */
export const PropertyDetailPage = () => {
  const dispatch = useDispatch();
  const routerParams = useParams();
  const propertySummaryCode = routerParams.propertySummaryCode;
  const myself = useSelector(selectMyself);

  const apiStatus = useSelector(selectApiStatus);
  const apiStatusMst = useSelector(selectApiStatusMst);
  const property = useSelector(selectPropertyDetail);
  const anxUsers = useSelector(selectAniplexUserMst);

  const [memberList, setMemberList] = useState([]);

  /** 編集可能なロールか? */
  const editable = useMemo(() => {
    if (myself == null) {
      return false;
    }
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
  }, [myself]);


  // アイコンファイルアップロード
  const[uploadedIcon, setUploadedIcon] = useState(null);
  // エラー有無
  const [hasError, setHasError] = useState(false);
  // アップロード中画像の有無
  const [hasProgress, setHasProgress] = useState(false);

  const [proposalFlag, setProposalFlag] = useState();
  const [messages, SetMessages] = useState({
    memberList: [],
  });

  useEffect(() => {
    // 画面離脱時
    dispatch(clearApiStatus('fetchPropertyDetail'));
    dispatch(clearApiStatus('updateProperty'));
  }, [dispatch]);
  useEffect(() => {
    // 作品詳細取得
    dispatch(fetchPropertyDetail(propertySummaryCode));
  }, [dispatch, propertySummaryCode]);
  useEffect(() => {
    setProposalFlag(property?.proposalFlag);
    setMemberList(property?.memberList);
  }, [property?.memberList, property?.proposalFlag]);

  // 設定値変更時のバリデート
  const handleChange = useCallback((name, value) => {
    let error = [];
    // 企画申請可否
    if (name === 'proposalFlag') {
      setProposalFlag(value);
      error = validate(value, memberList);
    }
    // 担当グループ
    if (name === 'memberList') {
      setMemberList(value);
      error = validate(proposalFlag, value);
    }
    SetMessages( {'memberList': error } );
  }, [memberList, proposalFlag]);

  const onSubmit = useCallback(() => {
    // 送信前の一括チェック
    const result = { memberList : [] };
    result['memberList'] = validate(proposalFlag, memberList);
    if (Object.values(result).flat().length > 0) {
      SetMessages(result);
      setNeedFocusError(true);
      return;
    }

    // 作品更新用のパラメータに整形
    const params = {
      proposalFlag: proposalFlag,
      iconFilename: uploadedIcon || null,
      // 作品グループのメンバーリストからemployeeNameを除去したものを送付する
      memberList: memberList.map(member => { return {'employeeNo': member.employeeNo} }),
      detailUpdateDatetime: property?.detailUpdateDatetime,
    };
    // 更新処理呼び出し
    dispatch(updateProperty({propertySummaryCode: propertySummaryCode, params: params}));
  }, [dispatch, memberList, property?.detailUpdateDatetime, propertySummaryCode, proposalFlag, uploadedIcon]);

  // API受取
  useEffect(() => {
    if (apiStatus.updateProperty === 'finish') {
      dispatch(clearPropertyDetail());
      dispatch(clearApiStatus('updateProperty'));
      dispatch(pushMessage('作品情報を更新しました。'));
      dispatch(fetchPropertyDetail(propertySummaryCode));
      dispatch(fetchPropertyMst());
    }
  }, [apiStatus.updateProperty, dispatch, propertySummaryCode]);
  const loading = useMemo(() => {
    return [apiStatus?.fetchPropertyDetail, apiStatus?.updateProperty, apiStatusMst?.fetchPropertyMst].includes('loading') ? <LoadingOverlay /> : null;
  }, [apiStatus?.fetchPropertyDetail, apiStatus?.updateProperty, apiStatusMst?.fetchPropertyMst]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['memberList']);
  const [needFocusError, setNeedFocusError] = useState(false);
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.memberList.length > 0) {
      if (focusError('memberList')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.memberList.length, needFocusError]);

  return (
    <>
      <div className="wrap">
        <div className="title-border mb20">
          <h1 className="title">作品詳細</h1>
          <p className="link-pageback deco-line c-aniplex">
            <Link to='/aniplex/propertyList'><i className="icn pageback"></i>作品一覧へ戻る</Link>
          </p>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: 130 }}>作品コード</dt>
            <dd className="form-body">
              <div className="input-form wdt200">
                <input type="text" name="propertySummaryCode" title="作品コードは入力済みです"
                 aria-label="作品コード" value={property?.propertySummaryCode ?? ''} disabled />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name">作品名称</dt>
            <dd className="form-body">
              <div className="input-form wdt600">
                <input type="text" name="propertySummaryCode" title="作品名称は入力済みです"
                 value={property?.propertySummaryName ?? ''} aria-label="作品名称" disabled />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">作品名称カナ</dt>
            <dd className="form-body">
              <div className="input-form wdt600">
                <input type="text" name="propertySummaryCode" title="作品名称カナは入力済みです"
                 value={property?.propertySummaryNameKana ?? ''} aria-label="作品名称カナ" disabled />
              </div>
            </dd>
          </dl>

        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: 130 }}>企画申請可否</dt>
            <dd className="form-body">
              <fieldset>
                <legend>企画申請可否</legend>
                <ul className="list-form ml0">
                <li className="ml0">
                  <input type="checkbox" id="check-01" name="proposalFlag"
                    checked={proposalFlag ?? false} disabled={!editable}
                    onChange={(event) => handleChange('proposalFlag', event.target.checked) } />
                  <label htmlFor="check-01" className="form-checkbox"></label>
                </li>
                </ul>
              </fieldset>
            </dd>
          </dl>
        </div>

        {/** アイコン1ファイルのみ、表示はパス設定 */}
        <PropertyIconForm
          uploadFileName={property?.iconFilename ?? ''}
          setUploadedIcon={setUploadedIcon}
          setHasError={setHasError}
          setHasProgress={setHasProgress}
          editable={editable}
        />
        {
          editable && (hasError || hasProgress) && (
            <ul className="error_list" style={{ textAlign: 'center', paddingBottom: '20px' }}>
              {/* エラーがあった場合の表示 */}
              {hasError && (
                <li className="error_list-item">アイコン画像にエラーがあります。</li>
              )}
              {/* アップロード中画像があった場合の表示 */}
              {hasProgress && (
                <li className="error_list-item">アップロード中の画像があります。</li>
              )}
            </ul>
          )
        }

        <PropertyGroupForm
          anxUsers={
            /** 氏名が空のユーザーは表示しない*/
            anxUsers.filter(a => a.employeeName !== null)
          }
          oldList={property?.memberList ?? []}
          handleChange={handleChange}
          editable={editable}
          formRefs={formRefs}
          messages={messages?.memberList}
        />

        {
          editable ?
          <div className="l-buttons mt20">
          <p className="btn bg-orange" style={{ width: 200 }}>
            <button onClick={onSubmit}>作品情報を更新</button>
          </p>
          </div> : null
        }
      </div>
      {loading}
    </>
  );
}

/**
 *
 */
export function validate(proposalFlag, memberList) {
  const errors = [];
  // 企画申請可能な場合、担当者は必須
  if (proposalFlag && memberList.length < 1) {
    return ['作品を企画申請可能にする場合は、作品担当グループに1名以上登録してください。']
  }
  return errors;
}
