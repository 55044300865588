import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearApiStatus, clearRegisteredAnnouncementId, registerAnnouncement, selectApiStatus, selectRegisteredAnnouncementId } from "../../../slices/aniplex/announcementSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { AnnouncementDetailForm } from "./AnnouncementDetailForm";

/**
 * [A]お知らせ登録
 */
export const AnnouncementRegisterPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus);
  const registeredAnnouncementId = useSelector(selectRegisteredAnnouncementId);

  /** 通信中フラグ */
  const apiLoading = useMemo(() => {
    return apiStatus.registerAnnouncement === 'loading';
  }, [apiStatus.registerAnnouncement]);

  // 画面離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('registerAnnouncement'));
      dispatch(clearRegisteredAnnouncementId());
    }
  }, [dispatch]);

  /** 登録ボタン押下時のコールバック */
  const onRegister = useCallback((params) => {
    dispatch(registerAnnouncement(params));
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus.registerAnnouncement === 'finish') {
      dispatch(pushMessage('お知らせの登録を行いました。'));
      // お知らせが登録されたら対応する詳細画面に遷移
      navigate(`/aniplex/announcementUpdate/${registeredAnnouncementId}`);
    }
  }, [apiStatus.registerAnnouncement, dispatch, navigate, registeredAnnouncementId])

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">お知らせ登録</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/announcementList' >
            <i className="icn pageback"></i>お知らせ一覧へ戻る
          </Link>
        </p>
      </div>

      <AnnouncementDetailForm
        submitBtn="お知らせを登録"
        execType="登録"
        showSubmit={true}
        onSubmit={onRegister}
        formLocked={apiLoading} />

      {
        apiLoading && <LoadingOverlay />
      }
    </div>
  );
}
