import { useMemo, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PasswordUpdateForm } from "../shared/PasswordUpdateForm";
import { expiredPasswordUpdated } from "../../../slices/licensee/authSlice";
import { updateMyself, selectApiStatus, clearApiStatus, fetchMyself } from "../../../slices/licensee/usersSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay"
import { useAuthRedirect } from "../../../lib/hooks/licensee";

/**
 * パスワード変更画面
 * @returns
 */
export const PasswordUpdatePage = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).update;
  const authRedirect = useAuthRedirect();

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('update'));
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  const canSubmit = useMemo(() => {
    return apiStatus !== 'loading' && apiStatus !== 'finish';
  }, [apiStatus])

  // 初期パスワード変更完了したら期限切れパスワード変更済みにする
  useEffect(() => {
    if (apiStatus === 'finish') {
      dispatch(expiredPasswordUpdated());
      dispatch(fetchMyself());
    }
  }, [dispatch, apiStatus]);

  if (authRedirect !== '/licensee/passwordUpdate') {
    // 期限切れパスワード変更待ち以外は対応する画面へリダイレクト
    return (
      // ログイン画面に戻る
      <Navigate to={authRedirect ?? '/licensee/home'} />
    );
  }

  return (
    <>
      <h1 className="main_login-head">パスワード変更</h1>
      <div className="main_login-body wrap">
        <p className="main_login-read tac">
          パスワードの有効期限が切れています。<br />新しいパスワードを設定して、パスワード変更ボタンを押してください。
        </p>
        <PasswordUpdateForm
          canSubmit={canSubmit}
          onSubmit={(userData) => dispatch(updateMyself(userData))}
         />
      </div>
      {loading}
    </>
  );
}
