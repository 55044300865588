import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Constants } from '../../../Constants';
import { useAccordion } from '../../../lib/hooks/common';
import { comma3, emptyToHyphen, formatPeriodYM } from '../../../lib/util';
import { ProductDetail } from '../proposalDetail/productDetail';
import { getColNumBeforeId, SpannableTableView } from '../../common/table/SpannableTableView';

/**
 * 企画状況参照画面の商品リスト表示
 * @param {object} props
 * @param {Proposal} props.proposal 企画情報
 * @param {Boolean} updateBtnVisible ロールに基づくボタンの表示可否
 * @returns
 */
export const ProposalProductsForm = ({ proposal, updateBtnVisible }) => {
  // 列表示フラグ
  const [showColFlg, setShowColFlg] = useState(() => getDefaultShowColFlg(proposal));
  // 項目表示制御エリア表示フラグ
  const [showFilterArea, setShowFilterArea] = useState(false);
  // テーブルヘッダ定義
  const [tableHeaders, setTableHeaders] = useState(getTableHeaders(proposal, showColFlg));
  // 選択状態のレコード
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  // 商品ポップアップ
  const [productPopup, setProductPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {Product} 対象商品 */
    target: null,
    /** @type {Function} 閉じたときのコールバック */
    onClose: null,
  });

  // アコーディオン
  const { accordionRef, accordionStyle, onChangeOpen } = useAccordion();

  const onFilterAreaClick = useCallback(() => {
    const isOpen = !showFilterArea;
    setShowFilterArea(isOpen);
    onChangeOpen(isOpen);
  }, [onChangeOpen, showFilterArea]);

  /** 表示項目数 */
  const showColNum = useMemo(() => {
    return Object.values(showColFlg).filter(v => v).length;
  }, [showColFlg]);

  /** 期軸の表示設定判定 */
  const isNotDispPeriodItems = useMemo(() => {
    // 期の選択
    let isDispPeriod = false;
    for (const[, pr] of Object.entries(proposal?.periodList ?? [])) {
      const prKey = `period${pr.period}`;
      if (showColFlg[prKey]) {
        isDispPeriod = true;
        break;
      }
    };
    // 期間合計の選択
    if (!isDispPeriod) isDispPeriod = showColFlg.periodTotal;

    // 期もしくは期の項目のいずれかの項目だけ選択されている場合はNG
    return !(isDispPeriod ^ (!showColFlg.planNum && !showColFlg.labelNum && !showColFlg.resultProduction && !showColFlg.resultSales));
  }, [proposal?.periodList, showColFlg]);

  useEffect(() => {
    if (proposal != null) {
      // 企画情報が取得出来たらN期部分のフラグを追加する
      setShowColFlg(prev => {
        const newVal = {
          ...getDefaultShowColFlg(proposal),
          ...prev,
        };
        setTableHeaders(getTableHeaders(proposal, newVal));
        return newVal;
      })
    }
  }, [proposal]);

  /**
   * 列表示フラグ変更時のハンドラ
   * @param {keyof showColFlg} name 変更された項目
   * @param {boolean} checked チェック状態
   */
  const onShowFlgChange = useCallback((name, checked) => {
    setShowColFlg(prev => ({
      ...prev,
      [name]: checked,
    }));
  }, []);

  /** 項目の表示切替ボタン押下時のハンドラ */
  const onChangeColClick = useCallback(() => {
    setTableHeaders(getTableHeaders(proposal, showColFlg));
  }, [proposal, showColFlg]);

  /** レコードの選択状態変更時のコールバック */
  const onCheck = useCallback((idx, checked) => {
    if (checked) {
      // インデックスを追加してユニークにする
      setSelectedIndexes(prev => (
        [...prev, idx].filter((el, i, self) => self.indexOf(el) === i)
      ));
    } else {
      setSelectedIndexes(prev => prev.filter(el => el !== idx));
    }
  }, []);

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((idx) => {
    const target = proposal.productList[idx];
    setProductPopup({
      showFlg: true,
      target,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      },
    });
  }, [proposal?.productList]);

  /** データレコード */
  const records = useMemo(() => {
    return convertProductToRecords({
      productList: proposal?.productList ?? [],
      periodList: proposal?.periodList ?? [],
      headers: tableHeaders,
      onProductNameClick,
      onCheck,
      selectedIndexes,
      updateBtnVisible
    });
  }, [proposal?.productList, proposal?.periodList, tableHeaders,
    onProductNameClick, onCheck, selectedIndexes, updateBtnVisible]);

    /** 合計行表示フラグ */
    const hasTotalRow = useMemo(() =>
      tableHeaders.find(h => h.id === 'royalty') != null
    , [tableHeaders]);

  /** 合計行表示時のCSSクラス名 */
  const hasTotalRowClass = useMemo(() => {
    return showColFlg.royalty ? 'has-total-row' : '';
  }, [showColFlg.royalty]);

  /** 固定する列数 */
  const fixedCols = useMemo(() => {
    let result = 0;
    if (tableHeaders.find(h => h.id === 'productNo') != null) {
      result++;
    }
    if (tableHeaders.find(h => h.id === 'productName') != null) {
      result++;
    }
    if (tableHeaders.find(h => h.id === 'character') != null) {
      result++;
    }
    return result;
  }, [tableHeaders]);

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title"><i className="icn box"></i>許諾申請内容</h2>
      </div>

      <div className="mt20">
        <dl className="l-acordion">
          <dt className={`js-toggle_trigger acordion-head ${showFilterArea ? 'is-open' : ''}`}
            onClick={onFilterAreaClick}
          >項目の表示／非表示設定</dt>
          <dd
            ref={accordionRef}
            className={`js-toggle_body acordion-body`}
            style={accordionStyle}
          >
            <fieldset style={{ display: 'block' }}>
              <legend>項目の表示／非表示設定</legend>
              <ul className="list-form">
                <li>
                  <input type="checkbox" id="check-00-01"
                    name="項目の表示／非表示設定"
                    checked={showColFlg.productNo}
                    onChange={ev => onShowFlgChange('productNo', ev.target.checked)} />
                  <label htmlFor="check-00-01" className="form-checkbox">品番</label>
                </li>
                <li>
                  <input type="checkbox" id="check-00-02"
                    name="項目の表示／非表示設定"
                    checked={showColFlg.productName}
                    onChange={ev => onShowFlgChange('productName', ev.target.checked)} />
                  <label htmlFor="check-00-02" className="form-checkbox">商品名等</label>
                </li>
                <li>
                  <input type="checkbox" id="show-col-character"
                    name='項目の表示／非表示設定'
                    checked={showColFlg.character}
                    onChange={ev => onShowFlgChange('character', ev.target.checked)} />
                  <label htmlFor="show-col-character" className='form-checkbox'>キャラクター</label>
                </li>
                <li>
                  <input type="checkbox" id="check-00-04"
                    name="項目の表示／非表示設定"
                    checked={showColFlg.sampleDate}
                    onChange={ev => onShowFlgChange('sampleDate', ev.target.checked)} />
                  <label htmlFor="check-00-04" className="form-checkbox">サンプル送付</label>
                </li>
                <li>
                  <input type="checkbox" id="check-00-05"
                    name="項目の表示／非表示設定"
                    value="完成イメージ"
                    checked={showColFlg.productImage}
                    onChange={ev => onShowFlgChange('productImage', ev.target.checked)} />
                  <label htmlFor="check-00-05" className="form-checkbox">完成イメージ</label>
                </li>
                <li>
                  <input type="checkbox" id="check-00-06"
                    name="項目の表示／非表示設定"
                    value="上代（税抜き）・製造原価"
                    checked={showColFlg.priceCost}
                    onChange={ev => onShowFlgChange('priceCost', ev.target.checked)} />
                  <label htmlFor="check-00-06" className="form-checkbox">上代（税抜き）・製造原価</label>
                </li>
                </ul>
                  <details open>
                    <summary>期間を選択する</summary>
                    <ul className="list-form">
                    {
                      proposal?.periodList?.map(pr => (
                        <li key={pr.period}>
                          <input type="checkbox" id={`show-col-pr-${pr.period}`}
                            name="項目の表示／非表示設定"
                            checked={showColFlg[`period${pr.period}`] ?? false}
                            onChange={ev => onShowFlgChange(`period${pr.period}`, ev.target.checked)} />
                          <label htmlFor={`show-col-pr-${pr.period}`} className="form-checkbox">{`第${pr.period}期`}</label>
                        </li>
                      ))
                    }
                    <li>
                      <input type="checkbox" id="show-col-pr-total"
                        name="項目の表示／非表示設定"
                        checked={showColFlg.periodTotal}
                        onChange={ev => onShowFlgChange('periodTotal', ev.target.checked)} />
                      <label htmlFor="show-col-pr-total" className="form-checkbox">期間合計</label>
                    </li>
                    </ul>
                  </details>
                  <details open>
                  <summary>期間の表示項目を選択する</summary>
                  <ul className="list-form">
                    <li>
                      <input type="checkbox" id="check-00-14"
                        name="項目の表示／非表示設定"
                        checked={showColFlg.planNum}
                        onChange={ev => onShowFlgChange('planNum', ev.target.checked)} />
                      <label htmlFor="check-00-14" className="form-checkbox">予定生産数/予定販売数</label>
                    </li>
                    <li>
                      <input type="checkbox" id="check-00-15"
                        name="項目の表示／非表示設定"
                        checked={showColFlg.labelNum}
                        onChange={ev => onShowFlgChange('labelNum', ev.target.checked)} />
                      <label htmlFor="check-00-15" className="form-checkbox">証紙発行数</label>
                    </li>
                    <li>
                      <input type="checkbox" id="check-00-16"
                        name="項目の表示／非表示設定"
                        checked={showColFlg.resultProduction}
                        onChange={ev => onShowFlgChange('resultProduction', ev.target.checked)} />
                      <label htmlFor="check-00-16" className="form-checkbox">実績生産数</label>
                    </li>
                    <li>
                      <input type="checkbox" id="check-00-17"
                        name="項目の表示／非表示設定"
                        checked={showColFlg.resultSales}
                        onChange={ev => onShowFlgChange('resultSales', ev.target.checked)} />
                      <label htmlFor="check-00-17" className="form-checkbox">実績販売数</label>
                    </li>
                  </ul>
                  </details>
                <ul className="list-form" style={{ marginTop: 'auto' }}>
                <li>
                  <input type="checkbox" id="check-00-12"
                    name="項目の表示／非表示設定"
                    checked={showColFlg.royalty}
                    onChange={ev => onShowFlgChange('royalty', ev.target.checked)} />
                  <label htmlFor="check-00-12" className="form-checkbox">ロイヤリティ</label>
                </li>
                <li>
                  <input type="checkbox" id="check-00-13"
                    name="項目の表示／非表示設定"
                    checked={showColFlg.salesPrice}
                    onChange={ev => onShowFlgChange('salesPrice', ev.target.checked)} />
                  <label htmlFor="check-00-13" className="form-checkbox">販売金額</label>
                </li>
              </ul>
            </fieldset>
            <p className="btn bg-yellow mt20" style={{ width: '160px' }}>
              <button
                disabled={showColNum === 0 || isNotDispPeriodItems}
                onClick={onChangeColClick}
              >表示を切り替える</button>
            </p>
            {
              showColNum === 0 && (
                <p className="mt10 attention">表示する項目を1件以上選択してください</p>
              )
            }
            {
              isNotDispPeriodItems && (
                <p className="mt10 attention">期間を表示する場合は、期間内の表示項目を1件以上選択してください</p>
              )
            }
          </dd>
        </dl>
      </div>

      <SpannableTableView
        className={`scroll border0 mt20 ${hasTotalRowClass} header-2line` }
        headers={tableHeaders}
        records={records}
        scrollable={true}
        fixedCols={fixedCols}
        ignoreFixRows={hasTotalRow ? -1 : 0} />

      {
        productPopup.showFlg ?
        <ProductDetail
          product={productPopup.target}
          periodList={proposal.periodList}
          onClose={productPopup.onClose} /> : null
      }
    </section>
  );
}

/**
 * 企画情報をもとに列表示フラグのデフォルト値を取得する
 * @param {Proposal} proposal もとにする企画情報
 * @returns {ShowColFlg} 列表示フラグのデフォルト値
 */
function getDefaultShowColFlg(proposal) {
  const result = {
    /** 品番 */
    productNo: true,
    /** 商品名等 */
    productName: true,
    /** キャラクター */
    character: true,
    /** サンプル送付 */
    sampleDate: true,
    /** 完成イメージ */
    productImage: true,
    /** 上代・製造原価 */
    priceCost: true,
    /** 期間合計 */
    periodTotal: true,
    /** 予定生産数/予定販売数 */
    planNum: true,
    /** 証紙発行数 */
    labelNum: true,
    /** 実績生産数 */
    resultProduction: true,
    /** 実績販売数 */
    resultSales: true,
    /** ロイヤリティ */
    royalty: true,
    /** 販売金額 */
    salesPrice: true,
  }

  proposal?.periodList?.forEach(pr => {
    result[`period${pr.period}`] = true;
  });

  return result;
}

/**
 * 現在の列表示設定に基づいてテーブルのヘッダ定義を返す
 * @param {Proposal} proposal 企画情報
 * @param {ShowColFlg} showColFlg 列表示フラグ
 * @returns {TableHeader[]} テーブルヘッダ定義
 */
function getTableHeaders(proposal, showColFlg) {
  /** @type {TableHeader[]} */
  const headers = [];

  if (showColFlg.productNo) {
    headers.push({ id: 'productNo', label: '品番', style: { minWidth: '104px' }});
  }
  if (showColFlg.productName) {
    headers.push({ id: 'productName', label: '商品名等', style: { width: '161px', minWidth: '161px' }});
  }
  if (showColFlg.character) {
    headers.push({ id: 'character', label: 'キャラクター', style: { minWidth: '121px' }})
  }
  if (showColFlg.sampleDate) {
    headers.push({ id: 'sampleDate', label: 'サンプル\n送付', style: { minWidth: '92px' }});
  }
  if (showColFlg.productImage) {
    headers.push({ id: 'productImage', label: '完成\nイメージ', style: { minWidth: '92px' }});
  }
  if (showColFlg.priceCost) {
    headers.push({ id: 'priceCost', label: '上代（税抜き）・製造原価', className: 'bg-gray', children: [
      { id: 'planPriceCost', label: '予定', className: 'border-left', style: { minWidth: '94px' }},
      { id: 'latestPriceCost', label: '最新', style: { minWidth: '93px' }},
    ]});
  }

  proposal?.periodList?.forEach(pr => {
    const periodYm = formatPeriodYM(pr.ryStartDate, pr.ryEndDate);

    if (showColFlg[`period${pr.period}`]) {
      // 期軸の選択内容の表示制御
      const headerData = [];
      if (showColFlg.planNum) headerData.push({ id: `period${pr.period}PlanNum`, label: '予定生産数\n予定販売数', style: { minWidth: '93px' }});
      if (showColFlg.labelNum) headerData.push({ id: `period${pr.period}LabelNum`, label: '証紙発行数', style: { minWidth: '90px' }});
      if (showColFlg.resultProduction) headerData.push({ id: `period${pr.period}ResultProduction`, label: '実績生産数', style: { minWidth: '90px' }});
      if (showColFlg.resultSales) headerData.push({ id: `period${pr.period}ResultSales`, label: '実績販売数', style: { minWidth: '90px' }});
      headers.push({
        id: `period${pr.period}`,
        label: `第${pr.period}期\n${periodYm}`,
        className: 'bg-gray',
        children: headerData
      });
    }
  });

  if (showColFlg.periodTotal) {
    // 期軸の選択内容の表示制御
    const headerData = [];
    if (showColFlg.planNum) headerData.push({ id: 'periodTotalPlanNum', label: '予定生産数\n予定販売数', style: { minWidth: '93px' }});
    if (showColFlg.labelNum) headerData.push({ id: 'periodTotalLabelNum', label: '証紙発行数', style: { minWidth: '90px' }});
    if (showColFlg.resultProduction) headerData.push({ id: 'periodTotalResultProduction', label: '実績生産数', style: { minWidth: '90px' }});
    if (showColFlg.resultSales) headerData.push({ id: 'periodTotalResultSales', label: '実績販売数', style: { minWidth: '90px' }});

    headers.push({ id: 'periodTotal', label: '期間合計', className: 'bg-gray', children: headerData });
  }

  if (showColFlg.royalty) {
    headers.push({ id: 'royalty', label: 'ロイヤリティ', className: 'bg-gray', children: [
      { id: 'calPlanRyTarget', label: '予定ロイヤリ\nティ対象金額', style: { minWidth: '113px' }},
      { id: 'calResultRyTarget', label: '実績ロイヤリ\nティ対象金額', style: { minWidth: '110px' }},
      { id: 'ryRate', label: 'ロイヤリティ\n料率', style: { minWidth: '110px' }},
      { id: 'ryPrice', label: 'ロイヤリティ\n単価', style: { minWidth: '110px' }},
      { id: 'calPlanRyAmount', label: '予定\nロイヤリティ額', style: { minWidth: '120px' }},
      { id: 'calResultRyAmount', label: '実績\nロイヤリティ額', style: { minWidth: '120px' }},
    ]});
  }

  if (showColFlg.salesPrice) {
    headers.push({ id: 'salesPrice', label: '販売金額', className: 'bg-gray', children: [
      { id: 'calPlanProceeds', label: '予定', style: { minWidth: '90px' }},
      { id: 'calResultProceeds', label: '実績', style: { minWidth: '90px' }},
    ]});
  }

  return headers;
}

/**
 * 商品リストをテーブル表示用レコードの形式に変換する
 * @param {object} args
 * @param {Product[]} args.productList 商品リスト
 * @param {ProposalPeriod[]} args.periodList 第N期のリスト
 * @param {TableHeader[]} args.headers ヘッダ情報
 * @param {(idx: number) => void} args.onProductNameClick 商品名リンククリック時のコールバック
 * @returns {TableRecord[]} 変換後のレコードのリスト
 */
function convertProductToRecords({
  productList,
  periodList,
  headers,
  onProductNameClick,
}) {

  const result = productList.map((p, idx) => {
    const productImageRegDatetime = dayjs(p.productImageRegDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/M/D');
    const productImageEl = p.productImageRegDatetime ?
      <a href={p.productImageUrl} target='_blank' rel="noreferrer">{productImageRegDatetime}</a> : '未登録'
    /** @type {TableRecord} */
    const record = {
      productNo: p.productNo,
      productName: {
        el: <button onClick={() => onProductNameClick(idx)} className='link' style={{textAlign: 'left'}}>{p.productName}</button>,
        className: 'text-wrap',
        style: {
          maxWidth: '20vw',
        },
      },
      character: {
        el: p.character ?? '',
        className: 'text-wrap',
        style: {
          maxWidth: '20vw',
        },
      },
      sampleDate: {
        el: p.sampleDate || '未登録',
        className: 'tac',
      },
      productImage: {
        el: productImageEl,
        className: 'tac',
      },
      planPriceCost: {
        el: comma3(getPlanPriceCost(p)),
        className: 'cost'
      },
      latestPriceCost: {
        el: comma3(getLatestPriceCost(p)),
        className: 'cost',
      },
      calPlanRyTarget: {
        el: comma3(emptyToHyphen(p.calPlanRyTarget)),
        className: 'cost',
      },
      calResultRyTarget: {
        el: comma3(emptyToHyphen(p.calResultRyTarget)),
        className: 'cost',
      },
      ryRate: {
        el: comma3(emptyToHyphen(p.ryRate)),
        className: 'cost',
      },
      ryPrice: {
        el: comma3(emptyToHyphen(p.ryPrice)),
        className: 'cost',
      },
      calPlanRyAmount: {
        el: comma3(emptyToHyphen(p.calPlanRyAmount)),
        className: 'cost',
      },
      calResultRyAmount: {
        el: comma3(emptyToHyphen(p.calResultRyAmount)),
        className: 'cost',
      },
      calPlanProceeds: {
        el: comma3(emptyToHyphen(p.calPlanProceeds)),
        className: 'cost',
      },
      calResultProceeds: {
        el: comma3(emptyToHyphen(p.calResultProceeds)),
        className: 'cost',
      },
    };

    let planNumTotal = 0;
    let labelNumTotal = 0;
    let resultProductionTotal = 0;
    let resultSalesTotal = 0;

    periodList.forEach(pr => {
      const targetPeriod = findProductPeriod(p, pr.period);
      const planNum = getPeriodPlanNum(p, pr.period);
      const labelNum = emptyToHyphen(targetPeriod?.resultLabel);
      const resultProduction = emptyToHyphen(targetPeriod?.resultProduction);
      const resultSales = emptyToHyphen(targetPeriod?.resultSales);

      record[`period${pr.period}PlanNum`] = {
        el: comma3(planNum),
        className: 'cost',
      };
      record[`period${pr.period}LabelNum`] = {
        el: comma3(labelNum),
        className: 'cost',
      };
      record[`period${pr.period}ResultProduction`] = {
        el: comma3(resultProduction),
        className: 'cost',
      };
      record[`period${pr.period}ResultSales`] = {
        el: comma3(resultSales),
        className: 'cost',
      };
      planNumTotal += planNum === '-' ? 0 : planNum;
      labelNumTotal += labelNum === '-' ? 0 : labelNum;
      resultProductionTotal += resultProduction === '-' ? 0 : resultProduction;
      resultSalesTotal += resultSales === '-' ? 0 : resultSales;
    });

    record.periodTotalPlanNum = {
      el: comma3(emptyToHyphen(planNumTotal)),
      className: 'cost',
    };
    record.periodTotalLabelNum = {
      el: comma3(emptyToHyphen(labelNumTotal)),
      className: 'cost',
    };
    record.periodTotalResultProduction = {
      el: comma3(emptyToHyphen(resultProductionTotal)),
      className: 'cost',
    };
    record.periodTotalResultSales = {
      el: comma3(emptyToHyphen(resultSalesTotal)),
      className: 'cost',
    };

    return record;
  });

  // 合計行を追加
  if (headers.find(h => h.id === 'royalty') != null) {
    const calPlanRyAmountTotal = productList.reduce((prev, cur) => prev + (cur.calPlanRyAmount || 0), 0);
    const calResultRyAmountTotal = productList.reduce((prev, cur) => prev + (cur.calResultRyAmount || 0), 0);
    const beforeColNum = getColNumBeforeId(headers, 'ryRate');
    result.push({
      _tr: {
        className: 'total',
      },
      [headers[0].id]: {
        el: '',
        colSpan: beforeColNum,
      },
      ryRate: {
        el: '合計',
        colSpan: 2,
        className: 'total-head',
      },
      calPlanRyAmount: {
        el: comma3(calPlanRyAmountTotal),
        className: 'cost bb-solid-tblcolor fwb',
      },
      calResultRyAmount: {
        el: comma3(calResultRyAmountTotal),
        className: 'cost bb-solid-tblcolor fwb',
      },
      calPlanProceeds: {
        el: '',
        colSpan: 2,
      },
    });
  }

  return result;
}

/**
 * 商品情報から予定上代・製造原価の表示内容を取得する
 * @param {Product} product 商品情報
 * @returns {string} 予定上代・製造原価の表示内容
 */
function getPlanPriceCost(product) {
  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代
      return emptyToHyphen(product.planPrice);
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代
      return emptyToHyphen(product.planPrice);
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価
      return emptyToHyphen(product.planCost);
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から最新上代・製造原価の表示内容を取得する
 * @param {Product} product 商品情報
 * @returns {string} 最新上代・製造原価の表示内容
 */
function getLatestPriceCost(product) {
  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代
      return emptyToHyphen(product.latestPrice);
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代
      return emptyToHyphen(product.latestPrice);
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価
      return emptyToHyphen(product.latestCost);
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から対象の第N期の情報を取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の第N期
 * @returns {ProductPeriod|null} 見つかった第N期の情報
 */
function findProductPeriod(product, period) {
  return product.periodList?.find(p => p.period === period) ?? null;
}

/**
 * 商品情報から第N期の予定生産数・予定販売数の表示内容を取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の第N期
 * @returns {string} 表示内容
 */
function getPeriodPlanNum(product, period) {
  const targetPeriod = findProductPeriod(product, period);

  if (!targetPeriod) {
    // 対象の期が見つからなかった場合は非表示
    return '-';
  }

  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 生産数
      return emptyToHyphen(targetPeriod.planProduction);
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 販売数
      return emptyToHyphen(targetPeriod.planSales);
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 生産数
      return emptyToHyphen(targetPeriod.planProduction);
    // 生産数×ロイヤリティ単価
    case Constants.RoyaltyPattern.ProductNumUniPrice:
      // 生産数
      return emptyToHyphen(targetPeriod.planProduction);
    // 販売数×ロイヤリティ単価
    case Constants.RoyaltyPattern.SalesNumUnitPrice:
      // 販売数
      return emptyToHyphen(targetPeriod.planSales);
    default:
      // 上記以外は非表示
      return '-';
  }
}

//#region
/**
 * @typedef {import('./ProposalStatusForm').Proposal} Proposal 企画情報
 */
/**
 * @typedef {import('./ProposalStatusForm').ProposalPeriod} ProposalPeriod 第N期の情報
 */
/**
 * @typedef {import('./ProposalStatusForm').Product} Product 商品情報
 */
/**
 * @typedef {import('./ProposalStatusForm').ProductPeriod} ProductPeriod 商品の第N期の情報
 */
/**
 * @typedef {FixedShowColFlg & Record<string, boolean>} ShowColFlg 列表示フラグ
 */
/**
 * @typedef {object} FixedShowColFlg 列表示フラグの固定部分
 * @property {boolean} productNo 品番
 * @property {boolean} productName 商品名等
 * @property {boolean} character キャラクター
 * @property {boolean} sampleDate サンプル送付
 * @property {boolean} productImage 完成イメージ
 * @property {boolean} priceCost 上代・製造原価
 * @property {boolean} periodTotal 期間合計
 * @property {boolean} royalty ロイヤリティ
 * @property {boolean} salesPrice 販売金額
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader テーブルのヘッダ定義
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブルのデータ行
 */
//#endregion
