import { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { equals, getExtension, getImageType } from "../util.js";
import { useLocation } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

/**
 * ファイルアップロードのhook
 * @param {object} params
 * @param {boolean} params.isImageMode 画像モードフラグ
 * @param {({ renderingImageNo: string }) => Promise} params.apiGetImage 画像取得APIの呼出し処理
 * @param {({ filename: string }) => Promise} params.apiUploadFile ファイルアップロードAPIの呼出し処理
 * @param {({ fileNo: string }) => Promise} params.apiDownloadFile ファイルダウンロードAPIの呼出し処理
 * @param {string[]} params.acceptableExt 許容するファイル拡張子のリスト
 * @param {number} [params.maxFileSize] 許容するファイルサイズの最大値(単位：バイト)
 * @param {string} [params.maxFileSizeErrMsg] ファイルサイズ長梶野エラーメッセージ
 */
export const useFileUpload = ({
  isImageMode = false,
  apiGetImage,
  apiUploadFile,
  apiDownloadFile,
  acceptableExt = [],
  maxFileSize,
  maxFileSizeErrMsg,
}) => {
  // サムネイル一覧の項目
  const [fileEntries, setFileEntries] = useState([]);

  // サムネイル一覧の項目の状態を変更する
  const updateFileStatus = useCallback(newEntry => new Promise(resolve => {
    setFileEntries(fileEntries => {
      // keyの一致する物を置換する
      const a = fileEntries.map(entry => (entry.key === newEntry.key) ? newEntry : entry);
      resolve(); // ここまで待機
      return a; // 置換後の配列で更新
    });
  }), []);

  // アップロード進捗のコールバック
  const onProgress = useCallback((progressEvent, entry) => {
    const newEntry = {
      ...entry,
      maxSize: progressEvent.total,
      uploadedSize: progressEvent.loaded
    };
    updateFileStatus(newEntry);
    return newEntry;
  }, [updateFileStatus]);

  // アップロードエラー
  const onError = useCallback((errorMessage, entry) => {
    updateFileStatus({
      ...entry,
      hasError: true,
      errorMessage: errorMessage || '',
    });
  }, [updateFileStatus]);

  // ファイル単位のアップロード処理
  const startUploading = useCallback(async file => {
    const filename = file.name;

    // サムネイル用の項目を用意
    let entry = {
      // 表示用の一意なkey値
      key: (filename || '') + Math.random(),
      // オリジナルのファイル名
      name: filename || '',
      // ファイルサイズ
      maxSize: file.size,
      // 送信済みサイズ(最初は0)
      uploadedSize: 0,
      // 送信完了
      completed: false,
      // サムネイル画像のsrc属性に指定する値(最初は空)
      imageSrc: '',
      // 署名付きダウンロードURL
      downloadLink: '',
      // ファイル番号
      fileNo: '',
      // エラーか?
      hasError: false,
      // エラーメッセージ
      errorMessage: '',
    };

    // 項目を配列に追加
    setFileEntries(fileEntries => [...fileEntries, entry]);

    if (maxFileSize != null && maxFileSize < file.size) {
      const errMsg = maxFileSizeErrMsg || 'ファイルサイズが大きすぎます。';
      onError(errMsg, entry);
      return;
    }

    // アップロード中のサムネイル表示用にローカルの画像を取得
    if (isImageMode) {
      const imageSrc = await readUrlFromFile(file);
      const base64 = imageSrc.replace(/^data:[^;]+;base64,/, '');
      const imageType = getImageType(base64);

      // 画像でない場合はエラーとする
      if (!imageType) {
        onError('アップロードできないファイル形式です。', entry);
        return;
      }

      // マジックナンバーは正常だけれども拡張子が存在しない画像はAPIでエラーになるので抑止する
      const ext = getExtension(filename).toLowerCase();
      if (!ext) {
        onError('アップロードできないファイル形式です。', entry);
        return;
      }

      // 画像であれば
      entry = {...entry, imageSrc};
      await updateFileStatus(entry);
    } else {
      // 画像以外は拡張子チェックのみとする
      const ext = getExtension(filename).toLowerCase();
      const acceptable = acceptableExt.some(a => a.toLowerCase() === ext);
      if (!acceptable) {
        onError('アップロードできないファイル形式です。', entry);
        return;
      }
    }

    // 署名付きアップロードURLを取得
    let data;
    try {
      data = (await apiUploadFile({ filename }))?.result?.data ?? {};
    } catch {
      onError('アップロード中に通信エラーが発生しました。', entry);
      return;
    }
    const { fileNo, uploadPresignedUrl } = data;
    if (!fileNo || !uploadPresignedUrl) {
      onError('アップロード中に通信エラーが発生しました。');
      return;
    }

    // fileNoを付与
    entry = { ...entry, fileNo };
    await updateFileStatus(entry);

    // 署名付きアップロードURLへのPUTを開始
    // 画像アップロードでない場合は、強制ダウンロードのためoctet-streamを指定
    const contentType = isImageMode ? file.type : 'application/octet-stream';
    try {
      await axios.put(uploadPresignedUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
        onUploadProgress: progressEvent => {
          entry = onProgress(progressEvent, entry);
        },
      });
      entry = { ...entry, completed: true };
      await updateFileStatus(entry);
    } catch {
      onError('アップロード中に通信エラーが発生しました。', entry);
      return;
    }

    if (!isImageMode) {
      // 画像でない場合はダウンロードリンクを取得
      try {
        const downloadLink = await apiDownloadFile(fileNo);
        entry ={ ...entry, downloadLink };
        await updateFileStatus(entry);
      } catch {
        // エラーは無視
      } finally {
        // 何も返さないのでfinally内でreturnしても問題ない
        return;
      }
    }

    // アップロードした画像を画像参照APIから取得
    try {
      const data = (await apiGetImage({ renderingImageNo: fileNo }))?.result?.data ?? {};
      const { imageBinary } = data;
      const imageType = imageBinary && getImageType(imageBinary);

      if (imageBinary && imageType) {
        const imageSrc = 'data:' + imageType + ';base64,' + imageBinary;
        // 画像参照APIから返却された画像に差替え
        entry = { ...entry, imageSrc };
        await updateFileStatus(entry);
      }
    } catch {
      // エラーは無視
    }
  }, [maxFileSize, isImageMode, updateFileStatus, maxFileSizeErrMsg, onError, acceptableExt, apiUploadFile, onProgress, apiDownloadFile, apiGetImage]);

  return [fileEntries, setFileEntries, startUploading];
};

/**
 * File型からdataURLを取得する
 */
const readUrlFromFile = file => new Promise(resolve => {
  const reader = new FileReader();

  reader.onload = event => {
    resolve(event?.target?.result ?? '');
  };
  reader.onerror = () => resolve(''); // エラーは無視
  reader.readAsDataURL(file);
});

/**
 * アコーディオンのhook
 * @param {object} params
 * @param {string} [params.duration] アニメーション時間
 * @param {string} [params.easing] イージング関数
 * @param {string} [params.delay] 遅延時間
 * @param {string} [params.maxHeight] 最大縦幅
 * @returns {UseAccordionResponse} アコーディオン制御用のプロパティ群
 */
export const useAccordion = ({ duration = '.3s', easing = 'ease-in-out', delay = '0s', maxHeight = '30em' } = {}) => {
  const baseStyle = useMemo(() => ({
    transitionProperty: 'max-height, padding, margin',
    transitionDuration: duration,
    transitionDelay: delay,
    transitionTimingFunction: easing,
    overflowY: 'hidden',
    maxHeight,
  }), [delay, duration, easing, maxHeight]);

  const accordionRef = useRef();

  // 初期状態は閉じておく
  // TODO: 初期状態で開く画面があれば、propsにフラグを追加してsetStyleを呼ばないようにする
  const [style, setStyle] = useState({
    overflowY: 'hidden',
    maxHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  });

  const onChangeOpen = useCallback((isOpen) => {
    if (isOpen) {
      setStyle(baseStyle);
    } else {
      setStyle({
        ...baseStyle,
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
      });
    }
  }, [baseStyle]);

  return { accordionRef, onChangeOpen, accordionStyle: style }
}

/**
 * react-routerによるルーティング設定に設定されたクエリパラメータを取得する
 * @returns {Record<string, string>} クエリパラメータ
 */
export const useSearchParams = () => {
  const location = useLocation();

  const searchParams = useMemo(() => {
    return Object.fromEntries(
      location.search.replace(/^\?/, '')
        .split('&').map(a => a.split('='))
    );
  }, [location]);

  return searchParams;
}

/**
 * フォームデータのカスタムhook
 * @template T, U
 * @param {T|() => T} initialData 初期値
 * @param {(prop: keyof T, value: T[prop], entireData: T) => U} validator バリデート関数
 * @param {boolean} [forceValidateFlg] 全項目を強制的にバリデートするかのフラグ
 * @returns {[T, (prop: keyof T, value: T[prop], suppressValidate: boolean) => void, Partial<Record<keyof T, U>>]} データ, 変更のハンドラ, バリデートエラーメッセージ
 * @example
 * const [dog, onChangeDog, dogErrors] = useFormData({ name: '', age: 0 }, validate);
 * onChangeDog('name', 'John');
 * console.error(dogErrors?.name);
 */
export const useFormData = (initialData, validator, forceValidateFlg = false) => {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const [modifiedProp, setModifiedProp] = useState(null);

  const handleChange = useCallback(
    /**
     * 値変更時のハンドラ
     * @param {keyof T} prop 対象のプロパティ名
     * @param {T[prop]} value 対象の値
     * @param {boolean} suppressValidate バリデート抑止フラグ
     */
    (prop, value, suppressValidate = false) => {
      setData(prev => ({
        ...prev,
        [prop]: value
      }));
      if (!suppressValidate) {
        setModifiedProp(prop);
      }
    }, []);

  useEffect(() => {
    if (typeof validator !== 'function') return;

    // 変更された項目のバリデート
    if (modifiedProp == null) return;
    setErrors(prev => ({
      ...prev,
      [modifiedProp]: validator(modifiedProp, data[modifiedProp], data),
    }));
    setModifiedProp(null);
  }, [data, modifiedProp, validator]);

  useEffect(() => {
    if (typeof validator !== 'function') return;

    if (!forceValidateFlg) return;

    // 強制バリデートフラグ有効時
    Object.entries(data).forEach(([prop, value]) => {
      setErrors(prev => ({
        ...prev,
        [prop]: validator(prop, value, data),
      }));
    });
  }, [data, forceValidateFlg, validator]);

  return [data, handleChange, errors];
}

/**
 * アイコン画像アップロードのhook
 * @param {object} params
 * @param {({ filename: string }) => Promise} params.apiUploadFile ファイルアップロードAPIの呼出し処理
 */
export const useIconFileUpload = ({
  iconFileName,
  apiUploadFile
}) => {
  // サムネイル一覧の項目
  const [fileEntries, setFileEntries] = useState([]);

  // const updateFileName = useCallback(() => {
  //   const entry = {
  //     imageSrc: Config.PropertyIconPath + iconFileName
  //   };
  //   setFileEntries([entry]);
  // }, [iconFileName]);

  // サムネイル一覧の項目の状態を変更する
  const updateFileStatus = useCallback(newEntry => new Promise(resolve => {
    setFileEntries(fileEntries => {
      // 値上書き
      const a = fileEntries.map(e => newEntry);
      resolve(); // ここまで待機
      return a; // 置換後の配列で更新
    });
  }), []);

  const onProgress = useCallback((progressEvent, entry) => {
    const newEntry = {
      ...entry,
      maxSize: progressEvent.total,
      uploadedSize: progressEvent.loaded
    };
    updateFileStatus(newEntry);
    return newEntry;
  }, [updateFileStatus]);

  // アップロードエラー
  const onError = useCallback((errorMessage, entry) => {
    updateFileStatus({
      ...entry,
      hasError: true,
      errorMessage: errorMessage || '',
    });
  }, [updateFileStatus]);

  // ファイル単位のアップロード処理
  const startUploading = useCallback(async file => {
    const filename = file.name;

    // サムネイル用の項目を用意
    let entry = {
      // 表示用の一意なkey値
      key: (filename || '') + Math.random(),
      // オリジナルのファイル名
      name: filename || '',
      // ファイルサイズ
      maxSize: file.size,
      // 送信済みサイズ(最初は0)
      uploadedSize: 0,
      // 送信完了
      completed: false,
      // サムネイル画像のsrc属性に指定する値(最初は空)
      imageSrc: '',
      // エラーか?
      hasError: false,
      // エラーメッセージ
      errorMessage: '',
    };

    // 項目を配列に追加
    setFileEntries([entry]);

    // アップロード中のサムネイル表示用にローカルの画像を取得
    const imageSrc = await readUrlFromFile(file);
    const base64 = imageSrc.replace(/^data:[^;]+;base64,/, '');
    const imageType = getImageType(base64);

    // 画像でない場合はエラーとする
    if (!imageType) {
      onError('アップロードできないファイル形式です。', entry);
      return;
    }

    // 画像であれば
    entry = {...entry, imageSrc};
    await updateFileStatus(entry);

    // 署名付きアップロードURLを取得
    let data;
    try {
      data = (await apiUploadFile({ filename }))?.result?.data ?? {};
    } catch {
      onError('アップロード中に通信エラーが発生しました。', entry);
      return;
    }
    const { iconFilename, uploadPresignedUrl } = data;
    if (!iconFilename || !uploadPresignedUrl) {
      onError('アップロード中に通信エラーが発生しました。');
      return;
    }

    // ファイル名を付与
    entry = { ...entry, iconFilename };
    await updateFileStatus(entry);

    // 署名付きアップロードURLへのPUTを開始
    // 画像アップロードでない場合は、強制ダウンロードのためoctet-streamを指定
    const contentType =  file.type;
    try {
      await axios.put(uploadPresignedUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
        onUploadProgress: progressEvent => {
          entry = onProgress(progressEvent, entry);
        },
      });
      entry = { ...entry, completed: true };
      await updateFileStatus(entry);
    } catch {
      onError('アップロード中に通信エラーが発生しました。', entry);
      return;
    }

    try {
      entry = { ...entry, name: iconFilename };
      await updateFileStatus(entry);
    } catch {
    }

  }, [apiUploadFile, onError, onProgress, updateFileStatus]);

  return [fileEntries, setFileEntries, startUploading];
}

/**
 * @typedef {string|{name: string|number, items: RefTarget[]}} RefTarget
 */

/**
 * エラー項目にフォーカスする
 * @param {RefTarget[]} props フォーム項目のプロパティ名のリスト
 * @returns {[React.MutableRefObject<Record<string, React.MutableRefObject>>, (...props: (string|number)[]) => boolean]} フォーム項目に設定するref, フォーカス用のメソッド
 */
export const useFocusError = (props) => {
  /** refの中身を再帰的に生成する */
  const generateRef = useCallback((refs, prop) => {
    let ref = createRef();
    let name;

    if (typeof prop === 'object') {
      name = prop.name;
      ref.current = {};
      for (const item of prop.items) {
        generateRef(ref.current, item);
      }
    } else {
      name = prop;
    }

    refs[name] = ref;
  }, []);

  // propsの一時保存
  const [tmpProps, setTmpProps] = useState([]);

  useEffect(() => {
    // propsがFC内で宣言されている場合にrefの生成処理が
    // レンダリングの度に呼び出されるので一時変数に保持することで抑止
    if (!equals(tmpProps, props)) {
      setTmpProps(props);
    }
  }, [props, tmpProps]);

  // フォーム項目へのref
  const [formRefs, setFormRefs] = useState({ current: {} });
  useEffect(() => {
    const ref = createRef();
    ref.current = {};

    for (const prop of tmpProps) {
      generateRef(ref.current, prop);
    }

    setFormRefs(ref)
  }, [generateRef, tmpProps]);

  /**
   * フォーム項目にフォーカスを合わせる処理
   */
  const focusError = useCallback((...props) => {
    // 入力系要素
    const inputElClass = [
      HTMLInputElement,
      HTMLTextAreaElement,
      HTMLSelectElement,
    ];

    const target = (() => {
      // ネストされたrefを取得
      let tmp = formRefs.current;
      for (const prop of props) {
        tmp = tmp[prop]?.current;
      }
      return tmp;
    })();
    const isInputEl = inputElClass.some(cls => target instanceof cls);

    if (isInputEl) {
      // 入力系の要素だったらフォーカスを合わせる
      target.focus();
      if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
        try {
          // カーソル位置を合わせられる場合は先頭にする
          target.setSelectionRange(0, 0);
        } catch {
          // inputのtypeによってはエラーになるので握りつぶす
        }
      }
      return true;
    } else if (target instanceof Element) {
      // 入力系以外の要素だったら画面内にスクロールさせる
      target.scrollIntoView({ block: 'center', inline: 'center' });
      return true;
    } else if (target instanceof ReactDatePicker) {
      // datepickerの場合は内部のinputタグにフォーカスさせる
      target.input.focus();
      return true;
    } else if (typeof target?.focus === 'function') {
      // 上記以外のオブジェクトでfocusメソッドを持っていたらそれを呼び出す
      target.focus();
      return true;
    }
    // いずれにも当てはまらなかったらフォーカス失敗扱い
    return false;
  }, [formRefs]);

  return [formRefs, focusError];
}

/**
 * ResizeObserverを利用するためのカスタムフック
 * @param {React.RefObject<HTMLElement>[]} refs 監視対象の要素のref
 * @param {(entries: ResizeObserverEntry[]) => void} callback リサイズ時のコールバック
 */
export const useResizeObserver = (refs, callback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries);
    });

    for (const ref of refs) {
      ref.current && resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, [callback, refs]);
}

/**
 * フォームデータ保存処理
 * @template T
 * @param {object} params
 * @param {string} params.saveKey 保存時のフォーム識別キー
 * @param {T} [params.dataType] データ型特定用パラメータ
 * @param {(keyof PickByType<T, Date>)[]} [params.dateParams] Date型のプロパティ
 */
export const useSaveFormData = ({
  saveKey,
  dataType,
  dateParams,
}) => {
  // 保存されているデータ
  const [savedData, setSavedData] = useState(/** @type {T|null} */ (null));
  // 復元済みフラグ
  const [isRestored, setIsRestored] = useState(false);

  /** LocalStorageから取得したデータをパースする */
  const parseData = useCallback(
    /**
     * @param {string} json
     * @returns {T}
     */
    (json) => {
      const data = JSON.parse(json);
      /** @type {T} */
      //@ts-expect-error
      const parsedData = Object.fromEntries(
        Object.entries(data)
          .map(([key, val]) => {
            if ((/** @type {string[]} */(dateParams))?.includes(key)) {
              if (val) {
                // 値が空でないときのみDateとしてパース
                return [key, new Date(val)];
              }
            }
            return [key, val];
          })
      );
      return parsedData;
    }
  , [dateParams]);

  /** データ保存処理 */
  const saveData = useCallback(
    /** @param {T} data */
    (data) => {
      const json = JSON.stringify(data);
      localStorage.setItem(saveKey, json);
      setSavedData(data);
  }, [saveKey]);

  /** データ読み出し処理 */
  const restoreData = useCallback(() => {
    const json = localStorage.getItem(saveKey);
    if (json == null) {
      setSavedData(null);
      return;
    }
    setSavedData(parseData(json));
  }, [parseData, saveKey]);

  /** 保存済みデータクリア */
  const clearSavedData = useCallback(() => {
    localStorage.removeItem(saveKey);
    setSavedData(null);
  }, [saveKey]);

  // 最初にデータを読み出し
  useEffect(() => {
    restoreData();
    setIsRestored(true);
  }, [restoreData]);

  return {
    isRestored,
    savedData,
    saveData,
    restoreData,
    clearSavedData,
  };
}

//#region typedef
/**
 * @typedef {object} UseAccordionResponse アコーディオンのhookの返却値
 * @property {React.MutableRefObject} accordionRef 開閉する要素に設定するref
 * @property {(isOpen: boolean) => void} onChangeOpen 開閉状態が変更されたときの通知用コールバック
 * @property {React.CSSProperties} accordionStyle 開閉する要素に設定するstyle属性のプロパティ
 */
/**
 * @typedef {T extends U ? U : never} Includes
 * @template T
 * @template U
 */
/**
 * @typedef {{[R in keyof T as Includes<T[R], U> extends never ? never : R]: T[R]}} PickByType
 * @template T
 * @template U
 */
//#endregion typedef
