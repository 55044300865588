import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { PropertyGroupPopup } from "./PropertyGroupPopup";
import { ErrorMessageList } from "../../common/ErrorMessageList";

/**
 * 作品管理グループ所属ユーザー
 * @property {*} anxUsers ANXユーザーリスト
 * @property {*} oldList 登録済みユーザーリスト
 * @property {*} setMemberList ユーザーリスト更新処理
 * @property {*} editable 更新可否フラグ
 * @property {*} formRefs フォーム項目へのref
 * @property {*} messages メッセージ（担当グループメンバー）
 */
export const PropertyGroupForm = ({anxUsers, oldList, handleChange, editable, formRefs, messages}) => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteUsers, setDeleteUsers] = useState([]);
  // ANXユーザー選択ポップアップ
  const [memberPopup, setMemberPopup] = useState({
    showFlg: false, // 表示フラグ
    onClose: null,  // コールバック
  });
  // 削除予定ユーザーのemployeeNoリスト管理
  const onChangeUsers = useCallback((val, check) => {
    if (check) {
      setDeleteUsers(Array.from(new Set([...deleteUsers, val])));
    }  else {
      setDeleteUsers(deleteUsers.filter(d => d !== val));
    }
  }, [deleteUsers]);

  // ANXユーザー追加ポップアップ
  const addMemberPopup = useCallback(() => {
    setMemberPopup({
      showFlg: true,
      onClose: (btn, data) => {
        if (btn === 'submit') {
          let anxUserList = [];
          const mergeList = [...selectedUsers, ...data];
          if (selectedUsers.length > 0 && data.length > 0 ) {
            // 並び替え入れない方にしておく
            // const targetList = Array.from(new Set(mergeList.map(d => d.employeeNo))).sort();
            const targetList = Array.from(new Set(mergeList.map(d => d.employeeNo)));
            anxUserList = targetList.map(t => {
              return mergeList.find(m => m.employeeNo === t);
            });
          } else {
            anxUserList = mergeList;
          }
          setSelectedUsers(anxUserList);
          handleChange('memberList', anxUserList);
        }
        setMemberPopup({ showFlg: false, onClose: null, });
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  // 選択済みANXユーザー削除
  const delMember = useCallback(() => {
    if (!deleteUsers || deleteUsers.length < 1) {
      dispatch(pushMessage('ANIPLEXユーザーを選択してください。'));
      return;
    }
    // 未選択のユーザーのみリストに残す
    const targetUsers = selectedUsers.filter(s => !deleteUsers.includes(s.employeeNo));
    setSelectedUsers(targetUsers);
    handleChange('memberList', targetUsers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUsers, dispatch, selectedUsers]);

  useEffect(() => {
    // 設定済みのANXユーザーの並び替え
    const targetList = Array.from(new Set(oldList.map(o => o.employeeNo))).sort();
    const anxUserList = targetList.map(t => {
      return oldList.find(m => m.employeeNo === t);
    });
    setSelectedUsers(anxUserList);
  }, [oldList]);

  return (
    <>
      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title">作品担当グループ</h2>
        </div>

        {
          editable ?
            <div className="l-buttons">
              <p className="btn bg-yellow" style={{ width: 100 }}>
                <button
                  ref={formRefs.current.memberList}
                  onClick={addMemberPopup}>
                  <i className="icn plus"></i>追加
                </button>
              </p>

              <p className="btn c-aniplex" style={{ width: 100 }}>
                <button onClick={delMember}><i className="icn cross"></i>削除</button>
              </p>
            </div> : null
        }
      <ErrorMessageList messages={messages} />
      </section>

      <div className="l-table mt20">
        <table>
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: 39 }}></th>
              <th scope="col" style={{ minWidth: 300 }}>社員番号</th>
              <th scope="col" style={{ minWidth: 600 }}>氏名</th>
            </tr>
          </thead>
          <tbody>
          {
            selectedUsers && selectedUsers.length > 0 ?
            selectedUsers.map(
              s => <tr key={`check-${s.employeeNo}`}><th>
                  <fieldset>
                    {
                      editable ?
                      <>
                        <input type="checkbox" id={`check-${s.employeeNo}`}
                        onChange={event => onChangeUsers(s.employeeNo, event.target.checked)}/>
                        <label htmlFor={`check-${s.employeeNo}`} className="form-checkbox02">チェック</label>
                      </> : null
                    }
                  </fieldset>
                </th>
                <td>{s.employeeNo}</td>
                <td>{s.employeeName ?? ''}</td>
              </tr>
            ):
            (<tr><td colSpan={3}>作品担当グループにANIPLEXユーザーの情報がありません。</td></tr>)
          }
          </tbody>
        </table>
      {
        memberPopup.showFlg ?
        <PropertyGroupPopup anxUsers={anxUsers} onClose={memberPopup.onClose} /> : null
      }
      </div>
    </>
  );
}
