//@ts-check
import React from 'react';
import { UploadedImageList } from './UploadedImageList';

/**
 * ライセンシー側・画像アップロード(一覧のみ)
 * @param {object} params
 * @param {string[]} params.fileNoList
 * @param {string[]} [params.prevFileNoList]
 */
export const UploadImageShowOnly = ({ fileNoList, prevFileNoList }) => {
  return (fileNoList.length > 0 || (prevFileNoList?.length ?? 0) > 0) && (
    <div className="l-uploader-content mt15">
      <ul className="list-uploader-content">
        <UploadedImageList fileNoList={fileNoList} prevFileNoList={prevFileNoList} />
      </ul>
    </div>
  );
};
