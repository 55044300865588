import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { Navigate, Link  } from 'react-router-dom';
import { selectMyself } from "../../../slices/licensee/usersSlice";
import { fetchUsers, clearApiStatus, clearFetchUsers, selectApiStatus, fetchUserList, updateUser } from "../../../slices/licensee/licenseeUsersSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { MessagePopup } from "../../common/MessagePopup"
import { PagingTable } from "../../common/table/PagingTable";
import { TableCellButton } from "../../common/table/TableCellButton";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { licenseeUser } from "../../common/headerList";

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/**
 * ユーザー一覧画面
 * @returns
 */
export const UserListPage = () => {
  const authRedirect = useAuthRedirect();
  const dispatch = useDispatch();
  const [headers] = useState(licenseeUser);
  const [current, setCurrent] = useState(1);

  const myself = useSelector(selectMyself);
  const apiStatus = useSelector(selectApiStatus);
  const userList = useSelector(fetchUserList);

  // 削除確認ポップアップ関連
  const [deleteConfirm, setDeleteConfirm] = useState({
    showFlg: false,
    onClose: null,
  });

  // マウント時にライセンシーユーザー一覧情報を取得
  useEffect(() => {
    dispatch(fetchUsers());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('fetchUsers'));
      dispatch(clearApiStatus('updateUser'));
      dispatch(clearFetchUsers());
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return (([apiStatus.fetchUsers, apiStatus.updateUser].includes('loading')) ?
     <LoadingOverlay /> : null );
  }, [apiStatus.fetchUsers, apiStatus.updateUser]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const users = (userList?.result?.data || []).map(user => {
      return {
        userId: user.userId,
        username: user.username,
        mailaddress: user.mailaddress,
        department: user.department,
        userStatus: user.userStatus,
        updateDatetime: user.updateDatetime,
        loginDatetime: user.loginDatetime,
      };
    });

    return users;
  }, [userList]);

  // 削除処理処理
  const onDelete = useCallback((userId, updateDatetime) => {
    setDeleteConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          const params = {
            userId: userId,
            updateDatetime: updateDatetime,
          };
          dispatch(updateUser(params));
        }
        setDeleteConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    })
  }, [dispatch]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return records.map(rec => {
      return {
        ...rec,
        userStatus: rec.userStatus === 'INV' ? '招待中' : '登録済',
        // ログインユーザは削除不可
        delete: rec.userId !== myself?.userId ? (
          <TableCellButton onClick={() => onDelete(rec.userId, rec.updateDatetime)} className="c-pink">削除</TableCellButton>
        ) : '',
      };
    });
  }, [records, myself, onDelete]);

  useEffect(() => {
    if (apiStatus.fetchUsers === 'error') {
      dispatch(pushMessage('ユーザー一覧の取得に失敗しました'));
      dispatch(clearApiStatus('fetchUsers'));
    }
    if (apiStatus.updateUser === 'error') {
      dispatch(pushMessage('ユーザーの削除に失敗しました'));
      dispatch(clearApiStatus('updateUser'));
    }
  }, [apiStatus.fetchUsers, apiStatus.updateUser, dispatch]);

  // 更新完了
  useEffect(() => {
    if (apiStatus.updateUser === 'finish') {
      dispatch(pushMessage('ユーザーの削除を行いました。'));
      dispatch(clearApiStatus('updateUser'));
      // 初期化
      setCurrent(1);
      dispatch(fetchUsers());
    }
  }, [apiStatus.updateUser, dispatch]);

  // 未ログイン時は対応する画面へ遷移
  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <>
      <h1 className="main_login-head">ユーザー管理</h1>
      <div className="wrap">
        <div className="mt30">
          <p className="btn bg-green" style={{ width: '160px' }}>
            <Link to="/licensee/userInvitation">
              <i className="icn plus"></i>
              ユーザー招待
            </Link>
          </p>
        </div>

        <p className="mt30">
            使わなくなったメールアドレスは削除をお願いします。
        </p>

        {Boolean(userList) && (
          <PagingTable
            headers={headers}
            records={viewRecords}
            pageSize={pageSize}
            current={current}
            emptyMessage="ライセンシーユーザー情報がありません。"
            scrollable
            resizable
            onPageChange={setCurrent} />
        )}

        {loading}
      </div>

      {
        deleteConfirm.showFlg ?
          <MessagePopup
            message="ユーザーを削除します。よろしいですか？"
            btn={{ ok: '削除', cancel: 'キャンセル' }}
            btnClass='bg-pink'
            onClose={deleteConfirm.onClose} />
          : null
      }
    </>
  )
}
