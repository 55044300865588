//@ts-check
import React, { useMemo } from 'react';
import { ChangeStatusIcon } from '../common/ChangeStatusIcon';

/**
 * 企画添付ファイル変更状態表示
 * @param {object} params
 * @param {ProposalAttachment[]} params.attachmentList 添付ファイルのリスト
 * @param {ProposalAttachment[]|undefined} params.prevAttachmentList 前バージョンの添付ファイルのリスト
 */
export const AttachmentChangeStatus = ({
  attachmentList,
  prevAttachmentList,
}) => {
  const isChanged = useIsAttachmentChanged({
    attachmentList,
    prevAttachmentList,
  });

  return isChanged ? (
    <ChangeStatusIcon
      className="inline"
      iconClass="changed"
      tooltipText=''
      hideTooltip={true} />
  ) : null;
}

/**
 * 添付ファイルが変更されているかを判定する
 * @param {object} params
 * @param {ProposalAttachment[]} params.attachmentList 添付ファイルのリスト
 * @param {ProposalAttachment[]|undefined} params.prevAttachmentList 前バージョンの添付ファイルのリスト
 */
const useIsAttachmentChanged = ({
  attachmentList,
  prevAttachmentList,
}) => {
  return useMemo(() => {
    if (prevAttachmentList == null) {
      // 前バージョンが存在しない場合は変更なし扱い
      return false;
    }

    const addedList = attachmentList.filter(a => prevAttachmentList.find(p => p.proposalAttachmentNo === a.proposalAttachmentNo) == null);
    const deletedList = prevAttachmentList.filter(p => attachmentList.find(a => a.proposalAttachmentNo === p.proposalAttachmentNo) == null);

    return addedList.length !== 0 || deletedList.length !== 0;
  }, [attachmentList, prevAttachmentList]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalAttachment} ProposalAttachment
 */
//#endregion
