import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import { immutableSort } from "../../../lib/util";
import { clearApiStatus, clearPropertyList, fetchPropertyList, selectApiStatus, selectPropertyList } from "../../../slices/aniplex/propertiesSlice";
import { propertyAniplex } from "../../common/headerList";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { PagingTable } from "../../common/table/PagingTable";
import { TableVisible } from "../../common/table/TableVisible";
import {PropertyListSearchForm } from "./PropertyListSearchForm"

/** ページャの1ページあたりの行数 */
const pageSize = 20;

/** 項目表示チェック状態の初期値 */
const checkParamDefault = Object.fromEntries(
  propertyAniplex
    .map(prop => ([prop.id, true]))
);

/**
 * [A]作品一覧画面
 */
export const PropertyListPage = () => {
  const dispatch = useDispatch();
  const propertyList = useSelector(selectPropertyList);
  const apiStatus = useSelector(selectApiStatus).fetchPropertyList;

  /** データ取得済みフラグ */
  const dataFetched = useMemo(() => apiStatus === 'finish', [apiStatus]);

  const [isOpen, setIsOpen] = useState(false);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [headers, setHeaders] = useState(propertyAniplex);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortInfo, setSortInfo] = useState({
    sortKey: 'propertySummaryCode',
    order: 'asc'
  });

  /** ソート条件変更時のハンドラ */
  const onSortChange = useCallback((info) => {
    setSortInfo(info);
    // ソート変更時は1ページ目に戻る
    setCurrentPage(1);
  }, []);

  // マウント時に作品一覧を取得
  useEffect(() => {
    dispatch(fetchPropertyList({}));
    return () => {
      // 画面離脱時にAPI通信状況をクリア
      dispatch(clearApiStatus('fetchPropertyList'));
      dispatch(clearPropertyList());
    }
  }, [dispatch]);

  /** 検索実行時コールバック */
  const onSearch = useCallback((params) => {
    // 検索処理
    dispatch(fetchPropertyList(params));
    // 1ページ目に戻る
    setCurrentPage(1);
  }, [dispatch]);

  /** 「表示を切り替える」押下時 */
  const onCheckApply = useCallback(() => {
    setHeaders(propertyAniplex.filter(prop => checkParam[prop.id]));
  }, [checkParam]);

  /** ソート関数 */
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) return 1;
      if ((a[sortKey] || '') < (b[sortKey] || '')) return -1;
      return 0;
    };
  }, [sortInfo.sortKey]);

  /**
   * ソート後の作品リスト
   * @type {Property[]}
   */
  const sortedPropertyList = useMemo(() => {
    const newList = immutableSort(propertyList, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newList.reverse();
    }
    return newList;
  }, [propertyList, sortFunc, sortInfo.order]);

  /** 表示用レコード */
  const records = useMemo(() => {
    return sortedPropertyList.map(property => ({
      ...property,
      key: property.propertySummaryCode,
      propertySummaryCode: (
        <Link to={`/aniplex/propertyDetail/${property.propertySummaryCode}`}>{property.propertySummaryCode}</Link>
      ),
      // TODO: アイコンmax-heightの調整
      icon: property.iconFilename ? (
        <span className="db transition-img work-logo" style={{ display: 'block', textAlign: 'center' }}>
          <img src={Config.PropertyIconPath + property.iconFilename } alt="作品ロゴ" style={{ maxHeight: '23px' }} />
        </span>
      ) : '',
      proposalFlag: property.proposalFlag ? '可能' : '不可',
    }));
  }, [sortedPropertyList]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">作品一覧</h1>
      </div>

      <PropertyListSearchForm
        onSearch={onSearch} />

      <TableVisible
        isOpen={isOpen}
        headers={propertyAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />

      {
        dataFetched && (
          <PagingTable
            headers={headers}
            records={records}
            current={currentPage}
            pageSize={pageSize}
            sortParam={sortInfo}
            emptyMessage="検索条件と一致する作品情報がありません。"
            scrollable
            resizable
            onSortChange={onSortChange}
            onPageChange={setCurrentPage} />
        )
      }

      {
        apiStatus === 'loading' && (
          <LoadingOverlay />
        )
      }

    </div>
  )
}


//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/propertiesSlice').Property} Property 作品情報
 */
//#endregion typedef
