import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { isEmpty } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { ProposalClosingMonthPopup } from "./ProposalClosingMonthPopup"
import { Constants } from "../../../Constants";

/**
 * 締め月のプロパティ名
 * @type {(keyof FormData)[]}
 */
const closingMonthProps = [
  'ryReq1', 'ryReq2', 'ryReq3',
  'ryReq4', 'ryReq5', 'ryReq6',
  'ryReq7', 'ryReq8', 'ryReq9',
  'ryReq10', 'ryReq11', 'ryReq12',
];

/**
 * フォームの対象プロパティ名のリスト
 * @satisfies {(keyof FormData)[]}
 */
const formProps = [
  ...closingMonthProps,
  'ryReportCategory',
];

/**
 * このフォームで対象とするプロパティ
 * @satisfies {Readonly<OnValidatedProp[]>}
 */
const targetProps = Object.freeze([
  'closingMonth',
]);

/**
 * 企画書申請画面のロイヤリティ締め月のフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject} props.formRefs フォーム項目へのref
 * @param {boolean} props.isEditable 編集可否フラグ
 * @param {OnChange} props.onChange フォーム内容変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {import('./ProposalDetailForm').Product[]} props.productList 許諾商品リスト
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @param {keyof FormData} [props.requestValidateProp] 強制バリデートを要求するプロパティ名
 * @returns
 */
export const ProposalClosingMonthForm = ({ data, formLocked, formRefs, isEditable, onChange, onValidated, productList, validateAllFlg = false, requestValidateProp }) => {
  // エラーメッセージ
  const [messages, setMessages] = useState({
    /** ロイヤリティ報告締め月 */
    closingMonth: [],
  });
  // ポップアップ表示フラグ
  const [showPopupFlg, setShowPopupFlg] = useState(false);

  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っているときは未入力状態でもバリデートを行う
      const errors = validate(data);
      const newMessages = {};
      targetProps.forEach(prop => {
        onValidated(prop, errors[prop] ?? {tmp:[], apply:[]});
        newMessages[prop] = errors[prop]?.apply ?? [];
      });
      setMessages(prev => ({
        ...prev,
        ...newMessages,
      }));
    }
  }, [data, onValidated, validateAllFlg]);

  // プロパティの強制バリデート要求がある場合
  useEffect(() => {
    if (requestValidateProp != null && targetProps.includes(requestValidateProp)) {
      const errors = validate(data);
      const newMessages = {};
      onValidated(requestValidateProp, errors[requestValidateProp] ?? { tmp: [], apply: []});
      newMessages[requestValidateProp] = errors[requestValidateProp]?.apply ?? [];

      setMessages(prev => ({
        ...prev,
        ...newMessages,
      }));
    }
  }, [data, onValidated, requestValidateProp]);

  /** ロイヤリティ報告区分 */
  const ryReportCategoryText = useRyReportCategoryText(data);

  /**
   * 締め月設定ポップアップが閉じたときのコールバック
   * @type {import('./ProposalClosingMonthPopup').OnClose}
   */
  const onPopupClose = useCallback((type, formData) => {
    if (type === 'setting') {
      // ポップアップ内での設定内容を反映
      formProps.forEach(prop => {
        onChange(prop, formData[prop]);
      });
      const errors = validate(formData);
      onValidated('closingMonth', errors.closingMonth ?? { tmp: [], apply: [] });
      setMessages(prev => ({
        ...prev,
        closingMonth: errors.closingMonth?.apply ?? [],
      }));
    }
    setShowPopupFlg(false);
  }, [onChange, onValidated])

  /** ポップアップ表示ボタン押下時のハンドラ */
  const onShowPopup = useCallback(() => {
    setShowPopupFlg(true);
  }, []);

  return (
    <section className="mt40">
      <div className="title-pink">
        <h2 className="title"><i className="icn calendar02"></i>ロイヤリティ報告締め月</h2>
      </div>

      <span className="c-pink">※ロイヤリティ報告締め月の翌月15日までにロイヤリティ報告のご提出をお願いします</span>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required wdt100">報告締め月</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>{ryReportCategoryText}</span>
            </div>
            {
              isEditable && !data.updateLimitFlag ? (
                <div className="btn label c-pink wdt100 ml50">
                  <button
                    ref={formRefs.current.closingMonth}
                    disabled={formLocked}
                    onClick={onShowPopup}
                  >変更</button>
                </div>
              ) : null
            }
          </dd>
        </dl>
      </div>
      <ErrorMessageList messages={messages.closingMonth} />
      {
        showPopupFlg ?
          <ProposalClosingMonthPopup
            data={data}
            productList={productList}
            onClose={onPopupClose} />
          : null
      }
    </section>
  )
}

/**
 * 選択されたロイヤリティ報告区分の表示用テキスト
 * @param {FormData} formData フォームデータ
 */
const useRyReportCategoryText = (formData) => {
  return useMemo(() => {
    switch (formData.ryReportCategory) {
      case Constants.Licensee.RyReportCategory.Monthly:
        return '毎月';
      case Constants.Licensee.RyReportCategory.Any:
        return '任意';
      case Constants.Licensee.RyReportCategory.Specified:
        const selectedMonths = closingMonthProps
          .map((prop, idx) => ({
            name: `${idx + 1}月`,
            checked: formData[prop],
          }))
          .filter(val => val.checked)
          .map(val => val.name)
          .join(',');
        return `指定月（${selectedMonths}）`;
      default:
        return '';
    }
  }, [formData]);
}

/**
 * フォーム内容のバリデーションを行う
 * @param {FormData} formData フォーム内容
 * @returns {Record<string, { tmp: string[], apply: string[] }>} エラーメッセージ
 */
export function validate(formData) {
  const errors = {};

  const isSpecifiedMonth = formData.ryReportCategory === Constants.Licensee.RyReportCategory.Specified;

  // 1つ以上チェックが入っているか
  const checkedAtLeastOne = closingMonthProps.map(prop => formData[prop])
    .filter(v => v)
    .length > 0;
  if (isSpecifiedMonth && !checkedAtLeastOne) {
    const msg = 'ロイヤリティ報告締め月を設定してください';
    errors.closingMonth = {
      tmp: [
        ...errors.closingMonth?.tmp ?? [],
        msg,
      ],
      apply: [
        ...errors.closingMonth?.apply ?? [],
        msg,
      ],
    };
  }

  if (isSpecifiedMonth && !checkAllMonthIncluded(formData)) {
    const msg = '契約期間外の月が設定されています';
    errors.closingMonth = {
      tmp: [
        ...errors.closingMonth?.tmp ?? [],
        msg,
      ],
      apply: [
        ...errors.closingMonth?.apply ?? [],
        msg,
      ]
    };
  }

  return errors;
}

/**
 * ロイヤリティ報告締め月が契約期間内に含まれているか判定する
 * @param {FormData} formData
 * @returns {boolean} すべての報告締め月が契約期間内に含まれている場合はtrue.
 */
function checkAllMonthIncluded(formData) {
  if (isEmpty(formData.contractStartDate) || isEmpty(formData.contractEndDate)) {
    // 開始日と終了日のいずれかが空の場合は判定不可のためtrueを返す
    return true;
  }

  const start = dayjs(formData.contractStartDate, 'YYYY/MM/DD');
  const end = dayjs(formData.contractEndDate, 'YYYY/MM/DD');

  const startMonth = start.month();
  // 契約開始日の年を基準としたときに終了日が何月になっているか
  // (例)翌年の1月は13月の扱い
  const endMonth = end.date(start.date()).diff(start, 'month') + startMonth;

  let included = true;
  closingMonthProps.forEach((prop, month) => {
    let calMonth = month;
    if (month < startMonth) {
      // 開始月未満の月は1年後の値に補正する
      calMonth += 12;
    }

    // 契約期間外の月が締め月として有効になっている場合
    // ※開始月より前の場合は補正されるので終了だけ見ればよい
    if (formData[prop] && endMonth < calMonth) {
      included = false;
    }
  })

  return included;
}

//#region typedef
/**
 * @typedef {import('./ProposalClosingMonthPopup').FormData} FormData フォームの入力用データ
 */
/**
 * @callback OnChange フォームの入力内容変更時のハンドラ
 * @param {keyof FormData} prop 変更されたプロパティ
 * @param {boolean} value 変更後の値
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {OnValidatedProp} prop バリデート対象のプロパティ
 * @param {string[]} errors エラーメッセージのリスト
 */
/**
 * @typedef {(keyof FormData)|'closingMonth'} OnValidatedProp
 */
//#endregion typedef
