//@ts-check
import React, { useMemo } from 'react';

/**
 * 比較対象のプロパティ名
 * @type {(keyof ProposalProduct)[]}
 */
const props = [
  'productName',
  'productStatus',
  'categoryNo',
  'categoryDetailNo',
  'character',
  'version',
  'launchDate',
  'rypId',
  'planPrice',
  'planCost',
  'planProceeds',
  'planRyAmount',
  'ryRate',
  'ryPrice',
  'calPlanProceeds',
  'calPlanRyTarget',
  'calPlanRyAmount',
  'latestPrice',
  'latestCost',
  'calResultProceeds',
  'calResultRyTarget',
  'calResultRyAmount',
  'salesMethod',
  'productMethod',
  'material',
  'characterLineup',
  'productRemarks',
  'productStatusUpdateDatetime',
  'productOption1',
  'productOption2',
  'productOption3',
  'sampleRegDatetime',
  'productImageUrl',
  'productImageRegDatetime',
]

/**
 * 比較対象のperiodList内のプロパティ名
 * @type {PeriodProp[]}
 */
const periodProps = [
  'period',
  'planProduction',
  'planSales',
  'resultLabel',
  'resultProduction',
  'resultSales',
];

/**
 * 許諾商品の更新状態表示
 * @param {object} params
 * @param {ProposalProduct} params.product 商品情報
 * @param {ProposalProduct|null} params.prevProduct 以前の商品情報
 * @param {boolean} [params.deleted=false] 削除済みフラグ
 */
export const ProductUpdateStatus = ({
  product,
  prevProduct,
  deleted = false,
}) => {
  const icnClassName = useIconClass({ product, prevProduct, deleted });

  return (
    <span className="product-update-status">
      {
        icnClassName != null && (
          <i className={`icn ${icnClassName}`}></i>
        )
      }
    </span>
  )
}

/**
 * アイコンのクラス名
 * @param {object} params
 * @param {ProposalProduct} params.product 商品情報
 * @param {ProposalProduct|null} params.prevProduct 以前の商品情報
 * @param {boolean} params.deleted 削除済みフラグ
 */
const useIconClass = ({
  product,
  prevProduct,
  deleted,
}) => {
  /** 新規追加フラグ */
  const isNew = useMemo(() => product != null && prevProduct == null, [prevProduct, product]);
  /** 変更済みフラグ */
  const isChanged = useProductChanged({ product, prevProduct });

  return useMemo(() => {
    if (deleted) {
      return 'deleted';
    }
    if (isNew) {
      return 'new';
    }
    if (isChanged) {
      return 'changed';
    }
    return null;
  }, [isChanged, deleted, isNew]);
}

/**
 * 商品の変更フラグ
 * @param {object} params
 * @param {ProposalProduct|null} params.product 商品情報
 * @param {ProposalProduct|null} params.prevProduct 以前の商品情報
 */
const useProductChanged = ({
  product,
  prevProduct,
}) => {

  return useMemo(() => {
    if (product == null || prevProduct == null) {
      if (product != null || prevProduct != null) {
        return true;
      }
      return false;
    }

    // 直下のプロパティを比較
    for (const prop of props) {
      if (product[prop] !== prevProduct[prop]) {
        return true;
      }
    }

    // 期数が違うときは変更扱い
    if (product.periodList.length !== prevProduct.periodList.length) {
      return true;
    }

    // periodList内のプロパティを比較
    for (const period of product.periodList) {
      const prevPeriod = prevProduct.periodList.find(p => p.period === period.period);

      for (const prop of periodProps) {
        if (period[prop] !== prevPeriod?.[prop]) {
          return true;
        }
      }
    }

    // 商品画像の比較
    const imageSet = new Set(product.renderingImageList.map(r => r.renderingImageNo));
    const prevImageSet = new Set(prevProduct.renderingImageList.map(r => r.renderingImageNo));
    if (!eqSet(imageSet, prevImageSet)) {
      return true;
    }

    return false;
  }, [prevProduct, product]);
}

/**
 * Setの同値性比較
 * @param {Set} a
 * @param {Set} b
 */
const eqSet = (a, b) => {
  return a.size === b.size &&
    [...a].every(x => b.has(x));
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProduct} ProposalProduct
 */
/**
 * @typedef {keyof ProposalProduct['periodList'][number]} PeriodProp periodList内のプロパティ
 */
//#endregion typedef
