import { useCallback } from "react";

/**
 * サイズが動的に変化するポップアップ
 * @param {object} props
 * @param {string} [props.className] ポップアップに付与するCSSクラス名
 * @param {() => void} props.onClose 閉じるボタン押下時のコールバック
 * @param {React.ReactNode} props.children ポップアップ内に表示する内容
 */
export const FlexiblePopup = ({ className = '', onClose, children, style }) => {
  const onCloseClick = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  return (
    <div className={`l-popup flexible active ${className}`}>
      <div className="overlay"></div>
      <div className="popup-content" style={{...style}}>
        <div className="popup-body">
          {children}
        </div>
        <p className="btn-close" onClick={onCloseClick}></p>
      </div>
    </div>
  );
}
