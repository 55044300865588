import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clearApiStatus, clearAnnouncementDetail, selectApiStatus, fetchAnnouncement, updateAnnouncement, deleteAnnouncement, selectAnnouncementDetail } from "../../../slices/aniplex/announcementSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { AnnouncementDetailForm } from "./AnnouncementDetailForm";

/**
 * [A]お知らせ詳細画面
 */
export const AnnouncementUpdatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routerParams = useParams();
  const apiStatus = useSelector(selectApiStatus);
  const announcementDetail = useSelector(selectAnnouncementDetail);

  useEffect(() => {
    if (routerParams.announcementId != null) {
      // お知らせ内部コードを元にお知らせ情報を取得する
      dispatch(fetchAnnouncement(routerParams.announcementId));
    }
  }, [dispatch, routerParams.announcementId]);

  // 離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchAnnouncement'));
      dispatch(clearApiStatus('updateAnnouncement'));
      dispatch(clearApiStatus('deleteAnnouncement'));
      dispatch(clearAnnouncementDetail());
    }
  }, [dispatch]);

  /** 通信中フラグ */
  const apiLoading = useMemo(() => {
    return  apiStatus.fetchAnnouncement === 'loading'
      || apiStatus.updateAnnouncement === 'loading'
      || apiStatus.deleteAnnouncement === 'loading';
  }, [apiStatus.fetchAnnouncement, apiStatus.updateAnnouncement, apiStatus.deleteAnnouncement]);

  useEffect(() => {
    if (apiStatus.updateAnnouncement === 'finish') {
      dispatch(pushMessage('お知らせを更新しました。'));
      dispatch(clearApiStatus('updateAnnouncement'));
      dispatch(fetchAnnouncement(routerParams.announcementId));
    }

    if (apiStatus.deleteAnnouncement === 'finish') {
      dispatch(pushMessage('お知らせを削除しました。'));
      // 削除されたら一覧画面へ遷移
      navigate('/aniplex/announcementList');
    }
  }, [routerParams.announcementId, apiStatus.updateAnnouncement, apiStatus.deleteAnnouncement, dispatch, navigate]);

  /**
   * 更新ボタン押下時のコールバック
   */
   const onUpdate = useCallback((formData) => {
    const params = {
      ...formData,
      announcementUpdateDatetime: announcementDetail?.announcementUpdateDatetime,
    };
    dispatch(updateAnnouncement({
      announcementId: announcementDetail?.announcementId,
      params,
    }));
  }, [dispatch, announcementDetail?.announcementId, announcementDetail?.announcementUpdateDatetime]);

  /**
   * 削除ボタン押下時のコールバック
   */
  const onDelete = useCallback(() => {
    dispatch(deleteAnnouncement({
      announcementId: announcementDetail?.announcementId,
      announcementUpdateDatetime: announcementDetail?.announcementUpdateDatetime,
    }));
  }, [dispatch, announcementDetail?.announcementId, announcementDetail?.announcementUpdateDatetime]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">お知らせ詳細</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/announcementList' >
            <i className="icn pageback"></i>お知らせ一覧へ戻る
          </Link>
        </p>
      </div>

      <AnnouncementDetailForm
        submitBtn="お知らせを更新"
        execType="更新"
        showSubmit={true}
        showDelete={true}
        onSubmit={onUpdate}
        onDelete={onDelete}
        loadedData={announcementDetail}
        formLocked={apiLoading || !announcementDetail} />

      {
        apiLoading && <LoadingOverlay />
      }
    </div>
  );
}
