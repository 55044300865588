//@ts-check
import React, { createRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useResizeObserver } from "../../../lib/hooks/common";

/** 列幅調節の最小幅 */
const RESIZE_MIN = 64; // 全角3文字程
/** 列幅調節の列右端のドラッグ可能範囲 */
const RESIZE_PADDING = 8;
/** 列幅調節可能なマウスボタン 0:主、1:補助、2:副 */
const RESIZE_BUTTON = 0;
/** 列幅調整可能な要素に付与する属性名 */
const RESIZE_ID_ATTR = 'data-resize-id';

/**
 * ヘッダ部の行や列の結合が可能なテーブルビュー
 * @param {object} props
 * @param {Header[]} props.headers ヘッダ定義
 * @param {DataRecord[]} props.records ボディ部のデータ
 * @param {string} [props.className] CSSクラス名
 * @param {React.MutableRefObject} [props.tableRef] tableタグへのref
 * @param {boolean} [props.scrollable=false] テーブル領域内のスクロールを有効にするかのフラグ
 * @param {number} [props.fixedCols=0] スクロール時に固定する左端の列数 TODO: ヘッダの一番上の行の列数でしか実装できてないので、ボディ部分の列数で制御できるように改良する
 * @param {number} [props.ignoreFixRows=0] 左端列固定を無視するデータ行の数.負数を指定した場合は後ろの行から指定
 * @param {boolean} [props.resizable=false] 列幅調整可能にする
 * @returns
 */
export const SpannableTableView = ({
  headers,
  records,
  className,
  tableRef,
  scrollable = false,
  fixedCols = 0,
  ignoreFixRows = 0,
  resizable = false,
}) => {
  const { colsRef, colsWidth } = useColWidth({
    colNum: headers.length,
    headers,
  });

  const { resizedWidth, inResize } = useResizable({ resizable, colsRef })

  /** ヘッダ部の行数 */
  const headerRows = useMemo(() => {
    return getLongestDepth(headers);
  }, [headers]);

  /** 固定する列数 */
  const innerFixedCols = useMemo(() => scrollable ? fixedCols : 0
    , [fixedCols, scrollable]);

  /** 列固定する行数 */
  const fixedRowCount = useMemo(() => records.length + ignoreFixRows
    , [ignoreFixRows, records.length]);

  const tableStyle = useMemo(() => {
    /** @type {React.CSSProperties} */
    const style = {};

    if (resizable) {
      style.width = 0;
      style.tableLayout = 'fixed';
    }

    return style;
  }, [resizable]);

  return (
    <div className={`l-table ${scrollable ? 'hv-scroll-fixed' : ''} ${className}`}>
      <table ref={tableRef} style={tableStyle}>
        <thead>
          {
            new Array(headerRows).fill('').map((_, i) => (
              // @ts-expect-error
              <HeaderRow
                key={i}
                headers={headers}
                rowNumber={i + 1}
                maxDepth={headerRows}
                fixedCols={innerFixedCols}
                colsWidth={colsWidth}
                colsRef={colsRef}
                resizedWidth={resizedWidth} />
            ))
          }
        </thead>
        <tbody>
          {
            records.map((record, idx) => (
              // @ts-expect-error
              <BodyRow
                key={idx}
                record={record}
                headers={headers}
                isFixedRow={idx < fixedRowCount}
                fixedCols={innerFixedCols}
                colsWidth={colsWidth}
                resizable={resizable} />
            ))
          }
        </tbody>
      </table>
      {
        inResize && (
          // @ts-expect-error
          <ResizeOverlay />
        )
      }
    </div>
  )
}

/**
 * ヘッダ部の行ごとのコンポーネント
 * @param {object} props
 * @param {Header[]} props.headers ヘッダ全体の定義
 * @param {number} props.rowNumber 対象とするヘッダ行番号
 * @param {number} props.maxDepth ヘッダ全体の行数
 * @param {number} props.fixedCols 左端に固定する列数
 * @param {number[]} props.colsWidth 各列の幅
 * @param {React.MutableRefObject<React.RefObject<HTMLTableCellElement>[]>} props.colsRef 列幅測定用のref
 * @param {Record<ColID, number>} props.resizedWidth 手動調整された列幅
 */
const HeaderRow = ({
  headers,
  rowNumber,
  maxDepth,
  fixedCols,
  colsWidth,
  colsRef,
  resizedWidth,
}) => {
  /** この行で表示対象とするヘッダ項目 */
  const targets = useMemo(() => {
    return getTargetRowHeaders(headers, rowNumber);
  }, [headers, rowNumber]);

  /** rowspanを設定するときの値 */
  const rowSpan = useMemo(() => {
    return maxDepth - rowNumber + 1;
  }, [rowNumber, maxDepth]);

  return (
    <tr>
      {
        targets.map((h, idx) => {
          const isFixedCol = rowNumber === 1 && idx < fixedCols;
          const isFixedLastCol = rowNumber === 1 && idx === fixedCols - 1;
          const width = (() => {
            if (resizedWidth[h.id] != null) {
              return resizedWidth[h.id] + 'px';
            }
            return h.style?.width;
          })();

          /** @type {React.CSSProperties} */
          const style = {
            whiteSpace: 'pre-wrap',
            position: isFixedCol ? 'sticky' : undefined,
            left: isFixedCol ? `${colsWidth.slice(0, idx).reduce((p, c) => p + c, 0)}px` : undefined,
            zIndex: isFixedCol ? 1 : undefined,
            ...h.style,
            width,
          };

          const className = `${isFixedLastCol ? 'fixed-last-col' : ''} ${h.className ?? ''}`;

          return (
            <th
              key={h.id}
              ref={rowNumber === 1 ? colsRef.current[idx] : undefined}
              style={style}
              className={className}
              rowSpan={h.hasChild ? undefined : rowSpan}
              colSpan={h.colSpan}
              {...{
                [RESIZE_ID_ATTR]: h.id,
              }}
            >
              {h.label}
            </th>
          )
        })
      }
    </tr>
  )
}

/**
 * ボディ部の行ごとのコンポーネント
 * @param {object} props
 * @param {DataRecord} props.record 表示対象のデータ
 * @param {Header[]} props.headers ヘッダ定義
 * @param {boolean} props.isFixedRow 列固定を行う行のフラグ
 * @param {number} props.fixedCols 左端に固定する列数
 * @param {number[]} props.colsWidth 各列の幅
 * @param {boolean} props.resizable 列幅調整フラグ
 * @returns
 */
const BodyRow = ({
  record,
  headers,
  isFixedRow,
  fixedCols,
  colsWidth,
  resizable,
}) => {
  /** @type {string[]} 列IDのリスト */
  const colId = useMemo(() => {
    return getColIdList(headers);
  }, [headers]);

  return (
    <tr
      className={record._tr?.className}
    >
      {
        colId.map((id, idx) => {
          if (record[id] == null) {
            return null;
          }

          const isFixedCol = isFixedRow && idx < fixedCols;
          const isFixedLastCol = isFixedRow && idx === fixedCols - 1;

          /** @type {React.CSSProperties} */
          const style = {
            position: isFixedCol ? 'sticky' : undefined,
            left: isFixedCol ? `${colsWidth.slice(0, idx).reduce((p, c) => p + c, 0)}px` : undefined,
            zIndex: isFixedCol ? 1 : undefined,
            whiteSpace: resizable ? 'normal' : undefined,
            ...record[id].style,
          };

          const className =
            `${isFixedLastCol ? 'fixed-last-col' : ''} ${record[id].className ?? ''}`;

          const content = (() => {
            if (typeof record[id] === 'object' && 'el' in record[id]) {
              return record[id].el
            }
            return record[id]
          })()

          return (
            <td key={id}
              colSpan={record[id].colSpan}
              className={className}
              style={style}
            >
              {content}
            </td>
          )
        })
      }
    </tr>
  )
}

/** リサイズ中のカーソル形状変化用オーバーレイ */
const ResizeOverlay = () => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      cursor: 'ew-resize',
      zIndex: '9999',
      left: '0',
      top: '0',
    }}></div>
  )
}

/**
 * 列幅を取得するためのカスタムフック
 * @param {object} params
 * @param {number} params.colNum 列数
 * @param {Header[]} params.headers ヘッダ定義
 */
const useColWidth = ({ colNum, headers }) => {
  /** 列幅測定用のref */
  const colsRef = useRef(/** @type {React.RefObject<HTMLTableCellElement>[]} */ ([]));
  /** 列幅 */
  const [colsWidth, setColsWidth] = useState(/** @type {number[]} */ ([]));

  useEffect(() => {
    for (let i = 0; i < colNum; i++) {
      colsRef.current[i] = createRef();
    }
  }, [colNum]);

  const handleResize = useCallback(() => {
    const tmpWidth = [];
    colsRef.current.forEach((r, idx) => {
      tmpWidth[idx] = r.current?.getBoundingClientRect().width ?? 0;
    });

    if (tmpWidth.some((v, idx) => v !== colsWidth[idx])) {
      // 既存の列幅の値と異なる場合のみ更新
      setColsWidth(tmpWidth);
    }
  }, [colsWidth]);

  useResizeObserver(colsRef.current, handleResize);

  // 列幅の更新
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  // 表示列変更時の列幅の更新
  useLayoutEffect(() => {
    handleResize();
  }, [handleResize, headers]);

  return {
    colsRef,
    colsWidth,
  }
}

/**
 * 列幅調整機能
 * @param {object} params
 * @param {boolean} params.resizable
 * @param {React.MutableRefObject<React.RefObject<HTMLTableCellElement>[]>} params.colsRef
 */
const useResizable = ({ resizable, colsRef }) => {
  // リサイズ対象
  const [resizeTarget, setResizeTarget] = useState(/** @type {?HTMLElement} */ (null));
  // 調整後の列幅
  const [resizedWidth, setResizedWidth] = useState(/** @type {Record<ColID, number>} */ ({}));

  /** リサイズ中フラグ */
  const inResize = useMemo(() => resizeTarget != null, [resizeTarget]);

  const onMouseMove = useCallback(
    /** @param {MouseEvent} e */
    (e) => {
      if (!resizeTarget) {
        /** @type {HTMLElement} */
        const target = /** @type {HTMLElement} */ (e.target)
        const id = target.getAttribute(RESIZE_ID_ATTR);
        if (!id) {
          return;
        }
        const rect = target.getBoundingClientRect();
        // 各ヘッダセルの右端にカーソルがあれば形状を変化させる
        if (e.clientX >= rect.right - RESIZE_PADDING) {
          target.style.cursor = 'ew-resize';
        } else {
          target.style.cursor = ''
        }
        return;
      }

      // リサイズ中
      e.preventDefault();
      const id = resizeTarget.getAttribute(RESIZE_ID_ATTR) ?? '';
      const rect = resizeTarget.getBoundingClientRect();
      const newWidth = Math.max(e.clientX - rect.left, RESIZE_MIN)
      setResizedWidth(prev => ({
        ...prev,
        [id]: newWidth,
      }));
    }, [resizeTarget]);

  const onMouseDown = useCallback(
    /** @param {MouseEvent} e */
    (e) => {
      // ボタンを主ボタンに制限する
      if (e.button !== RESIZE_BUTTON) {
        return;
      }

      const target = /** @type {HTMLElement} */ (e.target);
      const id = target.getAttribute(RESIZE_ID_ATTR);
      if (resizeTarget || !id) {
        return;
      }

      // 右端にカーソルがある場合リサイズを開始
      const rect = target.getBoundingClientRect();
      if (e.clientX >= rect.right - RESIZE_PADDING) {
        e.preventDefault();
        setResizeTarget(target);
      }
    }, [resizeTarget]);

  const onMouseUp = useCallback(() => {
    setResizeTarget(null)
  }, []);

  useEffect(() => {
    if (!resizable) {
      return
    }

    /** @type {(?HTMLElement)[]} */
    let targetEls = [];
    setTimeout(() => {
      targetEls = colsRef.current.map(ref => ref.current);
      window.addEventListener('mousemove', onMouseMove);
      targetEls.forEach(target => target?.addEventListener('mousedown', onMouseDown));
      window.addEventListener('mouseup', onMouseUp);
    });

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      targetEls.forEach(target => target?.removeEventListener('mousedown', onMouseDown));
      window.removeEventListener('mouseup', onMouseUp);
    }
  }, [colsRef, onMouseDown, onMouseMove, onMouseUp, resizable]);

  return {
    resizedWidth,
    inResize,
  }
}

/**
 * 指定の列IDの列より左に存在する列の数を取得する
 * @param {Header[]} headers ヘッダ定義
 * @param {string} colId 対象の列ID
 * @returns {number} 列の数
 */
export function getColNumBeforeId(headers, colId) {

  /**
   * ヘッダの数を再帰的に子ヘッダに潜ってカウントする
   * @type {(header: Header) => number}
   */
  const headerNumRecursive = (header) => {
    if ((header.children ?? []).length === 0) {
      return 1;
    }
    return (header.children ?? []).reduce((prev, cur) => {
      return prev + headerNumRecursive(cur);
    }, 0);
  };

  /**
   * ヘッダ情報が子ヘッダも含め対象のIDを持っているかを判定する
   * @param {Header} header ヘッダ情報
   * @param {string} id 判定対象のID
   * @returns {boolean} 対象のIDを持っているか
   */
  const hasId = (header, id) => {
    if (header.id === id) {
      return true;
    }
    return (header.children ?? []).reduce((prev, cur) => {
      return prev || hasId(cur, id);
    }, false);
  }

  /**
   * 指定列IDより前の列数を再帰的にカウントする
   * @param {Header[]} headers ヘッダ情報
   * @returns {number} 列数
   */
  const countRecursive = (headers) => {
    const targetIndex = headers.findIndex(h => hasId(h, colId));
    if (targetIndex < 0) {
      return 0;
    }
    const target = headers[targetIndex]
    const hasChild = (target.children ?? []).length > 0;
    const beforeHeaders = headers.slice(0, targetIndex);
    return beforeHeaders.reduce((prev, cur) => prev + headerNumRecursive(cur), 0)
      + (hasChild ? countRecursive(target.children ?? []) : 0);
  }

  return countRecursive(headers);
}

/**
 * ヘッダ定義から列IDのリストを取得する
 * 子ヘッダがある場合は最下層の子ヘッダのIDを列IDとする
 * @param {Header[]} headers ヘッダ定義
 * @returns {string[]} 列IDのリスト
 */
function getColIdList(headers) {
  const idFinder = (headers) => {
    return headers.map(h => {
      if (!h.children?.length) {
        return [h.id];
      }
      return idFinder(h.children).flat();
    }).flat();
  };
  return idFinder(headers);
}

/**
 * ヘッダ定義のリストから最大の子要素ネストの深さを取得する
 * @param {Header[]} headers
 * @returns {number} ネストの深さの最大値
 */
function getLongestDepth(headers) {
  /**
   * 子要素を再帰的に探索する
   * @param {Header[]|undefined} headers 探索対象のヘッダ定義
   * @param {number} cur 現在の深さ
   */
  const recursiveFinder = (headers, cur) => {
    if (!headers?.length) {
      return cur;
    }
    return headers.map(h => {
      return recursiveFinder(h.children, cur + 1);
    }).reduce((a, b) => Math.max(a, b));
  }
  return recursiveFinder(headers, 0);
}

/**
 * 全体のヘッダ定義から対象ヘッダ行のヘッダ定義を取り出す
 * @param {Header[]} headers 全体のヘッダ定義
 * @param {number} rowNumber 対象の行番号(1始まり)
 * @returns {(Header & HeaderMeta)[]} 対象ヘッダ行のヘッダ情報
 */
function getTargetRowHeaders(headers, rowNumber) {
  const finder = (headers, cur) => {
    if (cur === rowNumber) {
      return headers;
    }
    return headers.map(h => {
      if (!Array.isArray(h.children)) {
        return [];
      }
      return finder(h.children, cur + 1);
    }).flat();
  }
  return finder(headers, 1).map(h => ({
    ...h,
    hasChild: !!(h.children?.length),
    colSpan: h.children?.length ?? 1,
  }));
}

//#region typedef
/**
 * @typedef {object} Header ヘッダ定義
 * @property {ColID} id ID
 * @property {React.ReactNode} label 表示名
 * @property {React.CSSProperties} [style] thタグのスタイル設定
 * @property {string} [className] thタグのCSSクラス名
 * @property {Header[]} [children] 対象ヘッダにぶら下がる子ヘッダ
 */
/**
 * @typedef {object} HeaderMeta ヘッダ定義メタ情報
 * @property {boolean} hasChild 子ヘッダを持っているかのフラグ
 * @property {number} colSpan colspanの設定値
 */
/**
 * @typedef {string} ColID 列ID
 */
/**
 * @typedef {Record<ColID, BodyCell> & {_tr?: BodyRowConfig}} DataRecord ボディ部のレコード定義
 */
/**
 * @typedef {object} BodyRowConfig ボディ部の行の設定
 * @property {string} className trタグのCSSクラス名
 */
/**
 * @typedef {object} BodyCell ボディ部のセル定義
 * @property {React.ReactNode} el セルの表示内容
 * @property {number} [colSpan] colspanに設定する値
 * @property {React.CSSProperties} [style] tdタグのスタイル設定
 * @property {string} [className] tdタグのCSSクラス名
 */
//#endregion typedef
