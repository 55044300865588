import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';

/** ドロップ領域の非活性化フラグ提供用Context */
export const DropZoneDisabled = React.createContext();

export const MaxCnt = React.createContext();

/**
 * ファイルのドロップ領域
 * @param {object} prop
 * @param {string} [prop.btnClassName] ボタンに追加で付与するCSSクラス名
 * @param {onDropFile} prop.onDropFile ファイルドロップ時のコールバック関数
 * @returns
 */
export const FileDropZone = ({ btnClassName, onDropFile }) => {
  const disabled = useContext(DropZoneDisabled);
  const maxFiles = useContext(MaxCnt);

  const onDrop = useCallback(acceptedFiles => {
    if (typeof onDropFile === 'function') {
      onDropFile(acceptedFiles);
    }
  }, [onDropFile]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    disabled,
    maxFiles
  });

  const style = isDragActive ? {
    background: '#f4f4f4',
    borderColor: '#1919db33',
    borderStyle: 'solid',
    boxShadow: '0 0 2px 1px #1919dbd1',
    outline: '1px solid #1919db33',
  } : null;

  return (
    <div className='l-uploader wdt1200' style={style} {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <p><strong>ここにファイルをドラッグ</strong></p>
        <p>または</p>
        <div className={`btn label mt10 mlrauto ${btnClassName}`}>
          <label>
            <i className='icn upload' />
            ファイルを選択
          </label>
        </div>
      </div>
    </div>
  )
}

//#region typedef
/**
 * @callback onDropFile ファイルドロップ時のコールバック関数
 * @param {File[]} acceptedFiles ドロップされたファイルのリスト
 */
//#endregion typedef
