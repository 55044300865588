//@ts-check
import React, { useMemo } from 'react';
import { Constants } from '../../../Constants';
import { ValueChangeStatus } from '../common/ValueChangeStatus';

/** 締め月のプロパティ名 */
const closingMonthProps = [
  'ryReq1', 'ryReq2', 'ryReq3',
  'ryReq4', 'ryReq5', 'ryReq6',
  'ryReq7', 'ryReq8', 'ryReq9',
  'ryReq10', 'ryReq11', 'ryReq12',
];

/**
 * 企画詳細画面のロイヤリティ報告締め月部分のフォーム
 * @param {object} props
 * @param {Proposal|undefined} props.proposal 企画情報
 * @returns
 */
export const ProposalClosingMonthForm = ({ proposal }) => {
  /** ロイヤリティ報告区分 */
  const ryReportCategoryText= useRyReportCategoryText(proposal);
  /** 前バージョンのロイヤリティ報告区分 */
  const prevRyReportCategoryText = useRyReportCategoryText(proposal?.previousVersion);

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title"><i className="icn calendar02"></i>ロイヤリティ報告締め月</h2>
      </div>

      <span className="attention">ロイヤリティ報告締め月の翌月15日までにロイヤリティ報告のご提出をお願いします</span>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '122px' }}>
            報告締め月
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={ryReportCategoryText}
                  prevValue={prevRyReportCategoryText} />
              )
            }
          </dt>
          <dd className="form-body">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>{ryReportCategoryText}</span>
            </div>
          </dd>
        </dl>
      </div>
    </section>
  );
}

/**
 * 選択されたロイヤリティ報告区分の表示用テキスト
 * @param {Proposal|ProposalPreviousVersion|undefined} proposal 企画情報
 */
const useRyReportCategoryText = (proposal) => {
  return useMemo(() => {
    if (proposal == null) {
      return '';
    }

    switch (proposal.ryReportCategory) {
      case Constants.Aniplex.RyReportCategory.Monthly:
        return '毎月';
      case Constants.Aniplex.RyReportCategory.Any:
        return '任意';
      case Constants.Aniplex.RyReportCategory.Specified:
        const selectedMonths = closingMonthProps
          .map((prop, idx) => ({
            name: `${idx + 1}月`,
            checked: proposal[prop],
          }))
          .filter(val => val.checked)
          .map(val => val.name)
          .join(',');
        return `指定月（${selectedMonths}）`;
      default:
        return '';
    }
  }, [proposal]);
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalPreviousVersion} ProposalPreviousVersion
 */
//#endregion typedef
