import dayjs from "dayjs"
import DatePicker from 'react-datepicker';
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearApiStatus, fetchAll, fetchAllProposals, selectApiStatus } from "../../../slices/licensee/proposalsSlice";
import { clearApiStatus as clearUsersApiStatus, fetchMyself, selectMyself } from '../../../slices/licensee/usersSlice';
import { selectPropertySummaryMst } from "../../../slices/licensee/masterSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList"
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay"
import { SpannableTableView } from "../../common/table/SpannableTableView"
import { Constants } from "../../../Constants"
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { SearchableListBox } from "../../common/SearchableListBox";

/** 企画未選択時のエラーメッセージ */
const unselectedError = '設定する企画を選択してください';

/**
 * 企画 条件検索ポップアップ
 * @param {object} props
 * @param {Proposal} props.selectProposalId 企画内部コード
 * @param {OnClose} props.onClose 閉じるときのコールバック
 * @param {string} [props.targetProposalStatus='APP'] 対象とする企画のステータス(省略時は「承認済み」)
 */
export const SearchProposalPopup = ({ selectProposalId, onClose, targetProposalStatus = Constants.Licensee.ProposalStatus.Approved }) => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const searchResult = useSelector(fetchAllProposals);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  // 検索フォームの入力データ
  const [searchFormData, setSearchFormData] = useState({
    /** 作品名称（コード指定） */
    propertySummaryCode: null,
    /** 企画No */
    proposalNo: null,
    /** 企画件名 */
    proposalName: null,
    /** 申請日From */
    applicationDateFrom: null,
    /** 申請日To */
    applicationDateTo: null,
    /** 企画担当者 */
    kikakuUserName: null,
    /** 企画申請ステータス */
    proposalStatus: targetProposalStatus,
  });

  // 選択中の企画
  const [proposalId, setProposalId] = useState(selectProposalId ?? null);

  // 検索結果エラー
  const [searchError, setSearchError] = useState(null);

  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertySummaryMst);

  useEffect(() => {
    // マウント時にログイン中ユーザー情報を取得
    dispatch(fetchMyself());
    return () => {
      // 離脱時にAPIクリア
      dispatch(clearUsersApiStatus('fetch'));
      dispatch(clearApiStatus('fetchAll'));
    }
  }, [dispatch]);

  // ポップアップ表示時の検索
  useEffect(() => {
    if (initialFetched || !myself) {
      return;
    }
    // 検索パラメータにログイン中ユーザー名を含める
    const params = {
      ...searchFormData,
      kikakuUserName: myself?.username ?? '',
    }
    // 企画一覧取得処理
    dispatch(fetchAll(params));
    setSearchFormData(params)
    setInitialFetched(true);
  }, [dispatch, searchFormData, initialFetched, myself]);

  /** 企画選択時のハンドラ */
  const onSelectProposal = useCallback((id) => {
    if (!id) {
      setMessages([unselectedError]);
    } else {
      setMessages([]);
    }
    setProposalId(id);
  }, []);

  // エラーメッセージ
  const [messages, setMessages] = useState([]);
  // エラーフラグ
  const hasError = useMemo(() => messages.length > 0, [messages]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    return apiStatus?.fetchAll === 'loading';
}, [apiStatus?.fetchAll]);

  /** 検索フォームの値変更ハンドラ */
  const handleSearchFormChange = useCallback((name, value) => {
    setSearchFormData(prev => ({
      ...prev,
      [name]: value === '' ? null : value,
    }));
  }, []);

  /** 検索ボタン押下時のハンドラ */
  const onSearch = useCallback(() => {
    // 企画一覧取得処理
    dispatch(fetchAll({
      ...searchFormData,
      applicationDateFrom: searchFormData.applicationDateFrom ? dayjs(searchFormData.applicationDateFrom).format('YYYY/MM/DD') : undefined,
      applicationDateTo: searchFormData.applicationDateTo ? dayjs(searchFormData.applicationDateTo).format('YYYY/MM/DD') : undefined,
    }));
  }, [dispatch, searchFormData]);

  /** クリアボタン押下時のハンドラ */
  const onClear = useCallback(() => {
    let newVal = Object.fromEntries(
      Object.keys(searchFormData).map(key => ([key, null]))
    )
    // 企画申請ステータスのみ設定
    newVal = { ...newVal, proposalStatus: targetProposalStatus };

    setSearchFormData(newVal);
    dispatch(fetchAll(newVal));
  }, [dispatch, searchFormData, targetProposalStatus]);

  /** 設定ボタン押下時のハンドラ */
  const onSubmit = useCallback(() => {
    // 選択済みかチェック
    if (!proposalId) {
      setMessages([unselectedError]);
      return;
    } else {
      setMessages([]);
      onClose('ok', proposalId);
    }
  }, [onClose, proposalId])

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    return apiStatus?.fetchAll === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus?.fetchAll]);

  useEffect(() => {
    if (apiStatus?.fetchAll === 'error') {
      dispatch(pushMessage('企画情報の取得中にエラーが発生しました。'));
      setSearchError('検索中にエラーが発生しました。');
    }
    // APIステータスクリア
    dispatch(clearApiStatus('fetchAll'));
  }, [dispatch, apiStatus?.fetchAll]);

  return (
    <>
      <FlexiblePopup style={{ minWidth: '1000px' }}
        onClose={() => onClose('close')}
      >
        <h4 className="popup-title">企画情報設定</h4>

        <SearchForm
          propertyMst={propertyMst}
          formData={searchFormData}
          formLocked={formLocked}
          handleChange={handleSearchFormChange}
          onSearch={onSearch}
          onClear={onClear} />

        <div className="mt30" style={{ maxHeight: '47vh',  overflowY: 'auto' }}>
          <ProposalTable
            proposals={searchResult?.result.data ?? []}
            selected={proposalId}
            onSelect={onSelectProposal}
            error={searchError || (apiStatus?.fetchAll && '検索中') || null} />
        </div>

        <ErrorMessageList messages={messages} />

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-pink">
            <button
              disabled={hasError || formLocked}
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  )
}

/**
 * 検索フォーム部分
 * @param {object} props
 * @param {SearchFormData} props.formData 検索フォームのデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {HandlerSearchFormChange} props.handleChange 値変更時のハンドラ
 * @param {Function} props.onSearch 検索ボタン押下時のコールバック
 * @param {Function} props.onClear クリアボタン押下時のコールバック
 * @returns
 */
const SearchForm = ({ propertyMst, formData, formLocked, handleChange, onSearch, onClear }) => {
  /** 選択された作品 */
  const propertySummary = useMemo(() => {
    const found = propertyMst.find(i => i.propertySummaryCode === formData?.propertySummaryCode);
    if (found == null) return null;

    return {
      label: found.propertySummaryName,
      value: found.propertySummaryCode,
    };
  }, [propertyMst, formData?.propertySummaryCode]);

  return (
    <>
      <div className="l-form mt25">
        <dl className="form-set">
          <dt className="form-name">作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt650">
                <SearchableListBox
                  isDisabled={formLocked}
                  value={propertySummary}
                  onChange={item => handleChange('propertySummaryCode', item?.value)}
                  data={propertyMst}
                  labelKey='propertySummaryName'
                  valueKey='propertySummaryCode'
                  katakanaKey='propertySummaryNameKana'
                  hankakuKanaKey='propertySummaryNameHankakuKana'
                  hiraganaKey='propertySummaryNameHiragana' />
              </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">企画No</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="proposalNo"
                disabled={formLocked}
                value={formData.proposalNo ?? ''}
                onChange={ev => handleChange('proposalNo', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="proposalTitle"
                disabled={formLocked}
                value={formData.proposalTitle ?? ''}
                onChange={ev => handleChange('proposalTitle', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">申請日</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div className="input-form input-calendar mr10">
              <DatePicker
                name="applicationDateFrom"
                title="報告開始日を入力してください"
                aria-label="申請開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={formData.applicationDateFrom}
                onChange={date => handleChange('applicationDateFrom', date)} />
              </div>
              <p>〜</p>
              <div className="input-form input-calendar ml10">
              <DatePicker
                name="applicationDateTo"
                title="申請日を入力してください"
                aria-label="申請終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={formData.applicationDateTo}
                onChange={date => handleChange('applicationDateTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画担当者</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="kikakuUserName"
                disabled={formLocked}
                value={formData.kikakuUserName ?? ''}
                onChange={ev => handleChange('kikakuUserName', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt15">
        <p className="btn bg-pink" style={{ width: '160px' }}>
          <button
            disabled={formLocked}
            onClick={onSearch}
          >検索</button>
        </p>
        <p className="btn c-pink" style={{ width: '160px' }}>
          <button
            disabled={formLocked}
            onClick={onClear}
          >クリア</button>
        </p>
      </div>
    </>
  )
}

/**
 * テーブル表示部分
 * @param {object} props
 * @param {ProposalListItem[]} props.proposals 企画情報のリスト
 * @param {string} props.selected 選択中の企画内部コード
 * @param {(proposalNo: string) => void} props.onSelect 企画内部コード選択時のコールバック
 * @param {string} [props.error] エラーメッセージ
 * @returns
 */
const ProposalTable = ({ proposals, selected, onSelect, error }) => {
  let errorMsg = error;

  const headers = [
    { id: 'select', label: '', style: { minWidth: '30px' } },
    { id: 'propertySummaryName', label: '作品名称', style: { minWidth: '90px' } },
    { id: 'proposalNo', label: '企画No', style: { minWidth: '90px' } },
    { id: 'proposalTitle', label: '企画件名', style: { minWidth: '90px' } },
    { id: 'applicationDatetime', label: '申請日', style: { minWidth: '90px' } },
    { id: 'kikakuUserName', label: '企画担当者', style: { minWidth: '90px' } },
  ];

  /** @type {TableRecord[]} */
  let records = proposals.map(s => Object.fromEntries(
    Object.entries(s).map(([k, v]) => ([k, (v === '' || v == null) ? '-' : v]))
  ))
  .map(s => ({
    ...s,
    'select': (
      <>
        <input type="radio"
          id={`proposal-radio-${s.proposalId}`}
          checked={selected === s.proposalId}
          onChange={ev => onSelect(s.proposalId)} />
        <label htmlFor={`proposal-radio-${s.proposalId}`} className="form-radio"></label>
      </>
    ),
    'applicationDatetime' : (
        dayjs(s.applicationDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/M/D')
    )
  }));
  if (!errorMsg && records.length === 0) {
    errorMsg = '検索条件と一致する企画情報がありません。'
  }
  if (errorMsg) {
    const colNum = headers.length;
    records = [{
      'select': {
        colSpan: colNum,
        el: errorMsg,
      }
    }];
  }

  return (
    <SpannableTableView
      headers={headers}
      records={records} />
  )
}

//#region typedef
/**
 * @typedef {import('../proposalDetail/ProposalDetailForm').Proposal} Proposal 企画情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalListItem} ProposalListItem 企画情報
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブル表示のレコード定義
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
/**
 * @typedef {object} SearchFormData 検索フォームの入力データ
 * @property {string} propertySummaryCode 作品名称（コード選択）
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} applicationDatetime 申請日
 * @property {string} kikakuUserName 企画担当者
 */
/**
 * @callback HandlerSearchFormChange 検索フォームのデータ変更ハンドラ
 * @param {keyof SearchFormData} name 変更された項目名
 * @param {*} value 入力された値
 */
//#endregion typedef
