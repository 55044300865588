//@ts-check
import React, { useMemo } from 'react';
import { comma3 } from '../../../lib/util';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';

/**
 * 企画状況部分のフォーム
 * @param {object} params
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 */
export const RoyaltyStatusForm = ({
  royalty,
  proposalDetail,
}) => {
  /** 契約状況画面リンク表示フラグ */
  const showContractDetail = useShowContractDetail(proposalDetail);
  /** 当期のロイヤリティ金額 */
  const ryAmount = useRyAmount(royalty);
  /** 当期のオーバーロイヤリティ */
  const overRoyalty = useOverRoyalty(royalty);
  /** 過去のロイヤリティ金額 */
  const ryAmountHistory = useRyAmountHistory(royalty);
  /** 過去のオーバーロイヤリティ */
  const overRoyaltyHistory = useOverRoyaltyHistory(royalty);
  /** 当期までのロイヤリティ金額の合計 */
  const ryAmountPeriodTotal = useRyAmountPeriodTotal(royalty);
  /** 当期までのオーバーロイヤリティの合計 */
  const overRoyaltyPeriodTotal = useOverRoyaltyPeriodTotal(royalty);
  /** 報告受領後のMG残高 */
  const afterMgZan = useAfterMgZan({ royalty, proposalDetail });
  /** 報告受領後のアドバンス残高 */
  const afterAdZan = useAfterAdZan({ royalty, proposalDetail });

  return (
    <section className="mt40">
      <div className="title-pink">
        <h2 className="title">企画状況</h2>
        {
          showContractDetail && (
            <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>
              この企画のMG残高、アドバンス残高、オーバーロイヤリティは
              {/* @ts-expect-error */}
              <Link to={`/licensee/contractDetail/${proposalDetail?.sDecision?.sDecisionNo ?? ''}`}>こちら</Link>
              の画面を確認してください。
            </p>
          )
        }
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '200px' }}>MG</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="MG"
                title="MG"
                aria-label="MG"
                disabled
                value={comma3(proposalDetail?.sDecision?.mg ?? '')} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ width: '220px' }}>アドバンス</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="アドバンス"
                title="アドバンス"
                aria-label="アドバンス"
                disabled
                value={comma3(proposalDetail?.sDecision?.ad ?? '')} />
            </div>
          </dd>
        </dl>
      </div>

      {
        proposalDetail?.periodList.map(pr => (
          <div className="l-form" key={pr.period}>
            <dl className="form-set">
              <dt className="form-name" style={{ width: '200px' }}>第{pr.period}期ロイヤリティ金額</dt>
              <dd className="form-body">
                <div className="input-form wdt300 cost">
                  <input
                    type="text"
                    name={`第${pr.period}期ロイヤリティ金額`}
                    title={`第${pr.period}期ロイヤリティ金額`}
                    aria-label={`第${pr.period}期ロイヤリティ金額`}
                    disabled
                    value={
                      pr.period === royalty?.period ?
                        comma3(ryAmount) :
                        comma3(ryAmountHistory[pr.period] ?? '')
                    } />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name" style={{ width: '220px' }}>第{pr.period}期オーバーロイヤリティ</dt>
              <dd className="form-body">
                <div className="input-form wdt300 cost">
                  <input
                    type="text"
                    name={`第${pr.period}期オーバーロイヤリティ`}
                    title={`第${pr.period}期オーバーロイヤリティ`}
                    aria-label={`第${pr.period}期オーバーロイヤリティ`}
                    disabled
                    value={
                      pr.period === royalty?.period ?
                        comma3(overRoyalty) :
                        comma3(overRoyaltyHistory[pr.period] ?? '')
                    } />
                </div>
              </dd>
            </dl>
          </div>
        ))
      }

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '200px' }}>ロイヤリティ金額合計</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="ロイヤリティ金額合計"
                title="ロイヤリティ金額合計"
                aria-label="ロイヤリティ金額合計"
                disabled
                value={comma3(ryAmountPeriodTotal)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name" style={{ width: '220px' }}>オーバーロイヤリティ合計</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="オーバーロイヤリティ合計"
                title="オーバーロイヤリティ合計"
                aria-label="オーバーロイヤリティ合計"
                disabled
                value={comma3(overRoyaltyPeriodTotal)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '200px' }}>報告受領後のMG残高</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="報告受領後のMG残高"
                title="報告受領後のMG残高"
                aria-label="報告受領後のMG残高"
                disabled
                value={comma3(afterMgZan)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name" style={{ width: '220px' }}>報告受領後のアドバンス残高</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="報告受領後のアドバンス残高"
                title="報告受領後のアドバンス残高"
                aria-label="報告受領後のアドバンス残高"
                disabled
                value={comma3(afterAdZan)} />
            </div>
          </dd>
        </dl>
      </div>
    </section>
  )
}

/**
 * 契約状況詳細画面へのリンク表示フラグ
 * @param {?ProposalDetail} proposal 企画詳細
 */
const useShowContractDetail = (proposal) => {
  return useMemo(() => (proposal?.sDecision?.proposalCount ?? 0) > 1,
    [proposal?.sDecision?.proposalCount]);
}

/**
 * 当期のロイヤリティ金額
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useRyAmount = (royalty) => {
  return useMemo(() => {
    return royalty?.ryAmount ?? '';
  }, [royalty?.ryAmount]);
}

/**
 * 当期のオーバーロイヤリティ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useOverRoyalty = (royalty) => {
  return useMemo(() => {
    return royalty?.calOverRoyalty ?? '';
  }, [royalty?.calOverRoyalty]);
}

/**
 * 過去のロイヤリティ金額
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useRyAmountHistory = (royalty) => {
  return useMemo(() => {
    return Object.fromEntries(
      royalty?.ryReportHistory?.filter(h => h.period < royalty?.period)
        .map(h => ([h.period, h.ryAmount])) ?? []
    );
  }, [royalty?.period, royalty?.ryReportHistory]);
}

/**
 * 過去のオーバーロイヤリティ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useOverRoyaltyHistory = (royalty) => {
  return useMemo(() => {
    return Object.fromEntries(
      royalty?.ryReportHistory?.filter(h => h.period < royalty?.period)
        .map(h => ([h.period, h.calOverRoyalty])) ?? []
    );
  }, [royalty?.period, royalty?.ryReportHistory]);
}

/**
 * 当期までのロイヤリティ金額の合計
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useRyAmountPeriodTotal = (royalty) => {
  return useMemo(() => {
    return royalty?.calFullPeriodRoyaltyTotal ?? '';
  }, [royalty?.calFullPeriodRoyaltyTotal]);
}

/**
 * 当期までのオーバーロイヤリティの合計
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useOverRoyaltyPeriodTotal = (royalty) => {
  return useMemo(() => {
    return royalty?.calFullPeriodOverRoyalty ?? '';
  }, [royalty?.calFullPeriodOverRoyalty]);
}

/**
 * 報告受領後のMG残高
 * @param {object} params
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 */
const useAfterMgZan = ({
  royalty,
  proposalDetail,
}) => {
  const ryAmountPeriodTotal = useRyAmountPeriodTotal(royalty);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const diff = new BN(proposalDetail?.sDecision?.mg ?? 0)
      .minus(new BN(ryAmountPeriodTotal));
    return BN.max(diff, 0).toNumber();
  }, [proposalDetail?.sDecision?.mg, ryAmountPeriodTotal]);
}

/**
 * 報告受領後のアドバンス残高
 * @param {object} params
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 */
const useAfterAdZan = ({
  royalty,
  proposalDetail,
}) => {
  const ryAmountPeriodTotal = useRyAmountPeriodTotal(royalty);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const diff = new BN(proposalDetail?.sDecision?.ad ?? 0)
      .minus(new BN(ryAmountPeriodTotal));
    return BN.max(diff, 0).toNumber();
  }, [proposalDetail?.sDecision?.ad, ryAmountPeriodTotal]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RoyaltyDetail} RoyaltyDetail
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} ProposalDetail
 */
//#endregion
