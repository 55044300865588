import { useEffect, useState } from 'react';
import { UploadList } from '../../common/UploadList';
import { Config } from '../../../config';

/**
 * ライセンシー側・画像参照APIから取得したサムネイルを表示
 * @returns
 */
export const UploadedImageIcon = ({ regIconFileName, onDeleteFile }) => {
  // サムネイル一覧
  const [fileEntries, setFileEntries] = useState([]);

  // マウント時、削除時に状態を更新する
  useEffect(() => {
    setFileEntries([{
        completed: true,
        // APIから取得した画像を設定
        imageSrc: Config.PropertyIconPath + regIconFileName,
        key: regIconFileName, // ここに入るfileNoは一意の前提
        name: regIconFileName,
        iconFilename: regIconFileName,
        hasError: '',
        errorMessage: '',
    }]);
  }, [regIconFileName]);

  return (
    <UploadList fileEntries={fileEntries} onDeleteFile={onDeleteFile} />
  );
};
