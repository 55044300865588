import { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { nl2br } from '../../../lib/reactUtil';

/** 列幅調節の最小幅 */
const RESIZE_MIN = 64; // 全角3文字程

/** 列幅調節で列幅が指定されていない場合のデフォルト値 */
const RESIZE_DEFAULT = 100;

/** 列幅調節の列右端のドラッグ可能範囲 */
const RESIZE_PADDING = 8;

/** 列幅調節可能なマウスボタン 0:主、1:補助、2:副 */
const RESIZE_BUTTON = 0;

/**
 * テーブル用コンポーネント
 * @returns
 */
export const TableView = ({
  headers = [],
  records = [],
  sortParam = {},
  scrollable = false,
  verticalScrollable = false,
  verticalScrollHeight = 500,
  resizable = false,
  scrollAreaRef,
  emptyMessage,
  onSortChange,
  removalCssClass = [],
}) => {
  const tableRef = useRef(null);

  // 列幅調節の初期化
  const [headerWidth, setHeaderWidth] = useState({});
  useEffect(() => {
    if (!resizable) {
      return;
    }
    // 初期値を取得してstateに保存
    setHeaderWidth(headerWidth => {
      const newHeaderWidth = {...headerWidth};
      headers.forEach(col => {

        // すでに設定済みのカラムは無視(列の増減時に発生)
        if (newHeaderWidth[col.id]) {
          return;
        }

        // 設定済みでない場合はheadersの設定値を初期値とする
        // 何も設定しないと崩れるため、デフォルト値を用意しています
        newHeaderWidth[col.id] = col.minWidth || RESIZE_DEFAULT;
      });
      return newHeaderWidth;
    });
  }, [resizable, headers]);

  // リサイズ関連のイベント処理
  const [resizeTarget, setResizeTarget] = useState(null);
  useEffect(() => {
    if (!resizable) {
      return;
    }

    const table = tableRef.current;
    if (!table) {
      return;
    }

    // マウス移動時のイベント
    const onMouseMove = (e) => {

      // リサイズ中でない
      if (!resizeTarget) {
        const id = e.target.getAttribute('data-id');
        if (!id) {
          return;
        }
        // ヘッダーの右端にカーソルがあれば形状を変化させる
        const rect = e.target.getBoundingClientRect();
        if (e.clientX >= rect.right - RESIZE_PADDING) {
          e.target.style.cursor = 'ew-resize';
        } else {
          e.target.style.cursor = '';
        }
        return;
      }

      // リサイズ中
      e.preventDefault();
      const id = resizeTarget.getAttribute('data-id');
      const rect = resizeTarget.getBoundingClientRect();
      const newWidth = Math.max(e.clientX - rect.left, RESIZE_MIN);
      setHeaderWidth(headerWidth => ({...headerWidth, [id]: newWidth}));
    };

    // マウスボタン押下時のイベント
    const onMouseDown = (e) => {
      // ボタンを主ボタンに制限する
      if (e.button !== RESIZE_BUTTON) {
        return;
      }
      const id = e.target.getAttribute('data-id');
      if (resizeTarget || !id) {
        return;
      }
      // 右端にカーソルがある場合リサイズを開始
      const rect = e.target.getBoundingClientRect();
      if (e.clientX >= rect.right - RESIZE_PADDING) {
        e.preventDefault();
        setResizeTarget(e.target);
      }
    };

    // マウスボタン解除時のイベント
    const onMouseUp = () => setResizeTarget(null);

    // 予期せぬ動作を防ぐためpointerdownのみtableに指定
    window.addEventListener('pointermove', onMouseMove);
    table.addEventListener('pointerdown', onMouseDown);
    window.addEventListener('pointerup', onMouseUp);

    return () => {
      window.removeEventListener('pointermove', onMouseMove);
      table.removeEventListener('pointerdown', onMouseDown);
      window.removeEventListener('pointerup', onMouseUp);
    };
  }, [resizable, resizeTarget, tableRef]);

  // ソート関連の値
  const sortKey = sortParam.sortKey || '';
  const order = sortParam.order || '';

  // ソートボタン押下時の処理
  const onClick = useCallback((e, id) => {
    e.preventDefault();
    if (typeof onSortChange !== 'function') {
      return;
    }
    // 昇順→降順
    if (id === sortKey && order === 'asc') {
      return onSortChange({ sortKey: id, order: 'desc' });
    }
    // 上記以外(降順→昇順 or ソートキー変更)
    onSortChange({ sortKey:id, order: 'asc' });
  }, [sortKey, order, onSortChange]);

  // 外側divのstyle
  const divStyle = useMemo(() => {
    const style = {};

    // 縦スクロール
    if (verticalScrollable) {
      style.height = Number(verticalScrollHeight) + 'px';
      // 縦スクロールが有効な場合は横スクロールバーも表示する(見栄えの為)
      style.overflow = 'scroll';
    }

    // リサイズ可能
    if (resizable) {
      style.display = 'inline-block';
      style.maxWidth = '100%';
    }

    return style;
  }, [resizable, verticalScrollable, verticalScrollHeight]);

  // tableのstyle
  const tableStyle = useMemo(() => {
    const style = {};

    // 列幅調節可能な場合、特別なスタイルを付与
    if (resizable) {
        // 列幅の自動調節を無効化
        style.tableLayout = 'fixed';
        // HACK:これを設定しないと列幅を伸ばしても横幅が親をはみ出さない。
        style.width = '0';
    }

    // スクロール時、下の余白をなくする
    if (scrollable || verticalScrollable) {
      style.borderBottom = '0';
      style.marginBottom = '0';
      style.paddingBottom = '0';
    }

    return style;
  }, [resizable, scrollable, verticalScrollable]);

  // ヘッダー行の表示
  const headCols = useMemo(() => headers.map(col => {

    const className = [];
    // ソート可能アイコン
    if (col.sortable) {
      className.push('btn-sort');
      // 昇順・降順アイコン
      const currentSort = (sortKey === col.id); // ソート中の列か?
      if (currentSort && order === 'asc') {
        className.push('btn-sort-asc');
      } else if (currentSort && order === 'desc') {
        className.push('btn-sort-desc');
      }
    }

    // ヘッダー行のスタイル
    const style = {};

    // 縦スクロール時のヘッダー固定
    if (verticalScrollable) {
      style.position = 'sticky';
      style.top = '0';
      style.zIndex = '1';
    }

    // 列幅設定
    if (resizable) {
      // リサイズ可能
      style.width = headerWidth[col.id] || 'auto';
      // はみ出した分を「…」で表示
      style.overflow = 'hidden';
      style.textOverflow = 'ellipsis';
      style.whiteSpace = 'nowrap';
    } else {
      // 固定
      if (!Number.isNaN(col.minWidth)) {
        style.minWidth = Number(col.minWidth) + 'px';
      }
    }

    return (
      // ソート可能ならソートアイコンのクラス名を指定
      // デザイン案に無いがbtn-sort-ascとbtn-sort-descのクラスも付ける
      <th
        key={col.id}
        data-id={col.id}
        className={className.join(' ')}
        style={style}
        onClick={col.sortable ? ((e) => onClick(e, col.id)) : null}
      >
        {nl2br(col.label) || ''}
      </th>
    );
  }), [resizable, headers, sortKey, order, onClick, verticalScrollable, headerWidth]);

  // 各行の表示
  // デザイン案では作品アイコン等、1列目がthになる場合があるが見た目に影響が無い為tdで統一した
  const bodyRows = useMemo(() => records.map((row, i) => (
    <tr key={i}>
      {
        // ヘッダー順に表示
        headers.map(({id, align, tdStyle}) => (
          <td key={id} style={{
            // 行揃え
            // TODO: これはtdStyleに移せる
            textAlign: align || '',
            // これを設定しないと列幅が狭いとき内容がはみ出す
            whiteSpace: resizable ? 'normal' : '',
            // 列ごとの個別設定
            ...(tdStyle || {}),
          }}>{row[id] || ''}</td>
        ))
      }
    </tr>
  )), [resizable, headers, records]);

  // 0行表示
  const bodyRowsEmpty = useMemo(() => (
    <tr>
      <td colSpan={headers.length}>{emptyMessage}</td>
    </tr>
  ), [headers.length, emptyMessage]);

  // 外型divのCSSクラス名
  const classNames = useMemo(() => {
    const arr = [
      'l-table',
      'mt30',
    ];
    if (scrollable) {
      arr.push('scroll');
    }

    for (const cls of removalCssClass) {
      const idx = arr.indexOf(cls);
      if (idx >= 0) {
        arr.splice(idx, 1);
      }
    }

    return arr.join(' ');
  }, [removalCssClass, scrollable]);

  // リサイズ中のカーソル形状変化用
  const resizeOverlay = useMemo(() => Boolean(resizeTarget) && (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      cursor: 'ew-resize',
      zIndex: '9999',
      left: '0',
      top: '0',
    }}></div>
  ), [resizeTarget]);

  // テーブルの表示
  return (
    <div className={classNames} style={divStyle} ref={scrollAreaRef || null}>
      <table style={tableStyle} ref={tableRef}>
        <thead>
          <tr>{headCols}</tr>
        </thead>
        <tbody>
          {bodyRows.length > 0 ? bodyRows : bodyRowsEmpty}
        </tbody>
      </table>
      {resizeOverlay}
    </div>
  );
};
