import { useCallback } from 'react';

/**
 * ファイルアップロードのサムネイル一覧
 * 注意:裸のli要素を複数返却する為、ulで囲む必要あり
 * @returns
 */
export const UploadList = ({
  fileEntries,
  onDeleteFile,
}) => {

  // 削除ボタン押下時のコールバック
  const onDeleteClick = useCallback(deletedEntry => {
    if (typeof onDeleteFile !== 'function') {
      return;
    }
    // 削除対象の画像以外を残す
    const newEntries = fileEntries.filter(entry => entry !== deletedEntry);
    onDeleteFile(newEntries, deletedEntry);
  }, [fileEntries, onDeleteFile]);

  // サムネイル1つあたりの表示内容
  const render = useCallback(entry => {
    const { completed, name, imageSrc, hasError, errorMessage, updateStatus } = entry;

    // 進捗表示(0.0~1.0)
    let progress = 0;
    if (!completed && entry.maxSize > 0) {
      progress = Math.min(entry.uploadedSize / entry.maxSize, 1);
    }

    // ホバー時のツールチップ
    let title = name;
    if (hasError && errorMessage) {
      // エラー時はエラーメッセージを指定
      title = name + ':\n' + errorMessage
    }

    // liのスタイル
    const liStyle = hasError ? {
      background: '#e9100055',
      borderColor: '#e9100055',
      color: '#e91000',
    } : {};

    // 画像のスタイル
    const imgStyle = !completed ? {
      opacity: '0.4'
    } : {};

    // プログレスバーのスタイル
    const progressStyle = {
      background: '#333',
      bottom: '0',
      display: 'block',
      height: '5px',
      left: '0',
      borderRadius: '2px',
      position: 'absolute',
      width: '100%',
    };

    // プログレスバーの外側のスタイル
    const progressBarStyle = {
      background: '#e47683',
      border: '1px solid #e47683',
      bottom: '0',
      display: 'block',
      height: '5px',
      left: '0',
      borderRadius: '2px',
      position: 'absolute',
      width: progress * 100 + '%',
    };

    // エラー表示のスタイル
    const errorStyle = {
      background: 'rgba(0, 0, 0, 0.5)',
      bottom: '0',
      color: '#fff',
      display: 'block',
      left: '0',
      position: 'absolute',
      width: '100%',
    };

    /** 更新状態アイコン */
    const updateIcon = (() => {
      switch (updateStatus) {
        case 'new':
          return <i className='icn new'></i>;
        case 'deleted':
          return <i className='icn deleted'></i>
        default:
          return null;
      }
    })();

    return (
      <li title={title} style={liStyle} key={entry.key}>
        {Boolean(imageSrc) ? (
          // サムネイル
          <span className="list-uploader-inner">
            <p className="icn-area">
              {updateIcon}
            </p>
            <div className="img-container">
              <img src={imageSrc} alt={name} style={imgStyle} />
            </div>
          </span>
        ) : (
          // サムネイルが取得不可だった時
          hasError ? '' : '読み込み中'
        )}
        {!hasError && !completed && (
          // エラー時以外で読込中の場合はプログレスバーを表示
          <span style={progressStyle}>
            <span style={progressBarStyle}></span>
          </span>
        )}
        {hasError && (
          // エラー表示
          <span style={errorStyle}>{name + (name ? ':' : '') + errorMessage}</span>
        )}
        {Boolean(onDeleteFile) && (
          // propsに削除コールバックがある場合、削除ボタンを表示
          <button className="btn-delete" onClick={() => onDeleteClick(entry)}>
            <i className="icn cross"></i>
            削除
          </button>
        )}
      </li>
    )
  }, [onDeleteClick, onDeleteFile]);

  // ファイルが1件以上ある場合のみ表示
  return (fileEntries && fileEntries.length > 0) ? (
    fileEntries.map(render)
  ) : null;
};
