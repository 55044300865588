import { useCallback } from "react";
import { downloadFileRetry, uploadFile } from "../../lib/api/licensee"
import { useFileUpload } from "../../lib/hooks/common"
import { UploadArea } from "../common/UploadArea"
import { UploadFileList } from "../common/UploadFileList";
import { UploadedFileList } from "./UploadedFileList";

export const UploadFile = ({
  uploadedFileNoList = [],
  onChangeFileNoList,
  onChangeHasError,
  onChangeUploaded,
  onChangeHasProgress,
  onChangeHasExceeded,
  acceptableExt = [],
  filetype = 'ryProof',
  maxCnt,
  maxFileSize,
  maxFileSizeErrMsg,
}) => {
  const [fileEntries, setFileEntries, startUploading] = useFileUpload({
    isImageMode: false,
    apiUploadFile: bindUploadFile({
      filetype,
    }),
    apiDownloadFile: bindDownloadFile(filetype),
    acceptableExt,
    maxFileSize,
    maxFileSizeErrMsg,
  });

  const onDeleteFile = useCallback(entries => {
    if (typeof onChangeUploaded !== 'function') {
      return;
    }
    onChangeUploaded(entries.map(entry => entry.fileNo));
  }, [onChangeUploaded]);

  const props = {
    fileEntries,
    setFileEntries,
    startUploading,
    onChangeFileNoList,
    onChangeHasError,
    onChangeHasProgress,
    onChangeHasExceeded,
    maxCnt: maxCnt - uploadedFileNoList.length,
  };

  return (
    <UploadArea {...props} ListComponent={UploadFileList} >
      {
        uploadedFileNoList.length > 0 && (
          <UploadedFileList filetype={filetype} fileList={uploadedFileNoList} onDeleteFile={onDeleteFile} />
        )
      }
    </UploadArea>
  )
}

/**
 * ファイルアップロードAPIのパラメーターを固定する
 */
const bindUploadFile = bindOptions => async options => {
  return uploadFile({
    ...bindOptions,
    ...options,
  });
};

/**
 * ファイルダウンロードAPIのパラメーターを固定する
 */
const bindDownloadFile = bindFiletype => async fileNo => {
  return downloadFileRetry(fileNo, bindFiletype);
};
