import { useCallback, useEffect, useMemo } from 'react';
import { DropZoneDisabled, FileDropZone, MaxCnt } from './FileDropZone';
import { Config } from '../../config'

/**
 * ファイルアップロードの共通コンポーネント
 * @returns
 */
export const UploadArea = ({
  fileEntries,
  setFileEntries,
  startUploading,
  onChangeFileNoList,
  onChangeHasError,
  onChangeHasProgress,
  onChangeHasExceeded,
  children,
  ListComponent,
  maxCnt = Config.ProductImageLimit,
}) => {

  // ドロップされたファイルをアップロードする
  const onDropFile = useCallback(acceptedFiles => {
    acceptedFiles.forEach(startUploading);
  }, [startUploading]);

  // アップロード済ファイルのみのfileNoが入った配列
  const fileNoList = useMemo(() => {
    return fileEntries.filter(entry => entry.completed).map(entry => entry.fileNo);
  }, [fileEntries]);

  // アップロード済ファイルが増減した時に呼び出し元に通知する
  useEffect(() => {
    if (typeof onChangeFileNoList !== 'function') {
      return;
    }
    onChangeFileNoList(fileNoList);
  }, [fileNoList, onChangeFileNoList]);

  // エラー有無
  const hasError = useMemo(() => {
    return fileEntries.find((entry => entry.hasError));
  }, [fileEntries]);

  // エラー有無が変化した時に呼び出し元に通知する
  useEffect(() => {
    if (typeof onChangeHasError !== 'function') {
      return;
    }
    onChangeHasError(hasError);
  }, [hasError, onChangeHasError]);

  // アップロード中画像の有無
  const hasProgress = useMemo(() => {
    return fileEntries.find((entry => !entry.completed && !entry.hasError));
  }, [fileEntries]);

  // アップロード中画像の有無が変化した時に呼び出し元に通知する
  useEffect(() => {
    if (typeof onChangeHasProgress !== 'function') {
      return;
    }
    onChangeHasProgress(hasProgress);
  }, [hasProgress, onChangeHasProgress]);

  // ファイル上限数超過しているかどうか
  const hasExceeded = useMemo(() => {
    return fileEntries.length + (children?.props?.fileNoList?.length ?? 0) > maxCnt
  }, [children?.props?.fileNoList?.length, fileEntries.length, maxCnt]);

  // 上限超過状態が変化した場合に呼び出し元に通知する
  useEffect(() => {
    if (typeof onChangeHasExceeded !== 'function') {
      return;
    }
    onChangeHasExceeded(hasExceeded);
  }, [hasExceeded, onChangeHasExceeded]);

  // ファイル削除時にファイル一覧を更新する
  const onDeleteFile = setFileEntries;

  return (
    <>
      <MaxCnt.Provider value={maxCnt} >
        <DropZoneDisabled.Provider value={
          (fileEntries.length + children?.props?.fileNoList?.length ?? 0) >= maxCnt
        }>
          <FileDropZone
            btnClassName="bg-blue"
            onDropFile={onDropFile} />
          {(fileEntries.length > 0 || children) && (
            <div className="l-uploader-content mt15">
              <ul className="list-uploader-content">
                {/* 一時保存からの復帰時のサムネイルを、childrenとして受け取り、先頭に表示する */}
                {children}
                <ListComponent
                  fileEntries={fileEntries}
                  onDeleteFile={onDeleteFile} />
              </ul>
            </div>
          )}
        </DropZoneDisabled.Provider>
      </MaxCnt.Provider>
    </>
  );
};
