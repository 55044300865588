//@ts-check
import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAll, fetchAllLabels, selectApiStatus, clearApiStatus, clearFetchAll } from '../../../slices/aniplex/labelsSlice';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { labelRequestAniplex, statusListLabelAniplex } from '../../common/headerList.jsx';
import { defaultParams, LabelRequestListSearchForm } from './LabelRequestListSearchForm';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil';
import { useSaveFormData } from '../../../lib/hooks/common';
import { dateParams } from './LabelRequestListSearchForm';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
labelRequestAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [A]証紙申請一覧
 * @returns
 */
export const LabelRequestListPage = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const labelList = useSelector(fetchAllLabels);
  /** react-routerによるパラメータ */
  const routerParams = useParams();

  const proposalNo = routerParams.proposalNo || undefined

  /** @type {SearchFormData} */
  const defaultSearchParam = useMemo(() => ({
    ...defaultParams,
    proposalNo: proposalNo || '',
    filterCondition: proposalNo ? 'ALL' : 'PSO',
  }), [proposalNo]);
  const [searchParams, setSearchParams] = useState(defaultSearchParam);
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(labelRequestAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'labelApplicationNo', order: 'desc' });
  const [current, setCurrent] = useState(1);

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  // 検索パラメータ保存関連
  const {
    isRestored,
    savedData,
    saveData,
  } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.LabelRequestList,
    dataType: searchParams,
    dateParams,
  });

  // 申請中の件数
  const reqCount = useMemo(() =>
    labelList?.result?.data
      ?.filter(l => l.labelStatus === Constants.Aniplex.labelStatus.Requesting)
      .length ?? 0
  , [labelList?.result?.data])

  // 申請中の件数表示をするかのフラグ
  const showReqCount = useMemo(() => reqCount > 0, [reqCount]);

  // マウント時に証紙申請情報を取得
  useEffect(() => {
    if (!paramRestored) {
      return;
    }
    if (initialFetched) {
      return;
    }
    dispatch(fetchAll(emptyToUndefined({
      ...searchParams,
      applicationDateFrom: formatDate(searchParams.applicationDateFrom),
      applicationDateTo:   formatDate(searchParams.applicationDateTo),
      preferredDateFrom:   formatDate(searchParams.preferredDateFrom),
      preferredDateTo:     formatDate(searchParams.preferredDateTo),
      dispatchDateFrom:    formatDate(searchParams.dispatchDateFrom),
      dispatchDateTo:      formatDate(searchParams.dispatchDateTo),
    })));
    saveData(searchParams);
    setInitialFetched(true);
  }, [dispatch, initialFetched, paramRestored, saveData, searchParams]);

  // 画面離脱時にAPI通信状況をクリアする
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  // パスパラメーター変更時に検索パラメータをリセットする
  useEffect(() => {
    setSearchParams(defaultSearchParam);
    setInitialFetched(false);
  }, [defaultSearchParam]);

  // 画面突入時に保存済み検索パラメータを復元する
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (proposalNo != null) {
      // 企画書Noのパラメータが存在する場合は復元しない
      setParamRestored(true);
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }

    setSearchParams(savedData)
    setParamRestored(true);
  }, [isRestored, paramRestored, proposalNo, savedData]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const labels = labelList?.result?.data || [];
    return labels.map(label => {
      return {
        labelStatus: label.labelStatus,
        labelApplicationNo: label.labelApplicationNo,
        labelApplicationId: label.labelApplicationId,
        propertySummaryName: label.propertySummaryName,
        proposalId: label.proposalId,
        proposalNo: label.proposalNo,
        proposalTitle: label.proposalTitle,
        licenseeCode: label.licenseeCode,
        licenseeNameKanji: label.licenseeNameKanji,
        applicantUserName: label.applicantUserName,
        applicationDate: label.applicationDatetime,
        preferredDate: label.preferredDate,
        dispatchDate: label.dispatchDate,
        message: label.message,
      };
    });
  }, [labelList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'labelStatus') {
      return (a, b) => statusListLabelAniplex?.[a[sortKey]]?.index - statusListLabelAniplex?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {
      // ステータスが強調表示の対象か
      const statusEmphasis = Constants.Aniplex.labelStatusEmphasis.includes(record.labelStatus);
      const statusText = statusListLabelAniplex?.[record.labelStatus]?.text;

      return {
        ...record,
        id: record.labelApplicationId,
        //@ts-expect-error
        labelApplicationNo: <Link to={'../labelRequestDetail/' + record.labelApplicationId}>{record.labelApplicationNo}</Link>,
        //@ts-expect-error
        proposalNo: <Link to={'../proposalDetail/' + record.proposalId}>{record.proposalNo}</Link>,
        //@ts-expect-error
        licenseeCode: <Link to={'../LicenseeDetail/' + record.licenseeCode}>{record.licenseeCode}</Link>,
        labelStatus: statusEmphasis ? (
          <span className="attention">{statusText}</span>
        ) : statusText,
        applicationDate: String(record.applicationDate || '').substring(0, 10),
        dispatchDate: record.dispatchDate ? record.dispatchDate + ' 発送済み' : '',
      };
    });
  }, [sortedRecords]);

  // 検索
  const onSearch = useCallback(params => {
    dispatch(fetchAll(emptyToUndefined({
      ...params,
      applicationDateFrom: formatDate(params.applicationDateFrom),
      applicationDateTo:   formatDate(params.applicationDateTo),
      preferredDateFrom:   formatDate(params.preferredDateFrom),
      preferredDateTo:     formatDate(params.preferredDateTo),
      dispatchDateFrom:    formatDate(params.dispatchDateFrom),
      dispatchDateTo:      formatDate(params.dispatchDateTo),
    })));
  }, [dispatch]);

  // 申請中のみを検索
  const onSearchReq = useCallback(() => {
    const params = {
      ...searchParams,
      labelStatus: Constants.Aniplex.labelStatus.Requesting,
    }
    setSearchParams(params);
    // NOTE: 検索フォームのコンポーネントに渡らないのでここで保存
    saveData(params);
    onSearch(params);
  }, [onSearch, saveData, searchParams]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [labelList, sortInfo]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(labelRequestAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  return (
    <div className="wrap">
      <LabelRequestListSearchForm
        key={proposalNo || ''}
        params={searchParams}
        setParams={setSearchParams}
        onSearch={onSearch} />
      <TableVisible
        isOpen={isOpen}
        headers={labelRequestAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(labelList) && (
        <>
          {
            showReqCount && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '35px', marginBottom: '-10px' }}>
                <span className="attention" style={{ fontWeight: 'bold', marginRight: '10px' }}>申請中が{reqCount}件あります</span>
                <p className="btn bg-yellow" style={{ width: '120px' }}>
                  <button onClick={onSearchReq}>申請中を検索</button>
                </p>
              </div>
            )
          }
          <PagingTable
            headers={headers}
            records={viewRecords}
            current={current}
            pageSize={pageSize}
            sortParam={sortInfo}
            emptyMessage="検索条件と一致する証紙申請情報がありません。"
            onSortChange={setSortInfo}
            onPageChange={setCurrent}
            verticalScrollable
            scrollable
            resizable />
        </>
      )}

      {loading}

    </div>
  );
};

//#region typedef
/**
 * @typedef {import('./LabelRequestListSearchForm').SearchFormData} SearchFormData
 */
//#endregion typedef
