import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { getMessage } from "../../../lib/message";
import { isEmpty, isValidPassword, lengthRange, maxLength } from "../../../lib/validator";
import { selectMyself, updateMyself, selectApiStatus, clearApiStatus, fetchMyself } from "../../../slices/licensee/usersSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { useFocusError } from "../../../lib/hooks/common";

/** フォーム項目の順序 */
const formPropOrder = [
  'username',
  'department',
  'currentPassword',
  'password',
  'passwordConf',
];

/**
 * ログインユーザー情報編集
 */
export const UserUpdatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRedirect = useAuthRedirect();

  const apiStatus = useSelector(selectApiStatus);
  const myself = useSelector(selectMyself);

  const [username, setUsername] = useState('');
  const [department, setDepartment] = useState('');
  const [formData, setFormData] = useState({
    currentPassword: '',
    password: '',
    passwordConf: '',
  });
  const [messages, setMessages] = useState({
    username: [],
    currentPassword: [],
    password: [],
    passwordConf: [],
    department: [],
  });

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);

  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  // 未ログイン時は対応する画面へ遷移
  useEffect(() => {
    if (authRedirect != null) {
      navigate(authRedirect);
    }
  }, [authRedirect, navigate]);

  // 初期設定
  useEffect(() => {
    setUsername(myself?.username ?? '');
    setDepartment(myself?.department ?? '');
  }, [myself])

  // API管理
  useEffect(() => {
    return () => {
      // 画面離脱時にAPI状況クリア
      dispatch(clearApiStatus('update'));
      dispatch(clearApiStatus('fetch'));
    }
  }, [dispatch]);
  useEffect(() => {
    if (apiStatus.fetch === 'error') {
      dispatch(pushMessage('ログインユーザー情報取得に失敗しました'));
      dispatch(clearApiStatus('fetch'));
    }
    if (apiStatus.update === 'error') {
      dispatch(pushMessage('ログインユーザー情報編集に失敗しました'));
      dispatch(clearApiStatus('update'));
    }
  }, [apiStatus.fetch, apiStatus.update, dispatch]);
  // 処理中
  const loading = useMemo(() => {
    return [apiStatus.fetch, apiStatus.update].includes('loading') ?
    <LoadingOverlay /> : null
  }, [apiStatus.fetch, apiStatus.update])

  // 変更処理
  const handleChangeName = useCallback((value) => {
    setMessages({
      ...messages, username: validateName(value)
    });
    setUsername(value);
  }, [messages]);
  const handleChangeDepartment = useCallback((value) => {
    setMessages({
      ...messages, department: validateDepartment(value)
    });
    setDepartment(value);
  }, [messages]);
  const handleChangePass = useCallback((name, value) => {
    const tmpFormData = { ...formData, [name]: value };
    setMessages({
      ...messages, ...validatePassword(tmpFormData)
    });
    setFormData(tmpFormData);
  }, [formData, messages]);

  // 送信処理
  const onSubmit = useCallback(() => {
    const result = {
      ...validatePassword(formData),
      username: validateName(username),
      department: validateDepartment(department),
    };
    if (Object.values(result).flat().length > 0) {
      setMessages(result);
      setNeedFocusError(true);
      return;
    }
    const params = {
      updateDatetime: myself.updateDatetime
    };
    // 氏名、部署名は入力済みの時のみ設定
    if (username?.length > 0) {
      params.username = username;
    }
    if (department?.length > 0) {
      params.department = department;
    }
    // パスワード系は1つでも入力されていれば設定
    if (formData.currentPassword?.length > 0 && formData.password?.length > 0) {
      params.currentPassword =formData.currentPassword;
      params.password = formData.password;
    }
    dispatch(updateMyself(params));
  }, [dispatch, formData, myself?.updateDatetime, username, department]);
  // 更新完了
  useEffect(() => {
    if (apiStatus.update === 'finish') {
      dispatch(pushMessage('ログインユーザー情報の編集を行いました。'));
      dispatch(clearApiStatus('update'));
      // 初期化
      dispatch(fetchMyself());
      setFormData({
        currentPassword: '',
        password: '',
        passwordConf: '',
      });
    }
  }, [apiStatus.update, dispatch]);

  return (
    <>
    <h1 className="main_login-head">ユーザー情報編集</h1>
    <div className="main_login-body wrap">
      <div className="main_login-content">
        <dl className="main_login-form form-set">
          <dt className="form-name">氏名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="text" name="username" title="氏名を入力してください"
              ref={formRefs.current.username}
               maxLength={20} aria-label="氏名"
               value={username}
               onChange={(event) => handleChangeName(event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.username} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">メールアドレス</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="email" name="mailaddress" aria-label="メールアドレス" disabled
               value={myself?.mailaddress ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">会社名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="email" name="licenseeNameKanji" aria-label="会社名" disabled
              value={myself?.licenseeNameKanji ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">部署名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="text" name="department" title="部署名を入力してください"
              ref={formRefs.current.department}
               maxLength={100} aria-label="部署名"
               value={department}
               onChange={(event) => handleChangeDepartment(event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.department} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">現在のパスワード</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="password" name="currentPassword"
               title="現在のパスワードを入力してください" aria-label="現在のパスワード"
              ref={formRefs.current.currentPassword}
              maxLength={32}
               value={formData.currentPassword ?? ''}
               onChange={(event) => handleChangePass('currentPassword', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.currentPassword} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">パスワード</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="password" name="password"
               title="パスワードを入力してください" aria-label="パスワード"
              ref={formRefs.current.password}
              maxLength={32}
               value={formData.password ?? ''}
               onChange={(event) => handleChangePass('password', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.password} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">パスワード（確認）</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="password" name="passwordConf" aria-label="パスワード（確認）"
               title="パスワード（確認）を入力してください"
              ref={formRefs.current.passwordConf}
              maxLength={32}
               value={formData.passwordConf ?? ''}
               onChange={(event) => handleChangePass('passwordConf', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.passwordConf} />
          </dd>
        </dl>

        <p className="btn bg-green">
          <button onClick={() => onSubmit()}>
            保存
          </button>
        </p>
      </div>
    </div>
    {loading}
    </>
  );
}

/**
 * 氏名のバリデート
 */
function validateName(value) {
  const error = [];
  // 空を許容
  if (isEmpty(value)) {
    return [];
  }
  // 文字長
  if (!maxLength(value, 20)) {
    error.push(getMessage('maxLength', {max: 20}));
  }
  return error
}

/**
 * 部署名のバリデート
 */
 function validateDepartment(value) {
  const error = [];
  // 空を許容
  if (isEmpty(value)) {
    return [];
  }
  // 文字長
  if (!maxLength(value, 100)) {
    error.push(getMessage('maxLength', {max: 100}));
  }
  return error
}

/**
 * パスワードのバリデート
 * @param {*} formData
 */
function validatePassword(formData) {
  const someInput = Object.entries(formData).some(([key, value]) => !isEmpty(value));
  const errors = [];

  for (const[key, value] of Object.entries(formData) ) {
    errors[key] = [];
    // 必須
    if (isEmpty(value)) {
      // パスワード欄どれか1つのみ入力済みの場合は必須確認
      if (someInput) {
        errors[key].push('パスワード変更の場合は必須項目です');
      }
    } else {
      // 文字長
      if (!lengthRange(value, 8, 32)) {
        errors[key].push(getMessage('lengthRange', { min: 8, max: 32 }));
      }
      // PW形式バリデート
      if (!isValidPassword(value)) {
        errors[key].push(getMessage('isValidPassword'));
      }
      // 値の相関チェック
      if (key === 'password' && formData.currentPassword === value ) {
        // 新規パスワードと変更チェック
        errors[key].push('現在のパスワードと同じ値は使用できません');
      }
      // 新規パスワード一致確認
      if (key === 'passwordConf' && formData.password !== value) {
        errors[key].push(getMessage('isNotMatchConf', {'param':'パスワード'}));
      }
    }
  }

  return errors;
}
