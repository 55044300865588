import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { pushMessage } from '../../../slices/aniplex/utilSlice';
import { selectMyself } from '../../../slices/aniplex/usersSlice';
import { selectApiStatus, clearApiStatus, fetchProposalDetail, selectProposalDetail } from '../../../slices/aniplex/proposalsSlice';
import { selectLabelDetail, selectApiStatus as selectLabelApiStatus,
  clearApiStatus as clearLabelApiStatus, fetchLabelDetail, updateLabel } from '../../../slices/aniplex/labelsSlice';
import { useDeletePopup } from './useDeletePopup';
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { LabelProductForm } from './LabelProductForm';
import { DispatchDateForm } from './DispatchDateForm';
import { isEmpty } from '../../../lib/validator';
import dayjs from 'dayjs';
import { CommonRejectPopup } from '../CommonRejectPopup';
import { ValueChangeStatus } from '../common/ValueChangeStatus';
import { FlexiblePopup } from '../../common/FlexiblePopup';
import { MessageHistoryArea } from './MessageHistoryArea';
import { useProposalToSummaryName } from '../common/useProposalToSummaryName';

/**
 * 証紙申請画面
 * @returns
 */
export const LabelRequestDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routerParams = useParams();
  const labelApplicationId = routerParams.labelApplicationId;

  const myself = useSelector(selectMyself);

  const apiStatus = useSelector(selectApiStatus);
  const labelApiStatus = useSelector(selectLabelApiStatus);
  const label = useSelector(selectLabelDetail);
  const proposal = useSelector(selectProposalDetail);
  const [productList, setProductList] = useState([]);
  const [type, setType] = useState();

  // 作品名称
  const { propertySummaryName } = useProposalToSummaryName(proposal)

  // ポップアップ表示文言切替用
  const typeLabel = useMemo(() => {
    return { APP : '承認', REJ : '差し戻し', DEL: '削除', dispatch: '発送日設定' }
  }, []);

  // 差し戻しポップアップ
  const [rejectPopup, setRejectPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?(btn: 'ok'|'cancel', rejectMessage?: string) => void} 閉じるときのコールバック */
    onClose: null,
  });

  /** 更新系ボタン表示フラグ */
  const updateBtnVisible = useMemo(() => {
    return /** @type {string[]} */ ([
      // システム運用者以外
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ]).includes(myself?.role ?? '');
  }, [myself?.role]);

  /** 削除ボタン表示フラグ */
  const deleteBtnVisible = useMemo(() => {
    return label?.labelStatus === Constants.Aniplex.labelStatus.Approved
      && myself?.role === Constants.Aniplex.UserRole.Manager
  }, [label?.labelStatus, myself?.role])

  /** 証紙ステータス（申請中） */
  const isRequesting = useMemo(() => {
    return label?.labelStatus === Constants.Aniplex.labelStatus.Requesting
  }, [label?.labelStatus]);
  /** 証紙ステータス（承認済み） */
  const isApproved = useMemo(() => {
    return label?.labelStatus === Constants.Aniplex.labelStatus.Approved
  }, [label?.labelStatus]);
  /** 発送日有効フラグ */
  const enableDispatchDate = useMemo(() => {
    // 発送日が入力されている場合は管理者、担当者のみ
    if (label?.dispatchDate) {
      return /** @type {string[]} */ ([
        Constants.Aniplex.UserRole.Manager,
        Constants.Aniplex.UserRole.Tanto,
      ]).includes(myself?.role ?? '');
    } else {
      return true;
    }
  }, [label?.dispatchDate, myself?.role]);

  // 画面離脱時にAPIクリア
  useEffect(() => {
    // 企画詳細
    dispatch(clearApiStatus('fetchProposalDetail'));
    // 証紙詳細・更新
    dispatch(clearLabelApiStatus('fetchLabelDetail'));
    dispatch(clearLabelApiStatus('updateLabel'));
  }, [dispatch]);

  // 証紙申請詳細 取得
  useEffect(() => {
    dispatch(fetchLabelDetail(labelApplicationId));
  }, [dispatch, labelApplicationId]);
  // 企画情報 取得
  useEffect(() => {
    if (label?.proposalId) dispatch(fetchProposalDetail(label?.proposalId));
  }, [dispatch, label?.proposalId]);
  // 商品情報リスト 整形
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const products = label?.labelList.map((l) => {
      // 証紙申請済みの商品情報を取得（商品ポップアップ表示用）
      let product = proposal?.productList.find(p => p.productNo === l.productNo);
      if (product) {
        return {...product, label: l};
      }
    // productListに存在しない品番の商品はundefinedになるので除外
    }).filter((f) => f !== undefined);
    setProductList(products);
  }, [label?.labelList, proposal?.productList]);
  // 取引先名称
  const licenseeName = useMemo(() => {
    return (label?.licenseeCode ?? '') + ' ' + (label?.licenseeNameKanji ?? '');
  }, [label?.licenseeCode, label?.licenseeNameKanji]);

  /** 再読み込み */
  const requestReload = useCallback(() => {
    // 取得済みの情報削除
    dispatch(clearApiStatus('fetchProposalDetail'));
    dispatch(clearLabelApiStatus('fetchLabelDetail'));
    // 再取得
    dispatch(fetchLabelDetail(labelApplicationId));
  }, [dispatch, labelApplicationId]);

  /** 承認ポップアップ */
  const {
    onShowApprovePopup,
    popup: approvePopup,
  } = useApprovePopup({ proposal, label });

  /** 削除ポップアップ */
  const { setDeletePopupShown, deletePopup } = useDeletePopup({ label, myself });

  /** 承認 */
  const onApprove = useCallback(() => {
    setType(Constants.Aniplex.labelStatus.Approved);
    onShowApprovePopup();
  }, [onShowApprovePopup]);

  /** 差し戻し */
  const onReject = useCallback(() => {
    // 差し戻し理由の入力ポップアップ表示する
    setRejectPopup({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          // 差し戻し
          setType(Constants.Aniplex.labelStatus.Rejected);
          const param = {
            labelStatus : Constants.Aniplex.labelStatus.Rejected,
            messageContent,
            updateDatetime: label?.updateDatetime,
          };
          // 証紙情報更新
          dispatch(updateLabel({
            labelApplicationId: label?.labelApplicationId,
            params: param
          }));
        }
        setRejectPopup({
          showFlg: false,
          onClose: null,
        });
      }
    })
  }, [dispatch, label?.labelApplicationId, label?.updateDatetime]);

  /** 削除 */
  const onDelete = useCallback(() => {
    setType(Constants.Aniplex.labelStatus.Deleted);
    setDeletePopupShown(true);
  }, [setDeletePopupShown])

  /** 発送日設定 */
  const onChangeDispatch = useCallback((dispatchDate) => {
    setType('dispatch');
    const param = {
      dispatchDate: dispatchDate,
      updateDatetime: label?.updateDatetime,
    };
    // 証紙情報更新
    dispatch(updateLabel({
      labelApplicationId: label?.labelApplicationId,
      params: param
    }));
  }, [dispatch, label?.labelApplicationId, label?.updateDatetime]);

  useEffect(() => {
    const msg = typeLabel[type];

    if (labelApiStatus.updateLabel === 'finish') {
        // メッセージを表示
        let finishMsg = `${msg}を行いました。`;
        if (type === 'dispatch') {
          finishMsg = '発送が完了しました。'
        }
        dispatch(pushMessage(finishMsg));

        // APIステータスをクリア
        dispatch(clearLabelApiStatus('updateLabel'));

        // 次の画面の表示
        if (type === Constants.Aniplex.labelStatus.Deleted) {
          navigate('/aniplex/labelRequestList')
        } else {
          requestReload();
        }
        return;
      }
      if (labelApiStatus.updateLabel === 'error') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage(`${msg}の実行中にエラーが発生しました。`));
        dispatch(clearLabelApiStatus('updateLabel'));
        return;
      }
  }, [dispatch, labelApiStatus.updateLabel, requestReload, type, typeLabel, navigate]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    const target = [labelApiStatus.fetchLabelDetail,labelApiStatus.updateLabel,apiStatus.fetchProposalDetail];
    if (target.includes('loading') ) {
      return <LoadingOverlay />;
    }
    return null;
  }, [apiStatus?.fetchProposalDetail, labelApiStatus?.fetchLabelDetail, labelApiStatus?.updateLabel]);

  /** シール証紙合計 */
  const labelFigureTotal = useMemo(() => {
    if (productList && productList.length > 0) {
      return productList
          .map(p => p.label)
          .filter(l => l.labelType === 'S')
          .reduce((sum, l) => sum + (l.labelFigure ?? 0), 0);
    }
    return '';
  }, [productList]);

  return (
    <div className="wrap">
    <div className="title-border mb20" style={{ justifyContent: 'left' }}>
      <h1 className="title">証紙申請</h1>
      <p className='attention' style={{ marginLeft: '1.5em' }}>
        商品に二次使用料が発生する版権をご使用されている場合は、必ず作品担当者へ「商品二次使用版権申請書」のご提出をお願いいたします。
      </p>
      <p className="link-pageback deco-line c-aniplex" style={{ marginLeft: 'auto'}}>
        <Link to="/aniplex/labelRequestList"><i className="icn pageback"></i>証紙申請一覧へ戻る</Link>
      </p>
    </div>

    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name" style={{ width: 110 }}>取引先名称</dt>
        <dd className="form-body">
          <div className="input-form wdt300">
            <input type="text" name="取引先名称" aria-label="取引先名称" value={licenseeName} required disabled />
          </div>
        </dd>
      </dl>

      <dl className="form-set">
        <dt className="form-name required" style={{ width: 110 }}>証紙申請No</dt>
        <dd className="form-body">
          <div className="input-form wdt140">
            <input type="text" name="labelApplicationNo" aria-label="証紙申請No" value={label?.labelApplicationNo ?? ''} required disabled />
          </div>
        </dd>
      </dl>

      <dl className="form-set" style={{ flexWrap: 'wrap', width: '330px' }}>
        <dt className="form-name required">
          証紙受け取り希望日
          {
            label?.previousVersion != null && (
              <ValueChangeStatus
                value={label.preferredDate}
                prevValue={label.previousVersion.preferredDate} />
            )
          }
        </dt>
        <dd className="form-body">
          <div className="input-form input-calendar">
            <input
              type="text"
              name="preferredDate"
              aria-label="証紙受け取り希望日"
              value={label?.preferredDate ?? ''}
              required
              disabled />
          </div>
        </dd>
        <dd><p className='attention'>※申請日から実稼働日で7営業日以上を目安に<br />お願いいたします。</p></dd>
      </dl>
    </div>

    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name" style={{ width : 110 }}>申請日</dt>
        <dd className="form-body">
          <div className="input-form wdt140">
            <input type="text" name="applicationDatetime" aria-label="申請日" value={
              isEmpty(label?.applicationDatetime) ? '' :
              dayjs(label?.applicationDatetime).format('YYYY/MM/DD')} disabled />
          </div>
        </dd>
      </dl>

      <dl className="form-set">
        <dt className="form-name">申請者</dt>
        <dd className="form-body">
          <div className="input-form wdt140">
            <input type="text" name="applicantUserName" aria-label="申請者" value={label?.applicantUserName ?? ''} disabled />
          </div>
        </dd>
      </dl>

      <dl className="form-set">
        <dt className="form-name">証紙申請ステータス</dt>
        <dd className="form-body">
          <div className="input-form wdt140">
            <input type="text" name="labelStatus" aria-label="証紙申請ステータス"
              value={Constants.Aniplex.labelStatusName[label?.labelStatus] ?? ''} disabled />
          </div>
        </dd>
      </dl>
    </div>

    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name required" style={{ width: 110 }}>企画書</dt>
        <dd className="form-body">
          <div className="input-form wdt1000">
            <input type="text" name="proposalId" aria-label="企画書" value={label ? `${label.proposalNo} ${label.proposalTitle}` : ''} disabled />
          </div>
        </dd>
      </dl>
    </div>

    {
      proposal ? (
        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required" style={{ width: 110 }}>作品名</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="proposalId" aria-label="作品名" value={propertySummaryName} disabled />
              </div>
            </dd>
          </dl>
        </div>
      ) : null
    }

    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name" style={{ width: 110 }}>
          連絡事項
          {
            label?.previousVersion != null && (
              <ValueChangeStatus
                value={label.message}
                prevValue={label.previousVersion.message} />
            )
          }
        </dt>
        <dd className="form-body">
          <div className="input-form wdt1000">
            <input
              type="text"
              name="message"
              aria-label="連絡事項"
              value={label?.message ?? ''}
              disabled />
          </div>
        </dd>
      </dl>
    </div>

    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name" style={{ width: 110 }}>送付先</dt>
        <dd className="form-body" style={{ display: 'block' }}>
          <span className="attention">送付先に変更がある場合は、記載の変更をお願いいたします。</span>
          <dl className="form-set">
            <dt className="form-name required" style={{ width: 180 }}>
              郵便番号
              {
                label?.previousVersion != null && (
                  <ValueChangeStatus
                    value={label.deliveryZipCode}
                    prevValue={label.previousVersion.deliveryZipCode} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input
                  type="text"
                  name="deliveryZipCode"
                  aria-label="郵便番号"
                  disabled
                  value={label?.deliveryZipCode ?? ''} />
              </div>
            </dd>
            <dt className="form-name required" style={{ width: 110, marginLeft: 40 }}>
              電話番号
              {
                label?.previousVersion != null && (
                  <ValueChangeStatus
                    value={label.deliveryPhone}
                    prevValue={label.previousVersion.deliveryPhone} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="input-form wdt500">
                <input
                  type="text"
                  name="deliveryPhone"
                  aria-label="電話番号"
                  disabled
                  value={label?.deliveryPhone ?? ''} />
              </div>
            </dd>
          </dl>
          <dl className="form-set">
            <dt className="form-name required" style={{ width: 180 }}>
              住所
              {
                label?.previousVersion != null && (
                  <ValueChangeStatus
                    value={label.deliveryAddress}
                    prevValue={label.previousVersion.deliveryAddress} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input
                  type="text"
                  name="deliveryAddress"
                  aria-label="住所"
                  disabled
                  value={label?.deliveryAddress ?? ''} />
              </div>
            </dd>
          </dl>
          <dl className="form-set">
            <dt className="form-name required" style={{ width: 180 }}>
              宛先会社名／部署名
              {
                label?.previousVersion != null && (
                  <ValueChangeStatus
                    value={label.deliveryCorpDivision}
                    prevValue={label.previousVersion.deliveryCorpDivision} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input
                  type="text"
                  name="deliveryCorpDivision"
                  aria-label="宛名会社名／部署名"
                  disabled
                  value={label?.deliveryCorpDivision ?? ''} />
              </div>
            </dd>
          </dl>
          <dl className="form-set">
            <dt className="form-name required" style={{ width: 180 }}>
              宛名
              {
                label?.previousVersion != null && (
                  <ValueChangeStatus
                    value={label.deliveryName}
                    prevValue={label.previousVersion.deliveryName} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input
                  type="text"
                  name="deliveryName"
                  aria-label="宛名"
                  disabled
                  value={label?.deliveryName ?? ''} />
              </div>
            </dd>
          </dl>
        </dd>
      </dl>
    </div>

    <MessageHistoryArea labelDetail={label} />

    <p className="attention mt15" style={{ textAlign: 'right' }}>1商品あたりの総生産数が10,000点を超える場合、印刷証紙でのご手配が可能です。<br />
      印刷証紙ご希望の際は、連絡事項へその旨記載をお願いいたします。<br />10,000点に満たない場合はシール貼りでのご手配となります。</p>

    {
      productList && (
        <LabelProductForm
          productList={productList}
          prevLabelList={label?.previousVersion?.labelList}
          proposal={proposal} />
      )
    }

    {
      updateBtnVisible || deleteBtnVisible ?
        <div className="mt30 dif j-between">
          {
            updateBtnVisible ?
              <div className="l-buttons">
                <p className="btn bg-yellow" style={{ width: 100 }}>
                  <button onClick={onApprove} disabled={!isRequesting}>承認</button>
                </p>
                <p className="btn c-aniplex ml10" style={{ width: 100 }}>
                  <button onClick={onReject} disabled={!isRequesting}>差し戻し</button>
                </p>
              </div> :
              null
          }
          {
            deleteBtnVisible ?
              <p className="btn c-aniplex ml10" style={{ width: 100 }}>
                <button onClick={onDelete}><i className="icn cross"></i>削除</button>
              </p> :
              null
          }
        </div> :
        null
    }

    <DispatchDateForm
      label={label}
      onChangeDispatch={onChangeDispatch}
      updateBtnVisible={updateBtnVisible}
      isApproved={isApproved}
      enableDispatchDate={enableDispatchDate}
      labelFigureTotal={labelFigureTotal}
    />

    {approvePopup}
    {deletePopup}
    {
      rejectPopup.showFlg && (
        <CommonRejectPopup onClose={rejectPopup.onClose} />
      )
    }
    {loading}
  </div>
  );
}

/**
 * 承認ポップアップ
 * @param {object} params
 * @param {ProposalDetail|null} params.proposal 企画詳細
 * @param {LabelDetail|null} params.label 証紙申請詳細
 */
const useApprovePopup = ({
  proposal,
  label,
}) => {
  const dispatch = useDispatch();

  // 承認ポップアップ表示フラグ
  const [approvePopupShown, setApprovePopupShown] = useState(false);

  /** 契約期間外フラグ */
  const outOfContract = useMemo(() => {
    if (proposal == null) {
      return false;
    }

    const today = dayjs();
    const startDate = dayjs(proposal.contractStartDate, 'YYYY/MM/DD');
    const endDate = dayjs(proposal.contractEndDate, 'YYYY/MM/DD');

    return today.isBefore(startDate, 'date') || today.isAfter(endDate, 'date');
  }, [proposal]);

  /** ポップアップが閉じたときのコールバック */
  const onPopupClose = useCallback(
    /** @param {'ok'|'cancel'|'close'} btn */
    (btn) => {
      if (btn === 'ok' && label != null) {
        /** @type {PatchLabelParams} */
        const params = {
          'labelStatus': Constants.Aniplex.labelStatus.Approved,
          updateDatetime: label?.updateDatetime,
        }
        dispatch(updateLabel({
          labelApplicationId: label.labelApplicationId,
          params,
        }));
      }
      setApprovePopupShown(false);
  }, [dispatch, label]);

  /** 承認ポップアップを表示する */
  const onShowApprovePopup = useCallback(() => {
    setApprovePopupShown(true);
  }, []);

  /** 表示用ポップアップ */
  const popup = useMemo(() => {
    if (approvePopupShown) {
      return (
        <FlexiblePopup
          className='txt-center no-btn-close'
          onClose={() => onPopupClose('close')}
        >
          <div>
            <p className="msg wsPre" style={{ textAlign: 'center' }}>
              承認を行います。よろしいですか？
            </p>
            {
              outOfContract && (
                <p
                  className="msg wsPre"
                  style={{ color: '#ff0000', textAlign: 'center', marginTop: '0', fontWeight: 'bold' }}
                >
                  注意：契約期間外の証紙申請です。承認していいか確認してください。
                </p>
              )
            }
          </div>
          <div className="btn-wrapper">
            <div
              className="btn label mt15"
              onClick={() => onPopupClose('cancel')}
            >
              <button>キャンセル</button>
            </div>
            <div
              className='btn label mt15 bg-aniplex'
              onClick={() => onPopupClose('ok')}
            >
              <button>承認</button>
            </div>
          </div>
        </FlexiblePopup>
      )
    }

    return null;
  }, [approvePopupShown, onPopupClose, outOfContract]);

  return {
    onShowApprovePopup,
    popup,
  }
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/aniplex/labelsSlice').labelDetail} LabelDetail
 */
/**
 * @typedef {import('../../../lib/api/aniplex').PatchLabelParams} PatchLabelParams
 */
//#endregion
