import { useCallback } from "react"

/**
 * アップロードされたファイル一覧
 * @param {object} props
 * @returns {JSX.Element[]} li要素のリスト
 */
export const UploadFileList = ({
  fileEntries,
  onDeleteFile,
}) => {
  // 削除ボタン押下時のコールバック
  const onDeleteClick = useCallback(deletedEntry => {
    if (typeof onDeleteFile !== 'function') {
      return;
    }
    // 削除対象の画像以外を残す
    const newEntries = fileEntries.filter(entry => entry !== deletedEntry);
    onDeleteFile(newEntries, deletedEntry);
  }, [fileEntries, onDeleteFile]);

  // サムネイル1つあたりの表示内容
  const render = useCallback(entry => {
    const { completed, name, downloadLink, hasError, errorMessage } = entry;

    // 進捗表示(0.0~1.0)
    let progress = 0;
    if (!completed && entry.maxSize > 0) {
      progress = Math.min(entry.uploadedSize / entry.maxSize, 1);
    }

    // ホバー時のツールチップ
    let title = name;
    if (hasError && errorMessage) {
      // エラー時はエラーメッセージを指定
      title = name + ':\n' + errorMessage
    }

    // liのスタイル
    const liStyleBase = {
      height: 'auto',
      lineHeight: '1.6',
      minHeight: '9.6em',
      width: '17%',
      flex: 'unset',
    };
    const liStyle = hasError ? {
      ...liStyleBase,
      background: '#e9100055',
      borderColor: '#e9100055',
    } : liStyleBase;

    // プログレスバーのスタイル
    const progressStyle = {
      background: '#333',
      bottom: '0',
      display: 'block',
      height: '5px',
      left: '0',
      borderRadius: '2px',
      position: 'absolute',
      width: '100%',
    };

    // プログレスバーの外側のスタイル
    const progressBarStyle = {
      background: '#e47683',
      border: '1px solid #e47683',
      bottom: '0',
      display: 'block',
      height: '5px',
      left: '0',
      borderRadius: '2px',
      position: 'absolute',
      width: progress * 100 + '%',
    };

    // エラー表示のスタイル
    const errorStyle = {
      background: 'rgba(0, 0, 0, 0.5)',
      bottom: '0',
      color: '#fff',
      display: 'block',
      left: '0',
      position: 'absolute',
      width: '100%',
    };

    // ファイルアイコン
    const icon = (
      <span style={{
        display: 'block',
        color: '#19415A',
        fontSize: '10px',
        width: '2.4em',
        height: '3.2em',
        margin: 'auto',
        border: '0.2em solid',
        borderRadius: '1em 0.4em 0.4em 0.4em',
        position: 'relative',
      }}>
        <span style={{
          display: 'block',
          position: 'absolute',
          width: '0.8em',
          height: '0.8em',
          borderRight: '0.2em solid',
          borderBottom: '0.2em solid',
          borderRadius: '2em 0.4em 0.4em 0.4em',
          left: '-0.1em',
          top: '-0.1em',
        }}></span>
      </span>
    );

    return (
      <li title={title} style={liStyle} key={entry.key}>
        {Boolean(downloadLink) ? (
          // ダウンロードリンク
          <a href={downloadLink}>
            {icon}
            {name}
          </a>
        ) : (
          // リンクが取得不可だった時はファイル名をテキストで表示
          <span>
            {icon}
            <span style={hasError ? {visibility: 'hidden'} : null}>{name}</span>
          </span>
        )}
        {!hasError && !completed && (
          // エラー時以外で読込中の場合はプログレスバーを表示
          <span style={progressStyle}>
            <span style={progressBarStyle}></span>
          </span>
        )}
        {hasError && (
          // エラー表示
          <span style={errorStyle}>{name + ':' + errorMessage}</span>
        )}
        {Boolean(onDeleteFile) && (
          // propsに削除コールバックがある場合、削除ボタンを表示
          <button className="btn-delete" onClick={() => onDeleteClick(entry)}>
            <i className="icn cross"></i>
            削除
          </button>
        )}
      </li>
    )
  }, [onDeleteClick, onDeleteFile]);

  // ファイルが1件以上ある場合のみ表示
  return (fileEntries && fileEntries.length > 0) ? (
    fileEntries.map(render)
  ) : null;
}
