import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { getMessage } from "../../../lib/message";
import { isEmpty, isValidPassword, lengthRange, maxLength } from "../../../lib/validator";
import { fetchUserAuth, clearApiStatus, clearUserAuth, selectApiStatus, userAuth, registUser } from "../../../slices/licensee/licenseeUsersSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { TermsPopup } from "../TermsPopup";
import { useFocusError } from "../../../lib/hooks/common";

/** フォーム項目の順序 */
const formPropOrder = [
  'username',
  'department',
  'password',
  'passwordConf',
];

/**
 * ユーザー登録画面
 * @returns
 */
export const UserRegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRedirect = useAuthRedirect();
  const [searchParams] = useSearchParams();
  const apiStatus = useSelector(selectApiStatus);
  const userAuthInfo = useSelector(fetchUserAuth);
  // アクセストークン
  const [invitationToken, setInvitationToken] = useState('');
  // 招待ユーザー情報取得完了
  const [canSubmit, setCanSubmit] = useState(false);
  // 利用規約確認
  const [checkPolicy, setCheckPolicy] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    department: '',
    password: '',
    passwordConf: '',
  });
  const [messages, setMessages] = useState({
    username: [],
    department: [],
    password: [],
    passwordConf: [],
  });

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);
  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  // ログイン済ならホーム画面へ遷移
  useEffect(() => {
    if (authRedirect == null) {
      return navigate('/licensee/home');
    }
  }, [authRedirect, navigate]);

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      navigate('/licensee/login');
      return;
    }
    setInvitationToken(searchParams.get('token'));
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('userAuth'));
      dispatch(clearApiStatus('registUser'));
      dispatch(clearUserAuth());
    }
  }, [dispatch, navigate, searchParams]);

  // 一時トークンが取得できたら招待ユーザー情報取得
  useEffect(() => {
    if (invitationToken) {
      dispatch(userAuth(invitationToken));
    }
  }, [dispatch, invitationToken]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return (([apiStatus.userAuth, apiStatus.registUser].includes('loading')) ?
     <LoadingOverlay /> : null );
  }, [apiStatus.userAuth, apiStatus.registUser]);

  // 登録ボタン無効化フラグ
  const registDisabled = useMemo(() => {
    return (!canSubmit || !checkPolicy);
  }, [canSubmit, checkPolicy]);

  /**
   * フォーム更新ハンドラ
   * @param {'username'|'password'} prop 更新された項目名
   * @param {string} value 更新後の値
   */
  const handleChange = useCallback((prop, value) => {
    setMessages(prev => ({
      ...prev,
      [prop]: validate(prop, value, formData),
    }));
    setFormData(prev => ({
      ...prev,
      [prop]: value,
    }))
  }, [formData]);

  /**
   * パスワード更新用ハンドラ
   */
  const handleChangePass = useCallback((name, value) => {
    const tmpFormData = {...formData, [name]: value};
    setMessages(prev => ({
      ...prev,
      ...validateMultiPassword(tmpFormData)
    }));
    setFormData(prev => ({
      ...prev,
      [name] : value
    }));
  }, [formData]);

  // ログインボタン押下時のコールバック
  const onSubmit = useCallback(() => {
    // 全項目をバリデート
    const newMessages = {
      username: validate('username', formData.username),
      department: validate('department', formData.department),
      password: validate('password', formData.password),
      passwordConf: validate('passwordConf', formData.passwordConf, formData),
    };
    if (Object.values(newMessages).flat().length > 0) {
      // バリデートエラーがある場合は表示に反映して処理終了
      setMessages(newMessages);
      setNeedFocusError(true);
      return;
    }

    // ユーザー登録
    dispatch(registUser({
      params: {
        username: formData.username,
        department: formData.department,
        password: formData.password,
        updateDatetime: userAuthInfo?.updateDatetime ?? '',
      },
      invitationToken: invitationToken,
    }));
  }, [dispatch, formData, invitationToken, userAuthInfo?.updateDatetime]);

  // ライセンシーユーザー登録API成功時
  useEffect(() => {
    if (apiStatus.userAuth === 'finish') {
      setCanSubmit(true);
      dispatch(clearApiStatus('userAuth'));
      return;
    }
    if (apiStatus.userAuth === 'error') {
      // 招待ユーザー情報取得失敗時はログイン画面へ遷移
      navigate('/licensee/login');
      return;
    }
    if (apiStatus.registUser === 'finish') {
      dispatch(pushMessage('ユーザー情報の登録を行いました。'));
      navigate('/licensee/login');
      return;
    }
    if (apiStatus.registUser === 'error') {
      dispatch(pushMessage('ユーザーの登録に失敗しました'));
      dispatch(clearApiStatus('registUser'));
      return;
    }
  }, [apiStatus.userAuth, apiStatus.registUser, dispatch, navigate]);

  // 利用規約表示ポップアップ
  const [termsPopup, setTermsPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {import('./TermsPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });

  /** 利用規約表示リンク押下時のハンドラ */
  const onTermsPopupClick = useCallback(() => {
    setTermsPopup({
      showFlg: true,
      onClose: () => {
        setCheckPolicy(true);
        setTermsPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, []);

  return (
    <>
    <h1 className="main_login-head">ユーザー登録</h1>
    <div className="main_login-body wrap">
      <p className="main_login-read tac">
        利用規約をご確認いただき、下記フォームよりユーザー情報のご登録をお願いいたします。
      </p>

      <div className="main_login-content">
        <dl className="main_login-form form-set">
          <dt className="form-name">氏名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="text" name="username" title="氏名を必ず入力してください"
              ref={formRefs.current.username}
              maxLength={20} aria-label="氏名"
              value={formData.username}
              onChange={(event) => handleChange('username', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.username} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">メールアドレス</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="email" name="mailaddress" aria-label="メールアドレス" disabled
              value={userAuthInfo?.mailaddress ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">会社名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="email" name="licenseeNameKanji" aria-label="会社名" disabled
              value={userAuthInfo?.licenseeNameKanji ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">部署名</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="text" name="department" title="部署名を必ず入力してください"
              ref={formRefs.current.department}
              maxLength={100} aria-label="部署名"
              value={formData.department}
              onChange={(event) => handleChange('department', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.department} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">パスワード</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="password" name="password"
              title="パスワードを入力してください" aria-label="パスワード"
              ref={formRefs.current.password}
              maxLength={32}
              value={formData.password ?? ''}
              onChange={(event) => handleChangePass('password', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.password} />
          </dd>
        </dl>

        <dl className="main_login-form form-set">
          <dt className="form-name">パスワード（確認）</dt>
          <dd className="form-body wdt500">
            <div className="input-form">
              <input type="password" name="passwordConf" aria-label="パスワード（確認）"
              title="パスワード（確認）を入力してください"
              ref={formRefs.current.passwordConf}
              maxLength={32}
              value={formData.passwordConf ?? ''}
              onChange={(event) => handleChangePass('passwordConf', event.target.value)} />
            </div>
            <ErrorMessageList messages={messages.passwordConf} />
          </dd>
        </dl>

        <dl className="main_login-form form-set" style={{display: 'block', textAlign: 'center', marginBottom: -10}}>
          <button onClick={onTermsPopupClick} style={{textDecoration: 'underline', fontSize: 16}}>利用規約をご一読ください。</button>
          <p className="attention">※ユーザー登録ボタン押下前に、利用規約をご確認ください。</p>
        </dl>
        <p className="btn bg-green" style={{ width: '240px' }}>
          <button onClick={() => onSubmit()} disabled={registDisabled}>
            利用規約に同意してユーザー登録
          </button>
        </p>
      </div>
      {
        termsPopup.showFlg ?
          <TermsPopup onClose={termsPopup.onClose} /> :
          null
      }
    </div>
    {loading}
    </>
  );
}

/**
 * フォーム入力内容をバリデートする
 * @param {'username'|'password'} name 入力された項目名
 * @param {string} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
 function validate(name, value, data) {
  switch (name) {
    // 氏名
    case 'username':
      return validateName(value);
    // 部署名
    case 'department':
      return validateDepartment(value);
    // パスワード
    case 'password':
      return validatePassword(value);
    // パスワード（確認）
    case 'passwordConf':
      const error = validatePassword(value);
      // 一致確認
      if (value !== data.password) {
        error.push(getMessage('isNotMatchConf', {'param':'パスワード'}));
      }
      return error;
    default:
      // 上記以外の項目名の場合は何もしない
      return [];
  }
}

/**
 * 氏名のバリデート
 */
 function validateName(value) {
  const error = [];
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!maxLength(value, 20)) {
    error.push(getMessage('maxLength', {max: 20}));
  }
  return error
}

/**
 * 部署名のバリデート
 */
 function validateDepartment(value) {
  const error = [];
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!maxLength(value, 100)) {
    error.push(getMessage('maxLength', {max: 100}));
  }
  return error
}

/**
 * パスワードの共通バリデート
 * @param {string} value
 * @returns
 */
 function validatePassword(value) {
  const errors = [];
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!lengthRange(value, 8, 32)) {
    errors.push(getMessage('lengthRange', {min:8, max:32}));
  }
  if (!isValidPassword(value)) {
    errors.push(getMessage('isValidPassword'));
  }
  return errors;
}

/**
 * パスワードの一括バリデート
 */
function validateMultiPassword(formData) {
  const errors = [];
  for (const[key, value] of Object.entries(formData) ) {
    if (!['password', 'passwordConf'].includes(key)) continue;
    errors[key] = [];
    // 必須
    errors[key] = validatePassword(value);
    // 新規パスワード一致確認
    if (key === 'passwordConf' && formData.password !== value) {
      errors[key].push(getMessage('isNotMatchConf', {'param':'パスワード'}));
    }
  }
  return errors;
}
