
/**
 * エラー処理済みのエラー
 */
export class HandledError extends Error {

  constructor() {
    super();
    this.name = this.constructor.name;
  }
}
