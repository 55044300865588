//@ts-check
import { Navigate, Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuthRedirect } from '../../../lib/hooks/licensee';
import { labelRequest, statusListLabel } from '../../common/headerList';
import { fetchAll, fetchAllLabels, selectApiStatus } from '../../../slices/licensee/labelsSlice';
import { clearApiStatus as clearUsersApiStatus, fetchMyself, selectMyself } from '../../../slices/licensee/usersSlice';
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { TableVisible } from "../../common/table/TableVisible";
import { PagingTable } from "../../common/table/PagingTable";
import { LabelRequestListSearchForm, dateParams, defaultParams } from './LabelRequestListSearchForm';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil';
import { useSaveFormData } from '../../../lib/hooks/common';

const labelStatusEmphasis = Constants.Licensee.labelStatusEmphasis;

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
labelRequest.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [L]証紙申請一覧
 * @returns
 */
export const LabelRequestListPage = router => {
  const authRedirect = useAuthRedirect();
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const hasMyself = Boolean(myself);
  const username = myself?.username || undefined;
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const labelList = useSelector(fetchAllLabels);
  /** react-routerによるパラメータ */
  const routerParams = useParams();
  const proposalNo = routerParams.proposalNo || undefined;

  /**
   * 検索フォーム初期値
   * @type {SearchFormData}
   */
  const defaultSearchParam = useMemo(() => ({
    ...defaultParams,
    proposalNo: proposalNo ?? '',
  }), [proposalNo]);

  // 検索パラメータ
  const [searchParams, setSearchParams] = useState(defaultSearchParam);

  // 検索パラメータ保存関連
  const {
    isRestored,
    savedData,
    saveData,
  } = useSaveFormData({
    saveKey: Constants.Licensee.SearchFormSaveKey.LabelRequestList,
    dataType: searchParams,
    dateParams,
  });

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [headers, setHeaders] = useState(labelRequest);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'labelApplicationNo', order: 'desc' });

    /** 検索処理 */
  const onSearch = useCallback(
    /** @param {SearchFormData} params */
    params => {
      dispatch(fetchAll(emptyToUndefined({
        ...params,
        applicationDateFrom: formatDate(params.applicationDateFrom),
        applicationDateTo:   formatDate(params.applicationDateTo),
      })));
    },
  [dispatch]);

  // マウント時にログインユーザ情報を取得
  useEffect(() => {
    dispatch(fetchMyself());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearUsersApiStatus('fetch'));
    }
  }, [dispatch]);

  // 画面突入時に保存済み検索パラメータを復元
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (proposalNo != null) {
      // 企画書Noのパラメータが存在する場合は復元しない
      setParamRestored(true);
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }
    setSearchParams(savedData);
    setParamRestored(true);
  }, [isRestored, paramRestored, proposalNo, savedData]);

  // 保存済みパラメータ復元後に検索
  useEffect(() => {
    if (initialFetched) {
      return;
    }
    if (!paramRestored) {
      return;
    }
    if (!hasMyself) {
      return;
    }
    const params = {...searchParams};
    if (savedData == null && proposalNo == null) {
      // 保存済みパラメータが存在せず、企画一覧からの遷移でもない場合
      // 申請者の項目にログインユーザを設定
      params.applicantUserName = username ?? ''
    }
    setSearchParams(params);
    onSearch(params);
    saveData(params);
    setInitialFetched(true);
  }, [hasMyself, initialFetched, onSearch, paramRestored, proposalNo, saveData, savedData, searchParams, username]);

  // パスパラメータ変更時に検索パラメータをリセットする
  useEffect(() => {
    setSearchParams(defaultSearchParam);
    setInitialFetched(false);
  }, [defaultSearchParam]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const labels = labelList?.result?.data || [];
    return labels.map(label => {
      return {
        labelApplicationId: label.labelApplicationId,
        labelStatus: label.labelStatus,
        labelApplicationNo: label.labelApplicationNo,
        propertySummaryName: label.propertySummaryName,
        proposalId: label.proposalId,
        proposalNo: label.proposalNo,
        proposalTitle: label.proposalTitle,
        applicantUserName: label.applicantUserName,
        applicationDate: label.applicationDatetime,
        preferredDate: label.preferredDate,
        dispatchDate: label.dispatchDate ? label.dispatchDate + ' 発送済み' : '',
        message: label.message,
      };
    });
  }, [labelList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'labelStatus') {
      return (a, b) => statusListLabel?.[a[sortKey]]?.index - statusListLabel?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {

      // ステータスが強調表示の対象か?
      const statusEmphasis = labelStatusEmphasis.includes(record.labelStatus);
      const statisText = statusListLabel[record.labelStatus]?.text;

      return {
        ...record,
        id: record.labelApplicationId,
        //@ts-expect-error
        labelApplicationNo: <Link to={'../labelRequestDetail/' + record.labelApplicationId}>{record.labelApplicationNo}</Link>,
        //@ts-expect-error
        proposalNo: <Link to={'../proposalDetail/' + record.proposalId}>{record.proposalNo}</Link>,
        labelStatus: statusEmphasis ? (
          <span className="attention">{statisText}</span>
        ) : statisText,
        applicationDate: String(record.applicationDate || '').substring(0, 10),
      };
    });
  }, [sortedRecords]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [labelList, sortInfo]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(labelRequest.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <div className="wrap">
      <div className="mt30">
        <p className="btn bg-green" style={{ width: '160px' }}>
          {/* @ts-expect-error */}
          <Link to="/licensee/labelRequestDetail">
            <i className="icn plus"></i>
            新規証紙申請
          </Link>
        </p>
      </div>
      <hr />
      <LabelRequestListSearchForm
        params={searchParams}
        setParams={setSearchParams}
        onSearch={onSearch} />
      <TableVisible
        className="mt20"
        isOpen={isOpen}
        headers={labelRequest}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(labelList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          pageSize={pageSize}
          current={current}
          sortParam={sortInfo}
          scrollable
          resizable
          emptyMessage="検索条件と一致する証紙申請情報がありません。"
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}

    </div>
  );
};

//#region typedef
/**
 * @typedef {import('./LabelRequestListSearchForm').SearchFormData} SearchFormData
 */
//#endregion typedef
