import { useCallback, useEffect, useMemo } from 'react';
import { useAccordion } from '../../../lib/hooks/common';

/** inputのidとlabelのforに指定するidのプレフィックス */
const idPrefix = '__';

const btnStyle = { width: '160px' };

/**
 * テーブル列の表示非表示コンポーネント
 * @returns
 */
export const TableVisible = ({
  headers = [],
  checkParam = {},
  isOpen = false,
  className = 'mt30',
  onToggleClick,
  onParamChange,
  onApply,
  ignoreIdList = [],
}) => {

  // アコーディオン
  const { accordionRef, accordionStyle, onChangeOpen } = useAccordion();

  // アコーディオン開閉
  useEffect(() => {
    onChangeOpen(isOpen);
  }, [isOpen, onChangeOpen]);

  // チェックボックス押下時の処理
  const onChange = useCallback(id => {
    const newParams = { ...checkParam };
    newParams[id] = newParams[id] !== true
    if (onParamChange) {
      onParamChange(newParams);
    }
  }, [checkParam, onParamChange]);

  // 「表示を切り替える」ボタン押下時の処理
  const onClick = useCallback(() => {
    const checked = Boolean(headers.find(col => checkParam[col.id] === true));
    if (!checked) {
      // 項目が未選択の場合エラーメッセージを表示し、コールバックを呼ばない
      return;
    }
    if (onApply) {
      onApply();
    }
  }, [headers, checkParam, onApply]);

  // チェックボックスの表示
  const listItems = useMemo(() => headers
    .filter(col => !ignoreIdList.includes(col.id))
    .map(col => (
      <li key={col.id}>
        <input type="checkbox" name="" value=""
          id={idPrefix + col.id}
          checked={checkParam[col.id] === true}
          onChange={() => onChange(col.id)}
        />
        <label htmlFor={idPrefix + col.id} className="form-checkbox">{col.label}</label>
      </li>
    )), [headers, ignoreIdList, checkParam, onChange]);

  const hasChecked = useMemo(() => (
    Boolean(Object.keys(checkParam).find(key => checkParam[key]))
  ), [checkParam]);

  // 全体の表示
  return (
    <dl className={'l-acordion ' + className}>
      <dt
        className={'js-toggle_trigger acordion-head' + (isOpen ? ' is-open' : '')}
        onClick={() => {
          if (typeof onToggleClick !== 'function') {
            return;
          }
          onToggleClick();
        }}
      >
        項目の表示／非表示設定
      </dt>
      <dd className="js-toggle_body acordion-body"
        ref={accordionRef}
        style={accordionStyle}>
        <div className="acordion-body-wrap">
          <fieldset>
            <legend>項目の表示／非表示設定</legend>
            <ul className="list-form">
              {listItems}
            </ul>
          </fieldset>
          <p className="btn bg-yellow bg-blue mt20" style={btnStyle}>
            <button disable={hasChecked ? '' : undefined} onClick={onClick} style={!hasChecked ? { opacity: '0.5', cursor: 'default' } : null}>表示を切り替える</button>
          </p>
          {!hasChecked && (
            <p className="attention mt10">表示する項目を1件以上選択してください</p>
          )}
        </div>
      </dd>
    </dl>
  );
};
