import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from '../../../Constants';
import { updateLabel } from '../../../slices/aniplex/labelsSlice';
import { FlexiblePopup } from '../../common/FlexiblePopup';


/**
 * 削除ポップアップ
 * @param {object} params
 * @param {LabelDetail|null} params.label 証紙申請詳細
 */
export const useDeletePopup = ({ label }) => {
  const dispatch = useDispatch();

  /** 削除ポップアップ表示フラグ */
  const [deletePopupShown, setDeletePopupShown] = useState(false);

  /** ポップアップが閉じたときのコールバック */
  const onPopupClose = useCallback(
    /** @param {'ok'|'cancel'|'close'} btn */
    (btn) => {
      if (btn === 'ok' && label != null) {
        /** @type {PatchLabelParams} */
        const params = {
          labelStatus: Constants.Aniplex.labelStatus.Deleted,
          updateDatetime: label?.updateDatetime,
        };
        dispatch(updateLabel({
          labelApplicationId: label.labelApplicationId,
          params,
        }));
      }
      setDeletePopupShown(false);
    },
    [dispatch, label]
  );

  /** 削除ポップアップ */
  const deletePopup = useMemo(() => deletePopupShown && (
    <FlexiblePopup className='txt-center no-btn-close' onClose={() => onPopupClose('close')}>
      <div>
        <p className="msg wsPre" style={{ textAlign: 'center' }}>削除します。よろしいですか？</p>
      </div>
      <div className="btn-wrapper">
        <div className="btn label mt15" onClick={() => onPopupClose('cancel')}>
          <button>キャンセル</button>
        </div>
        <div className='btn label mt15 bg-aniplex' onClick={() => onPopupClose('ok')}>
          <button>削除</button>
        </div>
      </div>
    </FlexiblePopup>
  ), [deletePopupShown, onPopupClose]);

  return {
    setDeletePopupShown,
    deletePopup
  }
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/labelsSlice').labelDetail} LabelDetail
 */
//#endregion
