import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { LicenseeListSearchForm } from './LicenseeListSearchForm.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { fetchAll, fetchAllLicensees, selectApiStatus, clearApiStatus, clearFetchAll } from '../../../slices/aniplex/licenseesSlice';
import { licenseeAniplex } from '../../common/headerList.jsx';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
licenseeAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [A]取引先一覧画面
 * @returns
 */
export const LicenseeListPage = () => {
  const dispatch = useDispatch();

  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const licenseeList = useSelector(fetchAllLicensees);

  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(licenseeAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'licenseeCode', order: 'asc' });

  // マウント時に取引先一覧を取得
  useEffect(() => {
    dispatch(fetchAll());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const licensees = licenseeList?.result?.data ?? [];
    return licensees;
  }, [licenseeList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {
      return {
        ...record,
        key: record.licenseeCode,
        licenseeNameKanji: <Link to={'../licenseeDetail/' + record.licenseeCode}>{record.licenseeNameKanji}</Link>,
        assessment: Constants.Aniplex.licenseeAssessmentName[record.assessment] || '',
        kihonkeiyakuFlag: Constants.Aniplex.kihonkeiyakuFlagName[Boolean(record.kihonkeiyakuFlag)],
      }
    });
  }, [sortedRecords]);

  // 検索
  const onSearch = useCallback(params => {
    dispatch(fetchAll(params));
  }, [dispatch]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(licenseeAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [licenseeList, sortInfo]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">取引先一覧</h1>
      </div>
      <LicenseeListSearchForm onSearch={onSearch} />
      <TableVisible
        isOpen={isOpen}
        headers={licenseeAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(licenseeList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          current={current}
          pageSize={pageSize}
          sortParam={sortInfo}
          emptyMessage="検索条件と一致する取引先情報がありません。"
          scrollable
          resizable
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}

    </div>
  );
};
