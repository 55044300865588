import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { licenseeUserAniplex } from '../../common/headerList.jsx';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { LicenseeUserListSearchForm } from './LicenseeUserListSearchForm';
import { selectMyself } from '../../../slices/aniplex/usersSlice';
import { fetchAll, fetchAllLicenseeUsers, selectApiStatus, clearApiStatus, clearFetchAll } from '../../../slices/aniplex/licenseeUsersSlice';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
licenseeUserAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [A]ライセンシーユーザー一覧画面
 * @returns
 */
export const LicenseeUserListPage = () => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const licenseeUserList = useSelector(fetchAllLicenseeUsers);

  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(licenseeUserAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'userId', order: 'asc' });

  /** 編集可能なロールか? */
  const editable = useMemo(() => {
    if (myself == null) {
      return false;
    }
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
  }, [myself]);

  // マウント時に取引先一覧を取得
  useEffect(() => {
    dispatch(fetchAll());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // 検索
  const onSearch = useCallback(params => {
    dispatch(fetchAll(params));
  }, [dispatch]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(licenseeUserAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const licenseeUsers = licenseeUserList?.result?.data ?? [];
    return licenseeUsers;
  }, [licenseeUserList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用のレコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => ({
      ...record,
      key: record.userId,
      userId: (<Link to={'../licenseeUserUpdate/' + record.userId}>{record.userId}</Link>),
      regDatetime: record.regDatetime ? (
        <>
          {record.regDatetime.substring(0, 10)}
          <br />
          {record.regDatetime.substring(11)}
        </>
      ) : '',
      loginDatetime: record.loginDatetime ? (
        <>
          {record.loginDatetime.substring(0, 10)}
          <br />
          {record.loginDatetime.substring(11)}
        </>
      ) : '',
      userStatus: record.userStatus === 'INV' ? '招待中' : '登録済',
    }));
  }, [sortedRecords]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ライセンシーユーザー一覧</h1>
      </div>
      {editable && (
        <>
          <div className="mt30">
            <p className="btn bg-orange" style={{width: '160px'}}>
              <Link to="../licenseeUserRegister">
                <i className="icn plus"></i>
                ユーザー登録
              </Link>
            </p>
          </div>
          <hr />
        </>
      )}
      <LicenseeUserListSearchForm onSearch={onSearch} />
      <TableVisible
        isOpen={isOpen}
        headers={licenseeUserAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(licenseeUserList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          current={current}
          pageSize={pageSize}
          sortParam={sortInfo}
          emptyMessage="検索条件と一致するライセンシーユーザー情報がありません。"
          scrollable
          resizable
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}

    </div>
  );
};
