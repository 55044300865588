import { deleteComma } from "./number";

/**
 * 値が空かを判定する
 * @param {*} value 判定対象の値
 * @returns {boolean} 空の場合はtrue
 */
export function isEmpty(value) {
  if (value == null) {
    // nullishは空扱い
    return true;
  }

  if (value === '') {
    return true;
  }

  return false;
}

/**
 * 最大文字数以内かを判定する
 * @param {string} value 判定対象の値
 * @param {number} max 最大文字数
 * @returns {boolean} 最大文字数以内の場合はtrue
 */
export function maxLength(value, max) {
  if (typeof value !== 'string') {
    // string型でない場合はstringに変換
    value = String(value);
  }

  // コードポイントを考慮した文字数で判定
  const length = [...value].length;
  return length <= max;
}

/**
 * 最小文字数以上かを判定する
 * @param {string} value 判定対象の値
 * @param {number} min 最小文字数
 * @returns {boolean} 最小文字数以上の場合はtrue
 */
export function minLength(value, min) {
  if (typeof value !== 'string') {
    // string型でない場合はstringに変換
    value = String(value);
  }

  // コードポイントを考慮した文字数で判定
  const length = [...value].length;
  return min <= length;
}

/**
 * 指定文字数の範囲内かを判定する
 * @param {string} value 判定対象の値
 * @param {number} min 最小文字数
 * @param {number} max 最大文字数
 * @returns {boolean} 文字数が最小文字数以上かつ最大文字数以下の場合はtrue
 */
export function lengthRange(value, min, max) {
  return minLength(value, min) && maxLength(value, max);
}

/**
 * 値が最小値以上かを判定する
 * @param {number|string} value 判定対象の数値
 * @param {number} min 最小値
 * @returns {boolean} 値が最小値以上の場合はtrue
 */
export function minValue(value, min) {
  if (typeof value !== 'number') {
    value = Number(value);
  }

  if (isNaN(value)) {
    // 非数はエラー扱い
    return false;
  }

  return min <= value;
}

/**
 * 値が最大値以下か判定する
 * @param {number|string} value 判定対象の数値
 * @param {number} max 最大値
 * @returns {boolean} 値が最大値以下の場合はtrue
 */
export function maxValue(value, max) {
  if (typeof value !== 'number') {
    value = Number(value);
  }

  if (isNaN(value)) {
    // 非数はエラー扱い
    return false;
  }

  return value <= max;
}

/**
 * 値が最小値以上かつ最大値以下か判定する
 * @param {number|string} value 判定対象の数値
 * @param {number} min 最小値
 * @param {number} max 最大値
 * @returns {boolean} 値が最小値以上かつ最大値以下の場合はtrue
 */
export function valueRange(value, min, max) {
  return minValue(value, min) && maxValue(value, max);
}

/**
 * パスワードの文字種要件を満たしているか判定する
 * @param {string} value 判定対象の文字列
 * @returns {boolean} パスワードの文字種要件を満たしている場合はtrue
 */
export function isValidPassword(value) {
  // 半角英大文字、半角英小文字、半角数字、半角記号から最低3種類の組み合わせが存在する
  const pattern = /^(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[0-9])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])|(?=.*[0-9])(?=.*[a-z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]))[0-9A-Za-z!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

  if (typeof value !== 'string') {
    // 値がstring型でない場合はエラー扱い
    return false;
  }

  return pattern.test(value);
}

/**
 * 値がメールアドレス形式か判定する
 * @param {*} value 判定対象の値
 * @returns {boolean} メールアドレス形式の場合はtrue
 */
export function isValidEmail(value) {
  const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (typeof value !== 'string') {
    // 値がstring型でない場合はエラー扱い
    return false;
  }

  return pattern.test(value);
}

/**
 * 半角数字かどうか判定する
 * @param {*} value 判定対象の文字列
 * @returns {boolean} 数字ならtrue
 */
export function isNumber(value) {
  // 数字の形式かどうか（0詰め許容する）
  return /^[0-9]+$/.test(value);
}

/**
 * 数値かどうか判定する（小数許容）
 * @param {string} value 判定対象の文字列
 * @returns {boolean} true
 */
export function isDecimalNumber(value) {
  // 数字の形式かどうか（小数許容する）
  return /^([0-9]+\.?[0-9,]*|\.[0-9]+)$/.test(value);
}

/**
 * 小数部が指定桁数の範囲内かどうか
 * @param {*} value 判定対象の文字列
 * @param {*} digit 小数部の桁数
 * @returns {boolean} 小数部が指定桁数内ならtrue
 */
export function isDecimalWithin(value, digit) {
  if (!isDecimalNumber(value)) return false;
  const numArray = value.toString().split('.');
  if (numArray.length > 1) {
    // 小数の0抑制
    const decimalStr = numArray[1].replace(/([0-9]*[1-9]+)(0*)|^0+$/, '$1');
    return decimalStr.length <= digit;
  }
  // 小数部がない場合は桁数範囲内として扱う
  return true;
}

/**
 * 年月の形式チェック（月の0詰め有無）
 */
export function isMonth(value) {
  return /^[12][0-9]{3}\/(0?[1-9]|1[0-2])$/.test(value);
}

/**
 * カンマ含む数値の最大値チェック
 */
export function maxValueComma(value, max) {
  // 数値形式でなければfalse
  if (!isDecimalNumber(value)) return false;
  // カンマ除去
  value = deleteComma(value);
  return maxValue(value, max);
}

/**
 * 半角英数字かどうか
 * @param {string} value
 * @returns {boolean} 半角英数字のみならtrue
 */
export function isAlphanumeric(value) {
  return (/^[a-zA-Z0-9]+$/.test(value));
}

/**
 * 半角英数記号（※半角スペース含まない）
 * @param {string} value
 * @returns {boolean} 半角英数記号のみならtrue
 */
 export function isAlphanumericSymbols(value) {
  return (/^[!-~]+$/.test(value));
}
