import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { escapeRegExp } from '../../lib/util';

/** デフォルトの文字変換設定 */
const CONVERT_MAP = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
  'ー': '-',
  '－': '-',
  '―': '-',
  '‐': '-',
  '．': '.',
  '。': '.',
}

/** デフォルトの許容文字 */
const ALLOWED_CHARS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  '.',
];

/**
 * 数値入力用の入力部品
 * 全角文字が入力されると自動的に半角文字に変換する
 * @param {ParamType} params
 * @param {string|number} params.value 項目の値
 * @param {(val: string) => void} params.onChange 値変更時のハンドラ
 * @param {React.MutableRefObject} params.inputRef input要素へのref
 * @param {Record<string, string>} [params.extraConvert={}] 追加で変換をかける文字の設定.「'変換前の値': '変換後の値'」の形式で指定
 * @param {string[]} [params.extraChars=[]] 追加で許容する文字(extraConvertの設定で変換した後の文字を指定).デフォルトの許容文字は半角数字と一部記号(-.))
 * @param {Partial<JSX.IntrinsicElements['input']>} params.props
 */
export const NumberInput = ({ value, onChange, inputRef, extraConvert = {}, extraChars = [], ...props }) => {
  const localRef = useRef(null);

  // 一時保持用の値
  const [tmpVal, setTmpVal] = useState(value);
  // IME変換中フラグ
  const [inComposition, setInComposition] = useState(false);
  // 現在のカーソルの位置
  const [caretPos, setCaretPos] = useState(0);
  // キャレット位置を復元するかのフラグ
  const [restoreCaret, setRestoreCaret] = useState(false);

  // IME変換中にフォーカスを外されたときの制御用フラグ
  const [checkInput, setCheckInput] = useState(false);
  const [ignoreInput, setIgnoreInput] = useState(false);

  const ref = useMemo(() => {
    if (inputRef != null) {
      return inputRef;
    }
    return localRef
  }, [inputRef]);

  // 内部用のvalue
  const innerVal = useMemo(() => {
    if (inComposition) {
      return tmpVal;
    }
    return value;
  }, [inComposition, tmpVal, value]);

  // 値の変換と設定
  const convertVal = useCallback(val => {
    const orgLength = [...val].length;
    val = convertChars(val, { ...CONVERT_MAP, ...extraConvert });
    val = removeForbiddenChars(val, [...ALLOWED_CHARS, ...extraChars]);
    setTmpVal(val);
    if (typeof onChange === 'function') {
      onChange(val);
    }
    if ([...val].length === orgLength) {
      setRestoreCaret(true);
    }
  }, [extraChars, extraConvert, onChange]);

  // changeイベントのハンドラ
  const handleChange = useCallback(val => {
    setCheckInput(false);
    if (ignoreInput) {
      // フォーカスアウトによる誤入力防止
      return;
    }

    setTmpVal(val);
    if (!inComposition) {
      // IME変換中以外は即時反映
      convertVal(val);
    }
  }, [convertVal, ignoreInput, inComposition]);

  // inputイベントのハンドラ
  const handleInput = useCallback(ev => {
    setCheckInput(true);
    // 変換後にキャレット位置を戻すために現在の位置を保持
    setCaretPos(ev.target.selectionStart);
  }, []);

  // IME変換イベントのハンドリング
  const onCompositionStart = useCallback(() => {
    // IME変換中
    setInComposition(true);
  }, []);

  const onCompositionEnd = useCallback((ev) => {
    if (!checkInput) {
      // IME変換中にフォーカスを外されるとinputイベントより先にendイベントがくるので、
      // それを検知して入力抑制フラグを立てる
      setIgnoreInput(true);
      // 将来的なブラウザの仕様変更に備えて
      // 抑制フラグの解除処理はイベントキューに積む形にする
      setTimeout(() => setIgnoreInput(false), 10);
    }

    // IME変換確定
    setInComposition(false);
    // 入力内容に変換をかけて反映
    convertVal(tmpVal);
  }, [checkInput, convertVal, tmpVal]);

  // キャレット位置復元処理
  useEffect(() => {
    if (restoreCaret) {
      setRestoreCaret(false);
      if (ref?.current) {
        ref.current.selectionStart = caretPos;
        ref.current.selectionEnd = caretPos;
      }
    }
  }, [caretPos, ref, restoreCaret]);

  return (
    <input type="text"
      ref={ref}
      value={innerVal}
      onChange={ev => handleChange(ev.target.value)}
      onInput={handleInput}
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
      {...props} />
  )
}

/**
 * 文字の変換を行う
 * @param {string} str 変換対象の文字列
 * @param {Record<string, string>} config 変換の設定
 */
function convertChars(str, config) {
  let result = str;
  Object.entries(config).forEach(([key, val]) => {
    result = result.replace(new RegExp(escapeRegExp(key), 'g'), val);
  });
  return result;
}

/**
 * 許容文字以外を削除する
 * @param {string} str 処理対象の文字列
 * @param {string[]} allowedChars 許容文字
 * @returns 許容文字以外を削除した文字列
 */
function removeForbiddenChars(str, allowedChars) {
  const regex = new RegExp('[^' + escapeRegExp(allowedChars.join('')) + ']', 'g');
  return str.replace(regex, '');
}

/**
 * @typedef {{
 *   value: string,
 *   onChange: (val: string) => void,
 *   inputRef: React.MutableRefObject
 *   extraConvert: Record<string, string>,
 *   extraChars: string[],
 * }} Params
 * @typedef {Omit<JSX.IntrinsicElements['input'], 'value'|'onChange'> | Params} ParamType
 */
