import { TableView } from './TableView';
import { useCallback, useMemo, useRef } from 'react';

/**
 * ページング可能なテーブル
 * current:現在のページ,pageSize:1ページあたりの項目数が必須
 * @param {object} params
 * @param {*} params.headers
 * @param {any[]} params.records
 * @param {*} params.sortParam
 * @param {boolean} [params.scrollable]
 * @param {boolean} [params.verticalScrollable]
 * @param {boolean} [params.resizable]
 * @param {number} params.current
 * @param {number} params.pageSize
 * @param {number} [params.pageItemCount=11]
 * @param {string} [params.emptyMessage]
 * @param {Function} [params.onSortChange]
 * @param {Function} [params.onPageChange]
 * @param {string} [params.removalCssClass]
 */
export const PagingTable = ({
  headers,
  records,
  sortParam,
  scrollable,
  verticalScrollable,
  resizable,
  current,
  pageSize,
  pageItemCount = 11,
  emptyMessage,
  onSortChange,
  onPageChange,
  removalCssClass,
}) => {
  // 分割数
  pageSize = Math.max(pageSize, 1);

  const scrollAreaRef = useRef(null);

  // ページ数
  const maxPage = Math.ceil(records.length / pageSize);

  // ページ番号(1～ページ数分)
  current = Math.max(current, 1);
  current = Math.min(current, maxPage);

  // ページ番号が1 or 最後か
  const isFirst = current === 1;
  const isLast = current === maxPage;

  // ページジングボタンクリック時の処理
  const onClick = useCallback(value => {
    // 変化なし or 範囲外の時は何もしない
    if (value === current || value <= 0 || value > maxPage) {
      return;
    }
    // 新しいページ番号をコールバックに渡す
    if (onPageChange) {
      if (scrollAreaRef && scrollAreaRef.current) {
        window.currenter = scrollAreaRef.current;
        scrollAreaRef.current.scrollTo(scrollAreaRef.current.scrollLeft, 0);
      }
      onPageChange(value);
    }
  }, [current, maxPage, onPageChange]);

  // ページ内の行のみに絞る
  const pageRecords = useMemo(() => (
    records.slice((current - 1) * pageSize, current * pageSize)
  ), [records, current, pageSize]);

  // ページ番号の表示
  const pageItems = useMemo(() => {
    const DOT = -1;

    // 1～最終ページの廃立を作成
    let pages = [];
    for (let i = 1; i <= maxPage; i++) {
      pages.push(i);
    }

    // pageItemCountの数になるように切り抜く
    if (pages.length > pageItemCount) {
      const half = Math.floor(pageItemCount / 2);
      const left = Math.min(Math.max(current - 1 - half, 0), maxPage - pageItemCount);
      const right = left + pageItemCount;
      pages = pages.slice(left, right);
    }

    // 端の処理(左端は1、右端はmaxPageとして、省略された箇所にDOTを指定)
    if (pages[0] !== 1) {
      pages[0] = 1;
      pages[1] = DOT;
    }
    if (pages[pages.length - 1] !== maxPage) {
      pages[pages.length - 1] = maxPage;
      pages[pages.length - 2] = DOT;
    }

    // JSXの構築
    return pages.map((i, idx) => {
      if (i === DOT) {
        return (
          <li key={'dot' + idx}>…</li>
        );
      }
      const isCurrent = i === current;
      return (
        // 現在のページであればクラス名を付与
        <li className={isCurrent ? 'page-active' : ''} key={i}>
          <a disabled={isCurrent} onClick={!isCurrent ? () => onClick(i) : null}>{i}</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
        </li>
      );
    });
  }, [current, maxPage, pageItemCount, onClick]);

  const tableViewProps = {
    records: pageRecords,
    headers,
    sortParam,
    scrollable,
    resizable,
    scrollAreaRef,
    emptyMessage,
    onSortChange,
    verticalScrollable,
    removalCssClass,
  };

  // 全体の表示
  return (
    <>
      <TableView { ...tableViewProps } />
      {maxPage >= 2 && (
        <div className="l-pager">
          <ul className="list-pager">
            <li className="page-prev">
              <a style={isFirst ? disabledStyle : null}onClick={!isFirst ? () => onClick(current - 1) : null}>prev</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
            </li>
            {pageItems}
            <li className="page-next">
              <a style={isLast ? disabledStyle : null} onClick={!isLast? () => onClick(current + 1) : null}>next</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

const disabledStyle = {
  backgroundColor: '#f4f4f4',
  borderColor: 'transparent',
  cursor: 'default',
  opacity: '0.6',
};
