import { useEffect, useState, useCallback } from 'react';
import { MessagePopup } from '../common/MessagePopup';
import { Config } from '../../config';

/**
 * セッションタイムアウトの警告をするポップアップ
 * @param {*} onClose ポップアップイベント
 * @returns
 */
export const TimeoutWarningPopup = ({onClose}) => {
  return (
    <MessagePopup
      onClose={onClose}
      btn={{ok: '閉じる'}}
      btnClass="bg-pink"
      message={`長時間の操作がない状態が続いたため、セキュリティ上の観点からまもなくログアウトされます。\n作業中のデータは、画面下の「一時保存」ボタンで保存を行ってください。`} />
  );
}

/**
 * セッションタイムアウトの警告をするフック
 */
export const useTimeoutWarningPopup = (isEditable) => {
  const [popup, setPopup] = useState(false);
  const [count, setCount] = useState(0); // タイマーのリセットに使用

  const resetTimer = useCallback(() => {
    setCount(count + 1);
  }, [count]);

  useEffect(() => {
    if (!isEditable) {
      return;
    }
    const t = setTimeout(() => {
      setPopup(true);
    }, 1000 * 60 * Config.TimeoutMinutes);
    return () => {
      window.clearTimeout(t);
    };
  }, [count, isEditable]);

  const component= popup ? (
    <TimeoutWarningPopup
      onClose={() => setPopup(false)} />
  ) : null;
  return [component, resetTimer];
};
