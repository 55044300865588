import { useEffect, useState } from 'react';
import { comma3 } from '../../../lib/util';

/**
 * S決裁情報
 */
export const ProposalSdecisionForm = ({proposal}) => {
  const [decision, setDecision] = useState({});
  useEffect(() => {
    if (proposal) {
      setDecision(proposal?.sDecision);
    }
  }, [proposal])

  return (
    <>
      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title">S決裁情報</h2>
        </div>

        <div className="dif">
          <div>
            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>S決裁No</dt>
                <dd className="form-body">
                  <div className="input-form wdt140">
                    <input type="text" name="S決裁No" aria-label="S決裁No"
                     disabled value={decision?.sDecisionNo ?? ''} />
                  </div>
                </dd>
              </dl>

              <dl className="form-set">
                <dt className="form-name">プロパティコード</dt>
                <dd className="form-body">
                  <div className="input-form wdt140">
                    <input type="text" name="プロパティコード" aria-label="プロパティコード"
                     disabled value={decision?.propertyCode ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>プロパティ名</dt>
                <dd className="form-body">
                  <div className="input-form wdt600">
                    <input type="text" name="プロパティ名" aria-label="プロパティ名"
                    disabled value={decision?.propertyName ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px'}}>件名</dt>
                <dd className="form-body">
                  <div className="input-form wdt600">
                    <input type="text" name="件名" aria-label="件名"
                    disabled value={decision?.sTitle ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>許諾製造物（商品）</dt>
                <dd className="form-body">
                  <div className="input-form wdt600">
                    <input type="text" name="許諾製造物（商品）" aria-label="許諾製造物（商品）"
                    disabled value={decision?.kyodakuseizobutsu ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>商品カテゴリー</dt>
                <dd className="form-body">
                  <div className="input-form wdt600">
                    <input type="text" name="商品カテゴリー" aria-label="商品カテゴリー"
                    disabled value={decision?.category ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>経済条件</dt>
                <dd className="form-body">
                  <div className="form-textarea wdt600">
                    <textarea rows="4" name="経済条件" aria-label="経済条件"
                    disabled value={decision?.keizaijoken ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>MG（最低保証料）</dt>
                <dd className="form-body">
                  <div className="input-form cost wdt200">
                    <input type="text" name="MG（最低保証料）" aria-label="MG（最低保証料）"
                    disabled value={comma3(decision?.mg) ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '160px' }}>MG残高</dt>
                <dd className="form-body">
                  <div className="input-form cost wdt200">
                    <input type="text" name="MG残高" aria-label="MG残高"
                    disabled value={comma3(decision?.mgZan) ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>
          </div>

          <div className="ml50">
            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>担当者</dt>
                <dd className="form-body">
                  <div className="input-form wdt200">
                    <input type="text" name="担当者" aria-label="担当者"
                    disabled value={decision?.sTantousya ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>契約締結日</dt>
                <dd className="form-body">
                  <div className="input-form wdt200 input-calendar">
                    <input type="text" name="契約締結日" aria-label="契約締結日"
                    disabled value={decision?.keiyakuDate ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>許諾開始日</dt>
                <dd className="form-body">
                  <div className="input-form wdt200 input-calendar">
                    <input type="text" name="許諾開始日" aria-label="許諾開始日"
                    disabled value={decision?.startDate ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>許諾終了日</dt>
                <dd className="form-body">
                  <div className="input-form wdt200 input-calendar">
                    <input type="text" name="許諾終了日" aria-label="許諾終了日"
                    disabled value={decision?.endDate ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>販売開始期限</dt>
                <dd className="form-body">
                  <div className="input-form wdt200">
                    <input type="text" name="販売開始期限" aria-label="販売開始期限"
                    disabled value={decision?.sLaunchDate ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>セルオフ期間</dt>
                <dd className="form-body">
                  <div className="input-form wdt200">
                    <input type="text" name="セルオフ期間" aria-label="セルオフ期間"
                    disabled value={decision?.selloff ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>キャラクター使用許諾金</dt>
                <dd className="form-body">
                  <div className="input-form cost wdt200">
                    <input type="text" name="キャラクター使用許諾金" aria-label="キャラクター使用許諾金"
                    disabled value={comma3(decision?.kyodakukin) ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>アドバンスロイヤリティ</dt>
                <dd className="form-body">
                  <div className="input-form cost wdt200">
                    <input type="text" name="アドバンスロイヤリティ" aria-label="アドバンスロイヤリティ"
                    disabled value={comma3(decision?.ad) ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '200px' }}>アドバンス残高</dt>
                <dd className="form-body">
                  <div className="input-form cost wdt200">
                    <input type="text" name="アドバンス残高" aria-label="アドバンス残高"
                    disabled value={comma3(decision?.adZan) ?? ''} />
                  </div>
                </dd>
              </dl>
            </div>

          </div>
        </div>

      </section>
    </>
  )
}
