import { FlexiblePopup } from "../../common/FlexiblePopup"
import DatePicker from "react-datepicker";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { isEmpty } from "../../../lib/validator";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { clearApiStatus, selectApiStatus, updateContractPeriod } from "../../../slices/aniplex/proposalsSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { getMessage } from "../../../lib/message";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { useFocusError } from "../../../lib/hooks/common";
import { Constants } from "../../../Constants";

/** フォーム項目の順番 */
const formPropOrder = [
  'contractEndDate',
  'ryReq',
];

/** 締め月のプロパティ名 */
const closingMonthProps = [
  'ryReq1', 'ryReq2', 'ryReq3',
  'ryReq4', 'ryReq5', 'ryReq6',
  'ryReq7', 'ryReq8', 'ryReq9',
  'ryReq10', 'ryReq11', 'ryReq12',
];

/**
 * 契約期間の変更ポップアップ
 * @param {object} params
 * @param {ProposalDetail|undefined} params.proposal 対象の企画詳細情報
 * @param {OnClose} params.onClose ポップアップを閉じたときのコールバック関数
 */
export const UpdateContractPopup = ({ proposal, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateContractPeriod;

  // フォームデータ
  const [formData, setFormData] = useState(/** @type {FormData} */ ({
    /** 契約終了日 */
    contractEndDate: '',
    /** ロイヤリティ報告区分 */
    ryReportCategory: Constants.Aniplex.RyReportCategory.Monthly,
    /** ロイヤリティ報告締め月_1月 */
    ryReq1: false,
    /** ロイヤリティ報告締め月_2月 */
    ryReq2: false,
    /** ロイヤリティ報告締め月_3月 */
    ryReq3: false,
    /** ロイヤリティ報告締め月_4月 */
    ryReq4: false,
    /** ロイヤリティ報告締め月_5月 */
    ryReq5: false,
    /** ロイヤリティ報告締め月_6月 */
    ryReq6: false,
    /** ロイヤリティ報告締め月_7月 */
    ryReq7: false,
    /** ロイヤリティ報告締め月_8月 */
    ryReq8: false,
    /** ロイヤリティ報告締め月_9月 */
    ryReq9: false,
    /** ロイヤリティ報告締め月_10月 */
    ryReq10: false,
    /** ロイヤリティ報告締め月_11月 */
    ryReq11: false,
    /** ロイヤリティ報告締め月_12月 */
    ryReq12: false,
  }));

  // エラーメッセージ
  const [messages, setMessages] = useState(/** @type {Record<string, string[]>} */ ({
    contractEndDate: [],
    ryReq: [],
  }));

  // 締め月期間外エラー
  const closingMonthExcluded = useMemo(() => {
    return !checkAllMonthIncluded({
      ...formData,
      contractStartDate: proposal?.contractStartDate,
    });
  }, [formData, proposal?.contractStartDate])

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  /** 契約終了日 */
  const contractEndDate = useMemo(() => {
    // 日付文字列をDateオブジェクトに変換
    return isEmpty(formData.contractEndDate) ?
      null :
      dayjs(formData.contractEndDate, 'YYYY/MM/DD').toDate();
  }, [formData.contractEndDate]);

  // ポップアップを開いたときに現在の企画の情報をフォームに反映
  useEffect(() => {
    setFormData(prev => {
      const newData = {};
      for (const prop of Object.keys(prev)) {
        newData[prop] = proposal?.[prop] != null ? proposal[prop] : prev[prop];
      }
      return newData;
    });
  }, [proposal]);

  /**
   * フォーム更新ハンドラ
   * @type {(name: keyof FormData, value: FormData[name]) => void}
   */
  const handleChange = useCallback((name, value) => {
    setFormData(prev => {
      // バリデート
      const errors = validate(name, value, {...prev, [name]: value});
      if (/^ryReq\d{1,2}$/.test(name)) {
        // ロイヤリティ報告締め月のエラーは1つにまとめる
        setMessages(prev => ({
          ...prev,
          ryReq: errors,
        }));
      } else {
        setMessages(prev => ({
          ...prev,
          [name]: errors,
        }));
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  /**
   * DatePicker用の更新ハンドラ
   * @type {(name: keyof typeof formData, value: ?Date) => void}
   */
  const handleDateChange = useCallback((name, date) => {
    const value = isEmpty(date) ? '' : dayjs(date).format('YYYY/MM/DD');
    handleChange(name, value);
  }, [handleChange]);

  /**
   * フォームの全項目をバリデートする
   * @type {() => Record<string, string[]>}
   */
  const validateAll = useCallback(() => {
    /** @type {Record<string, string[]>} */
    const messages = {};
    for (const prop of /** @type {(keyof FormData)[]} */ (Object.keys(formData))) {
      const errors = validate(prop, formData[prop], formData);
      if (/^ryReq\d{1,2}$/.test(prop)) {
        // ロイヤリティ報告締め月はまとめる
        messages.ryReq = errors;
      } else {
        messages[prop] = errors;
      }
    }
    return messages;
  }, [formData])

  /** 設定ボタン押下時のハンドラ */
  const onSubmit = useCallback(() => {
    if (proposal?.proposalId == null) {
      return;
    }

    if (apiStatus === 'loading') {
      // API通信中は処理しない
      return;
    }

    // バリデート
    const errMessages = validateAll();
    const hasError = Object.values(errMessages).flat().length > 0;
    if (hasError || closingMonthExcluded) {
      setMessages(prev => ({
        ...prev,
        ...errMessages,
      }));
      setNeedFocusError(true);
      return;
    }

    // 契約期間変更処理を呼び出す
    dispatch(updateContractPeriod({
      proposalId: proposal.proposalId,
      params: {
        ...formData,
        updateDatetime: proposal?.updateDatetime,
      },
    }));
  }, [apiStatus, closingMonthExcluded, dispatch, formData, proposal?.proposalId, proposal?.updateDatetime, validateAll]);

  // 契約期間変更処理のハンドリング
  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(pushMessage('契約期間変更を設定しました。'));
      dispatch(clearApiStatus('updateContractPeriod'));
      onClose('submit');
      return;
    }
  }, [apiStatus, dispatch, onClose]);

  /** 指定月編集可能フラグ */
  const monthEditable = useMemo(() =>
    formData.ryReportCategory === Constants.Aniplex.RyReportCategory.Specified
  , [formData.ryReportCategory]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup
        onClose={() => onClose('close')}
        className="update-contract-popup"
      >
        <h4 className="popup-title">契約期間変更の設定</h4>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required">契約終了日</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="input-form wdt140">
                <DatePicker
                  dateFormat='yyyy/MM/dd'
                  locale='ja'
                  ref={formRefs.current.contractEndDate}
                  selected={contractEndDate}
                  onChange={date => handleDateChange('contractEndDate', date)} />
              </div>
              <ErrorMessageList messages={messages.contractEndDate} />
            </dd>
          </dl>
        </div>

        <div className="mb15" style={{ color: '#FF0000', fontWeight: 'bold', lineHeight: '1.5' }}>
          ライセンシーに覚書を送付済みか確認してください。<br />
          契約期間変更の設定を行いますか？
        </div>

        <div className="l-form">
          <dl style={{ marginLeft: '40px' }}>
            <dt className="form-name required">ロイヤリティ報告締め月</dt>
            <dd className="form-body" style={{ marginLeft: '16px', display: 'block', marginTop: '-20px' }}>
              <div style={{ marginTop: '-10px', marginBottom: '10px' }}>
                {
                  closingMonthExcluded && (
                    <ul className="error_list">
                      <li className="error_list-item">
                        契約期間外の月が設定されています
                      </li>
                    </ul>
                  )
                }
                <ErrorMessageList messages={messages.ryReq} />
              </div>
              <div>
                <div>
                  <input
                    type="radio"
                    name="ryReportCategory"
                    id="ryReportCategory-MON"
                    aria-label="毎月"
                    disabled
                    style={{ display: 'none' }}
                    value={Constants.Aniplex.RyReportCategory.Monthly}
                    checked={formData.ryReportCategory === Constants.Aniplex.RyReportCategory.Monthly}
                    onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
                  <label
                    htmlFor="ryReportCategory-MON"
                    className="form-radio"
                  >毎月</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="ryReportCategory"
                    id="ryReportCategory-SPE"
                    aria-label="指定月"
                    disabled
                    style={{ display: 'none' }}
                    value={Constants.Aniplex.RyReportCategory.Specified}
                    checked={formData.ryReportCategory === Constants.Aniplex.RyReportCategory.Specified}
                    onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
                  <label
                    htmlFor="ryReportCategory-SPE"
                    className="form-radio"
                  >指定月</label>
                </div>
                <div style={{ paddingLeft: '2em' }}>
                  <ul className="list-form ml0 taLeft">
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-01"
                        ref={formRefs.current.ryReq}
                        disabled={!monthEditable}
                        checked={formData.ryReq1}
                        onChange={ev => handleChange('ryReq1', ev.target.checked)} />
                      <label htmlFor="closing-month-01" className="form-checkbox blue">1月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-02"
                        disabled={!monthEditable}
                        checked={formData.ryReq2}
                        onChange={ev => handleChange('ryReq2', ev.target.checked)} />
                      <label htmlFor="closing-month-02" className="form-checkbox blue">2月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-03"
                        disabled={!monthEditable}
                        checked={formData.ryReq3}
                        onChange={ev => handleChange('ryReq3', ev.target.checked)} />
                      <label htmlFor="closing-month-03" className="form-checkbox blue">3月</label>
                    </li>
                  </ul>
                  <ul className="list-form ml0 taLeft">
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-04"
                        disabled={!monthEditable}
                        checked={formData.ryReq4}
                        onChange={ev => handleChange('ryReq4', ev.target.checked)} />
                      <label htmlFor="closing-month-04" className="form-checkbox blue">4月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-05"
                        disabled={!monthEditable}
                        checked={formData.ryReq5}
                        onChange={ev => handleChange('ryReq5', ev.target.checked)} />
                      <label htmlFor="closing-month-05" className="form-checkbox blue">5月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-06"
                        disabled={!monthEditable}
                        checked={formData.ryReq6}
                        onChange={ev => handleChange('ryReq6', ev.target.checked)} />
                      <label htmlFor="closing-month-06" className="form-checkbox blue">6月</label>
                    </li>
                  </ul>
                  <ul className="list-form ml0 taLeft">
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-07"
                        disabled={!monthEditable}
                        checked={formData.ryReq7}
                        onChange={ev => handleChange('ryReq7', ev.target.checked)} />
                      <label htmlFor="closing-month-07" className="form-checkbox blue">7月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-08"
                        disabled={!monthEditable}
                        checked={formData.ryReq8}
                        onChange={ev => handleChange('ryReq8', ev.target.checked)} />
                      <label htmlFor="closing-month-08" className="form-checkbox blue">8月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-09"
                        disabled={!monthEditable}
                        checked={formData.ryReq9}
                        onChange={ev => handleChange('ryReq9', ev.target.checked)} />
                      <label htmlFor="closing-month-09" className="form-checkbox blue">9月</label>
                    </li>
                  </ul>
                  <ul className="list-form ml0 taLeft">
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-10"
                        disabled={!monthEditable}
                        checked={formData.ryReq10}
                        onChange={ev => handleChange('ryReq10', ev.target.checked)} />
                      <label htmlFor="closing-month-10" className="form-checkbox blue">10月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-11"
                        disabled={!monthEditable}
                        checked={formData.ryReq11}
                        onChange={ev => handleChange('ryReq11', ev.target.checked)} />
                      <label htmlFor="closing-month-11" className="form-checkbox blue">11月</label>
                    </li>
                    <li className="ml0 wdt80">
                      <input type="checkbox"
                        id="closing-month-12"
                        disabled={!monthEditable}
                        checked={formData.ryReq12}
                        onChange={ev => handleChange('ryReq12', ev.target.checked)} />
                      <label htmlFor="closing-month-12" className="form-checkbox blue">12月</label>
                    </li>
                  </ul>
                </div>
                <div>
                  <input
                    type="radio"
                    name="ryReportCategory"
                    id="ryReportCategory-ANY"
                    aria-label="任意"
                    disabled
                    style={{ display: 'none' }}
                    value={Constants.Aniplex.RyReportCategory.Any}
                    checked={formData.ryReportCategory === Constants.Aniplex.RyReportCategory.Any}
                    onChange={ev => handleChange('ryReportCategory', ev.target.value)} />
                  <label
                    htmlFor="ryReportCategory-ANY"
                    className="form-radio"
                  >任意</label>
                </div>
              </div>
            </dd>
          </dl>
        </div>

        <div className="btn-wrapper">
          <div className="btn label c-aniplex mt15">
            <button
              disabled={formLocked}
              onClick={() => onClose('close')}
            >キャンセル</button>
          </div>
          <div className="btn label mt15 bg-aniplex">
            <button
              disabled={formLocked}
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  )
}

/**
 * バリデート処理
 * @template {keyof FormData} T
 * @param {T} prop 対象の項目名
 * @param {FormData[T]} value 対象の値
 * @param {FormData} entireData フォーム全体のデータ
 * @returns {string[]} エラーメッセージ
 */
function validate(prop, value, entireData) {
  switch(prop) {
    case 'contractEndDate':
      return validateContractEndDate(value);
    case 'ryReq1':
    case 'ryReq2':
    case 'ryReq3':
    case 'ryReq4':
    case 'ryReq5':
    case 'ryReq6':
    case 'ryReq7':
    case 'ryReq8':
    case 'ryReq9':
    case 'ryReq10':
    case 'ryReq11':
    case 'ryReq12':
      return validateRyReq(entireData);
    default:
      return[];
  }
}

/**
 * 契約終了日のバリデート
 * @param {?string} value 値
 * @returns {string[]} エラーメッセージ
 */
function validateContractEndDate(value) {
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  return [];
}

/**
 * ロイヤリティ報告締め月のバリデート
 * @param {object} formData フォーム全体のデータ
 */
function validateRyReq(formData) {
  if (formData.ryReportCategory !== Constants.Aniplex.RyReportCategory.Specified) {
    // 指定月以外のときはバリデートしない
    return [];
  }

  const props = [
    'ryReq1', 'ryReq2', 'ryReq3', 'ryReq4',
    'ryReq5', 'ryReq6', 'ryReq7', 'ryReq8',
    'ryReq9', 'ryReq10', 'ryReq11', 'ryReq12',
  ];

  const checked = props.some(prop => formData[prop]);

  if (!checked) {
    return ['ロイヤリティ報告締め月を設定してください']
  }

  return [];
}

/**
 * ロイヤリティ報告締め月が契約期間内に含まれているか判定する
 * @param {FormData & { contractStartDate: string|undefined }} formData
 * @returns {boolean} すべての報告締め月が契約期間内に含まれている場合はtrue.
 */
function checkAllMonthIncluded(formData) {
  if (isEmpty(formData.contractStartDate) || isEmpty(formData.contractEndDate)) {
    // 開始日と終了日のいずれかが空の場合は判定不可のためtrueを返す
    return true;
  }

  const start = dayjs(formData.contractStartDate, 'YYYY/MM/DD');
  const end = dayjs(formData.contractEndDate, 'YYYY/MM/DD');

  const startMonth = start.month();
  // 契約開始日の年を基準としたときに終了日が何月になっているか
  // (例)翌年の1月は13月の扱い
  const endMonth = end.date(start.date()).diff(start, 'month') + startMonth;

  let included = true;
  closingMonthProps.forEach((prop, month) => {
    let calMonth = month;
    if (month < startMonth) {
      // 開始月未満の月は1年後の値に補正する
      calMonth += 12;
    }

    // 契約期間外の月が締め月として有効になっている場合
    // ※開始月より前の場合は補正されるので終了だけ見ればよい
    if (formData[prop] && endMonth < calMonth) {
      included = false;
    }
  })

  return included;
}

//#region
/**
 * @typedef {import("../../../slices/aniplex/proposalsSlice").ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @typedef {import('../../../lib/api/aniplex').PatchProposalParams} PatchProposalParams
 */
/**
 * @typedef {Required<Pick<PatchProposalParams, 'contractEndDate'|'ryReportCategory'|'ryReq1'|'ryReq2'|'ryReq3'|'ryReq4'|'ryReq5'|'ryReq6'|'ryReq7'|'ryReq8'|'ryReq9'|'ryReq10'|'ryReq11'|'ryReq12'>>} FormData フォーム項目
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion
