//@ts-check
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

/** @type {ContextValue} */
const contextInitial = {
  clearAlertConfirmState: () => {},
  notAcceptedAlertConfirm: () => {},
  notAcceptedAlertConfirmed: false,
};

const Context = createContext(contextInitial);

/**
 * アラート状態を管理するコンテキスト
 * @param {object} params
 * @param {React.ReactNode} params.children
 */
export const AlertConfirmContext = ({ children }) => {
  // 未受領報告アラート確認状態
  const [notAcceptedAlertConfirmed, setNotAcceptedAlertConfirmed] = useState(false);

  /** 未受領報告アラート確認時コールバック */
  const notAcceptedAlertConfirm = useCallback(() => {
    setNotAcceptedAlertConfirmed(true);
  }, []);

  /** アラート確認状態をクリアする */
  const clearAlertConfirmState = useCallback(() => {
    setNotAcceptedAlertConfirmed(false);
  }, []);

  /** @type {ContextValue} */
  const contextValue = useMemo(() => ({
    clearAlertConfirmState,
    notAcceptedAlertConfirm,
    notAcceptedAlertConfirmed,
  }), [clearAlertConfirmState, notAcceptedAlertConfirm, notAcceptedAlertConfirmed]);

  return (
    // @ts-expect-error
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  )
}

/**
 * アラート確認状態をクリアする処理
 */
export const useClearAlertConfirmState = () => {
  const {
    clearAlertConfirmState,
  } = useContext(Context);

  return clearAlertConfirmState;
}

/**
 * 未受領報告アラート確認のカスタムフック
 */
export const useNotAcceptedAlertConfirm = () => {
  const {
    notAcceptedAlertConfirm,
    notAcceptedAlertConfirmed,
  } = useContext(Context);

  return {
    notAcceptedAlertConfirm,
    notAcceptedAlertConfirmed,
  }
}

//#region typedef
/**
 * @typedef {object} ContextValue コンテキストの供給する値
 * @property {() => void} clearAlertConfirmState アラート確認状態をクリアする
 * @property {() => void} notAcceptedAlertConfirm 未受領報告アラート確認時コールバック
 * @property {boolean} notAcceptedAlertConfirmed 未受領報告アラート確認済みフラグ
 */
//#endregion typedef
