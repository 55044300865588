import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Constants } from '../../../Constants';
import { getMessage } from '../../../lib/message';
import { calcProceeds, calcRyAmount, calcRyTarget, getRypInputFlg, planPriceCost, rypPriceCostType } from '../../../lib/royalty';
import { comma3 } from '../../../lib/util';
import { isEmpty, maxValue } from '../../../lib/validator';
import { CommaInput } from '../../common/CommaInput';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { ProductDetail } from '../../common/ProductDetail';
import { SpannableTableView } from '../../common/table/SpannableTableView';
import { useSelector } from 'react-redux';
import { selectCategoryMst } from '../../../slices/licensee/masterSlice';

/** このフォームで扱うプロパティ */
const targetProps = [
  'reportProduction',
  'reportSales',
  'reportPrice',
  'reportCost',
  'reportProceeds',
  'reportRyAmount',
];

/**
 * ロイヤリティ報告画面の商品リスト部分のフォーム
 * @param {object} props
 * @param {Proposal|undefined} props.proposal 企画情報
 * @param {number|null} props.targetPeriod 対象の第N期
 * @param {RyAmount[]} props.ryAmountList ロイヤリティ金額情報
 * @param {OnRyAmountChange} props.onRyAmountChange ロイヤリティ金額情報変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} props.validateAllFlg フォーム全体の強制バリデートフラグ
 * @param {boolean} props.forceValidateActionFlg 強制バリデートアクション実行フラグ
 * @param {() => void} props.onForceValidateAction 強制バリデートアクション実行後のコールバック
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject} props.formRefs フォーム項目に設定するref
 * @param {RyAmountDirtyInfo} props.ryAmountDirtyFlg ロイヤリティ金額情報の変更ダーティフラグ
 * @param {React.ReactNode} [props.tableTopElement] テーブル上部に表示するコンテンツ
 * @returns
 */
export const RoyaltyProductsForm = ({
  proposal,
  targetPeriod,
  ryAmountList,
  onRyAmountChange,
  onValidated,
  validateAllFlg = false,
  forceValidateActionFlg,
  onForceValidateAction,
  formLocked,
  formRefs,
  ryAmountDirtyFlg,
  tableTopElement,
}) => {
  // 商品ポップアップ
  const [productPopup, setProductPopup] = useState({
    showFlg: false,
    target: null,
    onClose: null,
  });
  // エラーメッセージ
  const [messages, setMessages] = useState({});

  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っているときは全項目をバリデートする
      setMessages(prev => {
        const newMessages = {};
        ryAmountList.forEach(r => {
          const product = proposal?.productList?.find(p => p.productId === r.productId);
          if (!product) {
            // 対応する商品がない場合はスキップ
            return;
          }

          const tmp = prev[r.productId] ?? {};
          targetProps.forEach((prop) => {
            const value = r[prop];
            const errors = validate(product.rypId, prop, value);
            onValidated(r.productId, prop, errors);
            tmp[prop] = errors.apply;
          });
          newMessages[r.productId] = tmp;
        });

        return {
          ...prev,
          ...newMessages,
        }
      });
    }
  }, [onValidated, proposal?.productList, ryAmountList, validateAllFlg]);

  // ダーティフラグが立っている項目はバリデートする
  useEffect(() => {
    setMessages(prev => {
      const newMessages = {};
      Object.entries(ryAmountDirtyFlg)
        .forEach(([strProductId, flags]) => {
          const productId = Number(strProductId);
          if (isNaN(productId)) {
            return;
          }
          const product = proposal?.productList?.find(p => p.productId === productId);
          const ryAmount = ryAmountList.find(r => r.productId === productId);
          if (!product || !ryAmount) {
            // 対応する商品または金額情報がない場合はスキップ
            return;
          }

          const tmp = prev[productId] ?? {};
          targetProps.forEach(prop => {
            if (!flags[prop]) {
              return;
            }
            const value = ryAmount[prop];
            const errors = validate(product.rypId, prop, value);
            onValidated(productId, prop, errors);
            tmp[prop] = errors.apply;
          });
          newMessages[productId] = tmp;
        });

      return {
        ...prev,
        ...newMessages,
      }
    })
  }, [onValidated, proposal?.productList, ryAmountDirtyFlg, ryAmountList]);

  /** テーブルのヘッダ定義 */
  const headers = useMemo(() => getTableHeaders(), []);

  /**
   * 値変更時のハンドラ
   * @type {OnRyAmountChange}
   */
  const handleChange = useCallback((productId, prop, value) => {
    const target = ryAmountList.find(r => r.productId === productId);
    const targetProduct = proposal?.productList.find(p => p.productId === productId);
    if (!target || !targetProduct) {
      return;
    }

    // バリデート
    const errors = validate(targetProduct.rypId, prop, value);
    onValidated(productId, prop, errors);
    setMessages(prev => {
      const org = prev[productId] ?? {};
      return {
        ...prev,
        [productId]: {
          ...org,
          [prop]: errors.apply,
        },
      };
    });

    onRyAmountChange(productId, prop, value);
  }, [onRyAmountChange, onValidated, proposal?.productList, ryAmountList]);

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((productId) => {
    const target = proposal?.productList.find(p => p.productId === productId);
    if (!target) {
      return;
    }

    setProductPopup({
      showFlg: true,
      target,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, [proposal?.productList])

  /** 商品フィルタ */
  const {
    filteredProductList,
    filterValue,
    onFilterChange,
    clearFilter,
    unreportedFlg,
    onUnreportedFlgChange,
  } = useProductFilter({
    period: targetPeriod,
    productList: proposal?.productList ?? [],
    ryAmountList,
  });

  // 強制バリデート時の処理
  useEffect(() => {
    if (!forceValidateActionFlg) {
      return;
    }
    const hasError = Object.values(messages).flatMap(Object.values).flat().length > 0;
    if (hasError) {
      // エラーがあるときはフィルタを解除する
      clearFilter();
    }
    onForceValidateAction();
  }, [clearFilter, forceValidateActionFlg, messages, onForceValidateAction]);

  /** テーブルのレコード定義 */
  const records = useMemo(() => getRecords({
      productList: filteredProductList,
      ryAmountList,
      handleChange,
      errors: messages,
      formLocked,
      onProductNameClick,
      formRefs,
      targetPeriod,
    }),
    [filteredProductList, ryAmountList, handleChange, messages, formLocked, onProductNameClick, formRefs, targetPeriod]
  );

  return (
    <>
      <div className="filter-form mt15">
        <dl className="form-set">
          <dt className="form-name">フィルター</dt>
          <dd className="form-body">
            <div className="input-form wdt250">
              <input
                type="text"
                name="フィルター"
                aria-label="フィルター"
                value={filterValue}
                onChange={ev => onFilterChange(ev.target.value)} />
            </div>
          </dd>
        </dl>
        {
          !formLocked && (
            <p>
              <input
                type="checkbox"
                id="onlyNotReported"
                name="証紙申請済だが、ロイヤリティ報告未提出の商品のみ表示"
                title="証紙申請済だが、ロイヤリティ報告未提出の商品のみ表示"
                checked={unreportedFlg}
                onChange={ev => onUnreportedFlgChange(ev.target.checked)} />
              <label
                htmlFor="onlyNotReported"
                className="form-checkbox"
              >証紙申請済だが、ロイヤリティ報告未提出の商品のみ表示</label>
            </p>
          )
        }
      </div>

      {tableTopElement}

      <SpannableTableView
        className='mt10 border0 has-total-row scroll'
        headers={headers}
        records={records}
        scrollable />

      {
        productPopup.showFlg && (
          <ProductDetail
            periodList={proposal?.periodList ?? []}
            product={productPopup.target}
            onClose={productPopup.onClose}
            isEditable={false} />
        )
      }
    </>
  )
}

/**
 * 商品フィルタのカスタムフック
 * @param {object} params
 * @param {number|null} params.period 対象報告期
 * @param {Product[]} params.productList 商品リスト
 * @param {RyAmount[]} params.ryAmountList ロイヤリティ金額情報のリスト
 */
const useProductFilter = ({
  period,
  productList,
}) => {
  const categoryMst = useSelector(selectCategoryMst);

  // フィルタ項目の値
  const [filterValue, setFilterValue] = useState('');
  // 未報告チェックの値
  const [unreportedFlg, setUnreportedFLg] = useState(false);

  /** フィルタの内容変更ハンドラ */
  const onFilterChange = useCallback(
    /** @param {string} newVal */
    newVal => {
      setFilterValue(newVal);
    },
  []);

  /** 未報告チェックの内容変更ハンドラ */
  const onUnreportedFlgChange = useCallback(
    /** @param {boolean} newVal */
    newVal => {
      setUnreportedFLg(newVal);
    }
  , []);

  /** フィルタの内容をクリアする */
  const clearFilter = useCallback(()=> {
    setFilterValue('');
    setUnreportedFLg(false);
  }, []);

  /** フィルタ後の商品リスト */
  const filteredProductList = useMemo(() => {
    return productList.filter(p => {
      const productPeriod = p.periodList.find(pr => pr.period === period);

      // 文字列フィルタ
      const category = categoryMst.find(c => c.categoryNo === p.categoryNo)
      const categoryDetail = category?.categoryDetailList.find(cd => cd.categoryDetailNo === p.categoryDetailNo);

      if (!isEmpty(filterValue)) {
        const match = [
          p.productName ?? '',
          p.productRemarks ?? '',
          p.productOption1 ?? '',
          p.productOption2 ?? '',
          p.productOption3 ?? '',
          category?.categoryName ?? '',
          categoryDetail?.categoryDetailName ?? '',
        ].reduce((prev, cur) => prev || cur.includes(filterValue), false);
        if (!match) {
          return false;
        }
      }

      // 未報告のみ表示時
      const resultLabel = productPeriod?.resultLabel ?? 0;
      if (unreportedFlg && resultLabel === 0) {
        // 証紙発行数が0は非表示
        return false
      }

      return true;
    });
  }, [categoryMst, filterValue, period, productList, unreportedFlg]);

  return {
    filterValue,
    onFilterChange,
    clearFilter,
    unreportedFlg,
    onUnreportedFlgChange,
    filteredProductList,
  }
}

/**
 * バリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {keyof RyAmount} prop バリデート対象のプロパティ名
 * @param {*} value 対象プロパティの値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(rypId, prop, value) {
  switch (prop) {
    // 生産数
    case 'reportProduction':
      return validateReportProduction(rypId, value);
    // 販売数
    case 'reportSales':
      return validateReportSales(rypId, value);
    // 確定上代
    case 'reportPrice':
      return validateReportPrice(rypId, value);
    // 確定製造原価
    case 'reportCost':
      return validateReportCost(rypId, value);
    // ロイヤリティ対象金額
    case 'reportProceeds':
      return validateReportProceeds(rypId, value);
    // ロイヤリティ金額
    case 'reportRyAmount':
      return validateReportRyAmount(rypId, value);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: []};
  }
}

/**
 * 生産数のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportProduction(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportProduction) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 1000000000)) {
    const error = getMessage('maxValue', { max: 1000000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * 販売数のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportSales(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportSales) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 1000000000)) {
    const error = getMessage('maxValue', { max: 1000000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * 確定上代のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportPrice(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportPrice) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 100000000)) {
    const error = getMessage('maxValue', { max: 100000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * 確定製造原価のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportCost(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportCost) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 100000000)) {
    const error = getMessage('maxValue', { max: 100000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * 確定売上金額のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportProceeds(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportRyTarget) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 100000000000)) {
    const error = getMessage('maxValue', { max: 100000000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * ロイヤリティ金額のバリデート処理
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateReportRyAmount(rypId, value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  const inputFlg = getRypInputFlg(rypId, 'licensee');
  if (!inputFlg.reportRyAmount) {
    // 入力対象外の報告パターンの場合はエラーなし
    return errors;
  }

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxValue(value, 100000000000)) {
    const error = getMessage('maxValue', { max: 100000000000 });
    errors.tmp.push(error);
    errors.apply.push(error);
  }

  return errors;
}

/**
 * テーブルのヘッダ定義を取得する
 * @returns {TableHeader[]} テーブルヘッダ定義
 */
function getTableHeaders() {
  /** @type {TableHeader[]} */
  const headers = [
    { id: 'productName', label: '商品名等', style: { minWidth: '187px' } },
    { id: 'character', label: 'キャラクター', style: { minWidth: '100px' } },
    { id: 'resultLabel', label: '当期\n証紙数', style: { minWidth: '100px' } },
    { id: 'unreportedLabel', label: '当期\n未報告数', style: { minWidth: '100px' } },
    { id: 'production', label: '生産数', style: { minWidth: '120px' } },
    { id: 'sales', label: '販売数', style: { minWidth: '120px' } },
    {
      id: 'priceCost', label: '上代（税抜き）・製造原価\nもしくは納品価格', className: 'bg-gray', children: [
        { id: 'planPriceCost', label: '予定', style: { minWidth: '94px' }, className: 'border-left' },
        { id: 'resultPriceCost', label: '確定', style: { minWidth: '120px' } },
      ]
    },
    {
      id: 'royalty', label: 'ロイヤリティ', className: 'bg-gray', children: [
        { id: 'ryTarget', label: 'ロイヤリティ\n対象金額', style: { minWidth: '119px' } },
        { id: 'ryRate', label: 'ロイヤリティ\n料率', style: { minWidth: '104px' } },
        { id: 'ryPrice', label: 'ロイヤリティ\n単価', style: { minWidth: '102px' } },
        { id: 'ryAmount', label: 'ロイヤリティ額', style: { minWidth: '110px' } },
      ]
    },
    { id: 'salesPrice', label: '販売金額', style: { minWidth: '122px' } },
  ];

  return headers;
}

/**
 * テーブルのレコード定義を取得する
 * @param {object} params
 * @param {Product[]} params.productList 商品リスト
 * @param {RyAmount[]} params.ryAmountList ロイヤリティ金額情報
 * @param {OnRyAmountChange} params.handleChange 値変更時のハンドラ
 * @param {Record<string, Record<keyof RyAmount, string[]>} params.errors エラーメッセージ
 * @param {boolean} params.formLocked 入力抑制フラグ
 * @param {(productId: number) => void} params.onProductNameClick 商品名リンククリック時のコールバック
 * @param {React.MutableRefObject} params.formRefs フォーム項目に設定するref
 * @param {number|null} params.targetPeriod 対象の期
 * @returns {TableRecord[]} レコード定義
 */
function getRecords({
  productList,
  ryAmountList,
  handleChange,
  errors,
  formLocked,
  onProductNameClick,
  formRefs,
  targetPeriod,
}) {
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  let ryAmountTotal = BN(0);

  const result = productList
  .filter(p => p.productStatus !== Constants.Licensee.productStatus.Registered)
  // ロイヤリティ金額情報のデータが存在しない商品は対象外
  .filter(p => !!ryAmountList?.find(r => r.productId === p.productId))
  .map(p => {
    /** 対象の期の情報 */
    const productPeriod = p.periodList.find(p => p.period === targetPeriod);
    /** 対応するロイヤリティ金額情報 */
    const ryAmount = ryAmountList.find(r => r.productId === p.productId);
    /** 対応するエラーメッセージ */
    const messages = errors[p.productId];
    /** refを短縮 */
    const refs = formRefs.current[p.productId];

    /** @type {TableRecord} */
    const record = {
      productName: {
        el: (
          <button className="link"
            onClick={() => onProductNameClick(p.productId)}
          >{p.productName}</button>
        ),
      },
        // キャラクター
        character: {
          el: p.character ?? '',
        },
    };

    // 当期証紙数
    const resultLabel = productPeriod?.resultLabel ?? ''
    record.resultLabel = {
      className: 'cost',
      el: comma3(resultLabel),
    };

    // 当期未報告数
    const unreportedLabel = Math.max((resultLabel || 0) - (ryAmount?.reportProduction ?? 0), 0)
    record.unreportedLabel = {
      className: 'cost',
      el: comma3(unreportedLabel),
    };

    // 入力可否フラグ
    const inputFlg = getRypInputFlg(p.rypId, 'licensee');

    // 生産数
    const productionEl = (() => {
      if (!inputFlg.reportProduction) return '';
      return (
        <>
          <div className="input-form cost mlauto wdt100">
            <CommaInput type="text"
              inputRef={refs?.current.reportProduction}
              value={ryAmount?.reportProduction ?? ''}
              disabled={formLocked}
              decimals={0}
              onChange={val => handleChange(p.productId, 'reportProduction', val)} />
          </div>
          <ErrorMessageList messages={messages?.reportProduction ?? []} />
        </>
      );
    })();
    record.production = {
      className: 'cost',
      el: productionEl,
    };

    // 販売数
    const salesEl = (() => {
      if (!inputFlg.reportSales) return '';
      return (
        <>
          <div className="input-form cost mlauto wdt100">
            <CommaInput type="text"
              inputRef={refs?.current.reportSales}
              value={ryAmount?.reportSales ?? ''}
              disabled={formLocked}
              decimals={0}
              onChange={val => handleChange(p.productId, 'reportSales', val)} />
          </div>
          <ErrorMessageList messages={messages?.reportSales ?? []} />
        </>
      );
    })();
    record.sales = {
      className: 'cost',
      el: salesEl,
    };

    // 上代・製造原価(予定)
    record.planPriceCost = {
      className: 'cost',
      el: comma3(planPriceCost(p) ?? ''),
    };

    // 上代・製造原価(確定)
    const resultPriceCostProp = (() => {
      switch (rypPriceCostType(p.rypId)) {
        case 'price':
          return 'reportPrice';
        case 'cost':
          return 'reportCost';
        default:
          return null;
      }
    })();
    const resultPriceCostEl = (() => {
      if (!inputFlg.reportPrice && !inputFlg.reportCost) return '';
      return (
        <>
          <div className="input-form cost mlauto wdt100">
            <CommaInput
              inputRef={refs?.current[resultPriceCostProp]}
              value={ryAmount?.[resultPriceCostProp] ?? ''}
              disabled={formLocked}
              decimals={2}
              onChange={val => handleChange(p.productId, resultPriceCostProp, val)} />
          </div>
          <ErrorMessageList messages={messages?.[resultPriceCostProp] ?? []} />
        </>
      );
    })();
    record.resultPriceCost = {
      className: 'cost',
      el: resultPriceCostEl,
    }

    // ロイヤリティ対象金額
    const ryTargetEl = (() => {
      const ryTarget = calcRyTarget({
        rypId: p.rypId,
        production: ryAmount?.reportProduction || 0,
        sales: ryAmount?.reportSales || 0,
        price: ryAmount?.reportPrice || 0,
        cost: ryAmount?.reportCost || 0,
        ryTarget: ryAmount?.reportProceeds || 0
      });
      if (!inputFlg.reportRyTarget) {
        if (ryTarget == null) return '';
        return comma3(BN(ryTarget).dp(0).toNumber());
      }

      return (
        <>
          <div className="input-form cost mlauto wdt100">
            <CommaInput
              inputRef={refs?.current.reportProceeds}
              value={ryAmount?.reportProceeds ?? ''}
              disabled={formLocked}
              decimals={0}
              onChange={val => handleChange(p.productId, 'reportProceeds', val)} />
          </div>
          <ErrorMessageList messages={messages?.reportProceeds ?? []} />
        </>
      );
    })();
    record.ryTarget = {
      className: 'cost',
      el: ryTargetEl,
    }

    // ロイヤリティ料率
    record.ryRate = {
      className: 'cost',
      el: comma3(p.ryRate ?? ''),
    };

    // ロイヤリティ単価
    record.ryPrice = {
      className: 'cost',
      el: comma3(p.ryPrice ?? ''),
    };

    // ロイヤリティ額
    const ryAmountPrice = (() => {
      return calcRyAmount({
        rypId: p.rypId,
        price: ryAmount?.reportPrice || 0,
        cost: ryAmount?.reportCost || 0,
        ryTarget: ryAmount?.reportProceeds || 0,
        ryRate: p.ryRate || 0,
        ryPrice: p.ryPrice || 0,
        production: ryAmount?.reportProduction || 0,
        sales: ryAmount?.reportSales || 0,
        ryAmount: ryAmount?.reportRyAmount || 0,
      });
    })();
    const ryAmountEl = (() => {
      if (!inputFlg.reportRyAmount) {
        if (ryAmountPrice == null) return '';
        return comma3(BN(ryAmountPrice).dp(0).toNumber());
      }

      return (
        <>
          <div className="input-form cost mlauto wdt100">
            <CommaInput
              inputRef={refs?.current.reportRyAmount}
              value={ryAmount?.reportRyAmount ?? ''}
              disabled={formLocked}
              decimals={0}
              onChange={val => handleChange(p.productId, 'reportRyAmount', val)} />
          </div>
          <ErrorMessageList messages={messages?.reportRyAmount ?? []} />
        </>
      );
    })();
    record.ryAmount = {
      className: 'cost',
      el: ryAmountEl,
    };
    if (!inputFlg.reportRyAmount) {
      ryAmountTotal = ryAmountTotal.plus(BN(ryAmountPrice ?? 0).dp(0).toNumber());
    } else {
      ryAmountTotal = ryAmountTotal.plus(BN(ryAmount?.reportRyAmount || 0));
    }

    // 販売金額
    const proceeds = (() => {
      return comma3(calcProceeds({
        rypId: p.rypId,
        price: ryAmount?.reportPrice || 0,
        sales: ryAmount?.reportSales || 0,
        ryTarget: ryAmount?.reportProceeds || 0,
      }) ?? '')
    })();
    record.salesPrice = {
      className: 'cost',
      el: proceeds,
    };

    return record;
  }) ?? [];

  // 合計行を追加
  result.push({
    _tr: { className: 'total' },
    productName: { el: '', colSpan: 9 },
    ryRate: {
      className: 'total-head',
      colSpan: 2,
      el: '合計'
    },
    ryAmount: {
      className: 'cost fwb bb-solid-tblcolor',
      el: comma3(ryAmountTotal.dp(0).toNumber()),
    },
    salesPrice: { el: '', colSpan: 2 },
  });

  return result;
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').ProposalDetail} Proposal 企画情報
 */
/**
 * @typedef {import('./RoyaltyReportDetailForm').Product} Product 商品情報
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader テーブルのヘッダ定義
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブルのデータ行
 */
/**
 * @typedef {import('./RoyaltyReportDetailForm').RyAmount} RyAmount ロイヤリティ金額情報
 */
/**
 * @callback OnRyAmountChange ロイヤリティ金額情報変更時のハンドラ
 * @param {number} productId 変更対象の商品内部コード
 * @param {keyof RyAmount} prop 変更対象のプロパティ名
 * @param {*} value 変更後の値
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {number} productId 商品内部コード
 * @param {string} prop バリデート対象のプロパティ
 * @param {{ tmp: string[], apply: string[] }} errors エラーメッセージのリスト
 */
/**
 * @typedef {import('./hooks').RyAmountDirtyInfo} RyAmountDirtyInfo
 */
//#endregion typedef
