import { Auth } from "aws-amplify";
import { Config } from "../../config";
import { pushMessage, setReturnPath } from "../../slices/aniplex/utilSlice";
import { ApiErrorMessage } from "../message";
import { axiosSend } from "./common";
import { ApiError } from "./errors/ApiError";
import { HandledApiError } from "./errors/HandledApiError";

/**
 * トークンを付与してAPIリクエストを投げる
 * @param {string} url 送信先APIのURL
 * @param {import('./common').RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {*} option axiosのオプション
 * @returns {Promise<import('./common').SendResultData>} APIのレスポンスボディ
 */
async function sendWithToken(url, method, data, option = {}) {
  let token = '';
  if (!Config.NoCognitoRedirect) {
    try {
      token = (await Auth.currentSession())?.getIdToken()?.getJwtToken() || '';
    } catch {
      // セッションが取れなかったらCognitoへリダイレクト
      // 現在のパスを復帰先のパスとして保存
      const store = require('../../store').store;
      store.dispatch(setReturnPath(window.location.hash.replace(/^#/, '')));
      Auth.federatedSignIn();
    }
  }

  option = {
    ...option,
    headers: {
      'Authorization': token,
    },
  };

  try {
    const { data: result } = await axiosSend(url, method, data, option);
    return result;
  } catch (err) {
    handleError(err);
    // エラーハンドリングを抜けたときにundefined参照エラーを出来るだけ抑止するため
    // 最低限のデータ構造を返却
    return {
      result: {
        data: {},
      },
    }
  }
}

/**
 * API呼出しで発生したエラーのハンドリング
 * @param {*} err throwされたエラー
 */
function handleError(err) {
  if (err instanceof ApiError) {
    if (err.statusCode === 401) {
      // TODO: セッションエラーは発生し得るのか
    }

    // 共通系エラー処理
    const store = require('../../store').store;
    switch (err.errorCode) {
      case '10000': // 認証エラー(ROYSに登録されていないユーザー)
        // エラーページに遷移する
        window.location.href = Config.AniplexNoPermissionUrl;
        break;
      case '90000': // バリデートエラー
        store.dispatch(pushMessage(ApiErrorMessage.ValidateError));
        break;
      case '99999': // システムエラー
        store.dispatch(pushMessage(ApiErrorMessage.SystemError));
        break;
      default:
        throw err;
    }

    throw new HandledApiError(err);
  }

  throw err;
}

/**
 * 企画一覧を取得する
 */
export async function getProposalsAll(params = {}) {
  const url = Config.AniplexApiUrl + '/proposals';
  const result = await sendWithToken(url, 'GET', {...(params || {})});
  return result;
}

/**
 * 企画詳細APIを呼び出す
 * @param {number} proposalId 企画内部コード
 * @returns {GetProposalResult} APIレスポンスボディのresult.data
 */
export async function getProposal(proposalId) {
  const url = Config.AniplexApiUrl + `/proposals/${proposalId}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * 企画更新APIを呼び出す
 * @param {number} proposalId 企画内部コード
 * @param {PatchProposalParams} params リクエストパラメータ
 * @returns {PatchProposalResult} APIレスポンスボディのresult.data
 */
export async function patchProposal(proposalId, params) {
  const url = Config.AniplexApiUrl + `/proposals/${proposalId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * 画像取得API
 * @param {integer} renderingImageNo 商品イメージNo
 * @returns {GetImageResult} APIレスポンス
 */
export async function getImage({renderingImageNo}) {
  const url = Config.AniplexApiUrl + '/images';
  const result = await sendWithToken(url, 'GET', { renderingImageNo });
  return result;
}

/**
 * 画像取得API(再試行あり)
 */
export async function getImageRetry({ renderingImageNo }) {
  let count = 0;
  while (1) {
    count++;
    try {
      return await getImage({ renderingImageNo });
    } catch (e) {
      // 画像のコピー待ち以外はエラーとする
      if (e.errorCode !== '10702') {
        throw e;
      }
      // 画像のコピー待ち時、一定回数の試行を超えたらエラーとする
      if (count >= Config.GetImage.RetryCount) {
        throw e;
      }
    }
    // 一定時間待機後に再試行する
    await new Promise(resolve => window.setTimeout(resolve, Config.GetImage.RetryWaitMs));
  }
}


/**
 * 証紙申請詳細APIを呼び出す
 * @param {*} label 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
 export async function getLabels(labelApplicationId) {
  const url = Config.AniplexApiUrl + `/labels/${labelApplicationId}`;
  const result = await sendWithToken(url, 'GET');
  return result;
}

/**
 * 証紙申請更新APIを呼び出す
 * @param {number} labelApplicationId 証紙申請ID
 * @param {PatchLabelParams} params 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
 export async function patchLabels(labelApplicationId, params) {
  const url = Config.AniplexApiUrl + `/labels/${labelApplicationId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result;
}

/**
 * ロイヤリティ報告詳細APIを呼び出す
 * @param {number} ryReportId ロイヤリティ報告書内部コード
 * @returns {GetRoyaltyResult} APIレスポンスボディのresult.data
 */
export async function getRoyalty(ryReportId) {
  const url = Config.AniplexApiUrl + `/royalties/${ryReportId}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ロイヤリティ報告更新APIを呼び出す
 * @param {number} ryReportId ロイヤリティ報告書内部コード
 * @param {PatchRoyaltyParams} params リクエストパラメータ
 * @returns {PatchProposalResult} APIレスポンスボディのresult.data
 */
export async function patchRoyalty(ryReportId, params) {
  const url = Config.AniplexApiUrl + `/royalties/${ryReportId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * 売上連携APIを呼び出す
 * @param {ExportRoyaltyParams} params リクエストパラメータ
 * @returns {ExportRoyaltyResult} APIレスポンスボディのresult.data
 */
export async function exportRoyalty(params) {
  const url = Config.AniplexApiUrl + `/export/royalties`;
  const result = await sendWithToken(url, 'POST', params);
  return result.result.data;
}

/**
 * ファイルアップロードAPIを呼び出す
 * @param {string} filename
 * @returns
 */
export async function putFilesUpload(filename) {
  const url = Config.AniplexApiUrl + '/files/upload';
  const result = await sendWithToken(url, 'PUT', filename)
  return result;

}

/**
 * ファイルダウンロードAPIを呼び出す
 * @param {PutFilesDownloadParam} param リクエストパラメータ
 * @returns {PutFilesDownloadResult} APIレスポンスボディのresult.data
 */
export async function putFilesDownload(param) {
  const url = Config.AniplexApiUrl + '/files/download';
  const result = await sendWithToken(url, 'PUT', param);
  return result.result.data;
}

/**
 * 再試行つきでファイルダウンロードAPIを呼び出す
 * @param {PutFilesDownloadParam} param リクエストパラメータ
 * @returns {PutFilesDownloadResult} APIレスポンスボディのresult.data
 */
export async function downloadFileRetry(param) {
  let count = 0;
  while (1) {
    count++;
    try {
      return await putFilesDownload(param);
    } catch (e) {
      // ファイルのコピー待ち以外は即時エラーにする
      if (e.errorCode !== '11602') {
        throw e;
      }

      // コピー待ちによるリトライが一定回数を超えたらエラーにする
      if (count >= Config.DownloadFile.RetryCount) {
        throw e;
      }
    }
    // 一定時間待機後に再試行する
    await new Promise(res => window.setTimeout(res, Config.DownloadFile.RetryWaitMs));
  }
}

/**
 * 作品一覧APIを呼び出す
 * @param {GetPropertiesParam} param クエリパラメータ
 * @returns {GetPropertiesResult} APIレスポンスボディのresult.data
 */
export async function getProperties(param = {}) {
  const url = Config.AniplexApiUrl + '/properties';
  const result = await sendWithToken(url, 'GET', param);
  return result.result.data;
}

/**
 * 作品詳細APIを呼び出す
 * @param {string} propertySummaryCode 作品コード
 * @returns {GetPropertyResult} APIレスポンスボディのresult.data
 */
export async function getProperty(propertySummaryCode) {
  const url = Config.AniplexApiUrl + `/properties/${propertySummaryCode}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * 作品更新APIを呼び出す
 * @param {string} propertySummaryCode
 * @param {*} params
 * @returns
 */
export async function patchProperty(propertySummaryCode, params) {
  const url = Config.AniplexApiUrl + `/properties/${propertySummaryCode}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * ライセンシーユーザー一覧APIを呼び出す
 * @param {GetLicenseeUsersParam} params リクエストパラメータ
 * @returns {GetLicenseeUsersResult} APIレスポンスボディのresult.data
 */
export async function getLicenseeUsers(params) {
  const url = Config.AniplexApiUrl + '/licenseeusers';
  const result = await sendWithToken(url, 'GET', params);
  return result.result.data;
}

/**
 * ライセンシーユーザー登録APIを呼び出す
 * @param {PostLicenseeUserParam} params リクエストパラメータ
 * @returns {PostLicenseeUserResult} APIレスポンスボディのresult.data
 */
export async function postLicenseeuser(params) {
  const url = Config.AniplexApiUrl + `/licenseeusers`;
  const result = await sendWithToken(url, 'POST', params);
  return result.result.data;
}

/**
 * ライセンシーユーザー更新APIを呼び出す
 * @param {string} userId ユーザーID
 * @param {PatchLicenseeUserParam} params リクエストパラメータ
 * @returns {PatchLicenseeUserResult} APIレスポンスボディのresult.data
 */
export async function patchLicenseeUser(userId, params) {
  const url = Config.AniplexApiUrl + `/licenseeusers/${userId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/** * ANIPLEXユーザー更新APIを呼び出す
 * @param {string} employeeNo 社員番号
 * @param {PatchLicenseeParam} param 更新パラメータ
 * @returns {PatchLicenseeResult}
 */
 export async function patchLicensee(licenseeCode, param) {
  const url = Config.AniplexApiUrl + `/licensees/${licenseeCode}`;
  const result = await sendWithToken(url, 'PATCH', param);
  return result.result.data;
}

/**
 * ログインユーザー詳細APIを呼び出す
 * @returns {getUsersMyselfResult}
 */
export async function getUsersMyself() {
  const url = Config.AniplexApiUrl + '/users/myself';
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ANIPLEXユーザー一覧APIを呼び出す
 * @param {GetAniplexuserParam} params クエリパラメータ
 * @returns {GetAniplexuserResult} APIレスポンスボディのresult.data
 */
export async function getAniplexusers(params = {}) {
  const url = Config.AniplexApiUrl + '/aniplexusers';
  const result = await sendWithToken(url, 'GET', params);
  return result.result.data;
}

/**
 * ANIPLEXユーザー登録APIを呼び出す
 * @param {PostAniplexUserParam} param
 * @returns {PostAniplexUserResult} APIレスポンスのresult.data
 */
export async function postAniplexuser(params) {
  const url = Config.AniplexApiUrl + '/aniplexusers';
  const result = await sendWithToken(url, 'POST', params);
  return result.result.data;
}

/**
 * ANIPLEXユーザー詳細APIを呼び出す
 * @param {string} employeeNo 社員番号
 * @returns {GetAniplexUserResult}
 */
 export async function getAniplexuser(employeeNo) {
  const url = Config.AniplexApiUrl + `/aniplexusers/${employeeNo}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ANIPLEXユーザー更新APIを呼び出す
 * @param {string} employeeNo 社員番号
 * @param {PatchAniplexuserParam} param 更新パラメータ
 * @returns {PatchAniplexuserResult}
 */
 export async function patchAniplexuser(employeeNo, param) {
  const url = Config.AniplexApiUrl + `/aniplexusers/${employeeNo}`;
  const result = await sendWithToken(url, 'PATCH', param);
  return result.result.data;
}

/**
 * カテゴリ一覧APIを呼び出す
 * @returns {getCategoriesResult}
 */
export async function getCategories() {
  const url = Config.AniplexApiUrl + '/categories';
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ロイヤリティ報告パターン一覧APIを呼び出す
 * @returns {getRoyaltypatternsResult}
*/
export async function getRoyaltypatterns() {
  const url = Config.AniplexApiUrl + '/royaltypatterns';
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * S決裁一覧APIを呼び出す
 * @param {GetSdecisionsParam} params クエリパラメータ
 * @returns {Promise<GetSdecisionsResult>} APIレスポンスボディのresult.data
 */
export async function getSdecisions(params) {
  const url = Config.AniplexApiUrl + '/sdecisions';
  const result = await sendWithToken(url, 'GET', params);
  return result.result.data;
}

/**
 * S決済詳細APIを呼び出す
 * @param {string} sDecisionNo S決済No
 * @returns {Promise<SDecisionDetail>}
 */
export async function getSDecisionDetail(sDecisionNo) {
  const url = Config.AniplexApiUrl + `/sdecisions/${sDecisionNo}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * 証紙申請一覧APIを呼び出す
 * @param {*} param 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
export async function getLabelsAll(param) {
  const url = Config.AniplexApiUrl + '/labels';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

/**
 * ロイヤリティ報告一覧APIを呼び出す
 * @param {GetRoyaltiesParam} param 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
export async function getRoyaltiesAll(param) {
  const url = Config.AniplexApiUrl + '/royalties';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

/**
 * 取引先一覧APIを呼び出す
 * @param {object} param 送信パラメータ
 * @returns {GetLicenseesAllResult} APIのレスポンスデータ
 */
export async function getLicenseesAll(param) {
  const url = Config.AniplexApiUrl + '/licensees';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

// TODO: 型定義
/**
 * 取引先詳細APIを呼び出す
 * @param {string} licenseeCode 取引先コード
 * @returns {*} APIのレスポンスデータ
 */
export async function getLicensee(licenseeCode) {
  const url = Config.AniplexApiUrl + '/licensees/' + licenseeCode;
  const result = await sendWithToken(url, 'GET');
  return result;
}

/**
 * ライセンシーユーザー一覧APIを呼び出す
 * @param {object} param 送信パラメータ
 * @returns {GetLicenseeUsersAllResult} APIのレスポンスデータ
 */
export async function getLicenseeUsersAll(param) {
  const url = Config.AniplexApiUrl + '/licenseeusers';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

/**
 * お知らせ一覧APIを呼び出す
 * @param {GetAnnouncementAllParam} param 送信パラメータ
 * @returns {GetAnnouncementAllResult} APIのレスポンスデータ
 */
 export async function getAnnouncementAll(param) {
  const url = Config.AniplexApiUrl + '/announcement';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

/**
 * お知らせ詳細APIを呼び出す
 * @param {number} announcementId お知らせ内部コード
 * @returns {GetAnnouncementResult} APIのレスポンスデータ
 */
 export async function getAnnouncement(announcementId) {
  const url = Config.AniplexApiUrl + `/announcement/${announcementId}`;
  const result = await sendWithToken(url, 'GET');
  return result;
}

/**
 * お知らせ登録APIを呼び出す
 * @param {PostAnnouncementParam} param 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
 export async function postAnnouncement(param) {
  const url = Config.AniplexApiUrl + '/announcement';
  const result = await sendWithToken(url, 'POST', param);
  return result;
}

/**
 * お知らせ更新APIを呼び出す
 * @param {number} announcementId お知らせ内部コード
 * @param {PatchAnnouncementParam} param 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
 export async function patchAnnouncement(announcementId, param) {
  const url = Config.AniplexApiUrl + `/announcement/${announcementId}`;
  const result = await sendWithToken(url, 'PATCH', param);
  return result;
}

/**
 * 0円報告一括受領APIを呼び出す
 * @param {PatchReceiptRoyaltiesParam} param リクエストパラメータ
 * @returns {PatchReceiptRoyaltiesResult} 処理結果
 */
export async function patchReceiptRoyalties(param) {
  const url = Config.AniplexApiUrl + '/receipt/royalties';
  const result = await sendWithToken(url, 'PATCH', param);
  return result.result.data;
}

//#region typedef
/**
 * @typedef {import('../../slices/aniplex/proposalsSlice').ProposalDetail} GetProposalResult 企画詳細APIの結果
 */
/**
 * @typedef {object} PatchProposalParams 企画更新APIのパラメータ
 * @property {'REJ'|'APP'|'SUS'|'FIN'|'DEL'} [proposalStatus] 申請ステータス
 * @property {string} [messageContent] メッセージ内容
 * @property {string} [kikakuEmployeeNo] 企画担当者社員番号
 * @property {string} [sDecisionNo] S決裁No
 * @property {string} [importLicenseUrl] 輸入許諾書URL
 * @property {boolean} [ryRemindReq] ロイヤリティ報告督促要否
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} [contractEndDate] 契約終了日(YYYY/MM/DD)
 * @property {boolean} [ryReq1] ロイヤリティ報告締め月_1月
 * @property {boolean} [ryReq2] ロイヤリティ報告締め月_2月
 * @property {boolean} [ryReq3] ロイヤリティ報告締め月_3月
 * @property {boolean} [ryReq4] ロイヤリティ報告締め月_4月
 * @property {boolean} [ryReq5] ロイヤリティ報告締め月_5月
 * @property {boolean} [ryReq6] ロイヤリティ報告締め月_6月
 * @property {boolean} [ryReq7] ロイヤリティ報告締め月_7月
 * @property {boolean} [ryReq8] ロイヤリティ報告締め月_8月
 * @property {boolean} [ryReq9] ロイヤリティ報告締め月_9月
 * @property {boolean} [ryReq10] ロイヤリティ報告締め月_10月
 * @property {boolean} [ryReq11] ロイヤリティ報告締め月_11月
 * @property {boolean} [ryReq12] ロイヤリティ報告締め月_12月
 * @property {boolean} [automaticContractUpdateFlag] 自動契約更新制御フラグ
 * @property {valueOf<Constants['Aniplex']['RyReportCategory']>} [ryReportCategory] ロイヤリティ報告区分 MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 */
/**
 * @typedef {object} PatchProposalResult 企画更新APIの結果
 * @property {number} proposalId 企画内部コード
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} GetImageResult 画像取得APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {object} result.data
 * @property {string} result.data.imageBinary 商品イメージファイルのイメージデータ(base64)
 */
/**
 * @typedef {object} PatchLabelParams 証紙申請更新APIのパラメータ
 * @property {'REJ'|'APP'|'DEL'} [labelStatus] 証紙申請ステータス（ANIPLEX向け更新パラメータ）
 * REJ: 差し戻し中
 * APP: 承認済み
 * DEL: 削除
 * @property {string} [messageContent] メッセージ内容
 * @property {string} [dispatchDate] 発送日(YYYY/MM/DD)
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} GetRoyaltiesParam ロイヤリティ報告一覧APIのリクエストパラメータ
 * @property {string} [proposalNo] 企画No※部分一致検索
 * @property {string} [proposalTitle] 企画件名※部分一致検索
 * @property {Exclude<valueOf<Constants['Aniplex']['reportStatus']>, 'MOD'>} [reportStatus] ロイヤリティ報告ステータス
 * TMP: 修正中
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 0円報告済み
 * EXP: 売上送信済み
 * @property {string} [reportUserName] 報告者氏名 ※部分一致検索
 * @property {string} [reportDateFrom] 報告日From(YYYY/MM/DD)
 * @property {string} [reportDateTo] 報告日To(YYYY/MM/DD)
 * @property {string} [propertySummaryCode] 作品コード
 * @property {string} [licenseeCode] 取引先コード※部分一致検索
 * @property {string} [licenseeNameKanji] 取引先名（漢字）※部分一致検索
 * @property {'ALL'|'PSO'|'PPO'} filterCondition 絞り込み条件
 * ALL: すべて
 * PSO: 自分の担当作品のみ表示
 * PPO: 自分の担当企画のみ表示
 */
/**
 * @typedef {import('../../slices/aniplex/royaltiesSlice').RoyaltyDetail} GetRoyaltyResult ロイヤリティ報告詳細APIの結果
 */
/**
 * @typedef {object} PatchRoyaltyParams ロイヤリティ報告更新APIのパラメータ
 * @property {'REJ'} reportStatus 報告ステータス
 * @property {string} messageContent メッセージ内容
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchRoyaltyResult ロイヤリティ報告更新APIの結果
 * @property {number} ryReportId ロイヤルティ報告書内部コード
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ExportRoyaltyParams 売上連携APIのパラメータ
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {'0'|'1'} [process] 処理内容
 * @property {string} [billingDate] 請求予定日(YYYY/MM/DD)
 * @property {string} [salesDate] 売上日(YYYY/MM/DD)
 * @property {string} [depositDate] 入金予定日(YYYY/MM/DD)
 * @property {'21'|'11'|'01'|'31'|'0'} [taxType] 税区分
 * @property {'0'|'1'|'2'} [taxInOut] 消費税内外区分
 * @property {number} [consumptionTax] 消費税
 * @property {'0'|'1'} [bill] 請求書発行有無
 * @property {string} [billRemarks] 請求項目
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ExportRoyaltyResult 売上連携APIの結果
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 */
/**
 * @typedef {object} PutFilesDownloadParam ファイルダウンロードAPIのパラメータ
 * @property {'application'|'ryProof'|'proposalAttachment'} filetype ファイル種別(application: 企画申請書,ryProof: ロイヤリティ報告確証)
 * @property {string} fileNo ファイルNo(企画申請書: 企画No,ロイヤリティ報告確証: ロイヤリティ報告確証No)
 */
/**
 * @typedef {object} PutFilesDownloadResult ファイルダウンロードAPIの結果
 * @property {string} downloadPresignedUrl ダウンロード用署名付きURL
 */
/**
 * @typedef {object} GetPropertiesParam 作品一覧APIのパラメータ
 * @property {string} [PropertySummaryCode] 作品コード
 * @property {string} [PropertySummaryName] 作品名称
 * @property {boolean} [ProposalFlag] 企画申請可否
 */
/**
 * @typedef {import('../../slices/aniplex/propertiesSlice').Property[]} GetPropertiesResult 作品一覧APIの結果
 */
/**
 * @typedef {import('../../slices/aniplex/propertiesSlice').PropertyDetail} GetPropertyResult 作品詳細APIの結果
 */
/**
 * @typedef {object} GetLicenseeUsersParam ライセンシーユーザー一覧APIのパラメータ
 * @property {string} [userId] ユーザーID ※部分一致検索
 * @property {string} [username] 氏名 ※部分一致検索
 * @property {string} [mailaddress] メールアドレス ※部分一致検索
 * @property {string} [licenseeCode] 取引先コード ※部分一致検索
 * @property {string} [licenseeNameKanji] 取引先名（漢字） ※部分一致検索
 */
/**
 * @typedef {import('../../slices/aniplex/licenseeUsersSlice').LicenseeUser[]} GetLicenseeUsersResult ライセンシーユーザー一覧APIの結果
 */
/**
 * @typedef {object} PostLicenseeUserParam ライセンシーユーザー登録APIのパラメータ
 * @property {string} username 氏名
 * @property {string} mailaddress メールアドレス
 * @property {string} licenseeCode 取引先コード
 * @property {string} [department] 部署名
 */
/**
 * @typedef {object} PostLicenseeUserResult ライセンシーユーザー登録APIの結果
 * @property {string} userId ユーザーID
 */
/**
 * @typedef {object} PatchLicenseeUserParam ライセンシーユーザー更新APIのパラメータ
 * @property {string} [username] 氏名
 * @property {string} [mailaddress] メールアドレス
 * @property {string} [licenseeCode] 取引コード
 * @property {string} [department] 部署名
 * @property {boolean} [deleteFlag] 削除フラグ
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} PatchLicenseeUserResult ライセンシーユーザー更新APIの結果
 * @property {string} userId ユーザーID
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {import('../../slices/aniplex/usersSlice').UserMyself} getUsersMyselfResult ログインユーザー詳細APIの結果
 */
/**
 * @typedef {object} getCategoriesResult 商品カテゴリーマスタ情報
 * @property {string} categoryNo 商品カテゴリーNo
 * @property {string} categoryName 商品カテゴリー名
 * @property {CategoryDetail[]} categoryDetailList 商品カテゴリー詳細マスタ情報
 */
/**
 * @typedef {object} CategoryDetail 商品カテゴリー詳細マスタ情報
 * @property {number} categoryDetailNo 商品カテゴリー詳細No
 * @property {string} categoryDetailName 商品カテゴリー詳細名
 */

/**
 * @typedef {object} getRoyaltypatternsResult ロイヤリティ報告パターンマスタ情報
 * @property {'1'|'2'|'3'|'4'|'5'|'6'|'7'} rypId ロイヤリティ報告パターンID
 * @property {string} rypName ロイヤリティ報告パターン名
 * @property {boolean} inputPlanProduction 予定生産数入力
 * @property {boolean} inputPlanSales 予定販売数入力
 * @property {boolean} inputPlanProce 予定上代入力
 * @property {boolean} inputPlanCost 予定製造原価入力
 * @property {boolean} inputPlanProceeds 予定売上金額入力
 * @property {boolean} inputRyRate ロイヤリティ率入力
 * @property {boolean} inputRyProce ロイヤリティ単価入力
 * @property {boolean} inputPlanRyAmount ロイヤリティ金額入力
 * @property {boolean} inputReportProduction 生産数報告入力
 * @property {boolean} inputReportSales 販売数報告入力
 * @property {boolean} inputReportPrice 確定上代報告入力
 * @property {boolean} inputReportCost 確定製造原価報告入力
 * @property {boolean} inputReportProceeds 確定売上金額報告入力
 * @property {boolean} inputReportRyAmount ロイヤリティ金額報告入力
 * @property {string} calProceeds 売上金額算出項目
 * @property {string} calRyTarget ロイヤリティ対象金額算出項目
 * @property {string} calRyAmount ロイヤリティ金額算出項目
 */
/**
 * @typedef {object} GetAniplexuserParam ANIPLEXユーザ一覧APIのパラメータ
 * @property {string} [employeeNo] 社員番号
 * @property {string} [employeeName] 氏名
 * @property {string} [mailaddress] メールアドレス
 * @property {'M'|'T'|'A'|'S'} [role] ロール
 */
/**
 * @typedef {import('../../slices/aniplex/aniplexusersSlice').AniplexUser[]} GetAniplexuserResult ANIPLEXユーザー一覧APIの結果
 */
/**
 * @typedef {object} PostAniplexUserParam ANIPLEXユーザー登録APIのパラメータ
 * @property {string} employeeNo 社員番号
 * @property {'M'|'T'|'A'|'S'} role ロール
 */
/**
 * @typedef {object} PostAniplexUserResult ANIPLEXユーザー登録APIの結果
 * @property {string} employeeNo 社員番号
 */
/**
 * @typedef {object} GetAniplexUserResult ANIPLEXユーザー詳細APIの結果
 * @property {string} employeeNo 社員番号
 * @property {string} employeeName 社員番号
 * @property {string} mailaddress メールアドレス
 * @property {'M'|'T'|'A'|'S'} role ロール
 * @property {array} propertySummaryList 担当作品リスト
 * @property {string} propertySummaryList.propertySummaryCode 作品コード
 * @property {string} propertySummaryList.propertySummaryName 作品名称
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} PatchAniplexuserParam ANIPLEXユーザー更新APIのパラメータ
 * @property {'M'|'T'|'A'|'S'} role ロール ※ロール、削除フラグはどちらか一方
 * @property {boolean} deleteFlag 削除フラグ ※ロール、削除フラグはどちらか一方
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} PatchAniplexuserResult ANIPLEXユーザー更新APIの結果
 * @property {string} employeeNo 社員番号
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} GetSdecisionsParam S決裁一覧APIのパラメータ
 * @property {string} sDecisionNo S決裁No
 * @property {string} sTitle S決裁件名
 * @property {string} sTantousya S決裁担当者名
 * @property {string} propertyCode 作品コード
 * @property {string} propertyName 作品名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeName 取引先名
 */
/**
 * @typedef {SDecision[]} GetSdecisionsResult S決裁一覧APIの結果
 */
/**
 * @typedef {import('../../slices/aniplex/sDecisionsSlice').SDecisionDetail} SDecisionDetail
 */
/**
 * @typedef {object} GetLicenseesAllResult ライセンシー一覧APIの結果
 * @property {object} result
 * @property {import('../../slices/aniplex/licenseesSlice').Licensee[]} result.data
 */
/**
 * @typedef {object} SDecision S決裁情報
 * @property {string} sDecisionNo S決裁No
 * @property {string} sTitle 件名
 * @property {string} propertyCode プロパティコード
 * @property {string} propertyName プロパティ名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeName 許諾先名
 * @property {string} startDate 許諾期間開始(YYYY/MM/DD)
 * @property {string} endDate 許諾期間終了(YYYY/MM/DD)
 * @property {string} keiyakuDate 契約締結日(YYYY/MM/DD)
 * @property {string} sTantousya S決裁担当者名
 */
/**
 * @typedef {object} GetAnnouncementAllParam お知らせ一覧APIのパラメータ
 * @property {string} [announcementNo] お知らせNo※部分一致検索
 * @property {string} [announcementTitle] お知らせ件名※部分一致検索
 * @property {string} [releasePeriodFrom] 公開期間From※期間検索
 * @property {string} [releasePeriodTo] 公開期間To※期間検索
 * @property {'STT'|'OPN'|'END'} お知らせステータス（STT: 公開前 OPN: 公開中 END: 公開終了）
 */
/**
 * @typedef {import('../../slices/aniplex/announcementSlice').Announcement[]} GetAnnouncementAllResult お知らせ一覧APIの結果
 */
/**
 * @typedef {object} PostAnnouncementParam お知らせ登録APIのパラメータ
 * @property {string} [announcementTitle] お知らせ件名
 * @property {string} [announcementContent] お知らせ内容
 * @property {string} [announcementStartDatetime] 公開開始日時
 * @property {string} [announcementEndDatetime] 公開終了日時
 * @property {boolean} [announcementMailFlag] メール通知フラグ
 */
/**
 * @typedef {import('../../slices/aniplex/announcementSlice').AnnouncementDetail} GetAnnouncementResult お知ら詳細APIの結果
 */
/**
 * @typedef {object} PatchAnnouncementParam お知らせ更新APIのパラメータ
 * @property {string} [announcementTitle] お知らせ件名
 * @property {string} [announcementContent] お知らせ内容
 * @property {string} [announcementStartDatetime] 公開開始日時
 * @property {string} [announcementEndDatetime] 公開終了日時
 * @property {boolean} [announcementMailFlag] メール通知フラグ
 * @property {boolean} [deleteFlag] 削除フラグ
 * @property {string} [announcementUpdateDatetime] お知らせ更新日時
 */
/**
 * @typedef {object} PatchReceiptRoyaltiesParam 0円報告一括受領APIのリクエストパラメータ
 * @property {object[]} ryReportList
 * @property {number} ryReportList.ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportList.updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {import('../../slices/aniplex/royaltiesSlice').BulkAcceptResult} PatchReceiptRoyaltiesResult
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
