import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Constants } from "../../../Constants"
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { fetchMyself, selectMyself } from "../../../slices/aniplex/usersSlice";
import { clearAnxUserDetail, clearApiStatus, selectApiStatus,
  fetchAnxUserDetail, selectAnxUserDetail, updateAnxUser } from "../../../slices/aniplex/aniplexusersSlice";
import { fetchAniplexUserMst, selectApiStatus as selectApiStatusMst } from "../../../slices/aniplex/masterSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { MessagePopup } from "../../common/MessagePopup";

/**
 * ANXユーザー詳細画面
 */
export const AnxUserDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myself = useSelector(selectMyself);
  const routerParams = useParams();
  const anxuserDetail = useSelector(selectAnxUserDetail);
  const apiStatus = useSelector(selectApiStatus);
  const apiStatusMst = useSelector(selectApiStatusMst);
  const [role, setRole] = useState('');
  const employeeNo = useMemo(() => {
    return routerParams?.employeeNo;
  }, [routerParams?.employeeNo]);

  const [type, setType] = useState(''); // upd or del
  const [message, setMessage] = useState([]);
  const [anxuserPopup, setAnxuserPopup] = useState({
    showFlg: false,
    onClose: null
  });
  useEffect(() => {
    // ログインユーザー詳細取得
    dispatch(fetchMyself());
  }, [dispatch]);
  // 変更処理
  const handleChange = useCallback((value) => {
    setRole(value);
    setMessage(validate(value));
  },[]);
  // 画面離脱時に値クリア
  useEffect(() => {
    dispatch(clearApiStatus('fetchAnxUserDetail'));
    dispatch(clearApiStatus('updateAnxUser'));
    dispatch(clearAnxUserDetail(employeeNo));
  }, [dispatch, employeeNo]);
  // ANXユーザー情報設定
  useEffect(() => {
    if (employeeNo) dispatch(fetchAnxUserDetail(employeeNo));
  }, [dispatch, employeeNo]);
  // 初期設定
  useEffect(() => {
    setRole(anxuserDetail?.role);
  }, [anxuserDetail?.role]);
  const productList = useMemo(() => {
    let tmpProduct = [];
    // 作品リストの並び替え（作品コードの昇順）
    if (anxuserDetail?.propertySummaryList?.length > 0) {
      // ディープコピーでanxuserDetailnの作品リストを参照しないようにする
      tmpProduct = JSON.parse(JSON.stringify(anxuserDetail.propertySummaryList));
      tmpProduct.sort((a, b) => {
        return Number(a.propertySummaryCode) - Number(b.propertySummaryCode);
      });
    }
    return tmpProduct;
  }, [anxuserDetail?.propertySummaryList]);

  // 自分自身の詳細画面を開いてるか?
  const isMyself = useMemo(() => {
    if (!employeeNo) {
      return false;
    }
    return (employeeNo === myself?.employeeNo);
  }, [employeeNo, myself?.employeeNo]);

  // ユーザー更新
  const onUpd = useCallback(() => {
    const result = validate(role);
    if (Object.values(result).flat().length > 0) {
      setMessage(result);
      return;
    }
    setType('upd');
    dispatch(updateAnxUser({
      employeeNo: employeeNo,
      params: {
        role: role,
        updateDatetime: anxuserDetail.updateDatetime
      }
    }));
  }, [anxuserDetail?.updateDatetime, dispatch, employeeNo, role]);
  // ユーザー削除
  const onDel = useCallback(() => {
    setAnxuserPopup({
      showFlg: true,
      onClose: (btn) => {
        if(btn === 'ok') {
          setType('del');
          dispatch(updateAnxUser({
            employeeNo: employeeNo,
            params: {
              deleteFlag: true,
              updateDatetime: anxuserDetail.updateDatetime
            }
          }));
        }
        setAnxuserPopup({ showFlg: false, onClose: null });
      }
    });
  }, [anxuserDetail?.updateDatetime, dispatch, employeeNo]);

  // API状態
  const loading = useMemo(() => {
    return (([apiStatus.fetchAnxUserDetail, apiStatus.updateAnxUser, apiStatusMst.fetchAniplexUserMst].includes('loading')) ?
     <LoadingOverlay /> : null )
  }, [apiStatus.fetchAnxUserDetail, apiStatus.updateAnxUser, apiStatusMst.fetchAniplexUserMst]);
  useEffect(() => {
    if (apiStatus.updateAnxUser === 'finish') {
      const typeStr = type === 'upd' ? '更新' : '削除';
      if (!isMyself || type === 'upd') {
        // 自分自身を削除した場合、ダイアログは出さない(一瞬だけ表示されリダイレクトされてしまうため)
        dispatch(pushMessage(`ANIPLEXユーザー情報を${typeStr}しました。`));
      }
      dispatch(clearApiStatus('updateAnxUser'));
      dispatch(fetchAniplexUserMst());
      // 自分自身を更新・削除した場合、ログイン済みユーザー情報を再取得
      if (isMyself) {
        dispatch(fetchMyself());
      }
      if (type === 'upd') {
        // 更新後に再取得
        dispatch(clearApiStatus('fetchAnxUserDetail'));
        dispatch(clearAnxUserDetail(employeeNo));
        dispatch(fetchAnxUserDetail(employeeNo));
      } else {
        // 削除後遷移
        navigate('/aniplex/anxUserList');
      }
    }
  }, [apiStatus?.updateAnxUser, dispatch, navigate, type, employeeNo, isMyself]);

  return (
  <>
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ANIPLEXユーザー詳細</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/anxuserList'>
            <i className="icn pageback"></i>ANIPLEXユーザー一覧へ戻る
          </Link>
        </p>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>社員番号</dt>
          <dd className="form-body" style={{ display: "block" }}>
            <div className="input-form wdt200">
              <input type="text" name="employeeNo" aria-label="社員番号"
               value={employeeNo ?? ''} maxLength={6} disabled />
            </div>
            <ErrorMessageList messages={message.employeeNo} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>氏名</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="employeeName" aria-label="氏名"
              value={anxuserDetail?.employeeName ?? ''} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>メールアドレス</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="email" name="mailaddress" aria-label="メールアドレス"
              value={anxuserDetail?.mailaddress ?? ''} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>ロール</dt>
          <dd className="form-body" style={{ display: "block" }}>
            <div className="form-select wdt200">
              <select name="role" title="ロールを選択してください" required
                value={role}
                onChange={(event) => handleChange(event.target.value)}>
              {
                Object.entries(Constants.Aniplex.UserRoleName).map(
                  ([key, value]) => <option value={key} key={`opt${key}`}>{value}</option>
                )
              }
              </select>
            </div>
            <ErrorMessageList messages={message.role} />
          </dd>
        </dl>
      </div>

      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title">担当作品</h2>
        </div>

        <div className="l-table mt30">
          <table>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: 200 }}>作品コード</th>
                <th scope="col" style={{ minWidth: 1000 }}>作品名称</th>
              </tr>
            </thead>
            <tbody>
            {
              productList.length > 0 ?
              productList.map(
                p => <tr key={`pr${p.propertySummaryCode}`}><td>{p.propertySummaryCode}</td><td>{p.propertySummaryName ?? ''}</td></tr>
              ):
              (<tr><td colSpan="2">担当作品がありません。</td></tr>)
            }
            </tbody>
          </table>
        </div>
      </section>


      <div className="mt30 dif j-between">
        <div className="l-buttons">
          <p className="btn bg-orange" style={{ width: 203 }}>
            <button type="button" onClick={onUpd}>
              <i className="icn check"></i>ユーザー更新
            </button>
          </p>
        </div>
        <p className="btn c-aniplex" style={{ width: 203 }}>
          <button type="button" onClick={onDel}>
            <i className="icn cross"></i>ユーザー削除
          </button>
        </p>
      </div>
    </div>
    {
      anxuserPopup.showFlg ?
      <MessagePopup
        message='ANIPLEXユーザー情報を削除します。よろしいですか？'
        btn={{ ok: '削除', cancel: 'キャンセル' }}
        onClose={anxuserPopup.onClose}
        btnClass='bg-aniplex' /> : null
    }
    {loading}
  </>
  )
}

/**
 * バリデートチェック
 * @param {string} name パラメータ名
 * @param {string} value 確認対象の値
 */
function validate(value) {
  // ロール
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  } else if (!Object.values(Constants.Aniplex.UserRole).includes(value)){
    return ['不正な値です'];
  }
  // 空
  return [];
}
