import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAniplexuser, getAniplexuser, patchAniplexuser, getAniplexusers } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {AniplexUser[]} 取得したANIPLEXユーザ一覧 */
  anxUserList: [],
  anxUserDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** ANXユーザ一覧取得処理 */
    fetchAnxUserList: null,
    /** ANXユーザー詳細 */
    fetchAnxUserDetail: null,
    /** ANXユーザー登録 */
    registerAnxUser: null,
    /** ANXユーザー更新（兼削除） */
    updateAnxUser: null,
  },
}

/**
 * ANXユーザ一覧取得処理
 */
export const fetchAnxUserList = createAsyncThunk(
  'aniplex/aniplexusers/fetchAnxUserList',
  /**
   * ANIPLEXユーザ一覧取得処理
   * @param {import('../../lib/api/aniplex').GetAniplexuserParam} params リクエストパラメータ
   */
  async (params, { dispatch }) => {
    // 実行前に取得済みのリストをクリア
    dispatch(clearAnxUserList);
    try {
      const result = await getAniplexusers(params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch)
    }
  }
)

/**
 * ANXユーザー詳細情報取得
 */
export const fetchAnxUserDetail = createAsyncThunk(
  'aniplex/aniplexusers/fetchAnxUserDetail',
  /**
   * 証紙申請詳細情報取得
   * @param {string} employeeNo 社員番号
   * @returns {anxUserDetail}
   */
  async(employeeNo, { dispatch }) => {
    try {
      const data = await getAniplexuser(employeeNo);
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '12801': ApiErrorMessage.DataNotExists,
      });
    }
  }
);

/**
 * ANXユーザー登録処理
 */
export const registerAnxUser = createAsyncThunk(
  'aniplex/aniplexusers/registerAnxUser',
  /**
   * ANXユーザー登録処理
   * @param {*}  param
   * @returns
   */
  async(param, { dispatch }) => {
    try {
      const data = await postAniplexuser(param);
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // 登録失敗
        '12701': ApiErrorMessage.RegisterFailed,
        // データ重複
        '12702': ApiErrorMessage.DuplicatedUser,
      })
    }
  }
);

/**
 * ANXユーザー更新処理
 */
export const updateAnxUser = createAsyncThunk(
  'aniplex/aniplexusers/updateAnxUser',
  /**
   * @param {string} employeeNo 証紙申請書内部コード
   * @param {object} params
   * @param {'M'|'T'|'A'|'S'} params.role ロール ※どちらか一方
   * @param {boolean} param.deleteFlag 削除フラグ ※どちらか一方
   * @param {string} param.updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
   * @returns
   */
  async({ employeeNo, params }, { dispatch }) => {
    try {
      const result = await patchAniplexuser(employeeNo, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // 更新状態不一致
        '12901': ApiErrorMessage.UpdateStateMismatch,
        // データ不正
        '12902': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * アニプレックス向け画面のANXユーザー情報周りのスライス
 */
export const aniplexusersSlice = createSlice({
  name: 'aniplex/aniplexusers',
  initialState,
  reducers: {
    /**
     * 取得済みのANXユーザ一覧をクリアする
     * @param {typeof initialState} state
     */
    clearAnxUserList: (state) => {
      state.anxUserList = [];
    },
    /**
     * ANXユーザー詳細をクリア
     * @param {typeof initialState} state
     */
    clearAnxUserDetail: (state) => {
      state.anxUserDetail = null
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        case 'fetchAnxUserList':
          state.apiStatus.fetchAnxUserList = null;
          break;
        case 'fetchAnxUserDetail':
          state.apiStatus.fetchAnxUserDetail = null;
          break;
        case 'registerAnxUser':
          state.apiStatus.registerAnxUser = null;
          break;
        case 'updateAnxUser':
          state.apiStatus.updateAnxUser = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ANXユーザ一覧取得処理
      .addCase(fetchAnxUserList.pending, (state) => {
        state.apiStatus.fetchAnxUserList = 'loading';
      })
      .addCase(fetchAnxUserList.fulfilled, (state, action) => {
        state.apiStatus.fetchAnxUserList = 'finish';
        state.anxUserList = action.payload;
      })
      .addCase(fetchAnxUserList.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAnxUserList = '';
      })
      // ANXユーザー詳細
      .addCase(fetchAnxUserDetail.pending, (state) => {
        state.apiStatus.fetchAnxUserDetail = 'loading';
      })
      .addCase(fetchAnxUserDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchAnxUserDetail = 'finish';
        state.anxUserDetail = action.payload;
      })
      .addCase(fetchAnxUserDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAnxUserDetail = 'error';
      })
      // ANXユーザー登録
      .addCase(registerAnxUser.pending, (state) => {
        state.apiStatus.registerAnxUser = 'loading';
      })
      .addCase(registerAnxUser.fulfilled, (state) => {
        state.apiStatus.registerAnxUser = 'finish';
      })
      .addCase(registerAnxUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.registerAnxUser = null;
      })
      // ANXユーザー更新
      .addCase(updateAnxUser.pending, (state) => {
        state.apiStatus.updateAnxUser = 'loading';
      })
      .addCase(updateAnxUser.fulfilled, (state) => {
        state.apiStatus.updateAnxUser = 'finish';
      })
      .addCase(updateAnxUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateAnxUser = null;
      });
  }
});

export const {
  clearAnxUserList,
  clearApiStatus,
  clearAnxUserDetail
} = aniplexusersSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.aniplexusers.apiStatus;

/**
 * stateからANXユーザ一覧を取得する
 * @param {*} state
 * @returns {AniplexUser[]} ANXユーザ一覧
 */
export const selectAnxUserList = (state) => state.aniplex.aniplexusers.anxUserList;

/**
 *ANXユーザー詳細情報取得
 * @param {*} state
 * @returns {anxUserDetail|null} ANXユーザー詳細情報
 */
export const selectAnxUserDetail = (state) => state.aniplex.aniplexusers.anxUserDetail;

export default aniplexusersSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAnxUserList'|'fetchAnxUserDetail'|'registerAnxUser'|'updateAnxUser'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} AniplexUser ANIPLEXユーザ
 * @property {string} employeeNo 社員番号
 * @property {string} employeeName ユーザー氏名
 * @property {string} mailaddress メールアドレス
 * @property {'M'|'T'|'A'|'S'} role ロール
 * @property {string} regDatetime 登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} loginDatetime 最終ログイン日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} AniplexuserDetail ANIPLEXユーザー
 * @property {string} employeeNo 社員番号
 * @property {string} employeeName 社員番号
 * @property {string} mailaddress メールアドレス
 * @property {'M'|'T'|'A'|'S'} role ロール
 * @property {array} propertySummaryList 担当作品リスト
 * @property {string} propertySummaryList.propertySummaryCode 作品コード
 * @property {string} propertySummaryList.propertySummaryName 作品名称
 * @property {string} updateDatetime 最終更新日時
 */

//#endregion
