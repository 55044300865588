import React, { useLayoutEffect } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LicenseeContainer } from './components/licensee/LicenseeContainer';
import { LoginPage } from './components/licensee/login/LoginPage';
import { AniplexContainer } from './components/aniplex/AniplexContainer';
import { ProposalListPage as AniplexProposalListPage } from './components/aniplex/proposalList/ProposalListPage';
import { ProposalDetailPage as AniplexProposalDetailPage } from './components/aniplex/proposalDetail/ProposalDetailPage';
import { ProposalStatusPage as AniplexProposalStatusPage } from './components/aniplex/proposalStatus/ProposalStatusPage';
import { LabelRequestListPage as AniplexLabelRequestListPage } from './components/aniplex/labelRequestList/LabelRequestListPage';
import { LabelRequestDetailPage as AniplexLabelRequestDetailPage } from './components/aniplex/labelRequestDetail/LabelRequestDetailPage';
import { RoyaltyReportListPage as AniplexRoyaltyReportListPage } from './components/aniplex/royaltyReportList/RoyaltyReportListPage';
import { RoyaltyReportDetailPage as AniplexRoyaltyReportDetailPage } from './components/aniplex/royaltyReportDetail/RoyaltyReportDetailPage';
import { ContractDetailPage as AniplexContractDetailPage } from './components/aniplex/contractDetail/ContractDetailPage'
import { LicenseeListPage } from './components/aniplex/licenseeList/LicenseeListPage';
import { LicenseeDetailPage } from './components/aniplex/licenseeDetail/LicenseeDetailPage';
import { AnxUserListPage } from './components/aniplex/anxUserList/AnxUserListPage';
import { AnxUserRegisterPage } from './components/aniplex/anxUserRegister/AnxUserRegisterPage';
import { AnxUserDetailPage } from './components/aniplex/anxUserDetail/AnxUserDetailPage';
import { LicenseeUserListPage } from './components/aniplex/licenseeUserList/LicenseeUserListPage';
import { LicenseeUserRegisterPage } from './components/aniplex/licenseeUserDetail/LicenseeUserRegisterPage';
import { LicenseeUserUpdatePage } from './components/aniplex/licenseeUserDetail/LicenseeUserUpdatePage';
import { PropertyListPage } from './components/aniplex/propertyList/PropertyListPage';
import { PropertyDetailPage } from './components/aniplex/propertyDetail/PropertyDetailPage';
import { AnnouncementListPage } from './components/aniplex/announcementList/AnnouncementListPage';
import { AnnouncementRegisterPage } from './components/aniplex/announcementDetail/AnnouncementRegisterPage';
import { AnnouncementUpdatePage } from './components/aniplex/announcementDetail/AnnouncementUpdatePage';
import { AuthCodePage } from './components/licensee/authCode/AuthCodePage';
import { UserRegisterPage } from './components/licensee/userRegister/UserRegisterPage';
import { InitialPasswordUpdatePage } from './components/licensee/initialPasswordUpdate/InitialPasswordUpdatePage'
import { PasswordUpdatePage } from './components/licensee/passwordUpdate/PasswordUpdatePage'
import { PasswordResetPage } from './components/licensee/passwordReset/PasswordResetPage';
import { PasswordResetAuthPage } from './components/licensee/passwordResetAuth/PasswordResetAuthPage';
import { HomePage } from './components/licensee/home/HomePage';
import { UserListPage } from './components/licensee/userList/UserListPage';
import { UserInvitationPage } from './components/licensee/userInvitation/UserInvitationPage';
import { UserUpdatePage } from './components/licensee/userUpdate/userUpdatePage';
import { ProposalListPage as LicenseeProposalListPage } from './components/licensee/proposalList/ProposalListPage';
import { ProposalDetailPage as LicenseeProposalDetailPage } from './components/licensee/proposalDetail/ProposalDetailPage';
import { ProposalStatusPage as LicenseeProposalStatusPage } from './components/licensee/proposalStatus/ProposalStatusPage';
import { LabelRequestListPage as LicenseeLabelRequestListPage } from './components/licensee/labelRequestList/LabelRequestListPage';
import { LabelRequestDetailPage as LicenseeLabelRequestDetailPage } from './components/licensee/labelRequestDetail/LabelRequestDetailPage'
import { RoyaltyReportListPage as LicenseeRoyaltyReportListPage } from './components/licensee/royaltyReportList/RoyaltyReportListPage';
import { RoyaltyReportDetailPage as LicenseeRoyaltyReportDetailPage } from './components/licensee/royaltyReportDetail/RoyaltyReportDetailPage';
import { ContractDetailPage as LicenseeContractDetailPage } from './components/licensee/contractDetail/ContractDetailPage'
import { NotFoundPage } from './components/licensee/NotFoundPage';


const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    // 画面遷移時にページTOPへスクロール
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

function App() {
  return (
    <HashRouter>
      <Wrapper>
        <Routes>
          <Route index element={<Navigate to="/licensee/login" />} />
          <Route path='licensee' element={<LicenseeContainer />}>
            <Route index element={<Navigate to='/licensee/home' />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='authCode' element={<AuthCodePage />} />
            <Route path='userRegister' element={<UserRegisterPage />} />
            <Route path='initialPasswordUpdate' element={<InitialPasswordUpdatePage />} />
            <Route path='passwordUpdate' element={<PasswordUpdatePage />} />
            <Route path='passwordReset' element={<PasswordResetPage />} />
            <Route path='passwordResetAuth' element={<PasswordResetAuthPage />} />
            <Route path='home' element={<HomePage />} />
            <Route path='userList' element={<UserListPage />} />
            <Route path='userInvitation' element={<UserInvitationPage />} />
            <Route path='userUpdate' element={<UserUpdatePage />} />
            <Route path='proposalList' element={<LicenseeProposalListPage />} />
            <Route path='proposalDetail' element={<LicenseeProposalDetailPage />} />
            <Route path='proposalDetail/:proposalId' element={<LicenseeProposalDetailPage />} />
            <Route path='proposalStatus/:proposalId' element={<LicenseeProposalStatusPage />} />
            <Route path='labelRequestList' element={<LicenseeLabelRequestListPage />} />
            <Route path='labelRequestList/:proposalNo' element={<LicenseeLabelRequestListPage />} />
            <Route path='labelRequestDetail' element={<LicenseeLabelRequestDetailPage />} />
            <Route path='labelRequestDetail/:labelApplicationId' element={<LicenseeLabelRequestDetailPage />} />
            <Route path='royaltyReportList' element={<LicenseeRoyaltyReportListPage />} />
            <Route path='royaltyReportList/:proposalNo' element={<LicenseeRoyaltyReportListPage />} />
            <Route path='royaltyReportDetail' element={<LicenseeRoyaltyReportDetailPage />} />
            <Route path='royaltyReportDetail/:ryReportId' element={<LicenseeRoyaltyReportDetailPage />} />
            <Route path='contractDetail/:sDecisionNo' element={<LicenseeContractDetailPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
          <Route path='aniplex' element={<AniplexContainer />}>
            <Route path='proposalList' element={<AniplexProposalListPage />} />
            <Route path='proposalDetail/:proposalId' element={<AniplexProposalDetailPage />} />
            <Route path='proposalStatus/:proposalId' element={<AniplexProposalStatusPage />} />
            <Route path='labelRequestList' element={<AniplexLabelRequestListPage />} />
            <Route path='labelRequestList/:proposalNo' element={<AniplexLabelRequestListPage />} />
            <Route path='labelRequestDetail/:labelApplicationId' element={<AniplexLabelRequestDetailPage />} />
            <Route path='royaltyReportList' element={<AniplexRoyaltyReportListPage />} />
            <Route path='royaltyReportList/:proposalNo' element={<AniplexRoyaltyReportListPage />} />
            <Route path='royaltyReportDetail/:ryReportId' element={<AniplexRoyaltyReportDetailPage />} />
            <Route path='contractDetail/:sDecisionNo' element={<AniplexContractDetailPage />} />
            <Route path='licenseeList' element={<LicenseeListPage />} />
            <Route path='licenseeDetail/:licenseeCode' element={<LicenseeDetailPage />} />
            <Route path='anxUserList' element={<AnxUserListPage />} />
            <Route path='anxUserRegister' element={<AnxUserRegisterPage />} />
            <Route path='anxUserDetail/:employeeNo' element={<AnxUserDetailPage />} />
            <Route path='licenseeUserList' element={<LicenseeUserListPage />} />
            <Route path='licenseeUserRegister' element={<LicenseeUserRegisterPage />} />
            <Route path='licenseeUserUpdate/:userId' element={<LicenseeUserUpdatePage />} />
            <Route path='propertyList' element={<PropertyListPage />} />
            <Route path='propertyDetail/:propertySummaryCode' element={<PropertyDetailPage />} />
            <Route path='announcementList' element={<AnnouncementListPage />} />
            <Route path='announcementRegister' element={<AnnouncementRegisterPage />} />
            <Route path='announcementUpdate/:announcementId' element={<AnnouncementUpdatePage />} />
          </Route>
        </Routes>
      </Wrapper>
    </HashRouter>
  );
}

export default App;
