import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty, maxLength } from "../../../lib/validator";
import { clearApiStatus, registerProductImageUrl, selectApiStatus } from "../../../slices/licensee/proposalsSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay";

/**
 * 完成イメージ登録ポップアップ
 * @param {object} props
 * @param {Product} props.product 対象の商品情報
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const ProductImagePopup = ({ product, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).registerProductImageUrl;
  // 完成イメージURL
  const [productImageUrl, setProductImageUrl] = useState(product?.productImageUrl ?? '');
  // エラーメッセージ
  const [messages, setMessages] = useState({
    productImageUrl: [],
  });
  // エラー時にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['productImageUrl']);
  // エラーフォーカスフラグ
  const [needFocusError, setNeedFocusError] = useState(false);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.productImageUrl.length > 0) {
      if (focusError('productImageUrl')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.productImageUrl.length, needFocusError]);

  /** 完成イメージURL変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      productImageUrl: validateProductImageUrl(value),
    }));
    setProductImageUrl(value);
  }, []);

  /** 登録ボタン押下時のコールバック */
  const onSubmit = useCallback(() => {
    const errors = {
      productImageUrl: validateProductImageUrl(productImageUrl),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      return;
    }

    // 完成イメージURL登録APIを呼び出す
    dispatch(registerProductImageUrl({
      productId: product.productId,
      productImageUrl,
      productImageRegDatetime: product.productImageRegDatetime,
    }));
  }, [dispatch, product.productId, product.productImageRegDatetime, productImageUrl]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(clearApiStatus('registerProductImageUrl'));
      onClose('submit');
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('完成イメージURLの登録中にエラーが発生しました'));
      dispatch(clearApiStatus('registerProductImageUrl'));
      return;
    }
  }, [apiStatus, dispatch, onClose])

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup onClose={() => onClose('close')}>
        <h4 className="popup-title">完成イメージ登録</h4>

        <div className="l-form">
          <div className="form-set">
            <dt className="form-name required">完成イメージURL</dt>
            <dd className="form-body">
              <div className="input-form">
                <input type="text"
                  ref={formRefs.current.productImageUrl}
                  disabled={formLocked}
                  value={productImageUrl}
                  onChange={ev => handleChange(ev.target.value)} />
              </div>
              <ErrorMessageList messages={messages.productImageUrl} />
            </dd>
          </div>
        </div>

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-pink">
            <button
              disabled={formLocked}
              onClick={onSubmit}
            >登録</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  );
}

/**
 * 完成イメージURLをバリデートする
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateProductImageUrl(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  if (!maxLength(value, 200)) {
    errors.push(getMessage('maxLength', { max: 200 }));
  }

  return errors;
}

//#region typedef
/**
 * @typedef {import('./ProposalProductsForm').Product} Product 商品情報
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン
 */
//#endregion typedef
