import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom";
import {
  selectAuthCodeResult,
  selectPasswordUpdateStatus,
  selectTemporaryToken,
  selectToken
} from "../../slices/licensee/authSlice"
import {
  selectTemporaryToken as selectPasswordTemporaryToken,
} from "../../slices/licensee/passwordSlice";
import { selectMyself } from "../../slices/licensee/usersSlice";
import { selectReturnPath, setReturnPath } from "../../slices/licensee/utilSlice";

/**
 * 認証処理に関する画面のパス
 */
const authPages = [
  '/licensee/login',
  '/licensee/authCode',
  '/licensee/initialPasswordUpdate',
  '/licensee/passwordUpdate',
];

/**
 * ログイン状態を元にリダイレクト先を取得する
 * @returns {string|null} リダイレクト先のURL.ログイン済みの場合はnull.
 */
export const useAuthRedirect = () => {
  const token = useSelector(selectToken);
  const temporaryToken = useSelector(selectTemporaryToken);
  const authCodeResult = useSelector(selectAuthCodeResult);
  const dispatch = useDispatch();
  const location = useLocation();
  const myself = useSelector(selectMyself);
  const passwordUpdateStatus = useSelector(selectPasswordUpdateStatus);
  const returnPath = useSelector(selectReturnPath);

  /**
   * API側パスワード状態
   * @type {{ initialLogin: boolean, passwordExpired: boolean }}
   */
  const passwordStatus = useMemo(() => {
    if (authCodeResult) {
      return {
        initialLogin: authCodeResult.initialLogin,
        passwordExpired: authCodeResult.passwordExpired,
      };
    }
    return {
      initialLogin: myself?.initialLogin,
      passwordExpired: myself?.passwordExpired,
    }
  }, [authCodeResult, myself?.initialLogin, myself?.passwordExpired])

  const result = useMemo(() => {
    if (token == null) {
      if (temporaryToken == null) {
        // 未ログイン
        return '/licensee/login';
      } else {
        // 認証コード未送信
        return '/licensee/authCode';
      }
    }

    if (passwordStatus.initialLogin === false && !passwordUpdateStatus.initialPassword) {
      // 初期パスワード変更前
      return '/licensee/initialPasswordUpdate';
    }

    if (passwordStatus.passwordExpired && !passwordUpdateStatus.expiredPassword) {
      // 期限切れパスワード変更前
      return '/licensee/passwordUpdate';
    }

    // 正常ログイン状態
    if (returnPath && returnPath !== location.pathname) {
      // 復帰先パスがある場合はそのパスを指定
      return returnPath;
    }

    // 復帰完了後
    return null;
  }, [token, passwordStatus.initialLogin, passwordStatus.passwordExpired, passwordUpdateStatus.initialPassword, passwordUpdateStatus.expiredPassword, returnPath, location.pathname, temporaryToken]);

  useEffect(() => {
    if (result != null && !authPages.includes(location.pathname)) {
      // 未認証状態で認証関連以外の画面にアクセスした場合は
      // そのパスを復帰先として保存
      dispatch(setReturnPath(location.pathname));
    }
  }, [dispatch, location.pathname, result]);

  useEffect(() => {
    // 復帰先のパスが開かれたら保存していたパスをクリア
    if (location.pathname === returnPath) {
      dispatch(setReturnPath(null));
    }
  }, [dispatch, location.pathname, returnPath]);

  return result;
}

/**
 * パスワード再設定系画面でトークンの状態を元にリダイレクト先を取得する
 */
export const usePasswordRedirect = () => {
  const pwdTemporaryToken = useSelector(selectPasswordTemporaryToken);
  const authRedirect = useAuthRedirect();

  if (pwdTemporaryToken != null) {
    // パスワード再設定認証
    return '/licensee/passwordResetAuth';
  }

  if (authRedirect === '/licensee/login') {
    // 一時トークン未取得かつ未ログイン時
    // パスワード再設定画面
    return '/licensee/passwordReset';
  }

  // 上記以外はログイン状態に応じた判定を返す
  return authRedirect;
}
