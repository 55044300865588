import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNumber, isValidPassword, lengthRange } from "../../../lib/validator";
import { getMessage } from "../../../lib/message";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { usePasswordRedirect } from "../../../lib/hooks/licensee";
import { clearApiStatus, reset, selectApiStatus, selectTemporaryToken } from "../../../slices/licensee/passwordSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { useFocusError } from "../../../lib/hooks/common";

/** フォーム項目の順序 */
const formPropOrder = [
  'passResetAuthCode',
  'password',
  'passwordConf',
];

/**
 * パスワード再設定認証画面
 */
export const PasswordResetAuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus).reset;
  const tempToken = useSelector(selectTemporaryToken);
  const pwdRedirect = usePasswordRedirect();

  const [messages, setMessages] = useState({
    passResetAuthCode: [],
    password: [],
    passwordConf: [],
  });
  const [formData, setFormData] = useState({
    passResetAuthCode: '',
    password: '',
    passwordConf: '',
  });

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);

  const handleChange = useCallback((name, value) => {
    const tmpForm = { ...formData, [name]: value };
    setMessages({ ...validate(tmpForm) });
    setFormData({ ...formData, [name]: value });
  }, [formData]);

  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  /** パスワード再設定 */
  const onReset = useCallback(() => {
    // バリデート
    const result = validate(formData);
    if (Object.values(result).flat().length > 0) {
      setMessages({ ...result });
      setNeedFocusError(true);
      return;
    }
    const params = {
      password: formData.password,
      passResetAuthCode: formData.passResetAuthCode
    };
    dispatch(reset({
      params: params,
      // 一時トークン手動設定
      option: {headers: { 'Authorization': tempToken } }
    }));
  }, [dispatch, formData, tempToken]);

    // API通信中はローディング表示
    const loading = useMemo(() => {
      return apiStatus === 'loading' ? (<LoadingOverlay />) : null;
    }, [apiStatus]);

  // パスワード再設定認証待ち以外は対応する画面へリダイレクト
  useEffect(() => {
    if (pwdRedirect !== '/licensee/passwordResetAuth') {
      navigate(pwdRedirect ?? '/licensee/home');
    }
  }, [navigate, pwdRedirect]);

  useEffect(() => {
    // 画面離脱時にAPI通信状況クリア
    return () => {
      dispatch(clearApiStatus('reset'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus === 'error') {
      dispatch(pushMessage('パスワード再設定に失敗しました。'));
      dispatch(clearApiStatus('reset'));
    }
  }, [apiStatus, dispatch]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      dispatch(pushMessage('パスワード再設定が完了しました'))
      // パスワード再設定が成功したらログイン
      navigate('/licensee/login');
    }
  }, [apiStatus, dispatch, navigate]);

  return (
    <>
      <h1 className="main_login-head">パスワード再設定</h1>
      <div className="main_login-body wrap">
        <p className="main_login-read tac">
          ご登録のメールアドレスに届いた認証コードと新しいパスワードを入力して、再設定ボタンを押してください。
        </p>
        <div className="main_login-content">
          <dl className="main_login-form form-set">
            <dt className="form-name">認証コード</dt>
            <dd className="form-body wdt500">
              <div className="input-form">
                <input type="password" name="passResetAuthCode"
                 title="認証コードは必ず入力してください" aria-label="認証コード" maxLength={6}
                 ref={formRefs.current.passResetAuthCode}
                 onChange={(event) => handleChange('passResetAuthCode', event.target.value)}
                 required />
              </div>
              <ErrorMessageList messages={messages.passResetAuthCode}></ErrorMessageList>
            </dd>
          </dl>

          <dl className="main_login-form form-set">
            <dt className="form-name">パスワード</dt>
            <dd className="form-body wdt500">
              <div className="input-form">
                <input type="password" name="password"
                 title="パスワードは必ず入力してください" aria-label="パスワード"
                 ref={formRefs.current.password}
                 onChange={(event) => handleChange('password', event.target.value)}
                 maxLength={32} required />
              </div>
              <ErrorMessageList messages={messages.password}></ErrorMessageList>
            </dd>
          </dl>

          <dl className="main_login-form form-set">
            <dt className="form-name">パスワード（確認）</dt>
            <dd className="form-body wdt500">
              <div className="input-form">
                <input type="password" name="passwordConf"
                 title="パスワード（確認）は必ず入力してください" aria-label="パスワード（確認）"
                 ref={formRefs.current.passwordConf}
                 onChange={(event) => handleChange('passwordConf', event.target.value)}
                 maxLength={32} required />
              </div>
              <ErrorMessageList messages={messages.passwordConf}></ErrorMessageList>
            </dd>
          </dl>

          <p className="btn bg-green">
            <button onClick={() => onReset()}>
              パスワード再設定
            </button>
          </p>
        </div>
      </div>
      {loading}
    </>
  );
}

/**
 * 一括バリデートチェック
 * @param {object} formData
 */
function validate(formData) {
  const errors = [];
  for (const[name, value] of Object.entries(formData)) {
    switch(name) {
      case 'passResetAuthCode':
        errors[name] = validatePassResetAuthCode(value);
        break;
      case 'password':
        errors[name] = validatePassword(value);
        break;
      case 'passwordConf':
        errors[name] = validatePassword(value);
        // 一致確認
        if (value !== formData.password) {
          errors[name].push(getMessage('isNotMatchConf', {'param':'パスワード'}));
        }
        break;
      default:
        break;
    }
  }
  return errors;
}

/**
 * 認証コードのバリデート
 * @param {*} value
 */
function validatePassResetAuthCode(value) {
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!isNumber(value) || !lengthRange(value, 6, 6)) {
    return [getMessage('isFixedDigitNumber', {'digit': 6})];
  }
  return [];
}

/**
 * パスワードの共通バリデート
 * @param {string} value
 * @returns
 */
function validatePassword(value) {
  const errors = [];
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  if (!lengthRange(value, 8, 32)) {
    errors.push(getMessage('lengthRange', {min:8, max:32}));
  }
  if (!isValidPassword(value)) {
    errors.push(getMessage('isValidPassword'));
  }
  return errors;
}
