import { UploadAreaIcon } from './UploadAreaIcon';
import { useIconFileUpload } from '../../../lib/hooks/common';
import { putFilesUpload } from '../../../lib/api/aniplex';
import { UploadList } from '../../common/UploadList';
import { UploadedImageIcon } from './UploadedImageIcon';
import { useState, useCallback, useEffect } from 'react';

/**
 * ANX画像アップロード
 * @returns
 */
export const UploadImageIcon = ({
  regIconFileName,
  onDelUploadedIcon,
  onChangeHasError,
  onChangeHasProgress,
  onChangeFileName,
}) => {
  // ファイルアップロードのhook
  const [fileEntries, setFileEntries, startUploading] = useIconFileUpload({
    apiUploadFile: bindUploadIcon(),
  });

  const [isUploaded, setIsUploaded] = useState(false);

  // アップロード済み画像(一時保存からの復帰時)が削除された時の処理
  useEffect(() => {
    setIsUploaded(regIconFileName !== '');
  }, [regIconFileName]);

  const onDeleteFile = useCallback(entries => {
    if (typeof onDelUploadedIcon !== 'function') {
      return;
    }
    onDelUploadedIcon('');
    setIsUploaded(false);
    setFileEntries(entries);
  }, [onDelUploadedIcon, setFileEntries]);

  const props = {
    fileEntries,
    setFileEntries,
    startUploading,
    onChangeHasError,
    onChangeHasProgress,
    onChangeFileName,
  };

  return (
    <UploadAreaIcon { ...props } onDragEnter={() => document.title = 'foobar'}
      ListComponent={UploadList}
    >
    {isUploaded && (
      <UploadedImageIcon regIconFileName={regIconFileName} onDeleteFile={onDeleteFile} />
    )}
    </UploadAreaIcon>
  );
};

const bindUploadIcon = () => async options => {
  return putFilesUpload(options)
}


