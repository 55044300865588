import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAniplexusers, getCategories, getProperties, getRoyaltypatterns } from '../../lib/api/aniplex';
import { handleAniplexApiError } from '../../lib/util';

/** 初期値 */
const initialState = {
  /** @type {Property[]} 作品マスタ */
  propertyMst: [],
  /** カテゴリマスタ */
  categoryMst: [],
  /** ロイヤリティ報告パターンマスタ */
  royaltyPatternMst: [],
  /** @type {AniplexUser[]} ANIPLEXユーザマスタ */
  aniplexUserMst: [],
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** 作品マスタ取得処理 */
    fetchPropertyMst: null,
    /** カテゴリマスタ取得処理 */
    fetchCategoryMst: null,
    /** ロイヤリティ報告パターンマスタ取得処理 */
    fetchRoyaltyPatternMst: null,
    /** ANIPLEXユーザマスタ取得処理 */
    fetchAniplexUserMst: null,
  },
}

/**
 * 作品マスタ取得処理
 */
export const fetchPropertyMst = createAsyncThunk(
  'aniplex/master/fetchPropertyMst',
  async (_, { dispatch }) => {
    try {
      const data = await getProperties();
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * カテゴリマスタ取得処理
 */
export const fetchCategoryMst = createAsyncThunk(
  'aniplex/master/fetchCategoryMst',
  async (_, { dispatch }) => {
    try {
      const data = await getCategories();
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
);

/**
 * ロイヤリティ報告パターンマスタ取得処理
 */
export const fetchRoyaltyPatternMst = createAsyncThunk(
  'aniplex/master/fetchRoyaltyPatternMst',
  async (_, { dispatch }) => {
    try {
      const data = await getRoyaltypatterns();
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
);

/**
 * ANIPLEXユーザマスタ取得処理
 */
export const fetchAniplexUserMst = createAsyncThunk(
  'aniplex/master/fetchAniplexUserMst',
  async (_, { dispatch }) => {
    try {
      const data = await getAniplexusers();
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * ANIPLEX向け画面のマスタ情報スライス
 */
export const masterSlice = createSlice({
  name: 'aniplex/master',
  initialState,
  reducers: {
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // 作品マスタ取得処理
        case 'fetchPropertyMst':
          state.apiStatus.fetchPropertyMst = null;
          break;
        // カテゴリマスタ取得処理
        case 'fetchCategoryMst':
          state.apiStatus.fetchCategoryMst = null;
          break;
        // ロイヤリティ報告パターンマスタ取得処理
        case 'fetchRoyaltyPatternMst':
          state.apiStatus.fetchRoyaltyPatternMst = null;
          break;
        // ANIPLEXユーザーマスタ取得処理
        case 'fetchAniplexUserMst':
          state.apiStatus.fetchAniplexUserMst = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // 作品マスタ取得処理
      .addCase(fetchPropertyMst.pending, (state) => {
        state.apiStatus.fetchPropertyMst = 'loading';
      })
      .addCase(fetchPropertyMst.fulfilled, (state, action) => {
        state.apiStatus.fetchPropertyMst = 'finish';
        state.propertyMst = action.payload;
      })
      .addCase(fetchPropertyMst.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchPropertyMst = null;
      })
      // カテゴリマスタ取得処理
      .addCase(fetchCategoryMst.pending, (state) => {
        state.apiStatus.fetchCategoryMst = 'loading';
      })
      .addCase(fetchCategoryMst.fulfilled, (state, action) => {
        state.apiStatus.fetchCategoryMst = 'finish';
        state.categoryMst = action.payload;
      })
      .addCase(fetchCategoryMst.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchCategoryMst = null;
      })
      // ロイヤリティ報告パターンマスタ取得処理
      .addCase(fetchRoyaltyPatternMst.pending, (state) => {
        state.apiStatus.fetchRoyaltyPatternMst = 'loading';
      })
      .addCase(fetchRoyaltyPatternMst.fulfilled, (state, action) => {
        state.apiStatus.fetchRoyaltyPatternMst = 'finish';
        state.royaltyPatternMst = action.payload;
      })
      .addCase(fetchRoyaltyPatternMst.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchRoyaltyPatternMst = null;
      })
      // ANIPLEXユーザーマスタ取得処理
      .addCase(fetchAniplexUserMst.pending, (state) => {
        state.apiStatus.fetchAniplexUserMst = 'loading';
      })
      .addCase(fetchAniplexUserMst.fulfilled, (state, action) => {
        state.apiStatus.fetchAniplexUserMst = 'finish';
        state.aniplexUserMst = action.payload;
      })
      .addCase(fetchAniplexUserMst.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAniplexUserMst = null;
      });
  }
});

export const {
  clearApiStatus,
} = masterSlice.actions;

export default masterSlice.reducer;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.master.apiStatus;

/**
 * stateから作品マスタを取得する
 * @param {*} state
 * @returns {Property[]} 作品マスタ
 */
export const selectPropertyMst = (state) => state.aniplex.master.propertyMst;

/**
 * stateからカテゴリマスタを取得する
 * @param {*} state
 * @returns {CategoryMst[]} カテゴリマスタ
 */
export const selectCategoryMst = (state) => state.aniplex.master.categoryMst;

/**
 * stateからロイヤリティ報告パターンマスタを取得する
 * @param {*} state
 * @returns {RoyaltyPatternMst[]} ロイヤリティ報告パターンマスタ
 */
export const selectRoyaltyPatternMst = (state) => state.aniplex.master.royaltyPatternMst;

/**
 * stateからANIPLEXユーザーマスタを取得する
 * @param {*} state
 * @returns {AniplexUser[]} ANIPLEXユーザーマスタ
 */
export const selectAniplexUserMst = (state) => state.aniplex.master.aniplexUserMst;

//#region typedef
/**
 * @typedef {'fetchPropertyMst'|'fetchCategoryMst'|'fetchRoyaltyPatternMst'|'fetchAniplexUserMst'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {import('./propertiesSlice').Property} Property 作品情報
 */
/**
 * @typedef {import('./aniplexusersSlice').AniplexUser} AniplexUser ANIPLEXユーザー情報
 */
/**
 * @typedef {object} RoyaltyPatternMst ロイヤリティ報告パターンマスタ情報
 * @property {'1'|'2'|'3'|'4'|'5'|'6'|'7'} rypId ロイヤリティ報告パターンID
 * @property {string} rypName ロイヤリティ報告パターン名
 * @property {boolean} inputPlanProduction 予定生産数入力
 * @property {boolean} inputPlanSales 予定販売数入力
 * @property {boolean} inputPlanProce 予定上代入力
 * @property {boolean} inputPlanCost 予定製造原価入力
 * @property {boolean} inputPlanProceeds 予定売上金額入力
 * @property {boolean} inputPlanRyAmount ロイヤリティ金額入力
 * @property {boolean} inputRyRate ロイヤリティ率入力
 * @property {boolean} inputRyPrice ロイヤリティ単価入力
 * @property {boolean} inputReportProduction 生産数報告入力
 * @property {boolean} inputReportSales 販売数報告入力
 * @property {boolean} inputReportPrice 確定上代報告入力
 * @property {boolean} inputReportCost 確定製造原価報告入力
 * @property {boolean} inputReportProceeds 確定売上金額報告入力
 * @property {boolean} inputReportRyAmount ロイヤリティ金額報告入力
 * @property {string} calProceeds 売上金額算出項目
 * @property {string} calRyTarget ロイヤリティ対象金額算出項目
 * @property {string} calRyAmount ロイヤリティ金額算出項目
 */
/**
 * @typedef {object} CategoryMst 商品カテゴリーマスタ情報
 * @property {number} categoryNo 商品カテゴリーNo
 * @property {string} categoryName 商品カテゴリー名
 * @property {CategoryDetail[]} categoryDetailList 商品カテゴリー詳細マスタ情報
 */
/**
 * @typedef {object} CategoryDetail 商品カテゴリー詳細マスタ情報
 * @property {number} categoryDetailNo 商品カテゴリー詳細No
 * @property {string} categoryDetailName 商品カテゴリー詳細名
 */
//#endregion typedef
