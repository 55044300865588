//@ts-check
import React, { useMemo } from 'react';
import { SpannableTableView } from '../../common/table/SpannableTableView';
import dayjs from 'dayjs';
import { Constants } from '../../../Constants';

/** @type {TableHeader[]} */
const headers = [
  { id: 'messageRegDatetime', label: '日時', style: { minWidth: '160px' } },
  { id: 'messageRegUserName', label: '氏名', style: { minWidth: '200px' } },
  { id: 'messageRegStatus', label: '操作', style: { minWidth: '150px' } },
  { id: 'messageContent', label: 'コメント', style: { minWidth: '300px' } },
];

/**
 * 連絡事項のエリア
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 */
export const MessageHistoryArea = ({ proposalDetail }) => {
  const sortedList = useSortedList(proposalDetail?.messageHistoryList ?? [])
  const records = useViewRecords(sortedList);

  if (records.length === 0) {
    return null;
  }

  return (
    <section className="mt40">
      <div className="title-pink">
        <h2 className="title">連絡事項</h2>
      </div>

      {/* @ts-expect-error */}
      <SpannableTableView
        className='mt10 border0'
        headers={headers}
        records={records} />
    </section>
  )
}

/**
 * 連絡事項のリストをソートする
 * @param {MessageHistory[]} messageHistoryList 連絡事項のリスト
 */
const useSortedList = (messageHistoryList) => {
  return useMemo(() =>
    [...messageHistoryList].sort((a, b) => {
      const dateA = dayjs(a.messageRegDatetime, 'YYYY/MM/DD HH:mm:ss')
      const dateB = dayjs(b.messageRegDatetime, 'YYYY/MM/DD HH:mm:ss')
      return dateA.diff(dateB, 'seconds')
    }), [messageHistoryList]);
}

/**
 * 表示用のレコードを取得する
 * @param {MessageHistory[]} messageHistoryList 連絡事項のリスト
 * @returns {TableRecord[]}
 */
const useViewRecords = (messageHistoryList) => {
  return useMemo(() =>
    messageHistoryList.map(h => ({
      messageRegDatetime: {
        el: dayjs(h.messageRegDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm'),
      },
      messageRegUserName: {
        el: h.messageRegUserName,
      },
      messageRegStatus: {
        el: Constants.Licensee.ProposalStatusNameForHistory[h.messageRegStatus],
      },
      messageContent: {
        el: h.messageContent,
        style: {
          whiteSpace: 'pre-wrap'
        }
      }
    })), [messageHistoryList]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {ElementOf<ProposalDetail['messageHistoryList']>} MessageHistory
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord
 */
/**
 * @typedef {T[number]} ElementOf
 * @template {unknown[]} T
 */
//#endregion
