import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll as licenseesFetchAll,
  fetchAllLicensees,
  clearFetchAll,
  selectApiStatus,
  clearApiStatus,
} from "../../../slices/aniplex/licenseesSlice";
import { SelectLicenseePopupSearchForm } from "./SelectLicenseePopupSearchForm";
import { SelectLicenseePopupTable } from "./SelectLicenseePopupTable";
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay";

/**
 * 取引先設定ポップアップ
 * @param {object} props
 * @param {(btn: 'ok'|'cancel', licenseeCode?: string) => void} props.onClose 閉じたときのコールバック
 * @returns
 */
export const SelectLicenseePopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const licenseeList = useSelector(fetchAllLicensees)?.result?.data;
  const [innerLicenseeCode, setInnerLicenseeCode] = useState('');
  const [beforeFirstSearch, setBeforeFirstSearch] = useState(true);

  useEffect(() => {
    return () => {
      /** ポップアップ離脱時にAPI通信状況をクリアする */
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    };
  }, [dispatch]);

  /** 検索を行う */
  const onSearch = useCallback((param) => {
    dispatch(clearFetchAll());
    dispatch(licenseesFetchAll(param));
    setInnerLicenseeCode('');
    setBeforeFirstSearch(false);
  }, [dispatch]);

  /** 検索結果表下部に表示されるメッセージ */
  /** これが空文字列ではないときは検索結果は表示されず、代わりにこの文字列が表示される */
  const tableMessage = useMemo(() => {
    return apiStatus === 'loading'
      ? '検索中'
      : beforeFirstSearch
        ? 'ここに検索結果が表示されます。'
        : '';
  }, [apiStatus, beforeFirstSearch]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      licenseeCode: validateLicenseeCode(value),
    }));
    setInnerLicenseeCode(value);
  }, []);

  // 強制バリデートフラグ
  const [validateAllFlg, setValidateAllFlg] = useState(false);
  // エラーメッセージ
  const [messages, setMessages] = useState({});
  /** エラーフラグ */
  const hasError = useMemo(() => Object.values(messages).flat().length > 0, [messages]);

  useEffect(() => {
    if (validateAllFlg) {
      // 全項目バリデート
      const newMessages = {};
      newMessages.licenseeCode = validateLicenseeCode(innerLicenseeCode);
      setMessages(prev => ({
        ...prev,
        ...newMessages,
      }));
    }
  }, [innerLicenseeCode, validateAllFlg]);

  /** 設定ボタン押下時のハンドラ */
  const onSettingClick = useCallback(() => {
    setValidateAllFlg(true);

    const errors = validateLicenseeCode(innerLicenseeCode);
    if (errors.length > 0) {
      return;
    }

    const innerLicenseeNameKanji = licenseeList?.find((l) => l.licenseeCode === innerLicenseeCode)?.licenseeNameKanji ?? '';

    onClose('ok', innerLicenseeCode, innerLicenseeNameKanji);
  }, [innerLicenseeCode, onClose, licenseeList]);

  return (
    <>
      <FlexiblePopup style={{ maxWidth: '90%' }} onClose={() => onClose('cancel')}>
        <h4 className="popup-title">取引先設定</h4>

        <SelectLicenseePopupSearchForm
          onSearch={onSearch}
        />

        <div className="mt30" style={{ maxHeight: '47vh', overflowY: 'auto' }}>
          <SelectLicenseePopupTable
            licensees={licenseeList ?? []}
            selected={innerLicenseeCode}
            onSelect={handleChange}
            error={tableMessage}
          />
        </div>
        <ErrorMessageList messages={messages.licenseeCode ?? []} />

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-yellow">
            <button
              disabled={hasError}
              onClick={onSettingClick}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>
      {apiStatus === 'loading' ? <LoadingOverlay /> : null}
    </>
  )
}

/**
 * 取引先コードのバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateLicenseeCode(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}
