import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../../Constants";
import { isAlphanumeric, isEmpty, lengthRange } from "../../../lib/validator";
import { getMessage } from '../../../lib/message';
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { registerAnxUser, selectApiStatus, clearApiStatus } from "../../../slices/aniplex/aniplexusersSlice";
import { fetchAniplexUserMst } from "../../../slices/aniplex/masterSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { Link, useNavigate } from "react-router-dom";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { useFocusError } from "../../../lib/hooks/common";

/**
 * ANXユーザー登録
 */
export const AnxUserRegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus).registerAnxUser;
  const [messages, SetMessages] = useState({
    employeeNo: [],
    role: [],
  });
  const [formData, setFormData] = useState({
    employeeNo: '',
    // TODO::デフォルト：管理者（M）ロール指定
    role: 'M',
  });
  const [employeeNo, setEmployeeNo] = useState('');

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['employeeNo']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.employeeNo.length > 0) {
      if (focusError('employeeNo')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.employeeNo.length, needFocusError]);

  // 変更
  const handleChange = useCallback((name, value) => {
    SetMessages({...messages, [name]: validate(name, value)});
    setFormData({...formData, [name]: value});
  }, [formData, messages]);

  // 送信処理
  const onSubmit = useCallback(() => {
    // 一括バリデートチェック
    const result = { employeeNo: [], role: [] };
    Object.entries(formData).forEach(([key, value]) => {
      result[key] = validate(key, value);
    });
    if (Object.values(result).flat().length > 0) {
      SetMessages(result);
      setNeedFocusError(true);
      return;
    }
    // 新規登録
    setEmployeeNo(formData.employeeNo);
    dispatch(registerAnxUser(formData));
  }, [dispatch, formData]);

  // ローディング
  const loading = useMemo(() => {
    return apiStatus === 'loading' ?
    <LoadingOverlay /> : null
  }, [apiStatus]);
  useEffect(() => {
    if (apiStatus === 'error') {
      dispatch(pushMessage('ANIPLEXユーザー登録に失敗しました。'));
      dispatch(clearApiStatus('registerAnxUser'));
    }
  });
  useEffect(() => {
    // ANXユーザー登録後に詳細画面に遷移する
    if (apiStatus === 'finish') {
      dispatch(pushMessage('ANIPLEXユーザーを登録しました。'))
      dispatch(clearApiStatus('registerAnxUser'));
      dispatch(fetchAniplexUserMst());
      navigate(`/aniplex/anxUserDetail/${employeeNo}`);
    }
  }, [apiStatus, dispatch, employeeNo, navigate]);



  return (
    <>
      <div className="wrap">
        <div className="title-border mb20">
          <h1 className="title">ANIPLEXユーザー新規登録</h1>
          <p className="link-pageback deco-line c-aniplex">
            <Link to='/aniplex/anxUserList'>
              <i className="icn pageback"></i>ANIPLEXユーザー一覧へ戻る
            </Link>
          </p>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: 80 }}>社員番号</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="input-form wdt200">
                <input type="text" name="employeeNo" title="社員番号を入力してください"
                aria-label="社員番号" required
                ref={formRefs.current.employeeNo}
                value={formData.employeeNo}
                maxLength={6}
                onChange={(event) => handleChange('employeeNo', event.target.value)}
                />
              </div>
              <ErrorMessageList messages={messages.employeeNo} />
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: 80 }}>ロール</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="form-select wdt200">
                <select name="role" title="ロールを選択してください" required
                onChange={(event) => handleChange('role', event.target.value)}>
                {
                  Object.entries(Constants.Aniplex.UserRoleName).map(
                    ([key, value]) => <option value={key} key={`opt${key}`}>{value}</option>
                  )
                }
                </select>
              </div>
              <ErrorMessageList messages={messages.role} />
            </dd>
          </dl>
        </div>

        <div className="l-buttons mt20">
          <p className="btn label bg-orange" style={{ width: 190 }}>
            <button onClick={() => onSubmit()}>
              <i className="icn check"></i>ユーザー登録
            </button>
          </p>
        </div>
      </div>
      {loading}
    </>
  );
}

/**
 * バリデート
 * @param {*} name
 * @param {*} value
 */
export function validate(name, value) {
  const errors = [];
  // 社員番号
  if (name === 'employeeNo') {
    // 必須
    if(isEmpty(value)) {
      return [getMessage('isNotEmpty')];
    } else {
      // 半角英数6文字
      if (!lengthRange(value, 6, 6) || !isAlphanumeric(value)) {
        errors.push(getMessage('isFixedAlphanumeric', {digit: 6}));
      }
    }
  }
  // ロール
  if (name === 'role') {
    if(isEmpty(value)) {
      return [getMessage('isNotEmpty')];
    } else if (!Object.values(Constants.Aniplex.UserRole).includes(value)) {
      // 不正な値
      return ['不正な値です'];
    }
  }
  return errors;
}
