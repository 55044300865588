import { basename, scrollToPageTop } from '../../lib/util.js';
import { useState, useCallback, useMemo } from 'react';
import { TermsPopup } from './TermsPopup.jsx';
import { PrivacyPopup } from './PrivacyPopup.jsx';
import { downloadFileRetry } from '../../lib/api/licensee.js';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../slices/licensee/utilSlice.js';
import { LoadingOverlay } from '../common/LoadingOverlay.jsx';
import axios from 'axios';

/**
 * ライセンシー側フッター
 */
export const LicenseeFooter = () => {
  const dispatch = useDispatch();

  // プライバシーポリシー表示ポップアップ
  const [privacyPopup, setPrivacyPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {import('./PrivacyPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });
  // 利用規約表示ポップアップ
  const [termsPopup, setTermsPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {import('./TermsPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });

  // マニュアルダウンロード通信中フラグ
  const [manualDownloading, setManualDownloading] = useState(false);

  /** プライバシーポリシー表示リンク押下時のハンドラ */
  const onPrivacyPopupClick = useCallback(() => {
    setPrivacyPopup({
      showFlg: true,
      onClose: () => {
        setPrivacyPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, []);
  /** 利用規約表示リンク押下時のハンドラ */
  const onTermsPopupClick = useCallback(() => {
    setTermsPopup({
      showFlg: true,
      onClose: () => {
        setTermsPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, []);

  /** マニュアルダウンロードリンク押下時のハンドラ */
  const onManualDownload = useCallback(async () => {
    setManualDownloading(true);
    try {
      const link = await downloadFileRetry('', 'manual');

      // そのままリンクするとブラウザ内で開いてしまうので
      // DataURI化してリンクに設定する
      const fileRes = await axios.get(link, { responseType: 'arraybuffer' });
      const dataUri = await new Promise(res => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          res(reader.result);
        });
        reader.readAsDataURL(new Blob([fileRes.data]));
      })
      const anchor = document.createElement('a');
      anchor.href = dataUri;
      anchor.download = decodeURIComponent(basename(new URL(link).pathname));
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
    } catch {
      dispatch(pushMessage('ファイルのダウンロードに失敗しました。'));
    } finally {
      setManualDownloading(false);
    }
  }, [dispatch]);

  // ローディング表示
  const loading = useMemo(() => {
    if (manualDownloading) {
      return <LoadingOverlay />;
    }
    return null;
  }, [manualDownloading]);

  return (
    <>
      {
        privacyPopup.showFlg ?
          <PrivacyPopup onClose={privacyPopup.onClose} /> :
          null
      }
      {
        termsPopup.showFlg ?
          <TermsPopup onClose={termsPopup.onClose} /> :
          null
      }
      <footer className="l-footer mt30">
        <div className="wrap">
          <p className="l-copyright"><small>&copy; Aniplex Inc. All rights reserved.</small></p>
          <p style={{width: '50%'}}></p>
          <p>
            <button onClick={onManualDownload} style={{fontSize:'1.4rem'}}>ROYSご利用マニュアル</button>
          </p>
          <p><button onClick={onPrivacyPopupClick} style={{fontSize:'1.4rem'}}>プライバシーポリシー</button>
          </p>
          <p><button onClick={onTermsPopupClick} style={{fontSize:'1.4rem'}}>利用規約</button></p>
          <p className="js-pagetop l-pagetop" tabIndex="0" onClick={scrollToPageTop}><i className="icn pagetop"></i>ページの先頭へ</p>
        </div>
      </footer>

      {loading}
    </>
  );
};
