import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'
import { Constants } from '../../../Constants';
import { selectPropertyMst } from '../../../slices/aniplex/masterSlice'
import { ProposalProductsForm } from './ProposalProductsForm';
import { ProposalSdecisionForm } from './ProposalSdecisionForm';
import { RyRemindReqPopup } from './RyRemindReqPopup';
import { selectMyself } from '../../../slices/aniplex/usersSlice';

/** 締め月のプロパティ名 */
const closingMonthProps = [
  'ryReq1', 'ryReq2', 'ryReq3',
  'ryReq4', 'ryReq5', 'ryReq6',
  'ryReq7', 'ryReq8', 'ryReq9',
  'ryReq10', 'ryReq11', 'ryReq12',
];

/**
 * ライセンシー向け企画状況参照画面のフォーム
 * @param {object} props
 * @param {Proposal} props.proposal 対象の企画情報
 * @param {Function} props.requestReload 企画情報の再読込要求ハンドラ
 * @returns
 */
export const ProposalStatusForm = ({ proposal, requestReload }) => {
  const myself = useSelector(selectMyself);

  /** 更新系ボタン表示フラグ */
  const updateBtnVisible = useMemo(() => {
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
  }, [myself?.role]);


  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertyMst);

  /** 作品名 */
  const propertyName = useMemo(() => {
    return propertyMst
      .find(p => p.propertySummaryCode === proposal?.propertySummaryCode)
      ?.propertySummaryName ?? '';
  }, [propertyMst, proposal?.propertySummaryCode]);

  /** ロイヤリティ報告区分 */
  const ryReportCategoryText = useRyReportCategoryText(proposal);

  /** ロイヤリティ報告督促要否ポップアップのコールバック */
  const [ryRemindReqPopup, setRyRemindReqPopup] = useState({
    showFlg: false,
    onClose: null,
    proposal: [],
  });
  const onRyRemindReqPopup = useCallback(() => {
    setRyRemindReqPopup({
      showFlg: true,
      proposal: proposal,
      onClose: (btn) => {
        if (btn === 'submit') {
          // 企画情報再読込
          requestReload();
        }
        setRyRemindReqPopup({
          showFlg: false,
          onClose: null,
          proposal: [],
        });
      }
    });
  }, [proposal, requestReload]);

  return (
    <>
      <section className="mt30">
        <div className="title-aniplex">
          <h2 className="title"><i className="icn note"></i>企画内容</h2>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>作品名称</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="作品名称"
                  aria-label="作品名称"
                  disabled
                  value={propertyName} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>企画件名</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="企画件名"
                  aria-label="企画件名"
                  disabled
                  value={proposal?.proposalTitle ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>販売開始希望日</dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input type="text" name="販売開始希望日"
                  aria-label="販売開始希望日"
                  disabled
                  value={proposal?.proposalLaunchDate ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">申請者</dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input type="text" name="申請者"
                  aria-label="申請者"
                  disabled
                  value={proposal?.applicantUserName ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>企画説明</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="企画説明"
                  aria-label="企画説明"
                  disabled
                  value={proposal?.proposalDetail ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set" style={{ marginLeft: 40 }}>
            <dt className="form-name" style={{ width: '134px' }}>その他備考</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="その他備考"
                  aria-label="その他備考"
                  disabled
                  value={proposal?.proposalRemarks ?? ''} />
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title"><i className="icn calendar02"></i>ロイヤリティ報告締め月</h2>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '122px' }}>報告締め月</dt>
            <dd className="form-body">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>{ryReportCategoryText}</span>
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '122px' }}>督促要否</dt>
          <dd className="form-body dif a-center">
            {
              Object.keys(Constants.Aniplex.ProposalRyRemindReq).map(i =>
                Number(i) === Number(proposal?.ryRemindReq) &&
                <p key={`check${i}`}>
                  {Constants.Aniplex.ProposalRyRemindReq[i]}
                </p>
              )
            }
            {
              updateBtnVisible ?
              <p className="btn small ml20 bg-yellow" style={{ width: '60px' }}>
                <button onClick={onRyRemindReqPopup}>変更</button>
              </p> : null
            }
          </dd>
        </dl>
        </div>

        {
          updateBtnVisible && ryRemindReqPopup.showFlg ?
            <RyRemindReqPopup
              proposalId={proposal?.proposalId}
              proposal={ryRemindReqPopup.proposal}
              onClose={ryRemindReqPopup.onClose} /> : null
        }


      </section>

      <ProposalSdecisionForm
        proposal={proposal}
        requestReload={requestReload} />

      <ProposalProductsForm
        proposal={proposal}
        updateBtnVisible={updateBtnVisible} />
    </>
  )
}

/**
 * 選択されたロイヤリティ報告区分の表示用テキスト
 * @param {Proposal|undefined} proposal 企画情報
 */
const useRyReportCategoryText = (proposal) => {
  return useMemo(() => {
    if (proposal == null) {
      return '';
    }

    switch (proposal.ryReportCategory) {
      case Constants.Aniplex.RyReportCategory.Monthly:
        return '毎月';
      case Constants.Aniplex.RyReportCategory.Any:
        return '任意';
      case Constants.Aniplex.RyReportCategory.Specified:
        const selectedMonths = closingMonthProps
          .map((prop, idx) => ({
            name: `${idx + 1}月`,
            checked: proposal[prop],
          }))
          .filter(val => val.checked)
          .map(val => val.name)
          .join(',');
        return `指定月（${selectedMonths}）`;
      default:
        return '';
    }
  }, [proposal]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} Proposal 企画情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalPeriod} ProposalPeriod 第N期の情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProduct} Product 商品情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProductPeriod} ProductPeriod 商品の第N期の情報
 */
//#endregion typedef
