import { useCallback, useMemo, useRef } from 'react';
import dayjs from 'dayjs';

/** 新規お知らせ期間 */
const newAnnouncementPeriod = 7;

/**
 * お知らせ一覧のテーブル
 * current:現在のページ,pageSize:1ページあたりの項目数が必須
 * @returns
 */
export const AnnouncementTable = ({
  records = [],
  current,
  pageSize,
  pageItemCount = 11,
  emptyMessage,
  onPageChange,
  onOpenDetail,
}) => {
  // 分割数
  pageSize = Math.max(pageSize, 1);

  const scrollAreaRef = useRef(null);

  // ページ数
  const maxPage = Math.ceil(records.length / pageSize);

  // ページ番号(1～ページ数分)
  current = Math.max(current, 1);
  current = Math.min(current, maxPage);

  // ページ番号が1 or 最後か
  const isFirst = current === 1;
  const isLast = current === maxPage;

  // ページジングボタンクリック時の処理
  const onClick = useCallback(value => {
    // 変化なし or 範囲外の時は何もしない
    if (value === current || value <= 0 || value > maxPage) {
      return;
    }
    // 新しいページ番号をコールバックに渡す
    if (onPageChange) {
      if (scrollAreaRef && scrollAreaRef.current) {
        window.currenter = scrollAreaRef.current;
        scrollAreaRef.current.scrollTo(scrollAreaRef.current.scrollLeft, 0);
      }
      onPageChange(value);
    }
  }, [current, maxPage, onPageChange]);

  // ページ番号の表示
  const pageItems = useMemo(() => {
    const DOT = -1;

    // 1～最終ページの廃立を作成
    let pages = [];
    for (let i = 1; i <= maxPage; i++) {
      pages.push(i);
    }

    // pageItemCountの数になるように切り抜く
    if (pages.length > pageItemCount) {
      const half = Math.floor(pageItemCount / 2);
      const left = Math.min(Math.max(current - 1 - half, 0), maxPage - pageItemCount);
      const right = left + pageItemCount;
      pages = pages.slice(left, right);
    }

    // 端の処理(左端は1、右端はmaxPageとして、省略された箇所にDOTを指定)
    if (pages[0] !== 1) {
      pages[0] = 1;
      pages[1] = DOT;
    }
    if (pages[pages.length - 1] !== maxPage) {
      pages[pages.length - 1] = maxPage;
      pages[pages.length - 2] = DOT;
    }

    // JSXの構築
    return pages.map((i, idx) => {
      if (i === DOT) {
        return (
          <li key={'dot' + idx}>…</li>
        );
      }
      const isCurrent = i === current;
      return (
        // 現在のページであればクラス名を付与
        <li className={isCurrent ? 'page-active' : ''} key={i}>
          <a disabled={isCurrent} onClick={!isCurrent ? () => onClick(i) : null}>{i}</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
        </li>
      );
    });
  }, [current, maxPage, pageItemCount, onClick]);

  /** お知らせ件名押下時のハンドラ */
  const onTitleClick = useCallback((data) => {
    if (typeof onOpenDetail === 'function') {
      onOpenDetail(data);
    }
  }, [onOpenDetail]);

  // ページ内の行のみに絞る
  const pageRecords = useMemo(() => {
    const tartget = records.slice((current - 1) * pageSize, current * pageSize);
    const newPeriod = dayjs().subtract(newAnnouncementPeriod, 'day');

    return tartget.map((row, i) => {
      const regDate = dayjs(row.announcementStartDatetime, 'YYYY/MM/DD HH:mm:ss');

      return  <tr key={i}>
                <td style={{width: '110px'}}>{regDate.format('YYYY/MM/DD')}</td>
                <td style={{minWidth: '500px'}}>
                  <button className="titleLink"
                    onClick={() => onTitleClick(row)}
                    style={{ textAlign: 'left' }}
                  >{row.announcementTitle}</button>
                  {
                    // 公開開始日時から7日以内
                    regDate.isAfter(newPeriod) && (
                      <span className="new">NEW</span>
                    )
                  }
                </td>
              </tr>
    });
  }, [records, current, pageSize, onTitleClick]);

  // 全体の表示
  return (
    <>
      {
        (records && records.length) ?
          <>
            <div className="l-announce">
              <table className="list-announce">
                <tbody>
                  {pageRecords}
                </tbody>
              </table>
            </div>
            {maxPage >= 2 && (
              <div className="l-pager">
                <ul className="list-pager">
                  <li className="page-prev">
                    <a style={isFirst ? disabledStyle : null}onClick={!isFirst ? () => onClick(current - 1) : null}>prev</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
                  </li>
                  {pageItems}
                  <li className="page-next">
                    <a style={isLast ? disabledStyle : null} onClick={!isLast? () => onClick(current + 1) : null}>next</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
                  </li>
                </ul>
              </div>
            )}
          </>
          : <p className="mt10">{emptyMessage}</p>
        }
    </>
  );
};

const disabledStyle = {
  backgroundColor: '#f4f4f4',
  borderColor: 'transparent',
  cursor: 'default',
  opacity: '0.6',
};
