import { MessagePopup } from "../../common/MessagePopup";

/**
 * 企画の削除用ポップアップ
 * @param {object} props
 * @param {OnClose} props.onClose 閉じるときのコールバック
 * @returns
 */
export const ProposalDeletePopup = ({ onClose }) => {
  const message = (
    <span style={{ display: 'block', width: '330px' }}>
      企画、企画に紐づく証紙申請、企画に紐づくロイヤリティ報告の全データを削除します。よろしいですか？<br />
      <span className="attention" style={{ color: '#f00', fontWeight: 'bold' }}>
        注意：削除後、データの復元はできません。また、承認済みの証紙申請、ロイヤリティ報告が存在する場合は削除できません。
      </span>
    </span>
  );
  return (
    <MessagePopup btnClass="bg-aniplex" btn={{ok: '削除', cancel: 'キャンセル'}} onClose={onClose} message={message} />
  );
}

//#region
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'ok'|'cancel'} btn 閉じるときに押されたボタン
 */
//#endregion
