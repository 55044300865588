//@ts-check
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSDecisionDetail } from "../../lib/api/aniplex";
import { handleAniplexApiError } from "../../lib/util";
import { ApiErrorMessage } from "../../lib/message";

/** 初期値 */
const initialState = {
  /** @type {?SDecisionDetail} S決裁詳細 */
  sDecisionDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** S決裁詳細取得処理 */
    fetchSDecisionDetail: null,
  },
};

/**
 * S決裁詳細取得処理
 */
export const fetchSDecisionDetail = createAsyncThunk(
  'aniplex/sDecisions/fetchSDecisionDetail',
  /**
   * S決裁詳細取得処理
   * @param {string} sDecisionNo S決裁No
   */
  async (sDecisionNo, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearSDecisionDetail());

    try {
      const data = await getSDecisionDetail(sDecisionNo);
      return data;
    } catch (err) {
      handleAniplexApiError(err, thunkApi.dispatch, {
        // データ不正
        '13801': ApiErrorMessage.DataNotExists,
      })
    }
  }
)

/**
 * ANIPLEX向け画面のS決裁スライス
 */
export const sDecisionsSlice = createSlice({
  name: 'aniplex/sDecisions',
  initialState,
  reducers: {
    /**
     * 取得済みのS決裁詳細をクリアする
     * @param {typeof initialState} state
     */
    clearSDecisionDetail: (state) => {
      state.sDecisionDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // S決裁詳細取得処理
        case 'fetchSDecisionDetail':
          state.apiStatus.fetchSDecisionDetail = null;
          break;
        default:
          /** @type {never} */
          // eslint-disable-next-line no-unused-vars
          const _check = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // S決裁詳細取得処理
      .addCase(fetchSDecisionDetail.pending, (state) => {
        state.apiStatus.fetchSDecisionDetail = 'loading';
      })
      .addCase(fetchSDecisionDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchSDecisionDetail = 'finish';
        state.sDecisionDetail = action.payload;
      })
      .addCase(fetchSDecisionDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchSDecisionDetail = null;
      });
  }
});

export const {
  clearSDecisionDetail,
  clearApiStatus,
} = sDecisionsSlice.actions;

export default sDecisionsSlice.reducer;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.sDecisions.apiStatus;

/**
 * stateからS決裁詳細を取得する
 * @param {*} state
 * @returns {?SDecisionDetail} S決裁詳細
 */
export const selectSDecisionDetail = (state) => state.aniplex.sDecisions.sDecisionDetail;

//#region typedef
/**
 * @typedef {'fetchSDecisionDetail'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} SDecisionDetail S決裁詳細
 * @property {string} sTitle 件名
 * @property {number} ad アドバンス
 * @property {number} mg MG
 * @property {SDecisionProposal[]} proposalList 契約に紐づく企画一覧
 * @property {ApprovedRyReport[]} appRyReportList 受領済みのロイヤリティ報告一覧
 * @property {RequestingRyReport[]} reqRyReportList 申請中のロイヤリティ報告一覧
 */
/**
 * @typedef {object} SDecisionProposal S決裁に紐づく企画
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 */
/**
 * @typedef {object} ApprovedRyReport 受領済みRY報告
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportNo ロイヤリティ報告書No
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {string} approvalDatetime 承認日時(YYYY/MM/DD)
 */
/**
 * @typedef {object} RequestingRyReport 申請中RY報告
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportNo ロイヤリティ報告書No
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {string} reportDatetime 報告日時(YYYY/MM/DD)
 */
//#endregion
