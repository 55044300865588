import { Fragment } from 'react';

/**
 * 改行文字を<br>に変換する
 */
export const nl2br = s => {
  if (String(s || '').indexOf('\n') === -1) {
    return s; // 改行なしはそのまま返却
  }
  return String(s || '').split('\n').map((s, i) => (
    <Fragment key={i}>
      {i === 0 ? null : <br />}
      {s}
    </Fragment>
  ));
}
