//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Constants } from '../../../Constants'
import { selectMyself } from '../../../slices/aniplex/usersSlice'
import {
  updateProposalStatus,
  selectApiStatus as selectProposalApiStatus,
  clearApiStatus as clearProposalApiStatus,
} from '../../../slices/aniplex/proposalsSlice'
import {
  clearApiStatus as utilClearApiStatus,
  clearFileDownloadUrl,
  fetchDownloadUrl,
  selectApiStatus as utilSelectApiStatus,
  selectFileDownloadUrl
} from '../../../slices/aniplex/utilSlice'
import { pushMessage } from '../../../slices/aniplex/utilSlice'
import { MessagePopup } from '../../common/MessagePopup'
import { AssignUserPopup } from './AssignUserPopup'
import { ProposalClosingMonthForm } from './ProposalClosingMonthForm'
import { ProposalContentForm } from './ProposalContentForm'
import { ProposalOverviewForm } from './ProposalOverviewForm'
import { ProposalProductsForm } from './ProposalProductsForm'
import { ProposalSurveyForm } from './ProposalSurveyForm'
import { LoadingOverlay } from '../../common/LoadingOverlay'
import { ProposalRejectPopup } from './ProposalRejectPopup'
import { ProposalSuspendPopup } from './ProposalSuspendPopup'
import { AssignSDecisionPopup } from './AssignSDecisionPopup'
import { ErrorMessageList } from '../../common/ErrorMessageList'
import { UpdateContractPopup } from './UpdateContractPopup'
import { AutoContractUpdatePopup } from './AutoContractUpdatePopup'
import { ProposalDeletePopup } from './ProposalDeletePopup'
import { MessageHistoryArea } from './MessageHistoryArea'

/**
 * 企画詳細画面のフォーム部分
 * @param {object} props
 * @param {Proposal} props.proposal 企画情報
 * @param {Function} props.requestReload 企画情報の再読込要求ハンドラ
 */
export const ProposalDetailForm = ({ proposal, requestReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myself = useSelector(selectMyself);
  const fileDownloadUrl = useSelector(selectFileDownloadUrl);
  const proposalApiStatus = useSelector(selectProposalApiStatus);
  const utilApiStatus = useSelector(utilSelectApiStatus);
  // エラーメッセージ
  const [messages, setMessages] = useState({
    /** 企画担当者社員番号 */
    kikakuEmployeeNo: /** @type {string[]} */ ([]),
    /** S決裁Np */
    sDecisionNo: /** @type {string[]} */ ([]),
  });
  // ステータス更新系ポップアップ
  const [statusPopup, setStatusPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {string} メッセージ */
    message: '',
    /** @type {string} OKボタン */
    ok: '',
    /** @type {?(btn: 'ok'|'cancel'|'close') => void} 閉じるときのコールバック */
    onClose: null,
  });
  // 差し戻しポップアップ
  const [rejectPopup, setRejectPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?(btn: 'ok'|'cancel', messageContent?: string) => void} 閉じるときのコールバック */
    onClose: null,
  });
  // 中止ポップアップ
  const [suspendPopup, setSuspendPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?(btn: 'ok'|'cancel', messageContent?: string) => void} 閉じるときのコールバック */
    onClose: null,
  });
  // 削除ポップアップ
  const [deletePopup, setDeletePopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?(btn: 'ok'|'cancel', deleteMessage?: string) => void} 閉じるときのコールバック */
    onClose: null,
  });
  // 現在のステータス更新種別
  const [statusUpdType, setStatusUpdType] = useState(/** @type {?UpdateType} */ (null));
  // 企画担当者設定ポップアップ
  const [assignUserPopup, setAssignUserPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?import('./AssignUserPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });
  // S決裁No設定ポップアップ
  const [assignSDecisionPopup, setAssignSDecisionPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?import('./AssignSDecisionPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });
  // 契約期間変更ポップアップ
  const [updateContractPopup, setUpdateContractPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?import('./UpdateContractPopup').OnClose} 閉じるときのコールバック */
    onClose: null
  });
  // 自動契約更新ポップアップ
  const [autoContractUpdatePopup, setAutoContractUpdatePopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {?import('./AutoContractUpdatePopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    return proposalApiStatus.updateProposalStatus === 'loading';
  }, [proposalApiStatus.updateProposalStatus]);

  /** 更新系ボタン表示フラグ */
  const updateBtnVisible = useMemo(() => {
    return /** @type {string[]} */ ([
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ]).includes(myself?.role ?? '');
  }, [myself?.role]);

  /** 契約終了日設定ボタン表示フラグ */
  const updateContractBtnVisible = useMemo(() => {
    return /** @type {string[]} */ ([
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ]).includes(myself?.role ?? '')
      // 承認済み（APP）、契約終了(未報告)（FNR）、契約終了（FIN）の企画のみ表示許容
    && /** @type {string[]} */ ([
      Constants.Aniplex.ProposalStatus.Approved,
      Constants.Aniplex.ProposalStatus.FinishNotReported,
      Constants.Aniplex.ProposalStatus.Finished,
    ]).includes(proposal?.proposalStatus)
  }, [myself?.role, proposal?.proposalStatus]);

  /** 自動更新設定ボタン表示フラグ */
  const autoContractUpdateBtnVisible = useMemo(() => {
    return /** @type {string[]} */ ([
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ]).includes(myself?.role ?? '')
      // 承認済み（APP）の企画のみ表示許容
      && proposal?.proposalStatus === Constants.Aniplex.ProposalStatus.Approved;
  }, [myself?.role, proposal?.proposalStatus]);

  /** 承認可能フラグ */
  const canApprove = useMemo(() => {
    return proposal?.proposalStatus === Constants.Aniplex.ProposalStatus.Requesting
      && [messages.kikakuEmployeeNo, messages.sDecisionNo].flat().length === 0;
  }, [messages.kikakuEmployeeNo, messages.sDecisionNo, proposal?.proposalStatus]);
  /** 差し戻し可能フラグ */
  const canReject = useMemo(() => {
    return proposal?.proposalStatus === Constants.Aniplex.ProposalStatus.Requesting;
  }, [proposal?.proposalStatus]);
  /** 中止可能フラグ */
  const canSuspend = useMemo(() => {
    return proposal?.proposalStatus === Constants.Aniplex.ProposalStatus.Approved;
  }, [proposal?.proposalStatus]);

  // データ取得時に承認向けの入力チェックを更新
  useEffect(() => {
    const newMessages = {};
    if (proposal?.kikakuEmployeeNo) {
      newMessages.kikakuEmployeeNo = [];
    }
    if (proposal?.sDecision?.sDecisionNo) {
      newMessages.sDecisionNo = [];
    }
    setMessages(prev => ({
      ...prev,
      ...newMessages
    }));
  }, [proposal?.kikakuEmployeeNo, proposal?.sDecision?.sDecisionNo]);

  /** 担当者設定ボタン押下時のハンドラ */
  const onAssignUserClick = useCallback(() => {
    setAssignUserPopup({
      showFlg: true,
      onClose: btn => {
        if (btn === 'submit') {
          // 企画情報をリロード
          requestReload();
        }
        setAssignUserPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [requestReload]);
  /** S決裁No設定ボタン押下時のハンドラ */
  const onAssignSDecisionClick = useCallback(() => {
    setAssignSDecisionPopup({
      showFlg: true,
      onClose: btn => {
        if (btn === 'submit') {
          // 企画情報をリロード
          requestReload();
        }
        setAssignSDecisionPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [requestReload]);

  /** 契約終了日設定ボタン押下時のハンドラ */
  const onUpdateContractClick = useCallback(() => {
    setUpdateContractPopup({
      showFlg: true,
      onClose: btn => {
        if (btn === 'submit') {
          // 企画情報をリロード
          requestReload();
        }
        setUpdateContractPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [requestReload]);

  /** 自動契約更新設定ボタン押下時のハンドラ */
  const onAutoContractUpdateClick = useCallback(() => {
    setAutoContractUpdatePopup({
      showFlg: true,
      onClose: btn => {
        if (btn === 'submit') {
          // 企画情報をリロード
          requestReload();
        }
        setAutoContractUpdatePopup({
          showFlg: false,
          onClose: null,
        });
      },
    })
  }, [requestReload]);

  /** 承認ボタン押下時のハンドラ */
  const onApprove = useCallback(() => {
    const errors = {
      kikakuEmployeeNo: !(proposal?.kikakuEmployeeNo) ? ['承認するには企画担当者の設定が必要です'] : [],
      sDecisionNo: !(proposal?.sDecision?.sDecisionNo) ? ['承認するにはS決裁Noの設定が必要です'] : [],
    };
    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      return;
    }

    setStatusPopup({
      showFlg: true,
      message: '承認を行います。よろしいですか。',
      ok: '承認',
      onClose: (btn) => {
        if (btn === 'ok') {
          // 承認処理
          const targetStatus = Constants.Aniplex.ProposalStatus.Approved;
          dispatch(updateProposalStatus({
            proposalId: proposal.proposalId,
            proposalStatus: targetStatus,
            updateDatetime: proposal.updateDatetime,
          }));
          setStatusUpdType(targetStatus);
        }
        setStatusPopup({
          showFlg: false,
          message: '',
          ok: '',
          onClose: null,
        });
      }
    })
  }, [dispatch, proposal?.kikakuEmployeeNo, proposal?.proposalId, proposal?.sDecision?.sDecisionNo, proposal?.updateDatetime]);
  // 承認処理のハンドリング
  useEffect(() => {
    if (statusUpdType === Constants.Aniplex.ProposalStatus.Approved) {
      if (proposalApiStatus.updateProposalStatus === 'finish') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('承認を行いました。'));
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        // 企画情報のリロード
        requestReload();
        return;
      }
      if (proposalApiStatus.updateProposalStatus === 'error') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('承認の実行中にエラーが発生しました。'));
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        return;
      }
    }
  }, [dispatch, proposalApiStatus.updateProposalStatus, requestReload, statusUpdType]);

  /** 差し戻しボタン押下時のハンドラ */
  const onReject = useCallback(() => {
    setRejectPopup({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          // 差し戻し処理
          const targetStatus = Constants.Aniplex.ProposalStatus.Rejected;
          dispatch(updateProposalStatus({
            proposalId: proposal.proposalId,
            proposalStatus: targetStatus,
            updateDatetime: proposal.updateDatetime,
            messageContent,
          }));
          setStatusUpdType(targetStatus);
        }
        setRejectPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [dispatch, proposal?.proposalId, proposal?.updateDatetime]);
  // 差し戻し処理のハンドリング
  useEffect(() => {
    if (statusUpdType === Constants.Aniplex.ProposalStatus.Rejected) {
      if (proposalApiStatus.updateProposalStatus === 'finish') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('差し戻しを行いました。'))
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        // 企画情報のリロード
        requestReload();
        return;
      }
      if (proposalApiStatus.updateProposalStatus === 'error') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('差し戻しの実行中にエラーが発生しました。'));
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        return;
      }
    }
  }, [dispatch, proposalApiStatus.updateProposalStatus, requestReload, statusUpdType])

  /** 中止ボタン押下時のハンドラ */
  const onSuspend = useCallback(() => {
    setSuspendPopup({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          // 中止処理
          const targetStatus = Constants.Aniplex.ProposalStatus.Suspended;
          dispatch(updateProposalStatus({
            proposalId: proposal.proposalId,
            proposalStatus: targetStatus,
            updateDatetime: proposal.updateDatetime,
            messageContent,
          }));
          setStatusUpdType(targetStatus);
        }
        setSuspendPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [dispatch, proposal?.proposalId, proposal?.updateDatetime]);
  // 中止処理のハンドリング
  useEffect(() => {
    if (statusUpdType === Constants.Aniplex.ProposalStatus.Suspended) {
      if (proposalApiStatus.updateProposalStatus === 'finish') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('中止を行いました。'))
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        // 企画情報のリロード
        requestReload();
        return;
      }
      if (proposalApiStatus.updateProposalStatus === 'error') {
        // メッセージを表示してAPIステータスをクリア
        dispatch(pushMessage('中止の実行中にエラーが発生しました。'));
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        return;
      }
    }
  }, [dispatch, proposalApiStatus.updateProposalStatus, requestReload, statusUpdType])

  /** ダウンロードボタン押下時のハンドラ */
  const onDownloadClick = useCallback(() => {
    if (utilApiStatus.fetchDownloadUrl === 'loading') {
      // ダウンロードAPI通信中は処理しない
      return;
    }
    // ファイルダウンロードURL取得要求
    dispatch(fetchDownloadUrl({ filetype: 'application', fileNo: proposal?.proposalNo }));
  }, [dispatch, proposal?.proposalNo, utilApiStatus.fetchDownloadUrl]);
  // ダウンロード処理のハンドリング
  useEffect(() => {
    if (utilApiStatus.fetchDownloadUrl === 'finish' && fileDownloadUrl) {
      // ダウンロードURLを取得出来たらAPIステータスをクリアしてそのURLを開く
      dispatch(utilClearApiStatus('fetchDownloadUrl'));
      window.location.href = fileDownloadUrl;
      dispatch(clearFileDownloadUrl());
      return;
    }
    if (utilApiStatus.fetchDownloadUrl === 'error') {
      // メッセージを表示してAPIステータスをクリア
      dispatch(pushMessage('ファイルのダウンロードに失敗しました。'))
      dispatch(utilClearApiStatus('fetchDownloadUrl'));
      return
    }
  }, [dispatch, fileDownloadUrl, utilApiStatus.fetchDownloadUrl]);

  // 削除ボタン押下時のハンドラ
  const onDeleteClick = useCallback(() => {
    setDeletePopup({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          // 削除
          const targetStatus = Constants.Aniplex.ProposalStatus.Deleted;
          dispatch(updateProposalStatus({
            proposalId: proposal.proposalId,
            proposalStatus: targetStatus,
            updateDatetime: proposal.updateDatetime,
          }));
          setStatusUpdType(targetStatus);
        }
        setDeletePopup({
          showFlg: false,
          onClose: null,
        });
      }
    })
  }, [dispatch, proposal]);
  // 削除処理のハンドリング
  useEffect(() => {
    if (statusUpdType === Constants.Aniplex.ProposalStatus.Deleted) {
      if (proposalApiStatus.updateProposalStatus === 'finish') {
        // メッセージを表示
        dispatch(pushMessage('企画を削除しました。'));
        // APIステータスをクリア
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        // 一覧画面へ遷移
        navigate('/aniplex/proposalList');
        return;
      }
      if (proposalApiStatus.updateProposalStatus === 'error') {
        // APIステータスをクリア
        // NOTE: エラーメッセージはAPIステータスに応じて表示される
        dispatch(pushMessage('削除の実行中にエラーが発生しました。'));
        dispatch(clearProposalApiStatus('updateProposalStatus'));
        return;
      }
    }
  }, [dispatch, navigate, proposalApiStatus.updateProposalStatus, statusUpdType]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    if (proposalApiStatus.updateProposalStatus === 'loading') {
      // @ts-expect-error ts(2786)
      return <LoadingOverlay />
    }
    return null;
  }, [proposalApiStatus.updateProposalStatus]);

  return (
    <>
      {/* @ts-expect-error ts(2786) */}
      <ProposalOverviewForm proposal={proposal} />

      {/* @ts-expect-error ts(2786) */}
      <ProposalContentForm proposal={proposal} />

      {/* @ts-expect-error ts(2786) */}
      <ProposalClosingMonthForm proposal={proposal} />

      {/* @ts-expect-error ts(2786) */}
      <ProposalProductsForm proposal={proposal} />

      {/* @ts-expect-error ts(2786) */}
      <ProposalSurveyForm proposal={proposal} />

      {/* @ts-expect-error ts(2786) */}
      <MessageHistoryArea proposal={proposal} />

      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title">ANIPLEX管理</h2>
        </div>
        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '100px' }}>企画担当者</dt>
            <dd className="form-body">
              <div className="input-form" style={{ width: '240px' }}>
                <input type="text" name="企画担当者"
                  title="企画担当者を入力してください"
                  aria-label="企画担当者"
                  readOnly
                  value={proposal?.kikakuEmployeeName ?? ''} />
              </div>
              {
                updateBtnVisible ? (
                  <p className="btn small ml20 bg-yellow" style={{ width: '80px' }}>
                    <button
                      disabled={formLocked}
                      onClick={onAssignUserClick}
                    >設定</button>
                  </p>
                ) : null
              }
            </dd>
          </dl>
        </div>
        {
          messages.kikakuEmployeeNo.length ? (
              <div style={{ marginBottom: '10px' }}>
                {/* @ts-expect-error ts(2786) */}
                <ErrorMessageList messages={messages.kikakuEmployeeNo} />
              </div>
            ) :
            null
        }
        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '100px' }}>S決裁No</dt>
            <dd className="form-body">
              <div className="input-form" style={{ width: '240px' }}>
                <input type="text" name="S決裁No"
                  title="S決裁Noを入力してください"
                  aria-label="S決裁No"
                  readOnly
                  value={proposal?.sDecision?.sDecisionNo ?? ''} />
              </div>
              {
                updateBtnVisible ? (
                  <p className="btn small ml20 bg-yellow" style={{ width: '80px' }}>
                    <button
                      disabled={formLocked}
                      onClick={onAssignSDecisionClick}
                    >設定</button>
                  </p>
                ) : null
              }
            </dd>
          </dl>
        </div>
        {
          messages.sDecisionNo.length ? (
            <div style={{ marginBottom: '10px' }}>
              {/* @ts-expect-error ts(2786) */}
              <ErrorMessageList messages={messages.sDecisionNo} />
            </div>
          ) :
          null
        }

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '100px' }}>契約終了日</dt>
            <dd className="form-body">
              <div className="input-form" style={{ width: '240px' }}>
                <input type="text" name="契約終了日"
                  title='契約終了日'
                  aria-label='契約終了日'
                  readOnly
                  value={proposal?.contractEndDate ?? ''} />
              </div>
              {
                updateContractBtnVisible && (
                  <p className="btn small ml20 bg-yellow" style={{ width: '80px' }}>
                    <button
                      disabled={formLocked}
                      onClick={onUpdateContractClick}
                    >設定</button>
                  </p>
                )
              }
            </dd>
          </dl>
        </div>

        {
          proposal?.automaticContractUpdateReq && (
            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '100px' }}>自動契約更新</dt>
                <dd className="form-body dif a-center">
                  <p style={{ width: '240px' }}>
                    {
                      proposal?.automaticContractUpdateFlag ?
                      '1年ごとに自動契約延長を行う' :
                      '自動契約延長を行わない（終了する）'
                    }
                  </p>
                  {
                    autoContractUpdateBtnVisible && (
                      <p className="btn small ml20 bg-yellow" style={{ width: '80px' }}>
                        <button
                          disabled={formLocked}
                          onClick={onAutoContractUpdateClick}
                        >設定</button>
                      </p>
                    )
                  }
                </dd>
              </dl>
            </div>
          )
        }

        <div className="title-aniplex mt40">
          <h2 className="title">ライセンシー側担当者</h2>
        </div>
        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '100px' }}>企画担当者</dt>
            <dd className="form-body">
              <div className="input-form" style={{ width: '240px' }}>
                <input type="text" name="企画担当者"
                  title="企画担当者は入力済みです"
                  aria-label="企画担当者"
                  disabled
                  value={proposal?.kikakuUserName ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="title-aniplex mt40">
          <h2 className="title">通知先</h2>
        </div>

        <div className="l-table mt20">
          <table>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: 340 }}>氏名</th>
                <th scope="col" style={{ minWidth: 600 }}>メールアドレス</th>
              </tr>
            </thead>
            <tbody>
              {
                (proposal?.noticeUserIdList ?? []).length > 0 ? proposal.noticeUserIdList.map(u => {
                  return (
                    <tr key={u.userId}>
                      <td>{u.username}</td>
                      <td>{u.mailaddress}</td>
                    </tr>
                  );
                }) : (
                  <tr><td colSpan={3}>通知先が設定されていません。</td></tr>
                )
              }
            </tbody>
          </table>
        </div>

        <div className="mt30 pt30 dif j-between bt-solid-gray">
          <div className="l-buttons" style={{ width: '100%' }}>
          {
            updateBtnVisible ? (
              <>
                <p className="btn bg-yellow" style={{ width: '100px' }}>
                  <button
                    onClick={onApprove}
                    disabled={!canApprove || formLocked}
                  >承認</button>
                </p>
                <p className="btn c-aniplex ml10" style={{ width: '100px' }}>
                  <button
                    onClick={onReject}
                    disabled={!canReject || formLocked}
                  >差し戻し</button>
                </p>
                <p className="btn c-aniplex ml10" style={{ width: '100px' }}>
                  <button
                    onClick={onSuspend}
                    disabled={!canSuspend || formLocked}
                  >中止</button>
                </p>
                <p className="btn c-aniplex" style={{ width: '100px' }}>
                  <button onClick={onDeleteClick}><i className="icn cross"></i>削除</button>
                </p>
              </>
            ) : null
          }
          <p className="btn bg-aniplex download" style={{ width: '200px' }}>
            <button onClick={onDownloadClick}>Excel ダウンロード</button>
          </p>
          </div>
        </div>

      </section>

      {
        assignUserPopup.showFlg && assignUserPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <AssignUserPopup
            proposal={proposal}
            onClose={assignUserPopup.onClose} />
        )
      }

      {
        assignSDecisionPopup.showFlg && assignSDecisionPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <AssignSDecisionPopup
            proposal={proposal}
            onClose={assignSDecisionPopup.onClose} />
        )
      }

      {
        updateContractPopup.showFlg && updateContractPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <UpdateContractPopup
            proposal={proposal}
            onClose={updateContractPopup.onClose} />
        )
      }

      {
        autoContractUpdatePopup.showFlg && autoContractUpdatePopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <AutoContractUpdatePopup
            proposal={proposal}
            onClose={autoContractUpdatePopup.onClose} />
        )
      }

      {
        statusPopup.showFlg && statusPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <MessagePopup
            message={statusPopup.message}
            btn={{ ok: statusPopup.ok, cancel: 'キャンセル' }}
            onClose={statusPopup.onClose}
            btnClass='bg-aniplex' />
        )
      }

      {
        rejectPopup.showFlg && rejectPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <ProposalRejectPopup
            onClose={rejectPopup.onClose} />
        )
      }

      {
        suspendPopup.showFlg && suspendPopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <ProposalSuspendPopup
            onClose={suspendPopup.onClose} />
        )
      }

      {
        deletePopup.showFlg && deletePopup.onClose != null && (
          // @ts-expect-error ts(2786)
          <ProposalDeletePopup
            onClose={deletePopup.onClose} />
        )
      }

      {loading}
    </>
  )
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} Proposal 企画情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProduct} Product 許諾商品情報
 */
/**
 * @typedef {'APP'|'REJ'|'SUS'|'DEL'} UpdateType
 */
//#endregion typedef
