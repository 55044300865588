import * as dayjs from 'dayjs';
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authLogin, authCode } from "../../lib/api/licensee";
import { ApiErrorMessage } from '../../lib/message';
import { handleLicenseeApiError } from '../../lib/util';
import { pushMessage } from './utilSlice';
import { HandledApiError } from '../../lib/api/errors/HandledApiError';
import { Constants } from '../../Constants';

/** ストレージ保存キー */
const SaveKey = {
  token: 'licensee_token',
  temporaryToken: 'licensee_temporaryToken',
};

/** 初期値 */
const initialState = {
  /** @type {string|null} アクセストークン */
  token: null,
  /** @type {string|null} 一時トークン */
  temporaryToken: null,
  /** @type {AuthCodeResult|null} 認証コード送信処理のレスポンス */
  authCodeResult: null,
  /** @type {boolean} セッション有効期限切れフラグ */
  sessionExpired: false,
  /** 認証情報の更新状態 */
  passwordUpdateStatus: {
    /** @type {boolean} 初期パスワード変更済みフラグ */
    initialPassword: false,
    /** @type {boolean} 期限切れパスワード変更済みフラグ */
    expiredPassword: false,
  },
  /** API通信状況 */
  apiStatus: {
    /** @type {'loading'|'finish'|'error'|null} ログイン処理通信状況 */
    login: null,
    /** @type {'loading'|'finish'|'error'|null} 認証コード送信処理通信状況 */
    authCode: null,
  },
};

/**
 * store作成時のstateの前準備
 */
export function preloadState() {
  const token = localStorage.getItem(SaveKey.token);
  const temporaryToken = sessionStorage.getItem(SaveKey.temporaryToken);
  return {
    ...initialState,
    token,
    temporaryToken,
  };
}

/**
 * ログイン処理
 */
export const login = createAsyncThunk(
  'licensee/auth/login',
  /**
   * ログイン処理
   * @param {import('../../lib/api/licensee').LoginCredential} credential ログイン情報
   */
  async (credential, { dispatch }) => {
    try {
      const result = await authLogin(credential);
      return { temporaryToken: result.temporaryToken };
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20101': ApiErrorMessage.InvalidCredential,
        // アカウントロック状態
        '20102': ApiErrorMessage.AccountLock,
        // メール送信失敗
        '20103': ApiErrorMessage.SystemError,
      });
    }
  }
);

/**
 * 認証コード送信処理
 */
export const sendCode = createAsyncThunk(
  'licensee/auth/sendCode',
  /**
   * 認証コード送信処理
   * @param {string} loginAuthCode 認証コード
   * @returns
   */
  async (loginAuthCode, { dispatch }) => {
    try {
      const result = await authCode(loginAuthCode);

      return {
        token: result.token,
        result: result.result.data,
      };
    } catch (err) {
      if (err.errorCode === '20203') {
        dispatch(pushMessage('認証コードの有効期限が切れています。もう一度最初からやり直してください。'));
        dispatch(logout());
        throw HandledApiError(err);
      }
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20201': ApiErrorMessage.InvalidAuthCode,
        // アカウントロック状態
        '20202': ApiErrorMessage.AccountLock,
      });
    }
  }
)

/**
 * ログアウト処理
 */
export const logout = createAction('licensee/logout')

/**
 * ライセンシー向け画面の認証周りのスライス
 */
export const authSlice = createSlice({
  name: 'licensee/auth',
  initialState,
  reducers: {
    /**
     * トークンを更新する
     * @param {*} state
     * @param {object} action
     * @param {string} action.payload トークン
     */
    updateToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem(SaveKey.token, state.token);
    },
    /**
     * セッション有効期限切れフラグ設定
     * @param {typeof initialState} state
     * @param {object} action
     * @param {boolean} action.payload 設定する値
     */
    setSessionExpired: (state, action) => {
      state.sessionExpired = !!action.payload;
    },
    /**
     * 初期パスワード変更済みアクション
     * @param {*} state
     */
    initialPasswordUpdated: (state) => {
      state.passwordUpdateStatus.initialPassword = true;
    },
    /**
     * 期限切れパスワード更新済みアクション
     * @param {*} state
     */
    expiredPasswordUpdated: (state) => {
      state.passwordUpdateStatus.expiredPassword = true;
    },
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     * @param {object} action
     * @param {'login'|'authCode'} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        case 'login':
          state.apiStatus.login = null;
          break;
        case 'authCode':
          state.apiStatus.authCode = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ログイン処理
      .addCase(login.pending, (state) => {
        state.apiStatus.login = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.apiStatus.login = 'finish';
        state.temporaryToken = action.payload.temporaryToken;
        sessionStorage.setItem(SaveKey.temporaryToken, state.temporaryToken);
      })
      .addCase(login.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.login = null;
      })
      // 認証コード処理
      .addCase(sendCode.pending, (state) => {
        state.apiStatus.authCode = 'loading';
      })
      .addCase(sendCode.fulfilled, (state, action) => {
        state.apiStatus.authCode = 'finish';

        // トークン周りの設定
        state.token = action.payload.token;
        localStorage.setItem(SaveKey.token, state.token);
        state.temporaryToken = null;
        sessionStorage.removeItem(SaveKey.temporaryToken);

        // APIレスポンスのデータの設定
        const result = action.payload.result;
        const passwordUpdateDatetime = result.passwordUpdateDatetime
          ? dayjs(result.passwordUpdateDatetime, 'YYYY/MM/DD HH:mm:ss').valueOf()
          : null
        state.authCodeResult = {
          passwordUpdateDatetime,
          initialLogin: result.initialLogin,
          passwordExpired: result.passwordExpired,
        };
      })
      .addCase(sendCode.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.authCode = null;
      })
      // ログアウト処理
      .addCase(logout, (state) => {
        // 各種stateをクリア
        state.token = null;
        localStorage.removeItem(SaveKey.token);
        state.temporaryToken = null;
        sessionStorage.removeItem(SaveKey.temporaryToken);
        state.authCodeResult = null;
        state.passwordUpdateStatus = {
          ...initialState.passwordUpdateStatus,
        };

        // 保存済みの検索条件をクリア
        for (const key of Object.values(Constants.Licensee.SearchFormSaveKey)) {
          localStorage.removeItem(key);
        }
      });
  }
});

export const {
  updateToken,
  setSessionExpired,
  initialPasswordUpdated,
  expiredPasswordUpdated,
  clearApiStatus
} = authSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns API通信状況
 */
export const selectApiStatus = (state) => state.licensee.auth.apiStatus;

/**
 * stateから一時トークンを取得する
 * @param {*} state
 * @returns {string} 取得した一時トークン
 */
export const selectTemporaryToken = (state) => state.licensee.auth.temporaryToken;

/**
 * stateからトークンを取得する
 * @param {*} state
 * @returns {string|null} 取得したトークン
 */
export const selectToken = (state) => state.licensee.auth.token;

/**
 * stateから認証コードAPIのresultを取得する
 * @param {*} state
 * @returns {AuthCodeResult|null} 認証コードAPIのresult
 */
export const selectAuthCodeResult = (state) => state.licensee.auth.authCodeResult;

/**
 * stateからセッション有効期限切れフラグを取得する
 * @param {*} state
 * @returns {boolean} セッション有効期限切れフラグ
 */
export const selectSessionExpired = (state) => state.licensee.auth.sessionExpired;

/**
 * stateから認証情報の更新状態を取得する
 * @param {*} state
 * @returns {passwordUpdateStatus} 認証情報の更新状態
 */
export const selectPasswordUpdateStatus = (state) => state.licensee.auth.passwordUpdateStatus;

export default authSlice.reducer;

//#region typedef
/**
 * @typedef {object} AuthCodeResult 認証コードのAPIレスポンスのresult部分
 * @property {number|null} passwordUpdateDatetime パスワード変更日時(Unixタイム(ミリ秒))
 * @property {boolean} initialLogin 初回ログインフラグ
 * @property {boolean} passwordExpired パスワード有効期限切れフラグ
 */
/**
 * @typedef {object} passwordUpdateStatus 認証情報の更新状態
 * @property {boolean} initialPassword 初期パスワード変更済みフラグ
 * @property {boolean} expiredPassword 期限切れパスワード変更済みフラグ
 */
//#endregion
