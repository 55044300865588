import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getLicenseeUsersAll, getLicenseeUsers, patchLicenseeUser, postLicenseeuser } from "../../lib/api/aniplex";
import { HandledError } from "../../lib/api/errors/HandledError";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";
import { pushMessage } from "./utilSlice";

/** 初期値 */
const initialState = {
  fetchAll: null,
  /** @type {LicenseeUser|null} 取得したライセンシーユーザ */
  licenseeUser: null,
  /** @type {string|null} 登録されたユーザID */
  registeredUserId: null,
  /**
   * API通信状況
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** ライセンシーユーザ一覧取得処理
    fetchAll: null,
    /** ライセンシーユーザ取得処理 */
    fetchLicenseeUser: null,
    /** ライセンシーユーザ登録処理 */
    registerLicenseeUser: null,
    /** ライセンシーユーザ更新処理 */
    updateLicenseeUser: null,
    /** ライセンシーユーザ削除処理 */
    deleteLicenseeUser: null,
  },
  /**
   * API実行ID(レスポンスが前後した場合のチェック用)
   * @type {Record<ApiProcessName, number|null>}
   */
  apiCallId: {
    /** ライセンシーユーザ取得処理 */
    fetchLicenseeUser: null,
  }
}

/**
 * ライセンシーユーザ一覧情報取得
 */
export const fetchAll = createAsyncThunk(
  'aniplex/licenseeUsers/fetchAll',
  async (params, { dispatch }) => {
    try {
      const data = await getLicenseeUsersAll(params);
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * ライセンシーユーザ取得処理
 */
export const fetchLicenseeUser = createAsyncThunk(
  'aniplex/licenseeUsers/fetchLicenseeUser',
  /**
   * ライセンシーユーザ取得処理
   * @param {string} userId 対象ユーザID
   */
  async (userId, { dispatch }) => {
    /** @type {import('../../lib/api/aniplex').GetLicenseeUsersParam} */
    const params = {
      userId,
    }
    const callId = Date.now();
    dispatch(licenseeUsersSlice.actions.setApiCallId({
      name: 'fetchLicenseeUser',
      callId,
    }));

    try {
      const result = await getLicenseeUsers(params);
      const user = result.find(u => u.userId === userId);
      if (!user) {
        dispatch(pushMessage(ApiErrorMessage.DataNotExists));
        throw new HandledError();
      }
      return { user, callId };
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * ライセンシーユーザ登録処理
 */
export const registerLicenseeUser = createAsyncThunk(
  'aniplex/licenseeUsers/registerLicenseeUser',
  /**
   * ライセンシーユーザ登録処理
   * @param {import('../../lib/api/aniplex').PostLicenseeUserParam} params APIへのリクエスト内容
   */
  async (params, { dispatch }) => {
    try {
      const result = await postLicenseeuser(params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // 登録失敗
        '12401': ApiErrorMessage.RegisterFailed,
        // メール送信失敗
        '12402': ApiErrorMessage.SystemError,
        // データ重複
        '12403': ApiErrorMessage.DuplicatedUser,
      });
    }
  }
);

/**
 * ライセンシーユーザ更新処理
 */
export const updateLicenseeUser = createAsyncThunk(
  'aniplex/licenseeUsers/updateLicenseeUser',
  /**
   * ライセンシーユーザ更新処理
   * @param {object} args
   * @param {string} args.userId 対象ユーザID
   * @param {import('../../lib/api/aniplex').PatchLicenseeUserParam} args.params APIへの送信内容
   */
  async ({ userId, params }, { dispatch }) => {
    try {
      const result = await patchLicenseeUser(userId, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // 更新状態不一致
        '12501': ApiErrorMessage.UpdateStateMismatch,
        // データ不正
        '12502': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
);

/**
 * ライセンシーユーザ削除処理
 */
export const deleteLicenseeUser = createAsyncThunk(
  'aniplex/licenseeUsers/deleteLicenseeUser',
  /**
   * ライセンシーユーザ削除処理
   * @param {object} args
   * @param {string} args.userId 対象のユーザID
   * @param {string} args.updateDatetime 対象ユーザ情報の最終更新日時
   */
  async ({ userId, updateDatetime }, { dispatch }) => {
    /** @type {import('../../lib/api/aniplex').PatchLicenseeUserParam} */
    const params = {
      deleteFlag: true,
      updateDatetime,
    };

    try {
      const result = await patchLicenseeUser(userId, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // 更新状態不一致
        '12501': ApiErrorMessage.UpdateStateMismatch,
        // データ不正
        '12502': ApiErrorMessage.UpdateStateMismatch,
        // 担当企画存在
        '12503': ApiErrorMessage.UserHasProposalForAniplex,
      });
    }
  }
)

/**
 * ANIPLEX向け画面のライセンシーユーザ周りのスライス
 */
export const licenseeUsersSlice = createSlice({
  name: 'aniplex/licenseeUsers',
  initialState,
  reducers: {
    /**
     * 取得済みのライセンシーユーザ一覧情報をクリアする
     * @param {typeof initialState} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null;
    },
    /**
     * 取得済みのライセンシーユーザ情報をクリアする
     * @param {typeof initialState} state
     */
    clearLicenseeUser: (state) => {
      state.licenseeUser = null;
    },
    /**
     * 登録されたユーザIDをクリアする
     * @param {typeof initialState} state
     */
    clearRegisteredUserId: (state) => {
      state.registeredUserId = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // ライセンシーユーザ一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // ライセンシーユーザ取得処理
        case 'fetchLicenseeUser':
          state.apiStatus.fetchLicenseeUser = null;
          break;
        // ライセンシーユーザ登録処理
        case 'registerLicenseeUser':
          state.apiStatus.registerLicenseeUser = null;
          break;
        // ライセンシーユーザ更新処理
        case 'updateLicenseeUser':
          state.apiStatus.updateLicenseeUser = null;
          break;
        // ライセンシーユーザ削除処理
        case 'deleteLicenseeUser':
          state.apiStatus.deleteLicenseeUser = null;
          break;
        default:
          break;
      }
    },
    /**
     * API呼出しIDを設定する
     * @param {typeof initialState} state
     * @param {object} action
     * @param {object} action.payload
     * @param {ApiProcessName} action.payload.name API呼出し処理名
     * @param {string} action.payload.callId API呼出しID
     */
    setApiCallId: (state, action) => {
      state.apiCallId[action.payload.name] = action.payload.callId;
    }
  },
  extraReducers: builder => {
    builder
      // ライセンシーユーザ一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // ライセンシーユーザ取得処理
      .addCase(fetchLicenseeUser.pending, (state) => {
        state.apiStatus.fetchLicenseeUser = 'loading';
      })
      .addCase(fetchLicenseeUser.fulfilled, (state, action) => {
        state.apiStatus.fetchLicenseeUser = 'finish';
        if (state.apiCallId.fetchLicenseeUser === action.payload.callId) {
          // API呼出しIDが一致した場合のみ値を更新する
          state.licenseeUser = action.payload.user;
        }
      })
      .addCase(fetchLicenseeUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchLicenseeUser = null;
        // 取得済みデータをクリアする
        state.licenseeUser = null;
      })
      // ライセンシーユーザ登録処理
      .addCase(registerLicenseeUser.pending, (state) => {
        state.apiStatus.registerLicenseeUser = 'loading';
      })
      .addCase(registerLicenseeUser.fulfilled, (state, action) => {
        state.apiStatus.registerLicenseeUser = 'finish';
        state.registeredUserId = action.payload.userId;
      })
      .addCase(registerLicenseeUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.registerLicenseeUser = null;
      })
      // ライセンシーユーザ更新処理
      .addCase(updateLicenseeUser.pending, (state) => {
        state.apiStatus.updateLicenseeUser = 'loading';
      })
      .addCase(updateLicenseeUser.fulfilled, (state) => {
        state.apiStatus.updateLicenseeUser = 'finish';
      })
      .addCase(updateLicenseeUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateLicenseeUser = null;
      })
      // ライセンシーユーザ削除処理
      .addCase(deleteLicenseeUser.pending, (state) => {
        state.apiStatus.deleteLicenseeUser = 'loading';
      })
      .addCase(deleteLicenseeUser.fulfilled, (state) => {
        state.apiStatus.deleteLicenseeUser = 'finish';
      })
      .addCase(deleteLicenseeUser.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.deleteLicenseeUser = null;
      });
  }
});

export default licenseeUsersSlice.reducer;

export const {
  clearFetchAll,
  clearLicenseeUser,
  clearRegisteredUserId,
  clearApiStatus,
} = licenseeUsersSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.licenseeUsers.apiStatus;

/**
 * stateから取得済みのライセンシーユーザ一覧を取得する
 * @param {*} state
 * @returns {*} 取得済みのライセンシーユーザ一覧情報
 */
export const fetchAllLicenseeUsers = (state) => state.aniplex.licenseeUsers.fetchAll;

/**
 * stateから取得済みのライセンシーユーザ情報を取得する
 * @param {*} state
 * @returns {LicenseeUser} 取得済みのライセンシーユーザ情報
 */
export const selectLicenseeUser = (state) => state.aniplex.licenseeUsers.licenseeUser;

/**
 * stateから登録されたユーザIDを取得する
 * @param {*} state
 * @returns {string} 登録されたユーザID
 */
export const selectRegisteredUserId = (state) => state.aniplex.licenseeUsers.registeredUserId;

//#region typedef
/**
 * @typedef {'fetchLicenseeUser'|'registerLicenseeUser'|'updateLicenseeUser'|'deleteLicenseeUser'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} LicenseeUser ライセンシーユーザー
 * @property {string} userId ユーザーID
 * @property {string} username 氏名
 * @property {string} mailaddress メールアドレス
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} department 部署名
 * @property {string} regDatetime 登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} loginDatetime 最終ログイン日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} userStatus ユーザ－ステータス
 */
//#endregion typedef
