//@ts-check
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearApiStatus, clearSDecisionDetail, fetchSDecisionDetail, selectApiStatus, selectSDecisionDetail } from '../../../slices/licensee/sDecisionsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiLoadingOverlay } from '../../common/ApiLoadingOverlay';
import { ProposalList } from './ProposalList';
import { ApprovedRyReportList } from './ApprovedRyReportList';
import { ContractStatus } from './ContractStatus';

/**
 * [L]契約状況画面
 */
export const ContractDetailPage = () => {
  const { sDecisionDetail, apiStatus } = useSDecisionDetail();
  const navigate = useNavigate();

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">契約状況</h1>
        <p className="link-pageback deco-line c-pink">
          <button onClick={() => navigate(-1)}>
            <i className="icn pageback"></i>前の画面へ戻る
          </button>
        </p>
      </div>

      {/* @ts-expect-error */}
      <ProposalList proposalList={sDecisionDetail?.proposalList ?? []} />

      {/* @ts-expect-error */}
      <ContractStatus sDecision={sDecisionDetail} />

      {/* @ts-expect-error */}
      <ApprovedRyReportList sDecision={sDecisionDetail} />

      {/* @ts-expect-error */}
      <ApiLoadingOverlay apiStatus={[apiStatus]} />
    </div>
  )
}

/**
 * S決裁詳細を取得する
 */
const useSDecisionDetail = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchSDecisionDetail;
  const sDecisionDetail = useSelector(selectSDecisionDetail);
  const routerParams = useParams();

  /** 取得処理呼出し済フラグ */
  const fetchStarted = useRef(false);

  // 画面突入時にAPIを呼び出す
  useEffect(() => {
    if (fetchStarted.current) {
      return;
    }

    if (routerParams.sDecisionNo != null) {
      dispatch(fetchSDecisionDetail(routerParams.sDecisionNo));
    }
    fetchStarted.current = true;
  }, [dispatch, routerParams.sDecisionNo]);

  // 画面離脱時にAPI関連のstateをクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchSDecisionDetail'));
      dispatch(clearSDecisionDetail());
    }
  }, [dispatch]);

  return { sDecisionDetail, apiStatus };
}
