import { useCallback, useState } from 'react';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil';
import { statusListAnnouncement } from '../../common/headerList';
import DatePicker from 'react-datepicker';

/** 検索パラメーターの初期値 */
const defaultParams = {
  announcementNo: '',
  announcementTitle: '',
  announcementContent: '',
  releasePeriodFrom: undefined,
  releasePeriodTo: undefined,
  announcementStatus: '',
};

/**
 * [A]お知らせ一覧の検索フォーム
 * @returns
 */
export const AnnouncementListSearchForm = ({ onSearch }) => {

  /** 検索パラメーター(入力途中) */
  const [params, setParams] = useState(defaultParams);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, []);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, []);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefinedにする)
    onSearch(emptyToUndefined({
      ...params,
      releasePeriodFrom: formatDate(params.releasePeriodFrom),
      releasePeriodTo:   formatDate(params.releasePeriodTo),
    }));
  }, [params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    setParams(defaultParams);
    if (typeof onSearch !== 'function') {
      return;
    }
    onSearch();
  }, [onSearch]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '110px'}}>お知らせNo</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="announcementNo" title="お知らせNoを入力してください" aria-label="お知らせNo"
                value={params.announcementNo}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">お知らせタイトル</dt>
          <dd className="form-body">
            <div className="input-form wdt500">
              <input type="text" name="announcementTitle" title="お知らせタイトルを入力してください" aria-label="お知らせタイトル"
                value={params.announcementTitle}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '110px'}}>お知らせ内容</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="announcementContent" title="お知らせ内容を入力してください" aria-label="お知らせ内容"
                value={params.announcementContent}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '110px'}}>公開日時</dt>
          <dd className="form-body df a-center">
            <div className="input-form input-calendar mr10">
              <DatePicker
                name="releasePeriodFrom"
                title="公開開始日を入力してください"
                aria-label="公開開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.releasePeriodFrom}
                onChange={date => onDateChange('releasePeriodFrom', date)} />
            </div>
            <p>〜</p>
            <div className="input-form input-calendar ml10">
              <DatePicker
                name="releasePeriodTo"
                title="公開終了日を入力してください"
                aria-label="公開終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.releasePeriodTo}
                onChange={date => onDateChange('releasePeriodTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="announcementStatus" title="ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.announcementStatus}>
                <option value=""></option>
                {Object.keys(statusListAnnouncement).map(i => (
                  <option value={i} key={i}>{statusListAnnouncement[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{width: '160px'}}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{width: '160px'}}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};
