import { UploadedFileList } from './UploadedFileList';

/**
 * ライセンシー側のアップロードファイル一覧
 * @param {object} props
 * @param {string} props.filetype ファイル種別
 * @param {import('./UploadedFileList').FileInfo[]} props.fileList ファイル情報のリスト
 * @returns
 */
export const UploadFileShowOnly = ({ filetype, fileList }) => {
  return fileList.length > 0 ? (
    <div className="l-uploader-content">
      <ul className="list-uploader-content">
        <UploadedFileList filetype={filetype} fileList={fileList} />
      </ul>
    </div>
  ) : null;
}
