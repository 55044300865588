import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HandledApiError } from "../../lib/api/errors/HandledApiError";
import { passwordResetRequest, passwordReset } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";
import { pushMessage } from "./utilSlice";

/** ストレージ保存キー */
const SaveKey = {
  temporaryToken: 'licensee_passwordTmpToken',
};

/**
 * パスワード再設定
 */
const initialState = {
  resetRequest: null,
  reset: null,
  temporaryToken: null,
  apiStatus: {
    resetRequest: null,
    reset: null,
  }
}

/**
 * store作成時のstateの前準備
 */
export function preloadState() {
  const temporaryToken = sessionStorage.getItem(SaveKey.temporaryToken);
  return {
    ...initialState,
    temporaryToken,
  };
}

/**
 * パスワード再設定メール送信API
 */
export const resetRequest = createAsyncThunk(
  'licensee/password/resetRequest',
  /**
   * パスワード再設定メール送信処理
   * @param {passwordResetRequestParam} params
   * @returns {passwordResetRequestResponse}
   */
  async (params, { dispatch }) => {
    try {
      const result = await passwordResetRequest(params);
      // 一時トークン返却
      return { temporaryToken: result.temporaryToken };
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20301': ApiErrorMessage.ValidateError,
        // メール送信失敗
        '20302': ApiErrorMessage.SystemError,
      });
    }
  }
);

/**
 * パスワード再設定API
 */
export const reset = createAsyncThunk(
  'licensee/password/reset',
  /**
   * パスワード再設定処理
   * @param {passwordResetParam} params
   * @returns {passwordResetParamResponse}
   */
  async ({ params, option }, { dispatch }) => {
    try {
      const result = await passwordReset(params, option);
      return result
    } catch (err) {
      if (err.errorCode === '20403') {
        dispatch(pushMessage('認証コードの有効期限が切れています。もう一度最初からやり直してください。'));
        dispatch(clearTemporaryToken());
        throw new HandledApiError(err);
      }
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20401': ApiErrorMessage.InvalidPassResetCode,
        // アカウントロック状態
        '20402': ApiErrorMessage.AccountLock,
      });
    }
  }
);

/**
 * パスワード再設定関連のスライス
 */
export const passwordSlice = createSlice({
  name: 'licensee/password',
  initialState,
  reducers: {
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        // パスワード再設定メール送信
        case 'resetRequest':
          state.apiStatus.resetRequest = null;
          break;
        // パスワード再設定
        case 'reset':
          state.apiStatus.reset = null;
          break;
        default:
          break;
      }
    },
    /**
     * 一時トークンをクリアする
     * @param {typeof initialState} state
     */
    clearTemporaryToken: (state) => {
      state.temporaryToken = null;
    }
  },
  extraReducers: builder => {
    builder
      // 再設定メール送信処理
      .addCase(resetRequest.pending, (state) => {
        state.apiStatus.resetRequest = 'loading';
      })
      .addCase(resetRequest.fulfilled, (state, action) => {
        state.apiStatus.resetRequest = 'finish';
        state.temporaryToken = action.payload.temporaryToken;
        sessionStorage.setItem(SaveKey.temporaryToken, state.temporaryToken);
      })
      .addCase(resetRequest.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.resetRequest = null;
      })
      // 再設定処理
      .addCase(reset.pending, (state) => {
        state.apiStatus.reset = 'loading';
      })
      .addCase(reset.fulfilled, (state) => {
        state.apiStatus.reset = 'finish';
        state.temporaryToken = null;
        sessionStorage.removeItem(SaveKey.temporaryToken, state.temporaryToken);
      })
      .addCase(reset.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.reset = null;
      })
  }
});

export const {
  clearApiStatus,
  clearTemporaryToken,
} = passwordSlice.actions;

export default passwordSlice.reducer;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns API通信状況
 */
export const selectApiStatus = (state) => state.licensee.password.apiStatus;

/**
 * stateから一時トークンを取得する
 * @param {*} state
 * @returns {string} 一時トークン
 */
export const selectTemporaryToken = (state) => state.licensee.password.temporaryToken;

/**
 * @typedef {'resetRequest'|'reset'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} passwordResetRequestParam パスワード再設定メール送信APIパラメータ
 * @property {string} mailaddress メールアドレス
 */
/**
 * @typedef {object} passwordResetRequestResponse パスワード再設定メール送信APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result {}
 * @property {string} temporatyToken 一時トークン
 */
/**
 * @typedef {object} passwordResetParam パスワード再設定APIパラメータ
 * @property {string} password パスワード
 * @property {string} passResetAuthCode パスワードリセット認証コード
 */
/**
 * @typedef {object} passwordResetParamResponse パスワード再設定APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result {}
 * TODO::認証トークン返却される仕様で合ってる？
 * @property {string} token 認証トークン
 */
