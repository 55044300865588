import { useCallback, useState } from 'react';
import { emptyToUndefined } from '../../../lib/listPageUtil';
import { Constants } from '../../../Constants';

/** 検索パラメーターの初期値 */
const defaultParams = {
  licenseeCode: '',
  licenseeNameKanji: '',
  kihonkeiyakuFlag: '',
  assessment: '',
};

/**
 * [A]取引先一覧の検索フォーム
 * @returns
 */
export const LicenseeListSearchForm = ({ onSearch }) => {

  /** 検索パラメーター(入力途中) */
  const [params, setParams] = useState(defaultParams);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, []);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefinedにする)
    onSearch(emptyToUndefined(params));
  }, [params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    setParams(defaultParams);
    if (typeof onSearch !== 'function') {
      return;
    }
    onSearch();
  }, [onSearch]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeCode" title="取引コードを入力してください" aria-label="取引先コード"
                value={params.licenseeCode}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="licenseeNameKanji" title="取引先名を入力してください" aria-label="取引先名"
                value={params.licenseeNameKanji}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '115px'}}>基本契約締結</dt>
          <dd className="form-body">
            <div className="form-select wdt200">
              <select name="kihonkeiyakuFlag" title="基本契約締結を選択してください" onChange={onParamUpdate} value={params.kihonkeiyakuFlag}>
                <option value=""></option>
                {Object.keys(Constants.Aniplex.kihonkeiyakuFlagName).map(s => (
                  <option key={s} value={s}>{Constants.Aniplex.kihonkeiyakuFlagName[s]}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">評価</dt>
          <dd className="form-body">
            <div className="form-select wdt100">
              <select name="assessment" title="評価を選択してください" onChange={onParamUpdate} value={params.assessment}>
                <option value=""></option>
                {Object.keys(Constants.Aniplex.licenseeAssessmentName).map(s => (
                  <option key={s} value={s}>{Constants.Aniplex.licenseeAssessmentName[s]}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{width: '160px'}}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{width: '160px'}}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};
