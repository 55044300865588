//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { royaltyReportAniplex, statusListRoyaltyAniplex } from '../../common/headerList';
import { dateParams, defaultParams, RoyaltyReportListSearchForm } from './RoyaltyReportListSearchForm.jsx';
import { fetchAll, fetchAllRoyalties, selectApiStatus, clearApiStatus, clearFetchAll, selectBulkAcceptResult, bulkAccept, clearBulkAcceptResult } from '../../../slices/aniplex/royaltiesSlice';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { immutableSort } from '../../../lib/util';
import { Constants } from '../../../Constants.js';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil.js';
import { comma3 } from '../../../lib/util';
import { useSaveFormData } from '../../../lib/hooks/common.js';
import { selectMyself } from '../../../slices/aniplex/usersSlice.js';
import { MessagePopup } from '../../common/MessagePopup.jsx';
import { pushMessage } from '../../../slices/aniplex/utilSlice.js';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
royaltyReportAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [A]ロイヤルティ報告一覧画面
 * @returns
 */
export const RoyaltyReportListPage = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const royaltyList = useSelector(fetchAllRoyalties);
  /** react-routerによるパラメータ */
  const routerParams = useParams();

  const proposalNo = routerParams.proposalNo || undefined

  /** @type {SearchFormData} */
  const defaultSearchParam = useMemo(() => ({
    ...defaultParams,
    proposalNo: proposalNo || '',
    filterCondition: proposalNo ? 'ALL' : 'PSO',
  }), [proposalNo]);
  const [searchParams, setSearchParams] = useState(defaultSearchParam);
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(royaltyReportAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'ryReportNo', order: 'desc' });

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  /** 一括受領可能ユーザフラグ */
  const isBulkAcceptableUser = useIsBulkAcceptableUser();

  // RY報告チェックボックス機能
  const {
    checkedIdList,
    onChange: onCheckChange,
    clear: clearIdList,
  } = useCheckedIdList();

  /** 一括受領完了時コールバック */
  const onAcceptComplete = useCallback(() => {
    clearIdList();
    // 現在の条件で再検索
    dispatch(fetchAll(emptyToUndefined({
      ...searchParams,
      reportDateFrom: formatDate(searchParams.reportDateFrom),
      reportDateTo: formatDate(searchParams.reportDateTo),
    })));
  }, [clearIdList, dispatch, searchParams]);

  // 一括受領処理
  const {
    bulkAccept,
    apiStatus: acceptApiStatus,
  } = useBulkAccept({
    royaltyList: royaltyList?.result?.data ?? [],
    checkedIdList,
    onComplete: onAcceptComplete,
  });

  // 一括受領確認ポップアップ
  const [showAcceptConfirm, onAcceptClick, onAcceptClose] = useConfirmPopup({ onOK: bulkAccept });

  // 検索パラメータ保存関連
  const {
    isRestored,
    savedData,
    saveData,
  } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.RoyaltyReportList,
    dataType: searchParams,
    dateParams,
  });

  // 申請中の件数
  const reqCount = useMemo(() =>
    royaltyList?.result?.data
      ?.filter(r => r.reportStatus === Constants.Aniplex.reportStatus.Requesting)
      .length ?? 0
    , [royaltyList?.result?.data]);

  // 申請中の件数表示をするかのフラグ
  const showReqCount = useMemo(() => reqCount > 0, [reqCount]);

  //マウント時・検索時にロイヤリティ報告情報を取得
  useEffect(() => {
    if (!paramRestored) {
      return;
    }
    if (initialFetched) {
      return;
    }
    dispatch(fetchAll(emptyToUndefined({
      ...searchParams,
      reportDateFrom: formatDate(searchParams.reportDateFrom),
      reportDateTo: formatDate(searchParams.reportDateTo),
    })));
    saveData(searchParams);
    setInitialFetched(true);
  }, [dispatch, initialFetched, paramRestored, saveData, searchParams]);

  // 画面離脱時にAPI通信状況をクリアする
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  // パスパラメーター変更時に検索パラメータをリセットする
  useEffect(() => {
    setSearchParams(defaultSearchParam);
    setInitialFetched(false);
  }, [defaultSearchParam]);

  // 画面突入時に保存済み検索パラメータを復元する
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (proposalNo != null) {
      // 企画書Noのパラメータが存在する場合は復元しない
      setParamRestored(true);
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }

    setSearchParams(savedData);
    setParamRestored(true);
  }, [isRestored, paramRestored, proposalNo, savedData]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return (apiStatus === 'loading'
      || acceptApiStatus === 'loading'
      ) && (
        // @ts-expect-error
        <LoadingOverlay />
      );
  }, [acceptApiStatus, apiStatus]);

  // 検索
  const onSearch = useCallback(params => {
    dispatch(fetchAll(emptyToUndefined({
      ...params,
      reportDateFrom: formatDate(params.reportDateFrom),
      reportDateTo: formatDate(params.reportDateTo),
    })));
  }, [dispatch]);

  // 申請中のみを検索
  const onSearchReq = useCallback(() => {
    const params = {
      ...searchParams,
      reportStatus: Constants.Aniplex.reportStatus.Requesting,
    };
    setSearchParams(params);
    // NOTE: 検索フォームのコンポーネントに渡らないのでここで保存
    saveData(params);
    onSearch(params);
  }, [onSearch, saveData, searchParams]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [royaltyList, sortInfo]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const royalties = royaltyList?.result?.data || [];
    return royalties.map(royalty => {
      return {
        ryReportId: royalty.ryReportId,
        reportStatus: royalty.reportStatus,
        ryReportNo: royalty.ryReportNo,
        ryAmount: comma3(royalty.ryAmount),
        ryDate: '第' + (royalty.period ?? '') + '期 ' + (royalty.ryStartDate ?? '') + ' ～ ' + (royalty.ryEndDate ?? ''),
        propertySummaryName: royalty.propertySummaryName,
        proposalId: royalty.proposalId,
        proposalNo: royalty.proposalNo,
        proposalTitle: royalty.proposalTitle,
        licenseeCode: royalty.licenseeCode,
        licenseeNameKanji: royalty.licenseeNameKanji,
        reportDate: royalty.reportDatetime,
      };
    });
  }, [royaltyList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'reportStatus') {
      return (a, b) => statusListRoyaltyAniplex?.[a[sortKey]]?.index - statusListRoyaltyAniplex?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {
      // ステータスが強調表示の対象か
      const statusEmphasis = Constants.Aniplex.reportStatusEmphasis.includes(record.reportStatus);
      const statusText = statusListRoyaltyAniplex?.[record.reportStatus]?.text;

      return {
        ...record,
        checkbox: (
          // @ts-expect-error
          <RecordCheckbox
            royalty={record}
            checkedIdList={checkedIdList}
            onChange={onCheckChange} />
        ),
        id: record.ryReportId,
        //@ts-expect-error
        ryReportNo: (<Link to={'../RoyaltyReportDetail/' + record.ryReportId}>{record.ryReportNo}</Link>),
        //@ts-expect-error
        proposalNo: (<Link to={'../ProposalDetail/' + record.proposalId}>{record.proposalNo}</Link>),
        //@ts-expect-error
        licenseeCode: <Link to={'../LicenseeDetail/' + record.licenseeCode}>{record.licenseeCode}</Link>,
        reportStatus: statusEmphasis ? (
          <span className="attention">{statusText}</span>
        ) : statusText,
        reportDate: String(record.reportDate || '').substring(0, 10),
      };
    });
  }, [checkedIdList, onCheckChange, sortedRecords]);

  const tableHeaders = useMemo(() => {
    if (!isBulkAcceptableUser) {
      return [...headers];
    }

    return [
      {
        id: 'checkbox',
        sortable: false,
        minWidth: 40,
        label: (
          // @ts-expect-error
          <AllCheckbox
            royaltyList={sortedRecords}
            checkedIdList={checkedIdList}
            setChecked={onCheckChange}
            currentPage={current}
            pageSize={pageSize} />
        )
      },
      ...headers,
    ];
  }, [checkedIdList, current, headers, isBulkAcceptableUser, onCheckChange, sortedRecords]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(royaltyReportAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  return (
    <div className="wrap">
      {/* @ts-expect-error */}
      <RoyaltyReportListSearchForm
        key={proposalNo || ''}
        onSearch={onSearch}
        params={searchParams}
        setParams={setSearchParams} />

      {/* @ts-expect-error */}
      <TableVisible
        isOpen={isOpen}
        headers={royaltyReportAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply}
        ignoreIdList={['checkbox']} />
      {Boolean(royaltyList) && (
        <>
          {
            showReqCount && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '35px', marginBottom: '-10px' }}>
                <span className="attention" style={{ fontWeight: 'bold', marginRight: '10px' }}>申請中が{reqCount}件あります</span>
                <p className="btn bg-yellow" style={{ width: '120px' }}>
                  <button onClick={onSearchReq}>申請中を検索</button>
                </p>
              </div>
            )
          }
          {/* @ts-expect-error */}
          <PagingTable
            headers={tableHeaders}
            records={viewRecords}
            current={current}
            pageSize={pageSize}
            sortParam={sortInfo}
            emptyMessage="検索条件と一致するロイヤリティ報告情報がありません。"
            verticalScrollable
            scrollable
            resizable
            onSortChange={setSortInfo}
            onPageChange={setCurrent} />

          {
            isBulkAcceptableUser && (
              <div className="mt10 dif j-between">
                <div className="l-buttons">
                  <p className="btn bg-aniplex" style={{ width: '150px' }}>
                    <button
                      disabled={!checkedIdList.length}
                      onClick={onAcceptClick}
                    >0円一括受領</button>
                  </p>
                </div>
              </div>
            )
          }
        </>
      )}

      {
        showAcceptConfirm && (
          // @ts-expect-error
          <MessagePopup
            message='選択したロイヤリティ報告の一括受領を行います。よろしいですか？'
            btn={{ ok: '受領', cancel: 'キャンセル' }}
            onClose={onAcceptClose}
            btnClass='bg-aniplex' />
        )
      }

      {loading}

    </div>
  );
};

/**
 * ヘッダに表示する全選択用のチェックボックス
 * @param {object} params
 * @param {Royalty[]} params.royaltyList RY報告のリスト
 * @param {number[]} params.checkedIdList チェック状態のRY報告IDのリスト
 * @param {(id: number, checked: boolean) => void} params.setChecked チェック状態変更時のハンドラ
 * @param {number} params.pageSize 1ページ当たりの件数
 * @param {number} params.currentPage 現在のページ番号
 */
const AllCheckbox = ({
  royaltyList,
  checkedIdList,
  setChecked,
  pageSize,
  currentPage,
}) => {
  /** 現在のページに表示されているRY報告 */
  const currentPageList = useMemo(() => {
    const begin = (currentPage - 1) * pageSize;
    const end = begin + pageSize;
    return royaltyList.slice(begin, end)
      .filter(ry => isBulkAcceptable(ry));
  }, [currentPage, pageSize, royaltyList]);

  // ページ切替時やソート条件変更時に別ページに移動したRY報告の選択をクリア
  useEffect(() => {
    const currentPageIdList = currentPageList.map(r => r.ryReportId);
    const otherPageList = royaltyList.filter(r => !currentPageIdList.includes(r.ryReportId));

    otherPageList.forEach(r => setChecked(r.ryReportId, false));
  }, [currentPageList, royaltyList, setChecked]);

  /** 全RY報告選択中フラグ */
  const checked = useMemo(() =>
    !!currentPageList.length
    && currentPageList.every(ry => checkedIdList.includes(ry.ryReportId)),
    [checkedIdList, currentPageList]);

  const onChange = useCallback(
    /**
     * @param {boolean} checked チェック状態
     */
    (checked) => {
      currentPageList.forEach(ry => setChecked(ry.ryReportId, checked));
    }, [currentPageList, setChecked]);

  return (
    <fieldset>
      <input type="checkbox"
        id='check-royalty-all'
        checked={checked}
        onChange={e => onChange(e.target.checked)} />
      <label
        htmlFor='check-royalty-all'
        className='form-checkbox02'
      >チェック</label>
    </fieldset>
  )
}

/**
 * RY報告のレコードに表示するチェックボックス
 * @param {object} params
 * @param {Royalty} params.royalty レコードに対応するRY報告
 * @param {number[]} params.checkedIdList チェックの入っているIDのリスト
 * @param {(id: number, checked: boolean) => void} params.onChange チェック状態変更時のコールバック
 */
const RecordCheckbox = ({ royalty, checkedIdList, onChange }) => {
  /**
   * チェックボックス表示フラグ
   * 0円報告かつ申請中の場合のみ表示
   */
  const isVisible = useMemo(() => isBulkAcceptable(royalty), [royalty]);

  /** チェック状態 */
  const checked = useMemo(() => checkedIdList.includes(royalty.ryReportId), [checkedIdList, royalty.ryReportId]);

  return (
    isVisible && (
      <fieldset>
        <input type="checkbox"
          id={`check-royalty-${royalty.ryReportId}`}
          checked={checked}
          onChange={ev => onChange(royalty.ryReportId, ev.target.checked)} />
        <label
          htmlFor={`check-royalty-${royalty.ryReportId}`}
          className='form-checkbox02'
        >チェック</label>
      </fieldset>
    )
  )
}

/**
 * 現在のユーザが一括受領機能を使用可能か判定する
 */
const useIsBulkAcceptableUser = () => {
  const myself = useSelector(selectMyself);

  return useMemo(() => {
    /**
     * @type {string[]}
     * @satisfies {ValueOf<typeof Constants.Aniplex.UserRole>[]}
     */
    const roles = [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
    ]
    return roles.includes(myself?.role ?? '')
  }, [myself?.role]);
}

/**
 * チェックを入れられたRY報告IDのリスト
 */
const useCheckedIdList = () => {
  const [idList, setIdList] = useState(/** @type {number[]} */([]));

  /**
   * チェック状態変更時のハンドラ
   */
  const onChange = useCallback(
    /**
     * @param {number} id チェック状態変更対象のRY報告ID
     * @param {boolean} checked チェック状態
     */
    (id, checked) => {
      setIdList(prev => {
        let tmp = [...prev];

        // チェックが外されたらリストから削除
        if (!checked) {
          const idx = tmp.findIndex(i => i === id);
          if (idx >= 0) {
            tmp.splice(idx, 1);
          }
          return tmp;
        }

        tmp.push(id);
        tmp = tmp.filter((i, idx, self) => self.indexOf(i) === idx);
        return tmp;
      })
    }, []);

  /**
   * IDリストの中身を空にする
   */
  const clear = useCallback(() => setIdList([]), []);

  return {
    checkedIdList: idList,
    onChange,
    clear,
  }
}

/**
 * 確認ポップアップ関連の機能を提供するカスタムフック
 * @returns {[boolean, () => void, (btn: 'ok'|'cancel'|'close') => void]}
 */
const useConfirmPopup = ({ onOK }) => {
  // ポップアップ表示フラグ
  const [popupShown, setPopupShown] = useState(false);

  /** ポップアップ表示ボタン押下時 */
  const onShow = useCallback(() => setPopupShown(true), []);

  /** ポップアップの各ボタン押下時 */
  const onClose = useCallback(
    /**
     * @param {'ok'|'cancel'|'close'} btn 押されたボタン
     */
    (btn) => {
      setPopupShown(false);
      if (btn === 'ok') {
        onOK();
      }
    }, [onOK]);

  return [ popupShown, onShow, onClose ]
}

/**
 * 一括受領機能
 * @param {object} params
 * @param {Royalty[]} params.royaltyList RY報告のリスト
 * @param {number[]} params.checkedIdList チェック状態のRY報告ID
 * @param {() => void} params.onComplete 処理完了時のコールバック
 */
const useBulkAccept = ({ royaltyList, checkedIdList, onComplete }) => {
  const dispatch = useDispatch();
  /** API通信状況 */
  const apiStatus = useSelector(selectApiStatus).bulkAccept;
  /** 処理結果 */
  const result = useSelector(selectBulkAcceptResult);

  /** 選択状態のRY報告のリスト */
  const selectedRoyaltyList = useMemo(() =>
    royaltyList.filter(ry => checkedIdList.includes(ry.ryReportId)),
    [checkedIdList, royaltyList]);

  /** 一括受領処理 */
  const doBulkAccept = useCallback(() => {
    /** @type {BulkAcceptRyReport[]} */
    const param = selectedRoyaltyList.map(ry => ({
      ryReportId: ry.ryReportId,
      updateDatetime: ry.updateDatetime,
    }));
    dispatch(bulkAccept(param))
  }, [dispatch, selectedRoyaltyList]);

  // 通信結果の処理
  useEffect(() => {
    if (apiStatus !== 'finish') {
      return;
    }

    if ((result?.failedReportList?.length ?? 0) > 0) {
      const failedList = result?.failedReportList?.map(r => r.ryReportNo) ?? [];
      dispatch(pushMessage(
        '以下のロイヤリティ報告を受領できませんでした。確認してください。\n'
        + failedList.join('\n')));
    } else {
      dispatch(pushMessage('ロイヤリティ報告を一括受領しました。'));
    }

    onComplete();
    dispatch(clearApiStatus('bulkAccept'));
    dispatch(clearBulkAcceptResult());
  }, [apiStatus, dispatch, onComplete, result?.failedReportList]);

  return {
    /** 一括受領処理 */
    bulkAccept: doBulkAccept,
    apiStatus,
  };
}

/**
 * 対象のRY報告が一括受領可能かを判定する
 * @param {Royalty} royalty 判定対象のRY報告
 */
function isBulkAcceptable(royalty) {
  if (royalty.reportStatus !== Constants.Aniplex.reportStatus.Requesting) {
    return false;
  }
  if (isNaN(Number(royalty.ryAmount)) || Number(royalty.ryAmount) > 0) {
    return false;
  }
  return true;
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportListSearchForm').SearchFormData} SearchFormData
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').Royalty} Royalty
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').BulkAcceptRyReport} BulkAcceptRyReport
 */
/**
 * @typedef {T[keyof T]} ValueOf
 * @template T
 */
//#endregion typedef
