import { SpannableTableView } from "../../common/table/SpannableTableView"

/**
 * テーブル表示部分
 * @param {object} props
 * @param {Licensee[]} props.licensees 取引先情報のリスト
 * @param {string} props.selected 選択中の取引先
 * @param {(licenseeCode: string) => void} props.onSelect 取引先選択時のコールバック
 * @param {string} [props.error] エラーメッセージ
 * @returns
 */
export const SelectLicenseePopupTable = ({ licensees, selected, onSelect, error }) => {
  let errorMsg = error;

  const headers = [
    { id: 'select', label: '', style: { minWidth: '30px' } },
    { id: 'licenseeCode', label: '取引先コード', style: { minWidth: '90px' } },
    { id: 'licenseeNameKanji', label: '取引先名', style: { minWidth: '90px' } },
  ];

  /** @type {TableRecord[]} */
  let records = licensees
    .map(l => Object.fromEntries(
      Object.entries(l).map(([k, v]) => ([k, (v === '' || v == null) ? '-' : v]))
    ))
    .map(l => ({
      ...l,
      'select': (
        <>
          <input
            type="radio"
            id={`licensee-radio-${l.licenseeCode}`}
            checked={selected === l.licenseeCode}
            onChange={_ => onSelect(l.licenseeCode)}
          />
          <label htmlFor={`licensee-radio-${l.licenseeCode}`} className="form-radio"></label>
        </>
      )
    }));
  if (!errorMsg && records.length === 0) {
    errorMsg = '検索条件と一致する取引先情報がありません。';
  }
  if (errorMsg) {
    const colNum = headers.length;
    records = [{
      'select': {
        colSpan: colNum,
        el: errorMsg,
      }
    }];
  }

  return (
    <SpannableTableView
      headers={headers}
      records={records}
    />
  );
};

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/licenseesSlice').Licensee} Licensee 取引先情報
 */
//#endregion typedef
