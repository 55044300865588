import { useEffect, useState } from "react";
import { FlexiblePopup } from "../common/FlexiblePopup"
import axios from "axios";
import { Config } from '../../config';

/**
 * プライバシーポリシー画面
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const PrivacyPopup = ({ onClose }) => {
  // プライバシーポリシーHTML
  const [ htmlString, setHtmlString ] = useState('');

  // mount直後にプライバシーポリシーHTMLを取得
  useEffect(() => {
    axios.get(Config.LicenseePrivacyPopupUrl).then(
      ({ data }) => {
        // ステータスコードのエラーは無視
        // HTMLを設定
        setHtmlString(data || '');
      },
      () => {
        // 通信エラーは無視
      }
    );
  }, []);

  return (
      <FlexiblePopup
        onClose={() => onClose('close')}>
        <div className="wrap">
          {/* 取得したHTMLを表示 */}
          {Boolean(htmlString) && (
            <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
          )}
        </div>
      </FlexiblePopup>
  );
}

//#region typedef
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン
 */
//#endregion typedef
