//@ts-check
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom'
import { comma3 } from '../../../lib/util';
import BigNumber from 'bignumber.js';
import { useIsAcceptedReport, useNotAcceptedReportExists, useRyAmountTotal } from './hooks';
import { Constants } from '../../../Constants';

/**
 * 企画状況部分のフォーム
 * @param {object} params
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細情報
 * @param {?ProposalDetail} params.proposalDetail 企画情報
 */
export const RoyaltyStatusForm = ({
  royalty,
  proposalDetail,
}) => {
  /** 契約状況画面リンク表示フラグ */
  const showContractDetail = useShowContractDetail(proposalDetail);
  /** 当期のロイヤリティ金額合計 */
  const ryAmountTotalView = useRyAmountTotalForView(royalty);
  /** 過去ロイヤリティ報告のロイヤリティ金額 */
  const ryReportHistoryAmount = useRyReportHistoryAmount(royalty);
  /** 当期までの通算のロイヤリティ金額合計 */
  const ryAmountPeriodTotalView = useRyAmountPeriodTotalForView(royalty);
  /** 当期のオーバーロイヤリティ */
  const overRoyaltyView = useOverRoyaltyForView({ proposalDetail, royalty });
  /** 過去ロイヤリティ報告のオーバーロイヤリティ */
  const historyOverRoyalty = useHistoryOverRoyalty(royalty);
  /** 当期までの通算のオーバーロイヤリティ合計 */
  const overRoyaltyPeriodTotalView = useOverRoyaltyPeriodTotalForView({ proposalDetail, royalty });
  /** 報告受領後のMG残高 */
  const afterMgZanView = useAfterMgZanForView({ proposalDetail, royalty });
  /** 報告受領後のアドバンス残高 */
  const afterAdZanView = useAfterAdZanForView({ proposalDetail, royalty });
  /** 最終報告後のMG余剰金フラグ */
  const mgSurplusFlg = useMgSurplusFlg({ royalty, proposalDetail });

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title">企画状況</h2>
        {
          showContractDetail && (
            <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>
              S決裁に複数の企画書が紐づいているため、
              {/* @ts-expect-error */}
              <Link to={`/aniplex/contractDetail/${proposalDetail?.sDecision?.sDecisionNo ?? ''}`}>こちら</Link>の画面を確認してください。
            </p>
          )
        }
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '80px' }}>S決裁No</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="S決裁No"
                title="S決裁Noを入力してください"
                aria-label="S決裁No"
                disabled
                value={proposalDetail?.sDecision?.sDecisionNo ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '80px' }}>MG</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input type="text" name="MG"
                title="MGを入力してください"
                aria-label="MG"
                disabled
                value={comma3(proposalDetail?.sDecision?.mg ?? '')} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">アドバンス</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input type="text" name="アドバンス"
                title="アドバンスを入力してください"
                aria-label="アドバンス"
                disabled
                value={comma3(proposalDetail?.sDecision?.ad ?? '')} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">MG残高（BONUS最新）</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input type="text" name="MG残高"
                title="MG残高を入力してください"
                aria-label="MG残高"
                disabled
                value={comma3(proposalDetail?.sDecision?.mgZan ?? '')} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">アドバンス残高（BONUS最新）</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input type="text" name="アドバンス残高"
                title="アドバンス残高を入力してください"
                aria-label="アドバンス残高"
                disabled
                value={comma3(proposalDetail?.sDecision?.adZan ?? '')} />
            </div>
          </dd>
        </dl>
      </div>

      {
        proposalDetail?.periodList.map(pr => (
          <div className="l-form" key={pr.period}>
            <dl className="form-set">
              <dt className="form-name" style={{ width: '180px' }}>第{pr.period}期ロイヤリティ金額</dt>
              <dd className="form-body">
                <div className="input-form wdt300 cost">
                  <input type="text"
                    name={`第${pr.period}期ロイヤリティ金額`}
                    title={`第${pr.period}期ロイヤリティ金額を入力してください`}
                    aria-label={`第${pr.period}期ロイヤリティ金額`}
                    disabled
                    value={
                      pr.period === royalty?.period ?
                        comma3(ryAmountTotalView) :
                        comma3(ryReportHistoryAmount[pr.period] ?? '')
                    } />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name" style={{ width: '210px' }}>第{pr.period}期オーバーロイヤリティ</dt>
              <dd className="form-body">
                <div className="input-form wdt300 cost">
                  <input
                    type="text"
                    name={`第${pr.period}期オーバーロイヤリティ`}
                    title={`第${pr.period}期オーバーロイヤリティ`}
                    aria-label={`第${pr.period}期オーバーロイヤリティ`}
                    disabled
                    value={
                      pr.period === royalty?.period ?
                        comma3(overRoyaltyView) :
                        comma3(historyOverRoyalty[pr.period] ?? '')
                    } />
                </div>
              </dd>
            </dl>
          </div>
        ))
      }

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '180px' }}>ロイヤリティ金額合計</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="ロイヤリティ金額合計"
                title="ロイヤリティ金額合計を入力してください"
                aria-label="ロイヤリティ金額合計"
                disabled
                value={comma3(ryAmountPeriodTotalView)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ width: '210px' }}>オーバーロイヤリティ合計</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="オーバーロイヤリティ合計"
                title="オーバーロイヤリティ合計"
                aria-label="オーバーロイヤリティ合計"
                disabled
                value={comma3(overRoyaltyPeriodTotalView)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '180px' }}>報告受領後のMG残高</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="報告受領後のMG残高"
                title="報告受領後のMG残高"
                aria-label="報告受領後のMG残高"
                disabled
                value={comma3(afterMgZanView)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ width: '210px' }}>報告受領後のアドバンス残高</dt>
          <dd className="form-body">
            <div className="input-form wdt300 cost">
              <input
                type="text"
                name="報告受領後のアドバンス残高"
                title="報告受領後のアドバンス残高を入力してください"
                aria-label="報告受領後のアドバンス残高"
                disabled
                value={comma3(afterAdZanView)} />
            </div>
          </dd>
        </dl>
      </div>
      {
        mgSurplusFlg && (
          <div>
            <p className='attention' style={{ lineHeight: '1.3', marginTop: '10px' }}>
              アドバンスロイヤリティの設定がされていないため、MG未到達分のロイヤリティがある場合は契約終了時にライセンシーへ請求を行う必要があります。<br />
              BONUSにてMG未到達分の請求処理を行ってください。
            </p>
          </div>
        )
      }
    </section>
  )
}

/**
 * 契約状況画面へのリンク表示フラグ
 * @param {?ProposalDetail} proposal 企画詳細
 */
const useShowContractDetail = (proposal) => {
  return useMemo(() => (proposal?.sDecision?.proposalCount ?? 0) > 1,
    [proposal?.sDecision?.proposalCount])
}

/**
 * ライセンシー修正中フラグ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useInModify = (royalty) => {
  return useMemo(() => {
    // 差戻中、修正中はライセンシー修正中と判断
    /** @type {valueOf<Constants['Aniplex']['reportStatus']>[]} */
    const inModifyStatus = [
      Constants.Aniplex.reportStatus.Rejected,
      Constants.Aniplex.reportStatus.Temporary,
    ];
    return royalty != null && inModifyStatus.includes(royalty.reportStatus);
  }, [royalty]);
}

/**
 * ライセンシー側で変更可能なステータスの時に「ライセンシー修正中」を返す
 * @param {string|number} value ライセンシー修正中以外に表示したい値
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useInModifyValue = (value, royalty) => {
  const inModify = useInModify(royalty);

  return useMemo(() => {
    if (inModify) {
      return 'ライセンシー修正中';
    }
    return value;
  }, [inModify, value]);
}

/**
 * 報告受領前のアドバンス残高
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useBeforeAdZan = ({
  proposalDetail,
  royalty,
}) => {
  const ryReportHistoryAmount = useRyReportHistoryAmount(royalty);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const historyTotal = Object.values(ryReportHistoryAmount)
      .reduce((prev, cur) => prev.plus(new BN(cur)), new BN(0));
    const diff = new BN(proposalDetail?.sDecision?.ad ?? 0).minus(historyTotal);
    return BN.max(diff, 0).toNumber();
  }, [proposalDetail?.sDecision?.ad, ryReportHistoryAmount]);
}

/**
 * 当期ロイヤリティ金額の表示用の値
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useRyAmountTotalForView = (royalty) => {
  const ryAmountTotal = useRyAmountTotal(royalty?.ryAmountList);

  return useInModifyValue(ryAmountTotal, royalty);
}

/**
 * 当期オーバーロイヤリティ
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useOverRoyalty = ({
  proposalDetail,
  royalty,
}) => {
  const isAcceptedReport = useIsAcceptedReport(royalty);
  const ryAmountTotal = useRyAmountTotal(royalty?.ryAmountList);
  const beforeAdZan = useBeforeAdZan({ proposalDetail, royalty });

  return useMemo(() => {
    if (isAcceptedReport) {
      // 受領済みの場合はAPI計算済みの値を使う
      return royalty?.calOverRoyalty ?? '';
    }

    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });

    const diff = new BN(ryAmountTotal)
      .minus(new BN(beforeAdZan));
    return BN.max(diff, 0).toNumber();
  }, [beforeAdZan, isAcceptedReport, royalty?.calOverRoyalty, ryAmountTotal]);
}

/**
 * 当期オーバーロイヤリティの表示用の値
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useOverRoyaltyForView = ({
  proposalDetail,
  royalty,
}) => {
  const overRoyalty = useOverRoyalty({ proposalDetail, royalty });
  const value = useInModifyValue(overRoyalty, royalty);

  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  if (notAcceptedExists) {
    return '';
  }
  return value;
}

/**
 * 過去ロイヤリティ報告のロイヤリティ金額
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 * @returns 期数をキー, ロイヤリティ金額を値とするオブジェクト
 */
const useRyReportHistoryAmount = (royalty) => {
  return useMemo(() => {
    return Object.fromEntries(
      royalty?.ryReportHistory.filter(h => h.period < royalty?.period)
        .map(h => ([h.period, h.ryAmount])) ?? []
    );
  }, [royalty?.period, royalty?.ryReportHistory]);
}

/**
 * 過去ロイヤリティ報告のオーバーロイヤリティ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useHistoryOverRoyalty = (royalty) => {
  return useMemo(() => {
    return Object.fromEntries(
      royalty?.ryReportHistory.filter(h => h.period < royalty?.period)
        .map(h => [h.period, h.calOverRoyalty]) ?? []
    );
  }, [royalty?.period, royalty?.ryReportHistory]);
}

/**
 * 当期までの通算のロイヤリティ金額合計
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細情報
 */
const useRyAmountPeriodTotal = (royalty) => {
  const isAcceptedReport = useIsAcceptedReport(royalty);
  const ryAmountTotal = useRyAmountTotal(royalty?.ryAmountList);
  const ryReportHistoryAmount = useRyReportHistoryAmount(royalty);

  return useMemo(() => {
    if (isAcceptedReport) {
      return royalty?.calFullPeriodRoyaltyTotal ?? '';
    }

    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const historyTotal = Object.values(ryReportHistoryAmount)
      .reduce((prev, cur) => prev.plus(new BN(cur)), new BN(0))
    return new BN(ryAmountTotal).plus(historyTotal).dp(0).toNumber();
  }, [isAcceptedReport, royalty?.calFullPeriodRoyaltyTotal, ryAmountTotal, ryReportHistoryAmount]);
}

/**
 * 当期までの通算のロイヤリティ金額合計の表示用の値
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useRyAmountPeriodTotalForView = (royalty) => {
  const ryAmountPeriodTotal = useRyAmountPeriodTotal(royalty);
  const value = useInModifyValue(ryAmountPeriodTotal, royalty);

  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  if (notAcceptedExists) {
    return '';
  }
  return value;
}

/**
 * 当期までの通算のオーバーロイヤリティ合計
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useOverRoyaltyPeriodTotal = ({
  proposalDetail,
  royalty,
}) => {
  const isAcceptedReport = useIsAcceptedReport(royalty);
  const overRoyalty = useOverRoyalty({ proposalDetail, royalty });
  const historyOverRoyalty = useHistoryOverRoyalty(royalty);

  return useMemo(() => {
    if (isAcceptedReport) {
      // 受領済みの場合はAPI計算値を使う
      return royalty?.calFullPeriodOverRoyalty ?? '';
    }

    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const historyTotal = Object.values(historyOverRoyalty)
      .reduce((prev, cur) => prev.plus(new BN(cur ?? 0)), new BN(0));
    return new BN(overRoyalty).plus(historyTotal).dp(0).toNumber();
  }, [historyOverRoyalty, isAcceptedReport, overRoyalty, royalty?.calFullPeriodOverRoyalty]);
}

/**
 * 当期までの通算のオーバーロイヤリティ合計の表示用の値
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useOverRoyaltyPeriodTotalForView = ({
  proposalDetail,
  royalty,
}) => {
  const overRoyaltyPeriodTotal = useOverRoyaltyPeriodTotal({ proposalDetail, royalty });
  const value = useInModifyValue(overRoyaltyPeriodTotal, royalty);

  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  if (notAcceptedExists) {
    return '';
  }
  return value;
}

/**
 * 報告受領後のMG残高
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細
 */
const useAfterMgZan = ({
  proposalDetail,
  royalty,
}) => {
  const ryAmountPeriodTotal = useRyAmountPeriodTotal(royalty);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    const diff = new BN(proposalDetail?.sDecision?.mg ?? 0)
      .minus(new BN(ryAmountPeriodTotal));
    return BN.max(diff, 0).toNumber();
  }, [proposalDetail?.sDecision?.mg, ryAmountPeriodTotal]);
}

/**
 * 報告受領後のMG残高の表示用の値
 * @param {object} params
 */
const useAfterMgZanForView = ({
  proposalDetail,
  royalty,
}) => {
  const afterMgZan = useAfterMgZan({ proposalDetail, royalty });
  const value = useInModifyValue(afterMgZan, royalty);

  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  if (notAcceptedExists) {
    return '';
  }
  return value;
}

/**
 * 報告受領後のアドバンス残高
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細情報
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細情報
 * @returns
 */
const useAfterAdZan = ({
  proposalDetail,
  royalty
}) => {
  const ryReportHistoryAmount = useRyReportHistoryAmount(royalty);
  const ryAmountTotal = useRyAmountTotal(royalty?.ryAmountList);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    let result = new BN(proposalDetail?.sDecision?.ad ?? 0);
    for (let pr of proposalDetail?.periodList ?? []) {
      result = result.minus(new BN(ryReportHistoryAmount[pr.period] ?? 0));
    }
    result = result.minus(new BN(ryAmountTotal ?? 0));
    return BigNumber.max(result, 0).dp(0).toNumber();
  }, [proposalDetail?.periodList, proposalDetail?.sDecision?.ad, ryAmountTotal, ryReportHistoryAmount]);
}

/**
 * 報告受領後のアドバンス残高の表示用の値
 * @param {object} params
 */
const useAfterAdZanForView = ({
  proposalDetail,
  royalty,
}) => {
  const afterAdZan = useAfterAdZan({ proposalDetail, royalty });
  const value = useInModifyValue(afterAdZan, royalty);

  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  if (notAcceptedExists) {
    return '';
  }
  return value;
}

/**
 * MGに余りが出ているかのフラグ
 * @param {object} params
 * @param {?RoyaltyDetail} params.royalty ロイヤリティ報告詳細情報
 * @param {?ProposalDetail} params.proposalDetail 企画詳細情報
 */
const useMgSurplusFlg = ({
  royalty,
  proposalDetail,
}) => {
  const inModify = useInModify(royalty);
  const notAcceptedExists = useNotAcceptedReportExists(royalty);

  const afterMgZan = useAfterMgZan({ proposalDetail, royalty });
  const afterAdZan = useAfterAdZan({ proposalDetail, royalty });

  return useMemo(() => {
    if (inModify || notAcceptedExists) {
      // 受領後のMG残高が表示されない条件のときはフラグを立てない
      return false;
    }

    // 最終期以外のRY報告の場合はフラグを立てない
    const periodList = proposalDetail?.periodList;
    if (periodList == null) {
      return false;
    }
    const lastPeriod = periodList[periodList.length - 1].period;
    if (royalty?.period !== lastPeriod) {
      return false;
    }

    // MG残高がアドバンス残高を上回った場合は余剰金あり
    return afterMgZan > afterAdZan;
  }, [afterAdZan, afterMgZan, inModify, notAcceptedExists, proposalDetail?.periodList, royalty?.period]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告詳細情報
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RyAmount} RyAmount ロイヤリティ金額情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
