import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { ProductDetail } from '../../common/ProductDetail'
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { isEmpty, isNumber, maxValueComma } from '../../../lib/validator';
import { getMessage } from '../../../lib/message';
import { comma3 } from '../../../lib/util';
import { insertComma, deleteComma } from '../../../lib/number';
import { NumberInput } from '../../common/NumberInput';
import { useSelector } from 'react-redux';
import { selectCategoryMst } from '../../../slices/licensee/masterSlice';

/**
 * 証紙申請の商品リスト
 * @param {object} params
 * @param {Product[]} params.productList 商品リスト
 * @param {Label[]|undefined} params.pLabelList 証紙情報
 * @param {React.Dispatch<React.SetStateAction<Record<string, Record<string, string[]>>>>} params.setLabelErrors エラー文言設定
 * @param {React.MutableRefObject<Record<string, React.MutableRefObject<Record<string, React.MutableRefObject<HTMLElement>>>>>} params.formRefs フォーム要素へのref
 * @param {boolean} params.forceValidateActionFlg 強制バリデートアクション実行フラグ
 * @param {() => void} params.onForceValidateActionFlg 強制バリデートアクション実行後のコールバック
 */
export const LabelProductForm = ({
    productList,
    pLabelList,
    setParentLabel,
    setLabelError,
    setLabelErrors,
    isEditable,
    formRefs,
    forceValidateActionFlg,
    onForceValidateAction,
}) => {
  const [messages, setMessages] = useState([]);
  const [labels, setLabels] = useState(/** @type {ReqLabel[]} */ ([]));

  const setApplyDate = useCallback(() => {
    // 申請用のデータは数値のカンマ外した状態にしておく
    setParentLabel(labels.map((label) => {
      label['labelFigure'] = deleteComma(label.labelFigure);
      return label;
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  useEffect(() => {
    const tmp = [];
    for (const [, element] of productList.entries()) {
      // ラベルリスト
      const target = pLabelList ? pLabelList.find(l => l.productNo === element.productNo) : [];
      const tmpData = {
        'productId' : element.productId,
        'labelFigure' : target && target.labelFigure ? comma3(target.labelFigure) : 0,
        'labelType' : target && target.labelType ? target.labelType : 'S'
      };
      if (target) tmpData.labelNo = target.labelNo;
      tmp.push(tmpData);
    }
    setParentLabel(tmp.map((label) => {
      label['labelFigure'] = insertComma(label.labelFigure);
      return label;
    }));
    setLabels(tmp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pLabelList, productList])

  const handleChange = useCallback((name, value, idx) => {
    const target = name + idx;
    const updData = labels.map((label, index) => {
      label[name] = (idx === index) ? value : comma3(deleteComma(label[name]));
      return label;
    });
    setLabels(updData);

    const error = validate(name, value);
    const tmpError = {...messages, [target]: error};
    setMessages(tmpError);
    // eslint-disable-next-line
    setLabelError(Object.values(tmpError).filter(e => e != '').length > 0);
    setLabelErrors(prev => ({
      ...prev,
      [idx]: {
        ...prev[idx] ?? {},
        [name]: error
      }
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, messages]);

  // 商品ポップアップ関連
  const [productPopup, setProductPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {Product|null} 対象商品情報 */
    target: null,
    /** @type {Function|null} 閉じたときのコールバック */
    onClose: null,
  });

  /** 商品名クリック時のコールバック */
  const onProductNameClick = useCallback((product) => {
    setProductPopup({
      showFlg: true,
      target: product,
      onClose: () => {
        setProductPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, []);

  /** 商品フィルタ */
  const {
    filterValue,
    onFilterChange,
    clearFilter,
    invisibleProducts,
  } = useProductFilter({ productList });

  // 強制バリデート実行時の処理
  useEffect(() => {
    if (!forceValidateActionFlg) {
      return;
    }
    const hasError = Object.values(messages).flat().length > 0;
    if (hasError) {
      // エラーがあるときはフィルタを解除する
      clearFilter();
    }
    onForceValidateAction();
  }, [clearFilter, forceValidateActionFlg, messages, onForceValidateAction]);

  /** 証紙データ作成 */
  const records = useMemo(() => {
    const result = [];
    // 商品リストによるデータ作成
    productList.forEach((data, idx) => {
      if (invisibleProducts.find(i => i.productId === data.productId) != null) {
        // 非表示の商品はスキップ
        return;
      }

      result.push(
        <tr key={`label${idx}`}>
        <td>
          <button className='link'
            onClick={() => onProductNameClick(data)}
            style={{ textAlign: 'left' }}
          >{data.productName}</button>
        </td>
        <td>{data.character ?? ''}</td>
        <td className="cost">{comma3(data.planPrice ?? '')}</td>
        <td>
          <div className="input-form cost mlauto wdt140">
            <NumberInput type="text" name={`labelFigure${idx}`} title="証紙申請数を入力してください" aria-label="証紙申請数"
              inputRef={formRefs?.current?.[idx]?.current?.labelFigure}
              onChange={val => handleChange('labelFigure', val, idx)}
              onBlur={item => {setApplyDate(); handleChange('labelFigure', insertComma(item.target.value), idx)}}
              onFocus={item => handleChange('labelFigure', deleteComma(item.target.value), idx)}
              value={isEmpty(labels[idx]) ? 0 : labels[idx] && labels[idx].labelFigure }
              disabled={!isEditable} />
          </div>
          <ErrorMessageList messages={messages[`labelFigure${idx}`]} />
        </td>
        <td>
          <fieldset>
            <legend>証紙{idx}</legend>
            <ul className="list-form df">
              <li>
                <input type="radio" id={`radioS-${idx}`} name={`labelType${idx}`} aria-label={`証紙${idx}`} value="S"
                onChange={item => handleChange('labelType', item.target.value, idx)}
                onBlur={() => setApplyDate()}
                checked={(labels[idx] && labels[idx].labelType === 'S') ?? true}
                style={{ display: 'none'}} disabled={!isEditable} />
                <label htmlFor={`radioS-${idx}`} className="form-radio">シール</label>
              </li>
              <li className="ml15">
                <input type="radio" id={`radioT-${idx}`} name={`labelType${idx}`} aria-label={`証紙${idx}`} value="P"
                onChange={item => handleChange('labelType', item.target.value, idx)}
                onBlur={() => setApplyDate()}
                checked={isEmpty(labels[idx]) ? false : labels[idx].labelType === 'P'}
                style={{ display: 'none'}} disabled={!isEditable} />
                <label htmlFor={`radioT-${idx}`} className="form-radio">印刷</label>
              </li>
              <ErrorMessageList messages={messages[`labelType${idx}`]} />
            </ul>
          </fieldset>
        </td>
        </tr>
      )
    });
    return result;
  }, [formRefs, handleChange, invisibleProducts, isEditable, labels, messages, onProductNameClick, productList, setApplyDate]);

  return(
    <>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <div className="filter-form">
          <dl className="form-set">
            <dt className="form-name">フィルター</dt>
            <dd className="form-body">
              <div className="input-form wdt250">
                <input
                  type="text"
                  name="フィルター"
                  aria-label="フィルター"
                  value={filterValue}
                  onChange={ev => onFilterChange(ev.target.value)} />
              </div>
            </dd>
          </dl>
        </div>
        <p className="attention mt30" style={{ marginLeft: 'auto', textAlign: 'right' }}>
          1商品あたりの総生産数が10,000点を超える場合、印刷証紙でのご手配が可能です。<br />
          印刷証紙ご希望の際は、連絡事項へその旨記載をお願いいたします。<br />
          10,000点に満たない場合はシール貼りでのご手配となります。
        </p>
      </div>
      <div className="l-table border0 mt10" style={{ maxHeight: '500px', overflowY: 'auto'}}>
        <table>
          <thead style={{ position: 'sticky', top: 0, zIndex: '1' }}>
            <tr>
              <th scope="col" style={{ minWidth: 750 }}>商品名等</th>
              <th scope="col" style={{ minWidth: 150 }}>キャラクター</th>
              <th scope="col" style={{ minWidth: 110 }}>上代（税抜き）</th>
              <th scope="col" className="tar" style={{ minWidth: 197, width: 310 }}>証紙申請数</th>
              <th scope="col" style={{ minWidth: 160 }}>証紙</th>
            </tr>
          </thead>
          <tbody>
          {
            records.length > 0 && records
          }
          {
            records.length === 0 && (
              <tr><td colSpan={5}>該当する商品が存在しません。</td></tr>
            )
          }
          </tbody>
        </table>
        {
          productPopup.showFlg ?
            <ProductDetail
            periodList={productPopup.target.periodList}
            product={productPopup.target}
            onClose={productPopup.onClose}
            isEditable={false}
            /> : null
        }
      </div>
    </>
  );

}

/**
 * 商品フィルタのカスタムフック
 * @param {object} params
 * @param {Product[]} params.productList 商品リスト
 */
const useProductFilter = ({
  productList,
}) => {
  const categoryMst = useSelector(selectCategoryMst);

  // フィルタ項目の値
  const [filterValue, setFilterValue] = useState('');

  /** フィルタをクリアする */
  const clearFilter = useCallback(() => {
    setFilterValue('');
  }, []);

  /** フィルタの内容変更ハンドラ */
  const onFilterChange = useCallback(
    /** @param {string} newVal */
    newVal => {
      setFilterValue(newVal);
    }
  , []);

  /** 非表示にする商品のリスト */
  const invisibleProducts = useMemo(() => {
    return productList.filter(p => {
      const category = categoryMst.find(c => c.categoryNo === p.categoryNo);
      const categoryDetail = category?.categoryDetailList.find(c => c.categoryDetailNo === p.categoryDetailNo);

      if (!isEmpty(filterValue)) {
        const match = [
          p.productName ?? '',
          p.productRemarks ?? '',
          p.productOption1 ?? '',
          p.productOption2 ?? '',
          p.productOption3 ?? '',
          category?.categoryName ?? '',
          categoryDetail?.categoryDetailName ?? '',
        ].reduce((prev, cur) => prev || cur.includes(filterValue), false);
        if (!match) {
          return true;
        }
      }

      return false;
    })
  }, [categoryMst, filterValue, productList]);

  return {
    filterValue,
    clearFilter,
    onFilterChange,
    invisibleProducts,
  };
}


/**
 * 個別バリデート
 */
 export function validate(name, value, isApply = true) {
  const errors = [];
  // 証紙申請数
  if (name.startsWith('labelFigure')) {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (!isNumber(deleteComma(value))) {
      errors.push(getMessage('isNumber'));
    } else if (!maxValueComma(value, 1000000000)) {
      errors.push(getMessage('maxValue', {max: 1000000000}));
    }
  }
  // 証紙タイプ
  if (name.startsWith('labelType')) {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    }
  }
  return errors;
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalProduct} Product
 */
/**
 * @typedef {import('../../../slices/licensee/labelsSlice').labelProduct} Label
 */
/**
 * @typedef {import('../../../lib/api/licensee').PostLabelParam} PostLabelParam
 */
/**
 * @typedef {ElementOf<NonNullable<PostLabelParam['labelList']>>} ReqLabel
 */
/**
 * @typedef {T[number]} ElementOf
 * @template {any[]} T
 */
//#endregion typedef
