import { combineReducers, configureStore } from '@reduxjs/toolkit';
import licenseeAuthReducer, { preloadState as licenseeAuthPreload } from './slices/licensee/authSlice';
import licenseeUtilReducer from './slices/licensee/utilSlice';
import licenseePasswordReducer, { preloadState as licenseePasswordPreload } from './slices/licensee/passwordSlice';
import licenseeUsersReducer from './slices/licensee/usersSlice';
import licenseeLicenseeUsersReducer from './slices/licensee/licenseeUsersSlice';
import licenseeProposalsReducer from './slices/licensee/proposalsSlice';
import licenseeLabelsReducer from './slices/licensee/labelsSlice';
import licenseeRoyaltiesReducer from './slices/licensee/royaltiesSlice';
import licenseeMasterReducer from './slices/licensee/masterSlice';
import licenseeAnnouncementReducer from './slices/licensee/announcementSlice';
import licenseeSDecisionsReducer from './slices/licensee/sDecisionsSlice';
import aniplexUtilReducer, { preloadState as aniplexUtilPreload } from './slices/aniplex/utilSlice';
import aniplexProposalsReducer from './slices/aniplex/proposalsSlice';
import aniplexPropertiesReducer from './slices/aniplex/propertiesSlice';
import aniplexUsersReducer from './slices/aniplex/usersSlice';
import aniplexMasterReducer from './slices/aniplex/masterSlice';
import aniplexLabelsReducer from './slices/aniplex/labelsSlice';
import aniplexRoyaltiesReducer from './slices/aniplex/royaltiesSlice';
import aniplexLicenseesReducer from './slices/aniplex/licenseesSlice';
import aniplexLicenseeUsersReducer from './slices/aniplex/licenseeUsersSlice';
import aniplexAnxUsersReducer from './slices/aniplex/aniplexusersSlice';
import aniplexAnnouncementReducer from './slices/aniplex/announcementSlice';
import aniplexSDecisionsReducer from './slices/aniplex/sDecisionsSlice';

/**
 * store作成時のstateの前準備
 */
function loadPreState() {
  return {
    licensee: {
      auth: licenseeAuthPreload(),
      password: licenseePasswordPreload(),
    },
    aniplex: {
      util: aniplexUtilPreload(),
    },
  };
}

const licenseeReducers = combineReducers({
  auth: licenseeAuthReducer,
  util: licenseeUtilReducer,
  password: licenseePasswordReducer,
  users: licenseeUsersReducer,
  licenseeUsers: licenseeLicenseeUsersReducer,
  proposals: licenseeProposalsReducer,
  labels:licenseeLabelsReducer,
  royalties: licenseeRoyaltiesReducer,
  master: licenseeMasterReducer,
  announcement: licenseeAnnouncementReducer,
  sDecisions: licenseeSDecisionsReducer,
});
const aniplexReducers = combineReducers({
  util: aniplexUtilReducer,
  proposals: aniplexProposalsReducer,
  properties: aniplexPropertiesReducer,
  users: aniplexUsersReducer,
  master: aniplexMasterReducer,
  labels: aniplexLabelsReducer,
  royalties: aniplexRoyaltiesReducer,
  licensees: aniplexLicenseesReducer,
  licenseeUsers: aniplexLicenseeUsersReducer,
  aniplexusers: aniplexAnxUsersReducer,
  announcement: aniplexAnnouncementReducer,
  sDecisions: aniplexSDecisionsReducer,
});

export const store = configureStore({
  reducer: {
    licensee: licenseeReducers,
    aniplex: aniplexReducers,
  },
  preloadedState: loadPreState(),
});

/**
 * @typedef {ReturnType<typeof store.getState>} State
 */
