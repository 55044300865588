//@ts-check
import React, { useCallback, useMemo, useState } from "react";
import { isEmpty } from "../../../lib/validator";
import { getRypInputFlg, rypPriceCostType } from "../../../lib/royalty";
import { MessagePopup } from "../../common/MessagePopup";

/**
 * 空欄自動入力を行う関数を返す
 * @param {object} params
 * @param {?ProposalDetail} params.proposalDetail 企画詳細
 * @param {?number|undefined} params.targetPeriod 対象報告期
 * @param {React.Dispatch<React.SetStateAction<RyAmount[]>>} params.setRyAmountList ロイヤリティ金額情報設定処理
 */
export const useAutoFillEmptyRyAmount = ({
  proposalDetail,
  targetPeriod,
  setRyAmountList,
}) => {
  // 自動入力ダーティフラグ
  const [autoFillDirtyFlg, setAutoFillDirtyFlg] = useState(/** @type {RyAmountDirtyInfo} */({}));

  /** ダーティフラグ設定処理 */
  const markDirty = useCallback(
    /**
     * @param {number} productId 商品ID
     * @param {keyof RyAmountInputProp} prop 対象のプロパティ名
     */
    (productId, prop) => {
      setAutoFillDirtyFlg(prev => ({
        ...prev,
        [productId]: {
          ...prev[productId] ?? {},
          [prop]: true,
        },
      }));
    },
  []);

  /** 空欄自動入力処理実行 */
  const doAutoFill = useCallback(() => {
    setRyAmountList(prev => prev.map(ryAmount => {
      const newVal = {...ryAmount};
      const product = proposalDetail?.productList.find(p => p.productId === ryAmount.productId);
      const productPeriod = product?.periodList.find(p => p.period === targetPeriod);
      if (product == null || productPeriod == null) {
        // 対象商品や対象の報告期が見つからなかった場合は処理しない
        return newVal
      }


      const inputFlg = getRypInputFlg(product.rypId, 'licensee');

      // 生産数
      if (isEmpty(ryAmount.reportProduction) && inputFlg.reportProduction) {
        newVal.reportProduction = productPeriod.resultLabel;
        markDirty(product.productId, 'reportProduction');
      }

      // 報告数
      if (isEmpty(ryAmount.reportSales) && inputFlg.reportSales) {
        newVal.reportSales = productPeriod.resultLabel;
        markDirty(product.productId, 'reportSales');
      }

      // 上代・製造原価
      const priceCostType = rypPriceCostType(product.rypId);
      if (priceCostType === 'price' && isEmpty(ryAmount.reportPrice)) {
        newVal.reportPrice = product.planPrice;
        markDirty(product.productId, 'reportPrice');
      } else if (priceCostType === 'cost' && isEmpty(ryAmount.reportCost)) {
        newVal.reportCost = product.planCost;
        markDirty(product.productId, 'reportCost');
      }

      return newVal;
    }));
  }, [markDirty, proposalDetail?.productList, setRyAmountList, targetPeriod]);

  const [popupInfo, setPopupInfo] = useState(/** @type {PopupInfo} */ ({
    visible: false,
    onClose: null,
  }))

  /** 警告ポップアップ */
  const autoFillAlertPopup = useMemo(() => {
    if (!popupInfo.visible) {
      return null;
    }

    return (
      // @ts-expect-error ts(2786)
      <MessagePopup
        message={'生産数、販売数、上代、製造原価は証紙申請時の数字が自動入力されます。\n申請前に必ずご確認いただき、誤りがある場合は修正をした上でご申請をお願いします。'}
        btn={{ ok: '実行', cancel: 'キャンセル' }}
        btnClass="bg-pink"
        onClose={popupInfo.onClose} />
    )
  }, [popupInfo.onClose, popupInfo.visible]);

  /** 空欄自動入力処理呼出し */
  const autoFillEmptyRyAmount = useCallback(() => {
    setPopupInfo({
      visible: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          doAutoFill();
        }
        setPopupInfo({
          visible: false,
          onClose: null,
        });
      },
    });
  }, [doAutoFill]);

  return {
    autoFillEmptyRyAmount,
    autoFillAlertPopup,
    autoFillDirtyFlg,
  };
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RyAmount} RyAmount ロイヤリティ金額情報
 */
/**
 * @typedef {PopupInfoVisible|PopupInfoHidden} PopupInfo ポップアップ表示情報
 */
/**
 * @typedef {object} PopupInfoVisible 表示時のポップアップ情報
 * @property {true} visible 表示フラグ
 * @property {((btn: 'ok'|'cancel'|'close') => void)} onClose 閉じたときのコールバック
 */
/**
 * @typedef {object} PopupInfoHidden 非表示時のポップアップ情報
 * @property {false} visible 表示フラグ
 * @property {null} onClose 閉じたときのコールバック
 */
/**
 * @typedef {Pick<RyAmount, 'reportProduction'|'reportSales'|'reportProceeds'|'reportRyAmount'|'reportCost'|'reportPrice'>} RyAmountInputProp
 */
/**
 * @typedef {Record<number, Partial<Record<keyof RyAmountInputProp, true>>>} RyAmountDirtyInfo ロイヤリティ金額情報のダーティフラグ情報
 */
//#endregion typedef
