//@ts-check
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import { comma3 } from '../../../lib/util';
import { selectPropertySummaryMst } from '../../../slices/licensee/masterSlice'
import { ProposalProductsForm } from './ProposalProductsForm';
import { Constants } from '../../../Constants';

/** 締め月のプロパティ名 */
const closingMonthProps = [
  'ryReq1', 'ryReq2', 'ryReq3',
  'ryReq4', 'ryReq5', 'ryReq6',
  'ryReq7', 'ryReq8', 'ryReq9',
  'ryReq10', 'ryReq11', 'ryReq12',
];

/**
 * ライセンシー向け企画状況参照画面のフォーム
 * @param {object} props
 * @param {Proposal} props.proposal 対象の企画情報
 * @param {Function} props.requestReload 企画情報の再読込要求ハンドラ
 * @returns
 */
export const ProposalStatusForm = ({ proposal, requestReload }) => {
  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertySummaryMst);

  /** 本件著作物の名称 */
  const sPropertyName = useMemo(() => {
    return proposal?.sDecision?.propertyName ?? '';
  }, [proposal?.sDecision?.propertyName]);

  /** 作品名 */
  const propertyName = useMemo(() => {
    return propertyMst
      .find(p => p.propertySummaryCode === proposal?.propertySummaryCode)
      ?.propertySummaryName ?? '';
  }, [propertyMst, proposal?.propertySummaryCode]);

  /** ロイヤリティ報告区分 */
  const ryReportCategoryText = useRyReportCategoryText(proposal);

  return (
    <>
      <section className="mt30">
        <div className="title-pink">
          <h2 className="title"><i className="icn note"></i>企画内容</h2>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>作品名称</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="作品名称"
                  title="作品名称を入力してください"
                  aria-label="作品名称"
                  disabled
                  value={propertyName} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>本件著作物の名称</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="本件著作物の名称"
                  title='本件著作物の名称を入力してください'
                  aria-label='本件著作物の名称'
                  disabled
                  value={sPropertyName}/>
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>企画件名</dt>
            <dd className="form-body">
              <div className="input-form wdt1000">
                <input type="text" name="企画件名"
                  title="企画件名を入力してください"
                  aria-label="企画件名"
                  disabled
                  value={proposal?.proposalTitle ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>販売開始希望日</dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input type="text" name="販売開始希望日"
                  title="販売開始希望日を入力してください"
                  aria-label="販売開始希望日"
                  disabled
                  value={proposal?.proposalLaunchDate ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">申請者</dt>
            <dd className="form-body">
              <div className="input-form wdt200">
                <input type="text" name="申請者"
                  title="申請者を入力してください"
                  aria-label="申請者"
                  disabled
                  value={proposal?.applicantUserName ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>企画説明</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="企画説明"
                  title="企画説明を入力してください"
                  aria-label="企画説明"
                  disabled
                  value={proposal?.proposalDetail ?? ''} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '134px' }}>その他備考</dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="その他備考"
                  title="その他備考を入力してください"
                  aria-label="その他備考"
                  disabled
                  value={proposal?.proposalRemarks ?? ''} />
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-pink">
          <h2 className="title"><i className="icn calendar02"></i>ロイヤリティ報告締め月</h2>
        </div>

        <span className="c-pink">※ロイヤリティ報告締め月の翌月15日までにロイヤリティ報告のご提出をお願いします</span>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '122px' }}>報告締め月</dt>
            <dd className="form-body">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <span>{ryReportCategoryText}</span>
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-pink">
          <h2 className="title"><i className="icn list_pink"></i>全体状況</h2>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '140px' }}>許諾開始日</dt>
            <dd className="form-body">
              <div className="input-form input-calendar">
                <input type="text" name="許諾開始日"
                  title="許諾開始日を入力してください"
                  aria-label="許諾開始日"
                  disabled
                  value={proposal?.sDecision?.startDate ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">許諾終了日</dt>
            <dd className="form-body">
              <div className="input-form input-calendar">
                <input type="text" name="許諾終了日"
                  title="許諾終了日を入力してください"
                  aria-label="許諾終了日"
                  disabled
                  value={proposal?.sDecision?.endDate ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">販売開始期限</dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input type="text" name="販売開始期限"
                  title="販売開始期限を入力してください"
                  aria-label="販売開始期限"
                  disabled
                  value={proposal?.sDecision?.sLaunchDate ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">キャラクター使用許諾金</dt>
            <dd className="form-body">
              <div className="input-form wdt140 cost">
                <input type="text" name="キャラクター使用許諾金"
                  title="キャラクター使用許諾金を入力してください"
                  aria-label="キャラクター使用許諾金"
                  disabled
                  value={comma3(proposal?.sDecision?.kyodakukin ?? '')} />
              </div>
            </dd>
          </dl>
        </div>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name" style={{ width: '140px' }}>MG（最低保証料）</dt>
            <dd className="form-body">
              <div className="input-form wdt140 cost">
                <input type="text" name="MG（最低保証料）"
                  title="MG（最低保証料）を入力してください"
                  aria-label="MG（最低保証料）"
                  disabled
                  value={comma3(proposal?.sDecision?.mg ?? '')} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">アドバンスロイヤリティ</dt>
            <dd className="form-body">
              <div className="input-form wdt140 cost">
                <input type="text" name="アドバンスロイヤリティ"
                  title="アドバンスロイヤリティを入力してください"
                  aria-label="アドバンスロイヤリティ"
                  disabled
                  value={comma3(proposal?.sDecision?.ad ?? '')} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">MG残高</dt>
            <dd className="form-body">
              <div className="input-form wdt140 cost">
                <input type="text" name="MG残高"
                  title="MG残高を入力してください"
                  aria-label="MG残高"
                  disabled
                  value={comma3(proposal?.sDecision?.mgZan ?? '')} />
              </div>
            </dd>
          </dl>

          <dl className="form-set">
            <dt className="form-name">アドバンス残高</dt>
            <dd className="form-body">
              <div className="input-form wdt140 cost">
                <input type="text" name="アドバンス残高"
                  title="アドバンス残高を入力してください"
                  aria-label="アドバンス残高"
                  disabled
                  value={comma3(proposal?.sDecision?.adZan ?? '')} />
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <ProposalProductsForm
        proposal={proposal}
        requestReload={requestReload} />
    </>
  )
}

/**
 * 選択されたロイヤリティ報告区分の表示用テキスト
 * @param {Proposal|undefined} proposal フォームデータ
 */
const useRyReportCategoryText = (proposal) => {
  return useMemo(() => {
    if (proposal == null) {
      return '';
    }
    switch (proposal.ryReportCategory) {
      case Constants.Licensee.RyReportCategory.Monthly:
        return '毎月';
      case Constants.Licensee.RyReportCategory.Any:
        return '任意';
      case Constants.Licensee.RyReportCategory.Specified:
        const selectedMonths = closingMonthProps
          .map((prop, idx) => ({
            name: `${idx + 1}月`,
            checked: proposal[prop],
          }))
          .filter(val => val.checked)
          .map(val => val.name)
          .join(',');
        return `指定月（${selectedMonths}）`;
      default:
        return '';
    }
  }, [proposal]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} Proposal 企画情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalPeriod} ProposalPeriod 第N期の情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalProduct} Product 商品情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalProductPeriod} ProductPeriod 商品の第N期の情報
 */
//#endregion typedef
