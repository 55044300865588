import { useCallback, useState, useMemo } from 'react';
import { FlexiblePopup } from '../../common/FlexiblePopup';
import { SearchableListBox } from '../../common/SearchableListBox';

/**
 * 通知先を選択するポップアップ
 * @param {object} props
 * @param {LicenseeUser[]} props.licenseeUsers ライセンシーユーザーリスト
 * @param {*} props.onClose ポップアップイベント
 * @returns
 */
export const ProposalNoticePopup = ({licenseeUsers, onClose}) => {
  const [users, setUsers] = useState([]);
  const handleChange = useCallback((values) => {
    setUsers(values);
  }, []);

  // 選択用のリストを作成
  const listItems = useMemo(() => {
    return licenseeUsers
      .filter((u) => u.username)
      .filter((u) => u.userStatus === 'REG')
      .map((u) => {
        return {
          userId: u.userId,
          username: u.username,
          mailaddress: u.mailaddress,
          // 同姓同名を区別するために名前+メールアドレスの形式で選択させる
          namePlusMail: u.username + '（' + u.mailaddress + '）',
        };
      });
  }, [licenseeUsers]);

  // 追加ボタン親に設定を持ち帰る
  const onAdd = useCallback(() => {
    if (users.length > 0) {
      // ユーザー選択済み⇒親ウィンドウに持ちかえって氏名とメールアドレスを表示する
      onClose('submit', users.map(o => {
        const u = listItems.find(u => u.userId === o.value);
        return {
          'userId': u.userId,
          'username': u.username,
          'mailaddress': u.mailaddress,
        };
      }));
    } else {
      // 何もせずに閉じる
      onClose('close');
    }
  }, [onClose, users, listItems]);

  return (
    <FlexiblePopup onClose={() => onClose('close')}>
      <h4 className="popup-title">通知先設定</h4>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>通知先</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-input wdt500">
              <SearchableListBox
                data={listItems}
                labelKey='namePlusMail'
                valueKey='userId'
                isMulti={true}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={(event) => handleChange(event)}
                menuPortalTarget={document.body}
              />
            </div>
          </dd>
        </dl>
      </div>
      <div className="btn-wrapper">
        <div className="btn label mt15 bg-blue">
          <button
            onClick={onAdd}>追加</button>
        </div>
      </div>
    </FlexiblePopup>
  );
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/licenseeUsersSlice').LicenseeUsersDetail} LicenseeUser 企画情報
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
//#endregion typedef
