//@ts-check
import React from 'react';
import { ValueChangeStatus } from '../common/ValueChangeStatus';
import { ProposalDownloader } from './ProposalDownloader';

/**
 * 企画詳細画面の企画内容部分のフォーム
 * @param {object} props
 * @param {?Proposal} props.proposal 企画情報
 */
export const ProposalContentForm = ({ proposal }) => {
  return (
    <section className="mt30">
      <div className="title-aniplex">
        <h2 className="title"><i className="icn note"></i>企画内容</h2>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ minWidth: '132px' }}>
            発売開始希望日
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal.proposalLaunchDate}
                  prevValue={proposal.previousVersion.proposalLaunchDate} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <input type="text" name="発売開始希望日"
                title="発売開始希望日を必ず入力してください"
                aria-label="発売開始希望日"
                disabled
                value={proposal?.proposalLaunchDate ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '132px' }}>
            企画説明
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal.proposalDetail}
                  prevValue={proposal.previousVersion.proposalDetail} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="form-textarea wdt1200">
              <textarea rows={4} name="企画説明"
                aria-label="企画説明"
                title="企画説明を必ず入力してください"
                disabled
                value={proposal?.proposalDetail ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '132px' }}>
            その他備考
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal.proposalRemarks}
                  prevValue={proposal.previousVersion.proposalRemarks} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="form-textarea wdt1200">
              <textarea rows={4} name="その他備考"
                title="その他備考を入力してください"
                aria-label="その他備考"
                disabled
                value={proposal?.proposalRemarks ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      {(proposal?.proposalAttachmentList?.length ?? 0) > 0 && (
        <ProposalDownloader
          proposal={proposal} />
      )}

    </section>
  );
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
//#endregion typedef
