import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSdecisions } from "../../../lib/api/aniplex"
import { selectPropertyMst } from "../../../slices/aniplex/masterSlice"
import {
  updateSDecisionNo,
  selectApiStatus as selectProposalApiStatus,
  clearApiStatus as clearProposalApiStatus,
} from "../../../slices/aniplex/proposalsSlice"
import { pushMessage } from "../../../slices/aniplex/utilSlice"
import { ErrorMessageList } from "../../common/ErrorMessageList"
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay"
import { SpannableTableView } from "../../common/table/SpannableTableView"

/** S決裁未選択時のエラーメッセージ */
const unselectedError = '設定するS決裁を選択してください';

/**
 * S決裁No設定ポップアップ
 * @param {object} props
 * @param {Proposal} props.proposal 企画情報
 * @param {OnClose} props.onClose 閉じるときのコールバック
 */
export const AssignSDecisionPopup = ({ proposal, onClose }) => {
  const dispatch = useDispatch();
  const proposalApiStatus = useSelector(selectProposalApiStatus).updateSDecisionNo;
  const propertyMst = useSelector(selectPropertyMst);

  // 検索フォームの入力データ
  const [searchFormData, setSearchFormData] = useState({
    /** S決裁No */
    sDecisionNo: null,
    /** S決裁件名 */
    sTitle: null,
    /** S決裁担当者名 */
    sTantousya: null,
    /** プロパティコード */
    propertyCode: null,
    /** プロパティ名 */
    propertyName: null,
    /** 取引先コード */
    licenseeCode: null,
    /** 取引先名 */
    licenseeName: null,
  });

  // 選択中のS決裁No
  const [sDecisionNo, setSDecisionNo] = useState(proposal.sDecision?.sDecisionNo);

  // 検索結果エラー
  const [searchError, setSearchError] = useState(null);

  /** S決裁情報エラーのハンドラ */
  const onSDecisionError = useCallback((err) => {
      if (err.errorCode === '13201') {
        // 検索結果の件数超過エラー
        setSearchError('条件に該当する件数が多すぎるため検索を中断しました。条件を変更して再度検索してください。');
      } else {
        dispatch(pushMessage('S決裁情報の取得中にエラーが発生しました。'));
        setSearchError('検索中にエラーが発生しました。');
      }
  }, [dispatch]);

  /** S決裁情報検索完了時のハンドラ */
  const onSearchFinish = useCallback((result) => {
    // 現在選択状態のS決裁番号と同じものがない場合は選択内容をクリアする
    const found = result.find(s => s.sDecisionNo === sDecisionNo);
    if (!found) {
      setSDecisionNo(null);
    }
    // 検索結果エラーをクリア
    setSearchError(null);
  }, [sDecisionNo]);

  // S決裁情報検索フック
  const [doSearch, searchResult, searchApiLoading] = useSDecisionSearch(onSDecisionError, onSearchFinish);

  // ソート済みの検索結果
  const sortedSearchResult = useMemo(() => {
    return [...searchResult ?? []].sort((a, b) => {
      const aDate = dayjs(a.startDate, 'YYYY/MM/DD');
      const bDate = dayjs(b.startDate, 'YYYY/MM/DD');
      return bDate.diff(aDate, 'day');
    });
  }, [searchResult]);

  /** S決裁選択時のハンドラ */
  const onSelectSDecision = useCallback((no) => {
    if (!no) {
      setMessages([unselectedError]);
    } else {
      setMessages([]);
    }
    setSDecisionNo(no);
  }, []);

  // エラーメッセージ
  const [messages, setMessages] = useState([]);

  // エラーフラグ
  const hasError = useMemo(() => messages.length > 0, [messages]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    return searchApiLoading || proposalApiStatus === 'loading';
  }, [searchApiLoading, proposalApiStatus]);

  useEffect(() => {
    // ポップアップを開いたタイミングで企画の作品情報と取引先情報を初期設定して検索しておく
    const propertyName = propertyMst.find(p => p.propertySummaryCode === proposal?.propertySummaryCode)
      ?.propertySummaryName ?? '';
    setSearchFormData(prev => {
      const newData = {
        ...prev,
        propertyName,
        licenseeCode: proposal?.licenseeCode ?? null,
      };

      doSearch(newData);
      return newData
    });
  }, [dispatch, doSearch, propertyMst, proposal?.licenseeCode, proposal?.propertySummaryCode]);

  /** 検索フォームの値変更ハンドラ */
  const handleSearchFormChange = useCallback((name, value) => {
    setSearchFormData(prev => ({
      ...prev,
      [name]: value === '' ? null : value,
    }));
  }, []);

  /** 検索ボタン押下時のハンドラ */
  const onSearch = useCallback(() => {
    // S決裁一覧取得処理
    doSearch(searchFormData);
  }, [doSearch, searchFormData]);
  /** クリアボタン押下時のハンドラ */
  const onClear = useCallback(() => {
    const newVal = Object.fromEntries(
      Object.keys(searchFormData).map(key => ([key, null]))
    )
    setSearchFormData(newVal);
    doSearch(newVal);
  }, [doSearch, searchFormData]);

  /** 設定ボタン押下時のハンドラ */
  const onSubmit = useCallback(() => {
    // 選択済みかチェック
    if (!sDecisionNo) {
      setMessages([unselectedError]);
      return;
    } else {
      setMessages([]);
    }

    // S決裁No更新処理を呼び出す
    dispatch(updateSDecisionNo({
      proposalId: proposal?.proposalId,
      updateDatetime: proposal?.updateDatetime,
      sDecisionNo,
    }));
  }, [dispatch, proposal?.proposalId, proposal?.updateDatetime, sDecisionNo])

  // S決裁No更新処理のハンドリング
  useEffect(() => {
    if (proposalApiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(pushMessage('S決裁Noを設定しました。'));
      dispatch(clearProposalApiStatus('updateSDecisionNo'));
      onClose('submit');
      return;
    }
  }, [dispatch, onClose, proposalApiStatus]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (searchApiLoading || proposalApiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [proposalApiStatus, searchApiLoading]);

  return (
    <>
      <FlexiblePopup style={{ maxWidth: '90%' }}
        onClose={() => onClose('close')}
      >
        <h4 className="popup-title">S決裁No設定</h4>

        <SearchForm
          formData={searchFormData}
          formLocked={formLocked}
          handleChange={handleSearchFormChange}
          onSearch={onSearch}
          onClear={onClear} />

        <div className="mt30" style={{ maxHeight: '47vh', overflowY: 'auto' }}>
          <SDecisionTable
            sDecisions={sortedSearchResult}
            selected={sDecisionNo}
            onSelect={onSelectSDecision}
            error={searchError || (searchApiLoading && '検索中') || null} />
        </div>

        <ErrorMessageList messages={messages} />

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-yellow">
            <button
              disabled={hasError || formLocked}
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  )
}

/**
 * 検索フォーム部分
 * @param {object} props
 * @param {SearchFormData} props.formData 検索フォームのデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {HandlerSearchFormChange} props.handleChange 値変更時のハンドラ
 * @param {Function} props.onSearch 検索ボタン押下時のコールバック
 * @param {Function} props.onClear クリアボタン押下時のコールバック
 * @returns
 */
const SearchForm = ({ formData, formLocked, handleChange, onSearch, onClear }) => {
  return (
    <>
      <div className="l-form mt25">
        <dl className="form-set">
          <dt className="form-name">S決裁No</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="sDecisionNo"
                disabled={formLocked}
                value={formData.sDecisionNo ?? ''}
                onChange={ev => handleChange('sDecisionNo', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">S決裁件名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="sTitle"
                disabled={formLocked}
                value={formData.sTitle ?? ''}
                onChange={ev => handleChange('sTitle', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">S決裁担当者名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="sTantousya"
                disabled={formLocked}
                value={formData.sTantousya ?? ''}
                onChange={ev => handleChange('sTantousya', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">プロパティコード</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="propertyCode"
                disabled={formLocked}
                value={formData.propertyCode ?? ''}
                onChange={ev => handleChange('propertyCode', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">プロパティ名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="propertyName"
                disabled={formLocked}
                value={formData.propertyName ?? ''}
                onChange={ev => handleChange('propertyName', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">取引先コード</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="licenseeCode"
                disabled={formLocked}
                value={formData.licenseeCode ?? ''}
                onChange={ev => handleChange('licenseeCode', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input type="text" name="licenseeName"
                disabled={formLocked}
                value={formData.licenseeName ?? ''}
                onChange={ev => handleChange('licenseeName', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt15">
        <p className="btn bg-yellow" style={{ width: '160px' }}>
          <button
            disabled={formLocked}
            onClick={onSearch}
          >検索</button>
        </p>
        <p className="btn c-aniplex" style={{ width: '160px' }}>
          <button
            disabled={formLocked}
            onClick={onClear}
          >クリア</button>
        </p>
      </div>
    </>
  )
}

/**
 * テーブル表示部分
 * @param {object} props
 * @param {SDecision[]} props.sDecisions S決裁情報のリスト
 * @param {string} props.selected 選択中のS決裁No
 * @param {(sDecisionNo: string) => void} props.onSelect S決裁No選択時のコールバック
 * @param {string} [props.error] エラーメッセージ
 * @returns
 */
const SDecisionTable = ({ sDecisions, selected, onSelect, error }) => {
  let errorMsg = error;

  const headers = [
    { id: 'select', label: '', style: { minWidth: '30px' } },
    { id: 'sDecisionNo', label: 'S決裁No', style: { minWidth: '90px' } },
    { id: 'sTitle', label: '件名', style: { minWidth: '90px' } },
    { id: 'propertyCode', label: 'プロパティコード', style: { minWidth: '120px' } },
    { id: 'propertyName', label: 'プロパティ名', style: { minWidth: '90px' } },
    { id: 'licenseeCode', label: '取引先コード', style: { minWidth: '90px' } },
    { id: 'licenseeName', label: '許諾先名', style: { minWidth: '90px' } },
    { id: 'startDate', label: '許諾期間開始', style: { minWidth: '110px' } },
    { id: 'endDate', label: '許諾期間終了', style: { minWidth: '110px' } },
    { id: 'keiyakuDate', label: '契約締結日', style: { minWidth: '110px' } },
    { id: 'sTantousya', label: 'S決裁担当者', style: {minWidth: '100px'} },
  ];

  /** @type {TableRecord[]} */
  let records = sDecisions.map(s => Object.fromEntries(
    Object.entries(s).map(([k, v]) => ([k, (v === '' || v == null) ? '-' : v]))
  ))
  .map(s => ({
    ...s,
    'select': (
      <>
        <input type="radio"
          id={`sDecision-radio-${s.sDecisionNo}`}
          checked={selected === s.sDecisionNo}
          onChange={ev => onSelect(s.sDecisionNo)} />
        <label htmlFor={`sDecision-radio-${s.sDecisionNo}`} className="form-radio"></label>
      </>
    )
  }));
  if (!errorMsg && records.length === 0) {
    errorMsg = '検索条件と一致するS決裁情報がありません。'
  }
  if (errorMsg) {
    const colNum = headers.length;
    records = [{
      'select': {
        colSpan: colNum,
        el: errorMsg,
      }
    }];
  }

  return (
    <SpannableTableView
      headers={headers}
      records={records} />
  )
}

/**
 * S決裁情報検索機能のカスタムフック
 * @param {(err: *) => void} [onError] エラー発生時のコールバック
 * @param {(result: SDecision[])} [onFinish] 検索処理完了時のコールバック
 * @returns {[
 *  (condition: import('../../../lib/api/aniplex').GetSdecisionsParam) => void,
 *  SDecision[],
 *  boolean
 * ]} [検索処理実行コールバック, API実行結果, 通信中フラグ]
 */
const useSDecisionSearch = (onError, onFinish) => {
  /**
   * API実行結果
   * @type {[SDecision[], (val: SDecision[]) => void]}
   */
  const [result, setResult] = useState([]);
  // エラー内容
  const [error, setError] = useState(null);
  // 通信中フラグ
  const [loading, setLoading] = useState(false);
  // 取得完了フラグ(onSearchの依存配列を空にするために使用)
  const [fetched, setFetched] = useState(false);

  /** 検索処理実行コールバック */
  const onSearch = useCallback(async (condition) => {
    setLoading(true);
    try {
      const result = await getSdecisions(condition);
      setResult(result);
      setFetched(true)
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  // 検索完了したらコールバックを呼び出す
  useEffect(() => {
    if (fetched) {
      if (typeof onFinish === 'function') {
        onFinish(result);
      }
      setFetched(false);
    }
  }, [fetched, onFinish, result])

  // エラーがあるときにコールバックを呼び出す
  useEffect(() => {
    if (error != null) {
      if (typeof onError === 'function') {
        onError(error);
      }
      setError(null);
    }
  }, [error, onError]);

  return [onSearch, result, loading];
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
/**
 * @typedef {import('../../../lib/api/aniplex').SDecision} SDecision S決裁情報
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord テーブル表示のレコード定義
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
/**
 * @typedef {object} SearchFormData 検索フォームの入力データ
 * @property {string} sDecisionNo S決裁No
 * @property {string} sTitle S決裁件名
 * @property {string} sTantousya S決裁担当者名
 * @property {string} propertyCode 作品コード
 * @property {string} propertyName 作品名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeName 取引先名
 */
/**
 * @callback HandlerSearchFormChange 検索フォームのデータ変更ハンドラ
 * @param {keyof SearchFormData} name 変更された項目名
 * @param {*} value 入力された値
 */
//#endregion typedef
