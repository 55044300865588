import { FlexiblePopup } from "../../common/FlexiblePopup"

/**
 * お知らせ詳細ポップアップ
 * @param {object} props
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 * @param {object} props.announcement お知らせ情報
 * @returns
 */
export const AnnouncementDetailPopup = ({ onClose, announcement }) => {
  return (
    <FlexiblePopup onClose={() => onClose()} style={{ maxWidth: '90%'}}>
      <div className="multi-title-border mb20">
        <h1 className="title">{announcement?.announcementTitle ?? ''}</h1>
        <p className="mlauto">
          {announcement?.announcementStartDatetime ? announcement?.announcementStartDatetime.substring(0, 10) : ''}
        </p>
      </div>

      <div className="l-form mb20">
        <dl className="form-set">
          <dd className="form-body">
            <div className="form-textarea wdt1000">
              <textarea rows="8" name="お知らせ内容"
                aria-label="お知らせ内容"
                readOnly
                value={announcement?.announcementContent ?? ''} />
            </div>
          </dd>
        </dl>
      </div>
    </FlexiblePopup>
  )
}
