import DatePicker from 'react-datepicker';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '../../../lib/validator';
import dayjs from 'dayjs';
import { getMessage } from '../../../lib/message';
import { useFocusError } from '../../../lib/hooks/common';
import { comma3 } from '../../../lib/util';

/**
 * 発送日設定
 * @param {object} label
 * @param {Function} props.onChangeDispatch
 * @param {Boolean} updateBtnVisible
 * @param {Boolean} isApproved
 * @param {Boolean} enableDispatchDate
 * @param {Number} labelFigureTotal
 */
export const DispatchDateForm = ({label, onChangeDispatch, updateBtnVisible, isApproved, enableDispatchDate, labelFigureTotal}) => {
  const [messages, setMessages] = useState({
    dispatchDate: [],
  });

  const [dispatchDate, setDispatchDate] = useState();

  // 強制バリデートフラグ
  const [validateAllFlg, setValidateAllFlg] = useState(false);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['dispatchDate']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.dispatchDate.length > 0) {
      if (focusError('dispatchDate')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.dispatchDate.length, needFocusError]);

  // 初期データ投入
  useEffect(() => {
    setDispatchDate(label?.dispatchDate);
  }, [label?.dispatchDate]);

  // 強制バリデートフラグが立っているときは全項目をバリデートする
  useEffect(() => {
    if (validateAllFlg) {
      setMessages(prev => ({
        ...prev,
        dispatchDate: validateDispatchDate(dispatchDate),
      }));
    }
  }, [dispatchDate, validateAllFlg]);

  // 変更イベント
  const handleChange = useCallback((value) => {
    // データ整形
    const val = isEmpty(value) ? null : dayjs(value).format('YYYY/MM/DD');
    setMessages({
      dispatchDate: validateDispatchDate(val)
    });
    setDispatchDate(val);
  }, []);

  // 発送完了ボタン押下時
  const onSendClick = useCallback(() => {
    setValidateAllFlg(true);
    const err = validateDispatchDate(dispatchDate);
    if (err.length) {
      setNeedFocusError(true);
      return;
    }

    onChangeDispatch(dispatchDate);
  }, [dispatchDate, onChangeDispatch]);

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title">ANIPLEX管理</h2>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 120 }}>シール証紙合計</dt>
          <dd className="form-body">
            <div className="input-form cost wdt300">
              <input type="text" name="labelFigureTotal" aria-label="シール証紙合計"
                value={comma3(labelFigureTotal)} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 120 }}>発送日</dt>
          <dd className="form-body">
            {
              isApproved && updateBtnVisible && enableDispatchDate ?
              <div className="input-form input-calendar">
              <DatePicker
                name='dispatchDate'
                title='発送日を入力してください'
                aria-label='発送日'
                dateFormat='yyyy/MM/dd'
                locale='ja'
                ref={formRefs.current.dispatchDate}
                onChange={item => handleChange(item)}
                selected={isEmpty(dispatchDate) ? null : dayjs(dispatchDate).toDate()}
                required
              />
              <ErrorMessageList messages={messages.dispatchDate} />
              </div> :
              <div className="input-form wdt140">
                <input type="text" name="dispatchDate" aria-label="発送日" value={dispatchDate ?? ''}
                  readOnly disabled={!enableDispatchDate && updateBtnVisible} />
              </div>
            }

          </dd>
        </dl>
      </div>

      {
        updateBtnVisible ?
        <div className="mt30 dif">
          <p className="btn bg-aniplex" style={{ width: 140 }}>
            <button type="button" onClick={onSendClick}
             disabled={!isApproved || !enableDispatchDate}>
              発送完了</button>
          </p>
          {
            label?.dispatchDate && (<p style={{margin: '7px 0 0 5px'}}>※手配済み</p>)
          }
        </div> :
        null
      }
    </section>
  )
}

/**
 * バリデート
 * @param {*} value
 */
function validateDispatchDate(value) {
  // TODO::必須チェック必要？？
  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }
  return [];
}
