//@ts-check
import React, { useCallback, useMemo } from 'react';
import { SpannableTableView } from '../../common/table/SpannableTableView';
import { Link } from 'react-router-dom';
import { comma3 } from '../../../lib/util';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

/** 売上送信日時のフォーマット */
const approvalDatetimeFormat = 'YYYY/MM/DD HH:mm:ss';

/** @type {TableHeader[]} */
const headers = [
  { id: 'proposalNo', label: '企画書No', style: { width: '100px' } },
  { id: 'proposalTitle', label: '企画件名', style: { width: '400px' } },
  { id: 'ryReportNo', label: 'ロイヤリティ\n報告No', style: { width: '120px' } },
  { id: 'ryDate', label: '報告対象期間', style: { width: '270px' } },
  { id: 'ryAmount', label: 'ロイヤリティ金額', style: { width: '150px' } },
  { id: 'mgZan', label: 'MG残高', style: { width: '150px' } },
  { id: 'adZan', label: 'アドバンス残高', style: { width: '150px' } },
  { id: 'overRy', label: 'オーバーロイヤリティ', style: { width: '160px' } },
];

/**
 * 受領済みRY報告一覧
 * @param {object} params
 * @param {?SDecisionDetail} params.sDecision S決裁詳細
 */
export const ApprovedRyReportList = ({ sDecision }) => {
  const records = useViewRecords(sDecision);

  return (
    <section className="mt40">
      <div className="title-pink">
        <h2 className="title">受領済みのロイヤリティ報告一覧</h2>
      </div>

      {/* @ts-expect-error */}
      <SpannableTableView
        className='mt10 border0 has-total-row has-split-total scroll'
        headers={headers}
        records={records}
        resizable
        scrollable />
    </section>

  )
}

/**
 * 表示用のレコードを取得する
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useViewRecords = (sDecision) => {
  const calcMgZan = useCalcMgZan(sDecision);
  const calcAdZan = useCalcAdZan(sDecision);
  const calcOverRy = useCalcOverRy(sDecision);
  const sortedList = useSortedList(sDecision?.appRyReportList ?? []);

  return useMemo(() => {
    /** @type {TableRecord[]} */
    const records = sortedList.map(report => ({
      proposalNo: {
        el: (
          // @ts-expect-error
          <Link to={`/licensee/proposalDetail/${report.proposalId}`}>{report.proposalNo}</Link>
        ),
      },
      proposalTitle: {
        el: report.proposalTitle,
      },
      ryReportNo: {
        el: (
          // @ts-expect-error
          <Link to={`/licensee/royaltyReportDetail/${report.ryReportId}`}>{report.ryReportNo}</Link>
        ),
      },
      ryDate: {
        el: `第${report.period}期 ${report.ryStartDate} ～ ${report.ryEndDate}`
      },
      ryAmount: {
        el: comma3(report.ryAmount),
        className: 'cost',
      },
      mgZan: {
        el: comma3(calcMgZan(report)),
        className: 'cost',
      },
      adZan: {
        el: comma3(calcAdZan(report)),
        className: 'cost',
      },
      overRy: {
        el: comma3(calcOverRy(report)),
        className: 'cost',
      },
    })) ?? [];

    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });

    /** ロイヤリティ金額合計 */
    const ryAmountTotal = sortedList
      .reduce((prev, cur) => prev.plus(new BN(cur.ryAmount)), new BN(0))
      .toNumber();
    /** オーバーロイヤリティ合計 */
    const overRyTotal = sortedList
      .reduce((prev, cur) => prev.plus(new BN(calcOverRy(cur))), new BN(0))
      .toNumber();

    // 合計行を追加
    //@ts-expect-error
    records.push({
      _tr: {
        className: 'total',
      },
      proposalNo: {
        el: '',
        colSpan: 3,
      },
      ryDate: {
        className: 'split-total-head',
        el: (
          <span className="split-total-head__box">合計</span>
        ),
      },
      ryAmount: {
        className: 'cost fwb bb-solid-tblcolor split-total-body',
        el: comma3(ryAmountTotal),
      },
      mgZan: {
        el: ''
      },
      adZan: {
        className: 'split-total-head',
        el: (
          <span className="split-total-head__box">合計</span>
        ),
      },
      overRy: {
        className: 'cost fwb bb-solid-tblcolor split-total-body',
        el: comma3(overRyTotal),
      },
    });

    return records;
  }, [calcAdZan, calcMgZan, calcOverRy, sortedList]);
}

/**
 * RY報告のリストを売上送信日時の昇順にソートする
 * @param {ApprovedRyReport[]} ryReportList RY報告のリスト
 */
const useSortedList = (ryReportList) => {
  return useMemo(() =>
    [...ryReportList].sort((a, b) =>
      dayjs(a.approvalDatetime, approvalDatetimeFormat).diff(dayjs(b.approvalDatetime, approvalDatetimeFormat), 'seconds')
    ), [ryReportList]);
}

/**
 * 対象RY報告以前に受領済みのRY報告の取得取得
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useGetBeforeAppReports = (sDecision) => {
  /**
   * 指定のRY報告以前に受領されたRY報告のリストを取得する
   */
  const getBeforeAppReports = useCallback(
    /**
     * @param {ApprovedRyReport} report 取得対象のRY報告
     */
    (report) => {
      const targetDate = dayjs(report.approvalDatetime, approvalDatetimeFormat);
      return sDecision?.appRyReportList.filter(r => {
        const date = dayjs(r.approvalDatetime, approvalDatetimeFormat);
        return date.isBefore(targetDate);
      }) ?? [];
    }, [sDecision?.appRyReportList]);

  return getBeforeAppReports;
}

/**
 * 対象RY報告以前に受領済みのロイヤリティ金額合計計算処理
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useCalcBeforeRyAmountTotal = (sDecision) => {
  const getBeforeAppReports = useGetBeforeAppReports(sDecision);

  /**
   * 指定のRY報告以前に受領されたRY報告のロイヤリティ金額の合計を計算する
   */
  const calcBeforeRyAmountTotal = useCallback(
    (report) => {
      const BN = BigNumber.clone({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
      });
      const targets = getBeforeAppReports(report);
      return targets.reduce((prev, cur) =>
        prev.plus(new BN(cur.ryAmount)), new BN(0))
    }, [getBeforeAppReports]);

  return calcBeforeRyAmountTotal;
}

/**
 * MG残高計算処理
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useCalcMgZan = (sDecision) => {
  const calcBeforeRyAmountTotal = useCalcBeforeRyAmountTotal(sDecision);

  /**
   * MG残高を計算する
   */
  const calcMgZan = useCallback(
    /**
     * @param {ApprovedRyReport} report 計算対象のRY報告
     */
    (report) => {
      const BN = BigNumber.clone({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
      });

      const beforeRyAmountTotal = calcBeforeRyAmountTotal(report);
      const tmp = (new BN(sDecision?.mg ?? 0))
        .minus(new BN(report.ryAmount))
        .minus(beforeRyAmountTotal);

      return BN.max(tmp, 0).toNumber();
    }, [calcBeforeRyAmountTotal, sDecision?.mg]);

  return calcMgZan;
}

/**
 * アドバンス残高計算処理
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useCalcAdZan = (sDecision) => {
  const calcBeforeRyAmountTotal = useCalcBeforeRyAmountTotal(sDecision);

  /**
   * アドバンス残高を計算する
   */
  const calcAdZan = useCallback(
    /**
     * @param {ApprovedRyReport} report 計算対象のRY報告
     */
    (report) => {
      const BN = BigNumber.clone({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
      });

      const beforeRyAmountTotal = calcBeforeRyAmountTotal(report);
      const tmp = (new BN(sDecision?.ad ?? 0))
        .minus(new BN(report.ryAmount))
        .minus(beforeRyAmountTotal);

      return BN.max(tmp, new BN(0)).toNumber();
    }, [calcBeforeRyAmountTotal, sDecision?.ad]);

  return calcAdZan;
}

/**
 * オーバーロイヤリティ計算処理
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useCalcOverRy = (sDecision) => {
  const calcBeforeRyAmountTotal = useCalcBeforeRyAmountTotal(sDecision);

  /**
   * オーバーロイヤリティの計算を行う
   */
  const calcOverRy = useCallback(
    /**
     * @param {ApprovedRyReport} report 計算対象のRY報告
     */
    (report) => {
      const BN = BigNumber.clone({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
      });

      const beforeRyAmountTotal = calcBeforeRyAmountTotal(report);
      const beforeOverRyTotal = BN.max(
        beforeRyAmountTotal
          .minus(new BN(sDecision?.ad ?? 0)),
        new BN(0),
      );
      const tmp = (new BN(report.ryAmount))
        .plus(beforeRyAmountTotal)
        .minus(new BN(sDecision?.ad ?? 0))
        .minus(beforeOverRyTotal);

      return BN.max(tmp, new BN(0)).toNumber();
    }, [calcBeforeRyAmountTotal, sDecision?.ad]);

  return calcOverRy;
}

//#region typedef
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord
 */
/**
 * @typedef {import('../../../slices/licensee/sDecisionsSlice').SDecisionDetail} SDecisionDetail
 */
/**
 * @typedef {ElementOf<SDecisionDetail['appRyReportList']>} ApprovedRyReport
 */
/**
 * @typedef {T[number]} ElementOf
 * @template {unknown[]} T
 */
//#endregion
