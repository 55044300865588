//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessagePopup } from '../../common/MessagePopup'
import { useFirstNotAcceptedReportId } from './hooks';
import { useNotAcceptedAlertConfirm } from './AlertConfirmContext';
import { useNavigate } from 'react-router-dom';

const message = `これより以前にロイヤリティ報告がされていない期間が存在します。
このまま本報告を承認すると、ロイヤリティ報告受領の順番が前後してしまう可能性があります。
過去の未報告期間の確認をしますか？`;

/**
 * 未受領RY報告の警告ポップアップ
 * @param {object} params
 * @param {RoyaltyDetail|undefined} params.royalty RY報告詳細
 * @param {ProposalDetail|undefined} params.proposal 企画詳細
 */
export const NotAcceptedAlert = ({
  royalty,
  proposal,
}) => {
  const navigate = useNavigate();
  const { notAcceptedAlertConfirm } = useNotAcceptedAlertConfirm();

  // ポップアップ確認済みのRY報告ID
  const [confirmedReportId, setConfirmedReportId] = useState(/** @type {number|null} */(null));
  // チェック対象のRY報告ID
  const [checkingReportId, setCheckingReportId] = useState(/** @type {number|null} */ (null));

  /** 未受領RY報告ID */
  const notAcceptedReportId = useFirstNotAcceptedReportId({ royalty, proposal });

  // チェック対象のRY報告IDと現在表示中のデータが異なる場合に確認済みIDをクリア
  useEffect(() => {
    if (royalty?.ryReportId == null) {
      return;
    }
    if (royalty.ryReportId === checkingReportId) {
      return;
    }
    setCheckingReportId(royalty.ryReportId);
    setConfirmedReportId(null);
  }, [checkingReportId, royalty?.ryReportId]);

  /** ポップアップを閉じたときの処理 */
  const onClose = useCallback(
    /** @param {'ok'|'cancel'|'close'} btn */
    btn => {
      if (btn === 'ok') {
        // 未受領のロイヤリティ報告詳細にリダイレクト
        navigate(`/aniplex/RoyaltyReportDetail/${notAcceptedReportId}`);
      } else {
        // キャンセルされた後で上代変更や未報告証紙の警告を表示するため、
        // 未受領報告アラート確認済みフラグを立てる
        notAcceptedAlertConfirm();
      }
      setConfirmedReportId(royalty?.ryReportId ?? null);
    },
  [navigate, notAcceptedAlertConfirm, notAcceptedReportId, royalty?.ryReportId]);

  return useMemo(() => {
    if (notAcceptedReportId == null) {
      return null;
    }
    if (confirmedReportId === royalty?.ryReportId) {
      return null;
    }

    return (
      <MessagePopup
        message={message}
        btn={{ ok: 'はい', cancel: 'キャンセル' }}
        btnClass='bg-aniplex'
        swapBtn
        onClose={onClose} />
    );
  }, [confirmedReportId, notAcceptedReportId, onClose, royalty?.ryReportId]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RoyaltyDetail} RoyaltyDetail
 */
//#endregion typedef
