//@ts-check
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SpannableTableView } from '../../common/table/SpannableTableView';

/** @type {TableHeader[]} */
const headers = [
  { id: 'proposalNo', label: '企画書No', style: { width: '100px' } },
  { id: 'proposalTitle', label: '企画件名', style: { width: '600px' } },
  { id: 'propertySummaryName', label: '作品名称', style: { width: '300px' } },
  { id: 'licenseeNameKanji', label: '取引先名', style: { width: '300px' } },
];

/**
 * 契約に紐づく企画のリスト
 * @param {object} params
 * @param {SDecisionProposal[]} params.proposalList 企画のリスト
 */
export const ProposalList = ({ proposalList }) => {
  const records = useViewRecords(proposalList)

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title">契約に紐づく企画書一覧</h2>
      </div>

      {/* @ts-expect-error */}
      <SpannableTableView
        className='mt10 border0 scroll'
        headers={headers}
        records={records}
        scrollable
        resizable />
    </section>
  )
}

/**
 * 表示用のレコードを取得する
 * @param {SDecisionProposal[]} proposalList 企画のリスト
 * @returns {TableRecord[]}
 */
const useViewRecords = (proposalList) => {
  return useMemo(() =>
    proposalList.map(proposal => ({
      proposalNo: {
        el: (
          // @ts-expect-error
          <Link to={`/aniplex/proposalDetail/${proposal.proposalId}`}>{proposal.proposalNo}</Link>
        )
      },
      proposalTitle: {
        el: proposal.proposalTitle,
      },
      propertySummaryName: {
        el: proposal.propertySummaryName,
      },
      licenseeNameKanji: {
        el: proposal.licenseeNameKanji,
      },
    }))
    , [proposalList]);

}


//#region typedef
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord
 */
/**
 * @typedef {import('../../../slices/aniplex/sDecisionsSlice').SDecisionProposal} SDecisionProposal
 */
//#endregion
