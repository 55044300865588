import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja'
import { AwsConfig } from "./awsConfig";
import { Amplify } from "aws-amplify";
import { Config } from './config';

if (/^$|^#\/?$/.test(window.location.hash)) {
  // 画面のパスが設定されていないときはデフォルト設定に応じて表示先を切り替え
  if (Config.ForAniplex) {
    window.location.hash = '#/aniplex/';
  } else {
    window.location.hash = '#/licensee/';
  }
}

// トークン受信時ANIX側画面を表示
if (window.location.search.startsWith('?code=')) {
  window.location.hash = "#/aniplex/";
}

// ANIX時のみAmplifyを初期化
if (window.location.hash.startsWith('#/aniplex')) {
  Amplify.configure(AwsConfig);
}

registerLocale('ja', ja);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
