//@ts-check
import React from 'react';
import { comma3 } from '../../../lib/util';

/**
 * 契約状況
 * @param {object} params
 * @param {?SDecisionDetail} params.sDecision S決裁情報
 */
export const ContractStatus = ({ sDecision }) => {
  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title">契約状況</h2>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '50px' }}>MG</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input
                type='text'
                name='MG'
                title='MG'
                aria-label='MG'
                disabled
                value={comma3(sDecision?.mg ?? '')} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">アドバンス金額</dt>
          <dd className="form-body">
            <div className="input-form wdt200 cost">
              <input
                type='text'
                name='アドバンス金額'
                title='アドバンス金額'
                aria-label='アドバンス金額'
                disabled
                value={comma3(sDecision?.ad ?? '')} />
            </div>
          </dd>
        </dl>
      </div>
    </section>
  )
}

//#region
/**
 * @typedef {import('../../../slices/aniplex/sDecisionsSlice').SDecisionDetail} SDecisionDetail
 */
