/**
 * ヘルプメッセージ
 * @param {object} props
 * @param {string} [props.title] タイトル
 * @param {string} [props.description] 説明文
 */
export const HelpMessage = ({ title, description }) => {
  return (
    <>
      <p>
        {title}
        <span className='help'>?
          <span className="description wsPre">{description}</span>
        </span>
      </p>
    </>
  );
}
