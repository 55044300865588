import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { clearApiStatus, selectApiStatus, updateKikakuUserId } from "../../../slices/licensee/proposalsSlice";
import { AssignUserPopup } from "./AssignUserPopup";
import { isEmpty } from "../../../lib/validator";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { getMessage } from "../../../lib/message";

/**
 * 企画申請画面の企画担当者部分のフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {string} props.updateDatetime 最終更新日時
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject<{}>} props.formRefs フォーム項目へのref
 * @param {OnChange} props.onChange フォームの入力内容変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @returns
 */
export const AssignUserForm = ({
  data,
  updateDatetime,
  isNew,
  licenseeUsers,
  formLocked,
  formRefs,
  onChange,
  onValidated,
  validateErrors,
  validateAllFlg,
}) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateKikakuUserId;
  const [selectedUser, setSelectedUser] = useState(null); // 選択中のユーザー

  /**
   * ポップアップ
   */
  const [popup, setPopup] = useState({
    showFlg: false,
    onClose: null,
  });

  /**
   * ポップアップ表示
   */
  const onClick = useCallback(() => {
    setPopup({
      showFlg: true,
      onClose: (btn, user) => {
        if (btn === 'submit') {
          setSelectedUser(user);
          if (isNew) {
            // 新規作成の場合、呼び出し元に返してポップアップを閉じる
            onChange('kikakuUserName', user.label);
            onChange('kikakuUserId', user.value);
          } else {
            // 編集の場合、更新処理を呼び出す
            dispatch(updateKikakuUserId({
              proposalId: data.proposalId,
              updateDatetime,
              userId: user.value,
            }));
            // まだポップアップを閉じない
            return;
          }
        }
        setPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [isNew, onChange, dispatch, data.proposalId, updateDatetime]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // 更新完了
      onChange('kikakuUserName', selectedUser.label);
      onChange('kikakuUserId', selectedUser.value);

      // API通信状況をクリアしてポップアップを閉じる
      dispatch(pushMessage('企画担当者を設定しました。'));
      dispatch(clearApiStatus('updateKikakuUserId'));
      setPopup({
        showFlg: false,
        onClose: null,
      });
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('企画担当者の設定中にエラーが発生しました。'));
      dispatch(clearApiStatus('updateKikakuUserId'));
      return;
    }
  }, [apiStatus, onChange, dispatch, selectedUser]);

  // バリデーション
  useEffect(() => {
    const errors = validateKikakuUserId(data.kikakuUserId);
    if (validateAllFlg) {
      // 強制バリデーション時、必須確認
      onValidated('kikakuUserId', errors.apply);
    }
  }, [onValidated, validateAllFlg, data.kikakuUserId]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <section className="mt40">
        <div className="title-pink">
          <h2 className="title">企画担当者</h2>
        </div>

        <span className="c-pink">企画の責任者を設定してください。※申請者と同様の閲覧・編集権限が付与されます</span>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required" style={{ width: '100px' }}>企画担当者</dt>
            <dd className="form-body">
              <div className="input-form" style={{ width: '240px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input type="text"
                  title="企画担当者を必ず選択してください"
                  aria-label="企画担当者"
                  disabled
                  ref={formRefs.current.kikakuUserId}
                  value={data.kikakuUserName ?? ''}
                />
                {
                  <p className="btn label c-pink wdt100 ml20" style={{ width: '80px' }}>
                    <button
                      disabled={formLocked}
                      onClick={onClick}
                    >設定</button>
                  </p>
                }
              </div>
              {
                popup.showFlg ? (
                  <AssignUserPopup
                    data={data}
                    licenseeUsers={licenseeUsers}
                    onClose={popup.onClose}
                  />
                ) : null
              }
              {<ErrorMessageList messages={validateErrors?.kikakuUserId ?? []} /> }
            </dd>
          </dl>
        </div>
      </section>

      {loading}
    </>
  );
};

/**
 * フォーム入力内容をバリデートする
 * @param {keyof FormData} name 入力された項目名
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(name, value) {
  switch (name) {
    // 企画担当者
    case 'kikakuUserId':
      return validateKikakuUserId(value);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: [] };
  }
}

/**
 * 企画担当者のバリデートを行う
 * @param {string} value 入力された値
 * @returns {{ tmp: string[], apply: string }} エラーメッセージ
 */
function validateKikakuUserId(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.tmp.push(getMessage('isNotEmpty'));
    errors.apply.push(getMessage('isNotEmpty'));
  }

  return errors;
}
