//@ts-check
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Constants } from '../../../Constants';

/**
 * 受領済みロイヤリティ報告フラグ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
export const useIsAcceptedReport = (royalty) => {
  // 売上送信済み、0円報告済みを受領済みと判定
  /** @type {valueOf<Constants['Aniplex']['reportStatus']>[]} */
  const statuses = [
    Constants.Aniplex.reportStatus.Exported,
    Constants.Aniplex.reportStatus.ReportedZero,
  ];

  return royalty != null && statuses.includes(royalty?.reportStatus);
}

/**
 * 未受領のRY報告のうち最初の期を取得する
 * @param {RoyaltyDetail|undefined|null} royalty RY報告詳細
 */
const useFirstNotAcceptedPeriod = (royalty) => {
  return useMemo(() => {
    const periods = (new Array((royalty?.period ?? 1) - 1))
      .fill(null).map((_, i) => (i + 1));

    // 過去のロイヤリティ報告状況に存在しない期は未受領と判定
    return periods.find(p => royalty?.ryReportHistory.find(h => h.period === p) == null);
  }, [royalty?.period, royalty?.ryReportHistory]);
}

/**
 * 未受領のRY報告のうち一番古いもののIDを取得する
 * @param {object} params
 * @param {RoyaltyDetail|null|undefined} params.royalty ロイヤリティ報告詳細
 * @param {ProposalDetail|null|undefined} params.proposal 企画詳細
 */
export const useFirstNotAcceptedReportId = ({
  royalty,
  proposal,
}) => {
  /** 未受領の期 */
  const targetPeriod = useFirstNotAcceptedPeriod(royalty);

  return useMemo(() => {
    if (targetPeriod == null) {
      return null;
    }

    return proposal?.periodList
      .find(pr => pr.period === targetPeriod)?.ryReportId;
  }, [proposal?.periodList, targetPeriod]);
}

/**
 * 過去に未受領のロイヤリティ報告が存在するかのフラグ
 * @param {RoyaltyDetail|null|undefined} royalty ロイヤリティ報告詳細
 */
export const useNotAcceptedReportExists = (royalty) => {
  const notAcceptedPeriod = useFirstNotAcceptedPeriod(royalty);

  return useMemo(() => {
    return notAcceptedPeriod != null;
  }, [notAcceptedPeriod]);
}

/**
 * ロイヤリティ金額の合計
 * @param {?RyAmount[]} ryAmountList 全商品分のロイヤリティ金額情報
 */
export const useRyAmountTotal = (ryAmountList = []) => {
  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });
    return (ryAmountList ?? [])
      .reduce((prev, cur) => prev.plus(new BN(cur.calReportRyAmount ?? 0)), new BN(0))
      .dp(0)
      .toNumber();
  }, [ryAmountList]);
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告詳細情報
 */
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RyAmount} RyAmount ロイヤリティ金額情報
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
