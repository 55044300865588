import dayjs from 'dayjs';
import { read, utils } from 'xlsx';
import { getRypInputFlg } from './royalty';
import { isEmpty } from './validator';

// 商品登録用Excelの処理

/** フォーマットバージョン */
const formatVer = 'ROYS_v1.0';

/** 対象シート名 */
const sheetName = '商品登録'

/** データ領域の基準となるヘッダの内容 */
const headerLeftCell = '＜結果＞'

/** データ領域の開始列番号 */
const dataAreaLeft = 2;
/** データ領域の終了列番号 */
const dataAreaRight = 46;

/** 結果列の列番号 */
const checkResultCol = 0;

/** プロパティと列番号のマッピング */
const propColMap = {
  productName: { col: 2 }, // 商品名
  categoryNo: { col: 49, type: 'number' }, // 商品カテゴリNo
  categoryDetailNo: { col: 50, type: 'number' }, // 商品カテゴリ詳細No
  character: { col: 5 }, // キャラクター
  version: { col: 6 }, // バージョン
  launchDate: { col: 7 }, // 発売希望日
  rypId: { col: 51 }, // ロイヤリティ報告パターンNo
  planPrice: { col: 9, type: 'number' }, // 予定上代
  planCost: { col: 10, type: 'number' }, // 予定製造原価
  planProceeds: { col: 11, type: 'number' }, // 予定売上金額
  ryRate: { col: 12, type: 'number' }, // ロイヤリティ料率
  ryPrice:{ col: 13, type: 'number' }, // ロイヤリティ単価
  planRyAmount: {col: 14, type: 'number' }, // 予定ロイヤリティ金額
  salesMethod: {col: 52 }, // 販売方式
  productMethod: {col: 53 }, // 生産方式
  material: { col: 17 }, // 素材他仕様
  characterLineup: { col: 18 }, // キャラクターラインナップ
  productRemarks: { col: 19 }, // 備考
  productOption1: { col: 20 }, // 自由入力欄1
  productOption2: { col: 21 }, // 自由入力欄2
  productOption3: { col: 22 }, // 自由入力欄3
};

/** 第N期の開始列 */
const periodBeginCol = 23;
/** 1期ごとの列の数 */
const colPerPeriod = 2;
/** 第N期の数 */
const maxPeriod = 12;
/** N期のプロパティと列番号のマッピング */
const periodPropColMap = {
  // 予定生産数
  planProduction: { col: 0, type: 'number' },
  // 予定販売数
  planSales: { col: 1, type: 'number' },
};

/** チェック結果OK */
const CheckOK = 'OK';
/** フォーマットエラー時のエラー文言 */
const formatErrMsg = 'Excelによる商品登録は指定フォーマットを利用してください。指定フォーマットはフォーマットダウンロードボタンからダウンロードしてください。';
/** チェック未実施のエラー文言 */
const uncheckedErrMsg = 'Excelのデータチェックボタンを実行し、登録する商品情報を確認してください。';
/** チェック結果NGのエラー文言 */
const invalidErrMsg = 'Excelに不正データが含まれています。Excelのデータチェックボタンを実行し、登録する商品情報を再確認してください。';

/**
 * 商品登録用のExcelファイルをパースして商品情報を取得する
 * @param {File} file パース対象のExcelファイル
 * @returns {import('../slices/licensee/proposalsSlice').ProposalProduct[]} パースした商品情報
 */
export async function parseProductExcel(file) {
  const workbook = read(await file.arrayBuffer(), { cellDates: true })
  const sheet = workbook.Sheets[sheetName];

  if (sheet == null) {
    // 対象シートが存在しない
    throw new Error(formatErrMsg);
  }

  if (sheet.A1?.v !== formatVer) {
    // フォーマットバージョン不正
    throw new Error(formatErrMsg);
  }

  const json = utils.sheet_to_json(sheet, { header: 1, blankrows: true });
  const maxCol = json.reduce((prev, cur) => Math.max(prev, cur.length), 0);

  /** Excelシート自体のデータの開始行 */
  let sheetBeginRow = 0
  if (sheet['!ref']) {
    const begin = sheet['!ref'].split(':')[0];
    const match = /^[A-Z]+(\d+)$/.exec(begin);
    if (match) {
      sheetBeginRow = parseInt(match[1]);
    }
  }

  /** データ領域の左上のセルの座標 */
  const base = {
    row: 0,
    col: 0,
  }

  for (let i = 0; i < json.length; i++) {
    for (let j = 0; j < maxCol; j++) {
      const cell = json[i][j];
      if (cell === headerLeftCell) {
        base.row = i + 4; // ヘッダ2行目、説明行、例をスキップ
        base.col = j;
      }
    }
  }

  const products = [];
  json.slice(base.row)
    .forEach((row, idx) => {
      if (!rowHasData(row, base.col)) {
        // データが存在しない行は飛ばす
        return;
      }

      // 結果列のチェック
      if (isEmpty(row[base.col + checkResultCol])) {
        // チェック未実施
        throw new Error(uncheckedErrMsg);
      }
      if (row[base.col + checkResultCol] !== CheckOK) {
        // チェック結果NG
        throw new Error(invalidErrMsg);
      }

      const product = {};
      const rypId = row[base.col + propColMap.rypId.col];

      // 各プロパティの処理
      for (const prop in propColMap) {
        if (!checkCanInput(prop, rypId)) {
          // 入力不可のプロパティはnullを入れる
          product[prop] = null;
          continue;
        }

        const rowNum = sheetBeginRow + base.row + idx
        const colNum = base.col + propColMap[prop].col;
        let val = row[colNum] ?? null;
        if (propColMap[prop].type === 'number' && val != null && !isNaN(Number(val))) {
          val = Number(val);
        } else if (propColMap[prop].type === 'date') {
          const cell = getCellInfo(sheet, rowNum, colNum);
          const str = getDateCellText(cell);
          if (str != null) {
            val = str;
          }
        } else if (val != null) {
          val = String(val);
        }
        product[prop] = val;
      }

      // 第N期の処理
      const periodList = [];
      for (let n = 0; n < maxPeriod; n++) {
        const period = {
          period: n + 1,
        };
        const baseCol = base.col + periodBeginCol + n * colPerPeriod;
        for (const prop in periodPropColMap) {
          if (!checkCanInput(prop, rypId)) {
            // 入力不可のプロパティはnullを入れる
            period[prop] = null;
            continue;
          }
          let val = row[baseCol + periodPropColMap[prop].col] ?? null;
          if (periodPropColMap[prop].type === 'number' && val != null && !isNaN(Number(val))) {
            val = Number(val);
          } else if (val != null) {
            val = String(val);
          }
          period[prop] = val;
        }
        periodList.push(period)
      }
      product.periodList = periodList;

      products.push(product);
    });
  return products;
}

/**
 * データが入力されているかを判定する
 * @param {*} row Excelから読み込んだデータ行
 * @param {number} baseCol データ領域の開始列
 * @returns {boolean} データが入力されているか
 */
function rowHasData(row, baseCol) {
  for (let i = baseCol + dataAreaLeft; i <= baseCol + dataAreaRight; i++) {
    if (!isEmpty(row[i])) {
      return true;
    }
  }
  return false;
}

/**
 * 対象のロイヤリティ報告パターンで対象のプロパティが入力可能か判定する
 * @param {*} prop 判定対象のプロパティ
 * @param {*} rypId ロイヤリティ報告パターンID
 * @returns {boolean} 入力可能な場合はtrue
 */
function checkCanInput(prop, rypId) {
  const inputFlg = getRypInputFlg(rypId, 'licensee');

  // フラグ名とプロパティ名が異なるものの名前を変換
  const target = (() => {
    switch (prop) {
      case 'planPrice':
        return 'price';
      case 'planCost':
        return 'cost';
      case 'planRyAmount':
        return 'ryAmount';
      case 'planProduction':
        return 'production';
      case 'planSales':
        return 'sales';
      default:
        return prop;
    }
  })();

  if (!Object.prototype.hasOwnProperty.call(inputFlg, target)) {
    // ロイヤリティ報告パターンの制御対象でない場合は常に入力可能
    return true;
  }

  return inputFlg[target];
}

/**
 * 行番号と列番号からセルの情報を返す
 * @param {*} sheet 対象のシート情報
 * @param {number} row 行番号
 * @param {number} col 列番号
 */
function getCellInfo(sheet, row, col) {
  const ref = base26(col + 1) + row;
  return sheet[ref] ?? null;
}

/**
 * 日付型のデータが入ったセルからExcel上で表示されている文字列を取得する
 * @param {*} cell 対象のセルの情報
 * @returns {?string} Excel上で表示される文字列.日付型のセルでない場合はnull
 */
function getDateCellText(cell) {
  if (!cell) {
    return null;
  }

  if (cell.t !== 'd') {
    // 日付型のデータ以外はnullを返すことでエラー扱いとする
    return null;
  }

  // 書式がFMT14(デフォルト)の場合は英語圏の表記にされるので日本の形式に変換
  const date = cell.v;
  const check = dayjs(date).format('M/D/YY');
  if (cell.w === check) {
    return dayjs(date).format('YYYY/M/D');
  }

  return cell.w;
}

/**
 * アルファベット形式の26進数に変換する(Excelの列番号用)
 * @param {number} val 変換対象の数値
 */
function base26(val) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const base = chars.length;
  const result = [];
  let next = val

  while (next >= 1) {
    let mod = next % base;
    next = Math.floor(next / base);
    if (mod === 0) {
      mod = base;
      next -= 1;
    }
    result.unshift(chars[mod - 1]);
  }

  return result.join('');
}
