//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Constants } from "../../../Constants";
import { useAuthRedirect } from "../../../lib/hooks/licensee"
import {
  clearApiStatus,
  clearAppliedProposalId,
  clearProposalDetail,
  applyProposal,
  deleteProposal,
  cancelProposal,
  fetchProposalDetail,
  selectApiStatus,
  selectAppliedProposalId,
  selectProposalDetail,
  permitUpdateProposal,
  selectPatchProposalResult,
  clearPatchProposalResult
} from "../../../slices/licensee/proposalsSlice";
import {
  selectApiStatus as selectUtilApiStatus,
} from "../../../slices/licensee/utilSlice";
import {
  fetchUsers,
  clearApiStatus as clearUsersApiStatus,
  clearFetchUsers,
  selectApiStatus as selectUsersApiStatus,
  fetchUserList,
} from "../../../slices/licensee/licenseeUsersSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { ProposalDetailForm } from "./ProposalDetailForm"
import dayjs from "dayjs";

/**
 * 企画申請画面
 * @returns
 */
export const ProposalDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus);
  const utilApiStatus = useSelector(selectUtilApiStatus);
  const appliedProposalId = useSelector(selectAppliedProposalId);
  const proposalDetail = useSelector(selectProposalDetail);
  const usersApiStatus = useSelector(selectUsersApiStatus);
  const userList = useSelector(fetchUserList);
  const licenseeUsers = useMemo(() => {
    return userList?.result?.data || [];
  }, [userList]);
  const authRedirect = useAuthRedirect();
  /** react-routerによるパラメータ */
  const routerParams = useParams();

  // 保存タイプ
  const [saveType, setSaveType] = useState(/** @type {?SaveType} */ (null));
  /** 更新操作による最終更新日時 */
  const patchedUpdateDatetime = usePatchedUpdateDatetime();
  /** 最終更新日時 */
  const updateDatetime = useMemo(() => {
    if (patchedUpdateDatetime == null) {
      return proposalDetail?.updateDatetime ?? null;
    }
    if (proposalDetail?.updateDatetime == null) {
      return null;
    }
    // より新しい方の日付を返す
    const patchedDate = dayjs(patchedUpdateDatetime, 'YYYY/MM/DD HH:mm:ss');
    const loadedDate = dayjs(proposalDetail.updateDatetime, 'YYYY/MM/DD HH:mm:ss');
    return (patchedDate.isAfter(loadedDate) ? patchedDate : loadedDate).format('YYYY/MM/DD HH:mm:ss');
  }, [patchedUpdateDatetime, proposalDetail?.updateDatetime]);
  /** 企画内部コード */
  const proposalId = useMemo(() => {
    return routerParams.proposalId;
  }, [routerParams.proposalId])
  /** 新規登録モードフラグ */
  const isNew = useMemo(() => {
    return proposalId == null;
  }, [proposalId]);

  // マウント時にライセンシーユーザー一覧情報を取得
  useEffect(() => {
    dispatch(fetchUsers());
    return () => {
      // 画面離脱時にAPI通信関連の内容をクリアする
      dispatch(clearApiStatus('applyProposal'));
      dispatch(clearAppliedProposalId());
      dispatch(clearApiStatus('deleteProposal'));
      dispatch(clearApiStatus('cancelProposal'));
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(clearProposalDetail());

      dispatch(clearUsersApiStatus('fetchUsers'));
      dispatch(clearFetchUsers());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isNew) {
      // 編集モードの場合は対象のデータを取得する
      dispatch(fetchProposalDetail(Number(proposalId)));
    } else {
      // 対応する企画が存在しない場合の対応
      dispatch(clearProposalDetail());
    }
  }, [dispatch, isNew, proposalId]);

  useEffect(() => {
    if (apiStatus.fetchProposalDetail === 'error') {
      dispatch(pushMessage('企画情報の取得中にエラーが発生しました。'));
    }
  }, [apiStatus.fetchProposalDetail, dispatch]);

  // フォームから渡されたコールバック
  const [onSaveComplete, setOnSaveComplete] = useState(/** @type {Function|null} */(null));

  useEffect(() => {
    if (apiStatus.applyProposal === 'finish') {
      // API成功時はメッセージを表示
      if (saveType === Constants.Licensee.ProposalStatus.Requesting) {
        dispatch(pushMessage('企画書の申請を行いました。'));
      } else {
        dispatch(pushMessage('企画書の内容を一時保存しました。'));
      }
      // API通信状況をクリア
      dispatch(clearApiStatus('applyProposal'));

      // フォームから渡されたコールバックを実行
      if (onSaveComplete) {
        onSaveComplete();
        setOnSaveComplete(null);
      }

      if (isNew) {
        // 企画内部コード付きのURLに変更
        navigate(`/licensee/proposalDetail/${appliedProposalId}`)
      } else {
        // 企画情報をリロード
        dispatch(fetchProposalDetail(Number(proposalId)));
      }
    } else if (apiStatus.applyProposal === 'error') {
      setOnSaveComplete(null);
    }
  }, [apiStatus.applyProposal, appliedProposalId, dispatch, isNew, navigate, proposalId, saveType, onSaveComplete]);

  useEffect(() => {
    if (apiStatus.deleteProposal === 'finish') {
      // 削除API成功時
      // メッセージを表示
      dispatch(pushMessage('企画を削除しました。'));
      // 企画一覧画面へ遷移
      navigate('/licensee/proposalList');
    }
  });

  useEffect(() => {
    if (apiStatus.cancelProposal === 'finish') {
      // 取り下げAPI成功時
      dispatch(pushMessage('企画の取り下げを行いました。'));
      dispatch(clearApiStatus('cancelProposal'));
      dispatch(fetchProposalDetail(Number(proposalId)));
    }
  });

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    // データ取得中および送信中はフォーム入力を抑制する
    return apiStatus.fetchProposalDetail === 'loading'
      || apiStatus.fetchProposalDetail === 'error'
      || apiStatus.applyProposal === 'loading'
      || apiStatus.applyProposal === 'finish'
      || apiStatus.deleteProposal === 'loading'
      || apiStatus.deleteProposal === 'finish'
      || apiStatus.cancelProposal === 'loading'
      || apiStatus.cancelProposal === 'finish'
      || usersApiStatus.fetchUsers === 'loading'
      || usersApiStatus.fetchUsers === 'error';
  }, [apiStatus.applyProposal, apiStatus.cancelProposal, apiStatus.deleteProposal, apiStatus.fetchProposalDetail, usersApiStatus.fetchUsers]);

  /** 申請・一時保存ボタン押下時のコールバック */
  const onSubmit = useCallback(
    /** @param {PostProposalParam} params */
    (params, onComplete) => {
      // 申請か一時保存かを保持
      setSaveType(params.proposalStatus);
      // 企画申請API呼び出し
      dispatch(applyProposal({ ...params, updateDatetime: updateDatetime ?? undefined }));
      // API呼び出し完了時に呼ばれる
      setOnSaveComplete(onComplete);
    },
    [dispatch, updateDatetime]
  );

  /** 削除ボタン押下時のコールバック */
  const onDelete = useCallback(
    /** @param {Proposal} proposal 対象の企画情報 */
    (proposal) => {
      // 新規の場合はメッセージを表示して一覧画面へ遷移
      if (isNew) {
        dispatch(pushMessage('企画を削除しました。'));
        navigate('/licensee/proposalList');
        return;
      }
      // 編集モードの場合はAPIを呼び出す
      dispatch(deleteProposal({ proposal, updateDatetime }));
    },
    [dispatch, isNew, navigate, updateDatetime]
  )

  /** 取り下げボタン押下時のコールバック */
  const onCancel = useCallback(
    /**
     * @param {{ messageContent: string, proposal: Proposal}} params パラメータ
     */
    (params) => {
      dispatch(cancelProposal({...params, updateDatetime}));
    },[dispatch, updateDatetime]
  );

  // 更新開始処理
  const {
    onPermitUpdate,
    apiStatus: permitUpdateApiStatus,
  } = useOnPermitUpdate(proposalDetail, updateDatetime);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    const targetApiStatus = [
      apiStatus.applyProposal,
      apiStatus.deleteProposal,
      apiStatus.cancelProposal,
      apiStatus.fetchProposalDetail,
      apiStatus.copyProductImage,
      utilApiStatus.fetchDownloadUrl,
      permitUpdateApiStatus,
      usersApiStatus.fetchUsers,
    ];
    if (targetApiStatus.includes('loading')) {
      // @ts-expect-error
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus.applyProposal, apiStatus.cancelProposal, apiStatus.copyProductImage, apiStatus.deleteProposal, apiStatus.fetchProposalDetail, permitUpdateApiStatus, utilApiStatus.fetchDownloadUrl, usersApiStatus.fetchUsers]);

  // 未ログイン時は対応する画面へ遷移
  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    )
  }

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">企画書申請</h1>
        <p className="link-pageback deco-line c-pink">
          {/* @ts-expect-error */}
          <Link to={'/licensee/proposalList'}><i className="icn pageback"></i>企画一覧へ戻る</Link>
        </p>
      </div>

      {/* @ts-expect-error */}
      <ProposalDetailForm
        formLocked={formLocked}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onCancel={onCancel}
        onPermitUpdate={onPermitUpdate}
        isNew={isNew}
        licenseeUsers={licenseeUsers}
        loadedData={proposalDetail}
        updateDatetime={updateDatetime} />

      {loading}

    </div>
  )
}

/**
 * 企画更新系の処理によって更新された最終更新日時のカスタムフック
 * @returns {?string}
 */
const usePatchedUpdateDatetime = () => {
  const [updateDatetime, setUpdateDatetime] = useState(/** @type {?string} */ (null));

  const apiStatus = useSelector(selectApiStatus);
  const patchProposalResult = useSelector(selectPatchProposalResult);
  const dispatch = useDispatch();

  // 企画担当者更新時
  useEffect(() => {
    if (apiStatus.updateKikakuUserId === 'finish' && patchProposalResult != null) {
      setUpdateDatetime(patchProposalResult.updateDatetime);
      dispatch(clearApiStatus('updateKikakuUserId'));
      dispatch(clearPatchProposalResult());
    }
  }, [apiStatus.updateKikakuUserId, dispatch, patchProposalResult]);

  // 通知先変更時
  useEffect(() => {
    if (apiStatus.updateNoticeUserIdList === 'finish' && patchProposalResult != null) {
      setUpdateDatetime(patchProposalResult.updateDatetime);
      dispatch(clearApiStatus('updateNoticeUserIdList'));
      dispatch(clearPatchProposalResult());
    }
  }, [apiStatus.updateNoticeUserIdList, dispatch, patchProposalResult]);

  return updateDatetime;
}

/**
 * 更新開始ボタン押下時の処理
 * @param {?Proposal} proposal 対象の企画
 * @param {?string} updateDatetime 最終更新日時
 */
const useOnPermitUpdate = (proposal, updateDatetime) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).permitUpdateProposal;
  const [onSaveComplete, setOnSaveComplete] = useState(/** @type {Function|null} */(null));

  /**
   * 更新開始ボタン押下時のハンドラ
   */
  const onPermitUpdate = useCallback(
    /**
     * @param {string} messageContent 更新理由
     * @param {Function} onComplete 更新理由
     */
    (messageContent, onComplete) => {
      if (proposal == null) {
        // 処理順の関係でここに入ることはないはず
        return;
      }
      dispatch(permitUpdateProposal({ messageContent, proposal, updateDatetime }));
      setOnSaveComplete(onComplete);
    }, [dispatch, proposal, updateDatetime]);

  useEffect(() => {
    if (proposal == null) {
      // 処理順の関係でここに入ることはないはず
      return;
    }
    if (apiStatus === 'finish') {
      // 更新開始API成功時
      // TODO: メッセージ確認
      dispatch(pushMessage('企画の更新が可能になりました。'));
      dispatch(clearApiStatus('permitUpdateProposal'));
      dispatch(fetchProposalDetail(proposal.proposalId))

      if (onSaveComplete) {
        onSaveComplete();
        setOnSaveComplete(null);
      }
    } else if (apiStatus) {
      setOnSaveComplete(null);
    }
  }, [apiStatus, dispatch, proposal, onSaveComplete]);

  return {
    onPermitUpdate,
    apiStatus,
  }
}

//#region
/**
 * @typedef {import('../../../lib/api/licensee').PostProposalParam} PostProposalParam
 */
/**
 * @typedef {PostProposalParam['proposalStatus']} SaveType 保存種別
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} Proposal 企画情報
 */
//#endregion
