import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { Constants } from '../../../Constants';
import { getMessage } from '../../../lib/message';
import { isEmpty, maxLength } from '../../../lib/validator';
import { selectPropertySummaryMst } from '../../../slices/licensee/masterSlice';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { MessagePopup } from '../../common/MessagePopup';
import { SearchableListBox } from '../../common/SearchableListBox';

/** 契約終了日の最大値 */
const MAX_CONTRACT_END_DATE = dayjs('2049/12/31', 'YYYY/MM/DD');

/** このフォームで扱うプロパティ */
const targetProps = [
  'propertySummaryCode',
  'proposalTitle',
  'contractStartDate',
  'contractEndDate',
];

/**
 * 企画書申請画面の企画概要部分のフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject<{}>} props.formRefs フォーム項目へのref
 * @param {OnChange} props.onChange フォーム内容更新時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {import('./ProposalDetailForm').Product[]} props.productList 商品リスト
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @returns
 */
export const ProposalOverviewForm = ({ data, formLocked, formRefs, onChange, onValidated, productList, validateAllFlg = false, }) => {
  // 期間変更警告表示関連
  const [periodAlert, setPeriodAlert] = useState({
    /** アラート表示フラグ */
    showFlg: false,
    /** close時コールバック */
    onClose: null,
    /** アラート表示抑制フラグ */
    suppressAlert: false,
  });

  const [messages, setMessages] = useState({
    /** 作品コード */
    propertySummaryCode: [],
    /** 企画件名 */
    proposalTitle: [],
    /** 契約開始日 */
    contractStartDate: [],
    /** 契約終了日 */
    contractEndDate: [],
  });

  /** @type {FormData} フォームデータ */
  const formData = useMemo(() => {
    return {
      /** 作品コード */
      propertySummaryCode: '',
      /** 企画件名 */
      proposalTitle: '',
      /** 契約開始日 */
      contractStartDate: '',
      /** 契約終了日 */
      contractEndDate: '',
      ...data,
    }
  }, [data]);

  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertySummaryMst);

  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っているときは全項目をバリデートする
      const newMessages = {};
      targetProps.forEach(prop => {
        let value = formData[prop];
        if (prop === 'contractStartDate') {
          value = isEmpty(value) ? null : dayjs(value, 'YYYY/MM/DD').toDate();
        }
        const errors = validate(prop, value, formData);
        onValidated(prop, errors);
        newMessages[prop] = errors.apply;
      })
      setMessages(prevMessages => ({
        ...prevMessages,
        ...newMessages,
      }));
    }
  }, [validateAllFlg, formData, onValidated])

  /** 選択された作品 */
  const propertySummary = useMemo(() => {
    const found = propertyMst.find(i => i.propertySummaryCode === formData.propertySummaryCode);
    if (found == null) return null;

    return {
      label: found.propertySummaryName,
      value: found.propertySummaryCode,
    };
  }, [propertyMst, formData.propertySummaryCode]);

  /** 取引先名称 */
  const licenseeName = useMemo(() => {
    return (formData.licenseeCode ?? '') + ' ' + (formData.licenseeNameKanji ?? '');
  }, [formData.licenseeCode, formData.licenseeNameKanji]);

  /** 申請日 */
  const applicantDate = useMemo(() => {
    return isEmpty(formData.applicationDatetime) ?
      '' :
      dayjs(formData.applicationDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD');
  }, [formData.applicationDatetime]);

  /** ステータス */
  const proposalStatus = useMemo(() => {
    return Constants.Licensee.ProposalStatusName[formData.proposalStatus] ?? '';
  }, [formData.proposalStatus]);

  /** 契約開始日 */
  const contractStartDate = useMemo(() => {
    // 日付文字列をDateオブジェクトに変換
    return isEmpty(formData.contractStartDate) ?
      null :
      dayjs(formData.contractStartDate, 'YYYY/MM/DD').toDate();
  }, [formData.contractStartDate])

  /** 契約終了日 */
  const contractEndDate = useMemo(() => {
    // 日付文字列をDateオブジェクトに変換
    return isEmpty(formData.contractEndDate) ?
      null :
      dayjs(formData.contractEndDate, 'YYYY/MM/DD').toDate();
  }, [formData.contractEndDate]);

  /** 契約終了日の最小値 */
  const contractEndMinDate = useMemo(() => {
    return isEmpty(formData.contractStartDate) ?
      null :
      dayjs(formData.contractStartDate, 'YYYY/MM/DD').add(1, 'day').toDate();
  }, [formData.contractStartDate]);

  /**
   * フォーム更新ハンドラ
   * @type {(name: keyof FormData, value: *) => void}
   */
  const handleChange = useCallback((name, value) => {
    const errors = validate(name, value, formData);
    onValidated(name, errors);
    setMessages({
      ...messages,
      [name]: errors.apply,
    });
    if (name === 'contractStartDate') {
      value = isEmpty(value) ? '' : dayjs(value).format('YYYY/MM/DD');
      // 契約終了日も相関チェックする
      const endErrors = validate('contractEndDate', formData.contractEndDate, {
        ...formData,
        contractStartDate: value,
      });
      onValidated('contractEndDate', endErrors);
      setMessages(prevVal => ({
        ...prevVal,
        'contractEndDate': endErrors.apply,
      }));
    }
    if (name === 'contractEndDate') {
      value = isEmpty(value) ? '' : dayjs(value).format('YYYY/MM/DD');
    }
    onChange(name, value);
  }, [formData, onValidated, messages, onChange]);

  /**
   * 期間変更のアラートを表示する
   */
  const showPeriodAlert = useCallback(
    /**
     * @param {'contractStartDate'|'contractEndDate'} type フォーカスされた項目
     */
    (type) => {
      if (periodAlert.suppressAlert) {
        setPeriodAlert({
          ...periodAlert,
          showFlg: false,
        });
        return;
      }

      // 許諾商品入力状況の判定
      if ((productList ?? []).length === 0) {
        // 商品が空の場合はアラートは表示しない
        return;
      }

      setTimeout(() => {
        document.activeElement?.blur();
        formRefs.current.contractStartDate.current?.setOpen(false);
        formRefs.current.contractEndDate.current?.setOpen(false);
      }, 0);
      setPeriodAlert({
        ...periodAlert,
        showFlg: true,
        onClose: () => {
          setPeriodAlert({
            showFlg: false,
            onClose: null,
            suppressAlert: true,
          });
          setTimeout(() => {
            switch (type) {
              case 'contractStartDate':
                document.getElementById('contractStartDate')?.focus();
                formRefs.current.contractStartDate.current?.setOpen(true);
                break;
              case 'contractEndDate':
                document.getElementById('contractEndDate')?.focus();
                formRefs.current.contractEndDate.current?.setOpen(true);
                break;
              default:
                break;
            }
          }, 0);
        }
      });

    }, [formRefs, periodAlert, productList]);

  /**
   * 期間変更アラートの抑制状態を解除する
   */
  const clearSuppressAlert = useCallback(() => {
    if (periodAlert.suppressAlert) {
      setPeriodAlert({
        ...periodAlert,
        suppressAlert: false,
      });
    }
  }, [periodAlert]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>取引先名称</dt>
          <dd className="form-body">
            <div className="input-form wdt300">
              <input type="text" name="取引先名称" title="取引先名称は入力済みです"
                value={licenseeName} aria-label="取引先名称" disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請者名称</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="申請者名称" title="申請者名称は入力済みです"
                value={formData.applicantUserName ?? ''} aria-label="申請者名称" disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt100">
              <input type="text" name="企画書No" title="企画書Noは入力済みです"
                value={formData.proposalNo ?? ''} disabled aria-label="企画書No" />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請日</dt>
          <dd className="form-body">
            <div className="input-form wdt110">
              <input type="text" name="申請日"
                value={applicantDate} disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>企画申請ステータス</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="企画申請ステータス" title="企画申請ステータスは入力済みです"
                value={proposalStatus} aria-label="企画申請ステータス" disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '145px' }}>作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <SearchableListBox
                selectRef={formRefs.current.propertySummaryCode}
                isDisabled={formLocked}
                value={propertySummary}
                onChange={item => handleChange('propertySummaryCode', item?.value)}
                data={propertyMst}
                labelKey='propertySummaryName'
                valueKey='propertySummaryCode'
                katakanaKey='propertySummaryNameKana'
                hankakuKanaKey='propertySummaryNameHankakuKana'
                hiraganaKey='propertySummaryNameHiragana' />
            </div>
            <ErrorMessageList messages={messages.propertySummaryCode} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '145px' }}>企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text"
                name="企画件名"
                title="企画件名を必ず入力してください"
                aria-label="企画件名"
                ref={formRefs.current.proposalTitle}
                disabled={formLocked}
                value={formData.proposalTitle ?? ''}
                onChange={ev => handleChange('proposalTitle', ev.target.value)}
                required />
            </div>
            <ErrorMessageList messages={messages.proposalTitle} />
            <span className="attention">例：雑貨企画、物販催事企画、フィギュア企画、コンビニタイアップ企画</span>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '145px' }}>契約開始日</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <DatePicker
                dateFormat='yyyy/MM/dd'
                locale='ja'
                id="contractStartDate"
                ref={formRefs.current.contractStartDate}
                disabled={formLocked || formData.updateLimitFlag}
                selected={contractStartDate}
                onChange={date => handleChange('contractStartDate', date)}
                onFocus={() => showPeriodAlert('contractStartDate')}
                onCalendarClose={clearSuppressAlert} />
            </div>
            <ErrorMessageList messages={messages.contractStartDate} />
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name required" style={{ width: '145px' }}>契約終了日</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <DatePicker
                dateFormat='yyyy/MM/dd'
                locale='ja'
                id="contractEndDate"
                ref={formRefs.current.contractEndDate}
                minDate={contractEndMinDate}
                disabled={formLocked || formData.updateLimitFlag}
                selected={contractEndDate}
                onChange={date => handleChange('contractEndDate', date)}
                onFocus={() => showPeriodAlert('contractEndDate')}
                onCalendarClose={clearSuppressAlert} />
            </div>
            <ErrorMessageList messages={messages.contractEndDate} />
          </dd>
        </dl>
      </div>

      <div>
        <dl>
          <dt className="form-name">自動契約更新</dt>
          <dd className="ml15">
            <p>
              <input type="checkbox"
                id="check-auto-contract-update"
                name="自動契約更新"
                title="自動契約更新"
                disabled={formLocked}
                checked={formData.automaticContractUpdateReq}
                onChange={ev => handleChange('automaticContractUpdateReq', ev.target.checked)} />
              <label htmlFor="check-auto-contract-update" className="form-checkbox">1年ごとに契約延長する</label>
            </p>
          </dd>
        </dl>
      </div>

      {
        formData.rejectMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name c-pink" style={{ width: '145px'}} >差し戻し理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows="4"
                  name="差し戻し理由"
                  aria-label="差し戻し理由"
                  disabled
                  value={formData.rejectMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        formData?.cancelMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name c-pink" style={{ width: '145px' }}>取り下げ理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows="4"
                    name="取り下げ理由"
                    aria-label="取り下げ理由"
                    disabled
                    value={formData.cancelMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }
      {
        formData?.permissionMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name c-pink" style={{ width: '145px' }}>更新許可理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows="4"
                    name="更新許可理由"
                    aria-label="更新許可理由"
                    disabled
                    value={formData.permissionMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        formData.suspendMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name c-pink" style={{ width: '145px'}} >中止理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows="4"
                  name="中止理由"
                  aria-label="中止理由"
                  disabled
                  value={formData.suspendMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        periodAlert.showFlg ?
          <MessagePopup
            message="商品登録後に契約開始日、契約終了日、ロイヤリティ報告締め月を変更すると、商品の予定生産数、予定販売数情報がクリアされます。"
            btn={{ ok: 'OK' }}
            btnClass="bg-pink"
            onClose={btn => periodAlert.onClose ? periodAlert.onClose(btn) : null} />
          : null
      }
    </>
  );
}

/**
 * フォーム入力内容をバリデートする
 * @param {keyof FormData} name 入力された項目名
 * @param {*} value 入力された値
 * @param {FormData} formData フォーム全体の値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(name, value, formData) {
  switch (name) {
    // 作品名称
    case 'propertySummaryCode':
      return validatePropertySummaryCode(value);
    // 企画件名
    case 'proposalTitle':
      return validateProposalTitle(value);
    // 契約開始日
    case 'contractStartDate':
      return validateContractStartDate(value);
    // 契約終了日
    case 'contractEndDate':
      return validateContractEndDate(value, formData);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: [] };
  }
}

/**
 * 作品名称のバリデートを行う
 * @param {string} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePropertySummaryCode(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.tmp.push(getMessage('isNotEmpty'));
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 企画件名のバリデートを行う
 * @param {string} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateProposalTitle(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.tmp.push(getMessage('isNotEmpty'));
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 100)) {
    errors.tmp.push(getMessage('maxLength', { max: 100 }));
    errors.apply.push(getMessage('maxLength', { max: 100 }));
  }

  return errors;
}

/**
 * 契約開始日のバリデートを行う
 * @param {Date|null} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateContractStartDate(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.tmp.push(getMessage('isNotEmpty'));
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 契約終了日のバリデートを行う
 * @param {Date|null} value 入力された値
 * @param {FormData} formData フォーム全体の値
 * @returns {{tmp: string[], apply: string[]}} エラーメッセージ
 */
function validateContractEndDate(value, formData) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.tmp.push(getMessage('isNotEmpty'));
    errors.apply.push(getMessage('isNotEmpty'));
  }

  const end = dayjs(value, 'YYYY/MM/DD');

  // 契約開始日との相関チェック
  if (!isEmpty(formData.contractStartDate)) {
    const start = dayjs(formData.contractStartDate, 'YYYY/MM/DD');
    if (start.isSame(end, 'date') || start.isAfter(end, 'date')) {
      const msg = '契約開始日の翌日以降の日付を設定してください';
      errors.tmp.push(msg);
      errors.apply.push(msg);
    }
  }

  // 最大値チェック
  if (end.isAfter(MAX_CONTRACT_END_DATE, 'date')) {
    const msg = '契約終了日に指定できるのは2049/12/31までです';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }

  return errors;
}

//#region typedef
/**
 * @typedef {object} FormData フォームの入力・表示用データ
 * @property {string} proposalNo 企画No
 * @property {string} proposalStatus 申請ステータス
 * @property {string} applicantUserName 申請者氏名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} propertySummaryCode 作品コード
 * @property {string} proposalTitle 企画件名
 * @property {string} contractStartDate 契約開始日
 * @property {string} contractEndDate 契約終了日
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {boolean} automaticContractUpdateReq 自動契約更新リクエスト
 * @property {string} [rejectMessage] 差し戻し理由
 * @property {string} [permissionMessage] 更新許可理由
 * @property {string} [suspendMessage] 中止理由
 * @property {boolean} [updateLimitFlag] 更新制限フラグ
 */
/**
 * @callback OnChange フォームの入力内容変更時のハンドラ
 * @param {keyof FormData} prop 変更されたプロパティ
 * @param {*} value 変更後の値
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {keyof FormData} prop バリデート対象のプロパティ
 * @param {{ tmp: string[], apply: string[] }} errors エラーメッセージのリスト
 */
//#endregion typedef
