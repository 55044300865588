import { useMemo, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PasswordUpdateForm } from "../shared/PasswordUpdateForm";
import { initialPasswordUpdated } from "../../../slices/licensee/authSlice";
import { updateMyself, selectApiStatus, clearApiStatus } from "../../../slices/licensee/usersSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay"
import { useAuthRedirect } from "../../../lib/hooks/licensee";

/**
 * 初期パスワード変更画面
 * @returns
 */
export const InitialPasswordUpdatePage = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).update;
  const authRedirect = useAuthRedirect();

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('update'));
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  const canSubmit = useMemo(() => {
    return apiStatus !== 'loading' && apiStatus !== 'finish';
  }, [apiStatus]);

  if (authRedirect !== '/licensee/initialPasswordUpdate') {
    // 初期パスワード変更待ち以外は対応する画面へリダイレクト
    return (
      // ログイン画面に戻る
      <Navigate to={authRedirect ?? '/licensee/home'} />
    );
  }

  // 初期パスワード変更完了したら初期パスワード変更済みにする
  if (apiStatus === 'finish') {
    dispatch(initialPasswordUpdated());
  }

  return (
    <>
      <h1 className="main_login-head">初期パスワード変更</h1>
      <div className="main_login-body wrap">
        <p className="main_login-read tac">
          ご登録のメールアドレスに届いた初期パスワードと新しいパスワードを入力して、パスワード変更ボタンを押してください。<br />
        </p>
        <PasswordUpdateForm
          canSubmit={canSubmit}
          onSubmit={(userData) => dispatch(updateMyself(userData))}
         />
      </div>

      {loading}
    </>
  );
}
