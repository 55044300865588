import { useCallback, useState } from 'react';
import { getMessage } from '../../../lib/message';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { FlexiblePopup } from '../../common/FlexiblePopup';
import { SearchableListBox } from '../../common/SearchableListBox';

/**
 * 作品担当グループに所属追加させるユーザー選択
 * @param {*} anxUsers ANIPLEXユーザーリスト
 * @param {*} onClose ポップアップイベント
 * @returns
 */
export const PropertyGroupPopup = ({anxUsers, onClose}) => {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const handleChange = useCallback((values) => {
    setUsers(values);
    // 要素があれば返却
    setMessages(validate(values));
  }, []);

  // 追加ボタン親に設定を持ち帰る
  const onAdd = useCallback(() => {
    if (users.length > 0) {
      // ユーザー選択済み⇒親ウィンドウに持ちかえってNoと名前を表示する
      onClose('submit', users.map(u => { return { 'employeeNo': u.value, 'employeeName': u.label }} ));
    } else {
      // 何もせずに閉じる
      onClose('close');
    }
  }, [onClose, users]);

  return (
    <>
    <FlexiblePopup onClose={() => onClose('close')}>
      <h4 className="popup-title">作品担当グループ所属ANIPLEXユーザー設定</h4>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>作品担当</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-input wdt500">
              <SearchableListBox
                data={anxUsers}
                labelKey='employeeName'
                valueKey='employeeNo'
                isMulti={true}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={(event) => handleChange(event)}
                menuPortalTarget={document.body}
              />
            </div>
            <ErrorMessageList messages={messages} />
          </dd>
        </dl>
      </div>
      <div className="btn-wrapper">
        <div className="btn label mt15 bg-yellow">
          <button
            onClick={onAdd}>追加</button>
        </div>
      </div>
    </FlexiblePopup>
    </>
  );
}

/**
 *
 * @param {array} anxUserList
 * @param {string} anxUserList.value 「employeeNo」の値
 * @param {string} anxUserList.label 「employeeNo employeeName」の値
 */
function validate(values) {
  const errors = [];
  if (!values.length) {
    return [getMessage('isNotEmpty')];
  }
  return errors;
}
