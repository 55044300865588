import { useCallback, useEffect, useMemo } from 'react';
import { DropZoneDisabled, FileDropZone, MaxCnt } from '../../common/FileDropZone';
import { getExtension } from "../../../lib/util.js";

/**
 * ファイルアップロードの共通コンポーネント
 * @returns
 */
export const UploadAreaIcon = ({
  fileEntries,
  setFileEntries,
  startUploading,
  onChangeFileName,
  onChangeHasError,
  onChangeHasProgress,
  children,
  ListComponent,
}) => {
  // ドロップされたファイルをアップロードする
  const onDropFile = useCallback(acceptedFiles => {
    let filename = acceptedFiles[0].name;
    // 拡張子考慮
    if (getExtension(filename)) {
      acceptedFiles.forEach(startUploading);
    } else {
      setFileEntries([{
        key : filename,
        name : filename,
        completed : false,
        imageSrc: '',
        hasError: true,
        errorMessage: 'アップロードできないファイル形式です。'
      }]);
      return;
    }
  }, [setFileEntries, startUploading]);

  // アップロード済みファイルのファイル名
  const fileName = useMemo(() => {
    return fileEntries ? fileEntries.find(f => f.completed)?.name : '';
  }, [fileEntries]);

  // アップロード済ファイルが増減した時に呼び出し元に通知する
  useEffect(() => {
    if (typeof onChangeFileName !== 'function') {
      return;
    }
    // // ファイルアップロードAPIの返却ファイル名を設定する
    onChangeFileName(fileName);
  }, [fileName, onChangeFileName]);

  // エラー
  const hasError = useMemo(() => {
    return fileEntries.find((en => en.hasError));
  },[fileEntries]);
  useEffect(() => {
    onChangeHasError(hasError);
  }, [hasError, onChangeHasError]);

  // 進行中
  const hasProgress = useMemo(() => {
    return fileEntries.find((en => !en.completed && !en.hasError));
  }, [fileEntries]);
  useEffect(() => {
    onChangeHasProgress(hasProgress);
  }, [hasProgress, onChangeHasProgress]);

  // ファイル削除時にファイル一覧を更新する
  const onDeleteFile = setFileEntries;

  return (
    <>
      <MaxCnt.Provider value={1}>
      <DropZoneDisabled.Provider value={
        (fileEntries.length && fileEntries.length > 0)
      }>
      {(!children && !fileName) &&
        <FileDropZone
        btnClassName="bg-yellow"
        onDropFile={onDropFile} />
      }
      {(children || (fileEntries && fileEntries.length > 0)) && (
        <div className="l-uploader-content mt15">
          <ul className="list-uploader-content">
            {children}
            {!children &&
              <ListComponent
              fileEntries={fileEntries}
              onDeleteFile={onDeleteFile} />
            }
          </ul>
        </div>
      )}
      </DropZoneDisabled.Provider>
      </MaxCnt.Provider>
    </>
  );
};
