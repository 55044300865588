import { useEffect, useState } from "react";
import { UploadImageIcon } from "./UploadImageIcon";
import { Config } from "../../../config";

/**
 * 作品アイコン
 * @property {string} uploadFileName アイコンファイル名
 * @property {function} setUploadedIcon アップロードしたアイコン名の設定
 * @property {*} editable 更新可否フラグ
 */
export const PropertyIconForm = ({ uploadFileName, setUploadedIcon, setHasError, setHasProgress, editable }) => {
  const [fileName, setFileName] = useState(uploadFileName);
  useEffect(() => {
    setFileName(uploadFileName);
  }, [uploadFileName]);
  useEffect(() => {
    setUploadedIcon(fileName);
  }, [fileName, setUploadedIcon]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 130 }}>アイコン</dt>
          <dd className="form-body wdt1200" style={{ display: 'block' }}>
          {
            (uploadFileName && !editable) ?
              <img src={Config.PropertyIconPath + fileName} alt={fileName} />
            : editable ?
              <UploadImageIcon
                regIconFileName={uploadFileName ?? ''}
                onDelUploadedIcon={setUploadedIcon}
                onChangeHasError={setHasError}
                onChangeHasProgress={setHasProgress}
                onChangeFileName={setFileName}
              />
              : null
          }
          </dd>
        </dl>
      </div>
    </>
  );

}
