import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { clearApiStatus, updateRyRemindReq, selectApiStatus } from "../../../slices/aniplex/proposalsSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { Constants } from "../../../Constants";

/**
 * ロイヤリティ報告督促要否更新ポップアップ
 * @param {object} props
 * @param {Product} props.proposal 対象の企画情報
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const RyRemindReqPopup = ({ proposal, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateRyRemindReq;
  // ロイヤリティ報告督促要否
  const [ryRemindReq, setRyRemindReq] = useState(proposal.ryRemindReq ?? false);
  // エラーメッセージ
  const [messages, setMessages] = useState({
    ryRemindReq: [],
  });

  /** エラーフラグ */
  const hasError = useMemo(() => Object.values(messages).flat().length > 0, [messages]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  /** ロイヤリティ報告督促要否変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      ryRemindReq: validateRyRemindReq(value),
    }));
    setRyRemindReq(value);
  }, []);

  /** 登録ボタン押下時のコールバック */
  const onSubmit = useCallback(() => {
    const errors = {
      ryRemindReq: validateRyRemindReq(ryRemindReq),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      return;
    }

    // ロイヤリティ報告督促要否登録APIを呼び出す

    dispatch(updateRyRemindReq({
      proposalId: proposal.proposalId,
      updateDatetime: proposal.updateDatetime,
      // stringの0だとtrue返すのでNumberに型変換
      ryRemindReq: Boolean(Number(ryRemindReq)),
    }));
  }, [dispatch, proposal, ryRemindReq]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(clearApiStatus('updateRyRemindReq'));
      onClose('submit');
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('ロイヤリティ報告督促要否の更新中にエラーが発生しました'));
      dispatch(clearApiStatus('updateRyRemindReq'));
      return;
    }
  }, [apiStatus, dispatch, onClose])

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup onClose={() => onClose('close')}>
        <h4 className="popup-title">ロイヤリティ報告督促要否 更新</h4>

        <div className="l-form">
          <div className="form-set">
            <dt className="form-name required" style={{ width: 200 }}>
              ロイヤリティ報告督促要否</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="input-form">
                <ul className="list-form df" style={{ marginTop : -10, display: 'block' }}>
                {Object.keys(Constants.Aniplex.ProposalRyRemindReq).reverse().map(i => (
                  <li key={`radio${i}`}>
                    <input type="radio" id={`radio${i}`} name="ryRemindReq" aria-label=""
                     value={i} defaultChecked={Number(i) === Number(ryRemindReq)}
                     onChange={(event => handleChange(event.target.value))}
                     style={{ border: 'none' }} />
                    <label htmlFor={`radio${i}`} className="form-radio">
                      {Constants.Aniplex.ProposalRyRemindReq[i]}
                    </label>
                  </li>
                ))}
                </ul>
              </div>
              <ErrorMessageList messages={messages.productImageUrl} />
            </dd>
          </div>
        </div>

        <div className="btn-wrapper">
          <div className="btn label mt45 bg-aniplex">
            <button
              disabled={hasError || formLocked}
              onClick={onSubmit}
            >更新</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  );
}

/**
 * ロイヤリティ報告督促要否をバリデートする
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateRyRemindReq(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  return errors;
}

//#region typedef
/**
 * @typedef
 */
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン
 */
//#endregion typedef
