import { useEffect, useMemo } from "react";
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { clearApiStatus, selectApiStatus, sendCode } from "../../../slices/licensee/authSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { isNumber, lengthRange, isEmpty } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { getMessage } from "../../../lib/message";
import { useFocusError } from "../../../lib/hooks/common";
import { useCallback } from "react";

/**
 * 認証コード入力画面
 * @returns
 */
export const AuthCodePage = () => {
  const dispatch = useDispatch();
  const [authCode, setAuthCode] = useState('');
  const [messages, setMessages] = useState([]);
  const apiStatus = useSelector(selectApiStatus).authCode;
  const authRedirect = useAuthRedirect();

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['authCode']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.length > 0) {
      focusError('authCode');
      setNeedFocusError(false);
    }
  }, [focusError, messages.length, needFocusError]);

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('authCode'));
    }
  }, [dispatch]);

  /**
   * ハンドラ
   * @param {string} 認証コードの値
   */
  const handleChange = (value) => {
    setAuthCode(value);
    setMessages(validate(value));
  };

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  /** 送信ボタンクリック時の処理 */
  const onSendClick = useCallback(() => {
    const err = validate(authCode);
    if (err.length) {
      setMessages(err);
      setNeedFocusError(true)
      return;
    }

    dispatch(sendCode(authCode));
  }, [authCode, dispatch]);

  if (authRedirect !== '/licensee/authCode') {
    // 認証コード待ち状態以外は対応する画面へリダイレクト
    return (
      <Navigate to={authRedirect ?? '/licensee/home'} />
    );
  }

  return (
    <>
      <h1 className="main_login-head">認証コード入力</h1>
      <div className="main_login-body wrap">
        <p className="main_login-read tac">
          ご登録のメールアドレスに届いた認証コードを入力して送信ボタンを押してください。
        </p>
        <div className="main_login-content">
          <dl className="main_login-form form-set">
            <dt className="form-name">認証コード</dt>
            <dd className="form-body wdt500">
              <div className="input-form">
                <input type="password"
                  name="authCode"
                  title="認証コードは必ず入力してください"
                  aria-label="認証コード"
                  required
                  ref={formRefs.current.authCode}
                  maxLength={6}
                  value={authCode}
                  onChange={event => handleChange(event.target.value)} />
              </div>
              <ErrorMessageList messages={messages ?? []} />
            </dd>
          </dl>

          <p className="btn bg-green">
            <button onClick={onSendClick}>送信</button>
          </p>
        </div>
        {loading}
      </div>
    </>
  )
}

/**
 * 認証コードをバリデートする
 * @param {string} 認証コード
 * @return {string}} エラーメッセージ
 */
export function validate(value) {
  if (isEmpty(value)) {
    return getMessage('isNotEmpty');
  }
  if (!isNumber(value) || !lengthRange(value, 6, 6)) {
    // 固定桁の数値のみ許容
    return getMessage('isFixedDigitNumber', {'digit': 6});
  }

  return [];
}
