//@ts-check
import React, { useCallback, useMemo, useState } from 'react';
import { maxLength } from '../../../lib/validator';
import { getMessage } from '../../../lib/message';
import { FlexiblePopup } from '../../common/FlexiblePopup';
import { SafeCharTextArea } from '../../common/SafeCharTextArea';
import { ErrorMessageList } from '../../common/ErrorMessageList';

/**
 * RY報告申請用ポップアップ
 * @param {object} params
 * @param {OnClose} params.onClose ポップアップが閉じるときのコールバック
 */
export const RoyaltyRequestPopup = ({ onClose }) => {
  // 申請コメント
  const [messageContent, setMessageContent] = useState('');
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState({
    messageContent: /** @type {string[]} */ ([]),
  });

  /** エラーフラグ */
  const hasError = useMemo(() => Object.values(errorMessage).flat().length > 0, [errorMessage]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback(value => {
    setErrorMessage(prev => ({
      ...prev,
      messageContent: validateMessageContent(value),
    }));
    setMessageContent(value);
  }, []);

  /** 申請ボタン押下時のハンドラ */
  const onRequest = useCallback(() => {
    const errors = {
      messageContent: validateMessageContent(messageContent),
    };

    setErrorMessage(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      return;
    }

    onClose('ok', messageContent);
  }, [messageContent, onClose]);

  return (
    // @ts-expect-error ts(2786)
    <FlexiblePopup
      className='no-btn-close'
      onClose={() => onClose('cancel')}
    >
      <div className="wrap">
        <div className="msg-wrap">
          <p>申請を行います。よろしいですか？</p>
        </div>
      </div>
      <div className="l-form mt10">
        <dl className="form-set">
          <dt className="form-name">申請コメント（任意）</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-textarea wdt300">
              {/* @ts-expect-error ts(2786) */}
              <SafeCharTextArea
                rows={4}
                value={messageContent}
                onChange={val => handleChange(val)} />
            </div>
            {/* @ts-expect-error ts(2786) */}
            <ErrorMessageList messages={errorMessage.messageContent} />
          </dd>
        </dl>
      </div>

      <div className="btn-wrapper">
        <div className="btn label mt15">
          <button
            onClick={() => onClose('cancel')}
          >キャンセル</button>
        </div>
        <div className="btn mt15 bg-pink">
          <button
            disabled={hasError}
            onClick={onRequest}
          >申請</button>
        </div>
      </div>
    </FlexiblePopup>
  )
}

/**
 * 申請コメントのバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateMessageContent(value) {
  /** @type {string[]} */
  const errors = [];

  if (!maxLength(value, 500)) {
    errors.push(getMessage('maxLength', { max: 500 }));
  }

  return errors;
}

//#region typedef
/**
 * @callback OnClose ポップアップが閉じるときのコールバック
 * @param {'ok'|'cancel'} btn 閉じるときに押されたボタン
 * @param {string} [messageContent] 申請コメント (btn='ok'のときのみ設定される)
 */
//#endregion
