//@ts-check
import React, { useEffect, useMemo, useRef, useState } from 'react';

/**
 * 更新状態アイコン表示
 * @param {object} params
 * @param {string} [params.className=''] コンポーネントに付与するCSSクラス
 * @param {string} params.iconClass アイコンに付与するCSSクラス
 * @param {string} params.tooltipText ツールチップの表示内容
 * @param {boolean} [params.hideTooltip=false] ツールチップ非表示フラグ
 */
export const ChangeStatusIcon = ({
  className = '',
  iconClass,
  tooltipText,
  hideTooltip = false,
}) => {
  /** アイコン要素のref */
  const iconRef = useRef(/** @type {HTMLElement|null} */(null));

  /** ツールチップ表示制御 */
  const tooltipInfo = useTooltipControl({ iconRef });

  /** ツールチップ表示フラグ */
  const showTooltip = useMemo(() =>
    tooltipInfo.shown && !hideTooltip,
    [hideTooltip, tooltipInfo.shown]);

  return (
    <span className={`change-status ${className}`}>
      <i
        ref={iconRef}
        className={`icn ${hideTooltip ? 'no-tooltip' : ''} ${iconClass}`}
      ></i>
      {
        showTooltip && (
          <span
            className="tooltip"
            style={{ top: tooltipInfo.top, left: tooltipInfo.left }}
          >
            {tooltipText}
          </span>
        )
      }
    </span>
  )
}

/**
 * ツールチップ表示制御に関するフック
 * @param {object} params
 * @param {React.MutableRefObject<HTMLElement|null>} params.iconRef アイコン要素へのref
 */
const useTooltipControl = ({
  iconRef,
}) => {
  const [info, setInfo] = useState(/** @type {TooltipInfo} */({
    shown: false,
    top: 0,
    left: 0,
  }));

  useEffect(() => {
    // マウスオーバー時にツールチップを表示
    const overHandler = (/** @type {MouseEvent} */ e) => {
      if (e.target instanceof HTMLElement) {
        const rect = e.target.getBoundingClientRect();
        setInfo({
          shown: true,
          top: rect.bottom,
          left: rect.left,
        });
      }
    }

    // マウスアウト時にツールチップを非表示
    const outHandler = () => {
      setInfo({
        shown: false,
        top: 0,
        left: 0,
      });
    }

    // スクロール時にツールチップの位置を調整
    const scrollHandler = () => {
      if (iconRef.current != null) {
        const rect = iconRef.current.getBoundingClientRect();
        setInfo(prev => ({
          ...prev,
          top: rect.bottom,
          left: rect.left,
        }))
      }
    }



    /** @type {ParentNode[]} */
    let parents = [];
    if (iconRef.current != null) {
      parents = getScrollableParents(iconRef.current);
    }
    const target = iconRef.current;

    target?.addEventListener('mouseover', overHandler);
    target?.addEventListener('mouseout', outHandler);
    parents.forEach(p => p.addEventListener('scroll', scrollHandler));
    window.addEventListener('scroll', scrollHandler);
    return () => {
      target?.removeEventListener('mouseover', overHandler);
      target?.removeEventListener('mouseout', outHandler);
      parents.forEach(p => p.removeEventListener('scroll', scrollHandler));
      window.removeEventListener('scroll', scrollHandler);
    }
  }, [iconRef]);

  return info;
}

/**
 * スクロール可能な親要素を取得する
 * @param {HTMLElement} node 調査対象のノード
 * @returns スクロール可能な親要素のリスト
 */
const getScrollableParents = (node) => {
  /** @type {ParentNode|null|undefined} */
  let tmp = node;
  const parents = [];

  /**
   * 対象要素がスクロール可能か判定する
   * @param {?ParentNode} el 判定対象のノード
   */
  const isScrollable = (el) => {
    if (!(el instanceof HTMLElement)) {
      return false;
    }

    const overflowY = window.getComputedStyle(el).overflowY;
    const yScrollable = (overflowY.includes('scroll') || overflowY.includes('auto'))
      && el.scrollHeight > el.clientHeight;

    const overflowX = window.getComputedStyle(el).overflowX;
    const xScrollable = (overflowX.includes('scroll') || overflowX.includes('auto'))
      && el.scrollWidth > el.clientWidth;

    return yScrollable || xScrollable;
  }
  while (tmp !== document.body) {
    if (isScrollable(tmp) && tmp != null) {
      parents.push(tmp);
    }
    tmp = tmp?.parentNode;
  }
  return parents;
}

//#region typedef
/**
 * @typedef {object} TooltipInfo ツールチップ表示に関する情報
 * @property {boolean} shown 表示フラグ
 * @property {number} top CSSのtopの値
 * @property {number} left CSSのleftの値
 */
//#endregion typedef
