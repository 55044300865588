import { useCallback, useEffect, useState } from "react"
import { downloadFileRetry } from "../../lib/api/licensee";
import { UploadFileList } from "../common/UploadFileList";

/**
 * アップロード済みファイルのリスト表示
 * @param {object} props
 * @param {FileInfo[]} props.fileList ファイル情報のリスト
 * @returns
 */
export const UploadedFileList = ({ fileList, filetype, onDeleteFile }) => {
  // ファイル一覧
  const [fileEntries, setFileEntries] = useState([]);

  // fileNoごとのダウンロードリンクを保持する
  const [links, setLinks] = useState({});
  // ダウンロードリンク取得中フラグ
  const [linkFetching, setLinkFetching] = useState({});
  // ダウンロードリンク取得エラーフラグ
  const [linkFetchError, setLinkFetchError] = useState({});

  // ファイルダウンロードAPIから署名付きURLを取得して保持する
  const fetchDownloadLink = useCallback(async (fileNo) => {
    try {
      setLinkFetching(prev => ({ ...prev, [fileNo]: true }));
      const link = await downloadFileRetry(fileNo, filetype);
      setLinks(prev => ({ ...prev, [fileNo]: link }));
    } catch {
      // エラーを記録(一定回数リトライ後ここに来る)
      setLinkFetchError(prev => ({ ...prev, [fileNo]: true }));
    } finally {
      setLinkFetching(prev => ({ ...prev, [fileNo]: false }));
    }
  }, [filetype]);

  // マウント時、削除時に状態を更新する
  useEffect(() => {
    setFileEntries(fileList.map(file => {
      const entry = {
        completed: true,
        downloadLink: links[file.fileNo] ?? '',
        key: file.fileNo,
        name: file.filename,
        fileNo: file.fileNo,
      };
      if (!links[file.fileNo] && !linkFetching[file.fileNo] && !linkFetchError[file.fileNo]) {
        // ダウンロードリンク未取得時かつダウンロードリンク取得エラーなしの場合はAPIから取得
        fetchDownloadLink(file.fileNo);
      }
      return entry;
    }));
  }, [fetchDownloadLink, fileList, linkFetching, linkFetchError, links]);

  return (
    <UploadFileList fileEntries={fileEntries} onDeleteFile={onDeleteFile} />
  )
}

//#region typedef
/**
 * @typedef {object} FileInfo ファイル情報
 * @property {string} fileNo ファイルNo
 * @property {string} filename ファイル名
 */
//#endregion typedef
