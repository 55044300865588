import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { clearApiStatus, clearRoyaltyDetail, doExport, fetchRoyalty, rejectRoyalty, selectApiStatus, selectRoyaltyDetail } from '../../../slices/aniplex/royaltiesSlice';
import { RoyaltyReportDetailForm } from './RoyaltyReportDetailForm';
import { ApiLoadingOverlay } from '../../common/ApiLoadingOverlay';
import { pushMessage } from '../../../slices/aniplex/utilSlice';
import { AlertConfirmContext, useClearAlertConfirmState } from './AlertConfirmContext';

/**
 * ANIPLEX向けロイヤリティ報告詳細画面
 */
export const RoyaltyReportDetailPage = () => {
  return (
    <AlertConfirmContext>
      <RoyaltyReportDetailPageInner />
    </AlertConfirmContext>
  )
}

/**
 * ANIPLEX向けロイヤリティ報告詳細画面実体
 */
const RoyaltyReportDetailPageInner = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus);
  const royaltyDetail = useSelector(selectRoyaltyDetail);
  const routerParams = useParams();

  const clearAlertConfirmState = useClearAlertConfirmState();

  useEffect(() => {
    if (routerParams.ryReportId != null) {
      // ロイヤリティ報告書内部コードを元にロイヤリティ報告情報を取得する
      dispatch(fetchRoyalty(routerParams.ryReportId));
      // 取得時にアラート確認状態をクリアする
      clearAlertConfirmState();
    }
  }, [clearAlertConfirmState, dispatch, routerParams.ryReportId]);

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI関連のstoreをクリア
      dispatch(clearApiStatus('fetchRoyalty'));
      dispatch(clearApiStatus('doExport'));
      dispatch(clearApiStatus('rejectRoyalty'));
      dispatch(clearRoyaltyDetail());
    }
  }, [dispatch]);

  /** 受領ボタン押下時のコールバック */
  const onExport = useCallback((params) => {
    // 売上連携API呼び出し
    dispatch(doExport(params));
  }, [dispatch])

  useEffect(() => {
    if (apiStatus.doExport === 'finish') {
      dispatch(pushMessage('ロイヤリティ報告を受領しました。'));
      dispatch(clearApiStatus('doExport'));
      // データリロード
      dispatch(fetchRoyalty(routerParams.ryReportId));
      return;
    }
    if (apiStatus.doExport === 'error') {
      dispatch(pushMessage('システムエラーが発生しました。'));
      dispatch(clearApiStatus('doExport'));
      return;
    }
  }, [apiStatus.doExport, dispatch, routerParams.ryReportId]);

  /** 差し戻しボタン押下時のコールバック */
  const onReject = useCallback((messageContent) => {
    // ロイヤリティ報告更新API呼出し
    dispatch(rejectRoyalty({
      ryReportId: royaltyDetail?.ryReportId,
      messageContent,
      updateDatetime: royaltyDetail?.updateDatetime,
    }));
  }, [dispatch, royaltyDetail?.ryReportId, royaltyDetail?.updateDatetime]);

  useEffect(() => {
    if (apiStatus.rejectRoyalty === 'finish') {
      dispatch(pushMessage('ロイヤリティ報告を差し戻しました。'));
      dispatch(clearApiStatus('fetchRoyalty'));
      // データリロード
      dispatch(fetchRoyalty(routerParams.ryReportId));
      return;
    }
    if (apiStatus.rejectRoyalty === 'error') {
      dispatch(pushMessage('システムエラーが発生しました。'));
      dispatch(clearApiStatus('rejectRoyalty'));
      return;
    }
  }, [apiStatus.rejectRoyalty, dispatch, routerParams.ryReportId]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    return apiStatus.fetchRoyalty === 'loading'
      || apiStatus.fetchRoyalty === 'error'
      || apiStatus.doExport === 'loading'
      || apiStatus.rejectRoyalty === 'loading';
  }, [apiStatus.doExport, apiStatus.fetchRoyalty, apiStatus.rejectRoyalty]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ロイヤリティ報告</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/royaltyReportList'>
            <i className="icn pageback"></i>ロイヤリティ報告一覧へ戻る
          </Link>
        </p>
      </div>

      <RoyaltyReportDetailForm
        royalty={royaltyDetail}
        formLocked={formLocked}
        onAccept={onExport}
        onReject={onReject} />

      <ApiLoadingOverlay
        apiStatus={[
          apiStatus.fetchRoyalty,
          apiStatus.doExport,
          apiStatus.rejectRoyalty,
        ]} />

    </div>
  );
}
