import { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { logout, selectSessionExpired, selectToken } from '../../slices/licensee/authSlice';
import { popMessage, selectLatestMessage } from '../../slices/licensee/utilSlice';
import {
  clearApiStatus as clearUsersApiStatus,
  fetchMyself,
  selectMyself
} from '../../slices/licensee/usersSlice';
import {
  clearApiStatus as clearMasterApiStatus,
  fetchMaster,
} from '../../slices/licensee/masterSlice';
import '../../style/licensee/import_licensee.scss';
import { MessagePopup } from '../common/MessagePopup';
import { LicenseeHeader } from "./LicenseeHeader";
import { LicenseeHeaderNoLogin } from "./LicenseeHeaderNoLogin";
import { LicenseeFooter } from "./LicenseeFooter";
import { LicenseeFooterNoLogin } from "./LicenseeFooterNoLogin";
import { useAuthRedirect } from '../../lib/hooks/licensee';
import { Config } from '../../config';

/**
 * ライセンシー向け画面ラッパコンポーネント
 * @returns
 */
export const LicenseeContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myself = useSelector(selectMyself);
  const authRedirect = useAuthRedirect();
  const token = useSelector(selectToken);
  const sessionExpired = useSelector(selectSessionExpired);

  const isLoggedIn = useMemo(() => authRedirect === null, [authRedirect]);
  const hasToken = useMemo(() => token != null, [token]);

  // セッションエラー確認済みフラグ
  const [sessionErrorConfirmed, setSessionErrorConfirmed] = useState(false);

  // タイトルを設定値に上書き
  useEffect(() => {
    document.title = Config.SiteTitle;
  }, []);

  useEffect(() => {
    // トークンを持っているときはログインユーザー情報・マスタ情報をAPIから取得する
    if (hasToken) {
      // TODO::ログイン済みだけどログインユーザー情報の取得に失敗した場合どうするか
      dispatch(fetchMyself());
      dispatch(fetchMaster());
      return () => {
        dispatch(clearUsersApiStatus('fetch'));
        dispatch(clearMasterApiStatus('fetchMaster'));
      }
    }
  }, [dispatch, hasToken]);

  useEffect(() => {
    // セッション期限切れフラグが解除されたら確認済みフラグをクリア
    if (!sessionExpired) {
      setSessionErrorConfirmed(false);
    }
  }, [sessionExpired]);

  // ログアウトボタン押下時の処理
  const onLogoutClick = useCallback(() => {
    dispatch(logout());
    navigate('/licensee/login');
  }, [dispatch, navigate]);

  return (
    <div className="root root-licensee">
      {
        isLoggedIn && myself
          ? (
            <LicenseeHeader
              onLogoutClick={onLogoutClick}
              username={myself?.username}
            />
          )
          : (
            <LicenseeHeaderNoLogin />
          )
      }
      {
        /* TODO: ユーザー情報が取れるまで表示を抑制しているが、もっといい方法がないか検討する */
        <Outlet />
      }
      {
        isLoggedIn && myself
          ? (
            <LicenseeFooter />
          )
          : (
            <LicenseeFooterNoLogin />
          )
      }
      <LicenseeMessagePopup />

      {
        sessionExpired && !sessionErrorConfirmed ?
          <MessagePopup
            message='セッションの有効期限が切れました。再ログインしてください。'
            btn={{ ok: 'OK' }}
            btnClass='bg-pink'
            onClose={() => setSessionErrorConfirmed(true)} /> :
          null
      }
    </div>
  );
}

/**
 * ライセンシー向け画面のメッセージポップアップ
 */
function LicenseeMessagePopup() {
  const dispatch = useDispatch();
  const message = useSelector(selectLatestMessage);

  if (message == null) {
    return null;
  }

  const onClose = () => {
    dispatch(popMessage());
  }

  return (
    <MessagePopup
      message={message}
      btn={{ok: 'OK'}}
      btnClass="bg-pink"
      onClose={onClose} />
  )
}
