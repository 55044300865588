import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { clearApiStatus, selectApiStatus, updateNoticeUserIdList } from "../../../slices/licensee/proposalsSlice";
import { ProposalNoticePopup } from "./ProposalNoticePopup";
import { LoadingOverlay } from "../../common/LoadingOverlay";

/** 登録可能な通知先の最大数 */
const NOTICE_USER_NUM_MAX = 5;
/** 最大件数を超えた時のメッセージ */
const overMessage = `登録可能な通知先は${NOTICE_USER_NUM_MAX}件までです。`;

const ADD = 'ADD';
const DELETE = 'DELETE';

/**
 * 企画申請画面の通知先者部分のフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {string} props.updateDatetime 最終更新日時
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject<{}>} props.formRefs フォーム項目へのref
 * @param {OnChange} props.onChange フォームの入力内容変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @returns
 */
export const ProposalNoticeForm = ({
  data,
  updateDatetime,
  licenseeUsers,
  isNew,
  formLocked,
  formRefs,
  onChange,
  onValidated,
  validateAllFlg,
}) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateNoticeUserIdList;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [tempEditUsers, setTempEditUsers] = useState([]); // 編集の場合、API通信完了時にselectedUsersを更新したいので、一時的に保存する領域
  const [updateType, setUpdateType] = useState(''); // ADD or DELETE

  useEffect(() => {
    setSelectedUsers(data.noticeUserIdList);
  }, [data.noticeUserIdList]);

  /**
   * 通知先更新処理を呼び出す
   */
  const saveNoticeUserIdList = useCallback((users, type) => {
    setUpdateType(type);
    setTempEditUsers(users);
    dispatch(updateNoticeUserIdList({
      proposalId: data.proposalId,
      updateDatetime,
      noticeUserIdList: users.map(u => u.userId),
    }));
  }, [dispatch, data.proposalId, updateDatetime]);

  /**
   * ポップアップ
   */
  const [popup, setPopup] = useState({
    showFlg: false,
    onClose: null,
  });

  /**
   * ポップアップ表示
   */
  const onAddClick = useCallback(() => {
    if (selectedUsers.length >= NOTICE_USER_NUM_MAX) {
      dispatch(pushMessage(overMessage));
      return;
    }

    setPopup({
      showFlg: true,
      onClose: (btn, users) => {

        if (btn === 'submit' && users.length > 0) {
          let list = [];
          const mergeList = [...selectedUsers, ...users];
          if (selectedUsers.length > 0 && users.length > 0) {
            const targetList = Array.from(new Set(mergeList.map(d => d.userId)));
            list = targetList.map(t => {
              return mergeList.find(m => m.userId === t);
            });
          } else {
            list = mergeList;
          }

          if (isNew) {
            // 新規作成の場合、呼び出し元に返してポップアップを閉じる
            onChange('noticeUserIdList', list);
            setSelectedUsers(list);
          } else {
            // 編集の場合、更新処理を呼び出す
            if (list.length > NOTICE_USER_NUM_MAX) {
              // 上限を超える場合はエラーとする
              dispatch(pushMessage(overMessage));
              return;
            }
            saveNoticeUserIdList(list, ADD);
            // まだポップアップを閉じない
            return;
          }
        }
        setPopup({
          showFlg: false,
          onClose: null,
        })
      }
    })
  }, [dispatch, onChange, isNew, setPopup, selectedUsers, saveNoticeUserIdList]);

  // 削除予定ユーザーのloginIdリスト管理
  const onChangeUsers = useCallback((val, check) => {
    if (check) {
      setDeleteUsers(Array.from(new Set([...deleteUsers, val])));
    }  else {
      setDeleteUsers(deleteUsers.filter(d => d !== val));
    }
  }, [deleteUsers]);

  // 選択済みユーザー削除
  const delMember = useCallback(() => {
    if (!deleteUsers || deleteUsers.length < 1) {
      dispatch(pushMessage('通知先を選択してください。'));
      return;
    }

    // 未選択のユーザーのみリストに残す
    const targetUsers = selectedUsers.filter(s => !deleteUsers.includes(s.userId));
    if (isNew) {
      setSelectedUsers(targetUsers);
      setDeleteUsers([]);
      onChange('noticeUserIdList', targetUsers);
    } else {
      saveNoticeUserIdList(targetUsers, DELETE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUsers, dispatch, selectedUsers, onChange, saveNoticeUserIdList]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      if (updateType === ADD) {
        dispatch(pushMessage('通知先を追加しました。'));
        onChange('noticeUserIdList', tempEditUsers);
        setSelectedUsers(tempEditUsers);

      } else if (updateType === DELETE) {
        // 未選択のユーザーのみリストに残す
        dispatch(pushMessage('通知先を削除しました。'));
        onChange('noticeUserIdList', tempEditUsers);
        setDeleteUsers([]);
        setSelectedUsers(tempEditUsers);
      }
      dispatch(clearApiStatus('updateNoticeUserIdList'));
      setPopup({
        showFlg: false,
        onClose: null,
      });
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('通知先の設定中にエラーが発生しました。'));
      dispatch(clearApiStatus('updateNoticeUserIdList'));
      return;
    }
  }, [apiStatus, onChange, tempEditUsers, selectedUsers, dispatch, updateType]);

  // エラーメッセージ
  const errorMessages = useMemo(() => {
    const errors = validateNoticeUserIdList(selectedUsers);
    return errors.apply;
  }, [selectedUsers]);

  // 強制バリデーション
  useEffect(() => {
    const errors = validateNoticeUserIdList(selectedUsers);
    if (validateAllFlg) {
      onValidated('noticeUserIdList', errors);
    }
  }, [selectedUsers, onValidated, validateAllFlg]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <section className="mt40">
        <div className="title-pink">
          <h2 className="title">通知先</h2>
        </div>

        <span className="c-pink">企画、証紙申請、ロイヤリティ報告のステータスに更新があった際の通知の宛先に追加があれば、ご指定ください。</span>

        <div className="l-buttons" ref={formRefs}>
          <p className="btn bg-pink" style={{ width: 100 }}>
            <button onClick={onAddClick}>
              <i className="icn plus"></i>追加
            </button>
          </p>

          <p className="btn c-pink" style={{ width: 100 }}>
            <button onClick={delMember}>
              <i className="icn cross"></i>削除
            </button>
          </p>
        </div>
        {<ErrorMessageList messages={errorMessages} />}
      </section>

      <div className="l-table mt20">
        <table>
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: 39 }}></th>
              <th scope="col" style={{ minWidth: 300 }}>氏名</th>
              <th scope="col" style={{ minWidth: 600 }}>メールアドレス</th>
            </tr>
          </thead>
          <tbody>
            {
              selectedUsers.length > 0 ? selectedUsers.map(u => {
                return (
                  <tr key={u.userId}>
                    <th>
                      <fieldset>
                      {
                        !formLocked ?
                        <>
                          <input type="checkbox" id={`check-${u.userId}`}
                          onChange={event => onChangeUsers(u.userId, event.target.checked)}/>
                          <label htmlFor={`check-${u.userId}`} className="form-checkbox02">チェック</label>
                        </> : null
                      }
                      </fieldset>
                    </th>
                    <td>{u.username}</td>
                    <td>{u.mailaddress}</td>
                  </tr>
                );
              }) : (
                <tr><td colSpan={3}>通知先が設定されていません。</td></tr>
              )
            }
          </tbody>
        </table>
      </div>

      {
        popup.showFlg ? (
          <ProposalNoticePopup
            licenseeUsers={licenseeUsers}
            onClose={popup.onClose}
            />
        ) : null
      }

      {loading}
    </>
  );
};

/**
 * フォーム入力内容をバリデートする
 * @param {keyof FormData} name 入力された項目名
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(name, value) {
  switch (name) {
    // 通知先
    case 'noticeUserIdList':
      return validateNoticeUserIdList(value);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: [] };
  }
}

/**
 * 通知先のバリデートを行う
 * @param {object[]} values 入力された値
 * @returns {{ tmp: string[], apply: string }} エラーメッセージ
 */
function validateNoticeUserIdList(values) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (values.length > NOTICE_USER_NUM_MAX) {
    errors.tmp.push(overMessage);
    errors.apply.push(overMessage);
  }

  return errors;
}
