import React, { useMemo } from "react";
import Select, { createFilter } from 'react-select';

/**
 * 検索可能リストボックス
 * @param {object} props
 * @param {React.MutableRefObject} props.selectRef 入力要素に設定するref
 * @param {Array|object[]} props.data 選択肢に表示するデータ
 * @param {string|string[]} [props.labelKey] props.data内のラベル表示に使用するプロパティ名
 * @param {string} [props.valueKey] props.data内のvalueに使用するプロパティ名
 * @param {string} [props.delimiter=' '] 複数のラベルキー指定時の区切り文字
 * @param {string} [props.katakanaKey] props.data内のフリガナ(カナ)の項目のプロパティ名
 * @param {string} [props.hankakuKanaKey] props.data内のフリガナ(半角カナ)の項目のプロパティ名
 * @param {string} [props.hiraganaKey] props.data内のふりがな(ひらがな)の項目のプロパティ名
 * @returns
 */
export const SearchableListBox = ({
  selectRef,
  data,
  labelKey,
  valueKey,
  delimiter = ' ',
  katakanaKey,
  hankakuKanaKey,
  hiraganaKey,
  styles,
  options,
  ...selectProps
}) => {
  // スタイル設定
  const selStyles = useMemo(() => ({
    control: (base, state) => ({
      ...base,
      border: 'solid 1px #DEDEDE',
      boxShadow: state.isFocused ? '0 0 2px 1px #1919dbd1' : 'none',
      '&:hover': {
        border: 'solid 1px #DEDEDE',
        boxShadow: state.isFocused ? '0 0 2px 1px #1919dbd1' : 'none',
      },
      borderRadius: 0,
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      display: 'none',
    }),
    ...styles,
  }), [styles]);

  // 選択肢のデータ
  const selOptions = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }
    if (data.length === 0) {
      return [];
    }

    const labelKeyList = (() => {
      if (!Array.isArray(labelKey)) {
        return [labelKey];
      }

      return labelKey;
    })();

    if (typeof data[0] !== 'object'
      || labelKeyList.some(l => !data[0].hasOwnProperty(l))
      || !data[0].hasOwnProperty(valueKey)
    ) {
      return data.map(i => ({
        value: i,
        label: i
      }));
    } else {
      return data.map(i => ({
        value: i[valueKey],
        label: labelKeyList.map(l => i[l]).join(' '),
      }));
    }
  }, [data, labelKey, valueKey]);

  const filterConfig = {
    stringify: option => {
      const target = data.find(d => d[valueKey] === option.value);
      const katakana = target?.[katakanaKey] ?? '';
      const hankakuKana = target?.[hankakuKanaKey] ?? '';
      const hiragana = target?.[hiraganaKey] ?? '';
      const text = `${option.label} ${katakana} ${hankakuKana} ${hiragana}`
      return text
    }
  }

  return (
    <div className="searchable-list-box">
      <Select
        ref={selectRef}
        placeholder=''
        noOptionsMessage={() => '該当するデータが存在しません'}
        styles={selStyles}
        options={selOptions}
        isClearable={true}
        filterOption={createFilter(filterConfig)}
        {...selectProps}
      />
    </div>
  );
}
