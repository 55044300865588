import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAnnouncementAll, getAnnouncement, postAnnouncement, patchAnnouncement } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {Announcement[]|null} 取得したお知らせ一覧 */
  announcementList: null,
  /** @type {AnnouncementDetail} 取得したお知らせ詳細 */
  announcementDetail: null,
  /** @type {string|null} 登録されたお知らせ内部コード */
  registeredAnnouncementId: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** お知らせ一覧 */
    fetchAnnouncementAll: null,
    /** お知らせ詳細 */
    fetchAnnouncement: null,
    /** お知らせ登録 */
    registerAnnouncement: null,
    /** お知らせ更新 */
    updateAnnouncement: null,
    /** お知らせ削除 */
    deleteAnnouncement: null,
  },
}

/**
 * お知らせ一覧取得処理
 */
export const fetchAnnouncementAll = createAsyncThunk(
  'aniplex/announcement/fetchAnnouncementAll',
  /**
   * お知らせ一覧取得処理
   * @param {import('../../lib/api/aniplex').GetAnnouncementAllParam} param リクエストパラメータ
   */
  async (param, { dispatch }) => {
    dispatch(clearAnnouncementList);
    try {
      const data = await getAnnouncementAll(param);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * お知らせ詳細情報取得
 */
export const fetchAnnouncement = createAsyncThunk(
  'aniplex/announcement/fetchAnnouncement',
  /**
   * お知らせ詳細情報取得
   * @param {number} announcementId お知らせ内部コード
   * @returns {AnnouncementDetail}
   */
  async(announcementId, { dispatch }) => {
    dispatch(clearAnnouncementDetail);
    try {
      const data = await getAnnouncement(announcementId);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '13601': ApiErrorMessage.DataNotExists,
      });
    }
  }
);

/**
 * お知らせ登録処理
 */
export const registerAnnouncement = createAsyncThunk(
  'aniplex/announcement/registerAnnouncement',
  /**
   * お知らせ登録処理
   * @param {import('../../lib/api/aniplex').PostAnnouncementParam} param リクエストパラメータ
   */
  async(param, { dispatch }) => {
    try {
      const data = await postAnnouncement(param);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
);

/**
 * お知らせ更新処理
 */
export const updateAnnouncement = createAsyncThunk(
  'aniplex/announcement/updateAnnouncement',
  /**
   * お知らせ更新処理
   * @param {number} announcementId お知らせ内部コード
   * @param {import('../../lib/api/aniplex').PatchAnnouncementParam} params リクエストパラメータ
   */
  async({ announcementId, params }, { dispatch }) => {
    try {
      const data = await patchAnnouncement(announcementId, params);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '13701': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * お知らせ削除処理
 */
 export const deleteAnnouncement = createAsyncThunk(
  'aniplex/announcement/deleteAnnouncement',
  /**
   * お知らせ削除処理
   * @param {number} announcementId お知らせ内部コード
   * @param {string} announcementUpdateDatetime お知らせ更新日時
   * @param {import('../../lib/api/aniplex').PatchAnnouncementParam} param リクエストパラメータ
   */
  async({ announcementId, announcementUpdateDatetime }, { dispatch }) => {
    try {
      const param = {
        announcementUpdateDatetime,
        deleteFlag: true,
      };
      const data = await patchAnnouncement(announcementId, param);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '13701': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * アニプレックス向け画面のお知らせ情報周りのスライス
 */
export const announcementSlice = createSlice({
  name: 'aniplex/announcement',
  initialState,
  reducers: {
    /**
     * 取得済みのお知らせ一覧をクリアする
     * @param {typeof initialState} state
     */
     clearAnnouncementList: (state) => {
      state.announcementList = null;
    },
    /**
     * 取得済みのお知らせ詳細をクリアする
     * @param {typeof initialState} state
     */
    clearAnnouncementDetail: (state) => {
      state.announcementDetail = null
    },
    /**
     * 登録されたお知らせ内部コードをクリアする
     * @param {typeof initialState} state
     */
    clearRegisteredAnnouncementId: (state) => {
      state.registeredAnnouncementId = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        case 'fetchAnnouncementAll':
          state.apiStatus.fetchAnnouncementAll = null;
          break;
        case 'fetchAnnouncement':
          state.apiStatus.fetchAnnouncement = null;
          break;
        case 'registerAnnouncement':
          state.apiStatus.registerAnnouncement = null;
          break;
        case 'updateAnnouncement':
          state.apiStatus.updateAnnouncement = null;
          break;
        case 'deleteAnnouncement':
          state.apiStatus.deleteAnnouncement = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // お知らせ一覧
      .addCase(fetchAnnouncementAll.pending, (state) => {
        state.apiStatus.fetchAnnouncementAll = 'loading';
      })
      .addCase(fetchAnnouncementAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAnnouncementAll = 'finish';
        state.announcementList = action.payload;
      })
      .addCase(fetchAnnouncementAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAnnouncementAll = null;
      })
      // お知らせ詳細
      .addCase(fetchAnnouncement.pending, (state) => {
        state.apiStatus.fetchAnnouncement = 'loading';
      })
      .addCase(fetchAnnouncement.fulfilled, (state, action) => {
        state.apiStatus.fetchAnnouncement = 'finish';
        state.announcementDetail = action.payload;
      })
      .addCase(fetchAnnouncement.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAnnouncement = 'error';
      })
      // お知らせ登録
      .addCase(registerAnnouncement.pending, (state) => {
        state.apiStatus.registerAnnouncement = 'loading';
      })
      .addCase(registerAnnouncement.fulfilled, (state, action) => {
        state.apiStatus.registerAnnouncement = 'finish';
        state.registeredAnnouncementId = action.payload.announcementId;
      })
      .addCase(registerAnnouncement.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.registerAnnouncement = null;
      })
      // お知らせ更新
      .addCase(updateAnnouncement.pending, (state) => {
        state.apiStatus.updateAnnouncement = 'loading';
      })
      .addCase(updateAnnouncement.fulfilled, (state) => {
        state.apiStatus.updateAnnouncement = 'finish';
      })
      .addCase(updateAnnouncement.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateAnnouncement = null;
      })
      // お知らせ削除
      .addCase(deleteAnnouncement.pending, (state) => {
        state.apiStatus.deleteAnnouncement = 'loading';
      })
      .addCase(deleteAnnouncement.fulfilled, (state) => {
        state.apiStatus.deleteAnnouncement = 'finish';
      })
      .addCase(deleteAnnouncement.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.deleteAnnouncement = null;
      });
  }
});

export const {
  clearAnnouncementList,
  clearAnnouncementDetail,
  clearRegisteredAnnouncementId,
  clearApiStatus,
} = announcementSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.announcement.apiStatus;

/**
 * stateからお知らせ一覧を取得する
 * @param {*} state
 * @returns {Announcement[]} お知らせ一覧
 */
export const selectAnnouncementList = (state) => state.aniplex.announcement.announcementList;

/**
 * お知らせ詳細情報取得
 * @param {*} state
 * @returns {AnnouncementDetail|null} お知らせ詳細情報
 */
export const selectAnnouncementDetail = (state) => state.aniplex.announcement.announcementDetail;

/**
 * stateから登録されたお知らせ内部コードを取得する
 * @param {*} state
 * @returns {string} 登録されたお知らせ内部コード
 */
 export const selectRegisteredAnnouncementId = (state) => state.aniplex.announcement.registeredAnnouncementId;

export default announcementSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAnnouncementAll'|'fetchAnnouncement'|'registerAnnouncement'|'updateAnnouncement'|'deleteAnnouncement'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Announcement お知らせ情報
 * @property {number} announcementId お知らせ内部コード
 * @property {string} announcementNo お知らせNo
 * @property {string} announcementTitle お知らせ件名
 * @property {string} announcementStartDatetime 公開開始日時
 * @property {string} announcementEndDatetime 公開終了日時
 * @property {'STT'|'OPN'|'END'} お知らせステータス※返却値用（STT: 公開前 OPN: 公開中 END: 公開終了）
 * @property {string} announcementRegDatetime お知らせ登録日時
 * @property {string} announcementRegEmployeeName お知らせ登録者氏名
 * @property {string} announcementEditDatetime お知らせ最終更新日時
 * @property {string} announcementUpdateEmployeeName お知らせ最終更新者氏名
 */
/**
 * @typedef {object} AnnouncementDetail お知らせ情報詳細
 * @property {number} announcementId お知らせ内部コード
 * @property {string} announcementTitle お知らせ件名
 * @property {string} announcementContent お知らせ内容
 * @property {string} announcementStartDatetime 公開開始日時
 * @property {string} announcementEndDatetime 公開終了日時
 * @property {boolean} announcementMailFlag メール通知フラグ
 * @property {string} announcementMailDatetime メール送信日時
 * @property {string} announcementUpdateDatetime お知らせ更新日時
 */
//#endregion
