import { useCallback, useEffect, useState } from "react";
import { useFocusError, useFormData } from "../../../lib/hooks/common"
import { getMessage } from "../../../lib/message";
import { isEmpty, isValidEmail, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { MessagePopup } from "../../common/MessagePopup"
import { SelectLicenseePopup } from "./SelectLicenseePopup";

/** フォーム項目の順番 */
const formPropOrder = [
  // 氏名
  'username',
  // メールアドレス
  'mailaddress',
  // 取引先コード
  'licenseeCode',
  // 部署名
  'department',
];

/**
 * [A]ライセンシーユーザー登録画面のフォーム部分
 * @param {object} props
 * @param {LicenseeUser} [props.loadedData] 更新対象のライセンシーユーザ情報
 * @param {string} props.submitBtn 送信ボタン名
 * @param {boolean} [props.showSubmit=false] 送信ボタンを表示するか
 * @param {boolean} [props.showDelete=false] 削除ボタンを表示するか
 * @param {(data: FormData) => void} props.onSubmit 登録ボタン押下時のコールバック
 * @param {Function} props.onDelete 削除ボタン押下時のコールバック
 * @param {boolean} [props.formLocked=false] 入力抑制フラグ
 */
export const LicenseeUserDetailForm = ({
  loadedData,
  submitBtn,
  showSubmit = false,
  showDelete = false,
  onSubmit,
  onDelete,
  formLocked = false,
}) => {
  // 取引先選択ポップアップ
  const [licenseePopup, setLicenseePopup] = useState({
    /** @type {boolean} */
    showFlg: false,
    /** @type {(btn: 'ok'|'cancel', licenseeCode?: string) => void} */
    onClose: null,
  });
  // 削除確認ポップアップ
  const [deleteConfirm, setDeleteConfirm] = useState({
    showFlg: false,
    onClose: null,
  });

  // 強制バリデートフラグ
  const [validateAllFlg, setValidateAllFlg] = useState(false);
  // 強制バリデートフラグ
  const [userStatus, setUserStatus] = useState('');

  // フォームデータ
  const [formData, handleChange, errors] = useFormData({
    /** @type {string} 氏名 */
    username: '',
    /** @type {string} メールアドレス */
    mailaddress: '',
    /** @type {string} 取引先コード */
    licenseeCode: '',
    /** @type {string} 部署名 */
    department: '',
  }, validate, validateAllFlg);

  // 取引先名の欄に入れておくデータ
  // 取引先コードに伴って決まり、更新時に送るデータに含まれない
  const [licenseeNameKanji, setLicenseeNameKanji] = useState('');

  useEffect(() => {
    if (loadedData == null) return;

    // ユーザ情報が読み込まれたらそれをフォームに反映する
    handleChange('username', loadedData.username, true);
    handleChange('mailaddress', loadedData.mailaddress, true);
    handleChange('licenseeCode', loadedData.licenseeCode, true);
    handleChange('department', loadedData.department, true);
    setLicenseeNameKanji(loadedData?.licenseeNameKanji ?? '');
    setUserStatus(loadedData.userStatus === 'INV' ? '招待中' : '登録済');
  }, [handleChange, loadedData]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (errors[prop]?.length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          return;
        }
      }
    }
  }, [errors, focusError, needFocusError]);

  /** 取引先選択ボタン押下時のハンドラ */
  const onLicenseeSelectClick = useCallback(() => {
    setLicenseePopup({
      showFlg: SVGComponentTransferFunctionElement,
      onClose: (btn, licenseeCode, licenseeNameKanji) => {
        if (btn === 'ok') {
          handleChange('licenseeCode', licenseeCode);
          setLicenseeNameKanji(licenseeNameKanji);
        }
        setLicenseePopup({
          showFlg: false,
          onClose: null
        });
      }
    });
  }, [handleChange]);

  /** 登録ボタン押下時のハンドラ */
  const onSubmitClick = useCallback(() => {
    setValidateAllFlg(true);
    const errors = validateAll(formData);

    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      return;
    }

    // API呼出し
    onSubmit(formData)
  }, [formData, onSubmit]);

  /** 削除ボタン押下時のハンドラ */
  const onDeleteClick = useCallback(() => {
    setDeleteConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          // API呼出し
          onDelete();
        }
        setDeleteConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [onDelete]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>氏名</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt200">
              <input type="text" name="氏名"
                title="氏名を入力してください"
                aria-label="氏名"
                ref={formRefs.current.username}
                disabled={formLocked}
                value={formData.username}
                onChange={ev => handleChange('username', ev.target.value)} />
            </div>
            <ErrorMessageList messages={errors.username ?? []} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>メールアドレス</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt600">
              <input type="email" name="メールアドレス"
                title="メールアドレスを入力してください"
                aria-label="メールアドレス"
                ref={formRefs.current.mailaddress}
                disabled={formLocked}
                value={formData.mailaddress}
                onChange={ev => handleChange('mailaddress', ev.target.value)} />
            </div>
            <ErrorMessageList messages={errors.mailaddress ?? []} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>取引先コード</dt>
          <dd className="form-body">
            <div style={{ display: 'block'}}>
              <div className="input-form wdt200">
                <input type="email" name="取引先コード"
                  title="取引先コードを入力してください"
                  aria-label="取引先コード"
                  disabled
                  value={formData.licenseeCode} />
              </div>
              <ErrorMessageList messages={errors.licenseeCode ?? []} />
            </div>
            <p className="btn small bg-yellow ml20" style={{ width: '60px' }}>
              <button
                ref={formRefs.current.licenseeCode}
                disabled={formLocked}
                onClick={onLicenseeSelectClick}
              >選択</button>
            </p>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="email" name="取引先名"
                title="取引先名を入力してください"
                aria-label="取引先名"
                disabled
                value={licenseeNameKanji} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>部署名</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt600">
              <input type="email" name="部署名"
                title="部署名を入力してください"
                aria-label="部署名"
                ref={formRefs.current.department}
                disabled={formLocked}
                value={formData.department}
                onChange={ev => handleChange('department', ev.target.value)} />
            </div>
            <ErrorMessageList messages={errors.department ?? []} />
          </dd>
        </dl>
      </div>

      {
        userStatus && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{ width: '115px' }}>ステータス</dt>
              <dd className="form-body">
                <div className="input-form wdt200">
                  <input type="userStatus" name="ステータス"
                    aria-label="ステータス"
                    disabled
                    value={userStatus} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      <div className="dif j-between mt20">
        <div className="l-buttons">
          {
            showSubmit && (
              <p className="btn label bg-orange" style={{ width: '190px' }}>
                <input id="register" type="button"
                  disabled={formLocked}
                  value={submitBtn}
                  onClick={onSubmitClick} />
                <label htmlFor="register"><i className="icn check"></i>{submitBtn}</label>
              </p>
            )
          }
        </div>

        {
          showDelete && (
            <p className="btn c-aniplex" style={{ width: '203px' }}>
              <button
                disabled={formLocked}
                onClick={onDeleteClick}
              ><i className="icn cross"></i>ユーザー削除</button>
            </p>
          )
        }
      </div>

      {
        licenseePopup.showFlg && (
          <SelectLicenseePopup onClose={licenseePopup.onClose}/>
        )
      }

      {
        deleteConfirm.showFlg && (
          <MessagePopup
            message="ユーザー情報を削除します。よろしいですか？"
            btn={{ ok: '削除', cancel: 'キャンセル' }}
            btnClass="bg-aniplex"
            onClose={deleteConfirm.onClose} />
        )
      }
    </>
  )
}

/**
 * 全項目のバリデートを行う
 * @param {FormData} formData フォームデータ
 * @returns {Record<keyof FormData, string[]>} エラーメッセージ
 */
function validateAll(formData) {
  const errors = {};

  Object.entries(formData).forEach(([prop, value]) => {
    const error = validate(prop, value);
    errors[prop] = error;
  });

  return errors;
}

/**
 * バリデート処理
 * @param {keyof FormData} prop 対象のプロパティ名
 * @param {FormData[prop]} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validate(prop, value) {
  switch (prop) {
    // 氏名
    case 'username':
      return validateUserName(value);
    // メールアドレス
    case 'mailaddress':
      return validateMailaddress(value);
    // 取引先コード
    case 'licenseeCode':
      return validateLicenseeCode(value);
    // 部署名
    case 'department':
      return validateDepartment(value);
    default:
      return [];
  }
}

/**
 * 氏名のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validateUserName(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 20)) {
    errors.push(getMessage('maxLength', { max: 20 }));
  }

  return errors;
}

/**
 * メールアドレスのバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validateMailaddress(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 256)) {
    errors.push(getMessage('maxLength', { max: 256 }));
  }

  if (!isValidEmail(value)) {
    errors.push(getMessage('isValidEmail'));
  }

  return errors;
}

/**
 * 取引先コードのバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validateLicenseeCode(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push('取引先を選択してください');
    return errors;
  }

  return errors;
}

/**
 * 部署名のバリデート
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validateDepartment(value) {
  const errors = [];

  if (isEmpty(value)) {
    // 空の場合はエラーなし
    return [];
  }

  if (!maxLength(value, 100)) {
    errors.push(getMessage('maxLength', { max: 100 }));
  }

  return errors;
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/licenseeUsersSlice').LicenseeUser} LicenseeUser ライセンシーユーザ情報
 */
/**
 * @typedef {import('../../../lib/api/aniplex').PostLicenseeUserParam} FormData フォームデータ
 */
//#endregion typedef
