/**
 * ロイヤリティ計算用のロジック群
 */
import BigNumber from 'bignumber.js';
import { Constants } from '../Constants';
import {
  selectRoyaltyPatternMst as selectAniplexRoyaltyPatternMst,
} from '../slices/aniplex/masterSlice';
import {
  selectRoyaltyPatternMst as selectLicenseeRoyaltyPatternMst,
} from '../slices/licensee/masterSlice';
import { store } from '../store';

/**
 * ロイヤリティ報告パターンIDに対応するロイヤリティ報告パターンマスタ情報を取得する
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {'aniplex'|'licensee'} type マスタ情報の取得元種別
 * @returns {AniplexRyPatternMst|LicenseeRyPatternMst|null} ロイヤリティ報告パターンマスタ情報
 */
export function getRyPatternMst(rypId, type) {
  const state = store.getState();
  const ryPatternMstList = (() => {
    switch (type) {
      case 'licensee':
        return selectLicenseeRoyaltyPatternMst(state);
      case 'aniplex':
        return selectAniplexRoyaltyPatternMst(state);
      default:
        return [];
    }
  })();

  return ryPatternMstList.find(a => String(a.rypId) === String(rypId)) ?? null;
}

/**
 * ロイヤリティ報告パターンに対応する入力可否フラグを取得する
 * @param {string} rypId ロイヤリティ報告パターンID
 * @param {'aniplex'|'licensee'} type 使用するマスタ情報の取得元
 * @returns {RyInputFlg} 入力可否フラグ
 */
export function getRypInputFlg(rypId, type) {
  const mst = getRyPatternMst(rypId, type);

  if (mst == null) {
    // マスタ情報が取れない場合は全てfalseで返却
    return {
      price: false,
      cost: false,
      planProceeds: false,
      production: false,
      sales: false,
      ryRate: false,
      ryPrice: false,
      ryAmount: false,
      reportProduction: false,
      reportSales: false,
      reportPrice: false,
      reportCost: false,
      reportRyTarget: false,
      reportRyAmount: false,
    };
  }
  return {
    price: !!mst.inputReportPrice,
    cost: !!mst.inputPlanCost,
    planProceeds: !!mst.inputPlanProceeds,
    production: !!mst.inputPlanProduction,
    sales: !!mst.inputPlanSales,
    ryRate: !!mst.inputRyRate,
    ryPrice: !!mst.inputRyPrice,
    ryAmount: !!mst.inputPlanRyAmount,
    reportProduction: !!mst.inputReportProduction,
    reportSales: !!mst.inputReportSales,
    reportPrice: !!mst.inputReportPrice,
    reportCost: !!mst.inputReportCost,
    reportRyTarget: !!mst.inputReportProceeds,
    reportRyAmount: !!mst.inputReportRyAmount,
  };
}

/**
 * ロイヤリティパターンに対応する上代・製造原価の種別を取得する
 * @param {string} rypId ロイヤリティ報告パターンID
 * @returns {'price'|'cost'|null} 上代は'price,製造原価は'cost',どちらでもない場合はnull
 */
export function rypPriceCostType(rypId) {
  switch (rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代
      return 'price'
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代
      return 'price';
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価
      return 'cost';
    default:
      // 上記以外のロイヤリティ報告パターンはnullを返す
      return null;
  }
}

/**
 * 予定上代・製造原価を取得する
 * @param {Product} product 商品情報
 * @returns {number|null} 予定上代・製造原価
 */
export function planPriceCost(product) {
  switch (rypPriceCostType(product.rypId)) {
    case 'price':
      // 予定上代
      return product.planPrice;
    case 'cost':
      // 予定製造原価
      return product.planCost;
    default:
      // 上記以外
      return null;
  }
}

/**
 * 最新上代・製造原価を取得する
 * @param {Product} product 商品情報
 * @returns {number|null} 最新上代・製造原価
 */
export function latestPriceCost(product) {
  switch (rypPriceCostType(product.rypId)) {
    case 'price':
      // 最新上代
      return product.latestPrice;
    case 'cost':
      // 最新製造原価
      return product.latestCost;
    default:
      // 上記以外
      return null;
  }
}

/**
 * 予定ロイヤリティ対象金額を計算して取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象とする第N期
 * @returns {number|null} 計算された予定ロイヤリティ金額
 */
export function planRyTarget(product, period) {
  const targetPeriod = product.periodList.find(p => p.period === period);
  if (!targetPeriod) {
    return null;
  }

  return calcRyTarget({
    rypId: product.rypId,
    production: targetPeriod.planProduction ?? 0,
    sales: targetPeriod.planSales ?? 0,
    price: product.planPrice ?? 0,
    cost: product.planCost ?? 0,
    ryTarget: product.planProceeds ?? 0,
  });
}

/**
 * ロイヤリティ対象金額を計算する
 * @param {object} args
 * @param {string} args.rypId ロイヤリティ報告パターンID
 * @param {number} args.production 生産数
 * @param {number} args.sales 販売数
 * @param {number} args.price 上代
 * @param {number} args.cost 製造原価
 * @param {number} args.ryTarget 直接入力された対象売上金額
 * @returns {number|null} ロイヤリティ対象金額
 */
export function calcRyTarget({rypId, production, sales, price, cost, ryTarget}) {
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  switch (rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代×生産数
      return BN(price).times(BN(production)).dp(2).toNumber();
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代×販売数
      return BN(price).times(BN(sales)).dp(2).toNumber();
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価×生産数
      return BN(cost).times(BN(production)).dp(2).toNumber();
    // 対象売上金額×ロイヤリティ料率
    case Constants.RoyaltyPattern.SalesPriceRate:
      // 直接入力
      return BN(ryTarget).dp(2).toNumber();
    default:
      // 上記以外はnull
      return null;
  }
}

/**
 * 予定ロイヤリティ金額を計算して取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の第N期
 */
export function planRyAmount(product) {
  const calProduct = {
    rypId: product.rypId,
    price: product.planPrice ?? 0,
    cost: product.planCost ?? 0,
    ryTarget: product.planProceeds ?? 0,
    ryRate: product.ryRate ?? 0,
    ryPrice: product.ryPrice ?? 0,
    // production と sales はRY報告パターンに応じて設定
    ryAmount: product.planRyAmount ?? 0,
  };

  // 期ごとの生産数・販売数の要否によって処理を区別する
  if ([Constants.RoyaltyPattern.SalesPriceRate, Constants.RoyaltyPattern.FixedPrice].includes(product.rypId)) {
    return calcRyAmount({
      ...calProduct,
      production: 0,
      sales: 0
    });
  } else {
    // 期ごとの値を合算する
    return product.periodList.reduce((prev, pr) => prev + calcRyAmount({
      ...calProduct,
      production: pr.planProduction ?? 0,
      sales: pr.planSales ?? 0,
    }), 0);
  }
}

/**
 * ロイヤリティ金額を計算する
 * @param {object} args
 * @param {string} args.rypId ロイヤリティ報告パターンID
 * @param {number} args.price 上代
 * @param {number} args.cost 製造原価
 * @param {number} args.ryTarget 直接入力されたロイヤリティ対象金額
 * @param {number} args.ryRate ロイヤリティ料率
 * @param {number} args.ryPrice ロイヤリティ単価
 * @param {number} args.production 生産数
 * @param {number} args.sales 販売数
 * @param {number} args.ryAmount 直接入力されたロイヤリティ金額
 * @returns {number|null} ロイヤリティ金額
 */
export function calcRyAmount({rypId, price, cost, ryTarget, ryRate, ryPrice, production, sales, ryAmount}) {
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  const ryRateBN = BN(ryRate).div(100);
  switch (rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      return BN(price).times(ryRateBN).times(BN(production)).dp(2).toNumber();
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      return BN(price).times(ryRateBN).times(BN(sales)).dp(2).toNumber();
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      return BN(cost).times(ryRateBN).times(BN(production)).dp(2).toNumber();
    // 対象売上金額×ロイヤリティ料率
    case Constants.RoyaltyPattern.SalesPriceRate:
      return BN(ryTarget).times(ryRateBN).dp(2).toNumber();
    // 生産数×ロイヤリティ単価
    case Constants.RoyaltyPattern.ProductNumUniPrice:
      return BN(production).times(BN(ryPrice)).dp(2).toNumber();
    // 販売数×ロイヤリティ単価
    case Constants.RoyaltyPattern.SalesNumUnitPrice:
      return BN(sales).times(BN(ryPrice)).dp(2).toNumber();
    // ロイヤリティ金額（直接入力）
    case Constants.RoyaltyPattern.FixedPrice:
      return BN(ryAmount).dp(2).toNumber();
    default:
      return null;
  }
}

/**
 * 予定販売金額を計算して取得する
 * @param {Product} product 商品情報
 * @param {number} period 対象の第N期
 * @returns {number|null} 計算された予定販売金額
 */
export function planProceeds(product, period) {
  const targetPeriod = product.periodList.find(p => p.period === period);
  if (!targetPeriod) {
    return null;
  }

  return calcProceeds({
    rypId: product.rypId,
    price: product.planPrice ?? 0,
    sales: targetPeriod.planSales ?? 0,
    ryTarget: product.planProceeds ?? 0,
  });
}

/**
 * 販売金額を計算する
 * @param {object} args
 * @param {string} args.rypId ロイヤリティ報告パターンID
 * @param {number} args.price 上代
 * @param {number} args.sales 販売数
 * @param {number} args.ryTarget 直接入力されたロイヤリティ対象金額
 * @returns {number|null} 販売金額
 */
export function calcProceeds({rypId, price, sales, ryTarget }) {
  const BN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });
  switch (rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代×販売数
      return BN(price).times(BN(sales)).dp(0).toNumber();
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代×販売数
      return BN(price).times(BN(sales)).dp(0).toNumber();
    // 対象売上金額×ロイヤリティ料率
    case Constants.RoyaltyPattern.SalesPriceRate:
      // ロイヤリティ対象金額
      return ryTarget;
    default:
      return null;
  }
}

//#region typedef
/**
 * @typedef {import('../slices/licensee/proposalsSlice').ProposalProduct} LicenseeProduct ライセンシー向け商品情報
 */
/**
 * @typedef {import('../slices/aniplex/proposalsSlice').ProposalProduct} AniplexProduct ANIPLEX向け商品情報
 */
/**
 * @typedef {LicenseeProduct & AniplexProduct} Product 商品情報
 */
/**
 * @typedef {import('../slices/aniplex/masterSlice').RoyaltyPatternMst} AniplexRyPatternMst ANIPLEX向けロイヤリティ報告パターンマスタ
 */
/**
 * @typedef {import('../slices/licensee/masterSlice').RoyaltyPatternMst} LicenseeRyPatternMst ライセンシー向けロイヤリティ報告パターンマスタ
 */
/**
 * @typedef {object} RyInputFlg ロイヤリティ情報入力可否フラグ
 * @property {boolean} price 上代
 * @property {boolean} cost 製造原価
 * @property {boolean} planProceeds 予定売上金額
 * @property {boolean} production 生産数
 * @property {boolean} sales 販売数
 * @property {boolean} ryRate ロイヤリティ料率
 * @property {boolean} ryPrice ロイヤリティ単価
 * @property {boolean} ryAmount ロイヤリティ金額
 * @property {boolean} reportProduction 報告生産数
 * @property {boolean} reportSales 報告販売数
 * @property {boolean} reportPrice 報告上代
 * @property {boolean} reportCost 報告製造原価
 * @property {boolean} reportRyTarget 報告ロイヤリティ対象金額
 * @property {boolean} reportRyAmount 報告ロイヤリティ金額
 */
//#endregion typedef
