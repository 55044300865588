import { useCallback, useEffect, useMemo, useState } from "react";
import { useFocusError } from "../../../lib/hooks/common"
import { getMessage } from "../../../lib/message";
import { isEmpty, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { MessagePopup } from "../../common/MessagePopup"
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { SafeCharTextArea } from "../../common/SafeCharTextArea";

/** フォーム項目の順番 */
const formPropOrder = [
  // お知らせ件名
  'announcementTitle',
  // お知らせ内容
  'announcementContent',
  // 公開開始日時（日）
  'announcementStartDatetime_ymd',
  // 公開開始日時（時刻）
  'announcementStartDatetime_hm',
  // 公開終了日時（日）
  'announcementEndDatetime_ymd',
  // 公開終了日時（時刻）
  'announcementEndDatetime_hm',
];

/** 公開日の最大日 */
const AnnouncementMaxDate = dayjs('2049/12/31', 'YYYY/MM/DD').toDate();

/**
 * [A]お知らせ登録画面のフォーム部分
 * @param {object} props
 * @param {LicenseeUser} [props.loadedData] 更新対象のお知らせ情報
 * @param {string} props.submitBtn 送信ボタン名
 * @param {'登録'|'更新'} props.execType 実行タイプ
 * @param {boolean} [props.showSubmit=false] 送信ボタンを表示するか
 * @param {boolean} [props.showDelete=false] 削除ボタンを表示するか
 * @param {(data: FormData) => void} props.onSubmit 登録ボタン押下時のコールバック
 * @param {() => void} props.onSubmit 登録ボタン押下時のコールバック
 * @param {Function} props.onDelete 削除ボタン押下時のコールバック
 * @param {boolean} [props.formLocked=false] 入力抑制フラグ
 */
export const AnnouncementDetailForm = ({
  loadedData,
  submitBtn,
  execType,
  showSubmit = false,
  showDelete = false,
  onSubmit,
  onDelete,
  formLocked = false,
}) => {

  // メール通知確認ポップアップ
  const [mailConfirm, setMailConfirm] = useState({
    showFlg: false,
    onClose: null,
  });

  // 削除確認ポップアップ
  const [deleteConfirm, setDeleteConfirm] = useState({
    showFlg: false,
    onClose: null,
  });

  // フォームデータ
  const [formData, setFormData] = useState({
    /** @type {string} お知らせ件名 */
    announcementTitle: '',
    /** @type {string} お知らせ内容 */
    announcementContent: '',
    /** @type {string} 公開開始日時（日） */
    announcementStartDatetime_ymd: '',
    /** @type {string} 公開開始日時（時刻） */
    announcementStartDatetime_hm: '',
    /** @type {string} 公開終了日時（日） */
    announcementEndDatetime_ymd: '',
    /** @type {string} 公開終了日時（時刻） */
    announcementEndDatetime_hm: '',
    /** @type {string} メール通知フラグ */
    announcementMailFlag: true,
    /** @type {string} メール送信日時 */
    announcementMailDatetime: '',
  });

  const [errors, setErrors] = useState({
    announcementTitle: [],
    announcementContent: [],
    announcementStartDatetime_ymd: [],
    announcementStartDatetime_hm: [],
    announcementEndDatetime_ymd: [],
    announcementEndDatetime_hm: [],
  });

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (errors[prop]?.length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          return;
        }
      }
    }
  }, [errors, focusError, needFocusError]);

  /**
   * フォーム更新ハンドラ
 * @param {keyof FormData} prop 対象のプロパティ名
   * @param {string} value 更新後の値
   */
   const handleChange = useCallback((prop, value) => {
    setErrors(prev => ({
      ...prev,
      [prop]: validate(prop, value, formData),
    }));
    setFormData(prev => ({
      ...prev,
      [prop]: value,
    }));
  }, [formData]);

  useEffect(() => {
    if (loadedData == null) return;

    // お知らせ情報が読み込まれたらフォームに反映する
    const start = dayjs(loadedData.announcementStartDatetime, 'YYYY/MM/DD HH:mm:ss');
    const end = dayjs(loadedData.announcementEndDatetime, 'YYYY/MM/DD HH:mm:ss');
    setFormData({
      announcementTitle: loadedData.announcementTitle,
      announcementContent: loadedData.announcementContent,
      announcementStartDatetime_ymd: start.format('YYYY/MM/DD'),
      announcementStartDatetime_hm: start.format('HH:mm'),
      announcementEndDatetime_ymd: end.format('YYYY/MM/DD'),
      announcementEndDatetime_hm: end.format('HH:mm'),
      announcementMailFlag: loadedData.announcementMailFlag,
      announcementMailDatetime: loadedData.announcementMailDatetime ?
        `${loadedData.announcementMailDatetime}　送信済` : '',
    });
  }, [loadedData]);

  // 日付変更フラグ
  const [updateDate, setUpdateDate] = useState(false);

  /** 日付項目変更時のハンドラ */
  const handleDateChange = useCallback((prop, date) => {
    // 公開開始日時（日）、公開終了日時（日）
    if (prop === 'announcementStartDatetime_ymd' || prop === 'announcementEndDatetime_ymd') {
      handleChange(prop, isEmpty(date) ? '' : dayjs(date).format('YYYY/MM/DD'));
    }

    // 公開開始日時（時刻）、公開終了日時（時刻）
    if (prop === 'announcementStartDatetime_hm' || prop === 'announcementEndDatetime_hm') {
      handleChange(prop, isEmpty(date) ? '' : dayjs(date).format('HH:mm'));
    }

    // 公開終了日時（時刻）以外は日付の相関チェックを行うため、公開終了日時（時刻）のバリデーション処理実行
    if (prop !== 'announcementEndDatetime_hm' && formData.announcementEndDatetime_hm) {
      setUpdateDate(true);
    }
  }, [formData, handleChange]);

  /** 日付変更時の相関チェック実行 */
  useEffect(() => {
    if (updateDate) {
      setUpdateDate(false);
      handleChange('announcementEndDatetime_hm', formData.announcementEndDatetime_hm);
    }
  }, [updateDate, formData.announcementEndDatetime_hm, handleChange]);

  /** 公開開始日（日） */
  const announcementStartDatetime_ymd = useMemo(() => {
    return isEmpty(formData.announcementStartDatetime_ymd) ?
      null :
      dayjs(formData.announcementStartDatetime_ymd, 'YYYY/MM/DD').toDate();
  }, [formData.announcementStartDatetime_ymd])

  /** 公開開始日（時刻） */
  const announcementStartDatetime_hm = useMemo(() => {
    return isEmpty(formData.announcementStartDatetime_hm) ?
      null :
      dayjs(`2000/01/01 ${formData.announcementStartDatetime_hm}`, 'YYYY/MM/DD HH:mm').toDate();
  }, [formData.announcementStartDatetime_hm])

  /** 公開終了日（日） */
  const announcementEndDatetime_ymd = useMemo(() => {
    return isEmpty(formData.announcementEndDatetime_ymd) ?
      null :
      dayjs(formData.announcementEndDatetime_ymd, 'YYYY/MM/DD').toDate();
  }, [formData.announcementEndDatetime_ymd])

  /** 公開終了日（時刻） */
  const announcementEndDatetime_hm = useMemo(() => {
    return isEmpty(formData.announcementEndDatetime_hm) ?
      null :
      dayjs(`2000/01/01 ${formData.announcementEndDatetime_hm}`, 'YYYY/MM/DD HH:mm').toDate();
  }, [formData.announcementEndDatetime_hm])

  /** 公開開始日のエラーリスト */
  const announcementStartDatetimeErrors = useMemo(() => {
    const errorList = errors.announcementStartDatetime_ymd.concat(errors.announcementStartDatetime_hm);
    return Array.from(new Set(errorList));
  }, [errors.announcementStartDatetime_ymd, errors.announcementStartDatetime_hm])

  /** 公開終了日のエラーリスト */
  const announcementEndDatetimeErrors = useMemo(() => {
    const errorList = errors.announcementEndDatetime_ymd.concat(errors.announcementEndDatetime_hm);
    return Array.from(new Set(errorList));
  }, [errors.announcementEndDatetime_ymd, errors.announcementEndDatetime_hm])

  /** 登録ボタン押下時のハンドラ */
  const onSubmitClick = useCallback(() => {
    const errors = validateAll(formData);
    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      setErrors(errors);
      return;
    }

    // 登録APIの呼出し処理
    const submitFunc = () => {
      onSubmit({
        announcementTitle: formData.announcementTitle,
        announcementContent: formData.announcementContent,
        announcementStartDatetime:
          `${formData.announcementStartDatetime_ymd} ${formData.announcementStartDatetime_hm}:00`,
        announcementEndDatetime:
          `${formData.announcementEndDatetime_ymd} ${formData.announcementEndDatetime_hm}:00`,
        announcementMailFlag: formData.announcementMailFlag,
      });
    };

    // メール未送信でメール通知を行う場合は確認メッセージを表示する
    if (formData.announcementMailFlag && !formData.announcementMailDatetime) {
      setMailConfirm({
        showFlg: true,
        onClose: (btn) => {
          if (btn === 'ok') {
            submitFunc();
          }
          setMailConfirm({
            showFlg: false,
            onClose: null,
          });
        }
      });
    } else {
      submitFunc();
    }
  }, [formData, onSubmit]);

  /** 削除ボタン押下時のハンドラ */
  const onDeleteClick = useCallback(() => {
    setDeleteConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          // API呼出し
          onDelete();
        }
        setDeleteConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [onDelete]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>お知らせタイトル</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt500">
              <input type="text" name="お知らせタイトル"
                title="お知らせタイトルを入力してください"
                aria-label="お知らせタイトル"
                ref={formRefs.current.announcementTitle}
                disabled={formLocked}
                required
                maxLength={100}
                value={formData.announcementTitle}
                onChange={ev => handleChange('announcementTitle', ev.target.value)} />
            </div>
            <ErrorMessageList messages={errors.announcementTitle ?? []} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>お知らせ内容</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-textarea wdt1200">
              <SafeCharTextArea name="お知らせ内容" rows="4"
                title="お知らせ内容を入力してください"
                aria-label="お知らせ内容"
                ref={formRefs.current.announcementContent}
                disabled={formLocked}
                required
                maxLength={500}
                value={formData.announcementContent}
                onChange={val => handleChange('announcementContent', val)} />
            </div>
            <ErrorMessageList messages={errors.announcementContent ?? []} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>公開開始日</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div style={{ display: 'flex' }}>
              <div className="input-form wdt140" style={{ marginRight: '20px' }}>
                <DatePicker
                  title="公開開始日（日）を入力してください"
                  dateFormat='yyyy/MM/dd'
                  locale='ja'
                  id="announcementStartDatetime_ymd"
                  ref={formRefs.current.announcementStartDatetime_ymd}
                  disabled={formLocked}
                  selected={announcementStartDatetime_ymd}
                  maxDate={AnnouncementMaxDate}
                  AnnouncementMaxDate
                  onChange={date => handleDateChange('announcementStartDatetime_ymd', date)} />
              </div>
              <div className="input-form wdt100">
                <DatePicker
                  title="公開開始日（時刻）を入力してください"
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="時刻"
                  dateFormat='HH:mm'
                  locale='ja'
                  timeIntervals={30}
                  id="announcementStartDatetime_hm"
                  ref={formRefs.current.announcementStartDatetime_hm}
                  disabled={formLocked}
                  selected={announcementStartDatetime_hm}
                  onChange={date => handleDateChange('announcementStartDatetime_hm', date)} />
              </div>
            </div>
            <ErrorMessageList messages={announcementStartDatetimeErrors} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>公開終了日</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div style={{ display: 'flex' }}>
              <div className="input-form wdt140" style={{ marginRight: '20px' }}>
                <DatePicker
                  title="公開終了日（日）を入力してください"
                  dateFormat='yyyy/MM/dd'
                  locale='ja'
                  id="announcementEndDatetime_ymd"
                  ref={formRefs.current.announcementEndDatetime_ymd}
                  disabled={formLocked}
                  selected={announcementEndDatetime_ymd}
                  minDate={announcementStartDatetime_ymd}
                  maxDate={AnnouncementMaxDate}
                  AnnouncementMaxDate
                  onChange={date => handleDateChange('announcementEndDatetime_ymd', date)} />
              </div>
              <div className="input-form wdt100">
                <DatePicker
                  title="公開終了日（時刻）を入力してください"
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="時刻"
                  dateFormat='HH:mm'
                  locale='ja'
                  timeIntervals={30}
                  id="announcementEndDatetime_hm"
                  ref={formRefs.current.announcementEndDatetime_hm}
                  disabled={formLocked}
                  selected={announcementEndDatetime_hm}
                  onChange={date => handleDateChange('announcementEndDatetime_hm', date)} />
              </div>
            </div>
            <ErrorMessageList messages={announcementEndDatetimeErrors} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '130px' }}>メール通知</dt>
          <dd className="form-body">
            <fieldset>
              <legend>メール通知</legend>
              <ul className="list-form ml0">
                <li className="ml0">
                  <input type="checkbox" id="announcementMailFlag" name="メール通知" value="メール通知"
                    checked={formData.announcementMailFlag}
                    disabled={formLocked || !!formData.announcementMailDatetime}
                    onChange={ev => handleChange('announcementMailFlag', ev.target.checked)} />
                  <label htmlFor="announcementMailFlag" className="form-checkbox"></label>
                </li>
              </ul>
            </fieldset>
          </dd>
        </dl>

        <dl className="form-set">
          <dd className="form-body">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>{formData.announcementMailDatetime}</span>
            </div>
          </dd>
        </dl>
      </div>

      <div className="dif j-between mt20">
        <div className="l-buttons">
          {
            showSubmit && (
              <p className="btn label bg-orange" style={{ width: '190px' }}>
                <input id="register" type="button"
                  disabled={formLocked}
                  value={submitBtn}
                  onClick={onSubmitClick} />
                <label htmlFor="register"><i className="icn check"></i>{submitBtn}</label>
              </p>
            )
          }
        </div>

        {
          showDelete && (
            <p className="btn c-aniplex" style={{ width: '203px' }}>
              <button
                disabled={formLocked}
                onClick={onDeleteClick}
              ><i className="icn cross"></i>お知らせを削除</button>
            </p>
          )
        }
      </div>

      {
        mailConfirm.showFlg && (
          <MessagePopup
            message={`このお知らせは公開開始時にメール通知を行います。${execType}してよろしいですか？`}
            btn={{ ok: execType, cancel: 'キャンセル' }}
            btnClass="bg-aniplex"
            onClose={mailConfirm.onClose} />
        )
      }
      {
        deleteConfirm.showFlg && (
          <MessagePopup
            message="お知らせを削除します。よろしいですか？"
            btn={{ ok: '削除', cancel: 'キャンセル' }}
            btnClass="bg-aniplex"
            onClose={deleteConfirm.onClose} />
        )
      }
    </>
  )
}

/**
 * 全項目のバリデートを行う
 * @param {FormData} formData フォームデータ
 * @returns {Record<keyof FormData, string[]>} エラーメッセージ
 */
function validateAll(formData) {
  const errors = {};
  Object.entries(formData).forEach(([prop, value]) => {
    const error = validate(prop, value, formData);
    errors[prop] = error;
  });

  return errors;
}

/**
 * バリデート処理
 * @param {keyof FormData} prop 対象のプロパティ名
 * @param {FormData[prop]} value 対象の値
 * @param {FormData} data フォームデータ
 * @returns {string[]} エラーメッセージ
 */
function validate(prop, value, data) {
  switch (prop) {
    // お知らせ件名
    case 'announcementTitle':
      return validateAnnouncementTitle(value);
    // お知らせ件名
    case 'announcementContent':
      return validateAnnouncementContent(value);
    // 公開開始日時（日）
    case 'announcementStartDatetime_ymd':
      return validateAnnouncementStartDatetime_ymd(value);
    // 公開開始日時（時刻）
    case 'announcementStartDatetime_hm':
      return validateAnnouncementStartDatetime_hm(value);
    // 公開終了日時（日）
    case 'announcementEndDatetime_ymd':
      return validateAnnouncementEndDatetime_ymd(value);
    // 公開終了日時（時刻）
    case 'announcementEndDatetime_hm':
      return validateAnnouncementEndDatetime_hm(value, data);
    default:
      return [];
  }
}

/**
 * お知らせ件名のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
function validateAnnouncementTitle(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 100)) {
    errors.push(getMessage('maxLength', { max: 100 }));
  }

  return errors;
}

/**
 * お知らせ内容のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
 function validateAnnouncementContent(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!maxLength(value, 500)) {
    errors.push(getMessage('maxLength', { max: 500 }));
  }

  return errors;
}

/**
 * 公開開始日時（日）のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
 function validateAnnouncementStartDatetime_ymd(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 公開開始日時（時刻）のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
 function validateAnnouncementStartDatetime_hm(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 公開終了日時（日）のバリデート処理
 * @param {string} value 対象の値
 * @returns {string[]} エラーメッセージ
 */
 function validateAnnouncementEndDatetime_ymd(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

/**
 * 公開終了日時（時刻）のバリデート処理
 * @param {string} value 対象の値
 * @param {FormData} data フォームデータ
 * @returns {string[]} エラーメッセージ
 */
 function validateAnnouncementEndDatetime_hm(value, data) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  // 相関チェック
  const msg = '公開開始日以降の日時を設定してください';
  if (!isEmpty(data.announcementStartDatetime_ymd) &&
        !isEmpty(data.announcementStartDatetime_hm) &&
        !isEmpty(data.announcementEndDatetime_ymd)) {
    const startDateTime = `${data.announcementStartDatetime_ymd} ${data.announcementStartDatetime_hm}`;
    const endDateTime = `${data.announcementEndDatetime_ymd} ${value}`;
    if (startDateTime >= endDateTime) {
      errors.push(msg);
    }
  }

  return errors;
}
