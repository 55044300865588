import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { announcementAniplex, statusListAnnouncement } from '../../common/headerList.jsx';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { AnnouncementListSearchForm } from './AnnouncementListSearchForm';
import { fetchAnnouncementAll, selectApiStatus, clearApiStatus, clearAnnouncementList, selectAnnouncementList } from '../../../slices/aniplex/announcementSlice';
import { immutableSort } from '../../../lib/util';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
announcementAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/**
 * [A]お知らせ一覧一覧画面
 * @returns
 */
export const AnnouncementListPage = () => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchAnnouncementAll;
  const announcementList = useSelector(selectAnnouncementList);

  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(announcementAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'announcementNo', order: 'desc' });


  // マウント時にお知らせ一覧を取得
  useEffect(() => {
    dispatch(fetchAnnouncementAll());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('fetchAnnouncementAll'));
      dispatch(clearAnnouncementList());
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // 検索
  const onSearch = useCallback(params => {
    dispatch(fetchAnnouncementAll(params));
  }, [dispatch]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(announcementAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const announcements = announcementList ?? [];
    return announcements;
  }, [announcementList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用のレコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {
      const statusText = statusListAnnouncement?.[record.announcementStatus]?.text ?? '';

      return {
        ...record,
        announcementNo: (<Link to={`../announcementUpdate/${record.announcementId}`}>{record.announcementNo}</Link>),
        announcementStartDatetime: record.announcementStartDatetime ? record.announcementStartDatetime.substring(0, 10) : '',
        announcementEndDatetime: record.announcementEndDatetime ? record.announcementEndDatetime.substring(0, 10) : '',
        announcementRegDatetime: record.announcementRegDatetime ? record.announcementRegDatetime.substring(0, 10) : '',
        announcementEditDatetime: record.announcementEditDatetime ? record.announcementEditDatetime.substring(0, 10) : '',
        announcementStatus: statusText,
      };
    });
  }, [sortedRecords]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">お知らせ一覧</h1>
      </div>
      <div className="mt30">
        <p className="btn bg-orange" style={{width: '160px'}}>
          <Link to="../announcementRegister">
            <i className="icn plus"></i>
            お知らせ登録
          </Link>
        </p>
      </div>
      <hr />

      <AnnouncementListSearchForm onSearch={onSearch} />
      <TableVisible
        isOpen={isOpen}
        headers={announcementAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(announcementList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          current={current}
          pageSize={pageSize}
          sortParam={sortInfo}
          emptyMessage="検索条件と一致するお知らせ情報がありません。"
          scrollable
          resizable
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}
    </div>
  );
};
