//@ts-check
import React, { useCallback, useMemo, useState } from "react";
import { getMessage } from "../../../lib/message";
import { isEmpty, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { SafeCharTextArea } from "../../common/SafeCharTextArea";

/**
 * 企画の取り下げ用ポップアップ
 * @param {object} props
 * @param {OnClose} props.onClose 閉じるときのコールバック
 * @returns
 */
export const ProposalCancelPopup = ({ onClose }) => {
  // 取り下げ理由
  const [messageContent, setMessageContent] = useState('');
  // エラーメッセージ
  const [messages, setMessages] = useState({
    messageContent: /** @type {string[]} */ ([]),
  });

  /** エラーフラグ */
  const hasError = useMemo(() => {
    return Object.values(messages).flat().length > 0;
  }, [messages]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      messageContent: validateMessageContent(value),
    }));
    setMessageContent(value);
  }, []);

  /** 取り下げボタン押下時のハンドラ */
  const onCancel = useCallback(() => {
    const errors = {
      messageContent: validateMessageContent(messageContent),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      return;
    }

    onClose('ok', messageContent);
  }, [messageContent, onClose]);

  return (
    // @ts-expect-error
    <FlexiblePopup className="no-btn-close">
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required">取り下げ理由</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="form-textarea wdt300">
              {/* @ts-expect-error */}
              <SafeCharTextArea
                rows={4}
                value={messageContent}
                onChange={val => handleChange(val)} />
            </div>
            {/* @ts-expect-error */}
            <ErrorMessageList messages={messages.messageContent} />
          </dd>
        </dl>
      </div>

      <div className="btn-wrapper">
        <div className="btn label mt15">
          <button
            onClick={() => onClose('cancel')}
          >キャンセル</button>
        </div>
        <div className="btn mt15 bg-pink">
          <button
            disabled={hasError}
            onClick={onCancel}
          >取り下げ</button>
        </div>
      </div>

    </FlexiblePopup>
  );
}

/**
 * 取り下げ理由のバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateMessageContent(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  if (!maxLength(value, 500)) {
    errors.push(getMessage('maxLength', { max: 500 }));
  }

  return errors;
}

//#region
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'ok'|'cancel'} btn 閉じるときに押されたボタン
 * @param {string} [messageContent] 取り下げ理由(btn='ok'のときのみ設定される)
 */
//#endregion
