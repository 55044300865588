//@ts-check
import React, { useMemo } from 'react';
import { isEmpty } from '../../../lib/validator';
import { ChangeStatusIcon } from './ChangeStatusIcon';

/** 値が空の場合の表示文字列 */
const emptyString = '（空欄）';

/**
 * レコードの更新状態表示
 * @template {Record<string, any>} T
 * @param {object} params
 * @param {T|null|undefined} params.record レコード
 * @param {T|null|undefined} params.prevRecord 前のバージョンのレコード
 * @param {Record<keyof T, string>} params.fieldName レコードのキーに対応する項目表示名
 * @param {(keyof T)[]} params.fieldOrder 項目の表示順
 */
export const RecordChangeStatus = ({
  record,
  prevRecord,
  fieldName,
  fieldOrder,
}) => {
  /** 新規フラグ */
  const isNew = useMemo(() =>
    record != null && prevRecord == null
  , [prevRecord, record]);

  /** 変更フラグ */
  const isChanged = useMemo(() => {
    if (record == null || prevRecord == null) {
      return false;
    }

    return fieldOrder.some(f => record[f] !== prevRecord[f]);
  }, [fieldOrder, prevRecord, record]);

  /** アイコン表示フラグ */
  const showIcon = useMemo(() => isNew || isChanged, [isChanged, isNew]);

  /** アイコンのCSSクラス */
  const iconClass = useMemo(() => {
    if (isNew) {
      return 'new no-tooltip';
    }
    if (isChanged) {
      return 'changed';
    }
    return '';
  }, [isChanged, isNew]);

  /** ツールチップの内容 */
  const tooltipText = useTooltipText({ record, prevRecord, fieldName, fieldOrder });

  return showIcon ? (
    <ChangeStatusIcon
      className="record-change-status"
      iconClass={iconClass}
      tooltipText={tooltipText}
      hideTooltip={isNew} />
  ): null;
}

/**
 * ツールチップに表示するテキスト
 * @template {Record<string, any>} T
 * @param {object} params
 * @param {T|null|undefined} params.record レコード
 * @param {T|null|undefined} params.prevRecord 前のバージョンのレコード
 * @param {Record<keyof T, string>} params.fieldName レコードのキーに対応する項目表示名
 * @param {(keyof T)[]} params.fieldOrder 項目の表示順
 */
const useTooltipText = ({
  record,
  prevRecord,
  fieldName,
  fieldOrder,
}) => {
  return useMemo(() => {
    return fieldOrder
      .filter(f => record?.[f] !== prevRecord?.[f])
      .map(f => ({
        key: fieldName[f],
        val: isEmpty(prevRecord?.[f]) ?
          emptyString :
          (prevRecord?.[f] ?? emptyString),
      }))
      .map(({ key, val }) => `${key}：${val}`)
      .join('\n');
  }, [fieldName, fieldOrder, prevRecord, record]);
}
