//@ts-check
import React, { useCallback, useMemo, useState } from "react"
import { MessagePopup } from "../../common/MessagePopup";
import { RoyaltyRequestPopup } from "./RoyaltyRequestPopup";

/** ポップアップの表示ステップ */
const Step = Object.freeze({
  /** 非表示 */
  Hide: 0,
  /** 未報告証紙警告 */
  Unreported: 1,
  /** 申請確認 */
  Confirm: 2,
});

/** 表示ステップの順番 */
const stepOrder = [
  Step.Hide,
  Step.Unreported,
  Step.Confirm,
]

/**
 * RY報告申請確認ポップアップ用のカスタムフック
 * @param {object} params
 * @param {OnOK} params.onOk OKボタン押下時のコールバック
 * @param {() => void} [params.onCancel] キャンセルボタン、閉じるボタン押下時のコールバック
 * @param {number|undefined} params.targetPeriod 対象の期
 * @param {Product[]|undefined} params.productList 商品リスト
 * @param {RyAmount[]} params.ryAmountList ロイヤリティ金額情報のリスト
 */
export const useApplyConfirmPopup = ({
  onOk,
  onCancel,
  targetPeriod,
  productList,
  ryAmountList,
}) => {
  // 現在の表示ステップ
  const [step, setStep] = useState(/** @type {Step} */(Step.Hide));

  /** 未報告証紙存在フラグ */
  const unreportedExist = useMemo(() => {
    return productList?.some(p => {
      const productPeriod = p.periodList.find(pr => pr.period === targetPeriod);
      const ryAmount = ryAmountList.find(r => r.productId === p.productId);

      const unreported = (productPeriod?.resultLabel ?? 0) - (ryAmount?.reportProduction ?? 0)
      return unreported > 0;
    }) ?? false
  }, [productList, ryAmountList, targetPeriod]);

  /** 次のステップに進む */
  const nextStep = useCallback(() => {
    const curIdx = stepOrder.indexOf(step);
    for (const target of stepOrder.slice(curIdx + 1)) {
      if (target === Step.Unreported && unreportedExist) {
        setStep(Step.Unreported);
        return;
      }
    }
    setStep(Step.Confirm);
  }, [step, unreportedExist]);

  /** ポップアップ表示 */
  const showPopup = useCallback(() => {
    if (step !== Step.Hide) {
      return;
    }
    nextStep();
  }, [nextStep, step]);

  /** 閉じるときのコールバック */
  const onClose = useCallback(
    /** @param {'ok'|'cancel'|'close'} btn */
    (btn) => {
      if (btn === 'cancel' || btn === 'close') {
        if (typeof onCancel === 'function') {
          onCancel();
        }
        setStep(Step.Hide);
        return;
      }

      if (btn === 'ok') {
        switch (step) {
          case Step.Unreported:
            nextStep();
            break;
          default:
            setStep(Step.Hide);
            break;
        }
      }
    }, [nextStep, onCancel, step]);

  /** 申請ポップアップを閉じるときのコールバック */
  const onRequestPopupClose = useCallback(
    /**
     * @param {'ok'|'cancel'} btn 押されたボタン
     * @param {string} [messageContent] 申請コメント
     */
    (btn, messageContent) => {
      if (btn === 'cancel') {
        if (typeof onCancel === 'function') {
          onCancel();
        }
        setStep(Step.Hide);
        return;
      }

      if (messageContent != null) {
        onOk(messageContent);
      }
      setStep(Step.Hide);
    }, [onCancel, onOk]);

  /** ポップアップ */
  const popup = useMemo(() => {
    if (step === Step.Hide) {
      return null;
    }
    if (step === Step.Unreported) {
      // @ts-expect-error ts(2786)
      return <MessagePopup
        message={`生産報告がされていない未報告証紙が存在します。\n内容を再確認する場合は「キャンセル」を選択し、未報告証紙数と生産数の確認をお願いします。\n問題が無い場合は「確認済み」を選択してください。`}
        btn={{ ok: '確認済み', cancel: 'キャンセル' }}
        btnClass="bg-pink"
        onClose={onClose} />
    }

    return (
      // @ts-expect-error ts(2786)
      <RoyaltyRequestPopup
        onClose={onRequestPopupClose} />
    )
  }, [onClose, onRequestPopupClose, step]);

  return {
    popup,
    showPopup,
  };
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').Product} Product
 */
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RyAmount} RyAmount
 */
/**
 * @typedef {valueOf<typeof Step>} Step ポップアップ表示ステップ
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
/**
 * @callback OnOK 申請ボタンが押されたときのコールバック
 * @param {string} messageContent 申請コメント
 */
//#endregion typedef
