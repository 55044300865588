import { useCallback, useMemo } from "react";
import { basename } from "../../../lib/util";
import { UploadFile } from "../UploadFile";
import { UploadFileShowOnly } from "../UploadFileShowOnly";
import { ErrorMessageList } from "../../common/ErrorMessageList";

/** アップロード可能なファイル拡張子 */
const acceptableExt = [
  'zip',
  'ppt', 'pptx', 'pptm',
  'pdf',
  'doc', 'docm', 'docx',
  'xls', 'xlsm', 'xlsx',
  'jpg', 'jpeg',
  'png', 'gif',
];

/**
 * 企画申請画面のファイルアップロードフォーム
 * @param {object} props
 * @param {object[]} props.newFileList 新たにアップロードされたファイルのリスト
 * @param {Function} props.setNewFileList 新規アップロードファイルリスト変更時のコールバック
 * @param {object[]} props.uploadedFileList アップロード済みのファイルのリスト
 * @param {Function} props.setUploadedFileList アップロード済みファイルリスト変更時のコールバック
 * @param {(list: Array) => void} props.onChange ファイルリスト変更時のコールバック
 * @param {(hasError: boolean) => void} props.onChangeHasError アップロードエラー状態変更時のコールバック
 * @param {(hasProgress: boolean) => void} props.onChangeHasProgress アップロード進行状態変更時のコールバック
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {boolean} props.isEditable 編集可能モードフラグ
 * @returns
 */
export const ProposalUploadForm = ({
  newFileList,
  setNewFileList,
  uploadedFileList,
  setUploadedFileList,
  onChangeHasError,
  onChangeHasProgress,
  onChangeHasExceeded,
  formLocked,
  errorMessages,
  isEditable,
}) => {

  /** アップロードコンポーネント用の新規ファイルリスト */
  const newFileListForUploader = useMemo(() => {
    return newFileList.map(i => ({
      fileNo: i.proposalAttachmentNo,
    }));
  }, [newFileList]);

  /** アップロードコンポーネントの用のアップロード済みファイルリスト */
  const uploadedFileListForUploader = useMemo(() => {
    return uploadedFileList.map(i => ({
      fileNo: i.proposalAttachmentNo,
      filename: basename(i.proposalAttachmentFilepath),
    }));
  }, [uploadedFileList]);

  /** ファイルアップロード時のコールバック */
  const onChangeFileNoList = useCallback((fileNoList) => {
    setNewFileList(
      fileNoList.map(no => ({ proposalAttachmentNo: no }))
    );
  }, [setNewFileList]);

  /** アップロード済みファイル変更時のコールバック */
  const onChangeUploaded = useCallback((fileNoList) => {
    setUploadedFileList(prev => {
      return fileNoList.map(no => {
        const found = prev.find(i => i.proposalAttachmentNo === no);
        return {
          proposalAttachmentNo: no,
          proposalAttachmentFilepath: found?.proposalAttachmentFilepath ?? '',
        }
      });
    });
  }, [setUploadedFileList]);

  return (
    <div className="l-form">
      <dl className="form-set">
        <dt className="form-name db" style={{ width: '135px' }}>
          <strong className="db fwb">提案資料等</strong>
          <span className="fwn fss">※複数資料添付可能</span>
        </dt>
        <dd className="form-body wdt1200">
          {
            isEditable ? (
              <UploadFile
                filetype='proposalAttachment'
                maxCnt={5}
                fileNoList={newFileListForUploader}
                uploadedFileNoList={uploadedFileListForUploader}
                onChangeFileNoList={onChangeFileNoList}
                onChangeHasError={onChangeHasError}
                onChangeHasProgress={onChangeHasProgress}
                onChangeUploaded={onChangeUploaded}
                onChangeHasExceeded={onChangeHasExceeded}
                acceptableExt={acceptableExt}
                maxFileSize={5 * 1024 * 1024}
                maxFileSizeErrMsg='ファイルサイズが5MBを超えています。' />
            ) :
            (
              <UploadFileShowOnly
                filetype='proposalAttachment'
                fileList={uploadedFileListForUploader} />
            )
          }
          <ErrorMessageList messages={errorMessages} />
          <p className="mt10">
            ファイル形式：ZIP, PDF, Microsoft Word, Microsoft Excel, Microsoft PowerPoint, JPEG, PNG, GIF<br />
            ファイルサイズ：5MB以内<br />
            ファイル数：5つ以内<br />
            <span className="c-pink">
              ※指定形式に当てはまらない場合は、データ便にアップロードいただき、企画本文に該当URLの記載をお願い致します。
            </span>
          </p>
        </dd>
      </dl>
    </div>
  );
}
