import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from 'react-datepicker';
import { getMessage } from "../../../lib/message";
import { isEmpty, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { SafeCharTextArea } from "../../common/SafeCharTextArea";
import { ProposalUploadForm } from "./ProposalUploadForm";

/** このフォームで扱うプロパティ */
const targetProps = [
  'proposalLaunchDate',
  'proposalDetail',
  'proposalRemarks',
];

/**
 * 企画申請画面の企画内容部分のフォーム
 * @param {object} props
 * @param {FormData} props.data フォーム内容のデータ
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject<{}>} props.formRefs フォーム項目へのref
 * @param {OnChange} props.onChange フォームの入力内容変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @returns
 */
export const ProposalContentForm = ({
  data,
  formLocked,
  formRefs,
  onChange,
  onValidated,
  validateAllFlg = false,
  newFileList,
  setNewFileList,
  uploadedFileList,
  setUploadedFileList,
}) => {
  // ファイルアップロードエラー
  const [hasUploadError, setHasUploadError] = useState(false);
  // ファイルアップロード進行中フラグ
  const [hasUploadProgress, setHasUploadProgress] = useState(false);
  // 提案資料件数上限超過の有無
  const [hasExceeded, setHasExceeded] = useState(false);

  const [messages, setMessages] = useState({
    /** 発売開始希望日 */
    proposalLaunchDate: [],
    /** 企画説明 */
    proposalDetail: [],
    /** その他備考 */
    proposalRemarks: [],
  });

  /** @type {FormData} フォームデータ */
  const formData = useMemo(() => {
    return {
      /** 発売開始希望日 */
      proposalLaunchDate: '',
      /** 企画説明 */
      proposalDetail: '',
      /** その他備考 */
      proposalRemarks: '',
      ...data,
    }
  }, [data]);

  /** ファイルアップロードのエラー非表示 */
  const uploadErrors = useMemo(() => {
    if (hasUploadProgress) {
      return ['アップロード中の提案資料があります。'];
    }
    if (hasExceeded) {
      return ['提案資料は5点まで登録できます。'];
    }
    if (hasUploadError) {
      return ['提案資料にエラーがあります。'];
    }
    return [];
  }, [hasUploadProgress, hasUploadError, hasExceeded]);

  // ファイルアップロードのエラーを通知(申請ボタン・保存ボタンの非活性化のため)
  useEffect(() => {
    onValidated('proposalAttachmentList', {
      apply: uploadErrors,
      tmp: uploadErrors,
    });
  }, [onValidated, uploadErrors])

  // ファイルアップロードのエラーを上流へ通知
  useEffect(() => {
    onValidated('proposalAttachment', uploadErrors);
  }, [onValidated, uploadErrors]);

  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っているときは全項目をバリデートする
      const newMessages = {};
      targetProps.forEach(prop => {
        let value = formData[prop];
        if (prop === 'proposalLaunchDate') {
          value = isEmpty(value) ? null : dayjs(value, 'YYYY/MM/DD').toDate();
        }
        const errors = validate(prop, value);
        onValidated(prop, errors);
        newMessages[prop] = errors.apply;
      });
      setMessages(prevMessages => ({
        ...prevMessages,
        ...newMessages,
      }));
    }
  }, [formData, onValidated, validateAllFlg])

  /** @type {Date|null} 発売開始希望日 */
  const proposalLaunchDate = useMemo(() => {
    // 日付文字列をDateオブジェクトに変換
    return isEmpty(formData.proposalLaunchDate) ?
      null :
      dayjs(formData.proposalLaunchDate, 'YYYY/MM/DD').toDate();
  }, [formData.proposalLaunchDate]);

  /**
   * フォーム更新ハンドラ
   * @type {(name: keyof FormData, value: *) => void}
   */
  const handleChange = useCallback((name, value) => {
    const errors = validate(name, value);
    onValidated(name, errors);
    setMessages({
      ...messages,
      [name]: errors.apply,
    });
    if (name === 'proposalLaunchDate') {
      value = isEmpty(value) ? '' : dayjs(value).format('YYYY/MM/DD');
    }
    onChange(name, value);
  }, [onValidated, messages, onChange]);

  return (
    <section className="mt30">
      <div className="title-pink">
        <h2 className="title"><i className="icn note"></i>企画内容</h2>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '132px' }}>発売開始希望日</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name="発売開始希望日"
                title="発売開始希望日を必ず入力してください"
                aria-label="発売開始希望日"
                required
                dateFormat="yyyy/MM/dd"
                locale="ja"
                ref={formRefs.current.proposalLaunchDate}
                disabled={formLocked}
                selected={proposalLaunchDate}
                onChange={date => handleChange('proposalLaunchDate', date)} />
            </div>
            <ErrorMessageList messages={messages.proposalLaunchDate} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '132px' }}>企画説明</dt>
          <dd className="form-body">
            <div className="form-textarea wdt1200">
              <SafeCharTextArea rows="4"
                name="企画説明"
                aria-label="企画説明"
                title="企画説明を必ず入力してください"
                required
                ref={formRefs.current.proposalDetail}
                disabled={formLocked}
                value={formData.proposalDetail}
                onChange={val => handleChange('proposalDetail', val)} />
            </div>
            <ErrorMessageList messages={messages.proposalDetail} />
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '132px' }}>その他備考</dt>
          <dd className="form-body">
            <div className="form-textarea wdt1200">
              <SafeCharTextArea rows="4"
                name="その他備考"
                title="その他備考を入力してください"
                aria-label="その他備考"
                ref={formRefs.current.proposalRemarks}
                disabled={formLocked}
                value={formData.proposalRemarks}
                onChange={val => handleChange('proposalRemarks', val)} />
            </div>
            <ErrorMessageList messages={messages.proposalRemarks} />
          </dd>
        </dl>
      </div>

      <ProposalUploadForm
        newFileList={newFileList}
        setNewFileList={setNewFileList}
        uploadedFileList={uploadedFileList}
        setUploadedFileList={setUploadedFileList}
        onChangeHasError={setHasUploadError}
        onChangeHasProgress={setHasUploadProgress}
        onChangeHasExceeded={setHasExceeded}
        formLocked={formLocked}
        errorMessages={uploadErrors}
        isEditable={!formLocked}
      />
    </section>
  )
}

/**
 * フォーム入力内容をバリデートする
 * @param {keyof FormData} name 入力された項目名
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
export function validate(name, value) {
  switch (name) {
    // 発売開始希望日
    case 'proposalLaunchDate':
      return validateProposalLaunchDate(value);
    // 企画説
    case 'proposalDetail':
      return validateProposalDetail(value);
    // その他備考
    case 'proposalRemarks':
      return validateProposalRemarks(value);
    default:
      // 上記以外の項目の場合は何もしない
      return { tmp: [], apply: [] };
  }
}

/**
 * 発売開始希望日のバリデートを行う
 * @param {Date|null} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateProposalLaunchDate(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
  }

  return errors;
}

/**
 * 企画説明のバリデートを行う
 * @param {string} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateProposalDetail(value) {
  const errors = {
    tmp: [],
    apply: [],
  };
  // 申請向けバリデート継続フラグ
  let continueApply = true;

  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    continueApply = false;
  }

  if (!maxLength(value, 500)) {
    errors.tmp.push(getMessage('maxLength', { max: 500 }));
    if (continueApply) {
      errors.apply.push(getMessage('maxLength', { max: 500 }));
    }
  }

  return errors;
}

/**
 * その他備考のバリデートを行う
 * @param {string} value 入力された値
 * @returns {{ tmp: string[], apply: string }} エラーメッセージ
 */
function validateProposalRemarks(value) {
  const errors = {
    tmp: [],
    apply: [],
  };

  if (!maxLength(value, 500)) {
    errors.tmp.push(getMessage('maxLength', { max: 500 }));
    errors.apply.push(getMessage('maxLength', { max: 500 }));
  }

  return errors;
}

//#region typedef
/**
 * @typedef {object} FormData フォームの入力・表示用データ
 * @property {string} proposalLaunchDate 発売開始希望日
 * @property {string} proposalDetail 企画説明
 * @property {string} proposalRemarks その他備考
 */
/**
 * @callback OnChange フォームの入力内容変更時のハンドラ
 * @param {keyof FormData} prop 変更されたプロパティ
 * @param {*} value 変更後の値
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {keyof FormData} prop バリデート対象のプロパティ
 * @param {string[]} errors エラーメッセージのリスト
 */
//#endregion typedef
