import { useCallback, useEffect, useState } from 'react';
import { UploadList } from '../common/UploadList';
import { getImageRetry } from '../../lib/api/licensee';
import { getImageType } from '../../lib/util';
import { ApiErrorMessage } from '../../lib/message';

/**
 * ライセンシー側・画像参照APIから取得したサムネイルを表示
 * @returns
 */
export const UploadedImageList = ({ fileNoList = [], onDeleteFile }) => {

  // サムネイル一覧
  const [fileEntries, setFileEntries] = useState([]);

  // fileNoごとの画像を保持する
  const [memo, setMemo] = useState({});

  // 取得中
  const [progress, setProgress] = useState({});

  // エラー
  const [errors, setErrors] = useState({});

  // 画像参照APIから画像を取得してメモに保持する
  const fetchFileImage = useCallback(async (key, fileNo) => {
      try {
        setProgress(prev => ({ ...prev, [fileNo]: true }));
        const data = (await getImageRetry({ renderingImageNo: fileNo }))?.result?.data ?? {};
        const { imageBinary } = data;
        const imageType = imageBinary && getImageType(imageBinary);
        if (imageBinary && imageType) {
          const imageSrc = 'data:' + imageType + ';base64,' + imageBinary;
          setMemo(memo => ({ ...memo, [fileNo]: imageSrc}));
        }
      } catch {
        setErrors(prev => ({
          ...prev,
          [fileNo]: ApiErrorMessage.GetImageFailed,
        }));
      } finally {
        setProgress(prev => ({ ...prev, [fileNo]: false }));
      }
  }, []);

  // マウント時、削除時に状態を更新する
  useEffect(() => {
    setFileEntries(fileNoList.map(fileNo => {
      const entry = {
        completed: true,
        // APIから取得した画像を設定
        imageSrc: memo[fileNo] || '',
        key: fileNo, // ここに入るfileNoは一意の前提
        name: '',
        fileNo,
        hasError: !!errors[fileNo],
        errorMessage: errors[fileNo] ?? '',
      };
      // APIから画像を取得していない時は取得する
      if (!memo[fileNo] && !errors[fileNo] && !progress[fileNo]) {
        fetchFileImage(fileNo, fileNo);
      }
      return entry;
    }));
  }, [memo, fileNoList, fetchFileImage, errors, progress]);

  return (
    <UploadList fileEntries={fileEntries} onDeleteFile={onDeleteFile} />
  );
};
