//@ts-check
import dayjs from 'dayjs';

// 一覧系画面共通処理

/**
 * @template {{}} T
 * @param {T} params
 * @returns {Partial<{[K in keyof T]: Exclude<T[K], ''|0|false|null|undefined>}>}
 */
export const emptyToUndefined = params => {
  const newParams = {...params};
  Object.keys(params).forEach(key => {
    newParams[key] = newParams[key] || undefined;
  });
  return newParams;
};

/**
 * @param {Date|undefined|null} date
 * @returns {string|undefined}
 */
export const formatDate = date => {
  if (!date) {
    return undefined;
  }
  return dayjs(date).format('YYYY/MM/DD');
};
