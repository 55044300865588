import { useCallback, useState } from "react"
import { Constants } from "../../../Constants";

/** 検索フォームの初期値 */
const defaultParams = {
  /** @type {string} 社員番号 */
  employeeNo: '',
  /** @type {string} 氏名 */
  employeeName: '',
  /** @type {string} メールアドレス */
  mailaddress: '',
  /** @type {string} ロール */
  role: '',
};

/**
 * [A]ANIPLEXユーザー一覧画面の検索フォーム
 * @param {object} props
 * @param {(params: Record<string, string>) => void} props.onSearch 検索実行時のコールバック
 */
export const AnxUserListSearchForm = ({ onSearch }) => {
  const [params, setParams] = useState(defaultParams);

  /**
   * フォーム内容変更時のハンドラ
   * @type {(name: keyof params, value: (typeof params)[name]) => void}
   */
  const handleChange = useCallback((name, value) => {
    setParams(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    if (typeof onSearch !== 'function') {
      return;
    }

    onSearch(formatParams(params));
  }, [onSearch, params]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    setParams(defaultParams);
    if (typeof onSearch !== 'function') {
      return;
    }

    onSearch({});
  }, [onSearch]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '80px' }}>社員番号</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="社員番号"
                title="社員番号を入力してください"
                aria-label="社員番号"
                value={params.employeeNo}
                onChange={ev => handleChange('employeeNo', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">氏名</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="氏名"
                title="氏名を入力してください"
                aria-label="氏名"
                value={params.employeeName}
                onChange={ev => handleChange('employeeName', ev.target.value)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">メールアドレス</dt>
          <dd className="form-body">
            <div className="input-form wdt500">
              <input type="email" name="メールアドレス"
                title="メールアドレスを入力してください"
                aria-label="メールアドレス"
                value={params.mailaddress}
                onChange={ev => handleChange('mailaddress', ev.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '80px' }}>ロール</dt>
          <dd className="form-body">
            <div className="form-select wdt200">
              <select name="ロール" title="ロールを選択してください"
                value={params.role}
                onChange={ev => handleChange('role', ev.target.value)}
              >
                <option value=""></option>
                <option value={Constants.Aniplex.UserRole.Manager}>管理者</option>
                <option value={Constants.Aniplex.UserRole.Tanto}>担当者</option>
                <option value={Constants.Aniplex.UserRole.Assistant}>アシスタント</option>
                <option value={Constants.Aniplex.UserRole.SystemOperator}>システム運用者</option>
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{ width: '160px' }}>
          <button
            onClick={onSearchClick}
          ><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{ width: '160px' }}>
          <button
            onClick={onClearClick}
          ><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  )
}

/**
 * 入力されたパラメータを検索リクエスト用のパラメータに変換する
 * @param {typeof defaultParams} params フォームに入力されたパラメータ
 * @returns {Record<string, string>} フォーマット後のパラメータ
 */
function formatParams(params) {
  const result = {};

  Object.entries(params).forEach(([prop, value]) => {
    if (value == null || !String(value)) {
      // nullish, 空文字相当の値は設定しない
      return;
    }

    result[prop] = String(value);
  });

  return result;
}
