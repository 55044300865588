import { useCallback, useEffect, useMemo, useState } from "react"
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, useNavigate  } from 'react-router-dom';
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty, isValidEmail, maxLength } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList"
import { clearApiStatus, invitation, selectApiStatus } from "../../../slices/licensee/licenseeUsersSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { pushMessage } from "../../../slices/licensee/utilSlice";

/** フォーム項目の順序 */
const formPropOrder = [
  'mailAddress',
];

/**
 * ユーザー招待画面
 * @returns
 */
export const UserInvitationPage = () => {
  const authRedirect = useAuthRedirect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus).invitation;

  const [mailAddress, setMailAddress] = useState('');
  const [messages, setMessages] = useState({
    mailAddress: [],
  });

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);

  const handleChange = (value) => {
    setMailAddress(value);
    setMessages({ mailAddress: validateMailAddress(value) });
  }

  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  /** ユーザ招待API */
  const onInvitation = useCallback(() => {
    // 全項目をバリデート
    const newMessage = validateMailAddress(mailAddress);
    if (newMessage.length) {
      setMessages({ mailAddress: newMessage });
      setNeedFocusError(true);
      return;
    }

    dispatch(invitation({
      mailaddress: mailAddress
    }));
  }, [dispatch, mailAddress]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ?
      (<LoadingOverlay />) : null;
  }, [apiStatus]);

  useEffect(() => {
    // 画面離脱時にAPI通信状況クリア
    return () => {
      dispatch(clearApiStatus('invitation'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus === 'error') {
      dispatch(pushMessage('ユーザ招待に失敗しました'));
      dispatch(clearApiStatus('invitation'));
    }
  }, [apiStatus, dispatch]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      dispatch(pushMessage('招待メールを送信しました。'));
      // ユーザ招待が成功したらユーザー一覧画面に遷移する
      navigate('/licensee/userList');
    }
  }, [dispatch, apiStatus, navigate]);

  // 未ログイン時は対応する画面へ遷移
  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <>
      <h1 className="main_login-head">ユーザー招待</h1>
      <div className="title-border mb20 wrap">
        <p></p>
        <p className="link-pageback deco-line c-pink">
          <Link to={'/licensee/userList'}><i className="icn pageback"></i>ユーザー一覧へ戻る</Link>
        </p>
      </div>

      <div className="main_login-body wrap">
        <p className="main_login-read tac">
          下記フォームにROYSへ招待したいユーザーのメールアドレスを入力して、招待メールを送信してください。
        </p>
        <div className="main_login-content">
          <dl className="main_login-form form-set">
            <dt className="form-name">メールアドレス</dt>
            <dd className="form-body wdt500">
              <div className="input-form">
                <input type="email" placeholder="例：mail@example.com"
                 title="メールアドレスを必ず入力してください" name="mailaddress"
                 aria-label="メールアドレス" maxLength={256}
                 ref={formRefs.current.mailAddress}
                 onChange={(event) => handleChange(event.target.value) }
                 value={mailAddress}
                 required />
              </div>
              <ErrorMessageList messages={messages.mailAddress}></ErrorMessageList>
            </dd>
          </dl>

          <p className="btn bg-green">
            <button onClick={() => onInvitation()}>招待メール送信</button>
          </p>
        </div>
      </div>
      {loading}
    </>
  )
}

/**
 * 入力チェック
 * @param {string}} value
 */
function validateMailAddress(value) {
  const errors = [];

  if (isEmpty(value)) {
    return getMessage('isNotEmpty');
  }
  if (!isValidEmail(value)) {
    errors.push(getMessage('isValidEmail'));
  }
  if (!maxLength(value, 256)) {
    errors.push(getMessage('maxLength', {max: 256}));
  }
  return errors;
}
