import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Constants } from "../../../Constants";
import { clearApiStatus, clearRegisteredUserId, registerLicenseeUser, selectApiStatus, selectRegisteredUserId } from "../../../slices/aniplex/licenseeUsersSlice";
import { selectMyself } from "../../../slices/aniplex/usersSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { LicenseeUserDetailForm } from "./LicenseeUserDetailForm";

/**
 * [A]ライセンシーユーザー登録画面
 */
export const LicenseeUserRegisterPage = () => {
  const dispatch = useDispatch()
  const myself = useSelector(selectMyself);
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus);
  const registeredUserId = useSelector(selectRegisteredUserId);

  /** 通信中フラグ */
  const apiLoading = useMemo(() => {
    return apiStatus.registerLicenseeUser === 'loading';
  }, [apiStatus.registerLicenseeUser]);

  // 権限チェック
  useEffect(() => {
    if (myself == null) {
      return;
    }

    // 権限のないユーザの場合は一覧画面に飛ばす
    const hasAuthority = [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
    if (!hasAuthority) {
      navigate('/aniplex/licenseeUserList');
    }
  }, [myself, navigate]);

  // 画面離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('registerLicenseeUser'));
      dispatch(clearRegisteredUserId());
    }
  }, [dispatch]);

  /** 登録ボタン押下時のコールバック */
  const onRegister = useCallback((formData) => {
    // 登録APIの呼び出し
    dispatch(registerLicenseeUser(formData));
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus.registerLicenseeUser === 'finish') {
      dispatch(pushMessage('ユーザーを登録しました。'));
      // ユーザーが登録されたら対応する詳細画面に遷移
      navigate(`/aniplex/licenseeUserUpdate/${registeredUserId}`);
    }
  }, [apiStatus.registerLicenseeUser, dispatch, navigate, registeredUserId])

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ライセンシーユーザー新規登録</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/licenseeUserList' >
            <i className="icn pageback"></i>ライセンシーユーザー一覧へ戻る
          </Link>
        </p>
      </div>

      <LicenseeUserDetailForm
        submitBtn="ユーザー登録"
        showSubmit={true}
        onSubmit={onRegister}
        formLocked={apiLoading} />

      {
        apiLoading && <LoadingOverlay />
      }
    </div>
  );
}
