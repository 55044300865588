import { UploadedImageList } from './UploadedImageList';

/**
 * ライセンシー側・画像アップロード(一覧のみ)
 * @returns
 */
export const UploadImageShowOnly = ({ fileNoList }) => {
  return fileNoList.length > 0 ? (
    <div className="l-uploader-content mt15">
      <ul className="list-uploader-content">
        <UploadedImageList fileNoList={fileNoList} />
      </ul>
    </div>
  ) : null;
};
