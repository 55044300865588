import { pushMessage as licenseePushMessage } from "../slices/licensee/utilSlice";
import { pushMessage as aniplexPushMessage } from "../slices/aniplex/utilSlice";
import { ApiError } from "./api/errors/ApiError";
import { ApiErrorMessage } from "./message";
import { HandledError } from "./api/errors/HandledError";
import dayjs from "dayjs";

/**
 * ページの先頭へ戻る
 */
export const scrollToPageTop = () => {
  window.scrollTo(window.scrollX || 0, 0);
}

/**
 * 正規表現の特殊文字をエスケープする
 * @param {string} str 処理対象の文字列
 * @returns エスケープ後の文字列
 */
export function escapeRegExp(str) {
  // TODO: エスケープ対象はこれだけでよいか
  return str.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
}

/**
 * 指定時間(ms)待機する
 * @param {number} millisecond 待機時間
 */
export function sleep(millisecond) {
  return new Promise(res => setTimeout(res, millisecond));
}

/**
 * 内容が空(nullish, '')の場合にハイフン(-)に変換する
 * @param {*} val 判定対象の値
 * @returns {*} 空の場合はハイフン.それ以外の場合は渡された値.
 */
export const emptyToHyphen = (val) => {
  if (val == null) {
    return '-';
  }

  if (val === '') {
    return '-';
  }

  return val;
}

/**
 * 3桁カンマ編集
 * @param {number|string} num 編集対象の数値
 * @returns {string} カンマ編集後の文字列
 */
export const comma3 = (num) => {
  if (typeof num !== 'string') {
    num = String(num);
  }
  if (num === '' || isNaN(Number(num))) {
    // 数値でない場合はそのまま返す
    return num;
  }

  let hasMinus = false;
  if (num.charAt(0) === '-') {
    hasMinus = true;
    num = num.substring(1);
  }

  const arr = num.split('.');
  const [intPart, decPart] = arr;
  let result = [];
  let tmp = intPart;
  while (true) {
    if (tmp.length > 3) {
      result.unshift(tmp.slice(-3));
      tmp = tmp.slice(0, -3);
    } else {
      result.unshift(tmp);
      break;
    }
  }

  return (hasMinus ? '-' : '')
    + result.join(',')
    + (decPart !== undefined ? ('.' + decPart) : '');
}

/**
 * 配列をコピーしてソートする
 */
export const immutableSort = (array = [], func) => {
  const newer = [...array];
  newer.sort(func);
  return newer;
}

/**
 * base64エンコードされたバイナリに対して、アップロード可能な画像であるかを判定し、MIME型を返却する
 * @return {string | boolean} 画像ならMIME型(image/png等)、それ以外はfalseを返却
 */
export const getImageType = base64 => {
  if (!base64) {
    return false; // データが空
  }
  let byteString;
  try {
    byteString = atob(base64.substring(0, 8)) || '';
  } catch {
    return false; // base64エンコードが不正
  }
  // マジックナンバーの判定
  if (byteString.startsWith('\xFF\xD8')) {
    return 'image/jpeg';
  }
  if (byteString.startsWith('\x89PNG')) {
    return 'image/png';
  }
  if (byteString.startsWith('GIF87a')) {
    return 'image/gif';
  }
  if (byteString.startsWith('GIF89a')) {
    return 'image/gif';
  }
  // 上記以外
  return false;
}

/**
 * ファイルパスからファイル名部分のみを取り出す
 * @param {string} filepath ファイルパス
 * @returns {string} ファイル名
 */
export function basename(filepath) {
  return filepath.split('/').pop();
}

/**
 * ファイル名から拡張子を取得する
 * @param {string} filename ファイル名
 * @returns {string} ファイルの拡張子
 */
export function getExtension(filename) {
  const fileAr = filename.split('.');
  // 拡張子がないファイルの考慮追加
  if (fileAr.length > 1) {
    return fileAr[fileAr.length - 1];
  }
  return '' ;
}

/**
 * APIで発生したエラー内容に応じたエラーメッセージを取得する
 * @param {*} error 処理対象のエラー
 * @param {Record<string, string>} [errorMsg={}] エラーコードとエラーメッセージのマッピング
 * @returns {string|null} エラーメッセージ.表示すべきメッセージがない場合はnull.
 */
export function handleApiError(error, errorMsg = {}) {
  if (error instanceof HandledError) {
    // 処理済みのためメッセージなし
    return null;
  }

  if (!(error instanceof ApiError)) {
    return ApiErrorMessage.SystemError;
  }

  if (errorMsg[error.errorCode] != null) {
    return errorMsg[error.errorCode];
  }

  return ApiErrorMessage.SystemError;
}

/**
 * ライセンシー向けAPIで発生したエラーのハンドリング
 * 主にasyncThunkのcatch節内で使用することを想定
 * @param {*} error 処理対象のエラー
 * @param {import("@reduxjs/toolkit").ThunkDispatch} dispatch redux-thunkのディスパッチャ
 * @param {Record<string, string>} [errorMsg] エラーコードとエラーメッセージのマッピング
 * @returns {never} 必ずエラーを投げるため返り値なし
 * @throws {*} 引数で渡されたerror
 */
export function handleLicenseeApiError(error, dispatch, errorMsg = {}) {
  const msg = handleApiError(error, errorMsg);

  if (msg != null) {
    dispatch(licenseePushMessage(msg));
  }

  throw error;
}

/**
 * ANIPLEX向けAPIで発生したエラーのハンドリング
 * @param {*} error 処理対象のエラー
 * @param {import("@reduxjs/toolkit").ThunkDispatch} dispatch redux-thunkのディスパッチャ
 * @param {Record<string, string>} [errorMsg] エラーコードとエラーメッセージのマッピング
 * @returns {never} 必ずエラーを投げるため返り値なし
 * @throws {*} 引数で渡されたerror
 */
export function handleAniplexApiError(error, dispatch, errorMsg = {}) {
  const msg = handleApiError(error, errorMsg);

  if (msg != null) {
    dispatch(aniplexPushMessage(msg));
  }

  throw error;
}

/**
 * 2つのオブジェクトを再帰的に比較して値が等しいかを判定する
 * @param {*} val1 1つ目の値
 * @param {*} val2 2つ目の値
 */
export function equals(val1, val2) {
  if (typeof val1 !== typeof val2) {
    return false;
  }

  if (typeof val1 !== 'object') {
    // プリミティブの場合は単純に比較
    // TODO: functionの場合どうする？
    return val1 === val2;
  }

  // objectからnullを除外
  if (val1 === null && val2 === null) {
    return true;
  }
  if (val1 === null || val2 === null) {
    return false;
  }

  // 配列の場合
  if (Array.isArray(val1) || Array.isArray(val2)) {
    if (!Array.isArray(val1) || !Array.isArray(val2)) {
      // 片方のみ配列
      return false;
    }

    if (val1.length !== val2.length) {
      // 要素数が合わない
      return false;
    }

    // 要素を1つずつ比較
    for (let i = 0; i < val1.length; i++) {
      if (!equals(val1[i], val2[i])) {
        return false;
      }
    }
    return true;
  }

  // オブジェクトの場合
  // TODO: クラスの考慮
  const keys1 = Object.keys(val1).sort();
  const keys2 = Object.keys(val2).sort();
  if (!equals(keys1, keys2)) {
    // キーが合わない場合
    return false;
  }

  for (const key of keys1) {
    if (!equals(val1[key], val2[key])) {
      return false;
    }
  }

  return true;
}

/**
 * 第N期を"YYYY/MM-YYYY/MM"形式にフォーマットする
 * @param {string} startDate 開始日(YYYY/MM/DD)
 * @param {string} endDate 終了日(YYYY/MM/DD)
 * @returns {string} フォーマット後の文字列
 */
export function formatPeriodYM(startDate, endDate) {
  const start = dayjs(startDate, 'YYYY/MM/DD');
  const end = dayjs(endDate, 'YYYY/MM/DD');

  if (start.isSame(end, 'month')) {
    // 単月の場合はハイフンを出さない
    return start.format('YYYY/MM');
  }

  return start.format('YYYY/MM') + ' - ' + end.format('YYYY/MM');
}

/**
 * 配列内の要素を一意にした新しい配列を返す
 * @template T
 * @param {T[]} arr 処理対象の配列
 * @returns {T[]} 内容が一意の配列
 */
export const arrayUnique = (arr) => {
  return [...arr].filter((el, idx, self) =>
    self.indexOf(el) === idx);
}
