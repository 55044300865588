import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../../Constants';
import { selectCategoryMst, selectRoyaltyPatternMst, selectRypInputRequire } from '../../../slices/licensee/masterSlice';
import { SpannableTableView } from '../../common/table/SpannableTableView';
import { ProductDetail, productValidate } from '../../common/ProductDetail';
import { comma3, formatPeriodYM } from '../../../lib/util';
import { clearApiStatue, clearFileDownloadUrl, fetchDownloadUrl, pushMessage, selectApiStatus, selectFileDownloadUrl } from '../../../slices/licensee/utilSlice';
import {
  clearApiStatus as clearProposalApiStatus,
  copyProductImage,
  selectApiStatus as selectProposalApiStatus,
  selectCopiedProductImageList,
} from '../../../slices/licensee/proposalsSlice';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { useRef } from 'react';
import { parseProductExcel } from '../../../lib/productExcel';
import { createRef } from 'react';
import { getRypInputFlg, planRyAmount } from '../../../lib/royalty';
import { isEmpty } from '../../../lib/validator';

/** 登録可能な商品の最大数 */
const PRODUCTS_NUM_MAX = 150;

/**
 * ヘッダ定義固定部の前半
 * @type {TableHeader[]}
 */
const headersPre = [
  { id: 'productNo', label: '品番', style: { minWidth: '64px' } },
  { id: 'productName', label: '商品名', style: { minWidth: '110px' } },
  { id: 'character', label: 'キャラクター', style: { minWidth: '100px' } },
  { id: 'rypId', label: 'ロイヤリティ\n報告パターン', style: { minWidth: '120px' } },
  { id: 'priceCost', label: '上代（税抜き）\n・製造原価', style: { minWidth: '100px' } },
];
/**
 * ヘッダ定義固定部の後半
 * @type {TableHeader[]}
 */
const headersPost = [
  {
    id: 'periodTotal', label: '期間合計', className: 'bg-gray', children: [
      { id: 'planNumTotal', label: '数量', style: { minWidth: '87px' } },
      { id: 'planPriceTotal', label: '金額', style: { minWidth: '87px' } },
    ]
  },
  {
    id: 'royalty', label: 'ロイヤリティ', className: 'bg-gray', children: [
      { id: 'ryRate', label: 'ロイヤリティ\n料率', style: { minWidth: '100px' } },
      { id: 'ryPrice', label: 'ロイヤリティ\n単価', style: { minWidth: '100px' } },
      { id: 'planRyAmount', label: 'ロイヤリティ\n額', style: { minWidth: '105px' } },
    ],
  },
];

/**
 * 企画書申請画面の許諾商品一覧フォーム
 * @param {object} props
 * @param {Period[]} props.periodList 第N期のリスト
 * @param {Product[]} props.productList 許諾商品リスト
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {React.MutableRefObject} props.formRefs フォーム項目へのref
 * @param {boolean} props.isEditable 編集可否フラグ
 * @param {OnChange} props.onChange 許諾商品変更時のハンドラ
 * @param {OnValidated} props.onValidated バリデート実行イベントのハンドラ
 * @param {boolean} [props.validateAllFlg] フォーム全体の強制バリデートフラグ
 * @param {Record<string, any>} props.validateErrors バリデートエラー
 * @returns
 */
export const ProposalProductsForm = ({
  periodList,
  productList,
  formLocked,
  formRefs,
  isEditable,
  onChange,
  onValidated,
  validateAllFlg,
  validateErrors
}) => {
  const dispatch = useDispatch();
  const reqRyp = useSelector(selectRypInputRequire);
  const proposalApiStatus = useSelector(selectProposalApiStatus);
  const copiedProductImage = useSelector(selectCopiedProductImageList);
  const utilApiStatus = useSelector(selectApiStatus);
  const fileDownloadUrl = useSelector(selectFileDownloadUrl);
  /** カテゴリマスタ */
  const categoryMst = useSelector(selectCategoryMst);
  /** ロイヤリティ報告パターンマスタ */
  const ryPtnMst = useSelector(selectRoyaltyPatternMst);
  const reqRypFunc = useSelector(selectRypInputRequire);
  /** 選択状態のレコード */
  const [selectedIndexes, setSelectedIndexes] = useState(/** @type {number[]} */ ([]));
  /** レコードの選択状態変更時のコールバック */
  const onCheck = useCallback((idx, checked) => {
    if (checked) {
      // インデックスを追加してユニークにする
      setSelectedIndexes(
        [...selectedIndexes, idx].filter((el, i, self) => self.indexOf(el) === i)
      );
    } else {
      setSelectedIndexes(
        selectedIndexes.filter(el => el !== idx)
      );
    }
  }, [selectedIndexes]);
  /** 商品ポップアップ関連 */
  const [productPopup, setProductPopup] = useState({
    /** @type {boolean} ポップアップ表示フラグ */
    showFlg: false,
    /** @type {number|null} 編集対象商品の位置 */
    targetIndex: null,
    /**
     * ポップアップが閉じたときのコールバック
     * @type {null|(btn: 'submit'|'close', data: import('./ProposalDetailForm').Product) => void}
     */
    onClose: null,
    product: null
  });
  // 商品のエラー
  const [productErrors, setProductErrors] = useState([]);
  // エラーメッセージ
  const [messages, setMessages] = useState({
    /** 商品リスト */
    products: [],
  });
  // 商品Excelアップロードのref
  const excelUploadRef = useRef(/** @type {React.MutableRefObject[]} */([]));
  useEffect(() => {
    for (let i = 0; i < 3; i++) {
      excelUploadRef.current[i] = createRef();
    }
  }, []);

  /** 契約期間に関するエラー */
  const contractErrors = useMemo(() => {
    const props = [];
    if (validateErrors.contractStartDate?.apply.length) {
      props.push('契約開始日');
    }
    if (validateErrors.contractEndDate?.apply.length) {
      props.push('契約終了日');
    }
    if (validateErrors.closingMonth?.apply.length) {
      props.push('ロイヤリティ報告締め月');
    }

    if (props.length) {
      return [`商品を追加・変更するには${props.join('、')}を修正してください`];
    }
    return [];
  }, [validateErrors]);
  useEffect(() => {
    if (validateAllFlg) {
      // 強制バリデートフラグが立っている時は件数のバリデートを行う
      const errors = [];
      if (productList.length === 0) {
        errors.push('商品を設定してください');
      }
      setMessages(prev => ({
        ...prev,
        products: errors,
      }));
      onValidated('products', {
        tmp: [],
        apply: errors,
      });
    }
  }, [onValidated, productList.length, validateAllFlg]);

  useEffect(() => {
    // 商品リストのバリデート
    const productErrorList = productList.map(p => {
      return productValidate(p, reqRypFunc);
    });
    setProductErrors(productErrorList);
    onValidated('productList', productErrorList.reduce((prev, cur) => ({
      tmp: [...prev.tmp, ...Object.values(cur.tmp)],
      apply: [...prev.apply, ...Object.values(cur.apply)],
    }), { tmp: [], apply: [] }));
  }, [onValidated, productList, reqRypFunc])

  /** 商品リンク押下時のハンドラ */
  const onProductClick = useCallback((idx) => {
    setProductPopup({
      showFlg: true,
      product: productList[idx] ?? null,
      onClose: (btn, data) => {
        if (btn === 'submit') {
          onChange([{ product: data, idx }]);
        }
        setProductPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [onChange, productList]);

  /** テーブルのヘッダ定義 */
  const headers = useTableHeaders({
    periodList,
    productList,
    isEditable,
    selectedIndexes,
    setSelectedIndexes,
  });

  /**
   * クリップボードにコピーボタン押下時のハンドラ
   * @type {(product: Product) => Promise<void>}
   */
  const onClipboardCopy = useCallback(async product => {
    await copyClipboard(product, categoryMst, ryPtnMst);
  }, [categoryMst, ryPtnMst]);

  /**
   * テーブル表示のボディ部のデータ
   * @type {TableRecord[]}
   */
  const records = useMemo(() => {
    const data = convertProductToRecord(productList, periodList);
    /** @type {TableRecord[]} */
    const result = [];
    data.forEach((d, idx) => {
      // 金額・数量のセルにクラス'cost'を付与しカンマ編集をかける
      const costCols = ['priceCost', 'ryRate', 'ryPrice', 'planRyAmount'];
      const costProps = Object.fromEntries(
        Object.entries(d)
          .filter(([key, _]) => (
            d.hasOwnProperty(key) && (
              key.startsWith('planNum') || key.startsWith('planPrice') || costCols.indexOf(key) >= 0
            )
          ))
          .map(([key, value]) => [key, { el: comma3(value), className: 'cost' }])
      );
      const selected = selectedIndexes.indexOf(idx) >= 0;
      result.push({
        _tr: { className: idx % 2 === 0 ? 'odd' : 'even' },
        ...d,
        ...costProps,
        checkbox: <RecordCheckbox id={idx} onChange={onCheck} checked={selected} disabled={proposalApiStatus.copyProductImage !== null} />,
        productName: {
          el: (contractErrors.length ?
            <>{d.productName}</> :
            (
              <div className="copy-link-wrapper">
                <button className="link product-link"
                  onClick={() => onProductClick(idx)}
                >{d.productName}</button>
                <button
                  className="link"
                  style={{ marginLeft: '10px' }}
                  title="クリップボードにコピー"
                  onClick={() => onClipboardCopy(productList[idx])}>
                  <i className='icn no-margin siteopen blue'></i>
                </button>
              </div>
            )
          ),
          className: 'text-wrap',
          style: {
            maxWidth: '20vw',
          },
        },
        character: {
          el: d.character,
          className: 'text-wrap',
          style: {
            maxWidth: '20vw',
          },
        },
        rypId: ryPtnMst.find(m => m.rypId === d.rypId)?.rypName ?? '-',
      });
      if (isEditable && contractErrors.length === 0 && Object.values(productErrors[idx]?.apply ?? []).length > 0) {
        const colNum = headersPre.length + (periodList.length + 1) * 2
          + headersPost.reduce((prev, cur) => prev + cur.children.length, 0)
        result.push({
          _tr: { className: (idx % 2 !== 0 ? 'odd' : 'even') + ' error' },
          checkbox: '└',
          productNo: {
            el: (
                <span className='c-pink'>
                  商品の入力内容にエラーがあります。商品名をクリックして詳細を表示して修正してください。
                </span>
              ),
            colSpan: 3,
            style: { borderLeft: 'none' },
          },
          rypId: {
            el: (<p></p>),
            colSpan: colNum - 2,
            style: { borderLeft: 'none' },
          }
        });
      }
    });

    // 合計行を追加
    const blankCellNum = (isEditable ? 1 : 0)
      + headersPre.length + (periodList.length + 1) * 2;
    const leadingColId = isEditable ? 'checkbox' : 'productNo';
    result.push({
      _tr: { className: 'total' },
      [leadingColId]: { el: '', colSpan: blankCellNum, style: { borderBottom: 'none' } },
      ryRate: { el: <TotalCell productList={productList} />, className: 'total-body bb-solid-tblcolor', colSpan: 3 }
    });

    return result;
  }, [contractErrors.length, isEditable, onCheck, onClipboardCopy, onProductClick, periodList, productErrors, productList, proposalApiStatus.copyProductImage, ryPtnMst, selectedIndexes]);

    /** 商品追加ボタン押下時のハンドラ */
  const onAddClick = useCallback(() => {
    if (productList?.length >= PRODUCTS_NUM_MAX) {
      dispatch(pushMessage(`登録可能な商品は${PRODUCTS_NUM_MAX}件までです。`));
      return;
    }

    setProductPopup({
      showFlg: true,
      onClose: (btn, data) => {
        if (btn === 'submit') {
          onChange([{product: data, idx: null}]);
        }
        setProductPopup({
          showFlg: false,
          onClose: null,
        })
      }
    });
  }, [dispatch, onChange, productList?.length]);

  /** Excelデータ選択時のハンドラ */
  const onExcelUpload = useCallback(async (files) => {
    const file = files[0];

    // ファイルを取得したらinputの内容をクリア
    excelUploadRef.current.forEach(ref => {
      if (ref.current) {
        ref.current.value = '';
      }
    });

    let records
    try {
      records = await parseProductExcel(file);
    } catch (e) {
      dispatch(pushMessage(e?.message ?? 'ファイルの読み込みに失敗しました。'));
      return;
    }

    if (records.length === 0) {
      // データが空のときは処理しない
      return;
    }

    if (productList.length + records.length > PRODUCTS_NUM_MAX) {
      dispatch(pushMessage(`登録可能な商品は${PRODUCTS_NUM_MAX}件までです。`));
      return;
    }

    const products = formatExcelData(records, periodList);

    let hasError = false;
    products.forEach(prd => {
      const errors = productValidate(prd, reqRyp);
      hasError ||= Object.values(errors?.apply ?? {}).some(val => (val ?? []).length);
    });

    if (hasError) {
      dispatch(pushMessage('Excelに不正データが含まれています。Excelのデータチェックボタンを実行し、登録する商品情報を再確認してください。'));
      return;
    }

    const changeInfo = products.map(product => ({
      product,
      idx: null,
    }));
    onChange(changeInfo);
  }, [dispatch, onChange, periodList, productList.length, reqRyp]);

  /** フォーマットダウンロードボタン押下時のハンドラ */
  const onFormatDownload = useCallback(() => {
    if (utilApiStatus.fetchDownloadUrl === 'loading') {
      // ダウンロードAPI通信中は処理しない
      return;
    }
    // ファイルダウンロードURL取得要求
    dispatch(fetchDownloadUrl({ filetype: 'productFormat' }));
  }, [dispatch, utilApiStatus.fetchDownloadUrl]);
  // フォーマットダウンロード処理のハンドリング
  useEffect(() => {
    if (utilApiStatus.fetchDownloadUrl === 'finish' && fileDownloadUrl) {
      // ダウンロードURLを取得出来たらAPIステータスをクリアしてそのURLを開く
      dispatch(clearApiStatue('fetchDownloadUrl'));
      window.location.href = fileDownloadUrl;
      dispatch(clearFileDownloadUrl());
      return;
    }
    if (utilApiStatus.fetchDownloadUrl === 'error') {
      // メッセージを表示してAPIステータスをクリア
      dispatch(pushMessage('ファイルのダウンロードに失敗しました。'));
      dispatch(clearApiStatue('fetchDownloadUrl'));
      return;
    }
  }, [dispatch, fileDownloadUrl, utilApiStatus.fetchDownloadUrl]);

  /** 商品複製処理 */
  const copyProduct = useCallback(() => {
      /** @type {OnChangeInfo[]} */
      const changeInfo = selectedIndexes.map(idx => {
        const original = productList[idx];
        const newPeriodList = (original.periodList ?? []).map(i => ({...i}));
        const newRenderingImageList = (original.renderingImageList ?? []).map(img => {
          const copyInfo = copiedProductImage.find(a => String(a.renderingImageNoBefore) === String(img.renderingImageNo));
          return {
            renderingImageNo: copyInfo?.renderingImageNoAfter ?? null,
          };
        }).filter(img => img.renderingImageNo != null);
        const newItem = {
          ...original,
          periodList: newPeriodList,
          renderingImageList: newRenderingImageList,
          // 商品コード、品番、ステータスはクリア
          productId: null,
          productNo: null,
          productStatus: null,
        }
        return { product: newItem, idx: null };
      });
      onChange(changeInfo);
      setSelectedIndexes([]);
  }, [copiedProductImage, onChange, productList, selectedIndexes]);

  /** 複製ボタン押下時のハンドラ */
  const onCopyClick = useCallback(() => {
    if (selectedIndexes.length === 0) {
      dispatch(pushMessage('商品を選択してください。'));
      return;
    }

    if (productList?.length + selectedIndexes.length > PRODUCTS_NUM_MAX) {
      dispatch(pushMessage(`登録可能な商品は${PRODUCTS_NUM_MAX}件までです。`));
      return;
    }

    if (proposalApiStatus.copyProductImage === 'loading') {
      // API通信中は処理しない
      return;
    }

    const imageNoList = selectedIndexes.map(idx => productList[idx])
      .flatMap(product => product.renderingImageList)
      .map(image => image.renderingImageNo);

    if (imageNoList.length === 0) {
      // 商品イメージが1件もない場合はそのまま複製処理を行う
      copyProduct();
      return;
    }

    // 商品イメージが存在する場合はイメージ複製APIを呼び出す
    dispatch(copyProductImage(imageNoList));
  }, [copyProduct, dispatch, productList, proposalApiStatus.copyProductImage, selectedIndexes])
  // 商品イメージ複製処理のハンドリング
  useEffect(() => {
    if (proposalApiStatus.copyProductImage === 'error') {
      dispatch(pushMessage('システムエラーが発生したため、商品の複製を中止しました。商品の複製を再実行してください。再実行してもエラーになる場合はお問い合わせください。'));
      dispatch(clearProposalApiStatus('copyProductImage'));
      return;
    }

    if (proposalApiStatus.copyProductImage === 'finish') {
      // コピー後のファイルNoを取得出来たら商品情報の複製を行う
      copyProduct();
      dispatch(clearProposalApiStatus('copyProductImage'));
      return;
    }
  }, [copyProduct, dispatch, proposalApiStatus.copyProductImage])

  /** 削除ボタン押下時のハンドラ */
  const onDeleteClick = useCallback(() => {
    if (selectedIndexes.length === 0) {
      dispatch(pushMessage('商品を選択してください。'));
      return;
    }

    const cannotDelete = selectedIndexes.map(idx => productList[idx])
      .filter(p => p.productStatus != null && p.productStatus !== Constants.Licensee.productStatus.Registered)
    if (cannotDelete.length > 0) {
      dispatch(pushMessage('削除できない商品が選択されています。'));
      return;
    }

    /** @type {OnChangeInfo[]} */
    const changeInfo = selectedIndexes.map(idx => ({
      product: null,
      idx,
    }));
    onChange(changeInfo);
    setSelectedIndexes([]);
  }, [dispatch, onChange, productList, selectedIndexes]);

  /** 商品が存在しない場合のエリア */
  const noItemArea = (
    <>
      {
        isEditable ? (
          <>
            <ErrorMessageList messages={messages.products} />
            <ErrorMessageList messages={contractErrors} />
            <div className="l-buttons">
              <p className="btn bg-pink" style={{ width: '160px' }}>
                <button
                  disabled={formLocked || contractErrors.length > 0}
                  onClick={onAddClick}
                ><i className="icn plus"></i>商品を追加</button>
              </p>
              <p className="btn bg-pink label" style={{ width: '200px' }}>
                <label className={contractErrors.length ? 'disabled' : ''}>
                  <i className="icn upload"></i>商品情報アップロード
                  <input type="file"
                    ref={excelUploadRef.current[0]}
                    disabled={formLocked || contractErrors.length > 0}
                    onChange={e => onExcelUpload(e.target.files)} />
                </label>
              </p>
              <p className="btn bg-pink" style={{ width: '210px' }}>
                <button onClick={onFormatDownload}><i className="icn download"></i>フォーマットダウンロード</button>
              </p>
            </div>
          </>
        ) : null
      }

      <p className="c-pink mt15">商品を追加する前に契約開始日、契約終了日、ロイヤリティ報告締め月をご確認ください。</p>
      <p className="c-pink">商品登録後に契約開始日、契約終了日、ロイヤリティ報告締め月を変更すると、商品の予定生産数、予定販売数情報がクリアされます。</p>
    </>
  )

  /** テーブル表示エリア */
  const tableArea = (
    <>
      {
        isEditable ? (
          <>
            <ErrorMessageList messages={contractErrors} />
            <div className="l-buttons">
              <p className="btn bg-pink" style={{ width: '160px' }}>
                <button type="button"
                  disabled={formLocked || contractErrors.length}
                  onClick={onAddClick}
                ><i className="icn plus"></i>商品を追加</button>
              </p>

              <p className="btn bg-pink label" style={{ width: '200px' }}>
                <label className={contractErrors.length ? 'disabled' : ''}>
                  <i className="icn upload"></i>商品情報アップロード
                  <input type="file"
                    ref={excelUploadRef.current[1]}
                    disabled={formLocked || contractErrors.length}
                    onChange={e => onExcelUpload(e.target.files)} />
                </label>
              </p>

              <p className="btn bg-pink" style={{ width: '210px' }}>
                <button onClick={onFormatDownload}><i className="icn download"></i>フォーマットダウンロード</button>
              </p>

              <p className="btn bg-pink" style={{ width: '220px' }}>
                <button type="button"
                  disabled={formLocked || contractErrors.length || proposalApiStatus.copyProductImage !== null}
                  onClick={onCopyClick}
                ><i className="icn siteopen"></i>チェックした商品を複製</button>
              </p>

              <p className="btn c-pink" style={{ width: '220px' }}>
                <button type="button"
                  disabled={formLocked || contractErrors.length}
                  onClick={onDeleteClick}
                ><i className="icn cross"></i>チェックした商品を削除</button>
              </p>
            </div>
          </>
        ) : null
      }

      <SpannableTableView
        className='border0 mt15 has-total-row scroll header-2line'
        tableRef={formRefs.current.productList}
        headers={headers}
        records={records}
        scrollable={true}
        fixedCols={isEditable ? 4 : 3}
        ignoreFixRows={-1} />

      {
        isEditable ? (
          <div className="l-buttons" style={{ marginTop: '10px' }}>
            <p className="btn bg-pink" style={{ width: '160px' }}>
              <button type="button"
                disabled={formLocked || contractErrors.length}
                onClick={onAddClick}
              ><i className="icn plus"></i>商品を追加</button>
            </p>

            <p className="btn bg-pink label" style={{ width: '200px' }}>
              <label className={contractErrors.length ? 'disabled' : ''}>
                <i className="icn upload"></i>商品情報アップロード
                <input type="file"
                  ref={excelUploadRef.current[2]}
                  disabled={formLocked || contractErrors.length}
                  onChange={e => onExcelUpload(e.target.files)} />
              </label>
            </p>

            <p className="btn bg-pink" style={{ width: '210px' }}>
              <button onClick={onFormatDownload}><i className="icn download"></i>フォーマットダウンロード</button>
            </p>

            <p className="btn bg-pink" style={{ width: '220px' }}>
              <button type="button"
                disabled={formLocked || contractErrors.length || proposalApiStatus.copyProductImage !== null}
                onClick={onCopyClick}
              ><i className="icn siteopen"></i>チェックした商品を複製</button>
            </p>

            <p className="btn c-pink" style={{ width: '220px' }}>
              <button type="button"
                disabled={formLocked || contractErrors.length}
                onClick={onDeleteClick}
              ><i className="icn cross"></i>チェックした商品を削除</button>
            </p>
          </div>
        ) : null
      }
    </>
  )

  return (
    <section className="mt40">
      <div className="title-pink">
        <h2 className="title"><i className="icn box"></i>許諾申請内容</h2>
      </div>
      {
        productList.length ?
          tableArea :
          noItemArea
      }
      {
        productPopup.showFlg ?
          <ProductDetail
          periodList={periodList}
          product={productPopup.product}
          onClose={productPopup.onClose}
          isEditable={isEditable}
          /> : null
      }
    </section>
  );
}

/**
 * 許諾商品のレコードに表示するチェックボックス
 * @param {object} props
 * @param {string} props.id レコードのID
 * @returns
 */
const RecordCheckbox = ({ id, onChange, checked }) => {
  return (
    <fieldset>
      <input type="checkbox"
        id={`check-royalty-${id}`}
        checked={checked}
        onChange={ev => onChange(id, ev.target.checked)} />
      <label htmlFor={`check-royalty-${id}`}
        className="form-checkbox02"
      >チェック</label>
    </fieldset>
  )
}

/**
 * 合計金額表示のセル
 * @param {object} props
 * @param {import('./ProposalDetailForm').Product[]} props.productList 商品リスト
 * @returns
 */
const TotalCell = ({ productList }) => {
  const total = useMemo(() => {
    return productList.reduce((prev, cur) => {
      return prev + (cur.planRyAmount ?? 0);
    }, 0)
  }, [productList]);

  return (
    <dl>
      <dt>合計</dt>
      <dd>{comma3(total)}</dd>
    </dl>
  )
}

/**
 * テーブルのヘッダ定義
 * @param {object} props
 * @param {Period[]} props.periodList 期間のリスト
 * @param {boolean} props.isEditable 編集可能フラグ
 * @param {Product[]} props.productList 商品リスト
 * @param {number[]} props.selectedIndexes 選択中レコードのインデックスのリスト
 * @param {React.Dispatch<React.SetStateAction<number[]>>} props.setSelectedIndexes 選択中レコードインデックスリストの設定処理
 */
const useTableHeaders = ({
  periodList,
  isEditable,
  productList,
  selectedIndexes,
  setSelectedIndexes,
}) => {
  /** 全商品選択済みフラグ */
  const allSelected = useMemo(() =>
    selectedIndexes.length === productList.length
  , [productList.length, selectedIndexes.length])

  /** 全選択チェック変更時 */
  const onAllCheck = useCallback(
    /** @param {boolean} checked */
    checked => {
      if (checked) {
        setSelectedIndexes((new Array(productList.length).fill(null).map((_, idx) => idx)));
      } else {
        setSelectedIndexes([]);
      }
    }
  , [productList.length, setSelectedIndexes]);

  return useMemo(() => {
    const periodHeaders = getPeriodHeaders(periodList);

    const result = [
      ...headersPre,
      ...periodHeaders,
      ...headersPost,
    ];
    if (isEditable) {
      // 編集可能な場合はチェックボックスを表示する
      result.unshift({
        id: 'checkbox',
        label: (
          <>
            <fieldset>
              <input
                type="checkbox"
                id="check-product-all"
                checked={allSelected}
                onChange={ev => onAllCheck(ev.target.checked)} />
              <label htmlFor="check-product-all" className='form-checkbox02'>チェック</label>
            </fieldset>
          </>
        ),
        style: { minWidth: '32px' }
      });
    }
    return result;
  }, [allSelected, isEditable, onAllCheck, periodList]);
}

/**
 * 第N期の部分のテーブルのヘッダ定義を取得する
 * @param {Period[]} periodList 第N期のリスト
 * @returns {import('../../common/table/SpannableTableView').Header[]} 第N期部分のヘッダ定義
 */
function getPeriodHeaders(periodList) {
  return periodList.map((p, i) => {
    const ym = formatPeriodYM(p.ryStartDate, p.ryEndDate);

    return {
      id: `period${p.period}`,
      label: `第${p.period}期\n${ym}`,
      className: 'bg-gray',
      children: [
        {
          id: `planNum${p.period}`,
          label: '数量',
          style: { minWidth: '87px' },
          className: i === 0 ? 'border-left' : undefined
        },
        {
          id: `planPrice${p.period}`,
          label: '金額',
          style: { minWidth: '87px' }
        },
      ]
    }
  });
}

/**
 * 商品リストをテーブル表示用のデータに変換する
 * @param {import('./ProposalDetailForm').Product[]} productList 商品リスト
 * @param {import('./ProposalDetailForm').Period[]} periodList 第N期のリスト
 * @returns {Record<string, string>[]} テーブル表示用のデータ
 */
function convertProductToRecord(productList, periodList) {
  return productList.map((p) => {
    const rypInputFlg = getRypInputFlg(p.rypId, 'licensee');

    const result = {
      productNo: p.productNo ?? '-',
      productName: p.productName ?? '-',
      character: p.character ?? '-',
      rypId: p.rypId ?? '-',
      priceCost: getPriceCost(p),
      ryRate: p.ryRate ?? '-',
      ryPrice: rypInputFlg.ryPrice ? p.ryPrice : '-',
      planRyAmount: p.planRyAmount ?? 0,
    };

    const HUpBN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });

    let planNumTotal = 0
    let planPriceTotal = HUpBN(0);

    periodList.forEach(pr => {
      const planNum = getPeriodPlanNum(p, pr.period);
      const planPrice = getPeriodPlanPrice(p, pr.period);
      result[`planNum${pr.period}`] = planNum;
      result[`planPrice${pr.period}`] = planPrice;
      planNumTotal += planNum === '-' ? 0 : planNum;
      planPriceTotal = planPriceTotal.plus(HUpBN(planPrice === '-' ? 0 : planPrice));
    });

    if (Array.isArray(p.periodList)) {
      result.planNumTotal = planNumTotal;
      result.planPriceTotal = planPriceTotal.toNumber();
    } else {
      result.planNumTotal = '-';
      result.planPriceTotal = '-';
    }

    return result;
  });
}

/**
 * 商品情報から上代・製造原価の表示内容を取得する
 * @param {import('./ProposalDetailForm').Product} product 商品情報
 * @returns {string} 上代・製造原価の表示内容
 */
function getPriceCost(product) {
  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 上代
      return product.planPrice;
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 上代
      return product.planPrice;
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 製造原価
      return product.planCost;
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から第N期の数量の表示内容を取得する
 * @param {import('./ProposalDetailForm').Product} product 商品情報
 * @param {number} period 対象の期数
 * @returns {string} 表示内容
 */
function getPeriodPlanNum(product, period) {
  const targetPeriod = product.periodList?.find(p => p.period === period);

  if (!targetPeriod) {
    // 対象の期が見つからなかった場合は非表示
    return '-';
  }

  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      // 販売数
      return targetPeriod.planSales ?? '-';
    // 製造原価×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.CostRateProductNum:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 生産数×ロイヤリティ単価
    case Constants.RoyaltyPattern.ProductNumUniPrice:
      // 生産数
      return targetPeriod.planProduction ?? '-';
    // 販売数×ロイヤリティ単価
    case Constants.RoyaltyPattern.SalesNumUnitPrice:
      // 販売数
      return targetPeriod.planSales ?? '-';
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * 商品情報から第N期の金額の表示内容を取得する
 * @param {import('./ProposalDetailForm').Product} product 商品情報
 * @param {number} period 対象の期数
 * @returns {string} 表示内容
 */
function getPeriodPlanPrice(product, period) {
  const targetPeriod = product.periodList?.find(p => p.period === period);

  if (!targetPeriod) {
    // 対象の期が見つからなかった場合は非表示
    return '-';
  }

  const HUpBN = BigNumber.clone({
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  });

  switch (product.rypId) {
    // 上代×ロイヤリティ料率×生産数
    case Constants.RoyaltyPattern.PriceRateProductNum:
      if (targetPeriod.planProduction == null) {
        return '-';
      }
      // 上代×生産数
      return HUpBN(product.planPrice).times(HUpBN(targetPeriod.planProduction)).dp(2).toNumber();
    // 上代×ロイヤリティ料率×販売数
    case Constants.RoyaltyPattern.PriceRateSalesNum:
      if (targetPeriod.planSales == null) {
        return '-';
      }
      // 上代×販売数
      return HUpBN(product.planPrice).times(HUpBN(targetPeriod.planSales)).dp(2).toNumber();
    case Constants.RoyaltyPattern.CostRateProductNum:
      if (targetPeriod.planProduction == null) {
        return '-';
      }
      // 製造原価×生産数
      return HUpBN(product.planCost).times(HUpBN(targetPeriod.planProduction)).dp(2).toNumber();
    default:
      // 上記以外は非表示
      return '-';
  }
}

/**
 * Excelから取り込んだデータを整形する
 * @param {*} records excelから取り込んだ商品情報
 * @param {*} periodList 企画のN期の情報
 * @returns {import('../../../slices/licensee/proposalsSlice').ProposalProduct[]} 整形後の商品情報
 */
function formatExcelData(records, periodList) {
  return records
    .map(record => {
      record.periodList = record.periodList.filter(pr => periodList.findIndex(p => p.period === pr.period) >= 0);
      const inputFlg = getRypInputFlg(record.rypId, 'licensee');
      // 空欄を0埋めする
      record.periodList.forEach(pr => {
        if (inputFlg.production && isEmpty(pr.planProduction)) {
          pr.planProduction = 0;
        }
        if (inputFlg.sales && isEmpty(pr.planSales)) {
          pr.planSales = 0;
        }
      });

      if (record.periodList.length < periodList.length) {
        // 期数がExcelフォーマット分以上ある場合は0埋めする
        const periodMax = periodList.reduce((prev, cur) => Math.max(prev, cur.period), 0);
        const tmpMax = record.periodList.reduce((prev, cur) => Math.max(prev, cur.period), 0);
        for (let cur = tmpMax + 1; cur <= periodMax; cur++) {
          record.periodList.push({
            period: cur,
            planProduction: inputFlg.production ? 0 : null,
            planSales: inputFlg.sales ? 0 : null,
          });
        }
      }
      // ロイヤリティ額を算出（直接入力以外）
      if (record.rypId !== Constants.RoyaltyPattern.FixedPrice) {
        record.planRyAmount = planRyAmount(record)
      }
      // ロイヤリティ額を整数(小数一位四捨五入)に変換
      record.planRyAmount = BigNumber(record.planRyAmount).dp(0, BigNumber.ROUND_HALF_UP).toNumber();

      // 商品イメージは登録しないので空
      record.renderingImageList = [];

      return record;
    });
}

/**
 * 商品情報をクリップボードにコピーする
 * @param {import('../../../slices/licensee/proposalsSlice').ProposalProduct} product 商品情報
 * @param {import('../../../lib/api/licensee').CategoryMst[]} categoryMst カテゴリマスタ情報
 * @param {import('../../../slices/licensee/masterSlice').RoyaltyPatternMst[]} rypMst ロイヤリティパターンマスタ情報
 */
async function copyClipboard(product, categoryMst, rypMst) {
  /** 文字列型のプロパティ */
  const stringProps = [
    'productName', 'categoryNo', 'categoryDetailNo',
    'character', 'version', 'launchDate', 'rypId',
    'salesMethod', 'salesMethod', 'material', 'characterLineup',
    'productRemarks', 'productOption1', 'productOption2', 'productOption3',
  ];
  /** 書き込むプロパティの順番 */
  const propOrder = [
    'productName', 'categoryNo', 'categoryDetailNo',
    'character', 'version', 'launchDate', 'rypId',
    'planPrice', 'planCost', 'planProceeds', 'ryRate',
    'ryPrice', 'planRyAmount', 'salesMethod', 'productMethod',
    'material', 'characterLineup', 'productRemarks',
    'productOption1', 'productOption2', 'productOption3',
  ];
  /** N期のプロパティの順番 */
  const periodPropOrder = [
    'planProduction', 'planSales',
  ];
  /** N期の最大数 */
  const maxPeriod = 12;

  const category = categoryMst.find(cat => String(cat.categoryNo) === String(product.categoryNo));
  const categoryDetail = category?.categoryDetailList.find(cat => String(cat.categoryDetailNo) === String(product.categoryDetailNo));
  const ryPtn = rypMst.find(ptn => String(ptn.rypId) === String(product.rypId));

  // 販売方式
  const salesMethodName = (() => {
    switch(String(product.salesMethod)) {
      case '1':
        return '個別商品';
      case '2':
        return 'トレーディング商品';
      default:
        return '';
    }
  })();

  // 生産方式
  const productMethodName = (() => {
    switch(String(product.productMethod)) {
      case '1':
        return '見込み生産';
      case '2':
        return '受注生産';
      default:
        return '';
    }
  })();

  const record = [];
  for (const prop of propOrder) {
    let val
    switch (prop) {
      case 'categoryNo':
        val = category?.categoryName;
        break;
      case 'categoryDetailNo':
        val = categoryDetail?.categoryDetailName;
        break;
      case 'rypId':
        val = ryPtn?.rypName;
        break;
      case 'salesMethod':
        val = salesMethodName;
        break;
      case 'productMethod':
        val = productMethodName;
        break;
      case 'planRyAmount':
        // ロイヤリティ金額は直接入力の場合のみ入れる
        if (product.rypId === Constants.RoyaltyPattern.FixedPrice) {
          val = product.planRyAmount;
        } else {
          val = '';
        }
        break;
      default:
        val = product[prop];
    }

    if (stringProps.includes(prop) && val != null) {
      val = '"' + val.replace(/"/g, '""') + '"';
    }

    record.push(val ?? '');
  }

  for (const period of product.periodList) {
    if (period.period > maxPeriod) {
      // 指定数以上の期は切り捨てる
      break;
    }

    for (const prop of periodPropOrder) {
      record.push(period[prop] ?? '');
    }
  }
  const copyText = record.join('\t');

  await navigator.clipboard.writeText(copyText);
}

//#region typedef
/**
 * @callback OnChange 許諾商品リスト変更時のハンドラ
 * @param {OnChangeInfo[]} data 変更内容の情報
 */
/**
 * @typedef {object} OnChangeInfo 許諾商品リスト変更時ハンドラに引き渡す更新情報
 * @property {Product|null} product 更新内容.削除の場合はnull
 * @property {number|null} idx 更新位置.新規の場合はnull
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader テーブル表示のヘッダ定義
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord
 */
/**
 * @typedef {import('./ProposalDetailForm').Product} Product 商品情報
 */
/**
 * @typedef {import('./ProposalDetailForm').Period} Period
 */
/**
 * @callback OnValidated バリデート実行イベントのハンドラ
 * @param {keyof FormData} prop バリデート対象のプロパティ
 * @param {{ tmp: string[], apply: string[] }} errors エラーメッセージのリスト
 */
//#endregion typedef
