import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLabelsAll, getLabel, postLabel, patchLabel } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";


/**
 * 初期値
 */
const initialState = {
  fetchAll: null,
  /** @type {number|null} 申請された証紙申請の証紙内部コード */
  appliedLabelId: null,
  /** @type {labelDetail|null} 取得した証紙詳細情報 */
  labelDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** 証紙申請一覧 */
    fetchAll: null,
    /** 証紙申請処理 */
    applyLabel: null,
    /** 証紙申請削除処理 */
    deleteLabel: null,
    /** 企画詳細取得処理 */
    fetchLabelDetail: null
  },
}

/**
 * 証紙申請処理
 */
export const applyLabel = createAsyncThunk(
  'licensee/labels/applyLabels',
  /**
   * 企画申請処理
   * @param {PostLabelParam} data 申請する証紙のデータ
   * @returns
   */
  async (data, { dispatch }) => {
    try {
      const result = await postLabel(data);
      return result.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21601': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21602': ApiErrorMessage.UpdateStateMismatch,
        // メッセージ登録失敗
        '21603': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 証紙申請一覧取得処理
 */
export const fetchAll = createAsyncThunk(
  'licensee/labels/fetchAll',
  /**
   * @param {GetLabelsParam} params
   */
  async (params, { dispatch }) => {
    try {
      const data = await getLabelsAll(params);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch);
    }
  }
)

/**
 * 証紙詳細取得処理
 */
export const fetchLabelDetail = createAsyncThunk(
  'licensee/labels/fetchLabelDetail',
  /**
   * 証紙詳細取得処理
   * @param {number} proposalId 証紙内部コード
   * @returns
   */
  async (labelApplicationId, { dispatch }) => {
    try {
      const data = await getLabel(labelApplicationId);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21701': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 証紙申請更新API
 */
export const updateLabel = createAsyncThunk(
  'licensee/labels/patchLabel',
  async (label, { dispatch }) => {
    const data = {
      labelStatus: 'DEL',
      updateDatetime: label.updateDatetime
    }
    try {
      const result = await patchLabel(label.labelApplicationId, data);
      return result.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21801': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21802': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }

)

/**
 * ライセンシー向け画面の証紙申請情報周りのスライス
 */
export const labelsSlice = createSlice({
  name: 'licensee/labels',
  initialState,
  reducers: {

    /**
     * 保持している申請された証紙書の内部コードをクリアする
     * @param {*} state
     */
    clearAppliedLabelId: (state) => {
      state.appliedLabelId = null;
    },
    /**
     * 証紙申請一覧をクリアする
     * @param {*} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null
    },
    /**
     * 取得済みの証紙詳細情報をクリアする
     * @param {*} state
     */
    clearLabelDetail: (state) => {
      state.labelDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        // 証紙情報一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // 証紙申請処理
        case 'applyLabel':
          state.apiStatus.applyLabel = null;
          break;
        // 証紙申請削除処理
        case 'deleteLabel':
          state.apiStatus.deleteLabel = null;
          break;
        // 証紙詳細取得処理
        case 'fetchLabelDetail':
          state.apiStatus.fetchLabelDetail = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // 証紙一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // 証紙申請処理
      .addCase(applyLabel.pending, (state) => {
        state.apiStatus.applyLabel = 'loading';
      })
      .addCase(applyLabel.fulfilled, (state, action) => {
        state.apiStatus.applyLabel = 'finish';
        // 返却された証紙内部コードを保持
        state.appliedLabelId = action.payload.labelApplicationId
      })
      .addCase(applyLabel.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.applyLabel = null;
      })
      // 証紙申請削除処理
      .addCase(updateLabel.pending, (state) => {
        state.apiStatus.deleteLabel = 'loading';
      })
      .addCase(updateLabel.fulfilled, (state) => {
        state.apiStatus.deleteLabel = 'finish';
      })
      .addCase(updateLabel.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.deleteLabel = null;
      })
      // 証紙詳細取得処理
      .addCase(fetchLabelDetail.pending, (state) => {
        state.apiStatus.fetchLabelDetail = 'loading';
      })
      .addCase(fetchLabelDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchLabelDetail = 'finish';
        state.labelDetail = action.payload;
      })
      .addCase(fetchLabelDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchLabelDetail = null;
      });
  }
});

export const {
  clearApiStatus,
  clearAppliedLabelId,
  clearFetchAll,
  clearLabelDetail,
} = labelsSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.labels.apiStatus;

/**
 * stateから証紙情報を取得する
 * @param {*} state
 * @returns {*}
 */
export const fetchAllLabels = (state) => state.licensee.labels.fetchAll;

/**
 * stateから申請された証紙情報の証紙内部コードを取得する
 * @param {*} state
 * @returns {number|null} 証紙内部コード
 */
export const selectAppliedLabelId = (state) => state.licensee.labels.appliedLabelId;

/**
 * stateから証紙詳細情報を取得する
 * @param {*} state
 * @returns {label|null} 企画詳細情報
 */
export const selectLabelDetail = (state) => state.licensee.labels.labelDetail;

export default labelsSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAll'|'applyLabel'|'fetchLabelDetail'|'deleteLabel'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {import('../../lib/api/licensee').GetLabelsParam} GetLabelsParam
 */
/**
 * @typedef {import('../../lib/api/licensee').PostLabelParam} PostLabelParam
 */
/**
 * @typedef {object} label 証紙申請詳細情報
 * @property {number} labelApplicationId 証紙申請書内部コード
 * @property {string} labelApplicationNo 証紙申請書No
 * @property {number} revision 版数
 * @property {string} preferredDate 証紙受け取り希望日(YYYY/MM/DD)
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {'TMP'|'REQ'|'REJ'|'APP'} labelStatus 申請ステータス
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} message 連絡事項
 * @property {labelProduct[]} labelList 証紙申請商品情報リスト
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {MessageHistory[]} messageHistoryList 連絡事項のリスト
 */
/**
 * @typedef {object} labelProduct 証紙情報
 * @property {number} labelNo 証紙No
 * @property {string} productNo 品番
 * @property {string} productName 商品名
 * @property {number} labelFigure 証紙申請数
 * @property {'S'|'P'} labelType 証紙タイプ（S:シール P:印刷）
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'REJ'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * REJ: 差し戻し
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {'ANX'|'LCS'} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
//#endregion
