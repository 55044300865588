import { Navigate } from "react-router-dom";
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { fetchAnnouncementAll, selectApiStatus, clearApiStatus, clearAnnouncementList, selectAnnouncementList } from '../../../slices/licensee/announcementSlice';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { AnnouncementTable } from './AnnouncementTable.jsx';
import { AnnouncementDetailPopup } from './AnnouncementDetailPopup.jsx';
import { immutableSort } from '../../../lib/util';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/**
 * ライセンシー向けホーム画面
 * @returns
 */
export const HomePage = () => {
  const authRedirect = useAuthRedirect();
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).fetchAnnouncementAll;
  const announcementList = useSelector(selectAnnouncementList);

  const [current, setCurrent] = useState(1);

  // お知らせ詳細ポップアップ関連
  const [announcementDetailPopup, setAnnouncementDetailPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {Product|null} 対象お知らせ情報 */
    target: null,
    /** @type {Function|null} 閉じたときのコールバック */
    onClose: null,
  });

  // マウント時にお知らせ一覧を取得
  useEffect(() => {
    dispatch(fetchAnnouncementAll());
    return () => {
      // 画面離脱時にAPI通信状況をクリアする
      dispatch(clearApiStatus('fetchAnnouncementAll'));
      dispatch(clearAnnouncementList());
    }
  }, [dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const announcements = announcementList ?? [];

    // 公開開始日時の降順にソートする
    const sortKey = 'announcementStartDatetime';
    const newer = immutableSort(announcements, (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    });
    newer.reverse();
    return newer;
  }, [announcementList]);


  /** お知らせ件名クリック時のコールバック */
  const onOpenDetail = useCallback((data) => {
    setAnnouncementDetailPopup({
      showFlg: true,
      target: data,
      onClose: () => {
        setAnnouncementDetailPopup({
          showFlg: false,
          target: null,
          onClose: null,
        });
      }
    });
  }, []);

  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    )
  }

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">お知らせ</h1>
      </div>

      {Boolean(announcementList) && (
        <AnnouncementTable
          records={records}
          current={current}
          pageSize={pageSize}
          emptyMessage="お知らせがありません。"
          onOpenDetail={onOpenDetail}
          onPageChange={setCurrent} />
      )}

      {
        announcementDetailPopup.showFlg ?
          <AnnouncementDetailPopup
          onClose={announcementDetailPopup.onClose}
          announcement={announcementDetailPopup.target}
          /> : null
      }
      {loading}
    </div>
  );
};
