import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { fetchAll, fetchAllProposals, fetchProposalDetail, selectProposalDetail,
  clearApiStatus, selectApiStatus, clearAppliedProposalId, clearProposalDetail } from '../../../slices/licensee/proposalsSlice'
import { fetchLabelDetail, selectLabelDetail, applyLabel, updateLabel, selectAppliedLabelId,
  clearApiStatus as labelClearApiStatus, selectApiStatus as labelApiStatus, clearAppliedLabelId, clearLabelDetail } from '../../../slices/licensee/labelsSlice';
import { LabelProductForm } from './LabelProductForm';
import { isEmpty, maxLength, lengthRange } from '../../../lib/validator';
import { getMessage } from '../../../lib/message';
import { MessagePopup } from '../../common/MessagePopup';
import { pushMessage } from '../../../slices/licensee/utilSlice';
import { ErrorMessageList } from '../../common/ErrorMessageList';
import { Constants } from '../../../Constants';
import dayjs from 'dayjs';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { useAuthRedirect } from "../../../lib/hooks/licensee"
import { deleteComma } from '../../../lib/number';
import { selectMyself } from "../../../slices/licensee/usersSlice"
import { NumberInput } from '../../common/NumberInput';
import { useFocusError } from '../../../lib/hooks/common';
import { SearchProposalPopup } from '../shared/SearchProposalPopUp';
import { sleep } from '../../../lib/util';
import { MessageHistoryArea } from './MessageHistoryArea';
import { LabelRequestPopup } from './LabelRequestPopup';
import { useProposalToSummaryName } from '../shared/useProposalToSummaryName';
import { useTimeoutWarningPopup } from '../TimeoutWarningPopup';

/** フォーム項目の順序 */
const formPropOrder = [
  // 証紙受け取り希望日
  'preferredDate',
  // 企画書
  'proposalId',
  // 連絡事項
  'message',
  // 郵便番号
  'deliveryZipCode',
  // 電話番号
  'deliveryPhone',
  // 住所
  'deliveryAddress',
  // 宛先会社名／部署名
  'deliveryCorpDivision',
  // 宛名
  'deliveryName',
];

/** 証紙申請リスト部分の項目 */
const labelProps = [
  // 証紙申請数
  'labelFigure',
];

/**
 * 証紙申請画面
 * @returns
 */
export const LabelRequestDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routerParams = useParams();
  const apiStatus = useSelector(labelApiStatus);
  const proposalApiStatus = useSelector(selectApiStatus);

  const proposalList = useSelector(fetchAllProposals);
  const targetProposal = useSelector(selectProposalDetail);
  const appliedLabelId = useSelector(selectAppliedLabelId);
  const labelDetail = useSelector(selectLabelDetail);
  const authRedirect = useAuthRedirect();
  const myself = useSelector(selectMyself); // 新規のみ利用

  const [productList, setProductList] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [labelError, setLabelError] = useState(false);
  const [labelErrors, setLabelErrors] = useState({});
  const [saveType, setSaveType] = useState(null);
  const proposalId = new URLSearchParams(useLocation().search).get('proposalId');

  // 作品名称
  const { propertySummaryName } = useProposalToSummaryName(targetProposal)

  // エラー項目へフォーカスする設定
  const formProps = useMemo(() => {
    return [
      ...formPropOrder,
      {
        name: 'labelList',
        items: productList.map((_, idx) => ({ name: idx, items: ['labelFigure' ]})),
      }
    ]
  }, [productList]);
  const [formRefs, focusError] = useFocusError(formProps);
  const [needFocusError, setNeedFocusError] = useState(false);

  // 強制バリデートアクション実行フラグ
  const [forceValidateActionFlg, setForceValidateActionFlg] = useState(false);

  /** 強制バリデートアクション実行コールバック */
  const onForceValidateAction = useCallback(() => {
    setForceValidateActionFlg(false);
  }, []);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop]?.length > 0 && focusError(prop)) {
        setNeedFocusError(false);
        return;
      }
    }

    // 商品リスト部分
    for (let i = 0; i < productList.length; i++) {
      for (const prop of labelProps) {
        if (labelErrors[i]?.[prop]?.length > 0) {
          if (focusError('labelList', i, prop)) {
            setNeedFocusError(false);
            return;
          }
        }
      }
    }
  }, [focusError, labelErrors, messages, needFocusError, productList.length]);

  // 申請情報
  const [labelData, setLabelData] = useState({
    labelApplicationId: null,
    proposalId: null,
    labelStatus: null,
    preferredDate: null,
    message: null,
    labelList: [],
    updateDatetime: null,
    deliveryZipCode: null,
    deliveryPhone: null,
    deliveryAddress: null,
    deliveryCorpDivision: null,
    deliveryName: null
  });
  const [labelLcs, setLabelLcs] = useState({});

  // 編集フラグ
  const isNew = useMemo(() => {
    return !Boolean(routerParams.labelApplicationId)
  }, [routerParams.labelApplicationId]);

  const isEditable = useMemo(() => {
    // 一時保存・差し戻し中は入力可
    return isNew || (labelDetail?.labelStatus && [
      Constants.Licensee.labelStatus.TemporarySaved,
      Constants.Licensee.labelStatus.Rejected
    ].includes(labelDetail?.labelStatus));
  }, [isNew, labelDetail?.labelStatus]);

  // 申請可能フラグ
  const isAppliable = useMemo(() => {
    // 企画のステータスが承認済み or 契約終了（未報告）
    const isApp = targetProposal?.proposalStatus === Constants.Licensee.ProposalStatus.Approved
      || targetProposal?.proposalStatus === Constants.Licensee.ProposalStatus.FinishNotReported;
    // 証紙申請対象の商品が存在する
    return isApp && labelList.length > 0;
  }, [labelList, targetProposal]);

  // タイムアウトの警告ポップアップ (編集可能時のみ表示)
  const [timeoutWarningPopup, resetTimer] = useTimeoutWarningPopup(isEditable);

  // ポップアップ初期化用定義
  const resetPopup = useMemo(() => {
    return {
      showFlg: false,
      message: '',
      ok: '',
      onClose: null
    }
  }, []);

  // 確認ダイアログ
  const [confirmPopup, setConfirmPopup] = useState(resetPopup);
  // 申請確認ダイアログ
  const [applyConfirm, setApplyConfirm] = useState(/** @type {RequestConfirmPopup} */ ({
    showFlg: false,
    onClose: null,
  }))

  // 未ログイン時は対応する画面へ遷移
  if (authRedirect != null) {
    navigate(authRedirect);
  }

  useEffect(() => {
    // 離脱時にAPIクリア
    return () => {
      // 企画系
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(clearAppliedProposalId());
      dispatch(clearProposalDetail());
      // 証紙系
      dispatch(labelClearApiStatus('fetchLabelDetail'));
      dispatch(labelClearApiStatus('applyLabel'));
      dispatch(labelClearApiStatus('deleteLabel'));
      dispatch(clearAppliedLabelId());
      dispatch(clearLabelDetail())
    }
  }, [dispatch]);

  // 証紙申請に表示する商品リストを作成
  useEffect(() => {
    const tmpProduct = [];
    if (isNew) {
      targetProposal?.productList.forEach((p) => {
        tmpProduct.push(p);
      });
    } else {
      targetProposal?.productList.forEach((p) => {
        // 証紙申請済みの商品を取得
        const target = labelDetail?.labelList.find(l => l.productNo === p.productNo);
        // 編集可能なステータスの場合のみ、未申請の商品を追加
        if (target || isEditable) {
          tmpProduct.push(p);
        }
      });
    }
    setProductList(tmpProduct);
  }, [isEditable, isNew, labelDetail?.labelList, targetProposal?.productList])

  useEffect(() => {
    // 証紙が編集可能な場合のみ
    if (isEditable) {
      // 企画リストに承認済みの企画のみ抽出
      dispatch(fetchAll({proposalStatus: Constants.Licensee.ProposalStatus.ApprovedAndFinishNotReported}));
    }
  }, [dispatch, isEditable]);

  // 証紙申請詳細
  useEffect(() => {
    // 画面突入時に対象の証紙情報を取得する
    if (routerParams.labelApplicationId) {
      dispatch(fetchLabelDetail(routerParams.labelApplicationId));
    }
  }, [dispatch, routerParams.labelApplicationId]);

  // 証紙情報取得
  useEffect(() => {
    if (!isNew && labelDetail) {
      // 証紙申請で指定している企画を取得
      dispatch(fetchProposalDetail(labelDetail.proposalId));
      // 必要なパラメータ抽出
      const curLabel = {};
      for (const[name, ] of Object.entries(labelData)) {
        if (name === 'labelList') setLabelList(labelDetail[name]);
        curLabel[name] = labelDetail[name];
      }
      setLabelData(curLabel);
    }
    // 証紙のデフォルト設定
    if (isNew) {
      const curLabel = {
        deliveryZipCode: myself?.zipCode ?? '',
        deliveryPhone: myself?.phone ?? '',
        deliveryAddress: myself?.address ?? '',
        deliveryCorpDivision: myself?.licenseeNameKanji ?? '',
      };
      setLabelLcs(curLabel);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isNew, labelDetail, myself]);

  useEffect(() => {
    // 送付先の値反映
    if (isNew) {
      setLabelData(prev => ({ ...prev, ...labelLcs }));
    }
  }, [isNew, labelLcs]);

  // 入力値チェック
  const handleChange = useCallback((name, value) => {
    if (['deliveryZipCode','deliveryPhone'].includes(name)) {
      // ピリオド除外
      value = value.replace(/\./g, '');
    }
    setLabelData({...labelData, [name]: value});
    setMessages({...messages, [name] : validate(name, value)});
  }, [labelData, messages]);

  // 企画選択
  const handleChangeProposal = useCallback((name, value) => {
    if (!name) return;
    setLabelData({...labelData, [name]: value});
    setMessages({...messages, [name] : validate(name, value)});
    if (value) {
      dispatch(fetchProposalDetail(value));
    } else {
      // クリア
      dispatch(clearApiStatus('proposalDetail'));
      dispatch(clearProposalDetail());
    }
  }, [dispatch, labelData, messages]);

  useEffect(() => {
    if (proposalId) handleChangeProposal('proposalId', proposalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalId]);

  useEffect(() => {
    // ステータスが「一時保存」または「差し戻し中」の証紙申請に紐づく企画のステータスが「承認済み」でない場合
    if (isEditable && !isNew && proposalList && (labelDetail && !isEmpty(labelDetail.proposalId))) {
      // targetProposal はリストの値変更時にも使用するので承認済み企画リストにあるかどうかで判定
      const isApprovedProposal = (proposalList?.result?.data.find(p => Number(p.proposalId) === Number(labelDetail.proposalId)))
      if (!isApprovedProposal) {
        dispatch(pushMessage('企画が更新中か中止されたため、この証紙申請は行えません。'));
        navigate('/licensee/labelRequestList');
      }
    }
  }, [dispatch, isEditable, isNew, labelDetail, navigate, proposalList]);

  // 申請
  const onApply = useCallback(async () => {
    const params = {
      ...labelData,
      labelList: formatNumber(labelList),
      labelStatus: Constants.Licensee.labelStatus.Requesting
    };
    setForceValidateActionFlg(true);
    await sleep(0);
    const hasError = validateAll(params);
    if (Object.keys(hasError).length || labelError) {
      setMessages({...hasError});
      setNeedFocusError(true);
      return;
    }
    setSaveType(params.labelStatus);
    // 印刷証紙の選択がある場合
    if (labelList.find(l => l.labelType === 'P')) {
        setConfirmPopup({
        showFlg: true,
        message: '印刷証紙をご希望の場合は、事前にご相談ください。',
        ok: '申請',
        onClose: (btn) => {
          if (btn === 'ok') {
            setApplyConfirm({
              showFlg: true,
              onClose: (btn, messageContent) => {
                if (btn === 'ok') {
                  dispatch(applyLabel({
                    ...params,
                    messageContent,
                  }));
                }
                setApplyConfirm({
                  showFlg: false,
                  onClose: null,
                });
              }
            })
          }
          setConfirmPopup(resetPopup);
        }
      });
    } else {
      setApplyConfirm({
        showFlg: true,
        onClose: (btn, messageContent) => {
          if (btn === 'ok') {
            dispatch(applyLabel({
              ...params,
              messageContent,
            }));
          }
          setApplyConfirm({
            showFlg: false,
            onClose: null,
          });
        }
      });
    }
  }, [dispatch, labelData, labelError, labelList, resetPopup]);

  // 一時保存
  const onTemp = useCallback(async () => {
    const params = {
      ...labelData,
      labelList: formatNumber(labelList),
      labelStatus: Constants.Licensee.labelStatus.TemporarySaved
    };
    setForceValidateActionFlg(true);
    await sleep(0);
    const hasError = validateAll(params, false);
    if (Object.keys(hasError).length) {
      setMessages({...hasError});
      setNeedFocusError(true);
      return;
    }
    setSaveType(params.labelStatus);
    dispatch(applyLabel(params));
  }, [dispatch, labelData, labelList]);

  // 削除
  const onDel = useCallback(() => {
    setConfirmPopup({
      showFlg: true,
      message: '証紙申請を削除します。よろしいですか？',
      ok: '削除',
      onClose: (btn) => {
        if (btn === 'ok') {
          if (!isNew) {
            dispatch(updateLabel(labelData));
          } else {
            dispatch(pushMessage('証紙申請を削除しました。'));
            navigate('/licensee/labelRequestList');
          }
        }
        setConfirmPopup({
          showFlg: false,
          message: '',
          ok: '',
          onClose: null
        });
      }
    })
  }, [dispatch, isNew, labelData, navigate]);

  // 削除処理結果
  useEffect(() => {
    if (apiStatus.deleteLabel === 'finish') {
      dispatch(pushMessage('証紙申請を削除しました。'));
      navigate('/licensee/labelRequestList');
    }
  }, [apiStatus.deleteLabel, dispatch, navigate]);

  // 申請処理結果
  useEffect(() => {
    if (apiStatus.applyLabel === 'finish') {
      if (saveType === Constants.Licensee.labelStatus.Requesting) {
        dispatch(pushMessage('証紙の申請を行いました。'));
      } else {
        dispatch(pushMessage('証紙の一時保存を行いました。'));
        resetTimer();
      }
      dispatch(labelClearApiStatus('applyLabel'));
      if (isNew) {
        navigate(`/licensee/labelRequestDetail/${appliedLabelId}`);
      } else {
        dispatch(fetchLabelDetail(appliedLabelId));
      }
    }
  }, [apiStatus.applyLabel, appliedLabelId, dispatch, isNew, navigate, saveType, resetTimer])

  useEffect(() => {
    if (apiStatus.fetchLabelDetail === 'error') {
      dispatch(pushMessage('証紙情報の取得中にエラーが発生しました。'));
    }
  }, [apiStatus.fetchLabelDetail, dispatch]);

  useEffect(() => {
    // 証紙申請APIエラー時はメッセージ表示
    if (apiStatus.applyLabel === 'error') {
      // TODO: メッセージ要確認
      dispatch(pushMessage('証紙申請APIでエラーが発生しました'));
      // メッセージを投げたらAPIステータスをクリア
      dispatch(labelClearApiStatus('applyLabel'));
    }
  }, [apiStatus.applyLabel, dispatch]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    const targetApiStatus = [
      proposalApiStatus.fetchAll,
      proposalApiStatus.fetchProposalDetail,
      apiStatus.applyLabel,
      apiStatus.deleteLabel,
      apiStatus.fetchLabelDetail,
    ];
    if (targetApiStatus.includes('loading')) {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus.applyLabel, apiStatus.deleteLabel, apiStatus.fetchLabelDetail, proposalApiStatus.fetchAll, proposalApiStatus.fetchProposalDetail]);

  /**
   * 企画書設定ポップアップ
   */
   const [searchProposalPopup, setSearchProposalPopup] = useState({
    showFlg: false,
    onClose: null,
  });

  /**
   * 企画書検索フォーム表示
   */
  const onSearchProposalClick = useCallback(() => {
    setSearchProposalPopup({
      showFlg: true,
      onClose: (btn, code) => {
        if (btn === 'ok') {
          handleChangeProposal('proposalId', code)
        }
        setSearchProposalPopup({
          showFlg: false,
          onClose: null
        })
      }
    })
  }, [handleChangeProposal]);

  return (
    <div className="wrap">
      <div className="title-border mb20" style={{ justifyContent: 'left' }}>
        <h1 className="title">証紙申請</h1>
        <p className='c-pink' style={{ marginLeft: '1.5em' }}>
          商品に二次使用料が発生する版権をご使用されている場合は、必ず作品担当者へ「商品二次使用版権申請書」のご提出をお願いいたします。
        </p>
        <p className="link-pageback deco-line c-pink" style={{ marginLeft: 'auto' }}>
          <Link to='/licensee/labelRequestList'>
          <i className="icn pageback"></i>証紙申請一覧へ戻る</Link></p>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: 110 }}>証紙申請No</dt>
          <dd className="form-body">
            <div className="input-form wdt100">
              <input type="text" name="labelApplicationNo" title="証紙申請Noは入力済みです"
                aria-label="証紙申請No" value={labelDetail?.labelApplicationNo ?? ''} required disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set" style={{ flexWrap: 'wrap', width: '310px' }}>
          <dt className="form-name required">証紙受け取り希望日</dt>
          <dd className="form-body">
            <div className="input-form input-calendar">
              <DatePicker
                name='preferredDate'
                title='証紙受け取り希望日を入力してください'
                aria-label='証紙受け取り希望日'
                dateFormat='yyyy/MM/dd'
                locale='ja'
                ref={formRefs.current.preferredDate}
                onChange={item => handleChange('preferredDate', item ? dayjs(item).format('YYYY/MM/DD') : '')}
                selected={isEmpty(labelData.preferredDate) ? null : dayjs(labelData.preferredDate).toDate()}
                required
                disabled={!isEditable}
              />
            </div>
            <ErrorMessageList messages={messages.preferredDate} />
          </dd>
          <dd><p className='attention'>※申請日から実稼働日で7営業日以上を目安に<br />お願いいたします。</p></dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請日</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="applicationDatetime" title="申請日は入力済みです"
              aria-label="申請日" value={
                isEmpty(labelDetail?.applicationDatetime) ? '' :
                dayjs(labelDetail?.applicationDatetime).format('YYYY/MM/DD')} disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請者</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="applicantUserName" title="申請者は入力済みです"
              aria-label="申請者" value={labelDetail?.applicantUserName ?? ''} disabled />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">証紙申請ステータス</dt>
          <dd className="form-body">
            <div className="input-form wdt100">
              <input type="text" name="labelStatus"
               value={Constants.Licensee.labelStatusName[labelDetail?.labelStatus] ?? ''}
               disabled />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: 110 }}>企画書</dt>
          <dd className="form-body">
              {
                isEditable ?
                <>
                  <div className="input-form wdt1000" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input type="text"
                      title="企画書を必ず選択してください"
                      aria-label="企画書"
                      disabled
                      value={targetProposal ? `${targetProposal.proposalNo} ${targetProposal.proposalTitle}` : ''}
                    />
                    {
                      isNew && (
                        <p className="btn label c-pink wdt100 ml20" style={{ width: '80px' }}>
                          <button
                            ref={formRefs.current.proposalId}
                            onClick={onSearchProposalClick}
                          >設定</button>
                        </p>
                      )
                    }
                  </div>
                  <ErrorMessageList messages={messages.proposalId} />
                  {
                    searchProposalPopup.showFlg ?
                    <SearchProposalPopup
                      selectProposalId={labelData.proposalId}
                      onClose={searchProposalPopup.onClose}
                      targetProposalStatus={Constants.Licensee.ProposalStatus.ApprovedAndFinishNotReported} /> :
                    null
                  }
                </> :
                <div className="input-form wdt1000">
                  <input type="text" name="proposalId" aria-label="企画書" disabled
                   value={targetProposal ? `${targetProposal.proposalNo} ${targetProposal.proposalTitle}` : ''} />
                </div>
              }
          </dd>
        </dl>
      </div>

      {
        targetProposal ? (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{ width: 110 }}>作品名</dt>
              <dd className="form-body">
                <div className="input-form wdt1000">
                  <input type="text" name="labelStatus" title="作品名は入力済みです"
                   value={propertySummaryName}
                   disabled />
                </div>
              </dd>
            </dl>
          </div>
        ) : null
      }

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 110 }}>連絡事項</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="message" title="連絡事項を入力してください"
               ref={formRefs.current.message}
               onChange={item => handleChange('message', item.target.value)}
               aria-label="連絡事項" value={labelData.message ?? ''}
               disabled={!isEditable} />
              <ErrorMessageList messages={messages.message} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: 110 }}>送付先</dt>
          <dd className="form-body">
            <span className="attention">送付先に変更がある場合は、記載の変更をお願いいたします。</span>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: 170 }}>郵便番号</dt>
              <dd className="form-body" style={{ marginBottom: 10 }}>
                <div className="input-form wdt250">
                  <NumberInput type="text"
                    inputRef={formRefs.current.deliveryZipCode}
                    value={labelData.deliveryZipCode ?? ''}
                    onChange={item => handleChange('deliveryZipCode', item)}
                    name="deliveryZipCode"
                    disabled={!isEditable} />
                  <ErrorMessageList messages={messages.deliveryZipCode} />
                </div>
              </dd>
              <dt className="form-name required" style={{ width: 110, marginLeft: 40 }}>電話番号</dt>
              <dd className="form-body" style={{ marginBottom: 10 }}>
                <div className="input-form wdt500">
                  <NumberInput type="text"
                    inputRef={formRefs.current.deliveryPhone}
                    value={labelData.deliveryPhone ?? ''}
                    onChange={item => handleChange('deliveryPhone', item)}
                    name="deliveryPhone"
                    disabled={!isEditable} />
                  <ErrorMessageList messages={messages.deliveryPhone} />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: 170 }}>住所</dt>
              <dd className="form-body" style={{ marginBottom: 10 }}>
                <div className="input-form wdt1000">
                  <input type="text" name="deliveryAddress"
                  ref={formRefs.current.deliveryAddress}
                  onChange={item => handleChange('deliveryAddress', item.target.value)}
                  value={labelData.deliveryAddress ?? ''}
                  disabled={!isEditable} />
                  <ErrorMessageList messages={messages.deliveryAddress} />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: 170 }}>宛先会社名／部署名</dt>
              <dd className="form-body" style={{ marginBottom: 10 }}>
                <div className="input-form wdt1000">
                  <input type="text" name="deliveryCorpDivision"
                  ref={formRefs.current.deliveryCorpDivision}
                  onChange={item => handleChange('deliveryCorpDivision', item.target.value)}
                  value={labelData.deliveryCorpDivision ?? ''}
                  disabled={!isEditable} />
                  <ErrorMessageList messages={messages.deliveryCorpDivision} />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: 170 }}>宛名</dt>
              <dd className="form-body" style={{ marginBottom: 10 }}>
                <div className="input-form wdt1000">
                  <input type="text" name="deliveryName"
                  ref={formRefs.current.deliveryName}
                  onChange={item => handleChange('deliveryName', item.target.value)}
                  value={labelData.deliveryName ?? ''}
                  disabled={!isEditable} />
                  <ErrorMessageList messages={messages.deliveryName} />
                </div>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>

      <MessageHistoryArea
        labelRequest={labelDetail} />

      {
        /** 商品リストがあったら表示 */
        productList ?
        <LabelProductForm
          productList={productList}
          isEditable={isEditable}
          pLabelList={labelDetail && labelDetail.labelList}
          setLabelError={setLabelError}
          setLabelErrors={setLabelErrors}
          setParentLabel={setLabelList}
          formRefs={formRefs.current.labelList}
          forceValidateActionFlg={forceValidateActionFlg}
          onForceValidateAction={onForceValidateAction}
        /> : null
      }


      <div className="mt20 pt30 dif j-between bt-solid-gray" >
      {
        isEditable ? (
          <>
            <div className="l-buttons">
            <p className="btn bg-green" style={{ width: 203 }}>
              <button type="button" onClick={onApply} disabled={!isAppliable}>
                <i className="icn check"></i>証紙を申請する</button>
            </p>
            <p className="btn c-blue ml10" style={{ width: 160 }}>
              <button type="button" onClick={onTemp}>
                <i className="icn save"></i>一時保存</button>
            </p>
            </div>
          </>
        ) : null
      }
      {
        isNew || (isEditable && Number(labelDetail?.revision) === 0) ? (
          <p className="btn c-pink" style={{ width: 89 }}>
          <button type="button" onClick={onDel}><i className="icn cross"></i>削除</button>
          </p>
        ) : null
      }
      </div>

      {
        /** 確認ダイアログ */
        confirmPopup.showFlg ?
          <MessagePopup
            message={confirmPopup.message}
            btn={{ ok: confirmPopup.ok, cancel: 'キャンセル' }}
            onClose={confirmPopup.onClose}
            btnClass='bg-pink' /> : null
      }

      {
        applyConfirm.showFlg && (
          <LabelRequestPopup onClose={applyConfirm.onClose} />
        )
      }
      {loading}
      {
        isEditable && timeoutWarningPopup
      }
    </div>
  );

}

/**
 * 申請前に証紙申請数の3桁区切り一括除去
 * @property {array} dataList 処理対象
 */
function formatNumber(dataList) {
  return dataList.map(data => {
    return {
      ...data,
      labelFigure : data.labelFigure ? deleteComma(data.labelFigure) : null
    }
  });
}

/**
 * 商品以外の項目チェック
 * @param {*}} label
 */
function validateAll(label, isApply = true) {
  const errors = [];
  Object.entries(label).forEach(([key, value]) => {
    const err = validate(key, value, isApply);
    if (err.length > 0) errors[key] = err;
  })
  return errors;
}

/**
 * 申請用バリデート
 */
function validate(name, value, isApply = true) {
  const errors = [];
  // 希望日
  if (name === 'preferredDate') {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    }
  }
  // 企画書
  if (name === 'proposalId') {
    if (isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    }
  }
  // 連絡事項
  if (name === 'message') {
    if (value && !maxLength(value, 100)) {
      errors.push(getMessage('maxLength', {max: 100}));
    }
  }
  // 郵便番号
  if (name === 'deliveryZipCode') {
    // 8文字かどうか
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (value && !lengthRange(value, 8, 8)) {
      errors.push('半角ハイフンを含む8桁で入力してください')
    } else if (value && !value.match(/\d{3}-\d{4}/)) {
      errors.push('郵便番号形式で入力してください')
    }
  }
  // 電話番号
  if (name === 'deliveryPhone') {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (value && !maxLength(value, 13 )) {
      errors.push(getMessage('maxLength', {max:13}));
    }
  }
  // 住所
  if (name === 'deliveryAddress') {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (value && !maxLength(value, 100)) {
      errors.push(getMessage('maxLength', {max:100}));
    }
  }
  // 宛先会社名／部署名
  if (name === 'deliveryCorpDivision') {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (isApply && value && !maxLength(value, 100)) {
      errors.push(getMessage('maxLength', {max:100}));
    }
  }
  // 宛名
  if (name === 'deliveryName') {
    if (isApply && isEmpty(value)) {
      errors.push(getMessage('isNotEmpty'));
    } else if (value && !maxLength(value, 20)) {
      errors.push(getMessage('maxLength', {max:20}));
    }

  }

  return errors;
}

//#region typedef
/**
 * @typedef {object} RequestConfirmPopup 申請確認ポップアップ
 * @property {boolean} showFlg ポップアップ表示フラグ
 * @property {?RequestOnClose} onClose 閉じるときのコールバック
 */
/**
 * @typedef {import('./LabelRequestPopup').OnClose} RequestOnClose
 */
//#endregion
