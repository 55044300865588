import { useState } from 'react';
import logoRoys from '../../assets/aniplex_img/logo-roys.png';
import { Link, matchRoutes } from 'react-router-dom';
import { Config } from '../../config';

/**
 * アニプレックス側ヘッダー
 */
export const AniplexHeader = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);

  /** 企画一覧配下の画面滞在中フラグ */
  const inProposalPage = !!matchRoutes([
      {path: '/aniplex/proposalList'},
      {path: '/aniplex/proposalDetail'},
      {path: '/aniplex/proposalDetail/:proposalId'},
      {path: '/aniplex/proposalStatus/:proposalId'},
      {path: '/aniplex/contractDetail/:sDecisionNo'},
    ], window.location.hash.substring(1));
  /** 証紙申請一覧配下の画面滞在中フラグ */
  const inLabelRequestPage = !!matchRoutes([
    {path: '/aniplex/labelRequestList'},
    {path: '/aniplex/labelRequestList/:proposalNo'},
    {path: '/aniplex/labelRequestDetail/:labelApplicationId'},
  ], window.location.hash.substring(1));
  /** ロイヤリティ報告配下の画面滞在中フラグ */
  const inRoyaltyReportPage = !!matchRoutes([
    {path: '/aniplex/royaltyReportList'},
    {path: '/aniplex/royaltyReportList/:proposalNo'},
    {path: '/aniplex/royaltyReportDetail/:ryReportId'},
  ], window.location.hash.substring(1));
  /** ライセンシー管理配下の画面滞在中フラグ */
  const inLicenseePage = !!matchRoutes([
    {path: '/aniplex/licenseeList'},
    {path: '/aniplex/licenseeUserList'},
    {path: '/aniplex/licenseeUserRegister'},
    {path: '/aniplex/licenseeUserUpdate/:userId'},
  ], window.location.hash.substring(1));

  const onToggleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <header className="l-header">
      <div className="wrap">
        <h1 className="header-logo transition-img">
          <span className="header_login-logo_img">
            <img
              src={logoRoys}
              alt="ROYS ロゴ"
              title="ROYS"
              className="logo-roys"
              width="155px"
              height="30px"
            />
          </span>
          <span className="header_login-aniplex">
            アニプレックス管理用
          </span>
          <p className="deco-line c-black" style={{ marginLeft: 10 }}>
            <a href={Config.LicenseeUrl} target="_blank" rel="noreferrer">ライセンシー向け画面</a>
          </p>
        </h1>
        <div className="header-links">
          <p className="link-user c-black">
            <i className="icn user"></i>{ username }
          </p>
          <dl className="btn-master">
            <dt className={"js-nav_trigger" + (isOpen ? ' is-active' : '')} onClick={onToggleClick}>
              マスタ管理
            </dt>
            <dd className="js-nav_body" style={isOpen ? null : styleDisplayNone }>
              <ul>
                <li>
                  <Link to="/aniplex/propertyList">作品一覧</Link>
                </li>
                <li>
                  <Link to="/aniplex/anxUserList">ANIPLEX<br />ユーザー一覧</Link>
                </li>
                <li>
                  <Link to="/aniplex/announcementList">お知らせ一覧</Link>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>

      <nav className="header-nav">
        <ul className="header-nav_list">
          <li className={inProposalPage ? 'active' : ''}>
            <Link to="/aniplex/proposalList">企画管理</Link>
          </li>
          <li className={inLabelRequestPage ? 'active' : ''}>
            <Link to="/aniplex/labelRequestList">証紙申請管理</Link>
          </li>
          <li className={inRoyaltyReportPage ? 'active' : ''}>
            <Link to="/aniplex/royaltyReportList">ロイヤリティ報告管理</Link>
          </li>
          <li className={'toggle-sub-nav ' + (inLicenseePage ? 'active' : '')}>
            <p>ライセンシー管理</p>
            <ul className="header-sub-nav_list">
              <li><Link to="/aniplex/licenseeList">取引先一覧</Link></li>
              <li><Link to="/aniplex/licenseeUserList">ライセンシー一覧</Link></li>
            </ul>
          </li>
        </ul>
      </nav>

    </header>
  );
};

// マスタ管理、閉じている場合のstyle
const styleDisplayNone = { display: 'none' };
