//@ts-check
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../Constants";
import { getRoyalty, getRoyaltyAll, patchRoyalty, postRoyalties } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  /** @type {?ListRoyaltyResult} */
  fetchAll: null,
  /** @type {number|null} 申請されたロイヤリティ報告書内部コード */
  appliedRyReportId: null,
  /** @type {RoyaltyDetail|null} 取得したロイヤリティ報告詳細情報 */
  royaltyDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** ロイヤリティ報告一覧 */
    fetchAll: null,
    /** ロイヤリティ報告詳細取得処理 */
    fetchRoyaltyDetail: null,
    /** ロイヤリティ報告処理 */
    applyRoyalty: null,
    /** ロイヤリティ報告削除処理 */
    deleteRoyalty: null,
  },
}

export const fetchAll = createAsyncThunk(
  'royalties/labels/fetchAll',
  /**
   * ロイヤリティ報告一覧取得処理
   * @param {*} params
   */
  async (params, { dispatch }) => {
    try {
      const data = await getRoyaltyAll(params);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch);
    }
  }
)

/**
 * ロイヤリティ報告詳細取得処理
 */
export const fetchRoyaltyDetail = createAsyncThunk(
  'licensee/royalties/fetchRoyaltyDetail',
  /**
   * ロイヤリティ報告詳細取得処理
   * @param {number} ryReportId ロイヤリティ報告内部コード
   */
  async (ryReportId, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearRoyaltyDetail());

    try {
      const data = await getRoyalty(ryReportId);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, thunkApi.dispatch, {
        '22101': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * ロイヤリティ報告処理
 */
export const applyRoyalty = createAsyncThunk(
  'licensee/royalties/applyRoyalty',
  /**
   * ロイヤリティ報告処理
   * @param {PostRoyaltiesParam} data ロイヤリティ報告パラメータ
   * @returns
   */
  async (data, { dispatch }) => {
    const params = filterApplyRoyaltyParams(data);

    try {
      const result = await postRoyalties(params);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '22001': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '22002': ApiErrorMessage.UpdateStateMismatch,
        // メッセージ登録失敗
        '22004': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * ロイヤリティ報告削除処理
 */
export const deleteRoyalty = createAsyncThunk(
  'licensee/royalties/deleteRoyalty',
  /**
   * ロイヤリティ報告削除処理
   * @param {RoyaltyDetail} royalty 削除対象のロイヤリティ報告情報
   */
  async (royalty, { dispatch }) => {
    /** @type {import('../../lib/api/licensee').PatchRoyaltyParam} */
    const params = {
      reportStatus: Constants.Licensee.reportStatus.Deleted,
      updateDatetime: royalty.updateDatetime,
    }

    try {
      const result = await patchRoyalty(royalty.ryReportId, params);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '22201': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '22202': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * ライセンシー向け画面のロイヤリティ報告情報周りのスライス
 */
export const royaltySlice = createSlice({
  name: 'royalties/labels',
  initialState,
  reducers: {
    /**
     * 保持している申請されたロイヤリティ報告書内部コードをクリアする
     * @param {typeof initialState} state
     */
    clearAppliedRyReportId: (state) => {
      state.appliedRyReportId = null;
    },
    /**
     * ロイヤルティ報告一覧情報をクリアする
     * @param {typeof initialState} action
     */
    clearFetchAll: (action) => {
      action.fetchAll = null;
    },
    /**
     * 取得済みのロイヤリティ報告詳細情報をクリアする
     * @param {typeof initialState} state
     */
    clearRoyaltyDetail: (state) => {
      state.royaltyDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        // ロイヤリティ報告一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // ロイヤリティ報告詳細取得処理
        case 'fetchRoyaltyDetail':
          state.apiStatus.fetchRoyaltyDetail = null;
          break;
        // ロイヤリティ報告処理
        case 'applyRoyalty':
          state.apiStatus.applyRoyalty = null;
          break;
        // ロイヤリティ報告削除処理
        case 'deleteRoyalty':
          state.apiStatus.deleteRoyalty = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ロイヤリティ報告一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // ロイヤリティ報告詳細取得処理
      .addCase(fetchRoyaltyDetail.pending, (state) => {
        state.apiStatus.fetchRoyaltyDetail = 'loading';
      })
      .addCase(fetchRoyaltyDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchRoyaltyDetail = 'finish'
        state.royaltyDetail = action.payload;
      })
      .addCase(fetchRoyaltyDetail.rejected, (state) => {
        // thun内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchRoyaltyDetail = null;
      })
      // ロイヤリティ報告処理
      .addCase(applyRoyalty.pending, (state) => {
        state.apiStatus.applyRoyalty = 'loading';
      })
      .addCase(applyRoyalty.fulfilled, (state, action) => {
        state.apiStatus.applyRoyalty = 'finish';
        // 返却された報告書内部コードを保持
        state.appliedRyReportId = action.payload.ryReportId;
      })
      .addCase(applyRoyalty.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.applyRoyalty = null;
      })
      // ロイヤリティ報告削除処理
      .addCase(deleteRoyalty.pending, (state) => {
        state.apiStatus.deleteRoyalty = 'loading';
      })
      .addCase(deleteRoyalty.fulfilled, (state) => {
        state.apiStatus.deleteRoyalty = 'finish';
      })
      .addCase(deleteRoyalty.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.deleteRoyalty = null;
      });
  }
});

export const {
  clearAppliedRyReportId,
  clearFetchAll,
  clearRoyaltyDetail,
  clearApiStatus,
} = royaltySlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.royalties.apiStatus;

/**
 * stateから申請されたロイヤリティ報告書の報告書内部コードを取得する
 * @param {*} state
 * @returns {number|null} ロイヤリティ報告書内部コード
 */
export const selectAppliedRyReportId = (state) => state.licensee.royalties.appliedRyReportId;

/**
 * stateからロイヤリティ報告詳細情報を取得する
 * @param {*} state
 * @returns {RoyaltyDetail|null} ロイヤリティ報告詳細情報
 */
export const selectRoyaltyDetail = (state) => state.licensee.royalties.royaltyDetail;

/**
 * stateからロイヤリティ報告情報を取得する
 * @param {*} state
 * @returns {*}
 */
export const fetchAllRoyalties = (state) => state.licensee.royalties.fetchAll;

export default royaltySlice.reducer;

/**
 * ロイヤリティ報告APIのパラメータのみに絞り込む
 * @param {PostRoyaltiesParam} royalty ロイヤリティ報告情報
 * @returns {PostRoyaltiesParam} ロイヤリティ報告APIのパラメータ
 */
function filterApplyRoyaltyParams(royalty) {
  /** @type {(keyof PostRoyaltiesParam)[]} */
  const royaltyParams = [
    'ryReportId', 'proposalId', 'period', 'reportStatus',
    'ryAmountList', 'updateDatetime', 'billingZipCode', 'billingPhone',
    'billingAddress', 'billingDepartment', 'billingName',
    'ryStartDate', 'ryEndDate', 'messageContent',
  ];
  /** @satisfies {(keyof RyAmount)[]} */
  const ryAmountParams = [
    'ryAmountNo', 'productId', 'reportProduction',
    'reportSales', 'reportPrice', 'reportCost',
    'reportProceeds', 'reportRyAmount',
  ];
  /** @satisfies {(keyof RyProof)[]} */
  const ryProofParams = ['ryProofNo'];

  /** @type {import('../../lib/api/licensee').PostRoyaltiesParam} */
  const result = {};

  for (const key in royalty) {
    if (/** @type {string[]} */ (royaltyParams).includes(key)) {
      result[key] = royalty[key];
    }
  }

  /** @type {RyAmount[]} */
  const ryAmountList = [];
  for (const ryAmount of royalty.ryAmountList) {
    const a = {};
    for (const aKey in ryAmount) {
      if (/** @type {string[]} */ (ryAmountParams).includes(aKey)) {
        a[aKey] = ryAmount[aKey];
      }
    }
    //@ts-expect-error
    ryAmountList.push(a);
  }
  result.ryAmountList = ryAmountList;

  /** @type {RyProof[]} */
  const ryProofList = [];
  for (const ryProof of royalty.ryProofList) {
    const p = {};
    for (const pKey in ryProof) {
      if (/** @type {string[]} */ (ryProofParams).includes(pKey)) {
        p[pKey] = ryProof[pKey];
      }
    }
    // @ts-expect-error
    ryProofList.push(p);
  }
  result.ryProofList = ryProofList;

  return result;
}

//#region typedef
/**
 * @typedef {'fetchAll'|'fetchRoyaltyDetail'|'applyRoyalty'|'deleteRoyalty'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {import('../../lib/api/licensee').ListRoyaltyResult} ListRoyaltyResult
 */
/**
 * @typedef {object} Royalty ロイヤリティ情報
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 */
/**
 * @typedef {object} RoyaltyDetail ロイヤリティ報告書詳細情報
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportNo ロイヤリティ報告書No
 * @property {number} revision 版数
 * @property {number} period 第N期
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {string} reportDatetime 報告日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} reportUserId 報告者ユーザーID
 * @property {string} reportUserName 報告者氏名
 * @property {'TMP'|'REQ'|'REJ'|'APP'} reportStatus 報告ステータス
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {RyAmount[]} ryAmountList ロイヤリティ金額情報
 * @property {RyProof[]} ryProofList ロイヤリティ報告確証ファイル情報
 * @property {RyReportHistory[]} [ryReportHistory] 過去のロイヤリティ報告状況
 * @property {string} updateDatetime 最終更新日時
 * @property {string} billingZipCode 請求先郵便番号
 * @property {string} billingAddress 請求先住所
 * @property {string} billingDepartment 請求先部署名
 * @property {string} billingName 請求先担当者氏名
 * @property {string} billingPhone 請求先電話番号
 * @property {number} calOverRoyalty オーバーロイヤリティ金額(API計算)
 * @property {number} calFullPeriodOverRoyalty 通期オーバーロイヤリティ金額(API計算)
 * @property {number} calFullPeriodRoyaltyTotal 通期ロイヤリティ金額合計(API計算)
 * @property {MessageHistory[]} [messageHistoryList] 連絡事項のリスト
 */
/**
 * @typedef {object} RyAmount ロイヤリティ金額情報
 * @property {number} ryAmountNo ロイヤリティ金額No
 * @property {number} productId 許諾商品内部コード
 * @property {string} productNo 品番
 * @property {string} productName 商品名
 * @property {number} reportProduction 生産数
 * @property {number} reportSales 販売数
 * @property {number} reportPrice 確定上代
 * @property {number} reportCost 確定製造原価
 * @property {number} reportProceeds 確定売上金額
 * @property {number} reportRyAmount ロイヤリティ金額
 */
/**
 * @typedef {object} RyProof ロイヤリティ報告確証ファイル情報
 * @property {number} ryProofNo ロイヤリティ報告確証No
 * @property {string} ryProofFilepath 確証ファイルパス
 */
/**
 * @typedef {object} RyReportHistory 過去のロイヤリティ報告状況
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {number} period 第N期
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {number} calOverRoyalty オーバーロイヤリティ金額(API計算)
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'REJ'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * REJ: 差し戻し
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {'ANX'|'LCS'} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
/**
 * @typedef {import('../../lib/api/licensee').PostRoyaltiesParam} PostRoyaltiesParam
 */
//#endregion
