import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLicenseesAll, getLicensee, patchLicensee } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  fetchAll: null,
  licenseeDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** 取引先一覧 */
    fetchAll: null,
    /** 取引先詳細 */
    licenseeDetail: null,
    /** 取引先更新 */
    updateLicensee: null,
  },
}

/**
 * 取引先一覧情報取得
 */
export const fetchAll = createAsyncThunk(
  'aniplex/licensees/fetchAll',
  async (params, { dispatch }) => {
    try {
      const data = await getLicenseesAll(params);
      return data;
    } catch (err) {
      handleAniplexApiError(err, dispatch);
    }
  }
)

/**
 * 取引先詳細情報取得
 */
export const fetchLicenseeDetail = createAsyncThunk(
  'aniplex/licensees/fetchLicenseeDetail',
  /**
   * 取引先詳細情報取得
   * @param {number} licenseeCode 取引先コード
   * @returns {licenseeDetail}
   */
  async(licenseeCode, { dispatch }) => {
    try {
      const data = await getLicensee(licenseeCode);
      return data.result.data;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '12101': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 取引先更新処理
 */
export const updateLicensee = createAsyncThunk(
  'aniplex/licensees/updateLicensee',
  /**
   * @param {string} licenseeCode 取引先コード
   * @param {boolean} param.kihonkeiyakuFlag
   * @param {string} param.kihonkeiyakuDate
   * @param {'A'|'B'|'C'} param.assessment 評価
   * @param {string} param.licenseeRemarks 備考
   * @param {boolean} param.priorityUse 優先利用
   * @param {string} param.detailUpdateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
   * @returns
   */
  async({ licenseeCode, params }, { dispatch }) => {
    try {
      const result = await patchLicensee(licenseeCode, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '12201': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '12202': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * アニプレックス向け画面の取引先情報周りのスライス
 */
export const licenseesSlice = createSlice({
  name: 'aniplex/licensees',
  initialState,
  reducers: {
    /**
     * 取引先一覧をクリア
     * @param {typeof initialState} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null
    },
    /**
     * 取引先詳細をクリア
     * @param {typeof initialState} state
     */
    clearLicenseeDetail: (state) => {
      state.licenseeDetail = null
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        case 'licenseeDetail':
          state.apiStatus.licenseeDetail = null;
          break;
        case 'updateLicensee':
          state.apiStatus.updateLicensee = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // 取引先一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // 取引先詳細処理
      .addCase(fetchLicenseeDetail.pending, (state) => {
        state.apiStatus.licenseeDetail = 'loading';
      })
      .addCase(fetchLicenseeDetail.fulfilled, (state, action) => {
        state.apiStatus.licenseeDetail = 'finish';
        state.licenseeDetail = action.payload;
      })
      .addCase(fetchLicenseeDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.licenseeDetail = null;
      })
      // 取引先更新処理
      .addCase(updateLicensee.pending, (state) => {
        state.apiStatus.updateLicensee = 'loading';
      })
      .addCase(updateLicensee.fulfilled, (state, action) => {
        state.apiStatus.updateLicensee = 'finish';
      })
      .addCase(updateLicensee.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateLicensee = null;
      });
  }
});

export const {
  clearApiStatus,
  clearFetchAll,
  clearLicenseeDetail,
} = licenseesSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.licensees.apiStatus;

/**
 * 取引先一覧を取得する
 * @param {*} state
 * @returns {*}
 */
export const fetchAllLicensees = (state) => state.aniplex.licensees.fetchAll;

/**
 * 取引先詳細を取得する
 * @param {*} state
 * @returns {*}
 */
export const selectLicenseeDetail = (state) => state.aniplex.licensees.licenseeDetail;

export default licenseesSlice.reducer;

//#region typedef
/**
 * @typedef {'fetchAll'|'licenseeDetail'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Licensee 取引先情報
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名(漢字)
 * @property {string} address 住所
 * @property {string} phone 電話番号
 * @property {boolean} kihonkeiyakuFlag 基本契約締結
 * @property {string} kihonkeiyakuDate 基本契約締結日
 * @property {'A'|'B'|'C'} assessment 評価
 */
//#endregion
