//@ts-check
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../../../Constants';
import { selectPropertyMst } from '../../../slices/aniplex/masterSlice';
import { ValueChangeStatus } from '../common/ValueChangeStatus';

/**
 * 企画詳細画面の企画概要部分のフォーム
 * @param {object} props
 * @param {?Proposal} props.proposal 企画情報
 */
export const ProposalOverviewForm = ({ proposal }) => {
  /** 取引先名称 */
  const licenseeName = useMemo(() => {
    return (proposal?.licenseeCode ?? '') + ' ' + (proposal?.licenseeNameKanji ?? '');
  }, [proposal?.licenseeCode, proposal?.licenseeNameKanji]);

  /** 申請日 */
  const applicantDate = useMemo(() => {
    if (!proposal?.applicationDatetime) {
      return '';
    }
    return dayjs(proposal.applicationDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD');
  }, [proposal?.applicationDatetime]);

  /** ステータス */
  const proposalStatus = useMemo(() => {
    return proposal != null ?
      Constants.Aniplex.ProposalStatusName[proposal.proposalStatus] ?? '' :
      '';
  }, [proposal]);

  // 作品名称
  const {
    propertySummaryName,
    prevPropertySummaryName,
  } = usePropertySummaryName(proposal);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>取引先名称</dt>
          <dd className="form-body">
            <div className="input-form wdt300">
              <input type="text" name="取引先名称"
                title="取引先名称は入力済みです"
                aria-label="取引先名称"
                disabled
                value={licenseeName} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請者名称</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="申請者名称"
                title="申請者名称は入力済みです"
                disabled
                aria-label="申請者名称"
                value={proposal?.applicantUserName ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt100">
              <input type="text" name="企画書No"
                title="企画書Noは入力済みです"
                disabled
                aria-label="企画書No"
                value={proposal?.proposalNo ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請日</dt>
          <dt className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="申請日"
                disabled
                value={applicantDate} />
            </div>
          </dt>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>企画申請ステータス</dt>
          <dd className="form-body">
            <div className="input-form wdt160">
              <input type="text" name="企画申請ステータス"
                title="企画申請ステータスは入力済みです"
                disabled
                aria-label="企画申請ステータス"
                value={proposalStatus} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>
            作品名称
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={propertySummaryName}
                  prevValue={prevPropertySummaryName} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="作品名称"
                title="作品名称を必ず選択してください"
                disabled
                value={propertySummaryName} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>
            企画件名
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal?.proposalTitle}
                  prevValue={proposal?.previousVersion?.proposalTitle} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="企画件名"
                title="企画件名を必ず入力してください"
                aria-label="企画件名"
                disabled
                value={proposal?.proposalTitle ?? ''} />
              <span className="attention">例：雑貨企画、物販催事企画、フィギュア企画、コンビニタイアップ企画</span>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '145px' }}>
            契約開始日
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal?.contractStartDate}
                  prevValue={proposal?.previousVersion?.contractStartDate} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="契約開始日"
                title='契約開始日'
                aria-label='契約開始日'
                disabled
                value={proposal?.contractStartDate ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ minWidth: '115px' }}>
            契約終了日
            {
              proposal?.previousVersion != null && (
                <ValueChangeStatus
                  value={proposal?.contractEndDate}
                  prevValue={proposal?.previousVersion?.contractEndDate} />
              )
            }
          </dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="契約終了日"
                title='契約終了日'
                aria-label='契約終了日'
                disabled
                value={proposal?.contractEndDate ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div>
        <dl className='pt10'>
          <dt className="form-name">
            自動契約更新
          </dt>
          <dd className="ml15">
            <input type="checkbox"
              id="check-auto-contract-update-req"
              name='自動契約更新'
              title='自動契約更新'
              disabled
              checked={proposal?.automaticContractUpdateReq ?? false} />
            <label htmlFor="check-auto" className="form-checkbox">
              1年ごとに契約延長する
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.automaticContractUpdateReq}
                    prevValue={proposal.previousVersion.automaticContractUpdateReq}
                    hideTooltip />
                )
              }
            </label>
          </dd>
        </dl>
      </div>

      {
        proposal?.rejectMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name attention" style={{ width: '145px' }}>差し戻し理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows={4}
                    name="差し戻し理由"
                    aria-label="差し戻し理由"
                    disabled
                    value={proposal.rejectMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        proposal?.cancelMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name attention" style={{ width: '145px' }}>取り下げ理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows={4}
                    name="取り下げ理由"
                    aria-label="取り下げ理由"
                    disabled
                    value={proposal.cancelMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        proposal?.permissionMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name attention" style={{ width: '145px' }}>更新許可理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows={4}
                    name="更新許可理由"
                    aria-label="更新許可理由"
                    disabled
                    value={proposal.permissionMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }

      {
        proposal?.suspendMessage && (
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name attention" style={{ width: '145px' }}>中止理由</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows={4}
                    name="中止理由"
                    aria-label="中止理由"
                    disabled
                    value={proposal.suspendMessage} />
                </div>
              </dd>
            </dl>
          </div>
        )
      }
    </>
  );
}

/**
 * 作品名称
 * @param {?Proposal} proposal 企画情報
 */
const usePropertySummaryName = (proposal) => {
  const propertyMst = useSelector(selectPropertyMst);

  /** 作品名称を取得する処理 */
  const getPropertyName = useCallback(
    /** @param {string|undefined} propertySummaryCode 作品コード */
    (propertySummaryCode) => {
      const found = propertyMst.find(p => p.propertySummaryCode === propertySummaryCode);
      if (!found) {
        return '';
      }
      return found.propertySummaryName;
    }, [propertyMst]);

  /** 作品名称 */
  const propertySummaryName = useMemo(() =>
    getPropertyName(proposal?.propertySummaryCode),
    [getPropertyName, proposal?.propertySummaryCode]);

  /** 前バージョンの作品名称 */
  const prevPropertySummaryName = useMemo(() =>
    getPropertyName(proposal?.previousVersion?.propertySummaryCode),
    [getPropertyName, proposal?.previousVersion?.propertySummaryCode]);

  return {
    propertySummaryName,
    prevPropertySummaryName,
  };
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
//#endregion typedef
