import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { selectAniplexUserMst } from "../../../slices/aniplex/masterSlice";
import { clearApiStatus, selectApiStatus, updateKikakuEmployeeNo } from "../../../slices/aniplex/proposalsSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { SearchableListBox } from "../../common/SearchableListBox";

/**
 * 企画担当者設定ポップアップ
 * @param {object} props
 * @param {Proposal} props.proposal 企画情報
 * @param {OnClose} props.onClose ポップアップを閉じるときのコールバック
 */
export const AssignUserPopup = ({ proposal, onClose }) => {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus).updateKikakuEmployeeNo;
  const aniplexUserMst = useSelector(selectAniplexUserMst);
  // 社員番号
  const [employeeNo, setEmployeeNo] = useState('');
  // エラーメッセージ
  const [messages, setMessages] = useState({
    /** 社員番号 */
    employeeNo: [],
  });

  useEffect(() => {
    setEmployeeNo(proposal.kikakuEmployeeNo);
  }, [proposal.kikakuEmployeeNo]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['employeeNo']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.employeeNo.length > 0) {
      if (focusError('employeeNo')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.employeeNo.length, needFocusError]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => apiStatus === 'loading', [apiStatus]);

  /** 氏名が設定されているユーザ */
  const namedUserList = useMemo(() => {
    return aniplexUserMst.filter(u => u.employeeName);
  }, [aniplexUserMst]);

  /**選択された社員 */
  const selectedUser = useMemo(() => {
    const targetEmployeeNo = employeeNo ?? proposal.employeeNo;
    const found = aniplexUserMst.find(i => i.employeeNo === targetEmployeeNo);
    if (!found) {
      return null;
    }
    return {
      label: found.employeeName,
      value: found.employeeNo,
    }
  }, [aniplexUserMst, employeeNo, proposal?.employeeNo]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      employeeNo: validateEmployeeNo(value),
    }));
    setEmployeeNo(value);
  }, []);

  /** 登録ボタン押下時のコールバック */
  const onSubmit = useCallback(() => {
    const errors = {
      employeeNo: validateEmployeeNo(employeeNo),
    };

    setMessages(prev => ({
      ...prev,
      ...errors,
    }));

    if (Object.values(errors).flat().length > 0) {
      setNeedFocusError(true);
      return;
    }

    // 担当者更新処理を呼び出す
    dispatch(updateKikakuEmployeeNo({
      proposalId: proposal.proposalId,
      updateDatetime: proposal.updateDatetime,
      employeeNo,
    }));
  }, [dispatch, employeeNo, proposal.proposalId, proposal.updateDatetime]);

  useEffect(() => {
    if (apiStatus === 'finish') {
      // API通信状況をクリアしてポップアップを閉じる
      dispatch(pushMessage('企画担当者を設定しました。'));
      dispatch(clearApiStatus('updateKikakuEmployeeNo'));
      onClose('submit');
      return;
    }
    if (apiStatus === 'error') {
      // エラーメッセージを表示してAPI通信状況をクリア
      dispatch(pushMessage('企画担当者の設定中にエラーが発生しました。'));
      dispatch(clearApiStatus('updateKikakuEmployeeNo'));
      return;
    }
  }, [apiStatus, dispatch, onClose]);

  /** API通信中のローディング表示 */
  const loading = useMemo(() => {
    if (apiStatus === 'loading') {
      return <LoadingOverlay />
    }
    return null;
  }, [apiStatus]);

  return (
    <>
      <FlexiblePopup onClose={() => onClose('close')}>
        <h4 className="popup-title">企画担当者設定</h4>

        <div className="l-form">
          <dl className="form-set">
            <dt className="form-name required">担当者</dt>
            <dd className="form-body" style={{ display: 'block' }}>
              <div className="input-form wdt300">
                <SearchableListBox
                  selectRef={formRefs.current.employeeNo}
                  isDisabled={formLocked}
                  value={selectedUser}
                  data={namedUserList}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  onChange={item => handleChange(item?.value)}
                  labelKey='employeeName'
                  valueKey='employeeNo' />
              </div>
              <ErrorMessageList messages={messages.employeeNo} />
            </dd>
          </dl>
        </div>

        <div className="btn-wrapper">
          <div className="btn label mt15 bg-yellow">
            <button
              disabled={formLocked}
              onClick={onSubmit}
            >設定</button>
          </div>
        </div>
      </FlexiblePopup>

      {loading}
    </>
  );
}

/**
 * 社員番号のバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateEmployeeNo(value) {
  const errors = [];

  if (isEmpty(value)) {
    return [getMessage('isNotEmpty')];
  }

  return errors;
}

//#region typedef
/**
 * @callback OnClose ポップアップを閉じるときのコールバック
 * @param {'close'|'submit'} btn 押されたボタン種別
 */
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
//#endregion typedef
