import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { Constants } from "../../../Constants";
import { immutableSort } from "../../../lib/util";
import { isEmpty } from "../../../lib/validator";
import { clearAnxUserList, clearApiStatus, fetchAnxUserList, selectAnxUserList, selectApiStatus } from "../../../slices/aniplex/aniplexusersSlice";
import { aniplexUser } from "../../common/headerList";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { PagingTable } from "../../common/table/PagingTable";
import { TableVisible } from "../../common/table/TableVisible";
import { AnxUserListSearchForm } from "./AnxUserListSearchForm"

/** ページャの1ページあたりの行数 */
const pageSize = 20;

/** 項目表示チェック状態の初期値 */
const checkParamDefault = Object.fromEntries(
  aniplexUser
    .map(prop => ([prop.id, true]))
);

/**
 * [A]ANIPLEXユーザー一覧画面
 */
export const AnxUserListPage = () => {
  const dispatch = useDispatch();
  const anxUserList = useSelector(selectAnxUserList);
  const apiStatus = useSelector(selectApiStatus).fetchAnxUserList;

  /** データ取得済みフラグ */
  const dataFetched = useMemo(() => apiStatus === 'finish', [apiStatus]);

  const [isOpen, setIsOpen] = useState(false);
  const [checkParam, setCheckParam] = useState(checkParamDefault);

  const [headers, setHeaders] = useState(aniplexUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortInfo, setSortInfo] = useState({
    sortKey: 'employeeNo',
    order: 'asc',
  });

  // マウント時にユーザ一覧を取得
  useEffect(() => {
    dispatch(fetchAnxUserList({}));
    return () => {
      // 画面離脱時にAPI通信状況をクリア
      dispatch(clearApiStatus('fetchAnxUserList'));
      dispatch(clearAnxUserList());
    }
  }, [dispatch]);

  /** ソート条件変更時のハンドラ */
  const onSortChange = useCallback((info) => {
    setSortInfo(info);
    // ソート変更時は1ページ目に戻る
    setCurrentPage(1);
  }, []);

  /** 検索実行時コールバック */
  const onSearch = useCallback((params) => {
    // 検索処理
    dispatch(fetchAnxUserList(params));
    // 1ページ目に戻る
    setCurrentPage(1);
  }, [dispatch]);

  /** 表示を切り替える押下時 */
  const onCheckApply = useCallback(() => {
    setHeaders(aniplexUser.filter(prop => checkParam[prop.id]));
  }, [checkParam]);

  /** ソート関数 */
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) return 1;
      if ((a[sortKey] || '') < (b[sortKey] || '')) return -1;
      return 0;
    };
  }, [sortInfo.sortKey]);

  /** ソート後のユーザリスト */
  const sortedUserList = useMemo(() => {
    const newList = immutableSort(anxUserList, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newList.reverse();
    }
    return newList;
  }, [anxUserList, sortFunc, sortInfo.order]);

  /** 表示用レコード */
  const records = useMemo(() => {
    return sortedUserList.map(user => ({
      ...user,
      employeeNo: (
        <Link to={`/aniplex/anxUserDetail/${user.employeeNo}`}>
          {user.employeeNo}
        </Link>
      ),
      role: Constants.Aniplex.UserRoleName[user.role] ?? '',
      regDatetime: dateFormat(user.regDatetime),
      loginDatetime: dateFormat(user.loginDatetime),
    }));
  }, [sortedUserList]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ANIPLEXユーザー一覧</h1>
      </div>

      <div className="mt30">
        <p className="btn bg-orange" style={{width: '160px'}}>
          <Link to="/aniplex/anxUserRegister">
            <i className="icn plus"></i>
            ユーザー登録
          </Link>
        </p>
      </div>

      <hr />

      <AnxUserListSearchForm
        onSearch={onSearch} />

      <TableVisible
        isOpen={isOpen}
        headers={aniplexUser}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />

      {
        dataFetched && (
          <PagingTable
            headers={headers}
            records={records}
            current={currentPage}
            pageSize={pageSize}
            sortParam={sortInfo}
            emptyMessage="検索条件と一致するANIPLEXユーザーが存在しません。"
            scrollable
            resizable
            onSortChange={onSortChange}
            onPageChange={setCurrentPage} />
        )
      }

      {
        apiStatus === 'loading' && (
          <LoadingOverlay />
        )
      }
    </div>
  )
}

/**
 * 日時文字列を整形する
 * @param {string} dateStr YYYY/MM/DD HH:mm:ss
 */
function dateFormat(dateStr) {
  if (isEmpty(dateStr)) {
    return '';
  }

  const obj = dayjs(dateStr, 'YYYY/MM/DD HH:mm:ss');
  const date = obj.format('YYYY/M/D');
  const time = obj.format('HH:mm');
  return (
    <>
      {date}<br />
      {time}
    </>
  )
}
