import dayjs from "dayjs"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  ProposalOverviewForm,
  validate as overviewValidate,
} from "./ProposalOverviewForm"
import {
  ProposalContentForm,
  validate as contentValidate
} from "./ProposalContentForm"
import {
  ProposalClosingMonthForm,
  validate as closingMonthValidate,
} from "./ProposalClosingMonthForm"
import { ProposalProductsForm } from "./ProposalProductsForm"
import {
  ProposalSurveyForm,
  validate as surveyValidate,
} from "./ProposalSurveyForm"
import {
  AssignUserForm,
  validate as assignUserErrValidate,
} from "./AssignUserForm"
import {
  ProposalNoticeForm,
  validate as proposalNoticeErrValidate,
} from "./ProposalNoticeForm"
import { Constants } from "../../../Constants"
import { MessagePopup } from "../../common/MessagePopup"
import { useDispatch, useSelector } from "react-redux"
import { selectMyself } from "../../../slices/licensee/usersSlice"
import { productValidate } from "../../common/ProductDetail"
import { selectRypInputRequire } from "../../../slices/licensee/masterSlice"
import { ErrorMessageList } from "../../common/ErrorMessageList"
import { ProposalCancelPopup } from "./ProposalCancelPopup"
import { useFocusError } from "../../../lib/hooks/common"
import { useNavigate } from "react-router-dom"
import { pushMessage } from "../../../slices/licensee/utilSlice"
import { getRypInputFlg } from "../../../lib/royalty"
import { duplicateImage } from "../../../lib/api/licensee"
import { LoadingOverlay } from "../../common/LoadingOverlay"
import { PermitUpdatePopup } from "./PermitUpdatePopup"
import { MessageHistoryArea } from "./MessageHistoryArea"
import { ProposalRequestPopup } from "./ProposalRequestPopup"
import { useTimeoutWarningPopup } from "../TimeoutWarningPopup";

/** フォーム項目の順序 */
const formPropOrder = [
  // 作品コード
  'propertySummaryCode',
  // 企画件名
  'proposalTitle',
  // 契約開始日
  'contractStartDate',
  // 契約終了日
  'contractEndDate',
  // 発売開始希望日
  'proposalLaunchDate',
  // 企画説明
  'proposalDetail',
  // その他備考
  'proposalRemarks',
  // 提案資料等
  'proposalAttachmentList',
  // ロイヤリティ報告締め月
  'closingMonth',
  // 商品リスト
  'productList',
  // WEB通販比率
  'shareWeb',
  // 卸売比率
  'shareWholesale',
  // 自社EC有無
  'shareOwnEcFlag',
  // Web通販比率_自社EC
  'shareOwnEc',
  // Web通販比率_他社EC
  'shareOtherEc',
  // 卸売比率_アニメ専門店
  'shareAnimation',
  // 卸売比率_家電量販店
  'shareKaden',
  // 卸売比率_CVS
  'shareCvs',
  // 卸売比率_GMS
  'shareGms',
  // 卸売比率_スーパーマーケット
  'shareSm',
  // 卸売比率_ドラッグストア
  'shareDrug',
  // 卸売比率_ホビー
  'shareHobby',
  // 卸売比率_オンライン
  'shareOnline',
  // 卸売比率_その他
  'shareOther',
  // 卸売備考（流通先名、その他内訳）
  'shareWholesaleRemarks',
  // メイン・ターゲット
  'target',
  // 対流通施策
  'promotionChannel',
  // 対ユーザー施策
  'promotionUser',
  // WEB対策
  'promotionWeb',
  // Twitter情報
  'promotionTwitter',
  // 展示会・カタログ情報
  'promotionDisplay',
  // 企画担当者ユーザーID
  'kikakuUserId',
  // 通知先
  'noticeUserIdList',
];

/**
 * 企画書申請画面のフォーム部分
 * @param {object} props
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {OnSubmit} props.onSubmit 保存系ボタン押下時のコールバック
 * @param {OnDelete} props.onDelete 削除ボタン押下時のコールバック
 * @param {OnCancel} props.onCancel 取り下げボタン押下時のコールバック
 * @param {OnPermitUpdate} props.onPermitUpdate 更新開始ボタン押下時のコールバック
 * @param {boolean} props.isNew 新規作成モードフラグ
 * @param {LicenseeUser[]} props.licenseeUsers ライセンシーユーザー一覧
 * @param {?ProposalDetail} props.loadedData 企画書
 * @param {?string} props.updateDatetime 最終更新日時
 * @returns
 */
export const ProposalDetailForm = ({
  formLocked,
  onSubmit,
  onDelete,
  onCancel,
  onPermitUpdate,
  isNew,
  licenseeUsers,
  loadedData,
  updateDatetime,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myself = useSelector(selectMyself);
  const reqRyp = useSelector(selectRypInputRequire);
  // 申請確認ポップアップ関連
  const [applyConfirm, setApplyConfirm] = useState({
    showFlg: false,
    onClose: null,
  });
  // 削除確認ポップアップ関連
  const [deleteConfirm, setDeleteConfirm] = useState({
    showFlg: false,
    onClose: null,
  });
  // 取り下げ確認ポップアップ
  const [cancelConfirm, setCancelConfirm] = useState({
    showFlg: false,
    onClose: null,
  });
  // 複製確認ポップアップ
  const [copyConfirm, setCopyConfirm] = useState({
    showFlg: false,
    onClose: null,
  });
  // 以前のisNewの値
  const [prevIsNew, setPrevIsNew] = useState(isNew);
  // 強制バリデートフラグ
  const [forceValidateFlg, setForceValidateFlg] = useState(false);
  // フォームデータ
  const [formData, setFormData] = useState(() => getInitialFormData());
  // バリデートエラー
  const [validateErrors, setValidateErrors] = useState({});
  // 許諾商品リスト
  /** @type {[ProposalProduct[], (data: ProposalProduct[]) => void]} */
  const [productList, setProductList] = useState([]);
  /**
   * 強制バリデートを要求するプロパティ
   * @type {[keyof formData, (prop: keyof formData) => void]}
   */
  const [requestValidateProp, setRequestValidateProp] = useState(null);

  // 新規追加された提案資料リスト
  const [newFileList, setNewFileList] = useState([]);
  // アップロード済み提案資料リスト
  const [uploadedFileList, setUploadedFileList] = useState([]);

  // コピーする企画のデータ
  const [copyingProposalData, setCopyingProposalData] = useState(null);
  const [copyingProductList, setCopyingProductList] = useState([]);

  // 通信中フラグ
  const [isLoading, setIsLoading] = useState(false);

  /** 編集可能フラグ */
  const isEditable = useMemo(() => {
    return isNew || checkEditable(loadedData);
  }, [isNew, loadedData]);

  /** 削除可能フラグ */
  const canDelete = useMemo(() => {
    return (loadedData?.revision ?? 0) === 0;
  }, [loadedData]);

  /** 取り下げ可能フラグ */
  const canCancel = useMemo(() => {
    return Constants.Licensee.ProposalStatus.Requesting === formData.proposalStatus;
  }, [formData.proposalStatus]);

  /** 更新開始可能フラグ */
  const canPermitUpdate = useCanPermitUpdate({ proposalStatus: formData.proposalStatus });

  /** 複製可能フラグ */
  const canCopy = useMemo(() => {
    return checkCopyable(loadedData);
  }, [loadedData]);

  // タイムアウトの警告ポップアップ (編集可能時のみ表示)
  const [timeoutWarningPopup, resetTimer] = useTimeoutWarningPopup(isEditable);

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);

  /**
   * 項目にフォーカスするエラーの種別
   * @type {[?'apply'|'tmp', (type: ?'apply'|'tmp') => void]}
   */
  const [focusErrorType, setFocusErrorType] = useState(null);

  useEffect(() => {
    if (focusErrorType == null) {
      return;
    }

    // エラーが出ているときは対象要素へフォーカスする
    for (let prop of formPropOrder) {
      if ((validateErrors[prop]?.[focusErrorType] ?? []).length > 0) {
        if (focusError(prop)) {
          setFocusErrorType(null);
          break;
        }
      }
    }
  }, [focusError, focusErrorType, validateErrors]);

  useEffect(() => {
    // URL変更で編集モードから新規モードに変更されたときにフォーム内容をリセットする
    if (prevIsNew !== isNew) {
      if (isNew) {
        if (copyingProposalData != null) {
          // 企画複製時は複製元のデータを設定
          setFormData(copyingProposalData);
          setProductList(copyingProductList);
          setCopyingProposalData(null);
          setCopyingProductList([]);
        } else {
          setFormData(getInitialFormData());
          setProductList([]);
        }
        setUploadedFileList([]);
      }
      setPrevIsNew(isNew);
    }
  }, [copyingProductList, copyingProposalData, isNew, prevIsNew]);

  useEffect(() => {
    if (isNew && myself) {
      // 新規モードの場合はログインユーザーの情報を取引先名と申請者名と企画担当者名に設定
      setFormData(prevData => ({
        ...prevData,
        applicantUserName: myself.username,
        licenseeCode: myself.licenseeCode,
        licenseeNameKanji: myself.licenseeNameKanji,
        kikakuUserId: myself.userId,
        kikakuUserName: myself.username,
      }));
    }
  }, [isNew, myself]);

  useEffect(() => {
    // 更新モードかつデータが読み込まれた場合は
    // それをフォームデータに反映
    if (!isNew && loadedData != null) {
      setFormData(prevData => {
        const tmp = {
          ...prevData,
        };
        Object.entries(loadedData).forEach(([prop, val]) => {
          if (val != null) {
            tmp[prop] = val;
          }
        });
        // 編集時で、企画担当者が存在しない場合は申請者を初期表示
        if (checkEditable(loadedData) && tmp.kikakuUserId === '' && loadedData.applicantUserId != null) {
          tmp.kikakuUserId = loadedData.applicantUserId;
          tmp.kikakuUserName = loadedData.applicantUserName;
        }
        return tmp;
      });
      setProductList([...(loadedData.productList ?? [])]);
      setUploadedFileList([...(loadedData.proposalAttachmentList ?? [])]);
    }
  }, [isNew, loadedData]);

  /**
   * フォームの入力内容変更時のハンドラ
   * @type {(prop: keyof formData, value: *) => void}
   */
  const handleChange = useCallback((prop, value) => {
    if (detectPeriodChange(prop, value, formData)) {
      // 許諾商品の期間部分の入力内容をクリアする
      setProductList(prevList => clearProductPeriodData(prevList))
    }

    if (['contractStartDate', 'contractEndDate'].includes(prop)) {
      // 契約開始日、契約終了日が変更された場合は報告締め月もバリデートする
      setRequestValidateProp('closingMonth');
    }

    setFormData(preData => ({
      ...preData,
      [prop]: value
    }));
  }, [formData])

  /**
   * バリデート実行イベントのハンドラ
   * @type {(prop: keyof formData, errors: string[]) => void}
   */
  const onValidated = useCallback((prop, errors) => {
    setRequestValidateProp(prev => {
      if (prop === prev) {
        // 強制バリデート対象のプロパティがバリデートされたらバリデート対象をクリア
        return null;
      }
      return prev;
    });
    setValidateErrors(prevErrors => ({
      ...prevErrors,
      [prop]: errors,
    }));
  }, []);

  /** 期のリスト */
  const periodList = useMemo(() => {
    if (!isEditable || loadedData?.updateLimitFlag) {
      // 契約期間が変更不可の場合はAPIから取得した内容をそのまま返す
      return loadedData?.periodList ?? [];
    }
    const tmpPeriodList = calculatePeriodList(formData);
    // 期をリセットするタイミングで生産数／販売数を利用しない商品の期もリセット
    if (isEditable) {
      setProductList(p =>
        p.map(pr => {
          let newPeriodList = pr.periodList;
          if ([Constants.RoyaltyPattern.SalesPriceRate, Constants.RoyaltyPattern.FixedPrice].includes(pr.rypId)) {
            newPeriodList = (tmpPeriodList ?? []).map(prd => ({
              period: prd.period,
              planProduction: null,
              planSales: null,
            }));
          }
          return {
            ...pr,
            'periodList' : newPeriodList,
          }
        })
      )
    }
    return tmpPeriodList;
  }, [formData, isEditable, loadedData?.periodList, loadedData?.updateLimitFlag])

  /** 許諾商品リスト変更時のハンドラ */
  const handleProductChange = useCallback(data => {
    setProductList(prevList => {
      const deleteList = data.filter(({product}) => product == null)
        .map(({idx}) => prevList[idx])
        .filter(i => i !== undefined);
      const updateList = data.filter(({product}) => product != null)
        .map(({idx, product}) => ({
          target: prevList[idx],
          product,
        }));
      const addList = data.filter(({idx}) => idx == null)
        .map(({product}) => product);

      const newList = prevList
        // 削除対象を削除
        .filter(prev => !deleteList.includes(prev))
        // 更新対象を置き換え
        .map(prev => {
          const found = updateList.find(({target}) => prev === target);
          return found ? found.product : prev;
        });
      // 追加対象を追加
      newList.push(...addList);

      return newList;
    });
  }, []);

  /** 申請ボタン押下時のハンドラ */
  const onApplyClick = useCallback(() => {
    setForceValidateFlg(true);
    const errors = Object.values(validateAll(formData, productList, reqRyp)).map(e => e.apply ?? []).flat();
    if (errors.length > 0) {
      // バリデートエラーがあるときはAPIは呼ばない
      // エラー項目にフォーカスさせる
      setFocusErrorType('apply');
      return;
    }

    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    setApplyConfirm({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          /** @type {PostProposalParam} */
          const param = {
            ...formData,
            messageContent,
            periodList,
            productList,
            proposalStatus: Constants.Licensee.ProposalStatus.Requesting,
            proposalAttachmentList: [
              ...uploadedFileList,
              ...newFileList,
            ],
            noticeUserIdList: formData.noticeUserIdList.map(u => u.userId),
          }
          onSubmit(param);
        }
        setApplyConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [formData, newFileList, uploadedFileList, reqRyp, formLocked, periodList, productList, onSubmit]);

  // 一時保存・更新許可のAPI呼び出し成功時にセッションタイムアウト警告ポップアップのタイマーをリセットする
  const onSaveComplete = useCallback(() => {
    resetTimer();
  }, [resetTimer]);

  /** 一時保存ボタン押下時のハンドラ */
  const onSaveClick = useCallback(() => {
    setForceValidateFlg(true);
    const errors = Object.values(validateAll(formData, productList, reqRyp)).map(e => e.tmp ?? []).flat();
    if (errors.length > 0) {
      // バリデートエラーがあるときはAPIは呼ばない
      // エラー項目にフォーカスさせる
      setFocusErrorType('tmp');
      return;
    }

    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    const param = {
      ...formData,
      periodList,
      productList,
      proposalStatus: Constants.Licensee.ProposalStatus.TemporarySaved,
      proposalAttachmentList: [
          ...uploadedFileList,
          ...newFileList,
      ],
      noticeUserIdList: formData.noticeUserIdList.map(u => u.userId),
    }
    // API 完了時、resetTimerが呼ばれる
    onSubmit(param, onSaveComplete);
  }, [formData, newFileList, uploadedFileList, formLocked, onSubmit, periodList, productList, reqRyp, onSaveComplete]);

  /** 削除ボタン押下時のハンドラ */
  const onDeleteClick = useCallback(() => {
    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    setDeleteConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          onDelete(formData);
        }
        setDeleteConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    })
  }, [formData, formLocked, onDelete]);

  /** 取り下げボタン押下時のハンドラ */
  const onCancelClick = useCallback(() => {
    setCancelConfirm({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          onCancel({messageContent, proposal: formData});
        }
        setCancelConfirm({
          showFlg: false,
          onClose: null,
        })
      }
    })
  }, [formData, onCancel]);

  // 更新開始の確認ポップアップ
  const {
    onPermitUpdateClick,
    permitUpdateConfirm,
  } = usePermitUpdateConfirm({ onPermitUpdate, onSaveComplete });

  /** 企画複製処理 */
  const copyProposal = useCallback(async () => {
    const imageNoList = productList
      .flatMap(prd => prd.renderingImageList)
      .map(img => img.renderingImageNo);

    let duplicateImageList = [];
    if (imageNoList.length > 0) {
      try {
        setIsLoading(true);
        const duplicateResult = await duplicateImage(imageNoList, { suppressError: true });
        duplicateImageList = duplicateResult.duplicateImageList;
      } catch {
        dispatch(pushMessage('システムエラーが発生したため、企画書の複製を中止しました。企画書の複製を再実行してください。再実行してもエラーになる場合はお問い合わせください。'));
        return;
      } finally {
        setIsLoading(false);
      }
    }

    const initialFormData = getInitialFormData();
    const newProposal = {
      ...initialFormData,
      // 作品名称
      propertySummaryCode: loadedData.propertySummaryCode,
      // 企画件名
      proposalTitle: loadedData.proposalTitle,
      // 発売開始希望日
      proposalLaunchDate: loadedData.proposalLaunchDate,
      // 企画説明
      proposalDetail: loadedData.proposalDetail,
      // その他備考
      proposalRemarks: loadedData.proposalRemarks,
      // ロイヤリティ報告締め月
      ryReq1: loadedData.ryReq1,
      ryReq2: loadedData.ryReq2,
      ryReq3: loadedData.ryReq3,
      ryReq4: loadedData.ryReq4,
      ryReq5: loadedData.ryReq5,
      ryReq6: loadedData.ryReq6,
      ryReq7: loadedData.ryReq7,
      ryReq8: loadedData.ryReq8,
      ryReq9: loadedData.ryReq9,
      ryReq10: loadedData.ryReq10,
      ryReq11: loadedData.ryReq11,
      ryReq12: loadedData.ryReq12,
      // WEB通販比率
      shareWeb: loadedData.shareWeb,
      // 卸売比率
      shareWholesale: loadedData.shareWholesale,
      // 自社EC有無
      shareOwnEcFlag: loadedData.shareOwnEcFlag,
      // Web通販比率_自社EC
      shareOwnEc: loadedData.shareOwnEc,
      // Web通販比率_他社EC
      shareOtherEc: loadedData.shareOtherEc,
      // 卸売比率_アニメ専門店
      shareAnimation: loadedData.shareAnimation,
      // 卸売比率_家電量販店
      shareKaden: loadedData.shareKaden,
      // 卸売比率_CVS
      shareCvs: loadedData.shareCvs,
      // 卸売比率_GMS
      shareGms: loadedData.shareGms,
      // 卸売比率_スーパーマーケット
      shareSm: loadedData.shareSm,
      // 卸売比率_ドラッグストア
      shareDrug: loadedData.shareDrug,
      // 卸売比率_ホビー
      shareHobby: loadedData.shareHobby,
      // 卸売比率_オンライン
      shareOnline: loadedData.shareOnline,
      // 卸売比率_その他
      shareOther: loadedData.shareOther,
      // 流通先名
      shareWholesaleRemarks: loadedData.shareWholesaleRemarks,
      // メインターゲット
      target: loadedData.target,
      // 対流通施策
      promotionChannel: loadedData.promotionChannel,
      // 対ユーザー施策
      promotionUser: loadedData.promotionUser,
      // WEB施策
      promotionWeb: loadedData.promotionWeb,
      // Twitter情報
      promotionTwitter: loadedData.promotionTwitter,
      // 展示会・カタログ情報
      promotionDisplay: loadedData.promotionDisplay,
      // 企画担当者ユーザーID
      kikakuUserId: loadedData.kikakuUserId,
      // 通知先
      noticeUserIdList: loadedData.noticeUserIdList ?? [],
    };
    setCopyingProposalData(newProposal);

    const newProductList = productList.map(prd => {
      const newRenderingImageList = (prd.renderingImageList ?? []).map(img => {
        const copyInfo = duplicateImageList.find(a => String(a.renderingImageNoBefore) === String(img.renderingImageNo));
        return {
          renderingImageNo: copyInfo?.renderingImageNoAfter ?? null,
        };
      }).filter(img => img.renderingImageNo != null);

      // 契約期間はデフォルト値＋RY報告締め月は複製元の値 になるので、それに合わせて0埋めのデータに変更
      const periodLength = calculatePeriodList(newProposal).length;
      const inputFlg = getRypInputFlg(prd.rypId, 'licensee');
      const newPeriodList = new Array(periodLength).fill(null).map((_, i) => ({
        period: i+1,
        planProduction: inputFlg.production ? 0 : null,
        planSales: inputFlg.sales ? 0 : null,
      }));

      // ロイヤリティ金額リセット
      const newPlanRyAmount = [
        Constants.RoyaltyPattern.SalesPriceRate, Constants.RoyaltyPattern.FixedPrice
      ].includes(prd.rypId) ? prd.planRyAmount : 0;

      return {
        ...prd,
        periodList: newPeriodList,
        renderingImageList: newRenderingImageList,
        planRyAmount: newPlanRyAmount,
        // 商品コード、品番、ステータスはクリア
        productId: null,
        productNo: null,
        productStatus: null,
        // 自由入力欄は複製対象外
        productOption1: '',
        productOption2: '',
        productOption3: '',
      }
    });
    setCopyingProductList(newProductList);

    navigate('/licensee/proposalDetail');
  }, [productList, navigate, loadedData, dispatch]);

  /** 企画複製ボタン押下時のハンドラ */
  const onCopyClick = useCallback(() => {
    if (isLoading) {
      // 連打対策
      return;
    }

    setCopyConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          // 企画の複製
          copyProposal();
        }
        setCopyConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [copyProposal, isLoading]);

  return (
    <>
      <ProposalOverviewForm
        data={formData}
        formLocked={formLocked || !isEditable}
        formRefs={formRefs}
        onChange={handleChange}
        onValidated={onValidated}
        productList={productList}
        validateAllFlg={forceValidateFlg} />

      <ProposalContentForm
        data={formData}
        formLocked={formLocked || !isEditable}
        formRefs={formRefs}
        newFileList={newFileList}
        setNewFileList={setNewFileList}
        uploadedFileList={uploadedFileList}
        setUploadedFileList={setUploadedFileList}
        onChange={handleChange}
        onValidated={onValidated}
        validateAllFlg={forceValidateFlg} />

      <ProposalClosingMonthForm
        data={formData}
        formLocked={formLocked}
        formRefs={formRefs}
        isEditable={isEditable}
        onChange={handleChange}
        onValidated={onValidated}
        productList={productList}
        validateAllFlg={forceValidateFlg}
        requestValidateProp={requestValidateProp} />

      <ProposalProductsForm
        productList={productList}
        periodList={periodList}
        formLocked={formLocked}
        formRefs={formRefs}
        isEditable={isEditable}
        onChange={handleProductChange}
        onValidated={onValidated}
        validateAllFlg={forceValidateFlg}
        validateErrors={validateErrors} />

      <ProposalSurveyForm
        data={formData}
        formLocked={formLocked || !isEditable}
        formRefs={formRefs}
        onChange={handleChange}
        onValidated={onValidated}
        validateAllFlg={forceValidateFlg} />

      <AssignUserForm
        data={formData}
        updateDatetime={updateDatetime}
        formLocked={formLocked}
        formRefs={formRefs}
        isNew={isNew}
        licenseeUsers={licenseeUsers}
        onChange={handleChange}
        onValidated={onValidated}
        validateErrors={validateErrors}
        validateAllFlg={forceValidateFlg} />

      <ProposalNoticeForm
        data={formData}
        updateDatetime={updateDatetime}
        formLocked={formLocked}
        formRefs={formRefs}
        isNew={isNew}
        licenseeUsers={licenseeUsers}
        onChange={handleChange}
        onValidated={onValidated}
        validateErrors={validateErrors} />

      <MessageHistoryArea
        proposalDetail={loadedData} />

      <div className="mt30 pt30 dif j-between bt-solid-gray" style={{ alignItems: 'end' }}>
      {
        isEditable ? (
          <>
            <div className="l-buttons" style={{ alignItems: 'end' }}>
              <div>
                <ErrorMessageList messages={validateErrors?.proposalAttachment ?? []} />
                <ErrorMessageList messages={validateErrors?.products?.apply ?? []} />
                <p className="btn bg-green" style={{ width: '203px' }}>
                  <button
                    disabled={formLocked}
                    onClick={onApplyClick}
                  ><i className="icn check"></i>企画書を申請する</button>
                </p>
              </div>
              <p className="btn c-blue ml10" style={{ width: '160px' }}>
                <button
                  disabled={formLocked}
                  onClick={onSaveClick}
                ><i className="icn save"></i>一時保存</button>
              </p>
            </div>
            {
              canDelete ? (
                <p className="btn c-pink" style={{ width: '89px' }}>
                  <button
                    disabled={formLocked}
                    onClick={onDeleteClick}
                  ><i className="icn cross"></i>削除</button>
                </p>
              ) : null
            }
          </>
        ) : (
          <div className="l-buttons" style={{ alignItems: 'end' }}>
            <p className="btn c-pink ml10" style={{ width: '160px' }}>
              <button
                disabled={!canCancel}
                onClick={onCancelClick}
              ><i className=""></i>取り下げ</button>
            </p>

            <p className="btn bg-pink ml10" style={{ width: '200px' }}>
              <button
                disabled={!canPermitUpdate}
                onClick={onPermitUpdateClick}
              >企画書の更新開始</button>
            </p>

            <p className="btn bg-pink ml10" style={{ width: '200px' }}>
              <button
                disabled={!canCopy}
                onClick={onCopyClick}
              >複製して新規企画書を作成</button>
            </p>
          </div>

        )

      }
      </div>

      {
        applyConfirm.showFlg && (
          <ProposalRequestPopup onClose={applyConfirm.onClose} />
        )
      }

      {
        deleteConfirm.showFlg ?
          <MessagePopup
            message="企画書を削除します。よろしいですか？"
            btn={{ ok: '削除', cancel: 'キャンセル' }}
            btnClass='bg-pink'
            onClose={deleteConfirm.onClose} />
          : null
      }

      {
        cancelConfirm.showFlg ?
          <ProposalCancelPopup onClose={cancelConfirm.onClose} />
          : null
      }

      {
        permitUpdateConfirm.showFlg && (
          <PermitUpdatePopup onClose={permitUpdateConfirm.onClose} />
        )
      }

      {
        copyConfirm.showFlg && (
          <MessagePopup
            message="企画書の内容を複製して新しい企画書を作成しますか？"
            btn={{ ok: '作成', cancel: 'キャンセル' }}
            btnClass='bg-pink'
            onClose={copyConfirm.onClose} />
        )
      }

      {
        isLoading && <LoadingOverlay />
      }

      {
        isEditable && timeoutWarningPopup
      }
    </>
  );
}

/**
 * 更新開始可能フラグ
 * @param {object} params
 * @param {ValueOf<ProposalStatus>} params.proposalStatus 企画ステータス
 */
const useCanPermitUpdate = ({ proposalStatus }) => {
  // 承認済みと契約終了（未報告）の場合のみ更新開始可能
  return useMemo(() =>
    proposalStatus === Constants.Licensee.ProposalStatus.Approved
      || proposalStatus === Constants.Licensee.ProposalStatus.FinishNotReported,
    [proposalStatus]);
}

/**
 * 更新開始確認ポップアップ
 * @param {object} params
 * @param {(messageContent: string) => void} params.onPermitUpdate
 */
const usePermitUpdateConfirm = ({ onPermitUpdate, onSaveComplete }) => {
  // 表示フラグ
  const [showFlg, setShowFlg] = useState(false);

  /** ポップアップが閉じるときのコールバック */
  const onClose = useCallback(
    /**
     * @param {'ok'|'cancel'} btn 押されたボタン
     * @param {string} [messageContent] 更新理由
     */
    (btn, messageContent) => {
      if (btn === 'ok') {
        onPermitUpdate(messageContent, onSaveComplete);
      }
      setShowFlg(false);
    }, [onPermitUpdate, onSaveComplete]);

  /** ポップアップ情報 */
  const permitUpdateConfirm = useMemo(() => ({
    showFlg,
    onClose,
  }), [onClose, showFlg]);

  /** 更新開始ボタン押下時のハンドラ */
  const onPermitUpdateClick = useCallback(() => {
    setShowFlg(true);
  }, []);

  return {
    permitUpdateConfirm,
    onPermitUpdateClick,
  };
}

/**
 * フォームデータの初期値を取得する
 * @returns {FormData} フォームデータの初期値
 */
function getInitialFormData() {
  const contractStartDate = dayjs(getQuarterFirstDate());

  return {
    /** 作品コード */
    propertySummaryCode: '',
    /** 企画件名 */
    proposalTitle: '',
    /** 契約開始日 */
    contractStartDate: contractStartDate.format('YYYY/MM/DD'),
    /** 契約終了日 */
    contractEndDate: contractStartDate.add(1, 'year').subtract(1, 'day').format('YYYY/MM/DD'),
    /** 発売開始希望日 */
    proposalLaunchDate: '',
    /** 企画説明 */
    proposalDetail: '',
    /** その他備考 */
    proposalRemarks: '',
    /** ロイヤリティ報告区分 */
    ryReportCategory: Constants.Licensee.RyReportCategory.Monthly,
    /** ロイヤリティ報告締め月_1月 */
    ryReq1: false,
    /** ロイヤリティ報告締め月_2月 */
    ryReq2: false,
    /** ロイヤリティ報告締め月_3月 */
    ryReq3: false,
    /** ロイヤリティ報告締め月_4月 */
    ryReq4: false,
    /** ロイヤリティ報告締め月_5月 */
    ryReq5: false,
    /** ロイヤリティ報告締め月_6月 */
    ryReq6: false,
    /** ロイヤリティ報告締め月_7月 */
    ryReq7: false,
    /** ロイヤリティ報告締め月_8月 */
    ryReq8: false,
    /** ロイヤリティ報告締め月_9月 */
    ryReq9: false,
    /** ロイヤリティ報告締め月_10月 */
    ryReq10: false,
    /** ロイヤリティ報告締め月_11月 */
    ryReq11: false,
    /** ロイヤリティ報告締め月_12月 */
    ryReq12: false,
    /** WEB通販比率 */
    shareWeb: 0,
    /** 卸売比率 */
    shareWholesale: 0,
    /** 自社EC有無 */
    shareOwnEcFlag: false,
    /** Web通販比率_自社EC */
    shareOwnEc: 0,
    /** Web通販比率_他社EC */
    shareOtherEc: 0,
    /** 卸売比率_アニメ専門店 */
    shareAnimation: 0,
    /** 卸売比率_家電量販店 */
    shareKaden: 0,
    /** 卸売比率_CVS */
    shareCvs: 0,
    /** 卸売比率_GMS */
    shareGms: 0,
    /** 卸売比率_スーパーマーケット */
    shareSm: 0,
    /** 卸売比率_ドラッグストア */
    shareDrug: 0,
    /** 卸売比率_ホビー */
    shareHobby: 0,
    /** 卸売比率_オンライン（くじ、プライズ、電子書籍） */
    shareOnline: 0,
    /** 卸売比率_その他 */
    shareOther: 0,
    /** 卸売備考（流通先名、その他内訳） */
    shareWholesaleRemarks: '',
    /** メイン・ターゲット */
    target: '',
    /** 対流通施策 */
    promotionChannel: '',
    /** 対ユーザー施策 */
    promotionUser: '',
    /** WEB対策 */
    promotionWeb: '',
    /** Twitter情報 */
    promotionTwitter: '',
    /** 展示会・カタログ情報 */
    promotionDisplay: '',
    /** 企画担当者 */
    kikakuUserId: '',
    /** 通知先 */
    noticeUserIdList: [],
    /** 申請日時(YYYY/MM/DD HH:mm:ss) */
    applicationDatetime: '',
    /** 自動契約更新リクエスト */
    automaticContractUpdateReq: false,
  };
}

/**
 * フォームの全内容をバリデートする
 * @param {*} formData
 * @param {*} productList
 * @param {*} reqRyp
 */
function validateAll(formData, productList, reqRyp) {
  const errors = {};

  Object.entries(formData).forEach(([prop, value]) => {
    const overviewErr = overviewValidate(prop, value, formData);
    const contentErr = contentValidate(prop, value);
    const surveyErr = surveyValidate(prop, value);
    const assignUserErr = assignUserErrValidate(prop, value);
    const proposalNoticeErr = proposalNoticeErrValidate(prop, value);
    errors[prop] = {
      tmp: [
        ...errors[prop]?.tmp ?? [],
        ...overviewErr.tmp,
        ...contentErr.tmp,
        ...surveyErr.tmp,
        ...assignUserErr.tmp,
        ...proposalNoticeErr.tmp,
      ],
      apply: [
        ...errors[prop]?.apply ?? [],
        ...overviewErr.apply,
        ...contentErr.apply,
        ...surveyErr.apply,
        ...assignUserErr.apply,
        ...proposalNoticeErr.apply,
      ],
    };
  });

  const closingMonthErrors = closingMonthValidate(formData);
  Object.entries(closingMonthErrors).forEach(([prop, val]) => {
    if (closingMonthErrors.hasOwnProperty(prop)) {
      errors[prop] = {
        tmp: [
          errors[prop]?.tmp ?? [],
          val.tmp,
        ]
      }
      errors[prop] = Array.isArray(errors[prop]) ?
        [...errors[prop], ...val] :
        val;
    }
  });
  errors.closingMonth = {
    tmp: Object.values(closingMonthErrors).flatMap(e => e.tmp),
    apply: Object.values(closingMonthErrors).flatMap(e => e.apply),
  };

  // 許諾商品バリデート
  if (productList.length === 0) {
    errors.productList = {
      tmp: [],
      apply: [
        '商品を設定してください'
      ],
    };
  }
  productList.forEach((product) => {
    const productErrors = productValidate(product, reqRyp);
    Object.entries(productErrors?.apply).forEach(([prop, val]) => {
      errors[prop] = {
        apply: [
          ...errors[prop]?.apply ?? [],
          Array.isArray(val) ? [...val] : Object.values(val)
        ]
      }
    });
    Object.entries(productErrors?.tmp).forEach(([prop, val]) => {
      errors[prop] = {
        tmp : [
          ...errors[prop]?.tmp ?? [],
          ...val
        ]
      }
    });
    errors.productList = {
      tmp: [
        ...errors.productList?.tmp ?? [],
        ...Object.values(productErrors?.tmp ?? {}).flat(),
      ],
      apply: [
        ...errors.productList?.apply ?? [],
        ...Object.values(productErrors?.apply ?? {}).flat(),
      ],
    };
  });

  return errors;
}

/**
 * 対象の企画情報が編集可能な状態か判定する
 * @param {ProposalDetail} proposal 判定対象の企画情報
 * @returns {boolean} 編集可能状態の場合はtrue
 */
function checkEditable(proposal) {
  // ステータスが一時保存、差し戻し中、取り下げ、更新中の場合は編集可能
  const editableStatus = [
    Constants.Licensee.ProposalStatus.TemporarySaved,
    Constants.Licensee.ProposalStatus.Rejected,
    Constants.Licensee.ProposalStatus.Canceled,
    Constants.Licensee.ProposalStatus.Permitted,
  ];
  if (editableStatus.includes(proposal?.proposalStatus)) {
    return true;
  }

  return false;
}

/**
 * 対象の企画情報が複製可能な状態か判定する
 * @param {import("../../../slices/licensee/proposalsSlice").ProposalDetail} proposal proposa
 */
function checkCopyable(proposal) {
  // 編集不可かつ
  // ステータスが承認済み、中止、終了の場合は複製可能
  const copyableStatus = [
    Constants.Licensee.ProposalStatus.Approved,
    Constants.Licensee.ProposalStatus.Suspended,
    Constants.Licensee.ProposalStatus.FinishNotReported,
    Constants.Licensee.ProposalStatus.Finished,
  ];
  if (!checkEditable(proposal) && copyableStatus.includes(proposal?.proposalStatus)) {
    return true;
  }

  return false;
}

/**
 * 契約期間が変更されたかを判定する
 * @param {keyof orgData} prop 変更されたプロパティ名
 * @param {*} value 変更後の値
 * @param {object} orgData 変更前のデータ
 * @returns {boolean} 契約期間が変更された場合はtrue
 */
function detectPeriodChange(prop, value, orgData) {
  const targetProps = [
    'contractStartDate',
    'contractEndDate',
    'ryReq1',
    'ryReq2',
    'ryReq3',
    'ryReq4',
    'ryReq5',
    'ryReq6',
    'ryReq7',
    'ryReq8',
    'ryReq9',
    'ryReq10',
    'ryReq11',
    'ryReq12',
    'ryReportCategory',
  ]
  if (targetProps.includes(prop) && value !== orgData[prop]) {
    return true;
  }

  return false;
}

/**
 * 現在の四半期の最初の日付を返す
 * @returns {Date} 四半期の最初の日付
 */
function getQuarterFirstDate() {
  const currentMonth = (new Date()).getMonth();
  let resultMonth;
  switch (true) {
    // 1-3月
    case (currentMonth < 3):
      resultMonth = 0;
      break;
    // 4-6月
    case (3 <= currentMonth && currentMonth < 6):
      resultMonth = 3;
      break;
    // 7-9月
    case (6 <= currentMonth && currentMonth < 9):
      resultMonth = 6;
      break;
    // 9-12月
    case (9 <= currentMonth && currentMonth < 12):
      resultMonth = 9;
      break;
    default:
      // 上記以外はnullを返す.
      return null;
  }

  const result = new Date();
  result.setMonth(resultMonth);
  result.setDate(1);

  return result;
}

/**
 * フォームの入力内容をもとに第N期のリストを生成する
 * @param {FormData} formData フォームに入力されたデータ
 * @returns {Period[]} 算出された第N期のリスト
 */
function calculatePeriodList(formData) {
  if (formData.ryReportCategory === Constants.Licensee.RyReportCategory.Any) {
    // 任意の場合は1期にまとめる
    return [{
      period: 1,
      ryStartDate: formData.contractStartDate,
      ryEndDate: formData.contractEndDate,
    }];
  }
  const periods = [];
  /**
   * 締め月フラグ
   * @type {boolean[]}
   */
  const closeMonthFlg = (() => {
    if (formData.ryReportCategory === Constants.Licensee.RyReportCategory.Monthly) {
      // 毎月の場合は全部の月にフラグを立てる
      return (new Array(12)).fill(true);
    }
    // 指定月の場合は入力値をフラグとする
    return [
      formData.ryReq1, formData.ryReq2, formData.ryReq3,
      formData.ryReq4, formData.ryReq5, formData.ryReq6,
      formData.ryReq7, formData.ryReq8, formData.ryReq9,
      formData.ryReq10, formData.ryReq11, formData.ryReq12,
    ];
  })();
  const start = dayjs(formData.contractStartDate, 'YYYY/MM/DD');
  const end = dayjs(formData.contractEndDate, 'YYYY/MM/DD');
  let current = start.clone();
  let periodStart = start.clone();
  let periodEnd;
  while (current.isBefore(end)) {
    // 1か月ずつ回して締め月と一致するかチェックする
    if (closeMonthFlg[current.month()]) {
      periodEnd = current.endOf('month').isBefore(end, 'date') ?
        current.endOf('month') :
        end;
      periods.push({
        start: periodStart.format('YYYY/MM/DD'),
        end: periodEnd.format('YYYY/MM/DD'),
      });
      periodStart = periodEnd.add(1, 'day');
    }
    current = current.add(1, 'month');
  }

  // 終了日が最後の締め月からはみ出す場合の処理
  if (periods.length > 0) {
    const tmpEnd = dayjs(periods[periods.length - 1].end, 'YYYY/MM/DD');
    if (tmpEnd.isBefore(end, 'date')) {
      // 契約終了日が最後の期より後の場合
      periods.push({
        start: tmpEnd.add(1, 'day').format('YYYY/MM/DD'),
        end: end.format('YYYY/MM/DD')
      })
    }
  }

  return periods.map((p, idx) => ({
    period: idx + 1,
    ryStartDate: p.start,
    ryEndDate: p.end,
  }));
}

/**
 * 許諾商品の期間リストの内容をクリアする
 * @param {Product[]} productList 許諾商品のリスト
 * @returns {Product[]} 期間リストの内容をクリア後の許諾商品のリスト
 */
function clearProductPeriodData(productList) {
  return productList.map(p => {
    /** @type {ProductPeriod[]} */
    const periodList = (p.periodList ?? []).map(pr => ({
      period: pr.period,
      planProduction: null,
      planSales: null,
    }));
    return {
      ...p,
      periodList,
    }
  });
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} ProposalDetail
 */
/**
 * @typedef {import('../../../slices/licensee/licenseeUsersSlice').LicenseeUsersDetail} LicenseeUser 企画情報
 */
/**
 * @callback OnSubmit 申請・一時保存ボタン押下時のコールバック
 * @param {import('../../../lib/api/licensee').PostProposalParam} params 企画申請APIのパラメータ
 * @param {function} onComplite API呼び出し完了時のコールバック
 */
/**
 * @callback OnDelete 削除ボタン押下時のコールバック
 * @param {ProposalDetail} proposal 削除対象の企画情報
 */
/**
 * @callback OnCancel 取り下げボタン押下時のコールバック
 * @param {OnCancelParam} params 取り下げ理由
 */
/**
 * @typedef {object} OnCancelParam 取り下げボタンコールバックの引数
 * @property {string} messageContent 取り下げ理由
 * @property {ProposalDetail} proposal 取り下げ対象の企画情報
 */
/**
 * @callback OnPermitUpdate 更新開始ボタン押下時のコールバック
 * @param {string} messageContent 更新理由
 * @param {Function} onComplete 更新理由
 */
/**
 * @typedef {import('../../../lib/api/licensee').PostProposalParam} PostProposalParam
 */
/**
 * @typedef {Omit<PostProposalParam, 'proposalStatus'|'proposalList'|'productList'|'periodList'> & { applicationDatetime: string }} FormData
 */
/**
 * @typedef {object} Period 第N期の定義
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日
 * @property {string} ryEndDate ロイヤリティ報告終了日
 */
/**
 * @typedef {object} Product 許諾商品
 * @property {string} [productNo] 品番
 * @property {string} productName 商品名
 * @property {'REG'|'APP'|'OK'|'SUS'} [productStatus] 商品ステータス
 * @property {string} rypId ロイヤリティ報告パターンID
 * @property {number} planPrice 予定上代
 * @property {number} planCost 予定製造原価
 * @property {number} ryRate ロイヤリティ料率
 * @property {number} ryPrice ロイヤリティ単価
 * @property {number} planRyAmount 予定ロイヤリティ金額
 * @property {ProductPeriod[]} periodList 第N期のリスト
 * @property {ProductRenderingImage[]} renderingImageList 商品イメージリスト
 */
/**
 * @typedef {import("../../../slices/licensee/proposalsSlice").ProposalProduct} ProposalProduct
 */
/**
 * @typedef {object} ProductPeriod 許諾商品の第N期のリスト
 * @property {number} period 第N期
 * @property {number} planProduction 予定生産数
 * @property {number} planSales 予定販売数
 */
/**
 * @typedef {object} ProductRenderingImage 商品イメージ
 * @property {number} renderingImageNo 商品イメージNo
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {Constants['Licensee']['ProposalStatus']} ProposalStatus
 */
/**
 * @typedef {T[keyof T]} ValueOf
 * @template T
 */
//#endregion typedef
