import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { downloadFileRetry } from "../../lib/api/aniplex";

/** ストレージ保存キー */
const SaveKey = {
  returnPath: 'aniplex_returnPath',
};

/**
 * 初期値
 */
const initialState = {
  /** @type {string|null} ファイルダウンロードURL */
  fileDownloadUrl: null,
  /** @type {string[]} メッセージのリスト */
  messages: [],
  /** @type {?string} ログイン後の復帰先パス */
  returnPath: null,
  /**
   * API通信状況
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** ファイルダウンロードURL取得処理 */
    fetchDownloadUrl: null,
  },
}

/**
 * store作成時のstateの前準備
 */
export function preloadState() {
  const returnPath = localStorage.getItem(SaveKey.returnPath);
  return {
    ...initialState,
    returnPath,
  };
}

/**
 * ファイルダウンロードURL取得処理
 */
export const fetchDownloadUrl = createAsyncThunk(
  'aniplex/util/fetchDownloadUrl',
  /**
   * ファイルダウンロードURL取得処理
   * @param {object} args
   * @param {'application'|'ryProof'} args.filetype ファイル種別(application: 企画申請書,ryProof: ロイヤリティ報告確証)
   * @param {string} args.fileNo ファイルNo(企画申請書: 企画No,ロイヤリティ報告確証: ロイヤリティ報告確証No)
   */
  async ({ filetype, fileNo }) => {
    /** @type {import('../../lib/api/aniplex').PutFilesDownloadParam} */
    const param = { filetype, fileNo };

    const result = await downloadFileRetry(param);
    return result;
  }
)

/**
 * ANIPLEX向け画面のユーティリティ系データ保持用のスライス
 */
export const utilSlice = createSlice({
  name: '/aniplex/util',
  initialState,
  reducers: {
    /**
     * メッセージを追加する
     * @param {*} state
     * @param {object} action
     * @param {string} action.payload 追加するメッセージ
     */
    pushMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    /**
     * 最新のメッセージを取り除く
     * @param {*} state
     */
    popMessage: (state) => {
      if (state.messages.length > 0) {
        state.messages.pop();
      }
    },
    /**
     * 取得済みのファイルダウンロードURLをクリアする
     * @param {typeof initialState} state
     */
    clearFileDownloadUrl: (state) => {
      state.fileDownloadUrl = null;
    },
    /**
     * ログイン後の復帰先パスを設定する
     * @param {typeof initialState} state
     * @param {object} action
     * @param {?string} action.payload 設定するパス
     */
    setReturnPath: (state, action) => {
      state.returnPath = action.payload;
      if (action.payload != null) {
        localStorage.setItem(SaveKey.returnPath, action.payload);
      } else {
        localStorage.removeItem(SaveKey.returnPath);
      }
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // ファイルダウンロードURL取得処理
        case 'fetchDownloadUrl':
          state.apiStatus.fetchDownloadUrl = null;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // ファイルダウンロードURL取得処理
      .addCase(fetchDownloadUrl.pending, (state) => {
        state.apiStatus.fetchDownloadUrl = 'loading';
      })
      .addCase(fetchDownloadUrl.fulfilled, (state, action) => {
        state.apiStatus.fetchDownloadUrl = 'finish';
        state.fileDownloadUrl = action.payload.downloadPresignedUrl;
      })
      .addCase(fetchDownloadUrl.rejected, (state) => {
        state.apiStatus.fetchDownloadUrl = 'error';
      });
  }
});

export const {
  pushMessage,
  popMessage,
  clearFileDownloadUrl,
  setReturnPath,
  clearApiStatus,
} = utilSlice.actions;

export default utilSlice.reducer;

/**
 * stateから最新のメッセージを取得する
 * @param {*} state
 * @returns {string | null} 最新のメッセージ.メッセージが空の場合はnull.
 */
export const selectLatestMessage = (state) => {
  const length = state.aniplex.util.messages.length;
  if (length === 0) {
    return null;
  }
  return state.aniplex.util.messages[length - 1];
}

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.util.apiStatus;

/**
 * stateからファイルダウンロードURLを取得する
 * @param {*} state
 * @returns {string|null} ファイルダウンロードURL
 */
export const selectFileDownloadUrl = (state) => state.aniplex.util.fileDownloadUrl;

/**
 * stateからログイン後の復帰先パスを取得する
 * @param {*} state
 * @returns {?string} 復帰先のパス
 */
export const selectReturnPath = (state) => state.aniplex.util.returnPath;

//#region typedef
/**
 * @typedef {'fetchDownloadUrl'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
//#endregion typedef
