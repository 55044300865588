import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { Constants } from "../../../Constants"
import { useSearchParams } from "../../../lib/hooks/common"
import { useAuthRedirect } from "../../../lib/hooks/licensee"
import { applyRoyalty, clearApiStatus, clearAppliedRyReportId, clearRoyaltyDetail, deleteRoyalty, fetchRoyaltyDetail, selectApiStatus, selectAppliedRyReportId, selectRoyaltyDetail } from "../../../slices/licensee/royaltiesSlice"
import { pushMessage } from "../../../slices/licensee/utilSlice"
import { ApiLoadingOverlay } from "../../common/ApiLoadingOverlay"
import { RoyaltyReportDetailForm } from "./RoyaltyReportDetailForm"

/**
 * ライセンシー向けロイヤリティ報告画面
 * @returns
 */
export const RoyaltyReportDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRedirect = useAuthRedirect();
  const apiStatus = useSelector(selectApiStatus);
  const royaltyDetail = useSelector(selectRoyaltyDetail);
  const appliedRyReportId = useSelector(selectAppliedRyReportId);
  const searchParams = useSearchParams();
  const routerParams = useParams();

  // 保存タイプ
  const [saveType, setSaveType] = useState(null);
  /** ロイヤリティ報告書内部コード */
  const ryReportId = useMemo(() => {
    return routerParams.ryReportId;
  }, [routerParams.ryReportId]);
  /** 新規登録モードフラグ */
  const isNew = useMemo(() => {
    return ryReportId == null;
  }, [ryReportId]);

  /** 企画内部コード */
  const proposalId = useMemo(() => searchParams.proposalId ?? null, [searchParams]);

  useEffect(() => {
    if (!isNew) {
      // 編集モードの場合は対象のデータを取得する
      dispatch(fetchRoyaltyDetail(ryReportId));
    }
  }, [dispatch, isNew, ryReportId]);

  useEffect(() => {
    if (apiStatus.fetchRoyaltyDetail === 'error') {
      dispatch(pushMessage('ロイヤリティ報告の情報取得中にエラーが発生しました。'));
      return;
    }
  }, [apiStatus.fetchRoyaltyDetail, dispatch])

  // ページ離脱時に各種APIステータスをクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchRoyaltyDetail'));
      dispatch(clearRoyaltyDetail());
      dispatch(clearApiStatus('applyRoyalty'));
      dispatch(clearAppliedRyReportId());
      dispatch(clearApiStatus('deleteRoyalty'));
    }
  }, [dispatch]);

  // フォームから渡されたコールバック
  const [onSaveComplete, setOnSaveComplete] = useState(null);

  /** 申請・一時保存ボタン押下時のコールバック */
  const onSubmit = useCallback(
    /** @param {PostRoyaltiesParam} royalty */
    (param, onComplete) => {
      // 申請か一時保存かを保持
      setSaveType(param.reportStatus);
      // ロイヤリティ報告API呼び出し
      dispatch(applyRoyalty(param));
      // API呼び出し完了時に呼ばれる
      setOnSaveComplete(onComplete);
    }
  , [dispatch]);

  useEffect(() => {
    if (apiStatus.applyRoyalty === 'error') {
      // ロイヤリティ報告APIエラー時はメッセージ表示
      if (saveType === Constants.Licensee.reportStatus.Requesting) {
        dispatch(pushMessage('ロイヤリティ報告の申請中にエラーが発生しました。'));
      } else {
        dispatch(pushMessage('保存中にエラーが発生しました。'));
      }
      dispatch(clearApiStatus('applyRoyalty'));
      setOnSaveComplete(null);
    }
  }, [apiStatus.applyRoyalty, dispatch, saveType]);

  useEffect(() => {
    if (apiStatus.applyRoyalty === 'finish') {
      // API成功時はメッセージを表示
      if (saveType === Constants.Licensee.reportStatus.Requesting) {
        dispatch(pushMessage('ロイヤリティ報告の申請を行いました。'));
      } else {
        dispatch(pushMessage('ロイヤリティ報告の内容を一時保存しました。'));
      }
      // API通信状況をクリア
      dispatch(clearApiStatus('applyRoyalty'));
      if (isNew) {
        // 企画内部コード付きのURLに変更
        navigate(`/licensee/royaltyReportDetail/${appliedRyReportId}`);
      } else {
        // 企画情報をリロード
        dispatch(fetchRoyaltyDetail(ryReportId));
      }
      // フォームから渡されたコールバックを実行
      if (onSaveComplete) {
        onSaveComplete();
        setOnSaveComplete(null);
      }
    }
  }, [apiStatus.applyRoyalty, appliedRyReportId, dispatch, isNew, navigate, ryReportId, saveType, onSaveComplete]);

  /** 削除ボタン押下時のコールバック */
  const onDelete = useCallback(
    /** @param {RoyaltyDetail} royalty */
    (royalty) => {
      // 新規の場合はメッセージを表示して一覧画面へ遷移
      if (isNew) {
        dispatch(pushMessage('ロイヤリティ報告を削除しました。'));
        navigate('/licensee/royaltyReportList');
        return;
      }
      // 編集モードの場合はAPIを呼び出す
      dispatch(deleteRoyalty(royalty));
    }
  , [dispatch, isNew, navigate]);

  useEffect(() => {
    if (apiStatus.deleteRoyalty === 'finish') {
      // 削除API成功時
      dispatch(pushMessage('ロイヤリティ報告を削除しました。'));
      // 一覧画面へ遷移
      navigate('/licensee/royaltyReportList');
      return
    }
    if (apiStatus.deleteRoyalty === 'error') {
      // 削除API失敗時
      dispatch(pushMessage('ロイヤリティ報告の削除中にエラーが発生しました。'));
      clearApiStatus('deleteRoyalty');
      return;
    }
  }, [apiStatus.deleteRoyalty, dispatch, navigate]);

  /** 入力抑制フラグ */
  const formLocked = useMemo(() => {
    return apiStatus.fetchRoyaltyDetail === 'loading'
      || apiStatus.fetchRoyaltyDetail === 'error'
      || apiStatus.applyRoyalty === 'loading'
      || apiStatus.applyRoyalty === 'finish'
      || apiStatus.deleteRoyalty === 'loading'
      || apiStatus.deleteRoyalty === 'finish';
  }, [apiStatus.applyRoyalty, apiStatus.deleteRoyalty, apiStatus.fetchRoyaltyDetail]);

  // 未ログイン時は対応する画面へ遷移
  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ロイヤリティ報告</h1>
        <p className="link-pageback deco-line c-pink">
          <Link to={'/licensee/royaltyReportList'}>
            <i className="icn pageback"></i>ロイヤリティ報告一覧へ戻る
          </Link>
        </p>
      </div>

      <RoyaltyReportDetailForm
        isNew={isNew}
        loadedData={royaltyDetail}
        proposalId={proposalId}
        formLocked={formLocked}
        onSubmit={onSubmit}
        onDelete={onDelete} />

      <ApiLoadingOverlay
        apiStatus={[
          apiStatus.fetchRoyaltyDetail,
          apiStatus.applyRoyalty,
          apiStatus.deleteRoyalty,
        ]} />
    </div>
  )
}

//#region typedef
/**
 * @typedef {import('./RoyaltyReportDetailForm').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告情報
 */
/**
 * @typedef {import('../../../lib/api/licensee').PostRoyaltiesParam} PostRoyaltiesParam
 */
//#endregion typedef
