import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersMyself, updateUsersMyself } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";
import { logout } from "./authSlice";

/**
 * 初期値
 */
const initialState = {
  /** @type {UserMyself|null} ログイン中ユーザ情報 */
  myself: null,
  /** API通信ステータス */
  apiStatus : {
    /** @type {ApiStatus} ログインユーザー情報取得 */
    fetch: null,
    /** @type {ApiStatus} ログインユーザー情報更新 */
    update: null
  }
}

/**
 * ログイン中ユーザ情報取得処理
 */
export const fetchMyself = createAsyncThunk(
  'licensee/users/fetchMyself',
  async (_, { dispatch }) => {
    try {
      const data = await getUsersMyself();
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch);
    }
  }
)

/**
 * ログイン中ユーザ情報更新処理
 */
export const updateMyself = createAsyncThunk(
  'licensee/users/updateMyself',

  async(userdata, { dispatch }) => {
    try {
      const data = await updateUsersMyself(userdata);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20601': ApiErrorMessage.ValidateError,
        // 更新状態不一致
        '20602': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * ライセンシー向け画面のユーザー情報周りのスライス
 */
export const usersSlice = createSlice({
  name: 'licensee/users',
  initialState,
  reducers: {
    /**
     * API通信ステータスをクリアする
     * @param {*} state
     */
    clearApiStatus: (state, action) => {
      // // ログインユーザー情報取得処理
      switch(action.payload) {
        case 'fetch':
          state.apiStatus.fetch = null;
          break;
        case 'update':
          state.apiStatus.update = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // ログインユーザ情報取得処理
      .addCase(fetchMyself.pending, (state) => {
        state.apiStatus.fetch = 'loading';
      })
      .addCase(fetchMyself.fulfilled, (state, action) => {
        state.apiStatus.fetch = 'finish';
        state.myself = action.payload;
      })
      .addCase(fetchMyself.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetch = null;
      })
      // ログアウト処理
      .addCase(logout, (state) => {
        // 各種stateをクリア
        state.myself = null;
      })
      // ログインユーザー情報更新
      .addCase(updateMyself.pending, (state) => {
        state.apiStatus.update = 'loading';
      })
      .addCase(updateMyself.fulfilled, (state, action) => {
        state.apiStatus.update = 'finish';
      })
      .addCase(updateMyself.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.update = null;
      });
  }
});

export const { clearApiStatus } = usersSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<'fetch'|'update', ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.users.apiStatus;

/**
 * stateからログイン中ユーザ情報を取得する
 * @param {*} state
 * @returns {UserMyself|null} ログイン中ユーザ情報
 */
export const selectMyself = (state) => state.licensee.users.myself;

export default usersSlice.reducer;

//#region typedef
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} UserMyself ログインユーザー情報
 * @property {string} userId ユーザーID
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} [zipCode] 郵便番号
 * @property {string} [address] 住所
 * @property {string} [phone] 電話番号
 * @property {string} username 氏名
 * @property {string} mailaddress メールアドレス
 * @property {string} [department] 部署名
 * @property {string} updateDatetime 更新日時
 * @property {boolean} initialLogin 初回ログインフラグ
 * @property {boolean} passwordExpired パスワード有効期限切れフラグ
 */
//#endregion
