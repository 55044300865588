import { axiosSend } from './common';
import { Config } from '../../config';
import { logout, selectTemporaryToken, selectToken, setSessionExpired, updateToken } from '../../slices/licensee/authSlice';
import { ApiError } from './errors/ApiError';
import { pushMessage } from '../../slices/licensee/utilSlice';
import { ApiErrorMessage } from '../message';
import { HandledApiError } from './errors/HandledApiError';

/**
 * トークンを付与せずにAPIリクエストを投げる
 * @param {string} url 送信先APIのURL
 * @param {import('./common').RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {*} option axiosのオプション
 * @returns {Promise<import('./common').SendResultData>} APIのレスポンスボディ
 */
async function send(url, method, data, option = {}) {
  const { suppressError, ...axiosOption } = option;
  try {
    const { data: result } = await axiosSend(url, method, data, axiosOption);

    return result;
  } catch (err) {
    handleError(err, suppressError);
    // エラーハンドリングを抜けたときにundefined参照エラーを出来るだけ抑止するため
    // 最低限のデータ構造を返却
    return {
      result: {
        data: {},
      },
    }
  }
}

/**
 * 招待トークンを付与してAPIリクエストを投げる
 * @param {string} url 送信先APIのURL
 * @param {import('./common').RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {string} invitationToken 招待トークン
 * @returns {Promise<import('./common').SendResultData>} APIのレスポンスボディ
 */
 async function sendWithInvitationToken(url, method, data, invitationToken) {
  const option = {
    headers: {
      'Authorization': invitationToken,
    },
  };

  try {
    const { data: result } = await axiosSend(url, method, data, option);
    return result;
  } catch (err) {
    // ユーザ登録系の403エラーは独自にエラーメッセージを設定
    const status = err.statusCode;
    if (status === 403) {
      const store = require('../../store').store;
      store.dispatch(pushMessage(ApiErrorMessage.InvitationTokenExpired));
      throw new HandledApiError(err);
    }

    handleError(err);
    // エラーハンドリングを抜けたときにundefined参照エラーを出来るだけ抑止するため
    // 最低限のデータ構造を返却
    return {
      result: {
        data: {},
      },
    }
  }
}

/**
 * API呼出しで発生したエラーのハンドリング
 * @param {*} err throwされたエラーオブジェクト
 * @param {?boolean} suppressError エラー表示抑止フラグ
 */
function handleError(err, suppressError) {
  const status = err.statusCode;
  if (status === 403) {
    // セッション期限切れ
    const store = require('../../store').store;
    store.dispatch(setSessionExpired(true));
    store.dispatch(logout());
    throw new HandledApiError(err);
  }

  if (err instanceof ApiError) {
    // 共通系エラー処理
    const store = require('../../store').store;
    switch (err.errorCode) {
      case '90000': // バリデートエラー
        if (!suppressError) {
          store.dispatch(pushMessage(ApiErrorMessage.ValidateError));
        }
        break;
      case '99999': // システムエラー
        if (!suppressError) {
          store.dispatch(pushMessage(ApiErrorMessage.SystemError));
        }
        break;
      default:
        throw err;
    }

    throw new HandledApiError(err);
  }

  throw err;
}

/**
 * 一時トークンを付与してAPIリクエストを投げる
 * @param {string} url 送信先APIのURL
 * @param {import('./common').RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {*} option axiosのオプション
 * @returns {Promise<import('./common').SendResultData>} APIのレスポンスボディ
 */
async function sendWithTemporaryToken(url, method, data, option = {}) {
  const store = require('../../store').store;
  const temporaryToken = selectTemporaryToken(store.getState());
  option = {
    ...option,
    headers: {
      'Authorization': temporaryToken,
    },
  };

  const result = await send(url, method, data, option);

  return result;
}

/**
 * トークンを付与してAPIリクエストを投げる
 * @param {string} url 送信先APIのURL
 * @param {import('./common').RequestMethod} method リクエストするHTTPメソッド
 * @param {*} data 送信するbody部またはクエリパラメータ
 * @param {*} option axiosのオプション
 * @returns {Promise<import('./common').SendResultData>} APIのレスポンスボディ
 */
async function sendWithToken(url, method, data, option = {}) {
  const store = require('../../store').store;
  const token = selectToken(store.getState());
  option = {
    ...option,
    headers: {
      'Authorization': token,
    },
  };

  const result = await send(url, method, data, option);

  if (result.token) {
    store.dispatch(updateToken(result.token));
  }

  return result;
}

/**
 * ログインユーザー確認APIを呼び出す
 * @param {LoginCredential} credential ログイン情報
 * @returns {AuthLoginResponse} APIのレスポンスデータ
 */
export async function authLogin(credential) {
  const url = Config.LicenseeApiUrl + '/auth/login';
  const result = await send(url, 'POST', credential);
  return result
}

/**
 * ログインAPIを呼び出す
 * @param {string} loginAuthCode 認証コード
 * @returns {AuthCodeResponse} APIのレスポンスデータ
 */
export async function authCode(loginAuthCode) {
  const url = Config.LicenseeApiUrl + '/auth/code';
  const data = {
    loginAuthCode,
  };
  const result = await sendWithTemporaryToken(url, 'POST', data);
  return result;
}

/**
 * パスワード再設定メール送信API
 * @param {passwordResetRequestParam} パスワード再設定要求
 * @returns {passwordResetRequestResponse} APIレスポンス
 */
export async function passwordResetRequest(data) {
  const url = Config.LicenseeApiUrl + '/password/resetRequest';
  const result = await send(url, 'POST', data);
  return result;
}

/**
 * パスワード再設定API
 * @param {passwordResetParam} パスワード再設定情報
 * @returns {passwordResetParamResponse} APIレスポンス
 */
export async function passwordReset(data, option) {
  const url = Config.LicenseeApiUrl + '/password/reset';
  const result = await send(url, 'PATCH', data, option);
  return result;
}

/**
 * ログインユーザー情報詳細APIを呼び出す
 * @returns {GetUserMyselfResult} APIレスポンスボディのresult.data
 */
export async function getUsersMyself() {
  const url = Config.LicenseeApiUrl + '/users/myself';
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ログインユーザー情報更新APIを呼び出す
 * @param {object} ログインユーザー情報
 * @returns { UpdateUsersMyselfResponse } APIのレスポンスデータ
 */
export async function updateUsersMyself(userData) {
  const url = Config.LicenseeApiUrl + '/users/myself';
  const result = await sendWithToken(url, 'PATCH', userData);
  return result
}

/**
 * ライセンシーユーザー一覧APIを呼び出す
 * @returns { GetLicenseeUsersResponse } APIのレスポンスデータ
 */
 export async function getLicenseeUsers() {
  const url = Config.LicenseeApiUrl + '/licenseeusers';
  const result = await sendWithToken(url, 'GET');
  return result
}

/**
 * ライセンシーユーザー更新APIを呼び出す
 * @param {string} params ユーザID
 * @param {UpdateLicenseeUsersParam} params ボディ部に設定するパラメータ
 * @returns { UpdateLicenseeUsersResponse } APIのレスポンスデータ
 */
 export async function udpateLicenseeUsers(userId, param) {
  const url = Config.LicenseeApiUrl + `/licenseeusers/${userId}`;
  const result = await sendWithToken(url, 'PATCH', param);
  return result
}

/**
 * ユーザー招待APIを呼び出す
 * @param {PostInvitationParam} params ボディ部に設定するパラメータ
 * @returns { PostInvitationResponse } APIのレスポンスデータ
 */
 export async function postInvitation(params) {
  const url = Config.LicenseeApiUrl + '/licenseeusers/invitation';
  const result = await sendWithToken(url, 'POST', params);
  return result
}

/**
 * 招待ユーザー確認APIを呼び出す
 * @param {string} invitationToken 招待トークン
 * @returns { UserAuthResponse } APIのレスポンスデータ
 */
 export async function checkUserAuth(invitationToken) {
  const url = Config.LicenseeApiUrl + '/licenseeusers/auth';
  const result = await sendWithInvitationToken(url, 'POST', {}, invitationToken);
  return result
}

/**
 * ライセンシーユーザー登録APIを呼び出す
 * @param {PostLicenseeUsersParam} params ボディ部に設定するパラメータ
 * @param {string} invitationToken 招待トークン
 * @returns { PostLicenseeUsersResponse } APIのレスポンスデータ
 */
 export async function postLicenseeUsers(param, invitationToken) {
  const url = Config.LicenseeApiUrl + '/licenseeusers';
  const result = await sendWithInvitationToken(url, 'POST', param, invitationToken);
  return result
}

/**
 * 企画申請APIを呼び出す
 * @param {PostProposalParam} proposal 送信パラメータ
 * @returns {PostProposalResponse} APIのレスポンスデータ
 */
export async function postProposal(proposal) {
  const url = Config.LicenseeApiUrl + '/proposals';
  const result = await sendWithToken(url, 'POST', proposal);
  return result;
}

/**
 * 企画詳細APIを呼び出す
 * @param {number} proposalId 企画内部コード
 * @returns {GetProposalResult} APIレスポンスボディのresult.data
 */
export async function getProposal(proposalId) {
  const url = Config.LicenseeApiUrl + `/proposals/${proposalId}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * 企画更新APIを呼び出す
 * @param {number} proposalId 企画内部コード
 * @param {PatchProposalParam} params ボディ部に設定するパラメータ
 * @returns {PatchProposalResult} APIレスポンスボディのresult.data
 */
export async function patchProposal(proposalId, params) {
  const url = Config.LicenseeApiUrl + `/proposals/${proposalId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * 許諾商品更新APIを呼び出す
 * @param {number} productId 許諾商品内部コード
 * @param {PatchProductParam} params ボディ部に設定するパラメータ
 * @returns {PatchProductResult} APIレスポンスボディのresult.data
 */
export async function patchProduct(productId, params) {
  const url = Config.LicenseeApiUrl + `/products/${productId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * サンプル送付日更新APIを呼び出す
 * @param {number} proposalId 企画内部コード
 * @param {PatchSampleDateParam} params ボディ部に設定するパラメータ
 * @returns {PatchSampleDateResult} APIレスポンスボディのresult.data
 */
export async function patchSampleDate(proposalId, params) {
  const url = Config.LicenseeApiUrl + `/proposals/${proposalId}/sampledate`
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * マスタ情報照会APIを呼び出す
 * @returns {GetMastersResponse} APIレスポンスのデータ部
 */
export async function getMasters() {
  const url = Config.LicenseeApiUrl + '/masters';
  const result = await sendWithToken(url, 'GET');
  return result;
}

/**
 * ファイルアップロードAPIを呼び出す
 * @param {filetype} ファイル種別 ("renderingImage"又は"ryProof")
 * @param {filename} ファイル名
 * @returns { UploadFileResponse } APIのレスポンスデータ
 */
export async function uploadFile({ filetype, filename }) {
  const url = Config.LicenseeApiUrl + '/files/upload';
  const result = await sendWithToken(url, 'PUT', { filetype, filename });
  return result;
}

/**
 * ファイルダウンロードAPIを呼び出す
 * @param {?number} fileNo ファイルNo
 * @param {string} filetype ファイル種別
 * @returns {string} ダウンロード用署名付きURL
 */
export async function downloadFile(fileNo, filetype = 'ryProof') {
  const url = Config.LicenseeApiUrl + '/files/download';
  const params = {
    filetype,
  };
  if (fileNo != null) {
    params.fileNo = fileNo;
  }
  const result = await sendWithToken(url, 'PUT', params);
  return result.result.data.downloadPresignedUrl;
}

/**
 * 再試行つきでファイルダウンロードAPIを呼び出す
 * @param {?number} fileNo ファイルNp
 * @param {string} filetype ファイル種別
 * @returns {string} ダウンロード用署名付きURL
 */
export async function downloadFileRetry(fileNo, filetype = 'ryProof') {
  let count = 0;
  while (1) {
    count++;
    try {
      return await downloadFile(fileNo, filetype);
    } catch (e) {
      // ファイルのS3バケット間コピー待ち以外はエラーとする
      if (e.errorCode !== '22402') {
        throw e;
      }
      // ファイルのコピー待ち時、一定回数の試行を超えたらエラーにする
      if (count >= Config.DownloadFile.RetryCount) {
        throw e;
      }
    }
    // 一定時間待機後に再試行する
    await new Promise(res => window.setTimeout(res, Config.DownloadFile.RetryWaitMs));
  }
}

/**
 * 画像を取得する
 */
export async function getImage({ renderingImageNo }) {
  const url = Config.LicenseeApiUrl + '/images';
  const result = await sendWithToken(url, 'GET', { renderingImageNo });
  return result;
}

/**
 * 画像を取得する(再試行あり)
 */
export async function getImageRetry({ renderingImageNo }) {
  let count = 0;
  while (1) {
    count++;
    try {
      return await getImage({ renderingImageNo });
    } catch (e) {
      // 画像のコピー待ち以外はエラーとする
      if (e.errorCode !== '21402') {
        throw e;
      }
      // 画像のコピー待ち時、一定回数の試行を超えたらエラーとする
      if (count >= Config.GetImage.RetryCount) {
        throw e;
      }
    }
    // 一定時間待機後に再試行する
    await new Promise(resolve => window.setTimeout(resolve, Config.GetImage.RetryWaitMs));
  }
}

/**
 * 商品イメージ複製APIを呼び出す T
 * @param {number[]} imageNoList 商品イメージNoのリスト
 * @param {object} option オプション
 * @param {?boolean} option.suppressError エラー表示抑止フラグ
 * @returns {DuplicateImageResult} APIのレスポンスデータ
 */
export async function duplicateImage(imageNoList, { suppressError }) {
  const url = Config.LicenseeApiUrl + '/images/duplicate';
  const param = {
    renderingImageNoList: imageNoList
  };
  const result = await sendWithToken(url, 'POST', param, { suppressError });
  return result.result.data;
}

/**
 * 証紙申請APIを呼び出す
 * @param {PostLabelParam} label 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
export async function postLabel(label) {
  const url = Config.LicenseeApiUrl + '/labels';
  const result = await sendWithToken(url, 'POST', label);
  return result;
}

/**
 * 証紙申請一覧APIを呼び出す
 * @param {GetLabelsParam} param 送信パラメータ
 * @returns {*} APIのレスポンスデータ
 */
export async function getLabelsAll(param) {
  const url = Config.LicenseeApiUrl + '/labels';
  const result = await sendWithToken(url, 'GET', param);
  return result;
}

/**
 * 証紙申請詳細を取得する
 * @param {number} labelApplicationId 証紙申請書内部コード
 * @returns
 */
export async function getLabel(labelApplicationId) {
  const url = Config.LicenseeApiUrl + `/labels/${labelApplicationId}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * 証紙申請更新APIを呼び出す
 * @param {*} params
 * @returns
 */
export async function patchLabel(labelApplicationId, data) {
  const url = Config.LicenseeApiUrl + `/labels/${labelApplicationId}`;
  const result = await sendWithToken(url, 'PATCH', data);
  return result;
}

/**
 * ロイヤリティ報告詳細APIを呼び出す
 * @param {number} ryReportId ロイヤリティ報告書内部コード
 * @returns {GetRoyaltyResult} APIレスポンスボディのresult.data
 */
export async function getRoyalty(ryReportId) {
  const url = Config.LicenseeApiUrl + `/royalties/${ryReportId}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

/**
 * ロイヤリティ報告更新APIを呼び出す
 * @param {number} ryReportId ロイヤリティ報告書内部コード
 * @param {PatchRoyaltyParam} params リクエストパラメータ
 * @returns {PatchRoyaltyResult} APIレスポンスボディのresult.data
 */
export async function patchRoyalty(ryReportId, params) {
  const url = Config.LicenseeApiUrl + `/royalties/${ryReportId}`;
  const result = await sendWithToken(url, 'PATCH', params);
  return result.result.data;
}

/**
 * ロイヤリティ報告APIを呼び出す
 * @param {PostRoyaltiesParam} data リクエストデータ
 * @returns {PostRoyaltiesResult} APIレスポンスボディのresult.data
 */
export async function postRoyalties(data) {
  const url = Config.LicenseeApiUrl + '/royalties';
  const result = await sendWithToken(url, 'POST', data);
  return result.result.data;
}

/**
 * 企画一覧を取得する
 * @param {GetProposalsParam} params リクエストパラメータ
 */
export async function getProposalsAll(params = {}) {
  const url = Config.LicenseeApiUrl + '/proposals';
  const result = await sendWithToken(url, 'GET', {...(params || {})});
  return result;
}

/**
 * ロイヤリティ報告一覧を取得する
 * @returns {ListRoyaltyResult}
 */
export async function getRoyaltyAll(params = {}) {
  const url = Config.LicenseeApiUrl + '/royalties';
  const result = await sendWithToken(url, 'GET', {...(params || {})});
  return result;
}

/**
 * お知らせ一覧APIを呼び出す
 * @returns {GetAnnouncementAllResult} APIのレスポンスデータ
 */
 export async function getAnnouncementAll() {
  const url = Config.LicenseeApiUrl + '/announcement';
  const result = await sendWithToken(url, 'GET');
  return result;
}

/**
 * S決裁詳細APIを呼び出す
 * @param {string} sDecisionNo S決裁No
 * @returns {Promise<SDecisionDetail>}
 */
export async function getSDecisionDetail(sDecisionNo) {
  const url = Config.LicenseeApiUrl + `/sdecisions/${sDecisionNo}`;
  const result = await sendWithToken(url, 'GET');
  return result.result.data;
}

//#region typedef
/**
 * @typedef {object} LoginCredential ログイン情報
 * @property {string} mailaddress メールアドレス
 * @property {string} password パスワード
 */
/**
 * @typedef {object} AuthLoginResponse ログインユーザー確認APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {string} temporaryToken 一時トークン
 */
/**
 * @typedef {object} AuthCodeResponse ログインAPIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {object} result.data
 * @property {string} result.data.passwordUpdateDatetime パスワード変更日時
 * @property {boolean} result.data.initialLogin 初回ログインフラグ
 * @property {string} token 認証トークン
 */
/**
 * @typedef {object} passwordResetRequestParam パスワード再設定メール送信APIパラメータ
 * @property {string} mailaddress メールアドレス
 */
/**
 * @typedef {object} passwordResetRequestResponse パスワード再設定メール送信APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result {}
 * @property {string} temporatyToken 一時トークン
 */
/**
 * @typedef {object} passwordResetParam パスワード再設定APIパラメータ
 * @property {string} password パスワード
 * @property {string} passResetAuthCode パスワードリセット認証コード
 */
/**
 * @typedef {object} passwordResetParamResponse パスワード再設定APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result {}
 * TODO::認証トークン返却される仕様で合ってる？
 * @property {string} token 認証トークン
 */
/**
 * @typedef {import('../../slices/licensee/usersSlice').UserMyself} GetUserMyselfResult ログインユーザー情報詳細APIの結果
 */
/**
 * @typedef {object} GetProposalsParam 企画一覧APIのリクエストパラメータ
 * @property {string} [propertySummaryCode] 作品コード
 * @property {Exclude<valueOf<Constants['Licensee']['ProposalStatus']>, 'DEL'>} [proposalStatus] 企画申請ステータス（ライセンシー検索用）
 * TMP: 一時保存
 * CAN: 取り下げ
 * PER: 更新中
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 承認済み
 * SUS: 中止
 * FNR: 契約終了（未報告）
 * FIN: 契約終了
 * APP_FNR: 承認済み+契約終了（未報告）
 * @property {string} [applicantUserName] 申請者氏名※部分一致検索
 * @property {string} [proposalNo] 企画No※部分一致検索
 * @property {string} [proposalTitle] 企画件名※部分一致検索
 * @property {string} [applicationDateFrom] 申請日From(YYYY/MM/DD)
 * @property {string} [applicationDateTo] 申請日To(YYYY/MM/DD)
 */
/**
 * @typedef {object} PostProposalParam 企画申請APIのパラメータ
 * @property {number} [proposalId] 企画内部コード
 * @property {'TMP'|'REQ'} proposalStatus 申請ステータス
 * @property {string} propertySummaryCode 作品コード
 * @property {string} proposalTitle 企画件名
 * @property {string} [proposalLaunchDate] 発売開始希望日(YYYY/MM/DD)
 * @property {string} [proposalDetail] 企画説明
 * @property {string} [proposalRemarks] 備考
 * @property {string} contractStartDate 契約開始日(YYYY/MM/DD)
 * @property {string} contractEndDate 契約終了日(YYYY/MM/DD)
 * @property {boolean} ryReq1 ロイヤリティ報告締め月_1月
 * @property {boolean} ryReq2 ロイヤリティ報告締め月_2月
 * @property {boolean} ryReq3 ロイヤリティ報告締め月_3月
 * @property {boolean} ryReq4 ロイヤリティ報告締め月_4月
 * @property {boolean} ryReq5 ロイヤリティ報告締め月_5月
 * @property {boolean} ryReq6 ロイヤリティ報告締め月_6月
 * @property {boolean} ryReq7 ロイヤリティ報告締め月_7月
 * @property {boolean} ryReq8 ロイヤリティ報告締め月_8月
 * @property {boolean} ryReq9 ロイヤリティ報告締め月_9月
 * @property {boolean} ryReq10 ロイヤリティ報告締め月_10月
 * @property {boolean} ryReq11 ロイヤリティ報告締め月_11月
 * @property {boolean} ryReq12 ロイヤリティ報告締め月_12月
 * @property {number} [shareWeb] WEB通販比率
 * @property {number} [shareWholesale] 卸売比率
 * @property {boolean} [shareOwnEcFlag] 自社EC有無
 * @property {number} [shareOwnEc] Web通販比率_自社EC
 * @property {number} [shareOtherEc] Web通販比率_他社EC
 * @property {number} [shareAnimation] 卸売比率_アニメ専門店
 * @property {number} [shareKaden] 卸売比率_家電量販店
 * @property {number} [shareCvs] 卸売比率_CVS
 * @property {number} [shareGms] 卸売比率_GMS
 * @property {number} [shareSm] 卸売比率_スーパーマーケット
 * @property {number} [shareDrug] 卸売比率_ドラッグストア
 * @property {number} [shareHobby] 卸売比率_ホビー
 * @property {number} [shareOnline] 卸売比率_オンライン（くじ、プライズ、電子書籍）
 * @property {number} [shareOther] 卸売比率_その他
 * @property {string} [shareWholesaleRemarks] 卸売備考（流通先名、その他内訳）
 * @property {string} [target] メイン・ターゲット
 * @property {string} [promotionChannel] 対流通施策
 * @property {string} [promotionUser] 対ユーザー施策
 * @property {string} [promotionWeb] WEB対策
 * @property {string} [promotionTwitter] Twitter情報
 * @property {string} [promotionDisplay] 展示会・カタログ情報
 * @property {string} [updateDatetime] 最終更新日時(YYYY/MM/DD)
 * @property {boolean} automaticContractUpdateReq 自動契約更新リクエスト
 * @property {valueOf<Constants['Licensee']['RyReportCategory']>} ryReportCategory ロイヤリティ報告区分 MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 * @property {string} [messageContent] メッセージ内容
 * @property {string} kikakuUserId 企画担当者ユーザーID
 * @property {string[]} [noticeUserIdList] 通知先一覧
 * @property {object[]} periodList 第N期のリスト
 * @property {number} periodList.period 第N期
 * @property {string} periodList.ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} periodList.ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {object[]} productList 許諾商品のリスト
 * @property {number} productList.productId 許諾商品内部コード
 * @property {string} productList.productName 商品名
 * @property {number} productList.categoryNo 商品カテゴリーNo
 * @property {number} productList.categoryDetailNo 商品カテゴリー詳細No
 * @property {string} productList.character キャラクター
 * @property {string} productList.version バージョン
 * @property {string} productList.launchDate 発売希望日
 * @property {string} productList.rypId ロイヤリティ報告パターンID
 * @property {number} productList.planPrice 予定上代
 * @property {number} productList.planCost 予定製造原価
 * @property {number} productList.planProceeds 予定売上金額
 * @property {number} productList.ryRate ロイヤリティ料率
 * @property {number} productList.ryPrice ロイヤリティ単価
 * @property {number} productList.planRyAmount 予定ロイヤリティ金額
 * @property {string} productList.salesMethod 販売方式
 * @property {string} productList.productMethod 生産方式
 * @property {string} productList.material 素材他仕様
 * @property {string} productList.productRemarks 備考
 * @property {object[]} productList.periodList 商品の第N期のリスト
 * @property {number} productList.periodList.period 第N期
 * @property {number} productList.periodList.planProduction 予定生産数
 * @property {number} productList.periodList.planSales 予定販売数
 * @property {object} productList.renderingImageList 商品イメージのリスト
 * @property {number} productList.renderingImageList.renderingImageNo 商品イメージNo
 */
/**
 * @typedef {object} PostProposalResponse 企画申請APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {object} result.data
 * @property {number} result.data.proposalId 企画内部コード
 * @property {string} result.data.proposalNo 企画No
 */
/**
 * @typedef {import('../../slices/licensee/proposalsSlice').ProposalDetail} GetProposalResult 企画詳細APIの結果
 */
/**
 * @typedef {object} PatchProposalParam 企画更新APIのパラメータ
 * @property {'CAN'|'PER'|'DEL'} proposalStatus 申請ステータス
 * CAN: 取り下げ
 * PER: 更新中
 * DEL: 削除
 * @property {string} [messageContent] メッセージ内容
 * @property {string} [kikakuUserId] 企画担当者ユーザーID
 * @property {string[]} [noticeUserIdList] 通知先一覧
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchProposalResult 企画更新APIの結果
 * @property {number} proposalId 企画内部コード
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchProductParam 許諾商品更新APIのパラメータ
 * @property {string} [sampleDate] サンプル送付日(YYYY/MM/DD)
 * @property {string} [sampleRegDatetime] サンプル送付登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} [productImageUrl] 完成イメージURL
 * @property {string} [productImageRegDatetime] 完成イメージ登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchProductResult 許諾商品更新APIの結果
 * @property {number} [productId] 許諾商品内部コード
 * @property {string} [sampleRegDatetime] サンプル送付登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} [productImageRegDatetime] 完成イメージ登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchSampleDateParam サンプル送付日更新APIのパラメータ
 * @property {import('../../slices/licensee/proposalsSlice').UpdateSampleDateParamItem[]} productList 商品リスト
 */
/**
 * @typedef {object} PatchSampleDateResult サンプル送付日更新APIの結果
 * @property {number} productId 許諾商品内部コード
 * @property {string} sampleRegDatetime サンプル送付日最終登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} productImageRegDatetime 完成イメージ最終登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} DuplicateImageResult 商品イメージ複製APIの結果
 * @property {object[]} duplicateImageList 複製画像情報のリスト
 * @property {number} imageNoList[].renderingImageNoBefore 複製元のイメージNo
 * @property {number} imageNoList[].renderingImageNoAfter 複製後のイメージNo
 */
/**
 * @typedef {object} ログインユーザー情報更新
 * @property {string} username 氏名
 * @property {string} password パスワード
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} GetMastersResponse マスタ情報照会APIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {object} result.data
 * @property {PropertySummaryMst[]} result.data.propertySummaryMst 作品マスタ情報
 * @property {CategoryMst[]} result.data.categoryMst 商品カテゴリーマスタ情報
 * @property {import('../../slices/licensee/masterSlice').RoyaltyPatternMst[]} result.data.royaltyPatternMst ロイヤリティ報告パターンマスタ情報
 */
/**
 * @typedef {object} PropertySummaryMst 作品マスタ情報
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {string} propertySummaryNameKana 作品名称カナ
 * @property {string} propertySummaryNameHankakuKana 作品名称半角カナ
 * @property {string} propertySummaryNameHiragana 作品名称ひらがな
 * @property {string} iconFilename アイコンファイル名
 * @property {boolean} proposalFlag 申請可能フラグ
 */
/**
 * @typedef {object} CategoryMst 商品カテゴリーマスタ情報
 * @property {number} categoryNo 商品カテゴリーNo
 * @property {string} categoryName 商品カテゴリー名
 * @property {CategoryDetail[]} categoryDetailList 商品カテゴリー詳細マスタ情報
 */
/**
 * @typedef {object} CategoryDetail 商品カテゴリー詳細マスタ情報
 * @property {number} categoryDetailNo 商品カテゴリー詳細No
 * @property {string} categoryDetailName 商品カテゴリー詳細名
 */
/**
 * @typedef {object} UploadFileResponse ログインAPIの結果
 * @property {'SUCCESS'} status API実行ステータス
 * @property {object} result
 * @property {object} result.data
 * @property {string} result.data.filetype ファイル種別
 * @property {string} result.data.fileNo ファイルNo
 * @property {string} result.data.uploadPresignedUrl アップロード用署名付きURL
 * @property {string} token 認証トークン
 */
/**
 * @typedef {object} GetLabelsParam 証紙申請一覧APIのリクエストパラメータ
 * @property {string} [proposalNo] 企画No※部分一致検索
 * @property {string} [proposalTitle] 企画件名※部分一致検索
 * @property {string} [applicationDateFrom] 申請日From(YYYY/MM/DD)
 * @property {string} [applicationDateTo] 申請日To(YYYY/MM/DD)
 * @property {valueOf<Constants['Licensee']['labelStatus']>} [labelStatus] 証紙申請ステータス
 * TMP: 一時保存
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 承認済み
 * @property {string} [applicantUserName] 申請者氏名※部分一致検索
 */
/**
 * @typedef {object} PostLabelParam 証紙申請APIのパラメータ
 * @property {number} [labelApplicationId] 証紙申請書内部コード
 * @property {number} proposalId 企画内部コード
 * @property {'TMP'|'REQ'} labelStatus 証紙申請ステータス（ライセンシー向け申請用パラメータ） TMP: 一時保存, REQ: 申請中
 * @property {string} [preferredDate] 希望日
 * @property {string} [message] 連絡事項
 * @property {string} [messageContent] メッセージ内容
 * @property {string} [deliveryZipCode] 送付先郵便番号
 * @property {string} [deliveryAddress] 送付先住所
 * @property {string} [deliveryPhone] 送付先電話番号
 * @property {string} [deliveryCorpDivision] 宛先会社名／部署名
 * @property {string} [deliveryName] 宛名
 * @property {object[]} [labelList] 証紙情報
 * @property {number} [labelList.labelNo] 証紙No
 * @property {number} labelList.productId 許諾商品内部コード
 * @property {number} [labelList.labelFigure] 証紙申請数
 * @property {'S'|'P'} [labelList.labelType] 証紙タイプ S: シール, P: 印刷
 * @property {string} updateDatetime 最終更新日時
 */
/**
 * @typedef {object} 証紙申請APIの結果
 * @property {number} labelApplicationId 証紙申請内部コード
 * @property {string} labelApplicationNo 証紙申請書No
 */
/**
 * @typedef {import('../../slices/licensee/royaltiesSlice').RoyaltyDetail} GetRoyaltyResult ロイヤリティ報告詳細APIの結果
 */
/**
 * @typedef {object} PostRoyaltiesParam ロイヤリティ報告APIのリクエストパラメータ
 * @property {number} [ryReportId] ロイヤリティ報告書内部コード
 * @property {number} proposalId 企画内部コード
 * @property {number} period 第N期
 * @property {string} [ryStartDate] ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} [ryEndDate] ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {'TMP'|'REQ'} reportStatus 報告ステータス
 * @property {object[]} ryAmountList ロイヤリティ金額情報
 * @property {number} [ryAmountList[].ryAmountNo] ロイヤリティ金額No
 * @property {number} ryAmountList[].productId 許諾商品内部コード
 * @property {number} [ryAmountList[].reportProduction] 生産数
 * @property {number} [ryAmountList[].reportSales] 販売数
 * @property {number} [ryAmountList[].reportPrice] 確定上代
 * @property {number} [ryAmountList[].reportCost] 確定製造原価
 * @property {number} [ryAmountList[].reportProceeds] 確定売上金額
 * @property {number} [ryAmountList[].reportRyAmount] ロイヤリティ金額
 * @property {object[]} ryProofList ロイヤリティ報告確証ファイルリスト
 * @property {number} ryProofList[].ryProofNo ロイヤリティ報告確証No
 * @property {string} [updateDatetime] 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} [billingZipCode] 請求先郵便番号
 * @property {string} [billingAddress] 請求先住所
 * @property {string} [billingDepartment] 請求先部署名
 * @property {string} [billingName] 請求先担当者氏名
 * @property {string} [billingPhone] 請求先電話番号
 * @property {string} [messageContent] メッセージ内容
 */
/**
 * @typedef {object} PostRoyaltiesResult ロイヤリティ報告APIの結果
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportNo ロイヤリティ報告書No
 */
/**
 * @typedef {object} PatchRoyaltyParam ロイヤリティ報告更新APIのパラメータ
 * @property {'DEL'} reportStatus 報告ステータス
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PatchRoyaltyResult ロイヤリティ報告更新APIの結果
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ListRoyaltyResult ロイヤリティ報告一覧APIの結果
 * @property {object} result
 * @property {import('../../slices/licensee/royaltiesSlice').Royalty[]} data
 */
/**
 * @typedef {import('../../slices/licensee/licenseeUsersSlice').LicenseeUsersDetail[]} GetLicenseeUsersResponse ライセンシーユーザー一覧APIの結果
 */
/**
 * @typedef {object} UpdateLicenseeUsersParam ライセンシーユーザー更新APIパラメータ
 * @property {boolean} deleteFlag 削除フラグ
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} UpdateLicenseeUsersResponse ライセンシーユーザー更新API結果
 * @property {string} userId ユーザーID
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} PostInvitationParam ユーザー招待APIパラメータ
 * @property {string} mailaddress メールアドレス
 */
/**
 * @typedef {object} PostInvitationResponse ユーザー招待API結果
 */
/**
 * @typedef {import('../../slices/licensee/announcementSlice').Announcement[]} GetAnnouncementAllResult お知らせ一覧APIの結果
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
/**
 * @typedef {import('../../slices/licensee/sDecisionsSlice').SDecisionDetail} SDecisionDetail
 */
//#endregion
