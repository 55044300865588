import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { downloadFileRetry } from "../../lib/api/licensee";

/**
 * 初期値
 */
const initialState = {
  /** @type {?string} ファイルダウンロードURL */
  fileDownloadUrl: null,
  /** @type {string[]} メッセージのリスト */
  messages: [],
  /** @type {?string} ログイン後の復帰先のパス */
  returnPath: null,
  /**
   * API通信状況
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** ファイルダウンロードURL取得処理 */
    fetchDownloadUrl: null,
  },
};

/**
 * ファイルダウンロードURL取得処理
 */
export const fetchDownloadUrl = createAsyncThunk(
  'licensee/util/fetchDownloadUrl',
  /**
   * ファイルダウンロードURL取得処理
   * @param {object} args
   * @param {'productFormat'|'manual'} args.filetype ファイル種別
   */
  async ({ filetype }) => {
    const result = await downloadFileRetry('', filetype);
    return result;
  }
)

/**
 * ライセンシー向け画面のユーティリティ系データ保持用のスライス
 */
export const utilSlice = createSlice({
  name: 'licensee/util',
  initialState,
  reducers: {
    /**
     * メッセージを追加する
     * @param {*} state
     * @param {object} action
     * @param {string} action.payload 追加するメッセージ
     */
    pushMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    /**
     * 最新のメッセージを取り除く
     * @param {*} state
     */
    popMessage: (state) => {
      if (state.messages.length > 0) {
        state.messages.pop();
      }
    },
    /**
     * 取得済みのファイルダウンロードURLをクリアする
     * @param {typeof initialState} state
     */
    clearFileDownloadUrl: (state) => {
      state.fetchDownloadUrl = null;
    },
    /**
     * 復帰先のパスを設定する
     * @param {typeof initialState} state
     * @param {object} action
     * @param {?string} action.payload 設定するパス
     */
    setReturnPath: (state, action) => {
      state.returnPath = action.payload;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatue: (state, action) => {
      switch (action.payload) {
        // ファイルダウンロードURL取得処理
        case 'fetchDownloadUrl':
          state.apiStatus.fetchDownloadUrl = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // ファイルダウンロードURL取得処理
      .addCase(fetchDownloadUrl.pending, (state) => {
        state.apiStatus.fetchDownloadUrl = 'loading';
      })
      .addCase(fetchDownloadUrl.fulfilled, (state, action) => {
        state.apiStatus.fetchDownloadUrl = 'finish';
        state.fileDownloadUrl = action.payload;
      })
      .addCase(fetchDownloadUrl.rejected, (state) => {
        state.apiStatus.fetchDownloadUrl = 'error';
      });
  }
});

export const {
  pushMessage,
  popMessage,
  setReturnPath,
  clearFileDownloadUrl,
  clearApiStatue,
} = utilSlice.actions;

export default utilSlice.reducer;

/**
 * stateから最新のメッセージを取得する
 * @param {*} state
 * @returns {string|null} 最新のメッセージ.メッセージが空の場合はnull.
 */
export const selectLatestMessage = (state) => {
  const length = state.licensee.util.messages.length;
  if (length === 0) {
    return null;
  }
  return state.licensee.util.messages[length - 1];
}

/**
 * stateからファイルダウンロードURLを取得する
 * @param {*} state
 * @returns {?string} ファイルダウンロードURL
 */
export const selectFileDownloadUrl = (state) => state.licensee.util.fileDownloadUrl;

/**
 * stateから復帰先のパスを取得する
 * @param {*} state
 * @returns {?string} 復帰先のパス
 */
export const selectReturnPath = (state) => state.licensee.util.returnPath;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.util.apiStatus;

//#region
/**
 * @typedef {'fetchDownloadUrl'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
//#endregion
