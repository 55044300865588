import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadList } from '../common/UploadList';
import { getImageRetry } from '../../lib/api/aniplex';
import { getImageType } from '../../lib/util';
import { ApiErrorMessage } from '../../lib/message';

/**
 * ライセンシー側・画像参照APIから取得したサムネイルを表示
 * @param {object} params
 * @param {string[]} [params.fileNoList=[]] 表示対象のファイルNoリスト
 * @param {string[]} [params.prevFileNoList] 前のバージョンのファイルNoリスト
 * @param {Function} [params.onDeleteFile] 削除時のコールバック
 */
export const UploadedImageList = ({
  fileNoList = [],
  prevFileNoList,
  onDeleteFile
}) => {

  // サムネイル一覧
  const [fileEntries, setFileEntries] = useState(/** @type {FileEntry[]} */ ([]));

  // fileNoごとの画像を保持する
  const [memo, setMemo] = useState({});

  // 取得中
  const [progress, setProgress] = useState({});

  // エラー
  const [errors, setErrors] = useState({});

  // 画像参照APIから画像を取得してメモに保持する
  const fetchFileImage = useCallback(async (key, fileNo) => {
      try {
        setProgress(prev => ({ ...prev, [fileNo]: true }));
        const data = (await getImageRetry({ renderingImageNo: fileNo }))?.result?.data ?? {};
        const { imageBinary } = data;
        const imageType = imageBinary && getImageType(imageBinary);
        if (imageBinary && imageType) {
          const imageSrc = 'data:' + imageType + ';base64,' + imageBinary;
          setMemo(memo => ({ ...memo, [fileNo]: imageSrc}));
        }
      } catch {
        setErrors(prev => ({
          ...prev,
          [fileNo]: ApiErrorMessage.GetImageFailed,
        }));
      } finally {
        setProgress(prev => ({ ...prev, [fileNo]: false }));
      }
  }, []);

  /** 更新状態つきのファイルNoのリスト */
  const fileNoInfo = useFileNoInfo({ fileNoList, prevFileNoList });

  // マウント時、削除時に状態を更新する
  useEffect(() => {
    setFileEntries(fileNoInfo.map(({fileNo, updateStatus}) => {
      const entry = {
        completed: true,
        // APIから取得した画像を設定
        imageSrc: memo[fileNo] || '',
        key: fileNo, // ここに入るfileNoは一意の前提
        name: '',
        fileNo,
        hasError: !!errors[fileNo],
        errorMessage: errors[fileNo] ?? '',
        updateStatus,
      };
      // APIから画像を取得していない時は取得する
      if (!memo[fileNo] && !errors[fileNo] && !progress[fileNo]) {
        fetchFileImage(fileNo, fileNo);
      }
      return entry;
    }));
  }, [memo, fetchFileImage, errors, progress, fileNoInfo]);

  return (
    <UploadList fileEntries={fileEntries} onDeleteFile={onDeleteFile} />
  );
};

/**
 * 新旧のファイルNoのリストから更新状態を含むファイルNoの情報を取得する
 * @param {object} params
 * @param {string[]} params.fileNoList ファイルNoのリスト
 * @param {string[]|undefined} params.prevFileNoList 前バージョンのファイルNoのリスト
 * @returns {FileNoInfo[]}
 */
const useFileNoInfo = ({
  fileNoList,
  prevFileNoList,
}) => {
  /**
   * 削除済みのリスト
   * @type {FileNoInfo[]}
   */
  const deletedList = useMemo(() =>
    prevFileNoList?.filter(no => !fileNoList.includes(no))
      .map(no => ({ fileNo: no, updateStatus: 'deleted' })) ?? []
  , [fileNoList, prevFileNoList]);

  /** 新規追加されたNoのリスト */
  const newList = useMemo(() =>
    fileNoList.filter(no => !prevFileNoList?.includes(no))
  , [fileNoList, prevFileNoList]);

  /** ファイルNoの更新状態を取得する */
  const getUpdateStatus = useCallback((fileNo) => {
    if (prevFileNoList == null) {
      // 前バージョンが存在しない場合は未変更扱い
      return null;
    }
    if (newList.includes(fileNo)) {
      return 'new';
    }
    return null;
  }, [newList, prevFileNoList]);

  return useMemo(() => {
    /** @type {FileNoInfo[]} */
    const list = fileNoList.map(no => ({
      fileNo: no,
      updateStatus: getUpdateStatus(no),
    }));

    return [
      ...deletedList,
      ...list,
    ];
  }, [deletedList, fileNoList, getUpdateStatus]);
}

//#region typedef
/**
 * @typedef {object} FileNoInfo ファイルNoの情報
 * @property {string} fileNo ファイルNo
 * @property {'new'|'deleted'|null} updateStatus 更新状態
 */
/**
 * @typedef {object} FileEntry 画像ファイル情報
 * @property {boolean} completed
 * @property {string} imageSrc 画像URL
 * @property {'new'|'deleted'|null} [updateStatus] 更新状態
 */
//#endregion typedef
