import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, isValidPassword, lengthRange } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { selectMyself } from "../../../slices/licensee/usersSlice";
import { getMessage } from '../../../lib/message';
import { TermsPopup } from "../TermsPopup";
import { useFocusError } from "../../../lib/hooks/common";

/** フォーム項目の順序 */
const formPropOrder = [
  'password',
  'newPassword',
  'newPasswordConf',
];

/**
 * 初期パスワード変更・パスワード変更画面の入力フォーム部分
 * @param {object} prop
 * @param {boolean} prop.canSubmit フォーム送信可能フラグ
 * @param {(formData: { password: string }) => void} prop.onSubmit 送信ボタン押下時のコールバック
 * @returns
 */
export const PasswordUpdateForm = ({ canSubmit, onSubmit }) => {
  /** 初回レンダリング判定フラグ */
  const isFirstRef = useRef(true);
  const [ formData, setFormData ] = useState({
    password: '',
    newPassword: '',
    newPasswordConf: ''
  });

  const [ messages, setMessages ] = useState({
    password: [],
    newPassword: [],
    newPasswordConf: []
  });

  // 利用規約確認
  const [checkPolicy, setCheckPolicy] = useState(false);

  // エラー時にフォーカスするための設定
  const [formRefs, focusError] = useFocusError(formPropOrder);
  // エラー要素へのフォーカスが必要か
  const [needFocusError, setNeedFocusError] = useState(false);

  // パスワードのラベル出し分け
  const currentPath = useLocation().pathname;
  const isInitial = useMemo(() => {
    return /\/initial/.test(currentPath)
  }, [currentPath]);
  const passwordText = useMemo(() => {
    return isInitial ? '初期パスワード' : '現在のパスワード';
  }, [isInitial]);

  /** エラーがないかのチェック処理 */
  const checkHasError = useCallback((messages) => {
    return Object.values(messages)
      .flat().length > 0
  }, []);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }

  // エラー時にエラー項目にフォーカスする
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (messages[prop].length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          break;
        }
      }
    }
  }, [focusError, messages, needFocusError]);

  /** レンダリング毎にまとめてパラメータチェックを行なう */
  useEffect(() => {
    if (isFirstRef.current) {
      // 初回レンダリングの場合はスキップ
      isFirstRef.current = false;
      return;
    }
    setMessages({
      ...validate(formData, passwordText)
    });
  }, [formData, passwordText]);

  const loginUser = useSelector(selectMyself);
  const onPasswordChange = () => {
    const newMessages = {
      ...validate(formData, passwordText)
    }
    if (checkHasError(newMessages)) {
      // バリデートエラーがある場合は表示に反映して処理終了
      setMessages(newMessages);
      setNeedFocusError(true);
      return;
    }
    onSubmit({
      password: formData.newPassword,
      currentPassword: formData.password,
      updateDatetime: loginUser.updateDatetime,
    });
  };

  /** 送信系ボタン無効化フラグ true:disabled */
  const submitDisabled = (!canSubmit || (isInitial && !checkPolicy));

  // 利用規約表示ポップアップ
  const [termsPopup, setTermsPopup] = useState({
    /** @type {boolean} 表示フラグ */
    showFlg: false,
    /** @type {import('./TermsPopup').OnClose} 閉じるときのコールバック */
    onClose: null,
  });

  /** 利用規約表示リンク押下時のハンドラ */
  const onTermsPopupClick = useCallback(() => {
    setTermsPopup({
      showFlg: true,
      onClose: () => {
        setCheckPolicy(true);
        setTermsPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, []);

  return (
    <div className="main_login-content">
      <dl className="main_login-form form-set">
        <dt className="form-name">{passwordText}</dt>
        <dd className="form-body wdt500">
          <div className="input-form">
            <input type="password"
             name="password"
             title={`${passwordText}は必ず入力してください`}
             aria-label={passwordText}
             required
             maxLength={32}
             value={formData.password}
             ref={formRefs.current.password}
             onChange={event => handleChange('password', event.target.value)} />
          </div>
          <ErrorMessageList messages={messages.password} />
        </dd>
      </dl>

      <dl className="main_login-form form-set">
        <dt className="form-name">新規パスワード</dt>
        <dd className="form-body wdt500">
          <div className="input-form">
            <input type="password"
             name="newPassword"
             title="新規パスワードは必ず入力してください"
             aria-label="新規パスワード"
             required
             maxLength={32}
             value={formData.newPassword}
             ref={formRefs.current.newPassword}
             onChange={event => handleChange('newPassword', event.target.value)} />
          </div>
          <ErrorMessageList messages={messages.newPassword} />
        </dd>
      </dl>

      <dl className="main_login-form form-set">
        <dt className="form-name">新規パスワード（確認）</dt>
        <dd className="form-body wdt500">
          <div className="input-form">
            <input type="password"
             name="newPasswordConf"
             title="新規パスワード（確認）は必ず入力してください"
             aria-label="新規パスワード（確認）"
             required
             maxLength={32}
             value={formData.newPasswordConf}
             ref={formRefs.current.newPasswordConf}
             onChange={event => handleChange('newPasswordConf', event.target.value)} />
          </div>
          <ErrorMessageList messages={messages.newPasswordConf} />
        </dd>
      </dl>
      {
        isInitial ?
        <dl className="main_login-form form-set" style={{display: 'block', textAlign: 'center', marginBottom: -10}}>
          <button onClick={onTermsPopupClick} style={{textDecoration: 'underline', fontSize: 16}}>利用規約をご一読ください。</button>
          <p className="attention">※パスワード変更ボタン押下前に、利用規約をご確認ください。</p>
        </dl>
        : null
      }
      <p className="btn bg-green">
        <button onClick={() => onPasswordChange()}
        disabled={submitDisabled}>パスワード変更</button>
      </p>
      {
        termsPopup.showFlg ?
          <TermsPopup onClose={termsPopup.onClose} /> :
          null
      }
    </div>
  );
}

/**
 * フォーム入力内容をバリデートする（全パラメータ確認）
 * @param {object} {'password', 'newPassword', 'newPasswordConf'}
 * @param {string} パスワード名称
 * @return {array} エラーメッセージ
 */
function validate(data, passwordText) {
  const paramList = ['password', 'newPassword', 'newPasswordConf'];
  const errors = [];

  // パスワード共通のバリデートチェック
  for (const name of paramList) {
    errors[name] = [];
    if (isEmpty(data[name])) {
      errors[name].push(getMessage('isNotEmpty'));
      continue;
    }
    if (!lengthRange(data[name], 8, 32)) {
      errors[name].push(getMessage('lengthRange', {'min':8, 'max':32}));
    }
    if (!isValidPassword(data[name])) {
      errors[name].push(getMessage('isValidPassword'));
    }
  }
  // 値の相関チェック
  if (data['password'] === data['newPassword']) {
      // 新規パスワードと変更チェック
      errors['newPassword'].push(passwordText + 'と同じ値は使用できません');
  }
  if (data['newPassword'] !== data['newPasswordConf']) {
    // 新規パスワードの確認チェック
    errors['newPasswordConf'].push(getMessage('isNotMatchConf', {'param':'新規パスワード'}));
  }
  return errors;
}
