//@ts-check
import React, { useCallback, useMemo } from 'react';
import { comma3 } from '../../../lib/util';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { SpannableTableView } from '../../common/table/SpannableTableView';

/** @type {TableHeader[]} */
const headers = [
  { id: 'proposalNo', label: '企画書No', style: { width: '100px' } },
  { id: 'proposalTitle', label: '企画件名', style: { width: '400px' } },
  { id: 'ryReportNo', label: 'ロイヤリティ\n報告No', style: { width: '120px' } },
  { id: 'ryDate', label: '報告対象期間', style: { width: '270px' } },
  { id: 'ryAmount', label: 'ロイヤリティ金額', style: { width: '150px' } },
  { id: 'afterRyAmount', label: '報告受領後の\nロイヤリティ金額合計', style: { width: '150px' } },
  { id: 'afterMgZan', label: '報告受領後の\nMG残高', style: { width: '150px' } },
  { id: 'afterAdZan', label: '報告受領後の\nアドバンス残高', style: { width: '150px' } },
  { id: 'afterOverRy', label: '報告受領後の\nオーバーロイヤリティ合計', style: { width: '200px' } },
];

/**
 * 申請中RY報告一覧
 * @param {object} params
 * @param {?SDecisionDetail} params.sDecision S決裁詳細
 */
export const RequestingRyReportList = ({ sDecision }) => {
  const records = useViewRecords(sDecision);

  return (
    <section className="mt40">
      <div className="title-aniplex">
        <h2 className="title">申請中のロイヤリティ報告一覧</h2>
      </div>

      {/* @ts-expect-error */}
      <SpannableTableView
        className='mt10 border0 scroll'
        headers={headers}
        records={records}
        resizable
        scrollable />
    </section>
  );
}

/**
 * 表示用のレコードを取得する
 * @param {?SDecisionDetail} sDecision S決裁詳細
 * @returns {TableRecord[]}
 */
const useViewRecords = (sDecision) => {
  const calcAfterRyAmount = useCalcAfterRyAmount(sDecision);
  const sortedList = useSortedList(sDecision?.reqRyReportList ?? []);

  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });

    return sortedList.map(report => {
      const afterRyAmount = calcAfterRyAmount(report);
      const afterMgZan = BN.max(
        new BN(0),
        new BN(sDecision?.mg ?? 0).minus(new BN(afterRyAmount))
      ).toNumber();
      const afterAdZan = BN.max(
        new BN(0),
        new BN(sDecision?.ad ?? 0).minus(new BN(afterRyAmount))
      ).toNumber();
      const afterOverRy = BN.max(
        new BN(0),
        new BN(afterRyAmount).minus(new BN(sDecision?.ad ?? 0))
      ).toNumber();

      return {
        proposalNo: {
          el: (
            // @ts-expect-error
            <Link to={`/aniplex/proposalDetail/${report.proposalId}`}>{report.proposalNo}</Link>
          )
        },
        proposalTitle: {
          el: report.proposalTitle,
        },
        ryReportNo: {
          el: (
            // @ts-expect-error
            <Link to={`/aniplex/royaltyReportDetail/${report.ryReportId}`}>{report.ryReportNo}</Link>
          ),
        },
        ryDate: {
          el: `第${report.period}期 ${report.ryStartDate} ～ ${report.ryEndDate}`,
        },
        ryAmount: {
          className: 'cost',
          el: comma3(report.ryAmount),
        },
        afterRyAmount: {
          className: 'cost',
          el: comma3(afterRyAmount),
        },
        afterMgZan: {
          className: 'cost',
          el: comma3(afterMgZan),
        },
        afterAdZan: {
          className: 'cost',
          el: comma3(afterAdZan),
        },
        afterOverRy: {
          className: 'cost',
          el: comma3(afterOverRy),
        },
      }
    }) ?? []
  }, [calcAfterRyAmount, sDecision?.ad, sDecision?.mg, sortedList])
}

/**
 * RY報告のリストを報告日時の昇順にソートする
 * @param {RequestingRyReport[]} ryReportList RY報告のリスト
 */
const useSortedList = (ryReportList) => {
  return useMemo(() =>
    [...ryReportList].sort((a, b) =>
      dayjs(a.reportDatetime, 'YYYY/MM/DD HH:mm:ss').diff(dayjs(b.reportDatetime, 'YYYY/MM/DD HH:mm:ss'), 'second')
    ), [ryReportList]);
}

/**
 * 受領済みのロイヤリティ金額合計
 * @param {?SDecisionDetail} sDecision S決裁詳細
 */
const useApprovedRyAmountTotal = (sDecision) => {
  return useMemo(() => {
    const BN = BigNumber.clone({
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
    });

    return sDecision?.appRyReportList
      .reduce((prev, cur) => prev.plus(new BN(cur.ryAmount)), new BN(0)) ?? new BN(0)
  }, [sDecision?.appRyReportList]);
}

/**
 * 報告受領後のロイヤリティ金額合計計算処理
 * @param {?SDecisionDetail} sDecision
 */
const useCalcAfterRyAmount = (sDecision) => {
  const approvedRyAmountTotal = useApprovedRyAmountTotal(sDecision);

  /**
   * 報告受領後のロイヤリティ金額合計を計算する
   */
  const calcAfterRyAmount = useCallback(
    /**
     * @param {RequestingRyReport} report 計算対象のRY報告
     */
    (report) => {
      const BN = BigNumber.clone({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
      });

      return new BN(report.ryAmount).plus(approvedRyAmountTotal).toNumber();
    }, [approvedRyAmountTotal]);

  return calcAfterRyAmount;
}

//#region typedef
/**
 * @typedef {import('../../common/table/SpannableTableView').Header} TableHeader
 */
/**
 * @typedef {import('../../common/table/SpannableTableView').DataRecord} TableRecord
 */
/**
 * @typedef {import('../../../slices/aniplex/sDecisionsSlice').SDecisionDetail} SDecisionDetail
 */
/**
 * @typedef {ElementOf<SDecisionDetail['reqRyReportList']>} RequestingRyReport
 */
/**
 * @typedef {T[number]} ElementOf
 * @template {unknown[]} T
 */
//#endregion
