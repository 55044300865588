import { useState } from "react";

/** @type {SearchFormData} */
const initialFormData = {
  /** 取引先コード */
  licenseeCode: null,
  /** 取引先名 */
  licenseeNameKanji: null,
};

/**
 * 検索フォーム部分
 * @param {object} props
 * @param {(param: SearchFormData) => void} props.onSearch 検索実行時のコールバック
 * @returns
 */
export const SelectLicenseePopupSearchForm = ({ onSearch }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [canClickSearch, setCanClickSearch] = useState(false);

  const onFormInputChange = (form, value) => {
    const newFormData = {
      ...formData,
      [form]: value === '' ? null : value,
    };
    setFormData(newFormData);

    const existNotEmptyData = Object.values(newFormData).some((v) => v !== null);
    setCanClickSearch(existNotEmptyData);
  };

  const onSearchClick = () => {
    onSearch(formData);
  };

  const onClearClick = () => {
    setFormData(initialFormData);
    setCanClickSearch(false);
  };

  return (
    <>
      <div className="l-form mt25">
        <dl className="form-set">
          <dt className="form-name">取引先コード</dt>
          <dd className="form-body">
            <div className="input-form">
              <input
                type="text"
                name="licenseeCode"
                value={formData.licenseeCode ?? ''}
                onChange={e => onFormInputChange('licenseeCode', e.target.value)} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form">
              <input
                type="text"
                name="licenseeNameKanji"
                value={formData.licenseeNameKanji ?? ''}
                onChange={e => onFormInputChange('licenseeNameKanji', e.target.value)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt15">
        <p className="btn bg-yellow" style={{ width: '160px' }}>
          <button disabled={!canClickSearch} onClick={onSearchClick}>検索</button>
        </p>
        <p className="btn c-aniplex" style={{ width: '160px' }}>
          <button onClick={onClearClick}>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームの入力データ
 * @property {string|null} licenseeCode 取引先コード
 * @property {string|null} licenseeNameKanji 取引先名
 */
//#endregion typedef
