import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom";
import { clearApiStatus, clearProposalDetail, fetchProposalDetail, selectApiStatus, selectProposalDetail } from "../../../slices/aniplex/proposalsSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { ProposalDetailForm } from "./ProposalDetailForm";

/**
 * 企画申請画面
 * @returns
 */
export const ProposalDetailPage = () => {
  const dispatch = useDispatch();
  const proposalDetail = useSelector(selectProposalDetail);
  const apiStatus = useSelector(selectApiStatus);
  // react-routerによるパラメータ
  const routerParams = useParams();

  useEffect(() => {
    return () => {
      // 画面離脱時にAPI通信関連の内容をクリアする
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(clearProposalDetail());
    }
  }, [dispatch]);

  useEffect(() => {
    if (routerParams.proposalId != null) {
      // 企画内部コードを元に企画詳細を取得する
      dispatch(fetchProposalDetail(routerParams.proposalId));
    }
  }, [dispatch, routerParams.proposalId]);

  /** データ再読込要求のハンドラ */
  const requestReload = useCallback(() => {
    // いったん現在の値を削除
    dispatch(clearApiStatus('fetchProposalDetail'));
    dispatch(clearProposalDetail());
    // 企画情報の取得をリクエスト
    dispatch(fetchProposalDetail(routerParams.proposalId));
  }, [dispatch, routerParams.proposalId]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    if (apiStatus.fetchProposalDetail === 'loading') {
      return <LoadingOverlay />;
    }
    return null;
  }, [apiStatus.fetchProposalDetail]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">企画書申請</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to='/aniplex/proposalList'><i className="icn pageback"></i>企画一覧へ戻る</Link>
        </p>
      </div>

      <ProposalDetailForm
        proposal={proposalDetail}
        requestReload={requestReload} />

      {loading}

    </div>
  )
}
